import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as QuizInfoAction from '../../Actions/QuizInfo';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import DatePicker from 'react-date-picker';
import $ from 'jquery';

class AddQuizInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			examId: '',
			quizName: '',
			quizDate: '',
			quizTime: '',
			duration: '',
			totalMarks: '',
			staffId: '',
			subjectId: '',
			sectionId: '',
			gradeId: '',
			sessionId: '',
			classId: '',
			select: [],
			sessions: [],
			Subject: [],
			Staff: [],
			Section: [],
			msg: false,
			errorMsg: false,
			isLoading: false
		};
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.select !== nextProps.selectData ||
			prevState.sessions !== nextProps.selectSession
		) {
			return {
				select: nextProps.selectData,
				sessions: nextProps.selectSession
			};
		}
		return null;
	}
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/classSectionGradeTeacher',
				this.props.QuizInfoAction.getClassesForQuizInfo
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		this.props.sharedActions
			.gAllData('/api/Session', this.props.QuizInfoAction.getSessionForQuizInfo)
			.then(success => {})
			.catch(error => {});
	}
	handleChange = (date, state) => {
		this.setState({
			[state]: date
		});
		console.log(date);
	};
	handleValidation() {
		let fields = this.state.campusName;
		let errors = {};
		let formIsValid = true;

		//Name
		if (!fields) {
			formIsValid = false;
			errors['name'] = 'Cannot be empty';
		}

		if (typeof fields['name'] !== 'undefined') {
			if (!fields['name'].match(/^[a-zA-Z]+$/)) {
				formIsValid = false;
				errors['name'] = 'Only letters';
			}
		}

		//Email
		if (!fields['email']) {
			formIsValid = false;
			errors['email'] = 'Cannot be empty';
		}

		if (typeof fields['email'] !== 'undefined') {
			let lastAtPos = fields['email'].lastIndexOf('@');
			let lastDotPos = fields['email'].lastIndexOf('.');

			if (
				!(
					lastAtPos < lastDotPos &&
					lastAtPos > 0 &&
					fields['email'].indexOf('@@') == -1 &&
					lastDotPos > 2 &&
					fields['email'].length - lastDotPos > 2
				)
			) {
				formIsValid = false;
				errors['email'] = 'Email is not valid';
			}
		}

		this.setState({ errors: errors });
		return formIsValid;
	}
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	handleSubmit = e => {
		e.preventDefault();
		const {
			staffId,
			classId,
			sectionId,
			subjectId,
			sessionId,
			gradeId,
			examId,
			quizName,
			quizDate,
			quizTime,
			duration,
			totalMarks
		} = this.state;
		if (
			Object.is(staffId, '') ||
			Object.is(classId, '') ||
			Object.is(sectionId, '') ||
			Object.is(subjectId, '') ||
			Object.is(sessionId, '') ||
			Object.is(gradeId, '') ||
			Object.is(examId, '') ||
			Object.is(quizName, '') ||
			Object.is(quizTime, '') ||
			Object.is(quizDate, '') ||
			Object.is(duration, '') ||
			Object.is(totalMarks, '')
		) {
			this.setState({
				errorMsg: true
			});
		} else {
			const data = {
				staffId,
				classId,
				sectionId,
				subjectId,
				sessionId,
				gradeId,
				examId,
				quizName,
				quizDate,
				quizTime,
				duration,
				totalMarks,
				accountType: this.props.userData.role,
				accountId: this.props.userData.unique_name
			};
			this.setState({
				isLoading: true
			});
			this.props.sharedActions
				.addRecord('/api/QuizInfo', data, this.props.QuizInfoAction.addQuizInfo)
				.then(success => {
					this.setState({
						errorMsg: false,
						staffId: '',
						classId: '',
						sectionId: '',
						subjectId: '',
						sessionId: '',
						gradeId: '',
						quizDate: '',
						quizName: '',
						quizTime: '',
						duration: '',
						gradeName: '',
						totalMarks: '',
						msg: true,
						isLoading: false
					});
					this.props.QuizInfoAction.updateQuizInfoFlag(true);
				})
				.catch(error => {});
			console.log(this.state);
		}
	};
	inputData = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onSelectClassChange = e => {
		console.log(e.target.value);

		const Grade = this.state.select.grade;
		const Subject = this.state.select.subject;
		const Staff = this.state.select.staff;
		const Section = this.state.select.sections;
		let check = Grade.filter(item => item.classId == e.target.value);

		console.log(check);
		this.setState({
			gradeName: check[0] && check[0].gradeName,
			classId: e.target.value,
			gradeId: check[0] && check[0].gradeId
		});
		let checkTwo = Subject.filter(item => item.classId == e.target.value);

		this.setState({
			Subject: checkTwo
		});
		let checkThird = Staff.filter(item => item.classId == e.target.value);

		this.setState({
			Staff: checkThird
		});
		let checkFourth = Section.filter(item => item.classId == e.target.value);

		this.setState({
			Section: checkFourth
		});
	};
	onSelectSubjectChange = e => {
		console.log(e.target.value);

		this.setState({
			subjectId: e.target.value
		});
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);

		this.setState({
			sessionId: e.target.value
		});
	};
	onSectionChange = e => {
		console.log(e.target.value);

		this.setState({
			sectionId: e.target.value
		});
	};
	onStaffChange = e => {
		console.log(e.target.value);

		this.setState({
			staffId: e.target.value
		});
	};
	render() {
		console.log('classSectionGrade', this.state.select);
		const { classes } = this.state.select;
		console.log('Classes ka data', classes);
		const { session } = this.state.sessions;
		console.log('Session ka data', session);
		return (
			<div className="container">
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Quiz Info
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.state.errorMsg ? (
								<p style={{ color: 'red', textAlign: 'center' }}>
									Please fill the form properly
								</p>
							) : (
								''
							)}
							{this.state.msg ? (
								<p style={{ color: 'green', textAlign: 'center' }}>
									Added Successfully!
								</p>
							) : (
								''
							)}
							<form
								onSubmit={this.handleSubmit}
								id="contact-form"
								name="contact-form"
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Quiz Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="quizName"
												class="form-control"
												value={this.state.quizName}
												placeholder="Quiz Name"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Quiz Date
											</label>
											<br />
											<DatePicker
												onChange={e => this.handleChange(e, 'quizDate')}
												value={this.state.quizDate}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Quiz Time
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="quizTime"
												class="form-control"
												value={this.state.quizTime}
												placeholder="Quiz Time"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Duration
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="duration"
												class="form-control"
												value={this.state.duration}
												placeholder="Quiz Duration"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Total Marks
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="totalMarks"
												class="form-control"
												value={this.state.totalMarks}
												placeholder="Total Marks "
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												<i style={{ marginRight: 4 }} />
												Select Exam
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.inputData}
												class="custom-select"
												name="examId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Exam..
												</option>
												<option value="1">1st Terminal</option>
												<option value="2">2nd Terminal</option>
												<option value="3">3rd Terminal</option>
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Class Name
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onSelectClassChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Name...
												</option>
												{this.state.select.classes &&
													classes.map((item, index) => (
														<option key={index} value={`${item.classId}`}>
															{item.className}
														</option>
													))}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Section Name
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onSectionChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Section...
												</option>
												{this.state.Section.map((item, index) => (
													<option key={index} value={`${item.sectionId}`}>
														{item.section}
													</option>
												))}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Staff Name
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onStaffChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Staff...
												</option>
												{this.state.Staff.map((item, index) => (
													<option key={index} value={`${item.staffId}`}>
														{item.staffName}
													</option>
												))}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Grade Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="gradeId"
												class="form-control"
												readOnly={true}
												value={this.state.gradeName}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Sessions
											</label>
											<select
												onChange={this.onSelectSessionChange}
												class="custom-select"
												name="staffId"
												style={{ borderColor: '#01AC8A' }}
											>
												<option selected={this.state.msg ? true : false}>
													Choose Session...
												</option>
												{session &&
													session.map((item, index) => (
														<option key={index} value={`${item.sessionId}`}>
															{item.currentSession}
														</option>
													))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Subject Name
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onSelectSubjectChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Subject Name...
												</option>
												{this.state.Subject.map((item, index) => (
													<option key={index} value={`${item.subjectId}`}>
														{item.subjectName}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
								<br />
								<br />
								<div class="text-center text-md-center">
									<button type="submit" class="btns">
										Add
										<span
											style={{ marginBottom: 5 }}
											class={`${
												this.state.isLoading
													? 'spinner-border spinner-border-sm'
													: ''
											}`}
										></span>
									</button>
								</div>
							</form>

							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		QuizInfoAction: bindActionCreators(QuizInfoAction, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectData: state.setQuizInfoReducer.select,
		selectSession: state.setQuizInfoReducer.sessionSelect
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddQuizInfo);
