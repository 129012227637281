import React, { Component } from 'react';
import './Form.css';

class StudentDocuments extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { studentDocs } = this.props;
		console.log(studentDocs, 'Student Docs Ka Data');
		return (
			<div>
				<section class="mb-4">
					<div class="row print">
						<div class="col-md-12">
							<div class="md-form mb-0">
								<h2
									style={{
										backgroundColor: '#0A4F5E',
										textAlign: 'center',
										color: 'white'
									}}
								>
									Student Documents:
								</h2>
							</div>
						</div>
					</div>
					<div class="container">
						<div class="row rowCheck" style={{ marginTop: 10 }}>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>
									Date Of Birth Certificate:
								</label>
								&nbsp;&nbsp;{studentDocs && studentDocs.dobCertificates}{' '}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>
									Transfer Certificate:
								</label>{' '}
								&nbsp;&nbsp;{studentDocs && studentDocs.transferCertificates}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Medical History:</label>
								&nbsp;&nbsp;{studentDocs && studentDocs.medicalHistorys}{' '}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>
									Ministory Vacination:
								</label>
								&nbsp;&nbsp;{studentDocs && studentDocs.ministryVacinations}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Passport Copy:</label>
								&nbsp;&nbsp;{studentDocs && studentDocs.passportCopys}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Id Card Copy:</label>
								&nbsp;&nbsp;{studentDocs && studentDocs.idCardCopys}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Last Result:</label>
								&nbsp;&nbsp;{studentDocs && studentDocs.lastResults}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Test Result:</label>
								&nbsp;&nbsp;{studentDocs && studentDocs.testResults}
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default StudentDocuments;
