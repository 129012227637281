import React, { Component } from 'react';

class TermClassSubjectSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const {
			ClassData,
			SessionData,
			SectionData,
			SubjectData,
			onHandleChange,
			onChangeDrop,
			onSectionDrop,
			onSubjectDrop,
			flag,
			onSessionDrop
		} = this.props;
		return (
			<div>
				<br />
				<div class="container">
					<div class="row" style={{ justifyContent: 'center' }}>
						<div class="col-md-12">
							<div class="md-form mb-0">
								{/* <label for="name" class="">
									Term
								</label> */}
								<select
									onChange={e => onHandleChange(e)}
									class="custom-select"
									name="term"
								>
									<option selected={flag ? true : false}>Select Term..</option>
									<option value="1st">1st Term</option>
									<option value="2nd">2nd Term</option>
									<option value="3rd">3rd Term</option>
								</select>
							</div>
							<br />
						</div>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Class Name
								</label> */}
								<select
									onChange={e => onChangeDrop(e)}
									class="custom-select"
									name="className"
								>
									<option selected={flag ? true : false}>
										Select Class...
									</option>
									{ClassData &&
										ClassData.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.className}
											</option>
										))}
								</select>
							</div>
						</div>

						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Section
								</label> */}
								<select
									onChange={e => onSectionDrop(e)}
									class="custom-select"
									name="className"
								>
									<option selected={flag ? true : false}>
										Select Section...
									</option>
									{SectionData &&
										SectionData.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.section}
											</option>
										))}
								</select>
							</div>
						</div>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Subject
								</label> */}
								<select
									onChange={e => onSubjectDrop(e)}
									class="custom-select"
									name="className"
								>
									<option selected={flag ? true : false}>
										Select Subject...
									</option>
									{SubjectData &&
										SubjectData.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.subjectName}
											</option>
										))}
								</select>
							</div>
						</div>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Session
								</label> */}
								<select
									onChange={e => onSessionDrop(e)}
									class="custom-select"
									name="sessionId"
								>
									<option selected={flag ? true : false}>
										Select Session...
									</option>
									{SessionData &&
										SessionData.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.currentSession}
											</option>
										))}
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TermClassSubjectSelect;
