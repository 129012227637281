import React from 'react';

function Year(props) {
	const { onMonthChange } = props;
	const year = new Date().getFullYear();
	const years = Array.from(new Array(30), (val, index) => index + year);
	return (
		<div>
			<select
				class="custom-select"
				onChange={e => onMonthChange(e)}
				name="year"
			>
				<option>Select year..</option>
				{years.map((year, index) => {
					return (
						<option key={`year${index}`} value={year}>
							{year}
						</option>
					);
				})}
			</select>
		</div>
	);
}
export default Year;
