import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';

class AdministrationDashboard extends Component {
	state = {};
	componentDidMount() {
		$(document).click(function (e) {
			if (!$(e.target).is('.panel-body')) {
				$('.collapse').collapse('hide');
			}
		});
	}
	render() {
		return (
			<div>
				<div
					class="modal"
					id="myModalCampus"
					style={{
						justifyContent: 'center',
						textAlign: 'center',
						marginTop: 50
					}}
				>
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 0,
									borderBottomRightRadius: 0,
									borderTopLeftRadius: 0,
									borderTopRightRadius: 0,
									textAlign: 'center',
									justifyContent: 'center'
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Campus Module's
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddCampus">
												<button
													type="button"
													class="btn btn-dark"
													data-dismiss="modal"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Campus
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewCampus">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Campus
												</button>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					class="modal"
					id="myModalInventory"
					style={{
						textAlign: 'center',
						justifyContent: 'center',
						margin: 'auto'
					}}
				>
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 0,
									borderBottomRightRadius: 0,
									borderTopLeftRadius: 0,
									borderTopRightRadius: 0,
									textAlign: 'center',
									justifyContent: 'center'
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Inventory Module's
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Category</h3>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddCategory">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Category
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewCategory">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Category
												</button>
											</Link>
										</div>
									</div>
								</div>
								<br />
								<div
									class="row"
									style={{ textAlign: 'center', justifyContent: 'center' }}
								>
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Item's</h3>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddItems">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Item
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewItems">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Item
												</button>
											</Link>
										</div>
									</div>
								</div>
								<br />
								<div
									class="row"
									style={{ textAlign: 'center', justifyContent: 'center' }}
								>
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Suppliers's</h3>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddSuppliers">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Suppliers
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewSuppliers">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Suppliers
												</button>
											</Link>
										</div>
									</div>
								</div>
								<br />
								<div
									class="row"
									style={{ textAlign: 'center', justifyContent: 'center' }}
								>
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Stock</h3>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddStock">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Stock
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewStock">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Stock
												</button>
											</Link>
										</div>
									</div>
								</div>
								<br />
								<div
									class="row"
									style={{ textAlign: 'center', justifyContent: 'center' }}
								>
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Stock Usage</h3>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddStockUsage">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Stock Usage
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewStockUsage">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Stock Usage
												</button>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					class="modal"
					id="myModalTransport"
					style={{
						textAlign: 'center',
						justifyContent: 'center',
						margin: 'auto'
					}}
				>
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 0,
									borderBottomRightRadius: 0,
									borderTopLeftRadius: 0,
									borderTopRightRadius: 0,
									textAlign: 'center',
									justifyContent: 'center'
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Transport Module's
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Route</h3>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddRoute">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Route
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewRoute">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Route
												</button>
											</Link>
										</div>
									</div>
								</div>
								<br />
								<div
									class="row"
									style={{ textAlign: 'center', justifyContent: 'center' }}
								>
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Vehicle</h3>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddVehicle">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Vehicle
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewVehicle">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Vehicle
												</button>
											</Link>
										</div>
									</div>
								</div>
								<br />
								<div
									class="row"
									style={{ textAlign: 'center', justifyContent: 'center' }}
								>
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Driver</h3>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddDriver">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Driver
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewDriver">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Driver
												</button>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					class="modal"
					id="myModalCanteen"
					style={{
						textAlign: 'center',
						justifyContent: 'center',
						margin: 'auto'
					}}
				>
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 0,
									borderBottomRightRadius: 0,
									borderTopLeftRadius: 0,
									borderTopRightRadius: 0,
									textAlign: 'center',
									justifyContent: 'center'
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Canteen Module's
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Canteen</h3>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddCanteen">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Canteen
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewCanteen">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Canteen
												</button>
											</Link>
										</div>
									</div>
								</div>
								<br />
								<div
									class="row"
									style={{ textAlign: 'center', justifyContent: 'center' }}
								>
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Item's Category</h3>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddCanteenCategory">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 12,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Canteen Category
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewCanteenCategory">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 12,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Canteen Category
												</button>
											</Link>
										</div>
									</div>
								</div>
								<br />
								<div
									class="row"
									style={{ textAlign: 'center', justifyContent: 'center' }}
								>
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Canteen Item's</h3>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AddCanteenItems">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 12,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Add Canteen Items
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ViewCanteenItems">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 12,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													View Canteen Items
												</button>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					class="modal"
					id="myModalCensus"
					style={{
						textAlign: 'center',
						justifyContent: 'center',
						margin: 'auto'
					}}
				>
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 0,
									borderBottomRightRadius: 0,
									borderTopLeftRadius: 0,
									borderTopRightRadius: 0,
									textAlign: 'center',
									justifyContent: 'center'
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Census Module's
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<h3>Searches</h3>
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/SearchStudent">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Search Student
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/ClassWiseSearch">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Class Wise
												</button>
											</Link>
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/AgeWiseSearch">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Age Wise
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/CategoryWiseSearch">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Category Wise
												</button>
											</Link>
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/UserNameWise">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													User Name Wise
												</button>
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/FamilyWiseSearch">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 15,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Family Wise
												</button>
											</Link>
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<Link to="/dashboard/StudentResultStatus">
												<button
													type="button"
													class="btn btn-dark"
													style={{
														width: 160,
														height: 40,
														fontSize: 12,
														borderRadius: 5
													}}
												>
													<i
														style={{ marginRight: 8 }}
														className="fas fa-list animated fadeIn"
													/>
													Student Result Status
												</button>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section class="mb-4">
					<div class="h1-responsive font-weight-bold text-center my-4">
						<img
							src={require('../Assets/images/Administration-03.svg')}
							style={{ width: 400, height: 60 }}
						/>
					</div>

					<div
						class="row"
						style={{ justifyContent: 'center', textAlign: 'center' }}
					>
						<div class="col-md-8">
							<div class="md-form mb-0">
								<hr class="style-head" />
							</div>
						</div>
					</div>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{ textAlign: 'center', justifyContent: 'center' }}
					>
						<div class="col-md-9 mb-md-0 mb-5">
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/InventoryDashboard">
												<img
													src={require('../Assets/AdministrationDashboard/humza icon new-02.svg')}
													style={{ width: 150, height: 160 }}
												/>
											</Link>
										</div>
									</div>

									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/CampusDashboard">
												<img
													src={require('../Assets/AdministrationDashboard/humza icon new-01.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>

									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/CanteenDashboard">
												<img
													src={require('../Assets/AdministrationDashboard/humza icon new-03.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/TransportDashboard">
												<img
													src={require('../Assets/AdministrationDashboard/humza icon new-04.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>

									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/CensusDashboard">
												<img
													src={require('../Assets/AdministrationDashboard/humza icon new-05.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>

									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/StudentAdmissionDashboard">
												<img
													src={require('../Assets/AdministrationDashboard/humza icon new-06.svg')}
													width="160"
													height="160"
												/>
											</Link>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/AdministrationManagers">
												<img
													src={require('../Assets/AdministrationDashboard/managers-07.png')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/IndentDashboard">
												<img
													src={require('../Assets/AdministrationDashboard/procurement.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/CampusSecurityDashboard">
												<img
													src={require('../Assets/AdministrationDashboard/humza icon new-01.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>
								</div>							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default AdministrationDashboard;
