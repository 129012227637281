import React from 'react';
import ViewRendering from '../../../components/ViewRendering';
import { renderingStuff, images } from '../../../utils';

function AcademicLMS(props) {
	return (
		<div>
			<ViewRendering
				centered={true}
				centerWidth={'70%'}
				mainHeading={images.imgAcHead}
				data={renderingStuff.academicLmsLink}
				mainHead={true}
			/>
		</div>
	);
}

export default AcademicLMS;
