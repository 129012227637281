import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import TextArea from '../../components/TextArea'
import TextInput from '../../components/TextInput/TextInput'
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../Actions/sharedActions';
import Button from '../../components/Button';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';

class AddCertificateCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            accountId: props.name,
            accountType: props.role,
            errors: {},
            errorMsg: false,
            fewDetail: '',
            certificateTitle: '',
            largeDetail: ''
        }
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false
                });
            }, 3000);
        }
    }

    onHandleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { certificateTitle, fewDetail, largeDetail, } = this.state;

        if (
            Object.is(certificateTitle, '') ||
            Object.is(fewDetail, '') ||
            Object.is(largeDetail, '')) {
            this.setState({
                error: true,
                errorMsg: true
            })
        }
        else {
            this.setState({
                isLoading: true
            })
            const data = {
                certificateTitle,
                fewDetail,
                largeDetail,
            }
            console.log(data);
            this.props.sharedActions
                .simpleAddRequest('/api/CertificateCategory/',
                    data)
                .then(success => {
                    this.setState({
                        msg: 'Added Certificate Category Successfully',
                        isLoading: false,
                        errorMsg: false,
                        error: false,
                        certificateTitle: '',
                        fewDetail: '',
                        largeDetail: ''
                    })
                    this.props.snackbar();
                })
        }

    }
    render() {
        const { errors, } = this.state;

        const Form = (
            <FormLayoutWrapper
                formName='Add Certificate'
                borderColor='#01ac8a'
                borderRadius={15}
            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly
                    </p>
                ) : (
                        ''
                    )}


                <div className="row">
                    <TextInput
                        feildName={'accountId'}
                        inputLabelName={'Admin Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Admin Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        feildName={'accountType'}
                        inputLabelName={'Account Type'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Account Type"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'certificateTitle'}
                        inputLabelName={'Certificate Title'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        placeholder={'Certificate Title'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'fewDetail'}
                        inputLabelName={'Few Detail'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        placeholder={'Few Detail'}
                        isValidte={false}
                    />
                    <TextArea
                        feildName={'largeDetail'}
                        inputLabelName={'Large Detail'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Large Detail'}
                        isValidte={false}
                    />

                </div>
                <br />
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Add"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={20}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                    textColor={'white'}
                />

            </FormLayoutWrapper>
        )
        return (
            <React.Fragment>
                <SnackBar msg={this.state.msg} />
                {Form}
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCertificateCategory);