import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';
import { connect } from 'react-redux';

class AddInterview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			AdminName: '',
			AdminType: '',
			ApplicantName: '',
			VacancyName: '',
			Status: ''
		};
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		console.log(this.state);
	};
	render() {
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Interview
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-user-shield animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												readOnly={true}
												value={this.props.name}
												type="text"
												name="AdminName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-user-alt animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												readOnly={true}
												value={this.props.role}
												type="text"
												name="AdminType"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-file animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
												Applicant Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Applicant Name"
												type="text"
												name="ApplicantName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-file-alt animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
												Vacancy Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Vacancy Name"
												type="text"
												name="VacancyName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-info-circle animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
												Status
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Status"
												type="text"
												name="Status"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
								</div>
								<div class="text-center text-md-center">
									<br />
									<br />
									<button onClick={this.onHandleSubmit} class="btns">
										Add
									</button>
								</div>
							</form>

							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
export default connect(
	mapStateToProps,
	null
)(AddInterview);
