import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StudentEnquiryActions from '../../Actions/StudentEnquiry';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';

import $ from 'jquery';
import TableComponent from './TableComponent';
class ViewStudentEnquiryPagination extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			vehicleNo: '',
			capacity: '',
			transportType: '',
			enabled: false,
			id: null,
			select: [],
			routeId: '',
			routeName: '',
			startingServiceKm: '',
			midServiceKm: '',
			endingServiceKm: '',
			vehicleDateOfReg: '',
			vehicleDateOfRegExpiry: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			increament: 1,
			selectClassData: '',
			classId: '',
			sectionId: '',
			sections: [],
			btnLoad: false,
			checking: []
		};
	}
	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
			this.setState({
				disablepage: false
			});
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.adminData !== nextProps.AllTransportData ||
			prevState.selectClassData !== nextProps.selectData
		) {
			return {
				adminData: nextProps.AllTransportData,
				selectClassData: nextProps.selectData
			};
		}
		return null;
	}
	onSelectChange = e => {
		console.log(e.target.value);

		const sections = this.state.selectClassData.section;
		let check = sections.filter(item => item.classId == e.target.value);
		console.log(check);

		this.setState({
			sections: check,
			classId: e.target.value
		});
	};
	onSectionChange = e => {
		console.log(e.target.value);
		this.setState({
			sectionId: e.target.value
		});
	};
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	componentDidMount() {
		$(document).ready(function () {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.gAllData(
				'/api/classSection',
				this.props.StudentEnquiryActions.getClassesForStudentSearchEnquiry
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		// this.props.sharedActions.getAllData(`/api/EnquiryForm/?classId=4&sectionId=1004&pageNumber=1&pageSize=5`, this.props.StudentEnquiryActions.setAllStudentEnquiryInRedux, this.props.StudentEnquiryActions.updateStudentEnquiryFlag)
		//     .then((success) => {
		//
		//         this.setState({
		//             isLoading: false,
		//             checking: this.state.adminData.paginationMetadata
		//         })
		//     }).catch((err) => {
		//         this.props.StudentEnquiryActions.updateStudentEnquiryFlag(false);

		//         this.setState({
		//             isLoading: false
		//         });
		//
		//         console.log(err);
		//     })
	}
	onSearchStudent = e => {
		this.setState({
			btnLoad: true
		});
		this.props.sharedActions
			.getAllData(
				`/api/EnquiryForm/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&pageNumber=1&pageSize=1`,
				this.props.StudentEnquiryActions.setAllStudentEnquiryInRedux,
				this.props.StudentEnquiryActions.updateStudentEnquiryFlag
			)
			.then(success => {
				this.setState({
					isLoading: false,
					btnLoad: false,
					checking: this.state.adminData.paginationMetadata
				});
			})
			.catch(err => {
				this.props.StudentEnquiryActions.updateStudentEnquiryFlag(false);

				this.setState({
					isLoading: false,
					btnLoad: false
				});

				console.log(err);
			});
	};
	btnTechHover = () => {
		$(document).ready(function () {
			$('button').hover(
				function () {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function () {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/Vehicle/',
				itemId,
				this.props.routesActions.deleteVehicle
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => { });
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.id === itemId);

		this.setState({
			modalData: getData,
			transportType: getData[0].transportType,
			capacity: getData[0].capacity,
			vehicleNo: getData[0].vehicleNo,
			id: getData[0].id,
			routeName: getData[0].dropOff,
			routeId: getData[0].routeId,
			startingServiceKm: getData[0].startingServiceKm,
			midServiceKm: getData[0].midServiceKm,
			endingServiceKm: getData[0].endingServiceKm,
			vehicleDateOfReg: getData[0].vehicleDateOfReg,
			vehicleDateOfRegExpiry: getData[0].vehicleDateOfRegExpiry
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const {
			id,
			capacity,
			transportType,
			vehicleNo,
			routeId,
			routeName,
			startingServiceKm,
			midServiceKm,
			endingServiceKm,
			vehicleDateOfReg,
			vehicleDateOfRegExpiry
		} = this.state;
		var bodyParameters = {
			id,
			capacity,
			transportType,
			vehicleNo,
			routeId,
			routeName,
			startingServiceKm,
			midServiceKm,
			endingServiceKm,
			vehicleDateOfReg,
			vehicleDateOfRegExpiry
		};
		this.props.sharedActions
			.editRecord(
				'/api/Vehicle/',
				id,
				bodyParameters,
				this.props.routesActions.updateVehicle
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				this.props.snackbar();
			})
			.catch(err => { });
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function () {
			x.className = x.className.replace('show', '');
		}, 3000);
	};
	paginationRequest = number => {
		console.log(number);

		this.props.sharedActions
			.getAllData(
				`/api/EnquiryForm/?classId=${this.state.classId}&sectionId=1004&pageNumber=${number}&pageSize=1`,
				this.props.StudentEnquiryActions.setAllStudentEnquiryInRedux,
				this.props.StudentEnquiryActions.updateStudentEnquiryFlag
			)
			.then(success => {
				this.setState({
					isLoading: false
				});
			})
			.catch(err => {
				this.props.StudentEnquiryActions.updateStudentEnquiryFlag(false);

				this.setState({
					isLoading: false
				});

				console.log(err);
			});
	};
	onIncreament = e => {
		this.setState({
			increament: this.state.increament + 1
		});
		this.paginationRequest();
	};
	onDecreament = e => {
		this.setState({
			increament: this.state.increament - 1
		});
		this.paginationRequest();
	};
	check = e => {
		console.log('increament', this.state.count);
	};
	render() {
		const { adminData, modalData, select } = this.state;
		console.log('Admin Data', this.state.adminData);
		console.log('Classes Ka Data', this.state.selectClassData);
		const { classList } = this.state.selectClassData;
		const { studentEnuiry } = this.state.adminData;
		const { paginationMetadata } = this.state.adminData;
		console.log('Pagination Ka Data', paginationMetadata);
		console.log('Pagination Checking Ka Data', this.state.checking);

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<label style={{ color: 'red' }}>No Data</label>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <=
			Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return (
				<li
					class={`page-item ${
						this.state.checking.currentPage === number ? 'active' : ''
						} `}
				>
					<a
						key={number}
						id={number}
						onClick={() => this.paginationRequest(number)}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			);
		});
		// const { currentPage, todosPerPage } = this.state;
		// const indexOfLastTodo = currentPage * todosPerPage;
		// const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		// const currentTodos = studentEnuiry.slice(indexOfFirstTodo, indexOfLastTodo);
		// const renderTodos = studentEnuiry.map((item, index) => {
		//     return <tr key={index} >
		//         <th scope="row">{index + 1}</th>
		//         <td >{item.pickUp}</td>
		//         <td  >{item.dropOff}</td>
		//         <td  >{item.vehicleNo}</td>
		//         <td  >{item.transportType}</td>
		//         <td >{item.capacity}</td>
		//         <td >{item.startingServiceKm}</td>
		//         <td >{item.midServiceKm}</td>
		//         <td >{item.endingServiceKm}</td>

		//         <td  >
		//             <a href="#" data-toggle="tooltip" title="Edit!">
		//                 <button id='firstbutton' onClick={() => this.onEditClick(item.id)} onMouseOver={this.btnTechHover()} data-toggle="modal" data-target="#myModal" style={{ cursor: 'pointer', fontSize: 20, border: 'none', marginLeft: 5, background: 'none', marginBottom: 5 }}  >
		//                     <i class="fas fa-pen"></i>
		//                 </button>
		//             </a>
		//             <a href="#" data-toggle="tooltip" title="Delete!">

		//                 <button onClick={() => this.onEditClick(item.id)} data-toggle="modal" data-target="#myModal1" style={{ cursor: 'pointer', fontSize: 20, border: 'none', background: 'none', marginLeft: 5, marginBottom: 5 }} >
		//                     <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
		//                 </button>
		//             </a>

		//         </td>
		//     </tr>
		// });
		const MainContent = (
			<div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<form onSubmit={this.onHandleSubmit}>
									{modalData &&
										modalData.map((item, index) => (
											<div key={index}>
												<fieldset disabled={!this.state.enabled}>
													<div class="form-group">
														<label for="transportType">Transport Type:</label>
														<input
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.transportType}
															onChange={this.onHandleText}
															name="transportType"
															class="form-control"
															id="transportType"
														/>
													</div>
													<div class="form-group">
														<label for="capacity">Capacity</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.capacity}
															onChange={this.onHandleText}
															type="text"
															name="capacity"
															class="form-control"
															id="capacity"
														/>
													</div>
													<div class="form-group">
														<label for="vehicleNo">Vehicle Registration</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.vehicleNo}
															onChange={this.onHandleText}
															type="text"
															name="vehicleNo"
															class="form-control"
															id="vehicleNo"
														/>
													</div>
													<div class="form-group">
														<label for="vehicleNo">Starting Service KM</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.startingServiceKm}
															onChange={this.onHandleText}
															type="text"
															name="startingServiceKm"
															class="form-control"
															id="startingServiceKm"
														/>
													</div>
													<div class="form-group">
														<label for="vehicleNo">Mid Service KM</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.midServiceKm}
															onChange={this.onHandleText}
															type="text"
															name="midServiceKm"
															class="form-control"
															id="midServiceKm"
														/>
													</div>
													<div class="form-group">
														<label for="vehicleNo">Ending Service KM</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.endingServiceKm}
															onChange={this.onHandleText}
															type="text"
															name="endingServiceKm"
															class="form-control"
															id="endingServiceKm"
														/>
													</div>
													<div class="form-group">
														<label for="canteenName">Route Name:</label>
														<input
															readOnly={true}
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.routeName}
															onChange={this.onHandleText}
															name="routeName"
															class="form-control"
															id="routeName"
														/>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="email" class="">
																<i
																	style={{ marginRight: 4 }}
																	className="fas fa-university animated fadeIn"
																/>
																Route Name
															</label>
															<select
																onChange={this.onSelectChange}
																class="custom-select"
																name="select"
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	Choose Root...
																</option>
																{select.map((item, index) => (
																	<option
																		key={index}
																		value={`${JSON.stringify(item)}`}
																	>
																		{item.dropOff}
																	</option>
																))}
															</select>
														</div>
													</div>
												</fieldset>
												<br />
												<br />
												<button
													disabled={this.state.enabled}
													onClick={() => this.setState({ enabled: true })}
													type="button"
													class="btn btn-primary buttonAppear"
												>
													Edit
												</button>
												<button
													style={{ marginLeft: 5 }}
													disabled={!this.state.enabled}
													type="submit"
													class="btn btn-primary buttonAppear"
												>
													Save
												</button>
											</div>
										))}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="table-responsive">
					<table class="table table-hover" style={{ textAlign: 'center' }}>
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Applicant Name</th>
								<th scope="col">Class Name</th>
								<th scope="col">Section</th>
								<th scope="col">Status</th>
								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>
							<TableComponent data={studentEnuiry} />
						</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Student Enquiry Details</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								{renderPageNumbers}
							</ul>
						</nav>
					</div>
				</div>
				<div class="row" style={{ justifyContent: 'center' }}>
					<div class="col-md-4" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-map animated fadeIn"
								/>
								Class Name
							</label>
							<select
								onChange={this.onSelectChange}
								class="custom-select"
								name="className"
							>
								<option selected={this.state.msg ? true : false}>
									Choose Class...
								</option>
								{classList &&
									classList.map((item, index) => (
										<option key={index} value={`${item.id}`}>
											{item.className}
										</option>
									))}
							</select>
						</div>
					</div>

					<div class="col-md-4" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-map animated fadeIn"
								/>
								Section
							</label>
							<select
								onChange={this.onSectionChange}
								class="custom-select"
								name="className"
							>
								<option selected={this.state.msg ? true : false}>
									Choose Section...
								</option>
								{this.state.sections.map((item, index) => (
									<option key={index} value={`${item.sectionId}`}>
										{item.section}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
				<div class="text-center text-md-right">
					<button type="submit" class="btnss" onClick={this.onSearchStudent}>
						Search
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''
								}`}
						></span>
					</button>
				</div>
				<br />
				{this.props.allTransportState.apiFlag ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		StudentEnquiryActions: bindActionCreators(StudentEnquiryActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllTransportData: state.setStudentEnquiryReducer.Data,
		selectData: state.setStudentEnquiryReducer.selectClass,
		allTransportState: state.setStudentEnquiryReducer,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewStudentEnquiryPagination);
