import React, { Component } from 'react';
import * as ParentActions from '../../Actions/Parents';
import * as SharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';

class AlreadyRegister extends Component {
	constructor(props) {
		super(props);
		this.state = {
			parentData: [],
			studentFilterData: [],
			sections: [],
			students: [],
			classId: '',
			sectionId: '',
			studentId: '',
			name: '',
			userName: '',
			cnic: '',
			password: '',
			msg: '',
			errorMsg: '',
			errorText: '',
			deleteFlag: false,
			updateFlag: false,
			formLoading: true,
			btnLoad: false
		};
	}

	componentDidMount() {
		// this.props.SharedActions.getDataWithoutDispatch(`/api/Student`)
		// 	.then(success => {
		// 		this.setState({
		// 			studentFilterData: success
		// 		});
		// 		console.log(success);
		// 	})
		// 	.catch(error => {
		// 		console.log(error);
		// 	});
	}

	onClassChange = e => {
		console.log(e.target.value);
		const Sections = this.state.studentFilterData.sections;
		let filtering = Sections.filter(item => item.classId == e.target.value);
		console.log(filtering);

		this.setState({ classId: e.target.value, sections: filtering });
	};

	onSectionChange = e => {
		console.log(e.target.value);
		const Subjects = this.state.studentFilterData.student;
		let studentFiltering = Subjects.filter(
			item =>
				item.classId == this.state.classId && item.sectionId == e.target.value
		);
		this.setState({ sectionId: e.target.value, students: studentFiltering });
	};

	onSearchParent = e => {
		e.preventDefault();
		this.setState({
			btnLoad: true
		});

		this.props.SharedActions.getDataWithoutDispatch(
			`/api/Parent/?cnic=${this.state.cnic}`
		)
			.then(success => {
				this.setState({
					parentData: success,
					name: success.name,
					password: success.password,
					userName: success.userName,
					formLoading: false,
					btnLoad: false
				});
				console.log(success);
			})
			.catch(error => {
				this.setState({
					errorText: error.response.data.Message,
					badError: true,
					formLoading: true,
					btnLoad: false
				});
				console.log(error);
			});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const { classId, sectionId, studentId } = this.state;
		if (
			Object.is(classId, '') ||
			Object.is(sectionId, '') ||
			Object.is(studentId, '')
		) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			var bodyParameters = {
				id: this.state.parentData.id,
				studentId,
				accountId: this.props.userData.unique_name,
				accountType: this.props.userData.role
			};
			this.props.SharedActions.addRecord(
				`/api/AlreadyRegistered`,
				bodyParameters,
				this.props.ParentActions.addParent
			)
				.then(success => {
					this.props.ParentActions.updateParentFlag(true);
					this.setState({
						userName: '',
						classId: '',
						sectionId: '',
						studentId: '',
						msg: true,
						errorMsg: false,
						formLoading: true
					});
				})
				.catch(error => {
					debugger;
					this.setState({
						errorText: error.response.data.Message,
						badError: true
					});
					console.log(error);
				});
		}
		console.log(this.state);
	};
	HideUnHide = e => {
		$('.toggle-password').click(function() {
			$(this).toggleClass('fa-eye fa-eye-slash');
			var input = $($(this).attr('toggle'));
			if (input.attr('type') == 'password') {
				input.attr('type', 'text');
			} else {
				input.attr('type', 'password');
			}
		});
	};

	componentDidUpdate() {
		if (
			this.state.msg ||
			this.state.errorMsg ||
			this.state.badError ||
			this.state.updateFlag ||
			this.state.deleteFlag
		) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					badError: false,
					updateFlag: false,
					deleteFlag: false
				});
			}, 3000);
		}
	}

	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onDelete = e => {
		e.preventDefault();
		this.props.SharedActions.deleteRecordWithoutDispatch(
			'/api/Parent/',
			this.state.parentData.id
		)
			.then(success => {
				this.setState({
					formLoading: true,
					deleteFlag: true,
					cnic: ''
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onUpdate = e => {
		e.preventDefault();
		const { name, password } = this.state;
		if (Object.is(name, '') || Object.is(password, '')) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			var bodyParameters = {
				name,
				password
			};
			this.props.SharedActions.editPatchRecordWithoutDispatch(
				`/api/Parent/`,
				this.state.parentData.id,
				bodyParameters
			)
				.then(success => {
					this.setState({
						cnic: '',
						updateFlag: true,
						errorMsg: false,
						formLoading: true,
						updateMsg: success.data
					});
				})
				.catch(error => {
					debugger;
					this.setState({
						errorText: error.response.data.Message,
						badError: true
					});
					console.log(error);
				});
		}
		console.log(this.state);
	};
	render() {
		const { classes } = this.state.studentFilterData;
		const { formLoading, parentData } = this.state;

		const FormLoading = (
			<div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
				<p>No Data</p>
			</div>
		);

		const FormReady = (
			<div style={{ marginTop: -30 }}>
				<div>
					<section class="mb-4">
						<p class="text-center w-responsive mx-auto mb-5" />

						<div
							class="row"
							style={{
								justifyContent: 'center',
								textAlign: 'center'
							}}
						>
							<div
								class="col-md-9 mb-md-0 mb-5"
								style={{
									border: 'solid',
									borderColor: '#01ac8a',
									padding: 40,
									borderRadius: 15,
									width: 'auto'
								}}
							>
								{this.Message(
									'Please fill the form properly!',
									'danger',
									20,
									this.state.errorMsg
								)}
								<form
									id="contact-form"
									name="contact-form"
									onSubmit={this.onHandleSubmit}
								>
									<div class="row">
										<div class="col-md-12">
											<div class="md-form mb-0">
												<DateandTime />
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													Admin Name
												</label>
												<input
													readOnly={true}
													value={this.props.userData.unique_name}
													type="text"
													name="AdminName"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													Admin Type
												</label>
												<input
													readOnly={true}
													value={this.props.userData.role}
													type="text"
													name="AdminType"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													User Name
												</label>
												<input
													readOnly={true}
													value={this.state.userName}
													type="text"
													name="userName"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													Parent Name
												</label>
												<input
													value={this.state.name}
													type="text"
													name="name"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													Password
													<span
														toggle="#password-field"
														class="fa fa-fw fa-eye field-icon toggle-password"
														onClick={this.HideUnHide}
													></span>
												</label>
												<input
													id="password-field"
													type="password"
													class="form-control"
													name="password"
													onChange={this.onHandleTextChange}
													value={this.state.password}
												/>
											</div>
										</div>
									</div>
									<br />
									<div class="text-center text-md-center">
										<button
											class="btn"
											style={{
												backgroundColor: '#01ac8a',
												color: 'white',
												width: 100,
												marginRight: 10
											}}
											onClick={this.onUpdate}
										>
											Update
										</button>

										<button
											style={{
												backgroundColor: '#01ac8a',
												color: 'white',
												width: 100,
												marginLeft: 10
											}}
											onClick={this.onDelete}
											class="btn"
										>
											Delete
										</button>
									</div>
								</form>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
		return (
			<React.Fragment>
				<h3 class="h1-responsive font-weight-bold text-center my-4">
					Update Parent Registration
				</h3>
				{this.state.badError ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						{this.state.errorText}
					</p>
				) : (
					''
				)}
				{this.state.updateFlag ? (
					<p style={{ color: 'green', textAlign: 'center' }}>
						Update Successfully
					</p>
				) : (
					''
				)}
				{this.state.deleteFlag ? (
					<p style={{ color: 'green', textAlign: 'center' }}>
						Delete Successfully
					</p>
				) : (
					''
				)}
				{this.state.msg ? (
					<p style={{ color: 'green', textAlign: 'center' }}>
						Sibling Registered Successfully
					</p>
				) : (
					''
				)}
				<div class="row text-center" style={{ justifyContent: 'center' }}>
					<div class="col-md-6" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<div class="input-group">
								<input
									type="text"
									class="form-control"
									placeholder="Enter parent CNIC # e.g : 00000-0000000-0"
									name="cnic"
									value={this.state.cnic}
									onChange={this.onHandleTextChange}
								/>
								<div class="input-group-append">
									<button
										class="btn"
										onClick={this.onSearchParent}
										style={{ backgroundColor: '#01ac8a' }}
										type="button"
									>
										<span
											style={{ marginBottom: 5 }}
											class={`${
												this.state.btnLoad
													? 'spinner-border spinner-border-sm'
													: 'fas fa-search'
											}`}
										></span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<br />
				{formLoading ? FormLoading : FormReady}
			</React.Fragment>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		SharedActions: bindActionCreators(SharedActions, dispatch),
		ParentActions: bindActionCreators(ParentActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AlreadyRegister);
