import React, { Component } from 'react';
import DateandTime from './DateandTime';
import Axios from 'axios';
import Script from 'react-load-script';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import * as routesActions from '../../Actions/Transport';

class AddRoute extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pickUp: '',
			dropOff: '',
			km: '',
			totalKm: '',
			estimatedTime: '',
			perKmFare: '',
			fare: '',
			check: '',
			Data: [],
			errorMsg: false,
			msg: false
		};
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleTextChange2 = e => {
		console.log(e.target.value);
		let x = Number(e.target.value) * Number(this.state.totalKm);
		this.setState({
			[e.target.name]: e.target.value,
			fare: x
		});
	};

	handleScriptLoad = e => {
		var options = {
			types: ['establishment']
		}; // To disable any eslint 'google not defined' errors

		// Initialize Google Autocomplete
		/*global google*/ this.autocomplete = new google.maps.places.Autocomplete(
			document.getElementById('autocomplete'),
			options
		);

		this.autocomplete1 = new google.maps.places.Autocomplete(
			document.getElementById('autocomplete1'),
			options
		);

		// Fire Event when a suggested name is selected
		this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
		this.autocomplete1.addListener('place_changed', this.handlePlaceSelect2);
	};

	handlePlaceSelect = e => {
		// Extract City From Address Object
		let addressObject = this.autocomplete.getPlace();
		let address = addressObject.address_components;

		// Check if address is valid
		if (address) {
			// Set State
			this.setState({
				// city: address[0].long_name,
				pickUp: addressObject.formatted_address
			});
		}
	};
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	handlePlaceSelect2 = e => {
		// Extract City From Address Object
		let addressObject = this.autocomplete1.getPlace();
		let address = addressObject.address_components;

		// Check if address is valid
		if (address) {
			// Set State
			this.setState({
				// city: address[0].long_name,
				dropOff: addressObject.formatted_address
			});
		}
	};

	getDistance = e => {
		e.preventDefault();
		let token = '';
		if (localStorage.jwtToken) {
			token = localStorage.getItem('jwtToken');
		}
		const API_URL = 'https://embassyapp.linkit.org.uk/api/Map?';
		let params = `origin=${this.state.pickUp}&destination=${this.state.dropOff}`;
		let finalApiURL = `${API_URL}${encodeURI(params)}`;
		console.log(finalApiURL);

		Axios.get(finalApiURL, { headers: { Authorization: `Bearer  ${token}` } })
			.then(success => {
				console.log(success);
				this.setState({
					Data: success.data
				});
				this.setState({
					km: this.state.Data.rows[0].elements[0].distance.text,
					estimatedTime: this.state.Data.rows[0].elements[0].duration.text
				});
				let n = this.state.km.match(/\d+/g).map(Number);
				console.log(n);
				this.setState({
					totalKm: n[0] + n[0]
				});
			})
			.catch(error => {
				console.log(error);
			});
	};

	onHandleSubmit = e => {
		const {
			pickUp,
			dropOff,
			estimatedTime,
			km,
			perKmFare,
			fare,
			totalKm
		} = this.state;
		e.preventDefault();
		if (Object.is(pickUp, '') || Object.is(dropOff, '')) {
			this.setState({
				errorMsg: true
			});
		} else {
			let data = {
				pickUp,
				dropOff,
				totalKm,
				perKmFare,
				estimatedTime,
				fare,
				km,
				date: new Date().toDateString(),
				time: new Date().toTimeString(),
				accountType: this.props.role,
				accountId: this.props.name
			};
			this.props.sharedActions
				.addRecord('/api/Route', data, this.props.routesActions.addTransport)
				.then(success => {
					this.props.routesActions.updateTransportFlag(true);

					this.setState({
						errorMsg: false,
						pickUp: '',
						dropOff: '',
						fare: '',
						km: '',
						perKmFare: '',
						estimatedTime: '',
						totalKm: '',
						msg: true
					});
				})
				.catch(err => {});
		}
		console.log(this.state);
	};

	render() {
		return (
			<div>
				<Script
					url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAPe9ECEQvQ5j4-vXsmHgVAX6WNTqWzCYw&libraries=places"
					onLoad={this.handleScriptLoad}
				/>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Routes
					</h2>
					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.state.errorMsg ? (
								<p style={{ color: 'red' }}>please fill the form properly!</p>
							) : (
								''
							)}
							{this.state.msg ? (
								<p style={{ color: 'green' }}>Route Add Successfully!</p>
							) : (
								''
							)}

							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-user-shield animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												value={this.props.name}
												readOnly={true}
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-user-alt animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												value={this.props.role}
												readOnly={true}
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-route"
                          style={{ marginRight: 4 }}
                        /> */}
												Pick Up
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												id="autocomplete"
												type="text"
												name="pickUp"
												value={this.state.pickUp}
												class="form-control"
												placeholder="Pick Up"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          className="fas fa-money-check-alt"
                          style={{ marginRight: 4 }}
                        /> */}
												Drop Off
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												id="autocomplete1"
												type="text"
												value={this.state.dropOff}
												name="dropOff"
												placeholder="Drop Off"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<br />
									<div class="col-md-12" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<div class="text-center text-md-right">
												<button
													onClick={this.getDistance}
													style={{
														width: 120,
														height: 50
													}}
													class="btns"
												>
													Calculate
												</button>
											</div>
										</div>
									</div>
								</div>
								<br />

								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-user-shield animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
												Kilo Meters
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="km"
												class="form-control"
												value={this.state.km}
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-user-alt animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
												Total Kilo Meters
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="toalKm"
												value={this.state.totalKm}
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-route"
                          style={{ marginRight: 4 }}
                        /> */}
												Estimated Time
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="estimatedTime"
												value={this.state.estimatedTime}
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          className="fas fa-money-check-alt"
                          style={{ marginRight: 4 }}
                        /> */}
												Kilo Meter Fare
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												value={this.state.perKmFare}
												name="perKmFare"
												className="form-control check"
												onChange={this.onHandleTextChange2}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          className="fas fa-money-check-alt"
                          style={{ marginRight: 4 }}
                        /> */}
												Fare
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												style={{ borderColor: '#01AC8A' }}
												type="text"
												value={this.state.fare}
												name="fare"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
								</div>
							</form>
							<br />
							<br />
							<div class="text-center text-md-center">
								<button onClick={this.onHandleSubmit} class="btns">
									Add
								</button>
							</div>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		routesActions: bindActionCreators(routesActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddRoute);
