import React, { Component } from 'react';

class PrintStaffForm extends Component {
	state = {};
	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};
	render() {
		return (
			<div>
				<div id="div1">
					<section class="mb-4" style={{ marginTop: -50 }}>
						<div
							style={{
								justifyContent: 'center',
								textAlign: 'center',
								height: 180
							}}
						>
							<input
								type="image"
								src={require('../../Assets/images/logo1.png')}
								style={{ width: 350, height: 250 }}
							/>
						</div>
						<div style={{ justifyContent: 'center', textAlign: 'center' }}>
							<div class="col-md-12">
								Address: 798 South Park Avenue, Mascat, Oman <br /> Email:
								<a href="mailto:#">schoolsmart@gmail.com</a> <br />
								Phone:
								<a href="tel:#">+91 7568543012</a>
							</div>
						</div>
						<br />
						<br />
						<div class="row">
							<div class="col-md-12">
								<div class="md-form mb-0">
									<hr class="style-five" />
								</div>
							</div>
						</div>

						<p class="text-center w-responsive mx-auto mb-5" />
						<div
							class="row"
							style={{
								justifyContent: 'center'
							}}
						>
							<div
								class="col-md-9 mb-md-0 mb-5"
								style={{
									border: 'solid',
									borderColor: '#01ac8a',
									padding: 40,
									borderRadius: 15,
									width: 'auto'
								}}
							>
								<form id="contact-form" name="contact-form">
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-user animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													First Name:
												</label>
												<label style={{ marginLeft: 6 }}>Sana Ullah</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-user-shield animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Last Name:
												</label>
												<label style={{ marginLeft: 6 }}>Haji</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-restroom animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Gender:
												</label>
												<label style={{ marginLeft: 6 }}>Male</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-male animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Father Name:
												</label>
												<label style={{ marginLeft: 6 }}>Sana Ullah</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-building animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Department Name:
												</label>
												<label style={{ marginLeft: 6 }}>Main</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-male animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Post Name:
												</label>
												<label style={{ marginLeft: 6 }}>Teacher</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-file-signature animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Class Name:
												</label>
												<label style={{ marginLeft: 6 }}>Secondary</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-layer-group animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Section:
												</label>
												<label style={{ marginLeft: 6 }}>Primary</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-clipboard animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Designation:
												</label>
												<label style={{ marginLeft: 6 }}>Teacher</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-hand-holding-heart animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Marital Status:
												</label>
												<label style={{ marginLeft: 6 }}>Married</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-flag animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Nationality:
												</label>
												<label style={{ marginLeft: 6 }}>Omani</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-city animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													City:
												</label>
												<label style={{ marginLeft: 6 }}>Muscat</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-globe animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Country:
												</label>
												<label style={{ marginLeft: 6 }}>Married</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-flag animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													State:
												</label>
												<label style={{ marginLeft: 6 }}>Omani</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-address-book animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Address:
												</label>
												<label style={{ marginLeft: 6 }}>Muscat</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-envelope-open-text animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Account Type:
												</label>
												<label style={{ marginLeft: 6 }}>Active</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-pray animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Religion:
												</label>
												<label style={{ marginLeft: 6 }}>Islam</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-phone animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Mobile No:
												</label>
												<label style={{ marginLeft: 6 }}>03422181129</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-envelope-open-text animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Date Of Birth:
												</label>
												<label style={{ marginLeft: 6 }}>5/28/2019</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-pray animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Age:
												</label>
												<label style={{ marginLeft: 6 }}>12</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-dumbbell animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Experience Letter:
												</label>
												<label style={{ marginLeft: 6 }}>Yes</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-language animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													ILETS Certificate:
												</label>
												<label style={{ marginLeft: 6 }}>Yes</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-archway animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Ministry Approval:
												</label>
												<label style={{ marginLeft: 6 }}>Yes</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-passport animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Passport No:
												</label>
												<label style={{ marginLeft: 6 }}>Yes</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-passport animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Passport DOI:
												</label>
												<label style={{ marginLeft: 6 }}>2/28/2019</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-passport animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Passport DOE:
												</label>
												<label style={{ marginLeft: 6 }}>2/28/2022</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-passport animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Passport Copy:
												</label>
												<label style={{ marginLeft: 6 }}>Yes</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-id-card animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													ID Card No:
												</label>
												<label style={{ marginLeft: 6 }}>4221025658784</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-id-card animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													ID Card DOI:
												</label>
												<label style={{ marginLeft: 6 }}>2/28/2019</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-id-card animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													ID Card DOE:
												</label>
												<label style={{ marginLeft: 6 }}>2/28/2022</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-id-card animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													ID Card Copy:
												</label>
												<label style={{ marginLeft: 6 }}>Yes</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-home animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													House No:
												</label>
												<label style={{ marginLeft: 6 }}>4269</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-passport animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Way No:
												</label>
												<label style={{ marginLeft: 6 }}>14</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-street-view animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Street No:
												</label>
												<label style={{ marginLeft: 6 }}>Yes</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-phone animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Phone No:
												</label>
												<label style={{ marginLeft: 6 }}>Yes</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-building animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Campus Name:
												</label>
												<label style={{ marginLeft: 6 }}>Main</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-envelope-open-text animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													UserName:
												</label>
												<label style={{ marginLeft: 6 }}>Teacher</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-pray animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Password:
												</label>
												<label style={{ marginLeft: 6 }}>Teacher123</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-passport animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Status:
												</label>
												<label style={{ marginLeft: 6 }}>Active</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-envelope-open-text animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Appraisal Status:
												</label>
												<label style={{ marginLeft: 6 }}>Active</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-pray animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Date Of Joining:
												</label>
												<label style={{ marginLeft: 6 }}>5/28/2019</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-passport animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Account Id:
												</label>
												<label style={{ marginLeft: 6 }}>Admin</label>
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-6">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-envelope-open-text animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Email:
												</label>
												<label style={{ marginLeft: 6 }}>
													Syedhamza1997.sh@gmail.com
												</label>
											</div>
										</div>
									</div>
								</form>

								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<hr class="style-six" />
										</div>
									</div>
								</div>
								<br />

								<form id="contact-form" name="contact-form">
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-user-graduate animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Qualification Name:
												</label>
												<label style={{ marginLeft: 6 }}>Bachelors</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-user animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Staff Name:
												</label>
												<label style={{ marginLeft: 6 }}>Teacher</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-user-tie animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Interview Id:
												</label>
												<label style={{ marginLeft: 6 }}>26</label>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-building animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Board:
												</label>
												<label style={{ marginLeft: 6 }}>Omani</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-building animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Qualification Year:
												</label>
												<label style={{ marginLeft: 6 }}>2015</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-university animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Institute:
												</label>
												<label style={{ marginLeft: 6 }}>26</label>
											</div>
										</div>
									</div>
								</form>

								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<hr class="style-seven" />
										</div>
									</div>
								</div>
								<br />

								<form id="contact-form" name="contact-form">
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-chalkboard animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Experience Name:
												</label>
												<label style={{ marginLeft: 6 }}>Bachelors</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-building animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Company Name:
												</label>
												<label style={{ marginLeft: 6 }}>Teacher</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-layer-group animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Experience Year:
												</label>
												<label style={{ marginLeft: 6 }}>26</label>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-user-tie animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Staff Id:
												</label>
												<label style={{ marginLeft: 6 }}>Omani</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-user-tie animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Interview Id:
												</label>
												<label style={{ marginLeft: 6 }}>2015</label>
											</div>
										</div>
									</div>
								</form>

								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<hr class="style-eight" />
										</div>
									</div>
								</div>
								<br />

								<form id="contact-form" name="contact-form">
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-school animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Skill Name:
												</label>
												<label style={{ marginLeft: 6 }}>Mathematician</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-user-tie animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Interview Id:
												</label>
												<label style={{ marginLeft: 6 }}>21354</label>
											</div>
										</div>
									</div>
								</form>

								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<hr class="style-nine" />
										</div>
									</div>
								</div>
								<br />

								<form id="contact-form" name="contact-form">
									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-school animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Staff Name:
												</label>
												<label style={{ marginLeft: 6 }}>Bachelors</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-child animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Sibling Name:
												</label>
												<label style={{ marginLeft: 6 }}>Teacher</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-child animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Sibling DOB:
												</label>
												<label style={{ marginLeft: 6 }}>26</label>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-child animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Sibling Relation:
												</label>
												<label style={{ marginLeft: 6 }}>Omani</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-passport animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Sibling Passport No:
												</label>
												<label style={{ marginLeft: 6 }}>2015</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-passport animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Sibling Passport DOI:
												</label>
												<label style={{ marginLeft: 6 }}>5/29/2018</label>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-passport animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Sibling Passport DOE:
												</label>
												<label style={{ marginLeft: 6 }}>5/29/2022</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-id-card animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Sibling Id Card:
												</label>
												<label style={{ marginLeft: 6 }}>2015</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-id-card animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Sibling Id Card DOI:
												</label>
												<label style={{ marginLeft: 6 }}>5/29/2018</label>
											</div>
										</div>

										<div class="col-md-4">
											<div class="md-form mb-0">
												<label
													for="name"
													class=""
													style={{ fontWeight: 'bold' }}
												>
													<i
														className="fas fa-id-card animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Sibling Id Card DOE:
												</label>
												<label style={{ marginLeft: 6 }}>5/29/2022</label>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</section>
				</div>
				<div class="text-center text-md-right">
					<label for="name" class="" onClick={() => this.PrintContent('div1')}>
						<i
							className="fas fa-print fa-2x animated fadeIn"
							style={{ marginRight: 4 }}
						/>
						Print It Out
					</label>
				</div>
			</div>
		);
	}
}

export default PrintStaffForm;
