import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import Moment from 'moment';
import DatePicker from 'react-date-picker';
import $ from 'jquery';
import CardsForAttendance from './CardForAttendanceView';

class RangeAttendanceView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			SkillReports: [],
			studentList: [],
			imagePath: '',
			studentId: '',
			studentName: '',
			noOfDays: '',
			fromdate: '',
			todate: '',
			year: '2020',
			month: 'March',
			adminData: [],
			enabled: false,
			id: null,
			select: [],
			date: '',
			studentName: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			errorMsg: '',
			increament: 1,
			selectClassData: '',
			classId: '',
			className: '',
			sectionId: '',
			sectionName: '',
			sections: [],
			subjects: [],
			session: [],
			sessionId: '',
			subjectId: '',
			staffId: '',
			sectionName: '',
			term: '',
			btnLoad: false,
			exist: false,
			checking: [],
			searchCheck: true,
			multiple: [],
			errorText: '',
			badRequest: false,
			present: '',
			absent: '',
			excusedAbsent: '',
			excusedNonAbsent: '',
			totalLate: ''
		};
	}

	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		const sections = this.state.selectClassData.staffClassSection;
		let check = sections.filter(item => item.classId == result.classId);
		console.log(check);

		this.setState({
			sections: check,
			staffId: check[0].staffId,
			staffName: check[0].staffName,
			classId: result.classId,
			className: result.className
		});
	};

	onSectionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		const sections = this.state.selectClassData.staffClassSection;
		let check2 = sections.filter(
			item =>
				item.classId == this.state.classId && item.sectionId == result.sectionId
		);
		this.setState(
			{
				subjects: check2,
				sectionId: result.sectionId,
				sectionName: result.section
			},
			this.hitStudentApi(result.sectionId)
		);
		console.log(this.state.staffId);
	};

	onSessionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		this.setState({
			sessionId: result.sessionId,
			sessionName: result.currentSession
		});
		console.log(this.state);
	};

	onSubjectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		this.setState({
			subjectId: result.subjectId,
			subjectName: result.subjectName
		});
	};
	onStudentChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		this.setState({
			studentId: result.studentId,
			studentName: result.studentName
		});
	};

	hitStudentApi = sectionId => {
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/AttendanceClassSectionStudent/?classId=${this.state.classId}&sectionId=${sectionId}`
			)
			.then(success => {
				debugger;
				this.setState({
					studentList: success
				});
				console.log(success);
			})
			.catch(error => {
				this.setState({
					studentList: []
				});
				console.log(error);
			});
	};
	componentDidUpdate() {
		$('.tooltip').tooltip('hide');

		if (
			this.state.msg ||
			this.state.errorMsg ||
			this.state.exist ||
			this.state.badRequest
		) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					exist: false,
					badRequest: false
				});
			}, 3000);
		}
	}

	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/DescriptiveReport?userName=${this.props.users.unique_name}`
			)
			.then(success => {
				this.setState({
					selectClassData: success
				});
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		this.props.sharedActions
			.getDataWithoutDispatch('/api/Session')
			.then(success => {
				this.setState({
					session: success
				});
			})
			.catch(error => {});
	}

	onSearchStudent = e => {
		this.setState({
			btnLoad: true
		});
		let FromDate = Moment(this.state.fromdate).format('MM/DD/YYYY');
		let ToDate = Moment(this.state.todate).format('MM/DD/YYYY');
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/SubjectWiseAttendance/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&studentId=${this.state.studentId}&fromdate=${FromDate}&subjectId=${this.state.subjectId}&todate=${ToDate}`
			)
			.then(success => {
				debugger;
				this.setState({
					present: success.totalPresent,
					absent: success.totalAbsents,
					excusedAbsent: success.excuseAbsents,
					excusedNonAbsent: success.nonExcusedAbsents,
					totalLate: success.totalLates,
					noOfDays: success.noOfDays,
					imagePath: success.imagePath,
					isLoading: false,
					btnLoad: false,
					searchCheck: false
				});
			})
			.catch(err => {
				this.setState({
					errorText: err.response.data.Message,
					isLoading: false,
					btnLoad: false,
					searchCheck: true,
					badRequest: true
				});

				console.log(err);
			});
	};

	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.skillReportId === itemId);

		this.setState({
			modalData: getData,
			efforts: getData[0].efforts,
			average: getData[0].average,
			academicProgress: getData[0].academicProgress,
			id: getData[0].skillReportId,
			studentName: getData[0].stduentName,
			participation: getData[0].participation
		});
	};

	onHandleTextNumber = e => {
		console.log(e.target.value);
		if (e.target.value <= 5) {
			this.setState({
				[e.target.name]: e.target.value
			});
		}
		console.log(this.state.effort);
	};
	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onChangeText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	changeDate = (date, state) => {
		this.setState({
			[state]: date
		});
		console.log(date);
	};

	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};

	render() {
		const {
			classId,
			sectionId,
			sessionId,
			subjectId,
			term,
			adminData,
			date,
			sections,
			msg,
			subjects,
			studentList,
			studentId,
			year,
			month,
			fromdate,
			todate
		} = this.state;

		const { session } = this.state.session;
		const { classes } = this.state.selectClassData;

		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<label style={{ color: 'red' }}>No Data</label>
			</div>
		);
		const MainContent = (
			<div>
				<div id="div1">
					<CardsForAttendance
						excuseAbsents={this.state.excusedAbsent}
						nonExcusedAbsents={this.state.excusedNonAbsent}
						noOfDays={this.state.noOfDays}
						totalAbsents={this.state.absent}
						totalLates={this.state.totalLate}
						totalPresent={this.state.present}
						imagePath={this.state.imagePath}
						studentName={this.state.studentName}
						className={this.state.className}
						section={this.state.sectionName}
						subject={this.state.subjectName}
					/>
				</div>
				<div class="text-center text-md-right">
					<label for="name" class="" onClick={() => this.PrintContent('div1')}>
						<i
							className="fas fa-print fa-2x animated fadeIn"
							style={{ marginRight: 4 }}
						/>
						Print It Out
					</label>
				</div>
			</div>
		);
		return (
			<div>
				{this.state.badRequest ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						{this.state.errorText}
					</p>
				) : (
					''
				)}
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center'
						}}
					>
						<div>
							<h5 class="generalHead">Range Wise Attendance</h5>
						</div>
					</div>
				</div>
				<div class="container">
					<div class="row" style={{ justifyContent: 'center' }}>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Class Name
								</label> */}
								<select
									onChange={this.onSelectChange}
									class="custom-select"
									name="className"
								>
									<option selected={msg ? true : false}>Select Class...</option>
									{classes &&
										classes.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.className}
											</option>
										))}
								</select>
							</div>
						</div>

						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Section
								</label> */}
								<select
									onChange={this.onSectionChange}
									class="custom-select"
									name="className"
								>
									<option selected={msg ? true : false}>
										Select Section...
									</option>
									{sections &&
										sections.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.section}
											</option>
										))}
								</select>
							</div>
						</div>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Subject
								</label> */}
								<select
									onChange={this.onSubjectChange}
									class="custom-select"
									name="className"
								>
									<option selected={msg ? true : false}>
										Select Subject...
									</option>
									{subjects &&
										subjects.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.subjectName}
											</option>
										))}
								</select>
							</div>
						</div>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Session
								</label> */}
								<select
									onChange={this.onStudentChange}
									class="custom-select"
									name="sessionId"
								>
									<option selected={msg ? true : false}>
										Select Student...
									</option>
									{studentList &&
										studentList.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.studentName}
											</option>
										))}
								</select>
							</div>
						</div>
					</div>
				</div>
				<br />
				<div class="row justify-content-center">
					<div class="col-md-2" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="text-center">
								From Date
							</label>
							<DatePicker
								onChange={e => this.changeDate(e, 'fromdate')}
								value={this.state.fromdate}
							/>
						</div>
					</div>
					<div class="col-md-2" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="text-center">
								To Date
							</label>
							<DatePicker
								onChange={e => this.changeDate(e, 'todate')}
								value={this.state.todate}
								minDate={this.state.fromdate}
							/>
						</div>
					</div>
				</div>
				<br />
				<div class="col-md-12 text-center">
					<button
						type="submit"
						disabled={
							classId !== '' &&
							studentId !== '' &&
							sectionId !== '' &&
							fromdate !== '' &&
							todate !== '' &&
							subjectId !== ''
								? false
								: true
						}
						style={{
							backgroundColor:
								classId !== '' &&
								studentId !== '' &&
								sectionId !== '' &&
								fromdate !== '' &&
								subjectId !== '' &&
								todate !== ''
									? '#01ac8a'
									: '#ABD0BC',
							border: 'none',
							fontSize: 20,
							borderRadius: 4,
							color: 'white'
						}}
						onClick={this.onSearchStudent}
					>
						Search
						<i
							style={{ color: 'white', marginLeft: 4 }}
							class="fas fa-search"
						></i>
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
					</button>
				</div>
				<br />
				{this.state.searchCheck ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RangeAttendanceView);
