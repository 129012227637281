import React from 'react';
import Colors from '../../utils/app_constants/colors_constants';
import CustomBadge from '../CustomBadge/CustomBadge';

export default function CoverLayout({ children, titleName, titleIcon }) {
	return (
		<div
			className="lineGraph"
			style={{
				border: `3px solid ${Colors.DARK_SEA_GREEN_THEME}`
			}}
		>
			<CustomBadge
				fontSize={25}
				badgeText={titleName}
				badgeIconClass={titleIcon}
			/>
			{children()}
		</div>
	);
}
