import $ from 'jquery';
import Colors from '../app_constants/colors_constants';
import { JsonWebTokenError } from 'jsonwebtoken';

// Tools Constant
export const RENDER_SUB_TOOLS = 'sub_tools';
export const RENDER_TOOLS = 'tools';
export const DELETE_CA = 'delete';
export const ASSIGN_MARKS = 'assign_marks';

// End of Tools Constants

// Heading constants

export const MAIN_HEADING = 'MAIN_HEADING';
export const SUB_HEADING = 'SUB_HEADING';
export const SMALL_HEADING = 'SMALL_HEADING';

// end of Heading constants

//  camelCaseToCamelCase
function camelCaseToCamelCase(input) {
	return input
		.replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, ' $1')
		.replace(/^./, s => s.toUpperCase());
}
//
let Months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

let gender = ['Male', 'Female'];

let YesNO = ['Yes', 'No'];
const nationality = ['Pakistani', 'Omani', 'Saudi', 'Irani', 'Iraqi', 'Indian'];

let BloodGroup = ['A +', 'B +', 'A -', 'B -', 'O +', 'O -'];


// indent Form status
let indentDropDown = [
	'Approved',
	'Not Approved',
]

//Teacher Evaluation with or Without
let withOrWithout = [
	'With Prior Notification',
	'Without Prior Notification'
]

//term
let term = [
	'1st',
	'2nd',
	'3rd'
]

// time format

const timeFormat = 'h:mm a';

//leaveType
let leaveType = ['Excused']

//patient type
let patientType = ['Student', 'Staff']

//# Date Converters
const dateToFormat = date => {
	let tempDate = new Date(date)
	let apiDate = ((tempDate.getMonth() > 8) ? (tempDate.getMonth() + 1) : ('0' + (tempDate.getMonth() + 1))) + '/' + ((tempDate.getDate() > 9) ? tempDate.getDate() : ('0' + tempDate.getDate())) + '/' + tempDate.getFullYear();
	return apiDate
}

const formateToDate = date => {
	let dateObj = date.split('/')
	dateObj = new Date(dateObj[2], dateObj[0] - 1, dateObj[1])
	return dateObj
}

//#endregion

//# Student Medical severity
const medColorRange = ['#5cb85c', '#f0ad4e', '#d9534f']
//#endregion

const conversionOfImageUrl = url => {
	try {
		let getSplitResult = url.split('\\');
		let result = '';
		for (let i = 4; i < getSplitResult.length; i++) {
			if (getSplitResult.length - 1 == i) result += getSplitResult[i];
			else result += getSplitResult[i].concat('\\\\');
		}
		let finalResult = 'http://'.concat(`${result}`);
		return finalResult;
	} catch (e) {
		console.log(e);
	}
};
export const newconversionOfImageUrl = url => {
	let getNewSplit = String.raw`${url}`.split('\\').join('//');
	let getnewSliting = getNewSplit.split('//');

	// let getSplitResult = url.split('');
	let filterURL = [
		...getnewSliting.filter(
			item =>
				item !== 'C:' &&
				item !== 'Inetpub' &&
				item !== 'vhosts' &&
				item !== 'httpdocs'
		)
	];
	debugger;
	let getDesireURL = filterURL.join('/');

	// let result = '';
	// for (let i = 4; i < getSplitResult.length; i++) {
	//   if (getSplitResult.length - 1 == i)
	// 	result += getSplitResult[i];
	//   else
	// 	result += getSplitResult[i].concat('\\\\');
	// }
	let finalResult = 'http://'.concat(`${getDesireURL}`);
	let newFinal = finalResult.replace('linkit.life', 'schoolsmart.org.uk');
	return newFinal;
};
const stringConversion = input => {
	return [...input.match(/[A-Z][a-z]+/g)].join(' ');
};

const country = ['Pakistan', 'Oman', 'Iran', 'Iraq', 'Saudia', 'India'];
const religion = ['Islam ', 'Christianity ', 'Nonreligious', 'Hinduism '];
const status = ['On Hold', 'Active', 'Non-Active'];
const appStatus = ['Yes', 'No'];
const handleDumpDateChange = (date, dumpState, name) => {
	dumpState[name] = date;
	console.log(dumpState);
};
const handleDumpInputChange = (e, dumpState) => {
	// arrow function

	dumpState[e.target.name] = e.target.value;
	console.log(dumpState);
};
const IsObjectEmpty = Obj => {
	let checkFlag = false;

	for (let value of Object.values(Obj)) {
		if (Object.is(value, '')) {
			checkFlag = false; // John, then 30
			break;
		}
		checkFlag = true;
	}
	console.log(checkFlag);
	return checkFlag;
};
const btnTechHover = () => {
	$(document).ready(function () {
		$('button').hover(
			function () {
				$(this).css('box-shadow', '0px 5px 5px grey');
				$(this).css('border-color', 'white');
			},
			function () {
				$(this).css('box-shadow', '0px 0px 0px white');
			}
		);
	});
};
function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
function extensionCheck(imgUpload = false, fileUpload = false, fileName) {
	if (imgUpload) return /\.(jpg|jpeg|png)$/i.test(fileName);
	else if (fileUpload) return /\.(docx|doc|pdf)$/i.test(fileName);
	else return false;
}

function checkForImageOrFile(
	isImageUpload = false,
	isFileUpload = false,
	error = {},
	fileSize = 0,
	fileSizeInMB = 2.1,
	fileName,
	errorFieldName = 'defaultFlag',
	setErrorFun
) {
	let getImgSize = formatBytes(fileSize);
	let getPices = getImgSize.split(' ');

	console.log(getPices);
	if (
		getPices[1] === 'KB' &&
		parseFloat(getPices[0]) > fileSizeInMB &&
		extensionCheck(isImageUpload, isFileUpload, fileName)
	) {
		console.log(getPices);
		console.log('fine with KB');
		let cloneError = { ...error };
		cloneError[errorFieldName] = false;
		setErrorFun(cloneError);
		return false;
	} else if (
		getPices[1] === 'Bytes' &&
		parseFloat(getPices[0]) > fileSizeInMB &&
		extensionCheck(isImageUpload, isFileUpload, fileName)
	) {
		console.log(getPices);
		console.log('fine with bytes');
		let cloneError = { ...error };
		cloneError[errorFieldName] = false;
		setErrorFun(cloneError);
		return false;
	} else if (
		getPices[1] === 'MB' &&
		parseFloat(getPices[0]) < fileSizeInMB &&
		extensionCheck(isImageUpload, isFileUpload, fileName)
	) {
		console.log(getPices);
		console.log('fine with MB');
		let cloneError = { ...error };
		cloneError[errorFieldName] = false;
		setErrorFun(cloneError);
		return false;
	} else {
		let cloneError = { ...error };
		cloneError[errorFieldName] = true;
		setErrorFun(cloneError);
		return true;
	}
}
function extraction(extractArr = [], obj) {
	let getOBJ = {};
	let getKeys = Object.keys(obj);
	extractArr.forEach(element => {
		if (getKeys.includes(element)) {
			getOBJ[element] = obj[element];
		}
	});
	let check = getOBJ;

	return getOBJ;
	console.log(getOBJ);
}

export function dataFilteration(data, extractingValue = [], filter = {}) {
	let reqArr = [];

	let getResult = [
		...data.filter(function (item) {
			for (var key in filter) {
				if (item[key] === undefined || item[key] != filter[key]) return false;
			}
			return true;
		})
	];
	let requiredResult = getResult.map(item => extraction(extractingValue, item));

	return requiredResult;
}
function isObjEmpty(obj = {}) {
	let emptyStatus = true;
	let getKeys = Object.keys(obj);
	if (getKeys.length > 0) {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const element = obj[key];
				if (element !== '') {
					emptyStatus = false;
					break;
				}
			}
		}

		return emptyStatus;
	}
	return true;
}
export function isEmptyFieldInArrOBJ(arrObj, fieldName) {
	let isEmpty = false;
	arrObj.forEach(element => {
		if (element[fieldName] === '') {
			isEmpty = true;
		}
	});

	return isEmpty;
}

export function customSubToolDataAddingMiddleWare(addData, studentData) {
	let desireData = [];
	let getAllData = JSON.parse(JSON.stringify(studentData));

	for (let i = 0; i < studentData.length; i++) {
		let getData = $.extend(true, {}, addData);
		getData['Action'][3]['modalKey'] = `#deleteModal${studentData[i].pId}`;
		desireData.push({
			...studentData[i],
			...getData
		});
		debugger;
	}

	// studentData.map((item, index) => {
	// 	getData['Action'][3]['modalKey'] = `#deleteModal${index + 1}`;
	// 	debugger;
	// 	return {
	// 		...item,
	// 		...getData
	// 	};
	// 	debugger;
	// });
	debugger;
	return desireData;
}
export function customDataAddingMiddleWare(addData, studentData) {
	let desireData = [];
	let getData = $.extend(true, {}, addData);
	debugger;
	let getAllData = JSON.parse(JSON.stringify(studentData));
	debugger;
	desireData = studentData.map(item => {
		if (item.CA_AssignMark.length > 0) {
			getData['Action'][0]['isEnable'] = false;
			getData['Action'][0]['iconColor'] = Colors.DISABLE_GREY;
			debugger;
			return {
				...item,
				...getData
			};
			debugger;
		} else {
			debugger;
			addData['Action'][1]['isEnable'] = false;
			addData['Action'][1]['iconColor'] = Colors.DISABLE_GREY;
			debugger;
			return {
				...item,
				...addData
			};
		}
		debugger;
	});
	// getAllData.map(item => {
	// 	if (item.CA_AssignMark.length > 0) {
	// 		debugger;
	// getData['Action'][0]['isEnable'] = false;
	// 		getData['Action'][0]['iconColor'] = Colors.DISABLE_GREY;
	// 		debugger;
	// 		debugger;
	// 	}
	// });
	debugger;
	return desireData;
}
export function addDataMiddleware(addFields = {}, targatedArrObj = []) {
	let extendedArrObj = [];
	if (!isObjEmpty(addFields)) {
		// object shouldn't be empty
		extendedArrObj = targatedArrObj.map(item => {
			return {
				...item,
				...addFields
			};
		});
		return extendedArrObj;
	}
}
export function addCustomDataMiddleware(addFields = {}, targatedArrObj = []) {
	let extendedArrObj = [];
	if (!isObjEmpty(addFields)) {
		// object shouldn't be empty
		extendedArrObj = targatedArrObj.map(item => {
			return {
				...item,
				...addFields
			};
		});
		return extendedArrObj;
	}
}

export function checkingProperty(data = [], UserData) {
	for (let index = 0; index < UserData.length; index++) {
		if (!data.includes(UserData[index])) return false;
	}
	return true;
}
export function checkForFilteration(objKeys = [], userKeys = []) {
	let getNewKeys = objKeys.filter(item => {
		if (userKeys.includes(item)) return false;
		return true;
	});
	return getNewKeys;
}
export function extractIds(
	propName = [],
	itemObj = {},
	additional = '',
	type = false
) {
	let urlString = '';
	let getKeys = Object.keys(itemObj);
	let extractObj = {};
	getKeys.map(item => {
		if (propName.includes(item)) extractObj[item] = itemObj[item];
	});
	if (!type) {
		for (const key in extractObj) {
			if (extractObj.hasOwnProperty(key)) {
				urlString += `/${extractObj[key]}`;
			}
		}
	} else {
		for (const key in extractObj) {
			if (extractObj.hasOwnProperty(key)) {
				urlString += `${extractObj[key]}`;
			}
		}
	}
	if (additional !== 'undefined' && !type) {
		urlString += `/${additional}`;
	}

	return urlString;
}
function checkBlur() {
	console.log('hit bluerr');
}

function condtionalServices(
	classId,
	sectionId,
	sessionId,
	currentPage,
	customPageSize
) {
	let returnString = '';

	if (classId !== '' && sectionId !== '' && sessionId !== '') {
		return `/api/StudentAdmissionForm?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&pageNumber=${currentPage}&pageSize=${parseInt(
			customPageSize
		)}`;
	}

	if (classId !== '' && sessionId !== '') {
		return `/api/StudentAdmissionForm?classId=${classId}&sessionId=${sessionId}&pageNumber=${currentPage}&pageSize=${parseInt(
			customPageSize
		)}`;
	}

	if (sessionId !== '') {
		return `/api/StudentSession?sessionId=${sessionId}&pageNumber=${currentPage}&pageSize=${parseInt(
			customPageSize
		)}`;
	}

	return `/api/AllStudentAdmission?pageNumber=${currentPage}&pageSize=${parseInt(
		customPageSize
	)}`;
}

export function viewClassReturn(type, data, rowIndex) {
	debugger;
	if (type && type['isEnable'] && !type.hasOwnProperty('renderType')) {
		return '';
	} else if (type && !type['isEnable']) {
		debugger;
		return 'disabled-link';
	}
	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === ASSIGN_MARKS &&
		data[rowIndex]['tools'].length > 0 &&
		data[rowIndex]['subTools'].length < 1
	) {
		return 'disabled-link';
	}
	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === RENDER_SUB_TOOLS &&
		data[rowIndex]['tools'].length < 1
	) {
		return 'disabled-link';
	}
	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === ASSIGN_MARKS &&
		data[rowIndex]['tools'].length > 0 &&
		data[rowIndex]['subTools'].length > 0
	) {
		return '';
	} else if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === ASSIGN_MARKS &&
		data[rowIndex]['tools'].length < 1 &&
		data[rowIndex]['subTools'].length < 1
	) {
		return 'disabled-link';
	}
	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === RENDER_SUB_TOOLS &&
		data[rowIndex]['tools'].length > 0 &&
		data[rowIndex]['subTools'].length > 0
	) {
		return 'disabled-link';
	}

	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === RENDER_TOOLS &&
		data[rowIndex]['tools'].length > 0 &&
		data[rowIndex]['subTools'].length > 0
	) {
		return 'disabled-link';
	}
	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === RENDER_TOOLS &&
		data[rowIndex]['tools'].length > 0
	) {
		return 'disabled-link';
	}
}
export function disableColorOverCondition(type, data, rowIndex) {
	debugger;
	if (type && type['isEnable'] && !type.hasOwnProperty('renderType')) {
		debugger;
		return type['iconColor'];
	} else if (type && !type['isEnable']) {
		debugger;
		return '#d3d3d3';
	}
	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === RENDER_SUB_TOOLS &&
		data[rowIndex]['tools'].length < 1
	) {
		return '#d3d3d3';
	}

	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === ASSIGN_MARKS &&
		data[rowIndex]['tools'].length > 0 &&
		data[rowIndex]['subTools'].length < 1
	) {
		return '#d3d3d3';
	}
	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === ASSIGN_MARKS &&
		data[rowIndex]['tools'].length > 0 &&
		data[rowIndex]['subTools'].length > 0
	) {
		return type['iconColor'];
	} else if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === ASSIGN_MARKS &&
		data[rowIndex]['tools'].length < 1 &&
		data[rowIndex]['subTools'].length < 1
	) {
		debugger;
		return '#d3d3d3';
	}
	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === RENDER_SUB_TOOLS &&
		data[rowIndex]['tools'].length > 0 &&
		data[rowIndex]['subTools'].length > 0
	) {
		return '#d3d3d3';
	}
	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === RENDER_TOOLS &&
		data[rowIndex]['tools'].length > 0 &&
		data[rowIndex]['subTools'].length > 0
	) {
		return '#d3d3d3';
	}
	if (
		type.hasOwnProperty('renderType') &&
		type['renderType'] === RENDER_TOOLS &&
		data[rowIndex]['tools'].length > 0
	) {
		return '#d3d3d3';
	}
}
function condtionalServicesSubject(
	classId,
	sectionId,
	currentPage,
	customPageSize
) {
	let returnString = '';

	if (classId !== '' && sectionId !== '') {
		return `/api/ViewAssignSubjectClassSection?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${parseInt(classId)}&sectionId=${parseInt(sectionId)}`;
	}

	if (classId !== '') {
		return `/api/ViewAssignSubjectClass?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${parseInt(classId)}`;
	}

	return `/api/ViewAssignSubject?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}`;
}

function condtionalServicesVisitor(
	date,
	currentPage,
	customPageSize
) {
	let returnString = '';

	let tempDate = new Date(date)
	let apiDate = ((tempDate.getMonth() > 8) ? (tempDate.getMonth() + 1) : ('0' + (tempDate.getMonth() + 1))) + '/' + ((tempDate.getDate() > 9) ? tempDate.getDate() : ('0' + tempDate.getDate())) + '/' + tempDate.getFullYear();


	if (date !== '') {
		return `/api/SearchByDate?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&date=${apiDate}`;
	}
	return `/api/VisitorEntryRecord?PageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}`
}

function condtionalServicesPatient(
	studentStaff,
	doctorId,
	date,
	currentPage,
	customPageSize
) {
	let returnString = '';

	let tempDate = new Date(date)
	let apiDate = ((tempDate.getMonth() > 8) ? (tempDate.getMonth() + 1) : ('0' + (tempDate.getMonth() + 1))) + '/' + ((tempDate.getDate() > 9) ? tempDate.getDate() : ('0' + tempDate.getDate())) + '/' + tempDate.getFullYear();

	if (date !== '' && doctorId !== '') {
		return `/api/PatientDetail?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&doctorId=${doctorId}&date=${apiDate}&studentStaff=${studentStaff}`;
	}

	if (doctorId !== '') {
		return `/api/PatientDetail?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&doctorId=${doctorId}&date=${''}&studentStaff=${studentStaff}`;
	}

	if (date !== '') {
		return `/api/PatientDetail?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&doctorId=${''}&date=${apiDate}&studentStaff=${studentStaff}`;
	}
	return `/api/PatientDetail?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&doctorId=${''}&date=${''}&studentStaff=${studentStaff}`;
}

function condtionalServicesTour(
	classId,
	sectionId,
	sessionId,
	date,
	currentPage,
	customPageSize
) {
	let returnString = '';

	let tempDate = new Date(date)
	let apiDate = ((tempDate.getMonth() > 8) ? (tempDate.getMonth() + 1) : ('0' + (tempDate.getMonth() + 1))) + '/' + ((tempDate.getDate() > 9) ? tempDate.getDate() : ('0' + tempDate.getDate())) + '/' + tempDate.getFullYear();

	if (date !== '' && sectionId !== '' && sessionId !== '' && classId !== '') {
		return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&date=${apiDate}`;
	}

	if (date !== '' && classId !== '' && sectionId !== '') {
		return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=${sectionId}&sessionId=&date=${apiDate}`;
	}

	if (date !== '' && classId !== '' && sessionId !== '') {
		return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=&sessionId=${sessionId}&date=${apiDate}`;
	}

	if (classId !== '' && sectionId !== '' && sessionId !== '') {
		return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&date=`;
	}

	if (date !== '' && sessionId) {
		return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=&sectionId=&sessionId=${sessionId}&date=${apiDate}`;
	}

	if (date !== '' && classId) {
		return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=&sessionId=&date=${apiDate}`;
	}

	if (classId !== '' && sessionId !== '') {
		return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=&sessionId=${sessionId}&date=`;
	}

	if (classId !== '' && sectionId !== '') {
		return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=${sectionId}&sessionId=&date=`;
	}

	if (classId !== '') {
		return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=&sessionId=&date=`;
	}

	if (sessionId !== '') {
		return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=&sectionId=&sessionId=${sessionId}&date=`;
	}

	if (date !== '') {
		return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=&sectionId=&sessionId=&date=${apiDate}`;
	}

	return `/api/AllTours?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=&sectionId=&sessionId=&date=`;
}

function condtionalServicesNewAdmit(
	classId,
	sessionId,
	name,
	currentPage,
	customPageSize
) {


	if (classId !== '' && name !== '' && sessionId !== '') {
		return `/api/ListOfAdmissionsBySessionClassName?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sessionId=${sessionId}&name=${name}`;
	}

	if (name !== '' && sessionId) {
		return `/api/ListOfAdmissionsBySessionName?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${''}&sessionId=${sessionId}&name=${name}`;
	}

	if (name !== '' && classId) {
		return `/api/ListOfAdmissionsByClassName?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sessionId=${''}&name=${name}`;
	}

	if (classId !== '' && sessionId !== '') {
		return `/api/ListOfAdmissionsBySessionClass?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sessionId=${sessionId}&name=${''}`;
	}

	if (classId !== '') {
		return `/api/ListOfAdmissionsByClass?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sessionId=${''}&name=${''}`;
	}

	if (sessionId !== '') {
		return `/api/ListOfAdmissionsBySession?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${''}&sessionId=${sessionId}&name=${''}`;
	}

	if (name !== '') {
		return `/api/ListOfAdmissionsByName?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${''}&sessionId=${''}&name=${name}`;
	}

	return `/api/ListOfAdmissions?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${''}&sessionId=${''}&name=${''}`;
}

function condtionalServicesClassOfStudent(
	classId,
	sectionId,
	sessionId,
	currentPage,
	customPageSize
) {

	if (classId !== '' && sectionId !== '' && sessionId !== '') {
		return `/api/ListOfStdFromClassSectionSession/?pageNumber=${currentPage}&pageSiz=${parseInt(customPageSize)}&classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}`;
	}

	if (sectionId !== '' && classId !== '') {
		return `/api/ListOfStdFromClassSection/?pageNumber=${currentPage}&pageSiz=${parseInt(customPageSize)}&classId=${classId}&sectionId=${sectionId}&sessionId=
		`;
	}

	if (classId !== '' && sessionId !== '') {
		return `/api/ListOfStdFromClassSession/?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=&sessionId=${sessionId}`;
	}

	if (classId !== '') {
		return `/api/ListOfStdFromClass/?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=&sessionId=`;
	}

	if (sessionId !== '') {
		return `/api/ListOfStdFromsSession/?pageNumber=${currentPage}&pageSiz=${parseInt(customPageSize)}&sessionId=${sessionId}&classId=&sectionId=`;
	}

	return `/api/ListOfAllStd/?pageNumber=${currentPage}&pageSiz=${parseInt(customPageSize)}&classId=&sectionId=&sessionId=`;
}

function condtionalServicesTransferStudent(
	classId,
	sectionId,
	currentPage,
	customPageSize
) {

	if (sectionId !== '' && classId !== '') {
		return `/api/GetListOfStudentCurrentClassSection/?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=${sectionId}`;
	}

	if (classId !== '') {
		return `/api/GetListOfStudentCurrentClass/?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=`;
	}
	return `/api/GetListOfAllStudentCurrentClassSection?classId=&sectionId=&pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}`

}

function condtionalServicesOffGame(
	classId,
	sectionId,
	gameId,
	date,
	currentPage,
	customPageSize
) {
	let returnString = '';

	let tempDate = new Date(date)
	let apiDate = ((tempDate.getMonth() > 8) ? (tempDate.getMonth() + 1) : ('0' + (tempDate.getMonth() + 1))) + '/' + ((tempDate.getDate() > 9) ? tempDate.getDate() : ('0' + tempDate.getDate())) + '/' + tempDate.getFullYear();

	if (date !== '' && sectionId !== '' && gameId !== '' && classId !== '') {
		return `/api/SearchAllOffGameClassSectionDate?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=${sectionId}&gameId=${gameId}&date=${apiDate}`;
	}

	if (date !== '' && classId !== '' && sectionId !== '') {
		return `/api/SearchAllOffClassSectionDate?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=${sectionId}&date=${apiDate}&gameId=`;
	}

	if (date !== '' && classId !== '' && gameId !== '') {
		return `/api/SearchAllOffGameClassDate?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&gameId=${gameId}&date=${apiDate}&sectionId=`;
	}

	if (classId !== '' && sectionId !== '' && gameId !== '') {
		return `/api/SearchAllOffGameClassSection?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=${sectionId}&gameId=${gameId}&date=`;
	}

	if (date !== '' && gameId) {
		return `/api/SearchAllOffGameDate?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&gameId=${gameId}&date=${apiDate}&classId=&sectionId=`;
	}

	if (date !== '' && classId) {
		return `/api/SearchAllOffClassDate?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&date=${apiDate}&gameId=&sectionId=`;
	}

	if (classId !== '' && gameId !== '') {
		return `/api/SearchAllOffGameClass?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&gameId=${gameId}&sectionId=&date=`;
	}

	if (classId !== '' && sectionId !== '') {
		return `/api/SearchAllOffClassSection?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=${sectionId}&gameId=${''}&date=${''}`;
	}

	if (classId !== '') {
		return `/api/SearchAllOffClassId?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=${classId}&sectionId=${''}&gameId=${''}&date=${''}`;
	}

	if (gameId !== '') {
		return `/api/SearchAllOffGameId?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&gameId=${gameId}&classId=&sectionId=&date=`;
	}

	if (date !== '') {
		return `/api/SearchAllOffDate?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&date=${apiDate}&sectionId=${''}&gameId=${''}&classId=${''}`;
	}

	return `/api/SearchAllOffGameRecord?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&classId=&sectionId=&gameId=&date=`;
}

function condtionalServicesOtherCertificate(
	name,
	currentPage,
	customPageSize
) {
	let returnString = '';

	if (name !== '') {
		return `/api/othercertificate?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&name=${name}`
	}
	return `/api/othercertificate?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&name=`
}

function condtionalServicesEvent(
	date,
	currentPage,
	customPageSize
) {
	let returnString = '';

	let tempDate = new Date(date)
	let apiDate = ((tempDate.getMonth() > 8) ? (tempDate.getMonth() + 1) : ('0' + (tempDate.getMonth() + 1))) + '/' + ((tempDate.getDate() > 9) ? tempDate.getDate() : ('0' + tempDate.getDate())) + '/' + tempDate.getFullYear();


	if (date !== '') {
		return `/api/EventInfo?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&date=${apiDate}`;
	}
	return `/api/EventInfo?PageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&date=`
}

export default {
	Months,
	viewClassReturn,
	condtionalServices,
	condtionalServicesSubject,
	condtionalServicesVisitor,
	condtionalServicesPatient,
	condtionalServicesTour,
	condtionalServicesOtherCertificate,
	condtionalServicesEvent,
	condtionalServicesOffGame,
	condtionalServicesNewAdmit,
	condtionalServicesClassOfStudent,
	condtionalServicesTransferStudent,
	gender,
	YesNO,
	nationality,
	country,
	religion,
	status,
	handleDumpInputChange,
	handleDumpDateChange,
	IsObjectEmpty,
	BloodGroup,
	conversionOfImageUrl,
	stringConversion,
	btnTechHover,
	checkForImageOrFile,
	appStatus,
	newconversionOfImageUrl,
	dataFilteration,
	camelCaseToCamelCase,
	checkBlur,
	term,
	leaveType,
	indentDropDown,
	patientType,
	timeFormat,
	withOrWithout,
	dateToFormat,
	formateToDate,
	medColorRange
};
