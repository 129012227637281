import React from 'react';
import ViewRendering from '../../../components/ViewRendering';
import { renderingStuff, images } from '../../../utils';

function AcademicSID(props) {
	return (
		<div>
			<ViewRendering
				centered={true}
				centerWidth={'50%'}
				mainHeading={images.imgAcHead}
				data={renderingStuff.academicSIDLinks}
				mainHead={true}
			/>
		</div>
	);
}

export default AcademicSID;
