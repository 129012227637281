import React from 'react';

export default function IncomeView(props) {
	const {
		AllOverSearch,
		BalanceDue,
		TotalIncome,
		monthlySearch,
		MonthlyAmount,
		MonthlyDue
	} = props;
	debugger;
	return AllOverSearch == true ? (
		<div class="row justify-content-center">
			<div class="cold-md-4 animated slideInLeft">
				<div class="card shadowForBox" style={{ backgroundColor: '#00a559' }}>
					<div class="card-body text-center">
						<h5 class="card-title font-weight-bold" style={{ color: 'white' }}>
							School Total Income
						</h5>
						<label style={{ fontSize: 25 }}>ر.ع {TotalIncome}</label>
					</div>
				</div>
			</div>
			<div class="col-md-1"></div>
			<div class="cold-md-4  animated slideInRight">
				<div class="card shadowForBox" style={{ backgroundColor: '#dc4a38' }}>
					<div class="card-body text-center">
						<h5 class="card-title font-weight-bold" style={{ color: 'white' }}>
							Total Balance Due
						</h5>

						<label style={{ fontSize: 25 }}>ر.ع {BalanceDue}</label>
					</div>
				</div>
			</div>
		</div>
	) : monthlySearch == true ? (
		<div class="row justify-content-center">
			<div class="cold-md-4 animated slideInLeft">
				<div class="card shadowForBox" style={{ backgroundColor: '#00a559' }}>
					<div class="card-body text-center">
						<h5 class="card-title font-weight-bold" style={{ color: 'white' }}>
							Monthly Amount Income
						</h5>
						<label style={{ fontSize: 25 }}>ر.ع {MonthlyAmount}</label>
					</div>
				</div>
			</div>
			<div class="col-md-1"></div>
			<div class="cold-md-4 animated slideInRight" id="wrap">
				<div
					class="card shadowForBox inner"
					style={{ backgroundColor: '#dc4a38' }}
				>
					<div class="card-body text-center">
						<h5 class="card-title font-weight-bold" style={{ color: 'white' }}>
							Monthly Balance Due
						</h5>

						<label style={{ fontSize: 25 }}>ر.ع {MonthlyDue}</label>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div class="text-danger font-weight-bold text-center">No Data</div>
	);
}
