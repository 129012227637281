import React, { Component } from 'react';
import './Form.css';

class StudentParentDocument extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { parentDocs } = this.props;
		console.log(parentDocs, 'Parent Docs Ka Data Haii');
		return (
			<div>
				<section class="mb-4">
					<div class="row print">
						<div class="col-md-12">
							<div class="md-form mb-0">
								<h2
									style={{
										backgroundColor: '#0A4F5E',
										textAlign: 'center',
										color: 'white'
									}}
								>
									Parent Documents:
								</h2>
							</div>
						</div>
					</div>
					<div class="container">
						<div class="row rowCheck" style={{ marginTop: 10 }}>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Father Passport:</label>
								&nbsp;&nbsp;{parentDocs && parentDocs.passportFather}{' '}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Passport Mother:</label>
								&nbsp;&nbsp;{parentDocs && parentDocs.passportMother}{' '}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Father Id Card:</label>{' '}
								&nbsp;&nbsp;{parentDocs && parentDocs.idCardFather}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Mother Id Card:</label>
								&nbsp;&nbsp;{parentDocs && parentDocs.idCardMother}
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default StudentParentDocument;
