import React from 'react';
import ViewRendering from '../components/ViewRendering';
import { renderingStuff, images } from '../utils';

export default function CampusSecurituDashboard() {
	return (
		<div>
			<h3 class="h3-responsive font-weight-bold text-center my-4">
				Campus Security Module's
			</h3>
			<div
				class="row"
				style={{
					justifyContent: 'center',
					textAlign: 'center',
					marginBottom: -30
				}}
			>
				<div class="col-md-8">
					<div class="md-form mb-0">
						<hr class="style-head" />
					</div>
				</div>
			</div>
			<ViewRendering
				centered={true}
				centerWidth={'50%'}
				data={renderingStuff.CampusSecurity_Dashboard_Links}
				mainHead={false}
			/>
		</div>
	);
}
