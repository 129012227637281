import React, { Suspense, lazy } from 'react';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import { MainTimeTable } from '../TimeTableRendering';
// import Modal from '../../../components/Modal';
import TextInput from '../../../components/TextInput/TextInput';
const Modal = lazy(() => import('../../../components/Modal'));

export default function Step1Version_1(props) {
	const { onChangeClassName, errors = {}, stateData, onTextChange } = props;
	return (
		<React.Fragment>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<p style={{ fontSize: 30, margin: 0 }}>
					<span
						style={{ position: 'relative', top: 20 }}
						class="badge badge-pill badge-dark"
					>
						<p style={{ margin: 0, color: '#fff' }}>Class Section</p>
					</span>
				</p>
			</div>
			<div
				style={{
					fontSize: 18,
					border: '1px solid #D3D3D3',
					boxShadow: '0px 0px 15px #0A4F5E',
					padding: 10,
					borderRadius: 5,
					// position: 'relative',
					// bottom: 110,
					height: 'auto'
				}}
			>
				<div class="row">
					{stateData && stateData.errors && stateData.errors.step5 ? (
						<p
							style={{
								color: 'red',
								fontSize: 17,
								textAlign: 'center',
								position: 'relative',
								left: 20
							}}
						>
							Please Fill Properly!
						</p>
					) : (
						''
					)}
					<div style={{ display: 'flex', flexWrap: 'wrap' }} class="col-md-12">
						<div class="md-form mb-0"></div>
						{stateData && stateData.isLoading ? (
							'Loading..'
						) : (
							<React.Fragment>
								<SelectionInput
									selectName="Class"
									feildName="className"
									selectedText="Choose Class"
									optionsArrys={
										stateData &&
										stateData.classSectionData &&
										stateData.classSectionData.classes
									}
									errors={errors}
									stateData={stateData}
									onHandleChange={onChangeClassName}
									optionType="dynamic"
									property="className"
									// iconClassName='fas fa-level-down-alt'
									propertyId="className"
								/>
							</React.Fragment>
						)}
						{/* <div> */}

						<SelectionInput
							selectFont={17}
							selectName="Section"
							feildName="sectionId"
							selectedText="Choose Section"
							optionsArrys={stateData && stateData.grade}
							errors={errors}
							stateData={stateData}
							optionType="dynamic"
							onHandleChange={e => onTextChange(e)}
							property="section"
							// iconClassName='fas fa-level-down-alt'
							propertyId="sectionId"
						/>
						{/* </div> */}
						<TextInput
							textFont={17}
							feildName="gradeName"
							inputLabelName="Grade Name"
							errors={errors}
							stateData={stateData}
							placeholder="Grade Name"
							readOnly={true}
							type={'text'}
						/>
					</div>
				</div>
			</div>
			{stateData.metaExist ? (
				<Suspense fallback={<div class="spinner-border text-primary"></div>}>
					<Modal
						header={true}
						mdId="timeTableModal"
						mainId="istId"
						animation="fade"
						modalTitle="Time Table"
						// titleColor='#fff'
						modalSize="modal-xl"
						backdrop={'static'}
						datakey={'false'}
						fullModal={'modal-full'}
						handleSubmit={props.onSubmit}
						stateData={props.stateData}
						topCloseButton={false}
						bottomCloseButtonName={'Discard'}
						bottomSaveButtonName={'Submit'}
						handleCloseButton={props.onDiscard}
						closeHandler={false}
					>
						<MainTimeTable
							timeData={props.timeTable}
							msToTime={props.msToTime}
							stateData={props.stateData}
							workingDays={props.stateData.workingDays}
							PeriodArray={props.stateData.periodArr}
							AllPeriods={props.stateData.finalArray}
							onChangeNewPeriodData={props.onChangeNewPeriodData}
							handleCloseButton={props.onDiscard}
						/>
					</Modal>
				</Suspense>
			) : (
				<div></div>
			)}
		</React.Fragment>
	);
}
