import $ from 'jquery';
import React from 'react';
import { Link } from 'react-router-dom';
const ViewRendering = ({
	data,
	mainHeading,
	mainHead = false,
	linkImageHeight = 150,
	linkImageWidth = 140,
	isText = false,
	heading,
	backColor = false,
	centered = false,
	centerWidth = '70%',
	centerMargin = '0 auto'
}) => {
	const btnTechHover = () => {
		$(document).ready(function() {
			$('.linkClass').hover(
				function() {
					$(this).css(
						'background',
						'linear-gradient(to bottom, #ffffff 63%, #01ac8a 101%)'
					);
					$(this).css('border-color', '#FFFFFF');
					$(this).css('border-radius', '5px');
				},
				function() {
					$(this).css('background', '');
				}
			);
		});
	};

	return (
		<div>
			{mainHead ? (
				<React.Fragment>
					<div
						style={{
							background: backColor ? '#01AC8A' : '',
							margin: backColor ? '0 auto' : '',
							width: backColor ? 'fit-content' : '',
							borderRadius: backColor ? 60 : ''
						}}
						class="h1-responsive font-weight-bold text-center my-4"
					>
						<p style={{ fontSize: 25, textAlign: 'center' }}>
							{isText ? heading : ''}
						</p>
						<img src={mainHeading} style={{ width: 450 }} alt={'#tthead'} />
						<div>
							<div>
								<hr
									style={{ margin: '0 auto', width: '60%' }}
									class="style-head"
								/>
							</div>
						</div>
					</div>
				</React.Fragment>
			) : (
				''
			)}
			<div
				className="container"
				style={{
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				<div
					class="row"
					style={{
						margin: centered ? centerMargin : '',
						width: centered ? centerWidth : ''
					}}
				>
					{data.map((item, index) => (
						<div style={{ padding: 30 }} class="col linkClass">
							<Link to={`${item.linkPath}`}>
								<img
									src={item.imagePath}
									style={{ width: linkImageWidth, height: linkImageHeight }}
									alt={`img${index}`}
								/>
							</Link>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ViewRendering;
