import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import LoaderWrapper from '../../../components/HOC/LoaderWrapper';
import SnackBar from '../../../components/SnackBar/SnackBar';
import ViewComponent from '../../../components/ViewComponent/ViewComponent';
import $ from 'jquery';
import useHttp from '../../../hooks/http';
import useCustomHttpWithDataMiddleware from '../../../hooks/Services/httpWithCustomDataMiddleware';
import {
	RENDER_SUB_TOOLS,
	RENDER_TOOLS,
	DELETE_CA,
	ASSIGN_MARKS,
	viewClassReturn,
	disableColorOverCondition
} from '../../../utils/general/index';
import Modal from '../../../components/Modal';
import Colors from '../../../utils/app_constants/colors_constants';
import httpWithCaSubToolViewDataMiddlware from '../../../hooks/Services/httpWithCaSubToolViewDataMiddlware';

function ContinuousSubToolView(props) {
	const [snackbarMessage, setMsg] = useState('');
	const [studentData, setStudentData] = useState('');
	const [classId, setClassId] = useState('');
	const [deleteId, setDeleteId] = useState('');
	const [sectionId, setSectionId] = useState('');
	const [studentId, setStudentId] = useState('');

	const [subjectId, setSubjectId] = useState('');
	const [sessionId, setSessionId] = useState('');
	const [term, setTerm] = useState('');
	const [customLoader, setCustomLoader] = useState(false);
	var [isLoading, fetchDropData] = useHttp(
		`/api/GetChildByParent?userName=${props.name}`,
		[]
	);

	const deleteHandler = (id, data, ...args) => {
		debugger;
		let getItem = data.filter(item => item.pId === parseInt(id));
		debugger;
		$(`${getItem[0]['Action'][3]['modalKey']}`).modal('show');

		setDeleteId(id);
	};
	useEffect(() => {
		if (
			props &&
			props.location &&
			props.location.state &&
			props.location.state.postFlag
		) {
			setMsg('Add Record Successfully');
			props.snackbar();
			let state = {};
			props.history.replace({ ...props.location, state });
		}
	}, []);
	const [
		isLoader,
		fetchData,
		setFetchData,
		setIsLoading
	] = httpWithCaSubToolViewDataMiddlware(
		`/api/CA_PrimaryList`,
		[],
		{
			Action: [
				{
					renderType: RENDER_TOOLS,
					isEnable: true,
					type: 'actionLink',
					exceptionCase: true,
					title: 'Create Tool',
					initialPath: '/dashboard/CASubToolView',
					iconName: 'fas fa-plus-square',
					iconColor: '#01AC8A'
				},
				{
					renderType: RENDER_SUB_TOOLS,
					isEnable: true,
					exceptionCase: true,
					type: 'actionLink',
					title: 'Create Sub Tool',
					initialPath: '/dashboard/CASubToolView',
					iconName: 'fas fa-tools',
					iconColor: '#9d9d00'
				},
				{
					renderType: ASSIGN_MARKS,
					isEnable: true,
					exceptionCase: true,
					type: 'actionLink',
					title: 'Assign Marks',
					initialPath: '/dashboard/CA_ASSIGN_MAKRS',
					iconName: 'fab fa-markdown',
					iconColor: 'red'
				},
				{
					renderType: DELETE_CA,
					handler: deleteHandler,
					isEnable: true,
					exceptionCase: true,
					type: 'modalButton',
					modalKey: '#deleteModal',
					title: 'Delete',
					initialPath: '/dashboard/CASubToolView',
					iconName: 'fas fa-trash-alt',
					iconColor: Colors.RED_DANGER
				},
				{
					isEnable: true,
					type: 'actionLink',
					title: 'View',
					initialPath: '/dashboard/ContinouseAssesmentViewList',
					iconName: 'fas fa-list',
					iconColor: Colors.SEA_GREEN_THEME
				}
			]
		},
		false,
		'PrimaryToolViews'
	);
	debugger;
	const remoteDeleteHandler = deleteId => {
		let getFetchData = $.extend(true, [], fetchData);

		debugger;
		props.sharedAction
			.deleteRecordWithoutDispatch(`/api/PrimaryInfo`, deleteId)
			.then(success => {
				debugger;
				let getFilterData = getFetchData.filter(
					item => item.pId !== parseInt(deleteId)
				);
				setFetchData(getFilterData);
				debugger;
			})
			.catch(error => {
				debugger;
			});
	};

	const onSubmit = () => {
		props.sharedAction
			.simpleAddRequest(`/api/DisciplinaryRecord`, {
				DisciplinaryRecord: [...studentData]
			})
			.then(success => {
				setTerm('Choose Term');
				setSectionId('');
				setClassId('');
				setSubjectId('');
				setSessionId('');
				setStudentData([]);
				setMsg('Successfully Submitted');
				props.snackbar();
			})
			.catch(error => {
				if (error && error.response && error.response.status == 404) {
					//404-Error no understanding of 404 Error
					setMsg('404 Not Found');

					props.snackbar();
				}
				debugger;
			});
	};
	console.log(fetchData);
	debugger;
	return (
		<div>
			<h2 className="text-center generalHead">Continuous Tools Creation</h2>
			<SnackBar backColor={'#000'} msg={snackbarMessage} />
			<LoaderWrapper marginTop={20} isLoading={isLoader ? true : false}>
				<LoaderWrapper marginTop={20} isLoading={customLoader}>
					<ViewComponent
						children={() => (
							<React.Fragment>
								{fetchData &&
									fetchData.map((item, index) => (
										<div
											class="modal fade"
											id={`deleteModal${item.pId}`}
											tabindex={`${index}`}
											role="dialog"
											aria-labelledby={`exampleModalLabel${index}`}
											aria-hidden="true"
										>
											<div class="modal-dialog" role="document">
												<div class="modal-content modal_content_custom">
													<div
														style={{
															borderBottomLeftRadius: 30,
															borderBottomRightRadius: 30,
															borderTopLeftRadius: 18,
															borderTopRightRadius: 18
														}}
														class="modal-header modal_custom_header"
													>
														<h4 style={{ color: 'white' }} class="modal-title">
															Confirm
														</h4>
														<button
															style={{ color: 'white' }}
															type="button"
															class="close"
															data-dismiss="modal"
														>
															&times;
														</button>
													</div>
													<div class="modal-body">
														<div>
															<div style={{ textAlign: 'center' }}>
																<h4>Are you sure ?</h4>
															</div>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-evenly'
																}}
															>
																<div>
																	<button
																		data-dismiss="modal"
																		onClick={() =>
																			remoteDeleteHandler(deleteId)
																		}
																		type="button"
																		class="btn btn-primary buttonAppear"
																	>
																		Yes
																	</button>
																</div>
																<div>
																	<button
																		data-dismiss="modal"
																		type="button"
																		class="btn btn-primary buttonAppear"
																	>
																		No
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									))}
							</React.Fragment>
						)}
						mainData={fetchData}
						conditionalIconsColor={disableColorOverCondition}
						exceptionalHandler={viewClassReturn}
						extraRedirectProperty="renderType"
						headData={[
							'S #',
							'Name',

							'Class Name',
							'Section',
							'Session',
							'Subject Name',
							'Course Type',

							'Grade Name',
							'Actions'
						]}
						excludedData={['pId', 'tools', 'subTools', 'term']}
						redirectIds={['pId']}
					/>
				</LoaderWrapper>
			</LoaderWrapper>
		</div>
	);
}
function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ContinuousSubToolView);
