import * as actionTypes from '../actionTypes';

export function setAllDriverHistoryInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_DRIVER_HISTORY,
		payload: allData
	};
}
export function setAllDriverNameInDriverHistorySelect(allData) {
	return {
		type: actionTypes.SET_ALL_DRIVER_NAME_IN_HISTORY_SELECT,
		payload: allData
	};
}
export function updateDriverFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_DRIVER,
		payload: setFlag
	};
}
