import * as actionTypes from '../actionTypes';

export function setAllTestInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_TEST,
		payload: allData
	};
}

export function getClassesForTest(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_TEST_SELECT,
		payload: allData
	};
}
export function getSessionForTest(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_TEST_SELECT,
		payload: allData
	};
}
export function addTest(Data) {
	return {
		type: actionTypes.ADD_TEST,
		payload: Data
	};
}
export function updateTestFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_TEST,
		payload: setFlag
	};
}
export function updateTest(data) {
	return {
		type: actionTypes.EDIT_TEST_RECORD,
		payload: data
	};
}
export function deleteTest(id) {
	return {
		type: actionTypes.DELETE_TEST,
		payload: id
	};
}
