import React, { Component } from 'react';

export default class ParentInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gridNum: 4
		};
	}
	render() {
		const { onHandleTextChange, stateData, editData } = this.props;
		return (
			<div>
				<section class="mb-4">
					<div
						class="row"
						style={{
							justifyContent: 'left',
							textAlign: 'left'
						}}
					>
						<div
							style={{ fontSize: 20, padding: 20 }}
							class="col-md-9 mb-md-0 mb-5"
						>
							<form
								id="contact-form"
								name="contact-form"
								// onSubmit={this.onHandleSubmit}
								noValidate
							>
								{/*<div class="row">*/}
								{/*<div class="col-md-12">*/}
								{/*<div class="md-form mb-0">*/}
								{/*/!*<DateandTime/>*!/*/}
								{/*</div>*/}
								{/*</div>*/}
								{/*</div>*/}
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/*<i*/}
												{/*className="fas fa-user-shield animated fadeIn"*/}
												{/*style={{ marginRight: 4 }}*/}
												{/*/>*/}
												Father Name
											</label>
											<input
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '80%'
												}}
												onChange={e => onHandleTextChange(e)}
												value={stateData.fatherPName}
												name={'fatherPName'}
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												placeholder="Father Name"
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-house animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Mother Name
											</label>
											<input
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '80%'
												}}
												onChange={e => onHandleTextChange(e)}
												value={stateData.motherName}
												name={'motherName'}
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												placeholder="Mother Name"
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-house animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Gardiuan Name
											</label>
											<input
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '80%'
												}}
												onChange={e => onHandleTextChange(e)}
												value={stateData.garduainName}
												name={'garduainName'}
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												placeholder="Graduain Name"
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-house animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Occupation
											</label>
											<input
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '80%'
												}}
												onChange={e => onHandleTextChange(e)}
												value={stateData.occupation}
												name={'occupation'}
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												placeholder="Occupation"
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-house animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Income
											</label>
											<input
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '80%'
												}}
												onChange={e => onHandleTextChange(e)}
												value={stateData.income}
												name={'income'}
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												placeholder="Income"
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-house animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Emergency Contact
											</label>
											<input
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '80%'
												}}
												onChange={e => onHandleTextChange(e)}
												value={stateData.emergencyContact}
												name={'emergencyContact'}
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												placeholder="Emergency Contact"
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-house animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Parent Mobile No
											</label>
											<input
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '80%'
												}}
												onChange={e => onHandleTextChange(e)}
												value={stateData.parentMobileNo}
												name={'parentMobileNo'}
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												placeholder="Parent Mobile No"
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-house animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Parent Email Id
											</label>
											<input
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '80%'
												}}
												onChange={e => onHandleTextChange(e)}
												value={stateData.parentEmailId}
												name={'parentEmailId'}
												type="email"
												class="form-control"
												id="exampleInputEmail1"
												placeholder="Parent Email Id"
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-house animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Parent Id Card No
											</label>
											<input
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '80%'
												}}
												onChange={e => onHandleTextChange(e)}
												value={stateData.parentIdCardNo}
												name={'parentIdCardNo'}
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												placeholder="Parent Id Card No"
											/>
										</div>
									</div>
								</div>
								<br />
								<br />
								{/*<div class="text-center text-md-center">*/}
								{/*<button class="btns">Add</button>*/}
								{/*</div>*/}
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}
