import React, { Component } from 'react';
import $ from 'jquery';
import '../Admin/viewAdmin.css';
import Axios from 'axios';
import config from '../../config/config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as routesActions from '../../Actions/Transport';
import * as sharedActions from '../../Actions/sharedActions';
import setCanteenReducer from '../../Reducers/setCanteenReducer';
import setTransportReducer from '../../Reducers/setTransportReducer';

import SnackBar from '../../components/SnackBar/SnackBar';

const AdminDetails = [
	{
		username: 'Muhammad Hamza',
		role: 'Admin',
		id: 1
	},
	{
		username: 'Muhammad Hamza',
		role: 'Admin',
		id: 1
	},
	{
		username: 'Muhammad Hamza',
		role: 'Admin',
		id: 1
	},
	{
		username: 'Muhammad Hamza',
		role: 'Admin',
		id: 1
	},
	{
		username: 'Muhammad Hamza',
		role: 'Admin',
		id: 1
	},
	{
		username: 'Muhammad Sumair',
		role: 'Admin',
		id: 2
	},
	{
		username: 'Asad Akhtar',
		role: 'Admin',
		id: 3
	}
];
class ViewRoutes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			pickUp: '',
			dropOff: '',
			km: '',
			totalKm: '',
			estimatedTime: '',
			perKmFare: '',
			fare: '',
			enabled: false,
			id: null,
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: ''
		};
	}
	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
			this.setState({
				disablepage: false
			});
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.adminData !== nextProps.AllTransportData) {
			return {
				adminData: nextProps.AllTransportData
			};
		}
		return null;
	}
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		if (this.props.allTransportState.apiFlag) {
			this.props.sharedActions
				.getAllData(
					'/api/Route',
					this.props.routesActions.setAllTransportInRedux,
					this.props.routesActions.updateTransportFlag
				)
				.then(success => {
					this.setState({
						isLoading: false
					});
				})
				.catch(err => {
					this.props.routesActions.updateTransportFlag(false);

					this.setState({
						isLoading: false
					});

					console.log(err);
				});
		}
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/Route/',
				itemId,
				this.props.routesActions.deleteTransport
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.id === itemId);

		this.setState({
			modalData: getData,
			pickUp: getData[0].pickUp,
			dropOff: getData[0].dropOff,
			km: getData[0].km,
			totalKm: getData[0].totalKm,
			estimatedTime: getData[0].estimatedTime,
			perKmFare: getData[0].perKmFare,
			fare: getData[0].fare,
			id: getData[0].id
		});
	};

	onHandleText = e => {
		console.log(e.target.value);
		let x = Number(e.target.value) * Number(this.state.totalKm);
		this.setState({
			[e.target.name]: e.target.value,
			fare: x
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const {
			id,
			pickUp,
			dropOff,
			km,
			estimatedTime,
			totalKm,
			fare,
			perKmFare
		} = this.state;
		var bodyParameters = {
			id,
			pickUp,
			dropOff,
			km,
			estimatedTime,
			totalKm,
			fare,
			perKmFare,
			date: new Date().toDateString(),
			time: new Date().toTimeString()
		};
		this.props.sharedActions
			.editRecord(
				'/api/Route/',
				id,
				bodyParameters,
				this.props.routesActions.updateTransport
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				this.props.snackbar();
			})
			.catch(err => {});
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};
	render() {
		const { adminData, modalData } = this.state;

		console.log('view routes props', this.props);
		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return index <= 3 ? (
				<li
					class={`page-item ${
						this.state.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={this.handleClickNext}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			) : (
				''
			);
		});
		const { currentPage, todosPerPage } = this.state;
		const indexOfLastTodo = currentPage * todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
		const renderTodos = currentTodos.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" className="bordersSpace" style={{ padding: 1 }}>
						{index + 1}
					</th>
					<td className="bordersSpace" style={{ padding: 4 }}>
						{item.pickUp}
					</td>
					<td className="bordersSpace" style={{ padding: 4 }}>
						{item.dropOff}
					</td>
					<td className="bordersSpace" style={{ padding: 4 }}>
						{item.km}
					</td>
					<td className="bordersSpace" style={{ padding: 4 }}>
						{item.totalKm}
					</td>
					<td className="bordersSpace" style={{ padding: 4 }}>
						{item.estimatedTime}
					</td>
					<td className="bordersSpace" style={{ padding: 4 }}>
						{item.perKmFare}
					</td>
					<td className="bordersSpace" style={{ padding: 4 }}>
						{item.fare}
					</td>
					<td className="bordersSpace" style={{ padding: 4 }}>
						{item.date}
					</td>
					<td className="bordersSpace" style={{ padding: 4 }}>
						{item.time}
					</td>
					<td className="bordersSpace" style={{ padding: 4 }}>
						<a href="#" data-toggle="tooltip" title="Edit!">
							<button
								id="firstbutton"
								onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								data-toggle="modal"
								data-target="#myModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i class="fas fa-pen"></i>
							</button>
						</a>
						<a href="#" data-toggle="tooltip" title="Delete!">
							<button
								onClick={() => this.onEditClick(item.id)}
								data-toggle="modal"
								data-target="#myModal1"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
							</button>
						</a>
					</td>
				</tr>
			);
		});
		const MainContent = (
			<div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<form onSubmit={this.onHandleSubmit}>
									{modalData &&
										modalData.map((item, index) => (
											<div key={index}>
												<fieldset disabled={!this.state.enabled}>
													<div class="form-group">
														<label for="routeName">Per Km Fare:</label>
														<input
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.perKmFare}
															onChange={this.onHandleText}
															name="perKmFare"
															class="form-control"
															id="perKmRoute"
														/>
													</div>
													<div class="form-group">
														<label for="routeFees">Total Fare</label>
														<input
															readOnly={true}
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.fare}
															onChange={this.onHandleText}
															type="text"
															name="fare"
															class="form-control"
															id="fare"
														/>
													</div>
												</fieldset>
												<button
													disabled={this.state.enabled}
													onClick={() => this.setState({ enabled: true })}
													type="button"
													class="btn btn-primary buttonAppear"
												>
													Edit
												</button>
												<button
													style={{ marginLeft: 5 }}
													disabled={!this.state.enabled}
													type="submit"
													class="btn btn-primary buttonAppear"
												>
													Save
												</button>
											</div>
										))}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				{/* <div className='table-responsive' > */}
				<table class="table table-hover" style={{ textAlign: 'center' }}>
					<thead style={{ background: '#01AC8A', color: 'white' }}>
						<tr>
							<th scope="col" style={{ padding: 4 }}>
								#
							</th>
							<th scope="col" style={{ padding: 4 }}>
								Pick Up
							</th>
							<th scope="col" style={{ padding: 4 }}>
								Drop Off
							</th>
							<th scope="col" style={{ padding: 4 }}>
								Km
							</th>
							<th scope="col" style={{ padding: 4 }}>
								Total Km
							</th>
							<th scope="col" style={{ padding: 4 }}>
								Estimated Time
							</th>
							<th scope="col" style={{ padding: 4 }}>
								Per Km Fare
							</th>
							<th scope="col" style={{ padding: 4 }}>
								Fare
							</th>
							<th scope="col" style={{ padding: 4 }}>
								Date
							</th>
							<th scope="col" style={{ padding: 4 }}>
								Time
							</th>
							<th scope="col" style={{ padding: 4 }}>
								Actions
							</th>
						</tr>
					</thead>
					<tbody>{renderTodos}</tbody>
				</table>
			</div>
			// </div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Route&nbsp;Details</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								<li
									class={`page-item ${
										this.state.disableBPage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextBack}
										class="page-link"
										href="#"
									>
										{this.state.disableBPage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-backward"></i>
										)}
									</a>
								</li>
								{renderPageNumbers}
								<li
									class={`page-item ${
										this.state.disablepage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextFrwd}
										class="page-link"
										href="#"
									>
										{this.state.disablepage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-forward"></i>
										)}
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				{this.props.allTransportState.apiFlag ? Loader : MainContent}
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		routesActions: bindActionCreators(routesActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllTransportData: state.setTransportReducer.Data,
		allTransportState: state.setTransportReducer,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewRoutes);
