import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import TextInput from '../../components/TextInput/TextInput'
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../Actions/sharedActions';
import Button from '../../components/Button';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';

class AddGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            accountId: props.name,
            accountType: props.role,
            errors: {},
            errorMsg: false,
            game: ''
        }
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false
                });
            }, 3000);
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }

    onHandleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { game, accountId, accountType } = this.state;

        if (game === '') {
            this.setState({
                error: true,
                errorMsg: true
            })
        }
        else {
            this.setState({
                isLoading: true
            })
            const data = {
                game,
                accountId,
                accountType
            }
            console.log(data);
            this.props.sharedActions
                .simpleAddRequest('/api/Game/',
                    data)
                .then(success => {
                    this.setState({
                        msg: 'Added Game Successfully',
                        isLoading: false,
                        game: '',
                        errorMsg: false,
                        error: false,
                    })
                    this.props.snackbar();
                })
        }

    }
    render() {
        const { errors, } = this.state;

        const Form = (
            <FormLayoutWrapper
                formName='Add Game'
                borderColor='#01ac8a'
                borderRadius={15}
            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly
                    </p>
                ) : (
                        ''
                    )}


                <div className="row">
                    <TextInput
                        feildName={'accountId'}
                        inputLabelName={'Admin Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Admin Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        feildName={'accountType'}
                        inputLabelName={'Account Type'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Account Type"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        feildName={'game'}
                        inputLabelName={'Game Name'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        placeholder={'Game Name'}
                        isValidte={false}
                    />

                </div>
                <br />
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Add"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={20}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                    textColor={'white'}
                />

            </FormLayoutWrapper>
        )
        return (
            <React.Fragment>
                <SnackBar msg={this.state.msg} />
                {Form}
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddGame);