import React, { Component } from 'react';

const GeneralSelectionOptions = props => {
	const {
		sessionData,
		currentSession,
		campusName,
		campusData,
		gradeData,
		section,
		classId,
		sectionType,
		data,
		sports,
		pickUp,
		routeData
	} = props;

	let getGradeData = null;
	switch (sectionType) {
		case 'StudentAdmissionForms':
			const { grade } = gradeData;

			getGradeData =
				grade &&
				grade.filter((item, index) => item.classId === parseInt(classId));

			console.log(getGradeData);
			break;

		default:
			break;
	}

	return (
		<React.Fragment>
			(sectionType === 'StudentAdmissionForms') ? ({' '}
			<option>
				{sectionType === 'StudentAdmissionForms'
					? 'Section...'
					: sectionType === 'OtherInformations'
					? 'Sports...'
					: sectionType === 'StudentAdmissionFormsForCampus'
					? 'Campus Name...'
					: sectionType === 'StudentAdmissionFormsForSessionData'
					? 'Session...'
					: sectionType === 'StudentTransport'
					? 'Routes Name...'
					: ''}
			</option>
			{getGradeData &&
				getGradeData.map((item, index) => (
					<option
						value={item.sectionId}
						selected={item.section === section ? true : false}
					>
						{item.section}
					</option>
				))}
			) :(sectionType === 'OtherInformations') ? (
			{data &&
				data.map((item, index) => (
					<option value={item} selected={item === sports ? true : false}>
						{item}
					</option>
				))}
			) : (sectionType === 'StudentAdmissionFormsForCampus') ? (
			{campusData &&
				campusData.map((item, index) => (
					<option
						value={`${JSON.stringify(item)}`}
						selected={item.campusName === campusName ? true : false}
					>
						{item.campusName}
					</option>
				))}
			) :(sectionType === 'StudentAdmissionFormsForSessionData') ? (
			{sessionData &&
				sessionData.map((item, index) => (
					<option
						value={item.sessionId}
						selected={item.currentSession === currentSession ? true : false}
					>
						{item.currentSession}
					</option>
				))}
			) : (sectionType === 'StudentTransport') ? (
			{routeData &&
				routeData.map((item, index) => (
					<option
						value={item.pickUp}
						selected={item.pickUp === pickUp ? true : false}
					>
						{item.pickUp}
					</option>
				))}
			) : '' ;
		</React.Fragment>
	);
};
export default GeneralSelectionOptions;
