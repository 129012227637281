import React, { Component } from 'react';
import $ from 'jquery';
import RenderDynamicRows from '../Transport/RenderDynamicRows';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class AddMultipleItems extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('#addBtn').hover(
				function() {
					$(this).css(
						'background',
						'linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)'
					);
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('background', '#0A4F5E');
				}
			);
		});
	};
	render() {
		const {
			onHandleTextChange,
			stateData,
			updateSpecialArrObjOnChange,
			onClickAddItem,
			onClickDeleteItem
		} = this.props;
		return (
			<div>
				<h3 class="h1-responsive font-weight-bold text-left my-4">
					Multiple Items
				</h3>

				<div style={{ width: '100%' }}>
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Particular</th>
								<th scope="col">Description</th>
								<th scope="col">Amount</th>
								<th scope="col">Account Id</th>
								<th scope="col">Account Type</th>
							</tr>
						</thead>
						<tbody>
							{stateData &&
								stateData.itemsBudget.map((item, index) => (
									<tr key={index}>
										<th scope={'row'}>{index + 1}</th>
										<RenderDynamicRows
											arrName={'itemsBudget'}
											updateSpecialArrObjOnChange={updateSpecialArrObjOnChange}
											rowIndex={index}
											onHandleTextChange={onHandleTextChange}
											stateData={stateData}
											itemObj={item}
										/>
									</tr>
								))}
						</tbody>
					</table>
					<div className={'row'} style={{ justifyContent: 'flex-end' }}>
						<div>
							<button
								id={'addBtn'}
								onMouseEnter={this.btnTechHover()}
								onClick={() =>
									onClickAddItem('itemsBudget', {
										Particular: '',
										Description: '',
										Amount: '',
										AccountId: this.props.name,
										AccountType: this.props.role
									})
								}
								style={{
									background: '#0A4F5E',
									color: '#FFFFFF',
									borderRadius: 100
								}}
								type="button"
								class="btn"
							>
								<i class="fas fa-plus" />
							</button>
						</div>
						<div style={{ marginLeft: 8 }}>
							<button
								onClick={() => onClickDeleteItem('itemsBudget')}
								style={{ color: '#FFFFFF', borderRadius: 100 }}
								type="button"
								class="btn btn-danger"
							>
								<i class="far fa-trash-alt" />
							</button>
						</div>
					</div>
				</div>
				<br />
				<br />
				<br />
				{/*<div class="text-center text-md-center">*/}
				{/*<button class="btns">Add</button>*/}
				{/*</div>*/}
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

export default connect(mapStateToProps)(AddMultipleItems);
