import React from 'react';
import ViewRendering from '../components/ViewRendering';
import { renderingStuff, images } from '../utils';

export default function ParentPortalManagerDashboard() {
	return (
		<div>
			<ViewRendering
				centered={true}
				centerWidth={'50%'}
				mainHeading={images.imgAcHead}
				data={renderingStuff.ParentPortalManager_Links}
				mainHead={false}
			/>
		</div>
	);
}
