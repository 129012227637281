import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	select: []
};

export default function setDriverHistoryReducer(
	state = defaultState,
	action = {}
) {
	switch (action.type) {
		case actionTypes.GET_ALL_DRIVER_HISTORY:
			return { ...state, Data: action.payload.driverDetail.DriverHistories };

		case actionTypes.SET_LOADER_FLAG_DRIVER:
			return { ...state, apiFlag: action.payload };

		case actionTypes.SET_ALL_DRIVER_NAME_IN_HISTORY_SELECT:
			return {
				...state,
				select: [...action.payload]
			};
		default:
			return state;
	}
}
