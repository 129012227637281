import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ExamActions from '../../Actions/Exam';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';

class AddExam extends Component {
	constructor(props) {
		super(props);
		this.state = {
			examName: '',
			accountId: '',
			accountType: '',
			msg: false,
			errorMsg: false
		};
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	handleValidation() {
		let fields = this.state.campusName;
		let errors = {};
		let formIsValid = true;

		//Name
		if (!fields) {
			formIsValid = false;
			errors['name'] = 'Cannot be empty';
		}

		if (typeof fields['name'] !== 'undefined') {
			if (!fields['name'].match(/^[a-zA-Z]+$/)) {
				formIsValid = false;
				errors['name'] = 'Only letters';
			}
		}

		//Email
		if (!fields['email']) {
			formIsValid = false;
			errors['email'] = 'Cannot be empty';
		}

		if (typeof fields['email'] !== 'undefined') {
			let lastAtPos = fields['email'].lastIndexOf('@');
			let lastDotPos = fields['email'].lastIndexOf('.');

			if (
				!(
					lastAtPos < lastDotPos &&
					lastAtPos > 0 &&
					fields['email'].indexOf('@@') == -1 &&
					lastDotPos > 2 &&
					fields['email'].length - lastDotPos > 2
				)
			) {
				formIsValid = false;
				errors['email'] = 'Email is not valid';
			}
		}

		this.setState({ errors: errors });
		return formIsValid;
	}
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	handleSubmit = e => {
		const { examName } = this.state;
		e.preventDefault();
		if (Object.is(examName, '')) {
			this.setState({
				error: true,
				errorMsg: true
			});
		} else {
			console.log(this.state);
			const { examName } = this.state;
			var bodyParameters = {
				examName,
				accountType: this.props.userData.role,
				accountId: this.props.userData.unique_name
			};

			this.props.sharedActions
				.addRecord('/api/Exam', bodyParameters, this.props.ExamActions.addExam)
				.then(success => {
					this.setState({
						examName: '',
						msg: true,
						errorMsg: false
					});
					// this.props.campusActions.updateCampusFlag(true);
				})
				.catch(err => {
					console.log(err);
				});
		}
	};
	inputData = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	render() {
		return (
			<div className="container">
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Exam
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.state.errorMsg ? (
								<p style={{ color: 'red', textAlign: 'center' }}>
									Please fill the form properly
								</p>
							) : (
								''
							)}
							{this.state.msg ? (
								<p style={{ color: 'green', textAlign: 'center' }}>
									Added Successfully!
								</p>
							) : (
								''
							)}
							<form
								onSubmit={this.handleSubmit}
								id="contact-form"
								name="contact-form"
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-user-shield"
													style={{ marginRight: 4 }}
												/>
												Add Exam
											</label>
											<select
												onChange={this.inputData}
												class="custom-select"
												name="examName"
											>
												<option>Choose Exam..</option>
												<option value="1st Terminal">1st Terminal</option>
												<option value="2nd Terminal">2nd Terminal</option>
												<option value="3rd Terminal">3rd Terminal</option>
											</select>
										</div>
									</div>
								</div>
								<br />
								<br />
								<div class="text-center text-md-center">
									<button type="submit" class="btns">
										Add
										<span
											style={{ marginBottom: 5 }}
											class={`${
												this.state.isLoading
													? 'spinner-border spinner-border-sm'
													: ''
											}`}
										></span>
									</button>
								</div>
							</form>

							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		ExamActions: bindActionCreators(ExamActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddExam);
