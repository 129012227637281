import React from 'react';
import { connect } from 'react-redux';
import ViewRendering from '../components/ViewRendering';
import { renderingStuff, images } from '../utils';
import HrDashboard from '../Container/Hr_Component/HrDashboard';
import CommunicationDashboard from '../Container/ComunicationDashboard';
import StaffDashboard from '../Container/StaffDashboard';
import RoleTypes from '../utils/app_constants/app_constants';
import AdminstrationDashboard from '../Container/AdministrationDashboard';
import AdmissionDashboard from './AdmissionDashboard';
import StudentAdmissionDashboard from './StudentAdmissionDashboard';
import FinanceDashboard from './FinanceDashboard';
import FeesDashboard from './FeesDashboard';
import BillingDashboard from './BillingDashboard';
import CampusMaintenanceDashboard from './CampusMaintenanceDashboard';
import SecurityDashboard from './SecurityDashboard';
import ProcurementDashboard from './ProcurementDashboard';
import PastoralDashboard from './PastoralDashboard';
import ArabicHODDashboard from './ArabicHODDashboard';
import ArabicDeanDashboard from './DeanDashboards/ArabicDeanDashboard';
import ActivityDeanDashboard from './DeanDashboards/ActivityDeanDashboard';
import EnglishDeanDashboard from './DeanDashboards/EnglishDeanDashboard';
import LanguageCoordinatorDashboard from './AcademicCoordinatorDashboard/LanguageCoordinatorDashboard';
import MathsScienceCoordinatorDashboard from './AcademicCoordinatorDashboard/MathsScienceCoordinatorDashboard';
import BusinessIctCoordinatorDashboard from './AcademicCoordinatorDashboard/BusinessIctCoordinatorDashboard';
import HODPhysics from './AcademicHODDashboard/HODPhysics';
function Dashboard(props) {
	const Owner = (
		<div>
			<h4 className="dashboardHead text-center">Major Modules</h4>
			<ViewRendering data={renderingStuff.ownerLinks} />
		</div>
	);
	const Acadmic = (
		<ViewRendering
			mainHeading={images.imgAcHead}
			data={renderingStuff.academicLinks}
			mainHead={true}
		/>
	);
	const GeneralManager = (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column'
			}}
		>
			<h2 class="animated slideInLeft">General Manager Dashboard</h2>
			<p style={{ fontSize: 20 }} class="animated slideInRight">
				Working in Progress.. <i class="far fa-smile"></i>
			</p>
		</div>
	);
	const Student = (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column'
			}}
		>
			<h2 class="animated slideInLeft">Student Dashboard</h2>
			<p style={{ fontSize: 20 }} class="animated slideInRight">
				Work in Progress.. <i class="far fa-smile"></i>
			</p>
		</div>
	);
	const Parent = (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column'
			}}
		>
			<h2 class="animated slideInLeft">Parent Dashboard</h2>
			<p style={{ fontSize: 20 }} class="animated slideInRight">
				Working in Progress.. <i class="far fa-smile"></i>
			</p>
		</div>
	);
	const Hod = (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column'
			}}
		>
			<h2 class="animated slideInLeft">HOD Dashboard</h2>
			<p style={{ fontSize: 20 }} class="animated slideInRight">
				Working in Progress.. <i class="far fa-smile"></i>
			</p>
		</div>
	);
	const Communication = <CommunicationDashboard />;
	const Admission = <AdmissionDashboard />;
	const AdmissionManager = <StudentAdmissionDashboard />;
	const Administration = <AdminstrationDashboard />;
	const Finance = (
		<FinanceDashboard />
		// <ViewRendering
		// 	mainHead={true}
		// 	data={renderingStuff.financeLinks}
		// 	mainHeading={images.imgFHead}
		// />
	);
	const HR = <HrDashboard />;
	const Staff = <StaffDashboard />;
	const Accountant = <FeesDashboard />;
	const Billing = <BillingDashboard />;
	const EntryExamCoordinator = (
		<div>
			<h4 class="text-center">Entry Exam Dashboard</h4>
		</div>
	);
	const RegistrationCoordinator = (
		<div>
			<h4 class="text-center">Registration Dashboard</h4>
		</div>
	);
	const WellBeingCoordinator = (
		<div>
			<h4 class="text-center">Well Being Dashboard</h4>
		</div>
	);
	const ActivityCoordinator = (
		<div>
			<h4 class="text-center">Activity Dashboard</h4>
		</div>
	);
	const MedicalStaff = (
		<div>
			<h4 class="text-center">Medical Staff Dashboard</h4>
		</div>
	);
	const StaffCoordinator = (
		<div>
			<h4 class="text-center">Staff Coordinator Dashboard</h4>
		</div>
	);
	const StudentCoordinator = (
		<div>
			<h4 class="text-center">Student Coordinator Dashboard</h4>
		</div>
	);
	const MaintenanceCoordinator = (
		<div>
			<h4 class="text-center">Maintenance Coordinator Dashboard</h4>
			<ViewRendering data={renderingStuff.MaintenanceManager_Dashboard_Links} />
		</div>
	);
	const TransportCoordinator = (
		<div>
			<h4 class="text-center">Transport Coordinator Dashboard</h4>
		</div>
	);
	const CanteenCoordinator = (
		<div>
			<h4 class="text-center">Canteen Coordinator Dashboard</h4>
		</div>
	);
	const PortalCoordinator = (
		<div>
			<h4 class="text-center">Portal Coordinator Dashboard</h4>
		</div>
	);
	const ExamCoordinator = (
		<div>
			<h4 class="text-center">Exam Coordinator Dashboard</h4>
		</div>
	);
	const TimetableCoordinator = (
		<div>
			<h4 class="text-center">TimeTable Coordinator Dashboard</h4>
		</div>
	);
	const NewsCoordinator = (
		<div>
			<h4 class="text-center">News Coordinator Dashboard</h4>
		</div>
	);
	const SEN = (
		<div>
			<h4 class="text-center">SEN Dashboard</h4>
		</div>
	);
	const FrontDesk = (
		<div>
			<h4 class="text-center">Fron Desk Dashboard</h4>
		</div>
	);
	const CampusMaintenance = <CampusMaintenanceDashboard />;
	const Security = <SecurityDashboard />;
	const Procurement = <ProcurementDashboard />;
	const Pastoral = <PastoralDashboard />;

	//Dean Dashboards

	const ArabicDashboard = <ArabicDeanDashboard />;
	const ActivityDashboard = <ActivityDeanDashboard />;
	const EnglishDashboard = <EnglishDeanDashboard />;

	//Dean Dashboards END

	//Coordinator Dashboard Start
	const CoordinatorLanguage = <LanguageCoordinatorDashboard />;
	const CoordinatorMathsandScience = <MathsScienceCoordinatorDashboard />;
	const CoordinatorBusinessICT = <BusinessIctCoordinatorDashboard />;

	//Coordinator Dashboard End

	//HOD Dashboard Start
	const HODPhysicsDash = <HODPhysics />;

	//HOD Dashboard END

	console.log(props);

	return props.users.role.toLowerCase() === RoleTypes.OWNER
		? Owner
		: props.users.role.toLowerCase() === RoleTypes.ACADMIC_SMART
		? Acadmic
		: props.users.role.toLowerCase() === RoleTypes.STUDENT_SMART
		? Student
		: props.users.role.toLowerCase() === RoleTypes.STAFF_SMART
		? Staff
		: props.users.role.toLowerCase() === RoleTypes.FINANCE_SMART
		? Finance
		: props.users.role.toLowerCase() === RoleTypes.HR_SMART
		? HR
		: props.users.role.toLowerCase() === RoleTypes.PARENT_SMART
		? Parent
		: props.users.role.toLowerCase() === RoleTypes.HOD_SMART
		? Hod
		: props.users.role.toLowerCase() === RoleTypes.COMMUNICATION_SMART
		? Communication
		: props.users.role.toLowerCase() === RoleTypes.GENERAL_MANAGER_SMART
		? GeneralManager
		: props.users.role.toLowerCase() === RoleTypes.ADMISSION_SMART
		? Admission
		: props.users.role.toLowerCase() === RoleTypes.ADMINISTRATION_SMART
		? Administration
		: props.users.role.toLowerCase() === RoleTypes.ADMISSION_MANAGER
		? AdmissionManager
		: props.users.role.toLowerCase() === RoleTypes.ACCOUNTANT_MANAGER
		? Accountant
		: props.users.role.toLowerCase() === RoleTypes.BILLING_MANAGER
		? Billing
		: props.users.role.toLowerCase() === RoleTypes.ENTRY_EXAM_COORDINATOR
		? EntryExamCoordinator
		: props.users.role.toLowerCase() === RoleTypes.ACTIVITY_COORDINATOR
		? ActivityCoordinator
		: props.users.role.toLowerCase() === RoleTypes.WELLBEING_COORDINATOR
		? WellBeingCoordinator
		: props.users.role.toLowerCase() === RoleTypes.MEDICAL_STAFF
		? MedicalStaff
		: props.users.role.toLowerCase() === RoleTypes.STAFF_COORDINATOR
		? StaffCoordinator
		: props.users.role.toLowerCase() === RoleTypes.STUDENT_COORDINATOR
		? StudentCoordinator
		: props.users.role.toLowerCase() === RoleTypes.REGISTRATION_COORDINATOR
		? RegistrationCoordinator
		: props.users.role.toLowerCase() === RoleTypes.MAINTENANCE_COORDINATOR
		? MaintenanceCoordinator
		: props.users.role.toLowerCase() === RoleTypes.TRANSPORT_COORDINATOR
		? TransportCoordinator
		: props.users.role.toLowerCase() === RoleTypes.CANTEEN_COORDINATOR
		? CanteenCoordinator
		: props.users.role.toLowerCase() === RoleTypes.PORTAL_COORDINATOR
		? PortalCoordinator
		: props.users.role.toLowerCase() === RoleTypes.EXAM_COORDINATOR
		? ExamCoordinator
		: props.users.role.toLowerCase() === RoleTypes.TIMETABLE_COORDINATOR
		? TimetableCoordinator
		: props.users.role.toLowerCase() === RoleTypes.NEWS_COORDINATOR
		? NewsCoordinator
		: props.users.role.toLowerCase() === RoleTypes.SEN
		? SEN
		: props.users.role.toLowerCase() === RoleTypes.FRONT_DESK
		? FrontDesk
		: props.users.role.toLowerCase() === RoleTypes.SECURITY_MANAGER
		? Security
		: props.users.role.toLowerCase() === RoleTypes.PROCUREMENT_MANAGER
		? Procurement
		: props.users.role.toLowerCase() === RoleTypes.PASTORAL_SMART
		? Pastoral
		: props.users.role.toLowerCase() === RoleTypes.DEAN_ARABIC
		? ArabicDashboard
		: props.users.role.toLowerCase() === RoleTypes.DEAN_ACTIVITIES
		? ActivityDashboard
		: props.users.role.toLowerCase() === RoleTypes.DEAN_ENGLISH
		? EnglishDashboard
		: props.users.role.toLowerCase() === RoleTypes.COORDINATOR_LANGUAGE
		? CoordinatorLanguage
		: props.users.role.toLowerCase() === RoleTypes.Coordinator_Maths_Sciences
		? CoordinatorMathsandScience
		: props.users.role.toLowerCase() === RoleTypes.Coordinator_Business_Ict
		? CoordinatorBusinessICT
		: props.users.role.toLowerCase() === RoleTypes.PHYSICS_HOD
		? HODPhysicsDash
		: '';
}

function mapStateToProps(state) {
	return {
		users: state.setUserReducer.users
	};
}

export default connect(
	mapStateToProps,
	null
)(Dashboard);
