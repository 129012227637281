import React from 'react';
import DatePicker from 'react-date-picker';
import { Types } from '../../hooks/HookReducers/StaffReducer';
import ValidationText from '../../utils/app_constants/validate_messages_constants';

const CustomDatePicker = props => {
	const {
		edit = false,
		editOnDateChange,
		emptyDate = false,
		newHandleFlag = false,
		rowIndex,
		newError = {},
		onDateChange,
		arrName,
		marginTop,
		dumpStateFlag,
		feildName,
		dispatchFlag,
		iconClassName,
		iconColor,
		textFont,
		dispatch,
		inputLabelName,
		onChangeDate,
		stateData,
		extraType = 'none',
		reuse = false
	} = props;

	return (
		<React.Fragment>
			<div
				class="col-md-6"
				style={{
					marginTop: marginTop,
					display: 'flex',
					flexDirection: 'column',
					position: `${reuse ? 'relative' : ''} `,
					bottom: 20
				}}
			>
				<div class="md-form mb-0">
					<label for={feildName}>
						<i
							className={iconClassName}
							style={{ marginRight: 4, color: iconColor }}
						/>
						<p style={{ margin: 0, fontSize: textFont, color: '#000' }}>
							{inputLabelName}
						</p>
					</label>
					<div>
						<DatePicker
							// style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
							className={reuse ? 'dateStyle' : ''}
							tileClassName={reuse ? 'dateStyle' : ''}
							onChange={
								edit
									? e => editOnDateChange(e, feildName)
									: dispatchFlag
									? date =>
											dispatch({
												type: Types.ON_DATE_CHANGE,
												payload: { name: feildName, date, type: extraType }
											})
									: dumpStateFlag
									? e => onChangeDate(e, stateData, feildName)
									: reuse
									? date => onDateChange(date, feildName, rowIndex)
									: e => onChangeDate(e, feildName)
							}
							value={
								newHandleFlag
									? new Date(stateData[feildName])
									: emptyDate
									? stateData[feildName]
									: reuse
									? stateData[arrName][rowIndex][feildName]
									: stateData[feildName]
							}
						/>
						{Object.keys(newError).length > 0 && newError[feildName] ? (
							<p style={{ color: 'red', fontSize: 15 }}>
								{ValidationText.FIELD_REQUIRED}
							</p>
						) : (
							''
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default CustomDatePicker;
