export default function alphaNumericReturn(Num) {
	return Num === 1
		? `${Num}`.concat('st')
		: Num === 2
		? `${Num}`.concat('nd')
		: Num === 3
		? `${Num}`.concat('rd')
		: Num === 4
		? `${Num}`.concat('th')
		: `${Num}`.concat('th');
}
