import React, { Component } from 'react';

export default function GeneralCheckRendering(props) {
	const {
		periodsData,
		onChangeCheck,
		valuePropName,
		staticDisplayProp,
		nameCheckCom
	} = props;

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				position: 'relative',
				top: 15
			}}
		>
			{periodsData.map((item, index) => (
				<label className="containerCheck">
					{staticDisplayProp}
					{item[valuePropName]}
					<input
						onChange={e => props.onChangeCheck(e)}
						value={item[valuePropName]}
						type="checkbox"
						name={nameCheckCom}
					/>
					<span className="checkBoxmark"></span>
					{nameCheckCom === 'PeriodRendering' && item.type === 'break' ? (
						<div>
							<span class="badge badge-pill badge-warning">{'break'}</span>{' '}
						</div>
					) : (
						''
					)}
				</label>
			))}
		</div>
	);
}
