import React from 'react';

export default function SubItemComponent(props) {
	const { keyItem, data, rowIndex, key } = props;
	return (
		<React.Fragment>
			<td style={{ textAlign: 'center' }} key={key}>
				{data[rowIndex][keyItem]}
			</td>
		</React.Fragment>
	);
}
