import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StockActions from '../../Actions/Stock';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import $ from 'jquery';

class AddStock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stockIn: '',
			itemId: '',
			itemName: '',
			select: '',
			Barcode: '',
			barcodeId: '',
			check: false,
			barcodeCheck: true,
			errorMsg: false,
			msg: false,
			bCode: false
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.select !== nextProps.selectData ||
			prevState.Barcode !== nextProps.BarcodeData
		) {
			return {
				select: nextProps.selectData,
				Barcode: nextProps.BarcodeData
			};
		}
		return null;
	}

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onPrice = e => {
		const re = /^[0-9\b]+$/;

		// if value is not blank, then test the regex
		if (e.target.value === '' || re.test(e.target.value)) {
			this.setState({ stockIn: e.target.value });
			console.log(this.state.stockIn);
		}
	};
	barcodeValid = e => {
		e.preventDefault();
		this.setState({
			barcodeCheck: !this.state.barcodeCheck
		});
	};

	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			itemId: result.id
		});
	};
	onSelectBarcodeChange = e => {
		console.log(e.target.value);
		this.setState({
			barcodeId: e.target.value
		});

		this.props.sharedActions
			.gAllData(
				`/api/barcodes/?barcode=${e.target.value}`,
				this.props.StockActions.getItemsForStockBarcode
			)
			.then(success => {
				this.setState({
					bCode: true,
					itemId: this.state.Barcode.id,
					itemName: this.state.Barcode.name
				});
			})
			.catch(error => {
				this.setState({
					bCode: false
				});
			});
	};

	onDisableCheck = e => {
		this.setState({
			barcodeCheck: !this.state.barcodeCheck,
			check: !this.state.check
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const { stockIn, itemId } = this.state;
		if (Object.is(stockIn, '')) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			var bodyParameters = {
				stockIn: this.state.stockIn,
				itemId: this.state.itemId,
				accountId: this.props.userData.unique_name,
				accountType: this.props.userData.role
			};
			const check = this.state.itemId;
			this.props.sharedActions
				.addRecord(
					`/api/StockDetails/${check}`,
					bodyParameters,
					this.props.StockActions.addStock
				)
				.then(success => {
					// this.props.adminActions.updateGlobalFlag(true);
					this.setState({
						stockIn: '',
						itemId: '',
						select: '',
						barcodeId: '',
						barcodeCheck: true,
						bCode: false,
						msg: true,
						errorMsg: false,
						check: false
					});
				})
				.catch(error => {});
		}
		console.log(this.state);
	};
	componentDidMount() {
		this.props.sharedActions
			.gAllData('/api/InventoryItems', this.props.StockActions.getItemsForStock)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
		$('[data-toggle="tooltip"]').tooltip();
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};
	render() {
		console.log(this.state.Barcode, 'Barcode Data');
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Stock
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.Message(
								'Stock Added Successfully!',
								'success',
								20,
								this.state.msg
							)}
							{this.Message(
								'Please fill the form properly!',
								'danger',
								20,
								this.state.errorMsg
							)}
							<form>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-user-shield animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Admin Name
											</label>
											<input
												readOnly={true}
												value={this.props.userData.unique_name}
												type="text"
												name="AdminName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-user-alt animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Admin Type
											</label>
											<input
												readOnly={true}
												value={this.props.userData.role}
												type="text"
												name="AdminType"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-layer-group"
													style={{ marginRight: 4 }}
												/>
												Stock In
											</label>
											<input
												value={this.state.stockIn}
												type="text"
												name="stockIn"
												class="form-control"
												onChange={this.onPrice}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												<i
													style={{ marginRight: 4 }}
													className="fas fa-list animated fadeIn"
												/>
												Items Name
											</label>
											<select
												onChange={this.onSelectChange}
												class="custom-select"
												name="itemId"
												style={
													this.state.bCode ? { borderColor: 'green' } : null
												}
											>
												{this.state.bCode ? (
													<option>{this.state.itemName}</option>
												) : (
													<option selected={this.state.msg ? true : false}>
														Choose Items...
													</option>
												)}
												{this.state.select.map((item, index) => (
													<option key={index} value={`${JSON.stringify(item)}`}>
														{item.name}
													</option>
												))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-barcode"
													style={{ marginRight: 4 }}
												/>
												Barcode
											</label>
											<input
												disabled={this.state.barcodeCheck}
												type="text"
												name="barcode"
												value={this.state.barcodeId}
												class="form-control"
												onChange={this.onSelectBarcodeChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 60 }}>
										<div class="md-form mb-0">
											<label for="name" class="" style={{ marginRight: 230 }}>
												<input
													type="checkbox"
													onClick={this.onDisableCheck}
													style={{ marginRight: 3 }}
													checked={this.state.check}
												/>
												Enable Barcode
											</label>
										</div>
									</div>
								</div>

								<br />
								<br />
								<div class="text-center text-md-center">
									<button
										onClick={this.onHandleSubmit}
										class="btns"
										id="contact-form"
									>
										Add
									</button>
								</div>
							</form>

							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		StockActions: bindActionCreators(StockActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectData: state.setStockReducer.select,
		BarcodeData: state.setStockReducer.barcodeSelect,
		handlerData: state.setCanteenReducer.selectHandler
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddStock);
