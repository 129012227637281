import * as actionTypes from '../actionTypes';

export function setAllPostInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_POST,
		payload: allData
	};
}
export function getDepartmentForPost(allData) {
	return {
		type: actionTypes.SET_ALL_DEPARTMENT_NAME_INTO_POST_SELECT,
		payload: allData
	};
}
export function setAllSearchStaffThroughPostInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_STAFFSEARCH_BY_POST,
		payload: allData
	};
}
export function getPostForSearchStaff(allData) {
	return {
		type: actionTypes.SET_ALL_POST_NAME_INTO_SEARCHSTAFFPOST_SELECT,
		payload: allData
	};
}

export function addPost(Data) {
	return {
		type: actionTypes.ADD_POST,
		payload: Data
	};
}
export function updatePostFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_POST,
		payload: setFlag
	};
}
export function updatePost(data) {
	return {
		type: actionTypes.EDIT_POST_RECORD,
		payload: data
	};
}
export function deletePost(id) {
	return {
		type: actionTypes.DELETE_POST,
		payload: id
	};
}
