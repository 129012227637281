import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	select: [],
	sessionSelect: [],
	yearSelect: []
};

export default function setPastPapersReducer(
	state = defaultState,
	action = {}
) {
	switch (action.type) {
		case actionTypes.SET_ALL_CLASS_NAME_INTO_PASTPAPERS_SELECT:
			return {
				...state,
				select: action.payload
			};
		case actionTypes.SET_ALL_SESSION_NAME_INTO_PASTPAPERS_SELECT:
			return {
				...state,
				sessionSelect: action.payload
			};
		case actionTypes.SET_ALL_YEAR_NAME_INTO_PASTPAPERS_SELECT:
			return {
				...state,
				yearSelect: action.payload
			};
		case actionTypes.GET_ALL_PASTPAPERS:
			return { ...state, Data: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_PASTPAPERS:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_PASTPAPERS:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.paperId !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		default:
			return state;
	}
}
