import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as TestInfoActions from '../../Actions/TestInfo';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';

import $ from 'jquery';
class ViewTestInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			staffId: '',
			subjectId: '',
			sectionId: '',
			gradeId: '',
			sessionId: '',
			classId: '',
			examName: '',
			examId: '',
			testName: '',
			testType: '',
			testDate: '',
			testTime: '',
			duration: '',
			totalMarks: '',
			enabled: false,
			id: null,
			select: [],
			sessions: [],
			Staff: [],
			Section: [],
			Subject: [],
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: ''
		};
	}
	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
			this.setState({
				disablepage: false
			});
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.adminData !== nextProps.AllTransportData ||
			prevState.select !== nextProps.selectData ||
			prevState.sessions !== nextProps.selectSessio
		) {
			return {
				adminData: nextProps.AllTransportData,
				select: nextProps.selectData,
				sessions: nextProps.selectSession
			};
		}
		return null;
	}
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.gAllData(
				'/api/classSectionGradeTeacher',
				this.props.TestInfoActions.getClassesForTestInfo
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		this.props.sharedActions
			.gAllData(
				'/api/Session',
				this.props.TestInfoActions.getSessionForTestInfo
			)
			.then(success => {})
			.catch(error => {});
		if (this.props.allTransportState.apiFlag) {
			this.props.sharedActions
				.getAllData(
					'/api/TestInfo',
					this.props.TestInfoActions.setAllTestInfoInRedux,
					this.props.TestInfoActions.updateTestInfoFlag
				)
				.then(success => {
					this.setState({
						isLoading: false
					});
				})
				.catch(err => {
					this.setState({
						isLoading: false
					});

					console.log(err);
				});
		}
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/TestInfo/',
				itemId,
				this.props.TestInfoActions.deleteTestInfo
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.id === itemId);

		this.setState({
			modalData: getData,
			staffId: getData[0].staffId,
			staffName: getData[0].StaffName,
			sessionId: getData[0].sessionId,
			id: getData[0].id,
			sessionName: getData[0].sessionName,
			classId: getData[0].classId,
			className: getData[0].className,
			sectionId: getData[0].sectionId,
			sectionName: getData[0].section,
			gradeId: getData[0].gradeId,
			gradeName: getData[0].gradeName,
			testName: getData[0].testName,
			testType: getData[0].testType,
			testTime: getData[0].testTime,
			testDate: getData[0].testDate,
			duration: getData[0].duration,
			totalMarks: getData[0].totalMarks,
			examId: getData[0].examId,
			examName: getData[0].examName,
			subjectName: getData[0].subjectName,
			subjectId: getData[0].subjectId
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			routeName: result.dropOff,
			routeId: result.id
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const {
			id,
			staffId,
			classId,
			sectionId,
			subjectId,
			sessionId,
			gradeId,
			testName,
			testType,
			testDate,
			testTime,
			duration,
			totalMarks,
			examId
		} = this.state;
		var bodyParameters = {
			id,
			staffId,
			classId,
			sectionId,
			subjectId,
			sessionId,
			gradeId,
			testName,
			testType,
			testDate,
			testTime,
			duration,
			totalMarks,
			examId,
			accountId: this.props.users.unique_name,
			accountType: this.props.users.role
		};
		this.props.sharedActions
			.editRecord(
				'/api/TestInfo/',
				id,
				bodyParameters,
				this.props.TestInfoActions.updateTestInfo
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				this.props.snackbar();
			})
			.catch(err => {});
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			sessionName: result.currentSession,
			sessionId: result.sessionId
		});
	};
	onSelectClassChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);

		const Grade = this.state.select.grade;
		const Subject = this.state.select.subject;
		const Staff = this.state.select.staff;
		const Section = this.state.select.sections;
		let check = Grade.filter(item => item.classId == result.classId);

		console.log(check);
		this.setState({
			gradeName: check[0] && check[0].gradeName,
			classId: result.classId,
			className: result.className,
			gradeId: check[0] && check[0].gradeId,
			gradeName: check[0] && check[0].gradeName
		});
		let checkTwo = Subject.filter(item => item.classId == result.classId);

		this.setState({
			Subject: checkTwo
		});
		let checkThird = Staff.filter(item => item.classId == result.classId);

		this.setState({
			Staff: checkThird
		});
		let checkFourth = Section.filter(item => item.classId == result.classId);

		this.setState({
			Section: checkFourth
		});
	};
	onSelectSubjectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);

		this.setState({
			subjectId: result.subjectId,
			subjectName: result.subjectName
		});
	};
	onSectionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);

		this.setState({
			sectionId: result.sectionId,
			sectionName: result.section
		});
	};
	onStaffChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);

		this.setState({
			staffId: result.staffId,
			staffName: result.staffName
		});
	};
	render() {
		const { adminData, modalData, select } = this.state;
		console.log('Class Ka Data', this.state.select);
		console.log('Session Ka Data', this.state.sessions);
		const { session } = this.state.sessions;
		const { classes } = this.state.select;

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return index <= 3 ? (
				<li
					class={`page-item ${
						this.state.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={this.handleClickNext}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			) : (
				''
			);
		});
		const { currentPage, todosPerPage } = this.state;
		const indexOfLastTodo = currentPage * todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
		const renderTodos = currentTodos.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" className="bordersSpace" style={{ padding: 1 }}>
						{index + 1}
					</th>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.testName}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.testType}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.testDate}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.testTime}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.duration}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.totalMarks}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.examName}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.StaffName}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.sessionName}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.className}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.section}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.subjectName}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.gradeName}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.accountId}
					</td>
					<td className="bordersSpace" style={{ padding: 1 }}>
						{item.accountType}
					</td>

					<td className="bordersSpace" style={{ padding: 1 }}>
						<a href="#" data-toggle="tooltip" title="Edit!">
							<button
								id="firstbutton"
								onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								data-toggle="modal"
								data-target="#myModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i class="fas fa-pen"></i>
							</button>
						</a>
						<a href="#" data-toggle="tooltip" title="Delete!">
							<button
								onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								data-toggle="modal"
								data-target="#myModal1"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
							</button>
						</a>
					</td>
				</tr>
			);
		});
		const MainContent = (
			<div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<form onSubmit={this.onHandleSubmit}>
									{modalData &&
										modalData.map((item, index) => (
											<div key={index}>
												<fieldset disabled={!this.state.enabled}>
													<div class="form-group">
														<label for="transportType">Test Name:</label>
														<input
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.testName}
															onChange={this.onHandleText}
															name="testName"
															class="form-control"
															id="transportType"
														/>
													</div>
													<div class="form-group">
														<label for="capacity">Test Type</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.testType}
															onChange={this.onHandleText}
															type="text"
															name="testType"
															class="form-control"
															id="capacity"
														/>
													</div>
													<div class="form-group">
														<label for="capacity">Test Date</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.testDate}
															onChange={this.onHandleText}
															type="text"
															name="testDate"
															class="form-control"
															id="capacity"
														/>
													</div>
													<div class="form-group">
														<label for="capacity">Test Time</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.testTime}
															onChange={this.onHandleText}
															type="text"
															name="testTime"
															class="form-control"
															id="capacity"
														/>
													</div>
													<div class="form-group">
														<label for="capacity">Duration</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.duration}
															onChange={this.onHandleText}
															type="text"
															name="duration"
															class="form-control"
															id="capacity"
														/>
													</div>
													<div class="form-group">
														<label for="capacity">Total Marks</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.totalMarks}
															onChange={this.onHandleText}
															type="text"
															name="totalMarks"
															class="form-control"
															id="capacity"
														/>
													</div>
													<div class="form-group">
														<label for="Campus Phone">Session</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.sessionName}
															onChange={this.onHandleText}
															type="text"
															name="TotalMarks"
															readOnly={true}
															class="form-control"
															id="campusPhoneNumber"
														/>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="email" class="">
																Sessions
															</label>
															<select
																onChange={this.onSelectSessionChange}
																class="custom-select"
																name="staffId"
																style={{ borderColor: '#01AC8A' }}
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	Choose Session...
																</option>
																{session &&
																	session.map((item, index) => (
																		<option
																			key={index}
																			value={`${JSON.stringify(item)}`}
																		>
																			{item.currentSession}
																		</option>
																	))}
															</select>
														</div>
													</div>
													<br />
													<div class="form-group">
														<label for="Campus Phone">Class Name</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.className}
															onChange={this.onHandleText}
															type="text"
															name="TotalMarks"
															readOnly={true}
															class="form-control"
															id="campusPhoneNumber"
														/>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="email" class="">
																Class Name
															</label>
															<select
																style={{ borderColor: '#01AC8A' }}
																onChange={this.onSelectClassChange}
																class="custom-select"
																name="staffId"
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	Choose Name...
																</option>
																{this.state.select.classes &&
																	classes.map((item, index) => (
																		<option
																			key={index}
																			value={`${JSON.stringify(item)}`}
																		>
																			{item.className}
																		</option>
																	))}
															</select>
														</div>
													</div>

													<br />
													<div class="form-group">
														<label for="Campus Phone">Grade Name</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.gradeName}
															onChange={this.onHandleText}
															type="text"
															name="TotalMarks"
															readOnly={true}
															class="form-control"
															id="campusPhoneNumber"
														/>
													</div>
													<div class="form-group">
														<label for="Campus Phone">Section Name</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.sectionName}
															onChange={this.onHandleText}
															type="text"
															name="TotalMarks"
															readOnly={true}
															class="form-control"
															id="campusPhoneNumber"
														/>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="email" class="">
																Section
															</label>
															<select
																style={{ borderColor: '#01AC8A' }}
																onChange={this.onSectionChange}
																class="custom-select"
																name="staffId"
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	Choose Section...
																</option>
																{this.state.Section.map((item, index) => (
																	<option
																		key={index}
																		value={`${JSON.stringify(item)}`}
																	>
																		{item.section}
																	</option>
																))}
															</select>
														</div>
													</div>
													<div class="form-group">
														<label for="Campus Phone">Staff Name</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.staffName}
															onChange={this.onHandleText}
															type="text"
															name="TotalMarks"
															readOnly={true}
															class="form-control"
															id="campusPhoneNumber"
														/>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="email" class="">
																Staff
															</label>
															<select
																style={{ borderColor: '#01AC8A' }}
																onChange={this.onStaffChange}
																class="custom-select"
																name="staffId"
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	Choose Staff...
																</option>
																{this.state.Staff.map((item, index) => (
																	<option
																		key={index}
																		value={`${JSON.stringify(item)}`}
																	>
																		{item.staffName}
																	</option>
																))}
															</select>
														</div>
													</div>
													<div class="form-group">
														<label for="Campus Phone">Subject Name</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.subjectName}
															onChange={this.onHandleText}
															type="text"
															name="TotalMarks"
															readOnly={true}
															class="form-control"
															id="campusPhoneNumber"
														/>
													</div>
													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="email" class="">
																Subject Name
															</label>
															<select
																style={{ borderColor: '#01AC8A' }}
																onChange={this.onSelectSubjectChange}
																class="custom-select"
																name="staffId"
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	Subject Name...
																</option>
																{this.state.Subject.map((item, index) => (
																	<option
																		key={index}
																		value={`${JSON.stringify(item)}`}
																	>
																		{item.subjectName}
																	</option>
																))}
															</select>
														</div>
													</div>
												</fieldset>
												<br />
												<br />
												<button
													disabled={this.state.enabled}
													onClick={() => this.setState({ enabled: true })}
													type="button"
													class="btn btn-primary buttonAppear"
												>
													Edit
												</button>
												<button
													style={{ marginLeft: 5 }}
													disabled={!this.state.enabled}
													type="submit"
													class="btn btn-primary buttonAppear"
												>
													Save
												</button>
											</div>
										))}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="table-responsive">
					<table class="table table-hover" style={{ textAlign: 'center' }}>
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Test Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Test Type
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Test Date
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Test Time
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Duration
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Total Marks
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Exam
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Staff Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Session Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Class
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Subject
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Grade
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Acc.Id
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Acc.Type
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Actions
								</th>
							</tr>
						</thead>
						<tbody>{renderTodos}</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Test Information</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								<li
									class={`page-item ${
										this.state.disableBPage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextBack}
										class="page-link"
										href="#"
									>
										{this.state.disableBPage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-backward"></i>
										)}
									</a>
								</li>
								{renderPageNumbers}
								<li
									class={`page-item ${
										this.state.disablepage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextFrwd}
										class="page-link"
										href="#"
									>
										{this.state.disablepage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-forward"></i>
										)}
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				{this.props.allTransportState.apiFlag ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		TestInfoActions: bindActionCreators(TestInfoActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllTransportData: state.setTestInfoReducer.Data,
		allTransportState: state.setTestInfoReducer,
		users: state.setUserReducer.users,
		selectData: state.setTestInfoReducer.select,
		selectSession: state.setTestInfoReducer.sessionSelect
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewTestInfo);
