import React, { useState } from 'react';
import FormLayoutWrapper from '../../../../components/HOC/FormLayoutWrapper';
import SelectionInput from '../../../../components/SelectionInput/SelectionInput';
import RowWrapper from '../../../../components/style/RowWrapper';
import CustomLabel from '../../../../components/Label/CustomLabel';
import Button from '../../../../components/Button';
import useHttp from '../../../../hooks/http';
import LoaderWrapper from '../../../../components/HOC/LoaderWrapper';
import * as sharedActions from '../../../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';

import SnackBar from '../../../../components/SnackBar/SnackBar';
import { connect } from 'react-redux';
import validateInput, {
	CHECK_PRIMARY_INFO
} from '../../../../validator/inputValidator';
import {
	dataFilteration,
	addDataMiddleware,
	isEmptyFieldInArrOBJ
} from '../../../../utils/general/index';
import TextInput from '../../../../components/TextInput/TextInput';
function PrimaryInfo(props) {
	const [errors, setError] = useState({});
	const [name, setName] = useState('');

	const [snackbarMessage, setMsg] = useState('');
	const [studentData, setStudentData] = useState('');

	const [classId, setClassId] = useState('');
	const [className, setClassName] = useState('');

	const [grade, setGrade] = useState([]);

	const [gradeId, setGradeId] = useState('');
	const [gradeName, setGradeName] = useState('');

	const [subjects, setSubjects] = useState('');
	const [subjectName, setSubjectName] = useState('');
	const [subjectId, setSubjectId] = useState('');

	const [sectionId, setSectionId] = useState('');
	const [sectionName, setSection] = useState('');

	const [sessionId, setSessionId] = useState('');
	const [sessionName, setSessionName] = useState('');

	const [courseType, setcourseType] = useState('');

	const [term, onSetTerm] = useState('');
	const [isCustomLoader, setCustomLoader] = useState(false);
	const [sessions, setSessions] = useState([]);
	const [sections, setSections] = useState([]);
	const [isLoader, fetchData] = useHttp(
		`/api/DescriptiveReport?userName=${props.name}`,
		[]
	);

	const isValid = () => {
		const { errors, isValid } = validateInput(
			{ classId, sectionId, sessionId, subjectId, term, courseType },
			CHECK_PRIMARY_INFO
		);
		debugger;
		if (!isValid) {
			debugger;
			setError(errors);
		} else {
			setError(errors);
		}
		debugger;
		return isValid;
	};

	debugger;
	const [isLoading, fetchSessionData] = useHttp(`/api/Session`, []);

	const onSessionChange = e => {
		console.log(fetchSessionData);
		debugger;
		if (e.target.value !== 'Choose Session') {
			debugger;
			let getSession = dataFilteration(
				fetchSessionData.session,
				['currentSession'],
				{
					sessionId: parseInt(e.target.value)
				}
			);

			console.log(fetchSessionData);
			debugger;
			setSessionName(getSession[0].currentSession);
			debugger;
			setSessionId(e.target.value);
		}
	};
	// const onTermChange = e => {
	// 	if (e.target.value !== 'Choose Grade') setGrade(e.target.value);
	// };
	const onTermChange = e => {
		if (e.target.value !== 'Choose Term') onSetTerm(e.target.value);
	};
	const onSubjectChange = e => {
		const { staffClassSection, classes } = fetchData;

		if (e.target.value !== 'Choose Subject') {
			setSubjectId(e.target.value);
			let getSubjectName = dataFilteration(staffClassSection, ['subjectName'], {
				subjectId: parseInt(e.target.value),
				classId: classId,
				sectionId: sectionId
			});
			setSubjectName(getSubjectName[0].subjectName);
			debugger;
		}
	};
	const onChangeClassName = e => {
		const { staffClassSection, classes } = fetchData;
		if (e.target.value !== 'Choose Class') {
			let getSections = dataFilteration(
				staffClassSection,
				['section', 'sectionId'],
				{
					classId: parseInt(e.target.value)
				}
			);

			let getClassName = dataFilteration(classes, ['className'], {
				classId: parseInt(e.target.value)
			})[0].className;
			debugger;
			setClassName(getClassName);
			setClassId(parseInt(e.target.value));
			setSections(getSections);
			setSectionId('');
		}
	};
	const onSectionChange = e => {
		if (e.target.value !== 'Choose Section') {
			const { staffClassSection, classes } = fetchData;
			debugger;
			let getSubjects = dataFilteration(
				staffClassSection,

				['subjectName', 'subjectId'],
				{
					classId: classId,
					sectionId: parseInt(e.target.value)
				}
			);
			let getGrades = dataFilteration(
				staffClassSection,

				['gradeName', 'gradeId'],
				{
					classId: classId,
					sectionId: parseInt(e.target.value)
				}
			);

			debugger;
			setGradeName(getGrades[0].gradeName);
			setGradeId(getGrades[0].gradeId);

			let sectionName = dataFilteration(
				staffClassSection,

				['section'],
				{
					classId: classId,
					sectionId: parseInt(e.target.value)
				}
			);
			debugger;
			debugger;
			setSection(sectionName[0].section);
			setSectionId(parseInt(e.target.value));
			setSubjects(getSubjects);
		}
	};
	const onSetType = e => {
		if (e.target.value !== 'Choose Type') {
			setcourseType(e.target.value);
		}
	};
	const returnGenerateName = (
		classId = '',
		section = '',
		session = '',
		subject = '',
		term = ''
	) => `${classId}-${section}-${session}-${subject}-${term}`;

	const onHandleSubmit = () => {
		console.log(errors);
		debugger;
		if (isValid()) {
			console.log('everything is fine');
			console.log(subjectId, classId, sessionId, sectionId, term, courseType);
		}
	};
	const onSubmit = () => {
		if (isValid()) {
			setCustomLoader(true);
			props.sharedAction
				.simpleAddRequest(`/api/PrimaryInfo`, {
					classId,
					sectionId,
					sessionId,
					subjectId,
					gradeId,
					courseType,
					term,
					name: `${className}-${sectionName}-${sessionName}-${subjectName}-${term}`
				})
				.then(success => {
					debugger;
					setCustomLoader(false);
					onSetTerm('');
					setSectionId('');
					setClassId('');
					setSubjectId('');
					setcourseType('');
					setSessionId('');
					setClassName('');
					setSubjectName('');
					setSessionName('');

					setSection('');

					setStudentData([]);
					setMsg('Successfully Submitted');
					props.snackbar();
				})
				.catch(error => {
					if (error && error.response && error.response.status == 400) {
						setCustomLoader(false);
						//404-Error no understanding of 404 Error
						setMsg(error.response.data.Message);

						props.snackbar();
					}
				});
		}
	};
	return (
		<div>
			<LoaderWrapper isLoading={isLoader && isLoading ? true : false}>
				<SnackBar backColor={'#000'} msg={snackbarMessage} />

				<FormLayoutWrapper
					styleHead={true}
					formName={'Primary Information'}
					borderColor={'#01AC8A'}
					borderRadius={5}
					errorFlag={false}
					flagName={'error'}
				>
					<CustomLabel
						labelName="Generated Name"
						displayData={returnGenerateName(
							className,
							sectionName,
							sessionName,
							subjectName,
							term
						)}
						position="left"
					/>
					<RowWrapper wrap={'wrap'}>
						<SelectionInput
							feildName="classId"
							selectedText={'Choose Class Name'}
							optionsArrys={fetchData && fetchData.classes}
							errors={{}}
							isValidte={false}
							stateData={{
								classId: classId
							}}
							optionType="dynamic"
							onHandleChange={onChangeClassName}
							property={'className'}
							borderColor={
								errors['classId'] && !isEmpty(errors) ? '#FF0000' : '#01AC8A'
							}
							propertyId={'classId'}
							useFlag={false}
						/>
						<SelectionInput
							borderColor={
								errors['sectionId'] && !isEmpty(errors) ? '#FF0000' : '#01AC8A'
							}
							feildName={'sectionId'}
							selectedText={'Choose Section'}
							isValidte={false}
							optionsArrys={sections.length < 1 ? [] : sections}
							errors={{}}
							stateData={{
								sectionId: sectionId
							}}
							optionType="dynamic"
							onHandleChange={onSectionChange}
							property={'section'}
							propertyId={'sectionId'}
							useFlag={false}
						/>

						<TextInput
							isValidte={false}
							readOnly={true}
							// marginTop={20}
							feildName={'gradeName'}
							// inputLabelName={'Percentage Basic'}
							// onHandleChange={this.onHandleTextChange}
							errors={{}}
							stateData={{
								gradeName: gradeName
							}}
							// iconClassName={'fas fa-percentage'}
							placeholder={'Grade Name'}
							disabledField={'disabledPB'}
						/>

						<SelectionInput
							borderColor={
								errors['sessionId'] && !isEmpty(errors) ? '#FF0000' : '#01AC8A'
							}
							feildName={'sessionId'}
							selectedText={'Choose Session'}
							isValidte={false}
							optionsArrys={fetchSessionData && fetchSessionData.session}
							errors={{}}
							stateData={{
								sessionId: sessionId
							}}
							optionType="dynamic"
							onHandleChange={onSessionChange}
							property={'currentSession'}
							propertyId={'sessionId'}
							useFlag={false}
						/>

						<SelectionInput
							borderColor={
								errors['subjectId'] && !isEmpty(errors) ? '#FF0000' : '#01AC8A'
							}
							feildName={'subjectId'}
							selectedText={'Choose Subject'}
							isValidte={false}
							optionsArrys={subjects.length < 1 ? [] : subjects}
							errors={{}}
							stateData={{
								subjectId: subjectId
							}}
							optionType="dynamic"
							onHandleChange={onSubjectChange}
							property={'subjectName'}
							propertyId={'subjectId'}
							useFlag={false}
						/>
						<SelectionInput
							borderColor={
								errors['term'] && !isEmpty(errors) ? '#FF0000' : '#01AC8A'
							}
							isValidte={false}
							stateData={{
								term: term
							}}
							errors={{}}
							feildName={'term'}
							optionsArrys={['1st Term', '2nd Term', '3rd Term']}
							type="static"
							useFlag={false}
							selectedText={'Choose Term'}
							onHandleChange={onTermChange}
							// newFlag="customWidth"
						/>
						<SelectionInput
							borderColor={
								errors['courseType'] && !isEmpty(errors) ? '#FF0000' : '#01AC8A'
							}
							isValidte={false}
							stateData={{
								courseType: courseType
							}}
							errors={{}}
							feildName={'courseType'}
							optionsArrys={['Elective', 'Compulsory']}
							type="static"
							useFlag={false}
							selectedText={'Choose Type'}
							onHandleChange={onSetType}
							// newFlag="customWidth"
						/>
					</RowWrapper>
					<br />
					<br />
					<Button
						buttonClass={'btnStandard'}
						btnName="Submit"
						onHandleSubmit={onSubmit}
						stateData={{ isLoading: isCustomLoader }}
					/>
				</FormLayoutWrapper>
			</LoaderWrapper>
		</div>
	);
}
function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrimaryInfo);
