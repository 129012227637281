import React, { Suspense, lazy } from 'react';
import './login.css';
import './loginDemo.css';
const LoginForm = lazy(() => import('../Login_Component/LoginForm'));
const LoginLayout = props => {
	if (
		Object.is(props.match.params.select, ':Admin') ||
		Object.is(props.match.params.select, ':Student') ||
		Object.is(props.match.params.select, ':Parent') ||
		Object.is(props.match.params.select, ':Staff') ||
		Object.is(props.match.params.select, ':Owner') ||
		Object.is(props.match.params.select, ':General Manager') ||
		Object.is(props.match.params.select, ':Manager') ||
		Object.is(props.match.params.select, ':Dean') ||
		Object.is(props.match.params.select, ':Hod') ||
		Object.is(props.match.params.select, ':Coordinator')
	) {
	} else {
		props.history.push('/');
	}

	return (
		<div className="backgroundImg">
			<div className="container">
				<div className="row" style={{ marginTop: 60 }}>
					<div className="col-sm-4"></div>
					<div
						className="col-sm-4 mainDiv"
						style={{
							padding: 0,
							marginTop: 90,
							boxShadow: '0px 0px 5px 5px grey',
							textAlign: 'center'
						}}
					>
						<Suspense
							fallback={<div class="spinner-border text-primary"></div>}
						>
							<LoginForm {...props} />
						</Suspense>
					</div>
					<div class="col-sm-4"></div>
				</div>
			</div>
		</div>
	);
};

export default LoginLayout;
