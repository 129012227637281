import React from 'react';
import { RenderBodyData } from '../Edit';
import Spinner from '../Spinner/Spinner';
const ViewDataTable = ({
	errors,
	onSectionChange,
	stateData,
	localLoad,
	onChangeClassName,
	actionFieldName,
	actionsName,
	Actions,
	tHeadData,
	renderFields,
	Data,
	Loading,
	oldWorkingDays,
	gettingWorkDays,
	ViewClick,
	EditClick,
	workingDays,
	horizontalData,
	periodBoxData,
	periods,
	DeleteClick,
	removeElement,
	techNames,
	headerDetails,
	editPeriodData,
	horizontalDataEdit,
	daysForEdit,
	subAndTechData,
	eidtHorizonData,
	onChangeDynamic,
	onSubmit
}) => {
	return Loading ? (
		<Spinner />
	) : (
		<div className="table-responsive">
			<table class="table table-hover">
				<thead style={{ background: '#01AC8A', color: 'white' }}>
					<tr>
						<th scope="col">#</th>
						{tHeadData.map(item => (
							<th scope="col">{item}</th>
						))}
					</tr>
				</thead>
				<tbody>
					<RenderBodyData
						onSectionChange={onSectionChange}
						stateData={stateData}
						localLoad={localLoad}
						onChangeClassName={onChangeClassName}
						actionFieldName={actionFieldName}
						actionsName={actionsName}
						onSubmit={onSubmit}
						onChangeDynamic={onChangeDynamic}
						eidtHorizonData={eidtHorizonData}
						subAndTechData={subAndTechData}
						daysForEdit={daysForEdit}
						editPeriodData={editPeriodData}
						horizontalData={horizontalData}
						workingDays={workingDays}
						initailDays={oldWorkingDays}
						periodBoxData={periodBoxData}
						ViewClick={ViewClick}
						EditClick={EditClick}
						horizontalDataEdit={horizontalDataEdit}
						headerDetails={headerDetails}
						DeleteClick={DeleteClick}
						periods={periods}
						techNames={techNames}
						Data={Data}
						removeElement={removeElement}
						renderFeild={renderFields}
						Actions={Actions}
						errors={errors}
					/>
				</tbody>
			</table>
		</div>
	);
};

export default ViewDataTable;
