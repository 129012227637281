import * as actionTypes from '../Actions/actionTypes';
import isEmpty from 'lodash/isEmpty';

const defaultState = {
	auth: false,
	users: {},
	notificationLink: ''
};

export default function setUserReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.SET_USER:
			return {
				auth: !isEmpty(action.payload),
				users: action.payload
			};

		case actionTypes.SET_NOTIFICATION_LINK:
			return {
				...state,
				notificationLink: action.payload
			};
		default:
			return state;
	}
}
