import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as LoginAction from '../Actions/LoginActions/index';
import { logoutAck } from '../socketapi/api';
import { bindActionCreators } from 'redux';
import config from '../config/config';
import axios from 'axios';
import '../components/Head.css';
import '../Container/signup.css';
import '../components/signup';
import $ from 'jquery';
import AddStock from '../Container/Inventory/AddStock';
import NewIcon from '../Assets/icons/DashboardIcon/white heading and icon horizontal.svg';
import RoleTypes from '../utils/app_constants/app_constants';

// import "../Container/signup";
// start of staff routes

const Profile = [
	{ icon: 'fa-user-circle', path: '/AddAdmin', RouteName: 'My Profile' },
	{ icon: 'fa-level-down-alt', path: '/AdminList', RouteName: 'Annual Leave' }
];

const Hod_links = [];
const Communication_links = [
	{
		icon: 'fa-check',
		path: '/dashboard/AddParent',
		RouteName: 'Add Parent'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/RegisterStudentToParent',
		RouteName: 'Register Parent'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/UpdateParentRegistration',
		RouteName: 'Update Parent Registration'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/SearchParentByChild',
		RouteName: 'Search Parent'
	}
];
const Admission_Links = [
	{
		icon: 'fa-check',
		parentName: 'Managers',
		parentIconName: 'fa-check',
		type: 'double',
		ChildArray: [
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/AddManager',
				RouteName: 'Add Manager'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/ViewManager',
				RouteName: 'View Manager'
			}
		]
	}
];
const Administration_Links = [
	{
		icon: 'fa-check',
		parentName: 'Administration Smart',
		parentIconName: 'fa-check',
		type: 'double',
		ChildArray: [
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/InventoryDashboard',
				RouteName: 'Inventory Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/CampusDashboard',
				RouteName: 'Campus Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/CanteenDashboard',
				RouteName: 'Canteen Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/TransportDashboard',
				RouteName: 'Transport Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/CensusDashboard',
				RouteName: 'Census Information Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/StudentAdmissionDashboard',
				RouteName: 'Admission Form Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/AdministrationManagers',
				RouteName: 'Admin Manager Smart '
			}
		]
	}
];
const gm_Links = [
	{
		icon: 'fa-check',
		parentName: 'Admin',
		parentIconName: 'fa-check',
		type: 'double',
		ChildArray: [
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/AddAdmin',
				RouteName: 'Add Admin'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/ViewAdmin',
				RouteName: 'View Admin'
			}
		]
	},
	{
		icon: 'fa-check',
		parentName: 'Finance Smart',
		parentIconName: 'fa-check',
		type: 'double',
		ChildArray: [
			{
				icon: 'fa-check',
				path: '/dashboard/AccountLedger',
				RouteName: 'Account Ledger'
			},
			{
				icon: 'fa-check',
				path: '/dashboard/AddBudget',
				RouteName: 'Add Budget'
			},
			{
				icon: 'fa-check',
				path: '/dashboard/ViewBudget',
				RouteName: 'View Budget'
			}
		]
	},
	{
		icon: 'fa-check',
		parentName: 'Academic Smart',
		parentIconName: 'fa-check',
		type: 'double',
		ChildArray: [
			{
				icon: 'fa-check',
				path: '/dashboard/AcademicLMS',
				RouteName: 'LMS'
			},
			{
				icon: 'fa-check',
				path: '/dashboard/AcademicCMS',
				RouteName: 'CMS'
			},
			{
				icon: 'fa-check',
				path: '/dashboard/AcademicSID',
				RouteName: 'SID'
			}
		]
	},
	{
		icon: 'fa-check',
		parentName: 'Administration Smart',
		parentIconName: 'fa-check',
		type: 'double',
		ChildArray: [
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/InventoryDashboard',
				RouteName: 'Inventory Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/CampusDashboard',
				RouteName: 'Campus Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/CanteenDashboard',
				RouteName: 'Canteen Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/TransportDashboard',
				RouteName: 'Transport Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/CensusDashboard',
				RouteName: 'Census Information Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/StudentAdmissionDashboard',
				RouteName: 'Admission Form Smart'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/AdministrationManagers',
				RouteName: 'Admin Manager Smart '
			}
		]
	},
	{
		icon: 'fa-check',
		parentName: 'Communication Smart',
		parentIconName: 'fa-check',
		type: 'double',
		ChildArray: [
			{
				icon: 'fa-check',
				path: '/dashboard/CommunicationDashboard',
				RouteName: 'Parent Portal'
			},
			{
				icon: 'fa-check',
				path: '/dashboard/CommunicationDashboard',
				RouteName: 'Student Portal'
			}
		]
	},
	{
		icon: 'fa-check',
		path: '/dashboard/HrDashboard',
		RouteName: 'HR Smart',
		type: 'single'
	}
];

const Pastoral_Links = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/MedicalDashboard',
		RouteName: 'Medical Center'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ActivityDashboard',
		RouteName: 'Activity'
	},
];

const sm_Links = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/CampusSecurityDashboard',
		RouteName: 'Campus Security'
	},
]

const pm_Links = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/IndentDashboard',
		RouteName: 'Indent Form'
	},
]

const Transport = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AddRoutes',
		RouteName: 'Add Routes'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewRoutes',
		RouteName: 'View Routes'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AddVehicle',
		RouteName: 'Add Vehicle'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewVehicle',
		RouteName: 'View Vehicle'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AddDriver',
		RouteName: 'Add Driver'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewDriver',
		RouteName: 'View Driver'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AssignVehicle',
		RouteName: 'Assign Vehicle'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewAssignVehicle',
		RouteName: 'View Assign Vehicle'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/CreateTransportFees',
		RouteName: 'Create Transport Fees'
	}
];
const studentLinks = [
	{
		icon: 'fa-check',
		path: '/dashboard/StudentSkillView',
		RouteName: 'Student Skill'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/StudentDescriptiveView',
		RouteName: 'Descriptive Report'
	},

	{
		icon: 'fa-check',

		path: '/dashboard/StudentRoleDiscipline',
		RouteName: 'Student Discipline',
		type: 'single'
	},

	{
		icon: 'fa-check',
		path: '/dashboard/ViewStudentEvents',
		RouteName: 'Event List',
		type: 'single'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/StudentLeaveDashboard',
		RouteName: 'Student Leave',
		type: 'single'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/ViewTourByStudent',
		RouteName: 'Tour',
		type: 'single'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/ViewAwardByStudent',
		RouteName: 'Award',
		type: 'single'
	}
];
const parentLinks = [
	{
		icon: 'fa-check',
		path: '/dashboard/StudentSkillViewP',
		RouteName: 'Student Skill'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/StudentDescriptiveViewP',
		RouteName: 'Descriptive Report'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/ParentStudentDiscipline',
		RouteName: 'Student Discipline'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/StudentFeeHistoryP',
		RouteName: 'Student Fee History'
	}
];

const staffLinks = [
	{
		icon: 'fa-check',
		path: '/dashboard/StudentSkillDashboard',
		RouteName: 'Student Skill',
		type: 'single'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/DescriptiveDashboard',
		RouteName: 'Descriptive Report',
		type: 'single'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/StudentAttendanceDashboard',
		RouteName: 'Attendance',
		type: 'single'
	},
	{
		icon: 'fa-check',
		parentName: 'Discipline',
		parentIconName: 'fa-check',
		type: 'double',
		ChildArray: [
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/StudentDisciplineRecord',
				RouteName: 'Student Discipline Record'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/EditStudentDisciplineRecord',
				RouteName: 'Update Student Discipline Record'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/ViewStudentDiscipline',
				RouteName: 'Reports of Student Discipline Record'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/SubjectDisciplineMarks',
				RouteName: ' Subject Discipline Marks'
			}
		]
	},
	{
		icon: 'fa-check',
		parentName: 'Continuous Assessment',
		parentIconName: 'fa-check',
		type: 'double',
		letterSpacing: -1,
		ChildArray: [
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/caPrimaryInfo',
				RouteName: 'CA Primary Information'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/CASubToolView',
				RouteName: 'CA Tools Creation'
			}
		]
	},
	{
		icon: 'fa-check',
		parentName: 'Class Discipline',
		parentIconName: 'fa-check',
		type: 'double',
		ChildArray: [
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/ClassDiscipline',
				RouteName: 'Student Class Discipline'
			},
			{
				icon: 'fa-dot-circle',
				path: '/dashboard/ViewclassDisciplinaryRecords',
				RouteName: 'View Class Discipline'
			}
			// {
			// 	icon: 'fa-dot-circle',
			// 	path: '/dashboard/EditStudentDisciplineRecord',
			// 	RouteName: 'Update Student Discipline Record'
			// },
			// {
			// 	icon: 'fa-dot-circle',
			// 	path: '/dashboard/ViewStudentDiscipline',
			// 	RouteName: 'Reports of Student Discipline Record'
			// },
			// {
			// 	icon: 'fa-dot-circle',
			// 	path: '/dashboard/SubjectDisciplineMarks',
			// 	RouteName: ' Subject Discipline Marks'
			// }
		]
	},
	{
		icon: 'fa-check',
		path: '/dashboard/IndentStaffDashboard',
		RouteName: 'Indent Form',
		type: 'single'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/LeaveDashboard',
		RouteName: 'Leave ',
		type: 'single'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/ViewTourByStaff',
		RouteName: 'Tour',
		type: 'single'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/ViewEvaluationByStaff',
		RouteName: 'Teacher Evaluation',
		type: 'single'
	},
];
const hrLinks = [
	{
		icon: 'fa-check',
		path: '/dashboard/SearchStudent',
		RouteName: 'Student Record Smart'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/HrDevelopmentDashboard',
		RouteName: 'HR Development Smart'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/FrontDeskDashboard',
		RouteName: 'Front Desk Office Smart'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/DepartmentDashboard',
		RouteName: 'Department Smart'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/PostDashboard',
		RouteName: 'Assign Post Smart'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/PrintStaffAdmissionForm',
		RouteName: 'Staff Document Smart'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/Appraisal',
		RouteName: 'Appraisal Smart'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/',
		RouteName: 'Vacancy Smart'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/',
		RouteName: 'Interview Smart'
	}
];
const business_Smart = [
	{
		icon: 'fas fa-skull-crossbones',
		path: '/dashboard/',
		RouteName: 'Under Construction'
	}
];
const financeLinks = [
	{
		icon: 'fa-check',
		path: '/dashboard/AccountsDashboard',
		RouteName: 'Accounts'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/AccountLedger',
		RouteName: 'Account Ledger'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/BudgetDashboard',
		RouteName: 'Budget'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/BillingDashboard',
		RouteName: 'Billing'
	}
];

const acadmic_Parent_Links = [
	{
		icon: 'fa-check',
		parentName: 'LMS',
		ChildArray: Administration1
	},
	{
		icon: 'fa-check',
		parentName: 'CMS ',
		ChildArray: Administration1
	},
	{
		icon: 'fa-check',
		parentName: 'SID',
		ChildArray: Administration1
	}
];
const AcadmicsLinks = [
	{
		icon: 'fa-check',
		path: '/dashboard/AcademicLMS',
		RouteName: 'LMS'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/AcademicCMS',
		RouteName: 'CMS'
	},
	{
		icon: 'fa-check',
		path: '/dashboard/AcademicSID',
		RouteName: 'SID'
	}
];
const Staff = [
	{ icon: 'fa-user-circle', parentName: 'Profile', RouteName: 'My Profile' },
	{ icon: 'fa-child', path: '/viewCla', RouteName: 'Holiday' },
	{ icon: 'fa-clipboard', path: '/viewClass', RouteName: 'Notice' },
	{ icon: 'fa-sign-out-alt', path: `/`, RouteName: 'logout' }
];

// end of staff routes
//
// start of owner and admin routes

const AOSingleRoutes = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/PrintAdmissionFormFinal',
		RouteName: 'Admission Form'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewAppraisal',
		RouteName: 'View Appraisal'
	},
	{ icon: 'fa-dot-circle', path: '/dashboard/Check', RouteName: 'Check' },
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Help Desk' },
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: "Today's Thought" },
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Photo Album' },
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Holiday' },
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Role Management' },
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Notice' },
	{ icon: 'fa-dot-circle', path: `/`, RouteName: 'logout' }
];

const adminLinks = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AddAdmin',
		RouteName: 'Add Admin'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/viewAdmin',
		RouteName: 'View Admin'
	}
];
const Administration1 = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AddAdmin',
		RouteName: 'Add Admin'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewAdmin',
		RouteName: 'View Admin'
	}
];
const Administration = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/InventoryDashboard',
		RouteName: 'Inventory Smart'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/CampusDashboard',
		RouteName: 'Campus Smart'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/CanteenDashboard',
		RouteName: 'Canteen Smart'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/TransportDashboard',
		RouteName: 'Transport Smart'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/CensusDashboard',
		RouteName: 'Census Information Smart'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/StudentAdmissionDashboard',
		RouteName: 'Admission Form Smart'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AdministrationManagers',
		RouteName: 'Admin Manager Smart '
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/IndentDashboard',
		RouteName: 'Indent Form '
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/CampusSecurityDashboard',
		RouteName: 'Campus Security '
	}
];
const CensusInformation = [
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'View class' },
	{
		icon: 'fa-dot-circle',
		path: '/viewClass',
		RouteName: 'View Student Session and Count'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ClassSection',
		RouteName: 'Class Section'
	},

	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'View Admission' },
	{
		icon: 'fa-dot-circle',
		path: '/viewClass',
		RouteName: 'View Tranformed Student'
	},
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Student Filter' },
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Filter Option' }
];
const HRAdmin = [
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Post' },
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Department' }
];
// const Transport = [
//   { icon: "fa-route", path: "/viewClass", RouteName: "Route" },
//   { icon: "fa-truck-pickup", path: "/viewClass", RouteName: "Vehicle" },
//   { icon: "fa-wheelchair", path: "/viewClass", RouteName: "Driver" },
//   { icon: "fa-user-ninja", path: "/viewClass", RouteName: "Assign Vehicle" },
//   {
//     icon: "fa-money-bill-wave-alt",
//     path: "/viewClass",
//     RouteName: "Create Transport Fees"
//   }
// ];
const Officer = [
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Student Enquiry' },
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Staff Enquiry' }
];

const Management = [
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Search Student' },
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Add Student' },
	{ icon: 'fa-dot-circle', path: '/viewClass', RouteName: 'Student by class' }
];
const Canteen = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AddCanteen',
		RouteName: 'Add Canteen'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewCanteen',
		RouteName: 'View Canteen'
	}
];

const GM = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AddGM',
		RouteName: 'Add General Manager'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewGm',
		RouteName: 'View General Manager'
	}
];
const Campus = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AddCampus',
		RouteName: 'Add Campus'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewCampus',
		RouteName: 'View Campus'
	}
];
const Inventory = [
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AddCategory',
		RouteName: 'Add Category'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewCategory',
		RouteName: 'View Category'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AddItems',
		RouteName: 'Add Items'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewItems',
		RouteName: 'View Items'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/Supplier',
		RouteName: 'Add Supplier'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewSupplier',
		RouteName: 'View Supplier'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/AddStock',
		RouteName: 'Add Stock'
	},
	{
		icon: 'fa-dot-circle',
		path: '/dashboard/ViewStock',
		RouteName: 'View Stock'
	}
];
function getDataWithChangeIcon(iconName, routes) {
	let getNewData = routes.map(item => {
		return { ...item, icon: iconName };
	});
	return getNewData;
}
// end of owner and admin routes
const mainLinks_Owner = [
	{
		icon: 'fa-check',
		parentName: 'Admin',
		ChildArray: Administration1
	},
	{
		icon: 'fa-check',
		parentName: 'General Manager',
		ChildArray: GM
	},
	{
		icon: 'fa-check',
		parentName: 'Finance Smart',
		ChildArray: getDataWithChangeIcon('fa-dot-circle', financeLinks)
	},
	{
		icon: 'fa-check',
		parentName: 'Business Smart',
		ChildArray: business_Smart
	},
	{
		icon: 'fa-check',
		parentName: 'Administration Smart',
		ChildArray: Administration
	},
	{
		icon: 'fa-check',
		parentName: RoleTypes.ACADMIC_SMART,
		ChildArray: getDataWithChangeIcon('fa-dot-circle', AcadmicsLinks)
	},
	{
		icon: 'fa-check',
		parentName: 'Admission Smart',
		ChildArray: business_Smart
	},
	{
		icon: 'fa-check',
		parentName: RoleTypes.COMMUNICATION_SMART,
		ChildArray: business_Smart
	},
	{
		icon: 'fa-check',
		parentName: RoleTypes.HR_SMART,
		ChildArray: getDataWithChangeIcon('fa-dot-circle', hrLinks)
	},
	{
		icon: 'fa-check',
		parentName: 'Pastoral Smart ',
		ChildArray: business_Smart
	},
	{
		icon: 'fa-check',
		parentName: 'System Smart ',
		ChildArray: business_Smart
	}
];

const mainLinks_GM = [
	{
		icon: 'fas fa-user-shield',
		parentName: 'Admin',
		ChildArray: Administration
	},
	{
		icon: 'fa-info-circle',
		parentName: 'Census Information',
		ChildArray: CensusInformation
	},
	{ icon: 'fa-bus', parentName: 'Transport', ChildArray: Transport },
	{ icon: 'fa-user-check', parentName: 'HR Admin', ChildArray: HRAdmin },
	{ icon: 'fa-male', parentName: 'Front Desk Officer ', ChildArray: Officer },
	{
		icon: 'fas fa-user-graduate',
		parentName: 'Student Record Management',
		ChildArray: Management
	},
	{
		icon: 'fas fa-th-list',
		parentName: 'More',
		ChildArray: AOSingleRoutes
	}
];

const mainLinks_Admin = [
	{
		icon: 'fas fa-user-shield',
		parentName: 'Admin',
		ChildArray: Administration
	},
	{
		icon: 'fa-info-circle',
		parentName: 'Census Information',
		ChildArray: CensusInformation
	},
	{ icon: 'fa-bus', parentName: 'Transport', ChildArray: Transport },
	{ icon: 'fa-user-check', parentName: 'HR Admin', ChildArray: HRAdmin },
	{ icon: 'fa-male', parentName: 'Front Desk Officer ', ChildArray: Officer },
	{
		icon: 'fas fa-user-graduate',
		parentName: 'Student Record Management',
		ChildArray: Management
	},
	{
		icon: 'fas fa-th-list',
		parentName: 'More',
		ChildArray: AOSingleRoutes
	}
];

class Head extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// role: this.props.location.state.role,
		};

		if (!this.props.auth) {
			this.props.history.push('/');
		}
	}

	onLogout = () => {
		console.log('logout now');
		this.props.logoutNow.logout();
	};

	// openAndClose = () => {
	//   $(document).ready(function () {
	//     $('#sidebarCollapse').on('click', function () {
	//       console.log('working');
	//       $('#sidebar').toggleClass('active');
	//     });
	//   });
	// }

	componentDidMount() {
		$(document).ready(function () {
			$('#sidebarCollapse').on('click', function () {
				$('#sidebar').toggleClass('active');
			});
		});
	}
	logout = () => {
		axios
			.put(`${config.localhttp}/logout/confirm`, {
				isOnline: false,
				_id: this.props._id
			})
			.then(success => {
				Promise.all([
					this.props.logoutNow.logout(),
					this.props.history.push('/')
				])
					.then(success => {
						console.log(success);
					})
					.catch(err => {
						console.log(err);
					});
				console.log('logout confirmation: ', success);
			})
			.catch(error => {
				console.log(error);
			});
	};
	goBack = () => {
		this.props.history.goBack();
	};

	RenderSingleLinksOrDoubleLinks = (
		icon,
		path,
		RouteName,
		childIconMargin = 4,
		index,
		type = 'single',
		letterSpacing = 0,
		parentIconName = 'fa-check',
		ParentName = 'Default parent name',
		...args
	) => {
		return (
			<React.Fragment key={index}>
				{type === 'single' ? (
					<li key={index}>
						<Link key={index} to={`${path}`}>
							<i style={{ marginRight: 6 }} className={`fas ${icon}`} />
							{RouteName}
						</Link>
					</li>
				) : (
						<React.Fragment>
							<a
								href={`#homeSubmenu${index}`}
								data-toggle="collapse"
								aria-expanded="false"
								class="dropdown-toggle"
							>
								{''}
								<i
									style={{ marginRight: childIconMargin }}
									className={`fas ${parentIconName}`}
								/>
								<span style={{ letterSpacing: letterSpacing }}>{ParentName}</span>
							</a>
							<ul class="collapse list-unstyled" id={`homeSubmenu${index}`}>
								<li>
									{args[0].map((item, index) => (
										<Link key={index} to={`${item.path}`}>
											<i
												style={{ marginRight: childIconMargin }}
												className={`fas ${item.icon}`}
											/>
											{item.RouteName}
										</Link>
									))}
								</li>
							</ul>
						</React.Fragment>
					)}
			</React.Fragment>
		);
	};
	RenderSingleLinks = (icon, path, RouteName, childIconMargin = 4, index) => {
		console.log(this.props.role.toLowerCase());

		return (
			<React.Fragment key={index}>
				<li key={index}>
					<Link key={index} to={`${path}`}>
						<i style={{ marginRight: 6 }} className={`fas ${icon}`} />
						{RouteName}
					</Link>
				</li>
			</React.Fragment>
		);
	};
	RenderLinks = (
		parentIconName,
		ParentName,
		childIconMargin = 4,
		index,
		...args
	) => {
		return (
			<React.Fragment key={index}>
				{/* <img
					src={require('../Assets/icons/DashboardIcon/icon and heading upper csae 96 by 96.svg')}
					style={{ width: 180, height: 50 }}
				/> */}
				<a
					href={`#homeSubmenu${index}`}
					data-toggle="collapse"
					aria-expanded="false"
					class="dropdown-toggle"
				>
					{''}
					<i
						style={{ marginRight: childIconMargin }}
						className={`fas ${parentIconName}`}
					/>
					{/* <img
						src={require('../Assets/icons/DashboardIcon/filled in icon finance blue .svg')}
						style={{ width: 25 }}
					/>
					<img
						src={require('../Assets/icons/DashboardIcon/lower case finance heading final.svg')}
						style={{ width: 160, height: 20 }}
					/> */}
					{ParentName}
				</a>
				<ul class="collapse list-unstyled" id={`homeSubmenu${index}`}>
					<li>
						{args[0].map((item, index) => (
							<Link key={index} to={`${item.path}`}>
								<i
									style={{ marginRight: childIconMargin }}
									className={`fas ${item.icon}`}
								/>
								{item.RouteName}
							</Link>
						))}
					</li>
				</ul>
			</React.Fragment>
		);
	};
	render() {
		console.log(this.props);
		let conditionalLinks = (
			<Nav>
				<Nav.Link
					style={{ fontSize: 18, color: 'black' }}
					as={Link}
					to="/signup"
				>
					Sign Up {'|'}
				</Nav.Link>
				<Nav.Link style={{ fontSize: 18, color: 'black' }} as={Link} to="/">
					Login
				</Nav.Link>
			</Nav>
		);
		if (!this.props.auth) {
			return <Redirect to="/" />;
		}
		let renderUserRole = Object.is(this.props.role.toLowerCase(), 'Admin')
			? mainLinks_Admin
			: Object.is(this.props.role.toLowerCase(), RoleTypes.STUDENT_SMART)
				? studentLinks
				: Object.is(this.props.role.toLowerCase(), RoleTypes.STAFF_SMART)
					? staffLinks
					: Object.is(this.props.role.toLowerCase(), RoleTypes.PARENT_SMART)
						? parentLinks
						: Object.is(this.props.role.toLowerCase().toLowerCase(), RoleTypes.OWNER)
							? mainLinks_Owner
							: Object.is(this.props.role.toLowerCase(), RoleTypes.ACADMIC_SMART)
								? AcadmicsLinks
								: Object.is(this.props.role.toLowerCase(), RoleTypes.FINANCE_SMART)
									? financeLinks
									: Object.is(this.props.role.toLowerCase(), RoleTypes.HR_SMART)
										? hrLinks
										: Object.is(this.props.role.toLowerCase(), RoleTypes.COMMUNICATION_SMART)
											? Communication_links
											: Object.is(this.props.role.toLowerCase(), RoleTypes.HOD_SMART)
												? Hod_links
												: Object.is(
													this.props.role.toLowerCase(),
													RoleTypes.GENERAL_MANAGER_SMART
												)
													? gm_Links
													: Object.is(this.props.role.toLowerCase(), RoleTypes.ADMISSION_SMART)
														? Admission_Links
														: Object.is(this.props.role.toLowerCase(), RoleTypes.ADMINISTRATION_SMART)
															? Administration
															: Object.is(this.props.role.toLowerCase(), RoleTypes.PASTORAL_SMART)
																? Pastoral_Links
																: Object.is(this.props.role.toLowerCase(), RoleTypes.SECURITY_MANAGER)
																	? sm_Links
																	: Object.is(this.props.role.toLowerCase(), RoleTypes.PROCUREMENT_MANAGER)
																		? pm_Links
																		:
																		// : Object.is(this.props.role.toLowerCase(), RoleTypes.ADMISSION_MANAGER)
																		// ? Administration
																		'';
		let renderSubUserRole = Object.is(this.props.role, 'Admin')
			? AOSingleRoutes
			: Object.is(this.props.role, RoleTypes.STUDENT_SMART)
				? AOSingleRoutes
				: Object.is(this.props.role, RoleTypes.STAFF_SMART)
					? Staff
					: Object.is(this.props.role, RoleTypes.PARENT_SMART)
						? AOSingleRoutes
						: Object.is(this.props.role, 'Teacher')
							? AOSingleRoutes
							: Object.is(this.props.role.toLowerCase(), RoleTypes.OWNER)
								? AOSingleRoutes
								: Object.is(this.props.role, 'GeneralManager')
									? AOSingleRoutes
									: '';
		console.log(this.props.match);
		return (
			<div>
				<div class="wrapper">
					{/* <!-- Sidebar  --> */}
					<nav
						className="displayElemet"
						id="sidebar"
						style={{ background: '#01425E' }}
					>
						<div class="sidebar-header">
							<div style={{ textAlign: 'center', fontSize: 30 }}>
								{/* <img
									src={'https://www.w3schools.com/howto/img_avatar.png'}
									class="rounded-circle"
									alt="#profImage"
									width={70}
									height={70}
								/> */}
								<i
									class={`fas  ${
										Object.is(this.props.role.toLowerCase(), 'admin')
											? 'fa-user-shield'
											: Object.is(
												this.props.role.toLowerCase(),
												RoleTypes.STUDENT_SMART
											)
												? 'fa-user-tag'
												: Object.is(
													this.props.role.toLowerCase(),
													RoleTypes.STAFF_SMART
												)
													? 'fa-users'
													: Object.is(
														this.props.role.toLowerCase(),
														RoleTypes.PARENT_SMART
													)
														? 'fa-restroom'
														: Object.is(this.props.role.toLowerCase(), 'teacher')
															? 'fa-chalkboard-teacher'
															: Object.is(
																this.props.role.toLowerCase().toLowerCase(),
																RoleTypes.OWNER
															)
																? 'fa-user-tie'
																: Object.is(
																	this.props.role.toLowerCase(),
																	RoleTypes.GENERAL_MANAGER_SMART
																)
																	? 'fa-user-tie'
																	: Object.is(
																		this.props.role.toLowerCase(),
																		RoleTypes.ACADMIC_SMART
																	)
																		? 'fa-user-tie'
																		: Object.is(
																			this.props.role.toLowerCase(),
																			RoleTypes.FINANCE_SMART
																		)
																			? 'fa-user-tie'
																			: Object.is(
																				this.props.role.toLowerCase(),
																				RoleTypes.HR_SMART
																			)
																				? 'fa-user-tie'
																				: Object.is(
																					this.props.role.toLowerCase(),
																					RoleTypes.HOD_SMART
																				)
																					? 'fa-user-cog'
																					: Object.is(
																						this.props.role.toLowerCase(),
																						RoleTypes.COMMUNICATION_SMART
																					)
																						? 'fa-envelope'
																						: Object.is(
																							this.props.role.toLowerCase(),
																							RoleTypes.ADMISSION_SMART
																						)
																							? 'fa-ad'
																							: Object.is(
																								this.props.role.toLowerCase(),
																								RoleTypes.ADMINISTRATION_SMART
																							)
																								? 'fa-ad'
																								: Object.is(
																									this.props.role.toLowerCase(),
																									RoleTypes.ADMISSION_MANAGER
																								)
																									? 'fa-ad'
																									: Object.is(
																										this.props.role.toLowerCase(),
																										RoleTypes.ACCOUNTANT_MANAGER
																									)
																										? 'fa-ad'
																										: Object.is(
																											this.props.role.toLowerCase(),
																											RoleTypes.BILLING_MANAGER
																										)
																											? 'fa-ad'
																											: Object.is(
																												this.props.role.toLowerCase(),
																												RoleTypes.ENTRY_EXAM_COORDINATOR
																											)
																												? 'fa-user-cog'
																												: Object.is(
																													this.props.role.toLowerCase(),
																													RoleTypes.PASTORAL_SMART
																												)
																													? 'fa-ad'
																													: Object.is(
																														this.props.role.toLowerCase(),
																														RoleTypes.SECURITY_MANAGER
																													)
																														? 'fa-user-secret'
																														: Object.is(
																															this.props.role.toLowerCase(),
																															RoleTypes.PROCUREMENT_MANAGER
																														)
																															? 'fa-ad'
																															: ''
										}`}
								/>
							</div>
						</div>
						<ul class="list-unstyled components">
							<div
								style={{ textAlign: 'center', borderBottom: '2px solid #ffff' }}
							>
								<h3>
									{Object.is(this.props.role, 'admin')
										? 'Admin Menu'
										: Object.is(
											this.props.role.toLowerCase(),
											RoleTypes.STUDENT_SMART
										)
											? this.props.name
											: Object.is(
												this.props.role.toLowerCase(),
												RoleTypes.STAFF_SMART
											)
												? this.props.name
												: Object.is(
													this.props.role.toLowerCase(),
													RoleTypes.PARENT_SMART
												)
													? this.props.name.toUpperCase()
													: Object.is(this.props.role.toLowerCase(), 'teacher')
														? 'Teacher Menu'
														: Object.is(
															this.props.role.toLowerCase().toLowerCase(),
															RoleTypes.OWNER
														)
															? this.props.name
															: Object.is(
																this.props.role.toLowerCase(),
																RoleTypes.GENERAL_MANAGER_SMART
															)
																? this.props.name
																: Object.is(
																	this.props.role.toLowerCase(),
																	RoleTypes.ACADMIC_SMART
																)
																	? this.props.name
																	: Object.is(
																		this.props.role.toLowerCase(),
																		RoleTypes.FINANCE_SMART
																	)
																		? this.props.name
																		: Object.is(
																			this.props.role.toLowerCase(),
																			RoleTypes.COMMUNICATION_SMART
																		)
																			? this.props.name
																			: Object.is(
																				this.props.role.toLowerCase(),
																				RoleTypes.HOD_SMART
																			)
																				? this.props.name
																				: Object.is(
																					this.props.role.toLowerCase(),
																					RoleTypes.ADMISSION_SMART
																				)
																					? this.props.name
																					: Object.is(
																						this.props.role.toLowerCase(),
																						RoleTypes.ADMINISTRATION_SMART
																					)
																						? this.props.name
																						: Object.is(
																							this.props.role.toLowerCase(),
																							RoleTypes.ADMISSION_MANAGER
																						)
																							? this.props.name
																							: Object.is(
																								this.props.role.toLowerCase(),
																								RoleTypes.ACCOUNTANT_MANAGER
																							)
																								? this.props.name
																								: Object.is(
																									this.props.role.toLowerCase(),
																									RoleTypes.BILLING_MANAGER
																								)
																									? this.props.name
																									: Object.is(
																										this.props.role.toLowerCase(),
																										RoleTypes.ENTRY_EXAM_COORDINATOR
																									)
																										? this.props.name
																										: Object.is(
																											this.props.role.toLowerCase(),
																											RoleTypes.REGISTRATION_COORDINATOR
																										)
																											? this.props.name
																											: Object.is(
																												this.props.role.toLowerCase(),
																												RoleTypes.WELLBEING_COORDINATOR
																											)
																												? this.props.name
																												: Object.is(
																													this.props.role.toLowerCase(),
																													RoleTypes.ACTIVITY_COORDINATOR
																												)
																													? this.props.name
																													: Object.is(
																														this.props.role.toLowerCase(),
																														RoleTypes.STAFF_COORDINATOR
																													)
																														? this.props.name
																														: Object.is(
																															this.props.role.toLowerCase(),
																															RoleTypes.STUDENT_COORDINATOR
																														)
																															? this.props.name
																															: Object.is(
																																this.props.role.toLowerCase(),
																																RoleTypes.MEDICAL_STAFF
																															)
																																? this.props.name
																																: Object.is(
																																	this.props.role.toLowerCase(),
																																	RoleTypes.MAINTENANCE_COORDINATOR
																																)
																																	? this.props.name
																																	: Object.is(
																																		this.props.role.toLowerCase(),
																																		RoleTypes.TRANSPORT_COORDINATOR
																																	)
																																		? this.props.name
																																		: Object.is(
																																			this.props.role.toLowerCase(),
																																			RoleTypes.CANTEEN_COORDINATOR
																																		)
																																			? this.props.name
																																			: Object.is(
																																				this.props.role.toLowerCase(),
																																				RoleTypes.TIMETABLE_COORDINATOR
																																			)
																																				? this.props.name
																																				: Object.is(
																																					this.props.role.toLowerCase(),
																																					RoleTypes.PORTAL_COORDINATOR
																																				)
																																					? this.props.name
																																					: Object.is(
																																						this.props.role.toLowerCase(),
																																						RoleTypes.EXAM_COORDINATOR
																																					)
																																						? this.props.name
																																						: Object.is(
																																							this.props.role.toLowerCase(),
																																							RoleTypes.NEWS_COORDINATOR
																																						)
																																							? this.props.name
																																							: Object.is(this.props.role.toLowerCase(), RoleTypes.SEN)
																																								? this.props.name
																																								: Object.is(
																																									this.props.role.toLowerCase(),
																																									RoleTypes.FRONT_DESK
																																								)

																																									? this.props.name
																																									: Object.is(
																																										this.props.role.toLowerCase(),
																																										RoleTypes.PASTORAL_SMART
																																									)
																																										? this.props.name
																																										: Object.is(
																																											this.props.role.toLowerCase(),
																																											RoleTypes.SECURITY_MANAGER
																																										)
																																											? this.props.name
																																											: Object.is(
																																												this.props.role.toLowerCase(),
																																												RoleTypes.PROCUREMENT_MANAGER
																																											)
																																												? this.props.name
																																												: ''}
								</h3>
							</div>
							<li>
								{renderUserRole &&
									renderUserRole.map((item, index) =>
										Object.is(
											this.props.role.toLowerCase(),
											RoleTypes.ACADMIC_SMART
										)
											? this.RenderSingleLinks(
												item.icon,
												item.path,
												item.RouteName,
												index
											)
											: Object.is(
												this.props.role.toLowerCase(),
												RoleTypes.STUDENT_SMART
											)
												? this.RenderSingleLinks(
													item.icon,
													item.path,
													item.RouteName,
													index
												)
												: Object.is(
													this.props.role.toLowerCase(),
													RoleTypes.STAFF_SMART
												)
													? this.RenderSingleLinksOrDoubleLinks(
														item.icon,
														item.path,
														item.RouteName,
														4,
														index,
														item.type,
														item.letterSpacing,
														item.parentIconName,
														item.parentName,
														item.ChildArray
													)
													: Object.is(
														this.props.role.toLowerCase(),
														RoleTypes.HR_SMART
													)
														? this.RenderSingleLinks(
															item.icon,
															item.path,
															item.RouteName,
															index
														)
														: Object.is(
															this.props.role.toLowerCase(),
															RoleTypes.HOD_SMART
														)
															? this.RenderSingleLinks(
																item.icon,
																item.path,
																item.RouteName,
																index
															)
															: Object.is(
																this.props.role.toLowerCase(),
																RoleTypes.COMMUNICATION_SMART
															)
																? this.RenderSingleLinks(
																	item.icon,
																	item.path,
																	item.RouteName,
																	index
																)
																: Object.is(
																	this.props.role.toLowerCase(),
																	RoleTypes.PARENT_SMART
																)
																	? this.RenderSingleLinks(
																		item.icon,
																		item.path,
																		item.RouteName,
																		index
																	)
																	: Object.is(
																		this.props.role.toLowerCase(),
																		RoleTypes.GENERAL_MANAGER_SMART
																	)
																		? this.RenderSingleLinksOrDoubleLinks(
																			item.icon,
																			item.path,
																			item.RouteName,
																			4,
																			index,
																			item.type,
																			item.parentIconName,
																			item.parentName,
																			item.ChildArray
																		)
																		: Object.is(
																			this.props.role.toLowerCase(),
																			RoleTypes.ADMINISTRATION_SMART
																		)
																			? this.RenderSingleLinks(
																				item.icon,
																				item.path,
																				item.RouteName,
																				index
																			)
																			: Object.is(
																				this.props.role.toLowerCase(),
																				RoleTypes.ADMISSION_SMART
																			)
																				? this.RenderSingleLinksOrDoubleLinks(
																					item.icon,
																					item.path,
																					item.RouteName,
																					4,
																					index,
																					item.type,
																					item.parentIconName,
																					item.parentName,
																					item.ChildArray
																				)
																				: Object.is(
																					this.props.role.toLowerCase(),
																					RoleTypes.FINANCE_SMART
																				)
																					? this.RenderSingleLinks(
																						item.icon,
																						item.path,
																						item.RouteName,
																						index
																					)
																					: Object.is(this.props.role.toLowerCase(), RoleTypes.PASTORAL_SMART
																					)
																						? this.RenderSingleLinks(
																							item.icon,
																							item.path,
																							item.RouteName,
																							index
																						)
																						: Object.is(this.props.role.toLowerCase(), RoleTypes.SECURITY_MANAGER
																						)
																							? this.RenderSingleLinks(
																								item.icon,
																								item.path,
																								item.RouteName,
																								index
																							)
																							: Object.is(this.props.role.toLowerCase(), RoleTypes.PROCUREMENT_MANAGER
																							)
																								? this.RenderSingleLinks(
																									item.icon,
																									item.path,
																									item.RouteName,
																									index
																								)
																								:
																								this.RenderLinks(
																									item.icon,
																									item.parentName,
																									4,
																									index,
																									item.ChildArray
																								)
									)}
							</li>
						</ul>
					</nav>
					{/* <!-- Page Content  --> */}
					<div id="content">
						<nav
							class=" navbar navbar-expand-lg navbar-light"
							style={{
								background: '#01AC8A',
								borderRadius: 5,
								boxShadow: '5px 5px 5px grey'
							}}
						>
							<div class="container-fluid">
								<button type="button" id="sidebarCollapse" class="btn btn-info">
									<i class="fas fa-exchange-alt" />
								</button>
								<Link to="/dashboard">
									<button
										type="button"
										class="btn btn-info"
										style={{
											marginLeft: 15,
											backgroundColor: '#0A4F5E',
											borderRadius: 5
										}}
									>
										<i class="fas fa-home" />
									</button>
								</Link>
								{/* <button
									class="btn btn-dark d-inline-block d-lg-none ml-auto"
									type="button"
									data-toggle="collapse"
									id="sidebarCollapse"
									data-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent"
									aria-expanded="false"
									aria-label="Toggle navigation"
								>
									<i class="fas fa-align-justify" />
								</button> */}

								<div
									class="collapse navbar-collapse"
									id="navbarSupportedContent"
								>
									<ul class="nav navbar-nav ml-auto" style={{ marginTop: 0 }}>
										<li class="nav-item" />
										<li class="nav-item">
											<Link
												style={{ color: 'white', marginRight: 25 }}
												className="nav-item"
												onClick={this.goBack}
												to="/"
											>
												{/* <button
													type="button"
													class="btn btn-info"
													style={{
														marginTop: 5,
														borderRadius: 5,
														backgroundColor: '#0A4F5E',
														borderColor: '#0A4F5E'
													}}
												> */}
												<i class="fas fa-bell" style={{ marginTop: 20 }}></i>
												{/* </button> */}
											</Link>
										</li>
										<li class="nav-item">
											<Link
												style={{ color: 'white', marginRight: 15 }}
												className="nav-item"
												onClick={this.goBack}
												to="/"
											>
												{/* <button
													type="button"
													class="btn btn-info"
													style={{
														marginTop: 5,
														backgroundColor: '#0A4F5E',
														borderColor: '#0A4F5E'
													}}
												> */}
												<i
													class="fas fa-arrow-left"
													style={{ marginTop: 20 }}
												></i>
												{/* </button> */}
											</Link>
										</li>

										{/* <li class="nav-item">
											<Link
												style={{ color: 'white' }}
												onClick={this.onLogout}
												className="nav-item"
												to="/"
											>
												<button
													type="button"
													class="btn btn-info"
													style={{
														marginTop: 5,
														backgroundColor: '#0A4F5E',
														borderColor: '#0A4F5E'
													}}
												>
													{'Logout'}
												</button>
											</Link>
										</li> */}
										<li class="nav-item dropdown">
											<a href="#" data-toggle="dropdown" class="nav-link">
												<img
													src="https://www.tutorialrepublic.com/examples/images/avatar/3.jpg"
													style={{ borderRadius: 100, marginRight: 5 }}
													alt="Avatar"
												/>
												<label className="font-weight-bold">
													{this.props.name}
												</label>
												<i
													style={{ marginLeft: 5 }}
													class="fas fa-angle-down"
												></i>
											</a>
											<ul class="dropdown-menu">
												{/* <li>
													<a href="#" class="dropdown-item">
														<i class="fa fa-user-o"></i> Profile
													</a>
												</li>
												<li>
													<a href="#" class="dropdown-item">
														<i class="fa fa-calendar-o"></i> Calendar
													</a>
												</li>
												<li>
													<a href="#" class="dropdown-item">
														<i class="fa fa-sliders"></i> Settings
													</a>
												</li> */}
												<li class="dropdown-item"></li>
												<li>
													<a
														onClick={this.onLogout}
														href="#"
														class="dropdown-item"
													>
														<i class="fas fa-sign-out-alt"></i> Logout
													</a>
												</li>
											</ul>
										</li>
									</ul>
								</div>
							</div>
						</nav>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		name: state.setUserReducer.users.unique_name,

		role: state.setUserReducer.users.role,
		notificationLink: state.setUserReducer.notificationLink
	};
}
function mapDispatchToProps(dispatch) {
	return {
		logoutNow: bindActionCreators(LoginAction, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Head));
