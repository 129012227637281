import isEmpty from 'lodash/isEmpty';
import validator from 'validator';
export const CHECK_MARKS_RANGE = 'CHECK_MARKS_RANGE';
export const CHECK_PRIMARY_INFO = 'CHECK_PRIMARY_INFO';
export const CHECK_FIELD_IS_EMPTY = 'CHECK_FIELD_IS_EMPTY';
export const CHECK_SUB_TOOL_FIELD_IS_EMPTY = 'CHECK_SUB_TOOL_FIELD_IS_EMPTY';

export default function validateinput(data, validateType) {
	debugger;
	let errors = {};

	function validateEmail(email) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	if (validateType === CHECK_MARKS_RANGE) {
		if (
			parseInt(data.marks) > 4 ||
			parseInt(data.marks) < 0 ||
			data.marks.toString().length > 1
		) {
			errors['rangeError'] = true;
		}
	} else if (validateType === CHECK_SUB_TOOL_FIELD_IS_EMPTY) {
		if (
			validator.isEmpty(data.toolId.toString()) ||
			validator.isEmpty(data.toolName.toString()) ||
			validator.isEmpty(data.subToolName.toString()) ||
			validator.isEmpty(data.max.toString()) ||
			validator.isEmpty(data.min.toString())
		) {
			errors.isEmpty = true;
		}
	} else if (validateType === CHECK_FIELD_IS_EMPTY) {
		if (validator.isEmpty(data.toolName.toString())) {
			errors.isEmpty = true;
		}
	} else if (validateType === CHECK_PRIMARY_INFO) {
		debugger;
		if (validator.isEmpty(data.classId.toString())) {
			errors.classId = true;
		}
		if (validator.isEmpty(data.sectionId.toString())) {
			errors.sectionId = true;
		}
		if (validator.isEmpty(data.sessionId.toString())) {
			errors.sessionId = true;
		}

		if (validator.isEmpty(data.subjectId.toString())) {
			debugger;
			errors.subjectId = true;
		}
		if (validator.isEmpty(data.term.toString())) {
			errors.term = true;
		}
		if (validator.isEmpty(data.courseType.toString())) {
			debugger;
			errors.courseType = true;
		}
		if (
			!validator.isEmpty(data.classId.toString()) &&
			!validator.isEmpty(data.sectionId.toString()) &&
			!validator.isEmpty(data.sessionId.toString()) &&
			!validator.isEmpty(data.subjectId.toString()) &&
			!validator.isEmpty(data.term.toString()) &&
			!validator.isEmpty(data.courseType.toString())
		) {
			debugger;
		}
	}
	return {
		errors,
		isValid: isEmpty(errors)
	};
}
