import React, { Component } from 'react';
import SnackBar from '../../components/SnackBar/SnackBar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AgeWiseActions from '../../Actions/AgeWiseSearch';
import * as sharedActions from '../../Actions/sharedActions';
import ProgressBarComponent from './ProgressBarComponent';
import $ from 'jquery';

class DropDownComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			sections: [],
			sectionName: '',
			className: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			errorMsg: '',
			select: [],
			handler: [],
			check: true,
			currentSession: '',
			male: '0',
			female: '0',
			total: '0',
			apiSession: '- / -',
			apiSection: '-',
			apiClass: '-',
			age: ''
		};
	}
	onSelectSectionChange = e => {
		console.log(e.target.value);

		const sections = this.props.Document;
		let check = sections.filter(item => item.classId == e.target.value);
		console.log(check);

		this.setState({
			sections: check,
			className: e.target.value
		});
	};
	onSelectStudentChange = e => {
		console.log(e.target.value);

		this.setState({
			sectionName: e.target.value
		});
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);

		this.setState({
			currentSession: e.target.value
		});
		console.log(this.state);
	};
	onHandleSubmit = e => {
		this.props.sharedActions
			.getAllData(
				`/api/agewise/?age=${this.state.age}&sessionId=${this.state.currentSession}&classId=${this.state.className}&sectionId=${this.state.sectionName}`,
				this.props.AgeWiseActions.setAllAgeWiseInRedux,
				this.props.AgeWiseActions.updateAgeWiseFlag
			)
			.then(success => {
				this.setState({
					isLoading: false,
					check: false,
					msg: true,
					errorMsg: false,
					male: this.state.adminData.Male,
					female: this.state.adminData.Female,
					total: this.state.adminData.Total,
					apiSection: this.state.adminData.section,
					apiSession: this.state.adminData.currentSession,
					apiClass: this.state.adminData.className
				});
			})
			.catch(err => {
				// this.props.CensusActions.updateCensusFlag(false);
				this.setState({
					isLoading: false,
					check: true,
					msg: false,
					errorMsg: true,
					male: '0',
					female: '0',
					total: '0',
					apiSection: '-',
					apiSession: '- / -',
					apiClass: '-'
				});

				console.log(err);
			});
		console.log(this.state);
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.adminData !== nextProps.AllTransportData) {
			return {
				adminData: nextProps.AllTransportData
			};
		}
		return null;
	}
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
		$('[data-toggle="tooltip"]').tooltip();
	}
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	render() {
		const { data } = this.props;
		const { session } = this.props;
		const { adminData } = this.state;
		console.log('Data he Data', adminData);
		return (
			<div>
				<div className="page-header">
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Search&nbsp;</h2>
						</div>
						<div>
							<h3>
								<small class="text-muted">Age Wise</small>
							</h3>
						</div>
					</div>
				</div>
				<div class="row" style={{ justifyContent: 'center' }}>
					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-bars animated fadeIn"
								/>
								Session's
							</label>
							<select
								onChange={this.onSelectSessionChange}
								class="custom-select"
								name="sectionName"
							>
								<option selected={this.state.msg ? true : false}>
									Select Current Session..
								</option>
								{session &&
									session.map((item, index) => (
										<option value={`${item.sessionId}`}>
											{item.currentSession}
										</option>
									))}
							</select>
						</div>
					</div>
					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-school animated fadeIn"
								/>
								Class Name
							</label>
							<select
								onChange={this.onSelectSectionChange}
								class="custom-select"
								name="studentId"
							>
								<option selected={this.state.msg ? true : false}>
									Choose Class..
								</option>
								{data &&
									data.map((item, index) => (
										<option key={index} value={`${item.id}`}>
											{item.className}
										</option>
									))}
							</select>
						</div>
					</div>
					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-bars animated fadeIn"
								/>
								Class Section
							</label>
							<select
								onChange={this.onSelectStudentChange}
								class="custom-select"
								name="sectionName"
							>
								<option selected={this.state.msg ? true : false}>
									Choose Section...
								</option>
								{this.state.sections.map((item, index) => (
									<option value={`${item.sectionId}`}>{item.section}</option>
								))}
							</select>
						</div>
					</div>
					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								<i
									style={{ marginRight: 4 }}
									className="fas fa-bars animated fadeIn"
								/>
								Age
							</label>
							<input
								type="text"
								name="age"
								class="form-control"
								onChange={this.onHandleTextChange}
								placeholder="Please Enter Your Age"
							/>
						</div>
					</div>
				</div>
				<br />
				<div class="text-center text-md-right">
					<a href="#" data-toggle="tooltip" title="Search!">
						<button
							id="firstbutton"
							onClick={this.onHandleSubmit}
							onMouseOver={this.btnTechHover()}
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								marginLeft: 5,
								background: 'none',
								marginBottom: 5
							}}
						>
							<i class="fas fa-search fa-2x"></i>
						</button>
					</a>
				</div>
				{this.Message('No Record Found!', 'danger', 20, this.state.errorMsg)}
				<div class="row">
					<div class="col-md-4">
						<div class="md-form mb-0">
							<ProgressBarComponent percentage={this.state.male} />
							<label style={{ fontWeight: 'bold', fontSize: 20 }}>
								Male Students
							</label>{' '}
						</div>
					</div>
					<div class="col-md-4">
						<div class="md-form mb-0">
							<ProgressBarComponent percentage={this.state.female} />
							<label style={{ fontWeight: 'bold', fontSize: 20 }}>
								Female Students
							</label>{' '}
						</div>
					</div>
					<div class="col-md-4">
						<div class="md-form mb-0">
							<ProgressBarComponent percentage={this.state.total} />
							<label style={{ fontWeight: 'bold', fontSize: 20 }}>
								Total Students
							</label>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		AgeWiseActions: bindActionCreators(AgeWiseActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		AllTransportData: state.setAgeWiseSearchReducer.Data,
		allTransportState: state.setAgeWiseSearchReducer
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DropDownComponent);
