import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
export default function validateinput(data, formType, data1) {
	let errors = {};

	function validateEmail(email) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	if (formType === 'checkParentInfo') {
		if (Validator.isEmpty(data.parentIdCardNo)) {
			errors.parentIdCardNo = true;
		}
		if (Validator.isEmpty(data.parentEmailId)) {
			errors.parentEmailId = true;
		}
		if (Validator.isEmpty(data.parentMobileNo)) {
			errors.parentMobileNo = true;
		}
		if (Validator.isEmpty(data.emergencyContact)) {
			errors.emergencyContact = true;
		}
		if (Validator.isEmpty(data.income)) {
			errors.income = true;
		}
		if (Validator.isEmpty(data.occupation)) {
			errors.occupation = true;
		}
		if (Validator.isEmpty(data.garduainName)) {
			errors.garduainName = true;
		}
		if (Validator.isEmpty(data.motherName)) {
			errors.motherName = true;
		}
		if (Validator.isEmpty(data.fatherPName)) {
			errors.fatherPName = true;
		}
	} else if (formType === 'checkStaffDetailsForNonTech') {
		if (Validator.isEmpty(data.StaffFirstName)) {
			errors.StaffFirstName = true;
		}
		if (Validator.isEmpty(data.fullNameArabic)) {
			errors.fullNameArabic = true;
		}

		if (Validator.isEmpty(data.lastName)) {
			errors.lastName = true;
		}
		if (Validator.equals(data.gender, 'Choose Gender...')) {
			errors.gender = true;
		}
		// if (Validator.isEmpty(data.fatherName)) {
		//   errors.fatherName = true;
		// }
		if (Validator.isEmpty(data.designation)) {
			errors.designation = true;
		}
		if (Validator.equals(data.maritailStatus, 'Marital Status...')) {
			errors.maritailStatus = true;
		}
		if (Validator.equals(data.nationality, 'Choose Nationality...')) {
			errors.nationality = true;
		}

		if (Validator.isEmpty(data.state)) {
			errors.state = true;
		}

		if (Validator.isEmpty(data.email)) {
			errors.email = true;
		}
		if (Validator.isEmpty(data.fatherName)) {
			errors.fatherName = true;
		}

		if (Validator.equals(data.religion, 'Choose Religion...')) {
			errors.religion = true;
		}

		if (Validator.equals(data.status, 'Choose Status...')) {
			errors.status = true;
		}
		if (Validator.equals(data.bloodGroup, 'Choose Group...')) {
			errors.bloodGroup = true;
		}
		debugger;
		if (Validator.equals(data1.departmentId.toString(), 'Choose Depart')) {
			errors.departmentId = true;
		}
		if (Validator.equals(data1.postId.toString(), 'Choose Post')) {
			errors.postId = true;
		}
		if (Validator.equals(data1.campusId.toString(), 'Choose Campus...')) {
			errors.campusId = true;
		}
		// if (isNull(data1.file)) {
		// 	errors.file = true;
		// }
		if (Validator.equals(data.appraisalStatus, 'Choose Status...')) {
			errors.appraisalStatus = true;
		}
		if (Validator.isEmpty(data.basicSalary)) {
			errors.basicSalary = true;
		}
		if (isNull(data1.dob)) {
			errors.dob = true;
		}
	} else if (formType === 'checkStaffDetailsForTeach') {
		if (Validator.isEmpty(data.StaffFirstName)) {
			errors.StaffFirstName = true;
		}
		if (Validator.isEmpty(data.fullNameArabic)) {
			errors.fullNameArabic = true;
		}

		if (Validator.isEmpty(data.lastName)) {
			errors.lastName = true;
		}
		if (Validator.equals(data.gender, 'Choose Gender...')) {
			errors.gender = true;
		}
		// if (Validator.isEmpty(data.fatherName)) {
		//   errors.fatherName = true;
		// }
		if (Validator.isEmpty(data.designation)) {
			errors.designation = true;
		}
		if (Validator.equals(data.maritailStatus, 'Marital Status...')) {
			errors.maritailStatus = true;
		}
		if (Validator.equals(data.nationality, 'Choose Nationality...')) {
			errors.nationality = true;
		}

		if (Validator.isEmpty(data.state)) {
			errors.state = true;
		}

		if (Validator.isEmpty(data.email)) {
			errors.email = true;
		}
		if (Validator.isEmpty(data.fatherName)) {
			errors.fatherName = true;
		}

		if (Validator.equals(data.religion, 'Choose Religion...')) {
			errors.religion = true;
		}
		if (Validator.isEmpty(data.username)) {
			errors.username = true;
		}
		if (Validator.isEmpty(data.password)) {
			errors.password = true;
		}

		if (Validator.equals(data.status, 'Choose Status...')) {
			errors.status = true;
		}
		if (Validator.equals(data.bloodGroup, 'Choose Group...')) {
			errors.bloodGroup = true;
		}
		if (Validator.equals(data1.sectionId.toString(), 'Choose Section')) {
			errors.sectionId = true;
		}
		if (Validator.equals(data1.classId.toString(), 'Choose Class')) {
			errors.classId = true;
		}
		if (Validator.equals(data1.subjectId.toString(), 'Choose Subject')) {
			errors.subjectId = true;
		}
		if (Validator.equals(data1.departmentId.toString(), 'Choose Depart')) {
			errors.departmentId = true;
		}
		if (Validator.equals(data1.postId.toString(), 'Choose Post')) {
			errors.postId = true;
		}
		if (Validator.equals(data1.campusId.toString(), 'Choose Campus...')) {
			errors.campusId = true;
		}
		// if (isNull(data1.file)) {
		// 	errors.file = true;
		// }
		if (Validator.equals(data.appraisalStatus, 'Choose Status...')) {
			errors.appraisalStatus = true;
		}
		if (Validator.isEmpty(data.basicSalary)) {
			errors.basicSalary = true;
		}
		if (isNull(data1.dob)) {
			errors.dob = true;
		}
	} else if (formType === 'checkStaffAdress') {
		if (Validator.isEmpty(data.address)) {
			errors.address = true;
		}
		if (Validator.isEmpty(data.StreetNo)) {
			errors.StreetNo = true;
		}
		if (Validator.isEmpty(data.city)) {
			errors.city = true;
		}
		if (Validator.equals(data.country, 'Choose Country...')) {
			errors.country = true;
		}
		if (Validator.isEmpty(data.wayNo)) {
			errors.wayNo = true;
		}
		if (Validator.isEmpty(data.houseNo)) {
			errors.houseNo = true;
		}
		if (Validator.isEmpty(data.mobileNumber)) {
			errors.mobileNumber = true;
		}
	} else if (formType === 'staffPassPort') {
		if (Validator.isEmpty(data.staffPassportNo)) {
			errors.staffPassportNo = true;
		}
		if (Validator.isEmpty(data.staffIdcardNo)) {
			errors.staffIdcardNo = true;
		}
		if (data1.staffPassportDOI === '') {
			errors.staffPassportDOI = true;
		}
		if (data1.staffPassportDOE === '') {
			errors.staffPassportDOE = true;
		}
		if (data1.staffIdcardDOI === '') {
			errors.staffIdcardDOI = true;
		}
		if (data1.staffIdcardDOE === '') {
			errors.staffIdcardDOE = true;
		}
	} else if (formType === 'staffDocs') {
		if (
			Validator.isEmpty(data1.experienceLetter) &&
			Validator.isEmpty(data1.experienceLetterfilePath)
		) {
			errors.experienceLetter = true;
		}
		if (
			data1.experienceLetter === 'Yes' &&
			data1.experienceLetterfilePath === ''
		) {
			errors.experienceLetter = true;
		}
		if (
			Validator.isEmpty(data1.iletsCertificate) &&
			Validator.isEmpty(data1.iletsCertificatefilePath)
		) {
			errors.iletsCertificate = true;
		}
		if (
			data1.iletsCertificate === 'Yes' &&
			data1.iletsCertificatefilePath === ''
		) {
			errors.iletsCertificate = true;
		}
		if (
			Validator.isEmpty(data1.ministryApproval) &&
			Validator.isEmpty(data1.ministryApprovalfilePath)
		) {
			errors.ministryApproval = true;
		}
		if (
			data1.ministryApproval === 'Yes' &&
			data1.ministryApprovalfilePath === ''
		) {
			errors.ministryApproval = true;
		}
		if (
			Validator.isEmpty(data1.lastQualification) &&
			Validator.isEmpty(data1.lastQualificationfilePath)
		) {
			errors.lastQualification = true;
		}
		if (
			data1.lastQualification === 'Yes' &&
			data1.lastQualificationfilePath === ''
		) {
			errors.lastQualification = true;
		}
		if (
			Validator.isEmpty(data1.passportCopy) &&
			Validator.isEmpty(data1.passportCopyfilePath)
		) {
			errors.passportCopy = true;
		}
		if (data1.passportCopy === 'Yes' && data1.passportCopyfilePath === '') {
			errors.passportCopy = true;
		}
		if (
			Validator.isEmpty(data1.idCardCopys) &&
			Validator.isEmpty(data1.idCardCopysfilePath)
		) {
			errors.idCardCopys = true;
		}
		if (data1.idCardCopys === 'Yes' && data1.idCardCopysfilePath === '') {
			errors.idCardCopys = true;
		}
	} else if (formType === 'checkRattingForm') {
		if (isNull(data.HOD)) {
			errors.HOD = true;
		}
		if (isNull(data.Management)) {
			errors.Management = true;
		}
		if (isNull(data.Student)) {
			errors.Student = true;
		}
		if (isNull(data.Collegue)) {
			errors.Collegue = true;
		}
		if (isNull(data.Parents)) {
			errors.Parents = true;
		}
	} else if (formType === 'checkEditAppraisalData') {
		if (
			Validator.equals(data.type, 'Mixed') &&
			(Validator.isEmpty(data.percentageBasic) &&
				Validator.isEmpty(data.percentagePerformance)) &&
			!data.disabledPB &&
			!data.disabledP
		) {
			errors.percentageBasic = true;
			errors.percentagePerformance = true;
		}
		if (
			Validator.equals(data.type, 'Mixed') &&
			Validator.isEmpty(data.percentagePerformance) &&
			!data.disabledP
		) {
			errors.percentagePerformance = true;
		}
		if (
			Validator.equals(data.type, 'Mixed') &&
			(Validator.isEmpty(data.percentageBasic) && !data.disabledPB)
		) {
			errors.percentageBasic = true;
		}
		if (
			Validator.equals(data.type, 'Fixed') &&
			Validator.isEmpty(data.percentageBasic)
		) {
			errors.percentageBasic = true;
		}
		if (
			Validator.equals(data.type, 'Performance') &&
			Validator.isEmpty(data.percentagePerformance)
		) {
			errors.percentagePerformance = true;
		}
		if (Validator.isEmpty(data.type) || Validator.equals(data.type, 'Type')) {
			errors.type = true;
		}
	} else if (formType === 'checkAppraisalData') {
		if (
			Validator.equals(data.type, 'Mixed') &&
			(Validator.isEmpty(data.percentageBasic) &&
				Validator.isEmpty(data.percentagePerformance)) &&
			!data.disabledPB &&
			!data.disabledP
		) {
			errors.percentageBasic = true;
			errors.percentagePerformance = true;
		}
		if (
			Validator.equals(data.type, 'Mixed') &&
			Validator.isEmpty(data.percentagePerformance) &&
			!data.disabledP
		) {
			errors.percentagePerformance = true;
		}
		if (
			Validator.equals(data.type, 'Mixed') &&
			(Validator.isEmpty(data.percentageBasic) && !data.disabledPB)
		) {
			errors.percentageBasic = true;
		}
		if (
			Validator.equals(data.type, 'Fixed') &&
			Validator.isEmpty(data.percentageBasic)
		) {
			errors.percentageBasic = true;
		}
		if (Validator.isEmpty(data.post)) {
			errors.post = true;
		}
		if (Validator.isEmpty(data.department)) {
			errors.department = true;
		}
		if (
			Validator.equals(data.type, 'Performance') &&
			Validator.isEmpty(data.percentagePerformance)
		) {
			errors.percentagePerformance = true;
		}
		if (Validator.isEmpty(data.staffId)) {
			errors.staffId = true;
		}
		if (Validator.isEmpty(data.type) || Validator.equals(data.type, 'Type')) {
			errors.type = true;
		}
		if (
			Validator.isEmpty(data.staffId) ||
			Validator.equals(data.staffId, 'Choose Staff')
		) {
			errors.staffId = true;
		}
		if (
			Validator.isEmpty(data.timeDuration) ||
			Validator.equals(data.timeDuration, 'Type')
		) {
			errors.timeDuration = true;
		}
	} else if (formType === 'checkStudentDocument') {
		if (isNull(data.dobCertificate)) {
			errors.dobCertificate = true;
		}
		if (isNull(data.transferCertificate)) {
			errors.transferCertificate = true;
		}
		if (isNull(data.ministryVacination)) {
			errors.ministryVacination = true;
		}
		if (isNull(data.medicalHistory)) {
			errors.medicalHistory = true;
		}
		if (isNull(data.idCardCopy)) {
			errors.idCardCopy = true;
		}
		if (isNull(data.passportCopy)) {
			errors.passportCopy = true;
		}
		if (isNull(data.lastResult)) {
			errors.lastResult = true;
		}
		if (isNull(data.testResult)) {
			errors.testResult = true;
		}
	} else if (formType === 'checkAddressForm') {
		if (Validator.isEmpty(data.studentIdCardNo)) {
			errors.studentIdCardNo = true;
		}
		if (Validator.isEmpty(data.address)) {
			errors.address = true;
		}
		if (Validator.isEmpty(data.city)) {
			errors.city = true;
		}
		if (Validator.isEmpty(data.streetNo)) {
			errors.streetNo = true;
		}
		if (Validator.isEmpty(data.houseNo)) {
			errors.houseNo = true;
		}
		if (Validator.isEmpty(data.studentEmailId)) {
			errors.studentEmailId = true;
		}
		if (Validator.equals(data.country, 'Choose Country...')) {
			errors.country = true;
		}
		if (Validator.isEmpty(data.area)) {
			errors.area = true;
		}
		if (Validator.isEmpty(data.wayNo)) {
			errors.wayNo = true;
		}
	} else if (formType === 'checkStudentForm') {
		if (isNull(data.file)) {
			errors.file = true;
		}
		if (Validator.isEmpty(data.firstName)) {
			errors.firstName = true;
		}
		if (Validator.isEmpty(data.lastName)) {
			errors.lastName = true;
		}
		if (Validator.isEmpty(data.fatherName)) {
			errors.fatherName = true;
		}
		if (Validator.isEmpty(data.rollNo)) {
			errors.rollNo = true;
		}
		if (Validator.isEmpty(data.admissionNo)) {
			errors.admissionNo = true;
		}
		if (Validator.equals(data.admissionType, 'Admission Type...')) {
			errors.admissionType = true;
		}
		if (
			Validator.equals(data.currentSessionId.toString(), 'Current Session...')
		) {
			errors.currentSession = true;
		}
		if (Validator.equals(data.language, 'Choose Language...')) {
			errors.language = true;
		}
		if (Validator.isEmpty(data.parentId.toString())) {
			errors.parentId = true;
		}
		if (Validator.equals(data.gender, 'Choose Gender...')) {
			errors.gender = true;
		}
		if (isNull(data.dob)) {
			errors.dob = true;
		}
		if (isNull(data.age)) {
			errors.age = true;
		}
		if (Validator.isEmpty(data.placeOfBirth)) {
			errors.placeOfBirth = true;
		}
		if (Validator.equals(data.religion, 'Religion...')) {
			errors.religion = true;
		}
		if (Validator.equals(data.campusName, 'Campus Name...')) {
			errors.campusName = true;
		}
		if (Validator.equals(data.className, 'Class Name...')) {
			errors.className = true;
		}
		if (Validator.isEmpty(data.gradeName)) {
			errors.gradeName = true;
		}
		if (Validator.isEmpty(data.section)) {
			errors.section = true;
		}
		if (Validator.isEmpty(data.userName)) {
			errors.userName = true;
		}
		if (Validator.isEmpty(data.password)) {
			errors.password = true;
		}
		if (Validator.equals(data.status, 'Choose Status...')) {
			errors.status = true;
		}
		if (Validator.equals(data.nationality, 'Choose Nationality...')) {
			errors.nationality = true;
		}
	} else if (formType === 'loginPageValidation') {
		if (Validator.isEmpty(data.password)) {
			errors.password = true;
		}
		if (Validator.isEmpty(data.email)) {
			errors.email = true;
		}
	} else if (formType === 'UserDetailsValidation') {
		if (Validator.isEmpty(data.name)) {
			errors.name = true;
		}
		if (Validator.isEmpty(data.gender)) {
			errors.gender = true;
		}
		if (Validator.isEmpty(data.profession)) {
			errors.profession = true;
		}
		if (isNull(data.profileimage)) {
			errors.file = true;
		}
	} else if (formType === 'AdminLoginValidation') {
		if (Validator.isEmpty(data.email)) {
			errors.email = true;
		}
		if (Validator.isEmpty(data.password)) {
			errors.password = true;
		}
	} else if (formType === 'votingSessionForm') {
		if (Validator.isEmpty(data.date)) {
			errors.date = true;
		}
		if (Validator.isEmpty(data.time)) {
			errors.time = true;
		}
		if (Validator.isEmpty(data.time2)) {
			errors.time2 = true;
		}
	} else if (formType === 'voterProfileDisplay') {
		if (isNull(data.file)) {
			errors.file = true;
		}
	} else if (formType === 'addVoter') {
		if (Validator.isEmpty(data.email)) {
			errors.email = true;
		}
	}
	return {
		errors,
		isValid: isEmpty(errors)
	};
}
