import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import $ from 'jquery';
import { RenderAction, ConfirmationButton } from '../Edit';
import img1 from '../../Assets/images/toplog.jpg';
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import img from '../../Assets/images/logo1.png';
import alphaNumericReturn from '../../utils/alphaNumericReturn';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button';
import {
	PeriodBox,
	DayRowCom
} from '../../Container/Time-Table/TimeTableRendering';
let errors1 = {};
const RendeBodyData = props => {
	const {
		errors,
		onSectionChange,
		stateData,
		localLoad,
		actionFieldName,
		onSubmit,
		onChangeDynamic,
		editPeriodData,
		EditClick,
		eidtHorizonData,
		daysForEdit,
		actionsName,
		subAndTechData,
		techNames,
		onChangeClassName
	} = props;

	const PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
		setTimeout(function() {
			window.location.reload();
		}, 0);
	};
	const back = () => {
		$('#ttViewModal').modal('hide');
	};

	let getTeachNameWithoutNull =
		techNames && techNames.filter(item => item.StaffFirstName !== null);
	useEffect(() => {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
	}, []);
	return props && props.Data && props.Data.length > 0 ? (
		props &&
			props.Data &&
			props.Data.map((itemMain, index) => {
				return (
					<tr key={index}>
						<th style={{ padding: 2 }} scope="row">
							{index + 1}
						</th>
						{props.renderFeild.map(item1 => (
							<td style={{ padding: 2 }}>{itemMain[item1]}</td>
						))}
						<td style={{ padding: 2 }}>
							<RenderAction
								actionFieldName={actionFieldName}
								obj={itemMain}
								actionsName={actionsName}
								Actions={props.Actions}
								ViewClick={props.ViewClick}
								DeleteClick={props.DeleteClick}
								EditClick={EditClick}
							/>
							{props.Actions.map((item, index) => (
								<Modal
									mdId={item.mdId}
									height={item.height}
									fullModal={item.fullModal}
									animation="fade"
									modalTitle={item.modalTitle}
									modalBody={item.modalBody}
									modalSize={item.modalSize}
									type={item.type}
									header={item.header}
									bottomCloseButtonName={item.bottomCloseButtonName}
									bottomSaveButtonName={item.bottomSaveButtonName}
									handleSubmit={() => item.handleSubmit()}
									//   stateData={props.stateData}
								>
									{item[actionFieldName] === 'ttView' ? (
										<React.Fragment>
											<div id="div1" class="table-responsive-xl">
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'space-between',
														textAlign: 'center'
													}}
												>
													<div>
														<img
															style={{ width: '100%', height: '30%' }}
															src={img1}
															alt="#img"
														/>
													</div>
													<div>
														<p
															style={{
																fontSize: 40,
																letterSpacing: 20,
																margin: 0
															}}
														>
															TIME TABLE
														</p>
														<p
															style={{
																fontSize: 23,
																letterSpacing: 10,
																color: '#000',
																margin: 0
															}}
														>
															{props &&
																props.headerDetails &&
																props.headerDetails.Grade + ' '}
															<b>
																{alphaNumericReturn(
																	parseInt(
																		props &&
																			props.headerDetails &&
																			props.headerDetails.Class
																	)
																)}
															</b>

															<b>
																-
																{props &&
																	props.headerDetails &&
																	props.headerDetails.Section}
															</b>
														</p>
													</div>
												</div>
												<table class="table table-bordered">
													<thead>
														<tr>
															<th scope="col">
																<div style={{ marginBottom: 50 }}>Days</div>
															</th>
															{props.periodBoxData &&
																props.periodBoxData.map((item, index) => (
																	<PeriodBox
																		dispTime={item.dispTime}
																		periodDuration={item.periodDuration}
																		type={item.type}
																		name={item.name}
																		index={index}
																	/>
																))}
														</tr>
													</thead>
													<tbody>
														{props.workingDays &&
															props.workingDays.map((item, index) => (
																<DayRowCom
																	type="nonDataHandling"
																	off={item.off}
																	day={item.day}
																	indexDay={index}
																	AllPeriods={props.periods}
																/>
															))}
													</tbody>
												</table>
												<table id="t-table" class="table table-bordered">
													<thead>
														<td>
															<p style={{ margin: 0 }}>#</p>
														</td>
														<th>
															<p style={{ margin: 0 }}>Teachers</p>
														</th>
														<th>
															<p style={{ margin: 0 }}>Code</p>
														</th>
													</thead>
													<tbody>
														{props &&
															getTeachNameWithoutNull &&
															getTeachNameWithoutNull.map((item, index) =>
																item.StaffFirstName !== null ? (
																	<tr>
																		<td>
																			<p style={{ color: '#000' }}>
																				{index + 1}
																			</p>
																		</td>
																		<td>
																			<p style={{ color: '#000' }}>
																				{item.StaffFirstName +
																					' ' +
																					item.lastName}
																			</p>
																		</td>
																		<td>
																			<b>
																				<p style={{ color: '#000' }}>
																					{'(' +
																						item.StaffFirstName.charAt(0) +
																						' ' +
																						item.lastName.charAt(0) +
																						')'}
																				</p>
																			</b>
																		</td>
																	</tr>
																) : (
																	''
																)
															)}
													</tbody>
												</table>
												<div class="text-center text-md-right">
													<label
														for="name"
														class=""
														onClick={() => PrintContent('div1')}
													>
														<i
															className="fas fa-print fa-2x animated fadeIn"
															style={{ marginRight: 4 }}
														/>
														Print It Out
													</label>
												</div>
											</div>
											<div class="text-center text-md-left">
												<label
													style={{ cursor: 'Pointer' }}
													for="name"
													class=""
													onClick={back}
												>
													<i
														className="fas fa-backward"
														style={{ marginRight: 4 }}
													/>
													back
												</label>
											</div>
										</React.Fragment>
									) : item[actionFieldName] === 'ttEdit' ? (
										<React.Fragment>
											{errors && errors.responseError ? (
												<p style={{ color: 'red', textAlign: 'center' }}>
													{errors && errors.responseError}
												</p>
											) : (
												''
											)}
											<div class="table-responsive-xl">
												<table class="table table-bordered">
													<thead>
														<tr>
															<th scope="col">
																<div style={{ marginBottom: 50 }}>Days</div>
															</th>
															{eidtHorizonData &&
																eidtHorizonData.map((item, index) => (
																	<PeriodBox
																		dispTime={item.dispTime}
																		periodDuration={item.periodDuration}
																		type={item.type}
																		name={item.name}
																		index={index}
																	/>
																))}
														</tr>
													</thead>
													<tbody>
														{daysForEdit &&
															daysForEdit.map((item, index) => (
																<DayRowCom
																	type="EditDataHandling"
																	off={item.off}
																	day={item.day}
																	onChangeNewPeriodData={onChangeDynamic}
																	subAndTechData={subAndTechData}
																	indexDay={index}
																	AllPeriods={editPeriodData}
																/>
															))}
													</tbody>
												</table>
											</div>
										</React.Fragment>
									) : item[actionFieldName] === 'ttDelete' ||
									  item[actionFieldName] === 'LessonDelete' ||
									  item[actionFieldName] === 'StaffDelete' ? (
										<React.Fragment>
											<ConfirmationButton removeElement={props.removeElement} />
										</React.Fragment>
									) : item[actionFieldName] === 'LessonEdit' ? (
										<React.Fragment>
											{' '}
											<FormLayoutWrapper
												flagName={'fieldValidate'}
												errorFlag={errors}
												type="row"
												formName="Lesson Per Week"
												borderColor="#01ac8a"
												borderRadius={15}
											>
												<span
													style={{ color: '#01ac8a' }}
													class={`${
														stateData.dataLoader
															? 'spinner-border spinner-border-lg'
															: ''
													}`}
												></span>
												{stateData.fieldValidate ? (
													<p style={{ color: 'red' }}>
														Please fill the form properly!
													</p>
												) : (
													''
												)}
												{localLoad ? (
													<p>Loading...</p>
												) : (
													<React.Fragment>
														<SelectionInput
															selectName="Class"
															feildName="className"
															selectedText="Choose Class"
															optionsArrys={
																subAndTechData && subAndTechData.classes
															}
															errors={errors1}
															stateData={stateData}
															successflag={'successflag'}
															onHandleChange={onChangeClassName}
															optionType="dynamic"
															property="className"
															// iconClassName='fas fa-level-down-alt'
															propertyId="className"
														/>

														<SelectionInput
															selectName="Section"
															feildName="sectionId"
															selectedText="Choose Section"
															optionsArrys={stateData.grade}
															errors={errors1}
															successflag={'successflag'}
															stateData={stateData}
															optionType="dynamic"
															onHandleChange={onSectionChange}
															property="section"
															// iconClassName='fas fa-level-down-alt'
															propertyId="sectionId"
														/>
														<TextInput
															feildName="gradeName"
															inputLabelName="Grade Name"
															errors={errors1}
															stateData={stateData}
															placeholder="Grade Name"
															readOnly={true}
															type={'text'}
														/>
														<SelectionInput
															selectName="Subject"
															feildName="subjectId"
															successflag={'successflag'}
															selectedText="Choose Subject"
															optionsArrys={stateData.subjects}
															errors={errors1}
															stateData={stateData}
															optionType="dynamic"
															onHandleChange={e => stateData.onGeneralChange(e)}
															property="subjectName"
															// iconClassName='fas fa-level-down-alt'
															propertyId="subjectId"
															//  useFlag={true}
														/>
														<TextInput
															feildName="quantity"
															inputLabelName="quantity"
															errors={errors1}
															stateData={stateData}
															onHandleChange={e => stateData.onGeneralChange(e)}
															placeholder="Enter Quantity"
															readOnly={
																stateData.disabledField ||
																(stateData &&
																	stateData.subjects &&
																	stateData.subjects.length < 1)
															}
															type={'text'}
															required={false}
														/>

														<div style={{ visibility: 'hidden' }}>
															<TextInput
																feildName="quantity"
																inputLabelName="Quantity"
																errors={errors1}
																stateData={stateData}
																onHandleChange={e =>
																	stateData.onGeneralChange(e)
																}
																placeholder="Enter Quantity"
																readOnly={false}
																disabledField={false}
																type={'text'}
															/>
														</div>

														<Button
															width="50%"
															buttonClass="btns"
															btnName="Update"
															loaderBmargin={5}
															stateData={stateData}
															marginTop={20}
															// dataObj={itemMain}
															onHandleSubmit={stateData.onEditSubmit}
															margin={'0 auto'}
														/>
													</React.Fragment>
												)}
											</FormLayoutWrapper>
										</React.Fragment>
									) : item[actionFieldName] === 'StaffView' ? (
										''
									) : item[actionFieldName] === 'StaffEdit' ? (
										''
									) : item[actionFieldName] === 'StaffDocs' ? (
										''
									) : (
										''
									)}
								</Modal>
							))}
						</td>
					</tr>
				);
			})
	) : (
		<p style={{ position: 'absolute' }}>No Records Exist </p>
	);
};

export default RendeBodyData;
