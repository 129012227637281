import React from 'react';

function InActivePage() {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column'
			}}
		>
			<p style={{ fontSize: 30 }}>
				This Module is under implementation <i class="fas fa-smile"></i>
			</p>
		</div>
	);
}

export default InActivePage;
