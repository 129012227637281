import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';




class NotificationEmergencyMaintenance extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            id: null,
            staffName: null,
            campusName: null,
            departmentName: null,
            postName: null,
            month: null,
            year: null,
            basicSalary: null,
            taxAmount: null,
            allowances: null,
            bonusAmount: null,
            loanamountDeduction: null,
            attendancededuction: null,
            taxdeduction: null,
            totatAmountDeduct: null,
            grossIncome: null,
            NetIncome: null,
            status: null,
            accountId: null,
            accountType: null, 

    

        }

        


    }

    componentDidMount(){
        this.getSimple('campusDropdown', 'campus')


    }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false,
                emergencyMaintenance: []
            })
            console.error('Error occured', error);
        })
    }

    handleFilter = (e) => {
        if(!isEmpty(e.target.value)){
            
            e.target.value == 'FloorWise' ? 
            this.getSimple('Emergency/FloorWise', 'emergencyMaintenance') : 
            this.getSimple('Emergency/RoomWise', 'emergencyMaintenance')

        }else{
            this.setState({
                emergencyMaintenance: null
            })
        }
        
    }


    

    onEditClick = id => {
        const { emergencyMaintenance } = this.state
        let modalData = emergencyMaintenance.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        console.log(modalData)
        this.setState({
            categoryId: 1,
            accountId: modalData[0].accountId,
            accountType: modalData[0].accountType,
            categoryName: modalData[0].categoryName,
            date: modalData[0].date,
            description: modalData[0].description,
            floorId: modalData[0].floorId,
            floorName: modalData[0].floorName,
            id: modalData[0].id,
            postId: modalData[0].postId,
            postName: modalData[0].postName,
            roomId: modalData[0].roomId,
            roomName: modalData[0].roomName ? modalData[0].roomName : '-',
            staffId: modalData[0].staffId,
            staffName: modalData[0].staffName,
            status: modalData[0].status,
            time: modalData[0].time, 
            
        }, () => {
            $('#myModal1').modal({
                show: true
            })
        })
    }



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    handleSubmit = e => {
        e.preventDefault();
        console.log('Submit Called')

        const {  
            categoryId,
            accountId,
            accountType,
            categoryName,
            date,
            description,
            floorId,
            floorName,
            id,
            postId,
            postName,
            roomId,
            roomName,
            staffId,
            staffName,
            status,
            time,   } = this.state;

        let data1 = {
            categoryId,
            accountId,
            accountType,
            categoryName,
            date,
            description,
            floorId,
            floorName,
            id,
            postId,
            postName,
            roomId,
            roomName,
            staffId,
            staffName,
            status,
            time, 
        }

        let data2 = {
            categoryId,
            accountId,
            accountType,
            categoryName,
            date,
            description,
            floorId,
            floorName,
            id,
            postId,
            postName,
            roomId,
            staffId,
            staffName,
            status,
            time, 
        }

        let data = roomName == '-' ? data2 : data1

        console.log('data model', data)
        this.props.sharedActions.editPatchRecordWithoutDispatch(
            '/api/EmergencyMaintenance/',
            id,
            data
        ).then(success => {
            console.log('success', success)
            this.state.emergencyMaintenance.splice(this.state.editIndex, 1, data)
            
                
                $('#myModal1').modal('hide')
            
            this.setState({
                msg: 'Record Changed Successfully!',

                
            })
            this.props.snackbar();
            

        }).catch(error => {
            console.error('Error name: ', error)
                
                $('#myModal1').modal('hide')
            this.setState({
                msg: 'Error Occured!'
            })
            this.props.snackbar();


        })
    }


    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
	};


    render(){

        const { search, isLoading, editToggle, msg, emergencyMaintenance,

            floorName, floorId, roomName, roomId, categoryName, categoryId, staffName, staffId, accountId, accountType, date, status, 
            
        } = this.state;

        



        // const editEmergencyMaintenanceData = (
        //     <div>
        //     <div className="modal" id="myModal1">
        //         <div className="modal-dialog">
        //             <div className="modal-content modal_content_custom">
        //                 <div
        //                     style={{
        //                         borderBottomLeftRadius: 30,
        //                         borderBottomRightRadius: 30,
        //                         borderTopLeftRadius: 18,
        //                         borderTopRightRadius: 18
        //                     }}
        //                     className="modal-header modal_custom_header"
        //                 >
        //                     <h4 style={{ color: 'white' }} className="modal-title">
        //                         Edit
        //                     </h4>
        //                     <button
        //                         style={{ color: 'white' }}
        //                         on
        //                         type="button"
        //                         className="close"
        //                         data-dismiss="modal"
        //                     >
        //                         &times;
        //                     </button>
        //                 </div>
        //                 <div className="modal-body">
        //                     <form onSubmit={this.handleSubmit} >
        //                     <fieldset disabled={!editToggle}>
                                
        //                         <div className="form-group">
        //                         <label for="category">
        //                                         Status:
        //                                     </label>
        //                         <select class="custom-select" 
        //                                         value={status}
        //                                         name="status"
        //                                         onChange={this.onHandleText}

        //                                     >
        //                                                    <option value="On-Review">On Review</option>
        //                                                    <option value="In-Progress">In Progress</option>
        //                                                    <option value="Completed">Completed</option>
        //                                     </select>
        //                         </div>
        //                         <div className="form-group">
        //                         <label for="accountId">Account Name:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={accountId}
        //                         name="modalDataAccountId"
        //                         className="form-control"
        //                         id="accountId"
        //                         />
        //                         </div>
        //                         <div className="form-group">
        //                         <label for="accountType">Account Type:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={accountType}
        //                         name="modalDataAccountType"
        //                         className="form-control"
        //                         id="accountType"
        //                         />
        //                         </div>
        //                         <div className="form-group">
        //                         <label for="staffName">Staff Name:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={staffName}
        //                         name="staffName"
        //                         className="form-control"
        //                         id="staffName"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="floorName">Floor Name:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={floorName}
        //                         name="floorName"
        //                         className="form-control"
        //                         id="floorName"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="roomName">Room Name:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={roomName}
        //                         name="roomName"
        //                         className="form-control"
        //                         id="roomName"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="categoryName">Category Name:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={categoryName}
        //                         name="categoryName"
        //                         className="form-control"
        //                         id="categoryName"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="date">Date:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={date}
        //                         name="date"
        //                         className="form-control"
        //                         id="date"
        //                         />
        //                         </div>
        //                     </fieldset>
        //                     <br />
		// 					<br />
		// 						<button
		// 							disabled={editToggle}
		// 							onClick={() => this.setState({ editToggle: true })}
		// 							type="button"
		// 							class="btn btn-primary buttonAppear"
		// 							>
		// 						    	Edit
		// 							</button>
		// 							<button
		// 								style={{ marginLeft: 5 }}
		// 								disabled={!editToggle}
		// 								type="submit"
		// 								class="btn btn-primary buttonAppear"
		// 								>
		// 								Save
		// 							</button>
        //                     </form>
        //                 </div>
        //                 <div class="modal-footer">
		// 						<button
		// 							type="button"
		// 							style={{ background: '#00435D' }}
        //                             class="btn btn-danger"
        //                             onClick={() => this.setState({editToggle: false})}
		// 							data-dismiss="modal"
		// 						>
		// 							Close
		// 						</button>
		// 					</div>
        //             </div>
        //         </div>
        //     </div>
        //     </div>                 
        // )

        const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
        );
        
        const renderEmergencyMaintenanceData = emergencyMaintenance && emergencyMaintenance.map((item, index) => {
            return (

            <tr key={index} >
                <th scope="row" className="bordersSpace" style={{ padding: 1 }} >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.staffName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.floorName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.roomName ? item.roomName : '-'}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.categoryName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.description}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.date}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.status}
                </td>
                {/* <td className="borderSpace" style={{ padding: 1 }}>
                <a href="#" data-toggle="tooltip" title="Edit!">
                            
                            <button
								id="firstbutton"
                                onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</a>
                </td> */}
                
            </tr>
            
            );
        }) 

        return (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">
                        
                    <div class="col-md-8" >
                        <h2>Emergency Maintenance Notifications</h2>
                    </div>
                    <div class="col-md-2" >
                        
                    </div>
                    <div class="col-md-2 align-right">
                                <select class="form-control mr-sm-2" 
                                    type="search" 
                                    name="typeWise" 
                                    onChange={this.handleFilter}
                                    aria-label="typeWise" >

                                        <option value="">Please Select Type</option>
                                        <option value="FloorWise">Floor Wise</option>
                                        <option value="RoomWise">Room Wise</option>

                                    </select>
                                
                    </div>
                    </div>
                    
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Staff Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Role
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Floor Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Room Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Category Name
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Description
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Date
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Status
								</th>
                                {/* <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th> */}
                                

                                
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderEmergencyMaintenanceData}</tbody>
					</table>
				</div>
                {/* {editEmergencyMaintenanceData} */}
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NotificationEmergencyMaintenance);
