import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';

class AcademicsDashboard extends Component {
	state = {};
	componentDidMount() {
		$(document).click(function(e) {
			if (!$(e.target).is('.panel-body')) {
				$('.collapse').collapse('hide');
			}
		});
	}
	render() {
		return (
			<div>
				<div class="container">
					<div class="modal" id="myModal">
						<div class="modal-dialog">
							<div class="modal-content">
								<div class="modal-header">
									<h4 class="modal-title">
										<i className="fas fa-info-crossbones animated fadeIn" />
									</h4>
									<button type="button" class="close" data-dismiss="modal">
										&times;
									</button>
								</div>

								<div class="modal-body">
									This Module is Under Implementation
								</div>

								<div class="modal-footer">
									<button
										type="button"
										class="btn"
										style={{ backgroundColor: '#01425e', color: 'white' }}
										data-dismiss="modal"
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section class="mb-4">
					<div class="h1-responsive font-weight-bold text-center my-4">
						<img
							src={require('../Assets/images/Academic-01.svg')}
							style={{ width: 300 }}
						/>
					</div>

					<div
						class="row"
						style={{ justifyContent: 'center', textAlign: 'center' }}
					>
						<div class="col-md-8">
							<div class="md-form mb-0">
								<hr class="style-head" />
							</div>
						</div>
					</div>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{ textAlign: 'center', justifyContent: 'center' }}
					>
						<div class="col-md-9 mb-md-0 mb-5">
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/AcademicLMS">
												<img
													src={require('../Assets/Academic/learning managment sysytem.svg')}
													style={{ width: 180, height: 190 }}
												/>
												{/* <h3>Learning Management System</h3> */}
											</Link>
										</div>
									</div>

									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/AcademicSID">
												<img
													src={require('../Assets/Academic/student info desk.svg')}
													width="180"
													height="190"
												/>
												{/* <h3 data-toggle="modal" data-target="#myModal">
												Student Information Desk
											</h3> */}
											</Link>
										</div>
									</div>

									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/AcademicCMS">
												<img
													src={require('../Assets/Academic/competency management system.svg')}
													width="180"
													height="190"
												/>
												{/* <h3>Competency Management System</h3> */}
											</Link>
										</div>
									</div>
									<div class="col-md-12 text-center">
										<div class="md-form mb-0">
											<Link to="/dashboard/AcademicDeanDashboard">
												<img
													src={require('../Assets/Academic/academic head.svg')}
													width="180"
													height="190"
												/>
												{/* <h3>Competency Management System</h3> */}
											</Link>
										</div>
									</div>
								</div>

								{/* <div class="col-md-4">
                    <div class="md-form mb-0">
                      <Link to="/dashboard/CensusDashboard">
                        <img
                          src={require("../Assets/AdministrationDashboard/humza icon new-05.svg")}
                          width="150"
                          height="160"
                        />
                      </Link>
                    </div>
                  </div>


                  <div class="col-md-4">
                    <div class="md-form mb-0">
                      <Link to="/dashboard/StudentAdmissionDashboard">
                        <img
                          src={require("../Assets/AdministrationDashboard/humza icon new-06.svg")}
                          width="150"
                          height="160"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="md-form mb-0">
                      <Link to="/dashboard/AdministrationManagers">
                        <img
                          src={require("../Assets/AdministrationDashboard/managers-07.png")}
                          width="150"
                          height="160"
                        />
                      </Link>
                    </div>
                  </div> */}
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default AcademicsDashboard;
