import * as actionTypes from '../actionTypes';

export function setAllPastPapersInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_PASTPAPERS,
		payload: allData
	};
}

export function getClassesForPastPapers(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_PASTPAPERS_SELECT,
		payload: allData
	};
}
export function getYearForPastPapers(allData) {
	return {
		type: actionTypes.SET_ALL_YEAR_NAME_INTO_PASTPAPERS_SELECT,
		payload: allData
	};
}
export function getSessionForPastPapers(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_PASTPAPERS_SELECT,
		payload: allData
	};
}
export function addPastPapers(Data) {
	return {
		type: actionTypes.ADD_PASTPAPERS,
		payload: Data
	};
}
export function updatePastPapersFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_PASTPAPERS,
		payload: setFlag
	};
}
export function deletePastPapers(id) {
	return {
		type: actionTypes.DELETE_PASTPAPERS,
		payload: id
	};
}
