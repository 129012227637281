import React from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import SnackBar from '../../../components/SnackBar/SnackBar';
import Spinner from '../../../components/Spinner/Spinner';
import Colors from '../../../utils/app_constants/colors_constants';

const tHead = [
    'Doctor Name',
    'Date',
    'Account ID',
    'Account Type',
    'Actions'
]

class ViewDoctor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doctorData: [],
            isLoading: true,
            errorMsg: false,
            id: ''
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/Doctor/`
            )
            .then(success => {
                this.setState({
                    doctorData: success,
                    isLoading: false
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: '',
                    errorMsg: false,
                    snackColor: ''
                });
            }, 3000);
        }
    }

    setDeleteID = (id) => {
        this.setState({
            id: id
        })
    }

    onTakeItemDelete = itemId => {
        const { doctorData } = this.state;

        this.setState({
            isLoading: true
        })

        this.props.sharedActions
            .deleteRecordWithoutDispatch(
                '/api/Doctor/',
                itemId,
            )
            .then(success => {
                let getNewFilterArray = doctorData.filter(item => item.id !== itemId);
                this.setState({
                    msg: 'Delete Record Successfully!',
                    doctorData: getNewFilterArray,
                    isLoading: false
                });
                this.props.snackbar();
            })
            .catch(error => { });
    };

    render() {
        const { errors, isLoading, doctorData, id } = this.state;

        const renderTodos = doctorData.map((item, index) => {
            return (
                <tr className="bordersSpace" key={index}>
                    <th className="bordersSpace" scope="row">
                        {index + 1}
                    </th>
                    <td className="bordersSpace">{item.doctorName}</td>
                    <td className="bordersSpace">{item.date}</td>

                    <td className="bordersSpace">{item.accountId}</td>
                    <td className="bordersSpace">{item.accountType}</td>

                    <td className="bordersSpace">

                        <a href="#" data-toggle="tooltip" title="Delete!">
                            <button
                                onClick={() => this.setDeleteID(item.id)}
                                data-toggle="modal"
                                data-target="#myModal1"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    background: 'none',
                                    marginLeft: 5,
                                    marginBottom: 5
                                }}
                            >
                                <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                            </button>
                        </a>
                    </td>
                </tr>
            );
        });


        const MainContent = (
            <div>
                <div class="modal" id="myModal1">
                    <div class="modal-dialog">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Confirm
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <h4>Are you sure ?</h4>
                                    </div>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-evenly' }}
                                    >
                                        <div>
                                            <button
                                                disabled={this.state.enabled}
                                                data-dismiss="modal"
                                                onClick={() => this.onTakeItemDelete(id)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                Yes
											</button>
                                        </div>
                                        <div>
                                            <button
                                                data-dismiss="modal"
                                                disabled={this.state.enabled}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                No
											</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <table class="table table-hover">
                        <thead style={{ background: '#01AC8A', color: 'white' }}>
                            <tr>
                                <th scope="col">#</th>
                                {tHead.map(item => (
                                    <th
                                        style={{
                                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                            borderColor: Colors.WHITE
                                        }}
                                        scope="col"
                                    >
                                        {item}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>{renderTodos}</tbody>
                    </table>
                </div>
            </div>
        );

        return (
            <div>
                <div className="page-header">
                    <SnackBar msg={this.state.msg} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <h2>Doctor&nbsp;Details</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <br />

                {isLoading ? <Spinner /> : MainContent}
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewDoctor);