import React, { Component } from 'react';
import './Form.css';

class StudentTransport extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { studentTransport } = this.props;
		const { transportDriver } = this.props;
		const { routeTransport } = this.props;
		console.log(transportDriver, 'Driver ka Data');
		console.log(studentTransport, 'Transport ka Data');
		console.log(routeTransport, 'Route ka Data');
		return (
			<div>
				<section class="mb-4">
					<div class="row print">
						<div class="col-md-12">
							<div class="md-form mb-0">
								<h2
									style={{
										backgroundColor: '#0A4F5E',
										textAlign: 'center',
										color: 'white'
									}}
								>
									Student Transport:
								</h2>
							</div>
						</div>
					</div>
					<div class="container">
						<div class="row rowCheck" style={{ marginTop: 10 }}>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Transport Status: </label>
								&nbsp;&nbsp;
								{studentTransport && studentTransport.transportStatus}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Driver Name:</label>{' '}
								&nbsp;&nbsp;{transportDriver && transportDriver.driverName}{' '}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Route Name: </label>
								&nbsp;&nbsp;{routeTransport && routeTransport.routeName}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Vehicle No: </label>
								&nbsp;&nbsp;{studentTransport && studentTransport.vehicleNo}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Driver License: </label>
								&nbsp;&nbsp;{studentTransport && studentTransport.driverLicense}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Driver Mobile No:</label>
								&nbsp;&nbsp;{' '}
								{studentTransport && studentTransport.driverMobileNo}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Driver Id Card No:</label>
								&nbsp;&nbsp;
								{studentTransport && studentTransport.driverIdCardNo}{' '}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>
									Driver Id Card Copy:
								</label>
								&nbsp;&nbsp;{' '}
								{studentTransport && studentTransport.driverIdCardCopy}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Transport Type: </label>
								&nbsp;&nbsp;{studentTransport && studentTransport.transportType}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>
									Parent or By Walk:{' '}
								</label>
								&nbsp;&nbsp;
								{studentTransport && studentTransport.parentOrByWalk}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Other Driver Name:</label>
								&nbsp;&nbsp;{' '}
								{studentTransport && studentTransport.otherDriverName}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>
									Other Driver Id Card No:
								</label>
								&nbsp;&nbsp;{' '}
								{studentTransport && studentTransport.otherDriverCardIdNo}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>
									Other Driver Mobile No:
								</label>
								&nbsp;&nbsp;
								{studentTransport && studentTransport.otherDriverMobileNo}{' '}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Other Car No: </label>
								&nbsp;&nbsp;{studentTransport && studentTransport.otherCarNo}
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default StudentTransport;
