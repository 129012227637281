import React from 'react';
import img1 from '../../Assets/images/toplog.jpg';
import CustomBadge from '../CustomBadge/CustomBadge';

export default function ReportWrapper(props) {
	const {
		headData = {},

		targetedObj = 'teacherinfo',
		headRenderData = [],
		footerData = {},
		PrintContent = () => alert('function is missing'),
		headingName = 'Default Heading'
	} = props;

	const renderItems = (data, item) => {
		if (
			data &&
			data[targetedObj] &&
			data[targetedObj] &&
			data[targetedObj][item]
		) {
			return <span>{data[targetedObj][item]}</span>;
		}
	};

	return (
		<div>
			<div id="printDiv">
				<img src={img1} alt="#img" style={{ width: '100%', height: '30%' }} />
				<div>
					<CustomBadge
						bottom={-8}
						fontSize={28}
						badgeText={headingName}
						badgeIconClass={'fas fa-chart-bar'}
					/>
				</div>
				<div
					style={{
						fontSize: 24,
						border: '3px solid #01425E',
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<div className="reportText">
						Student: &nbsp;
						{headData && headData['info'] && headData['info']['studentName']}
					</div>
					<div className="reportText">
						Class Teacher: &nbsp;
						{headData && headData['info'] && headData['info']['staffName']}
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
						border: '3px solid #01425E',
						padding: 8
					}}
				>
					{headRenderData.map((item, index) => (
						<div key={index} className="reportText">
							{`${item.displayData}`} : {renderItems(headData, item.fieldName)}
						</div>
					))}
				</div>
				{props.children}
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<div
						id="gradeBox"
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '22%',
							height: 'fit-content'
						}}
					>
						<div>
							<table className="table table-bordered">
								<tr>
									<td style={{ fontWeight: 'bolder', padding: 2 }}>
										4 - Excellent
									</td>
								</tr>
								<tr>
									<td
										style={{
											fontWeight: 'bolder',
											padding: 2,
											textAlign: 'right'
										}}
									>
										<div
											style={{ display: 'flex', justifyContent: 'flex-end' }}
										>
											<div>ممتاز </div>
											<div> - 4</div>
										</div>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: 'bolder', padding: 2 }}>
										3 - Very Good
									</td>
								</tr>
								<tr>
									<td
										style={{
											fontWeight: 'bolder',
											padding: 2,
											textAlign: 'right'
										}}
									>
										<div
											style={{ display: 'flex', justifyContent: 'flex-end' }}
										>
											<div>حسن جدا </div>
											<div> - 3</div>
										</div>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: 'bolder', padding: 2 }}>2 - Good</td>
								</tr>
								<tr>
									<td
										style={{
											fontWeight: 'bolder',
											padding: 2,
											textAlign: 'right'
										}}
									>
										<div
											style={{ display: 'flex', justifyContent: 'flex-end' }}
										>
											<div>جيد</div>
											<div> - 2</div>
										</div>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: 'bolder', padding: 2 }}>1 - Poor</td>
								</tr>
								<tr>
									<td
										style={{
											fontWeight: 'bolder',
											padding: 2,
											textAlign: 'right'
										}}
									>
										<div
											style={{ display: 'flex', justifyContent: 'flex-end' }}
										>
											<div>فقير</div>
											<div> - 1</div>
										</div>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: 'bolder', padding: 2 }}>
										0 - Unstatisfactory
									</td>
								</tr>
								<tr>
									<td
										style={{
											fontWeight: 'bolder',
											padding: 2,
											textAlign: 'right'
										}}
									>
										<div
											style={{ display: 'flex', justifyContent: 'flex-end' }}
										>
											<div>غير مرض</div>
											<div> - 0</div>
										</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>

			<div
				style={{ marginTop: 60 }}
				class="displayElemet text-center text-md-right"
			>
				<label for="name" class="" onClick={() => PrintContent('printDiv')}>
					<i
						className="fas fa-print fa-2x animated fadeIn"
						style={{ marginRight: 4 }}
					/>
					Print It Out
				</label>
			</div>
		</div>
	);
}
