import React, { Component } from 'react';
import ValidationText from '../../utils/app_constants/validate_messages_constants';

const SelectionInput = props => {
	const {
		newFlag,

		isIcon = true,
		flag = false,
		isFeildDisabled = false,
		borderColor,
		newError = {},
		extraData = [],
		editClassOnChange,
		edit = false,
		selectFont = 17,
		marginTop = 0,
		feildName,
		selectName,
		onHandleChange,
		dispatchFlag,
		dispatch,
		errors = {},
		selectedText = 'Default Text',
		stateData = {},
		iconClassName,
		optionType = 'static',
		optionsArrys = [],
		property = '',
		propertyId = '',
		successflag,
		editUse,
		useFlag,
		dynamicIndex,
		lastName,
		StaffFirstName,
		isValidte = true,
		handleFlag = false,
		design = false,
		selectFontWeight = 'normal',
		dynamicDispatchFlag = false,
		day = 'mon'
	} = props;

	function conditionalOptions(optionsArrys, optionType, property) {
		if (optionsArrys && optionsArrys.length > 0) {
			let options = [...optionsArrys];
			switch (optionType) {
				case 'static':
					return (
						<React.Fragment>
							{options.map((item, index) => (
								<option key={index} value={item}>
									{item}
								</option>
							))}
						</React.Fragment>
					);
				case 'dynamic':
					return (
						<React.Fragment>
							{options.map((item, index) => (
								<option key={index} value={item[propertyId]}>
									{item[property]}
								</option>
							))}
						</React.Fragment>
					);
				case 'dynamicWithPropIdAndName':
					return (
						<React.Fragment>
							{options.map((item, index) => (
								<option key={index} value={item[propertyId]}>
									{item[property]}
								</option>
							))}
						</React.Fragment>
					);
				default:
					break;
			}
		}
	}
	return (
		<div
			className={
				(!useFlag ? 'col-md-6' : '',
				newFlag == 'customWidth' ? 'col-md-12' : 'col-md-6')
			}
			style={{ marginTop: marginTop }}
		>
			<div
				style={{ display: flag ? 'flex' : '' }}
				class={!useFlag ? 'md-form mb-0' : ''}
			>
				<label for={feildName}>
					<i
						style={{ marginRight: 4, display: isIcon ? '' : 'none' }}
						className={iconClassName}
					/>
					<p
						style={{
							margin: 0,
							fontSize: selectFont,
							color: '#000',
							fontWeight: selectFontWeight
						}}
					>
						{selectName}
					</p>
				</label>
				<select
					id={`${feildName}_${day}_${dynamicIndex}_${
						stateData.metaExist ? 'pre' : 'post'
					}`}
					onChange={
						edit
							? e => editClassOnChange(e)
							: dispatchFlag
							? e =>
									dispatch({
										type: 'onChange',
										payload: {
											name: feildName,
											value: e.target.value,
											extraData
										}
									})
							: handleFlag
							? e => onHandleChange(e, stateData.current)
							: dynamicDispatchFlag
							? e =>
									dispatch({
										type: 'ON_CLASS_CHANGE',
										payload: {
											value: e.target.value,
											allData: extraData
										}
									})
							: e => onHandleChange(e)
					}
					className={`form-control ${
						(errors[feildName] && stateData[feildName] === '') ||
						(errors[feildName] && stateData[feildName] === selectedText)
							? 'is-invalid'
							: stateData[feildName] !== '' &&
							  stateData[feildName] !== selectedText &&
							  isValidte
							? 'is-valid'
							: isValidte
							? ''
							: ''
					}`}
					name={feildName}
					value={editUse ? stateData[feildName] : stateData[feildName]}
					disabled={
						optionsArrys && optionsArrys.length < 1
							? true
							: false || isFeildDisabled
					}
					style={{
						borderBottomColor: borderColor,
						width: useFlag ? 'auto' : '',
						borderRight: design ? 0 : '',
						borderLeft: design ? 0 : '',
						borderTop: design ? 0 : '',
						boxShadow: design ? 'none' : ''
					}}
				>
					<option selected={stateData[successflag]}>{selectedText}</option>
					{conditionalOptions(optionsArrys, optionType, property)}
				</select>
				{Object.keys(newError).length > 0 && newError[feildName] ? (
					<p style={{ color: 'red', fontSize: 15 }}>
						{ValidationText.FIELD_REQUIRED}
					</p>
				) : (
					''
				)}
			</div>
		</div>
	);
};
export default SelectionInput;
