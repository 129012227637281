import React, { Component } from 'react';
import '../Admin/Add_Admin_Component/AddGm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RenderEditModules from './RenderEditModules';
import * as addActions from '../../Actions/Admission';
import * as sharedActions from '../../Actions/sharedActions';
import RenderAddFormData from '../../Container/Admission/RenderAddFormData';
import RenderArrayData from '../../Container/Admission/RenderArrayData';
const AddressIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const studentFormIcons = [
	'fas fa-registered animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-male animated fadeIn',
	'fas fa-user animated fadeIn',
	'fas fa-list-ol animated fadeIn',
	'fas fa-book-reader animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-bars animated fadeIn',
	'fas fa-language animated fadeIn',
	'fas fa-restroom animated fadeIn',
	'fas fa-birthday-cake animated fadeIn',
	'fas fa-sort-numeric-down animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-pray animated fadeIn',
	'fas fa-building animated fadeIn',
	'fas fa-bars animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-key animated fadeIn',
	'fas fa-poll animated fadeIn',
	'fas fa-signal animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const parentInfoIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const studentDocumentIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const parentDocIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const medConIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const widthDrawIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const otherInfosIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const studentTransIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const siblingIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];
const previousSchoolIcons = [
	'fas fa-building animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn',
	'fas fa-user-shield animated fadeIn'
];

class EditAddRecord extends Component {
	state = {
		getSingleAddData: [],
		imagePreviewUrl: null,
		isLoader: true
	};
	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};
	conversionOfImageUrl = url => {
		let getSplitResult = url.split('\\');
		let result = '';
		for (let i = 4; i < getSplitResult.length; i++) {
			if (getSplitResult.length - 1 == i) result += getSplitResult[i];
			else result += getSplitResult[i].concat('\\\\');
		}
		return result;
	};
	componentDidMount() {
		const { match } = this.props;
		Promise.all([
			this.props.shareAction.gAllData(
				`/api/StudentAdmissionForm/${match.params.id}`,
				this.props.addAction.getIndividualStudentData
			)
		])
			.then(success => {
				debugger;
				this.setState({
					isLoader: false
				});
				console.log(success);
			})
			.catch(error => {
				debugger;

				this.setState({
					isLoader: false
				});
			});
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.getSingleAddData !== nextProps.getData) {
			return {
				getSingleAddData: nextProps.getData
			};
		}
		return null;
	}
	render() {
		const { AddData, match, stringConversion, btnTechHover } = this.props;
		const { getSingleAddData } = this.state; // edit add record
		let medical = [getSingleAddData && getSingleAddData.MedicalConditions];
		let student = [getSingleAddData && getSingleAddData.StudentAdmissionForms];
		let parentInfo = [getSingleAddData && getSingleAddData.ParentInfos];
		let otherInfo = [getSingleAddData && getSingleAddData.OtherInformations];
		let parentDocs = [getSingleAddData && getSingleAddData.ParentDocs];
		let prevSchoolDetails = [
			getSingleAddData && getSingleAddData.PreviousSchoolDetails
		];
		let studentAddresss = [
			getSingleAddData && getSingleAddData.StudentAddresss
		];
		//  let studentADDRESS = {
		//      Address : studentAddresss[0].address,
		//      ['House No'] : studentAddresss[0].houseNo,
		//      ['Student Id Card No'] : studentAddresss[0].studentIdCardNo,
		//      ['Student Email Id'] :studentAddresss[0].studentEmailId,
		//      ['Way No'] : studentAddresss[0].wayNo,
		//      ['Street No'] : studentAddresss[0].streetNo,
		//      ['Area'] : studentAddresss[0].area,
		//      City:studentAddresss[0].city,
		//      Country:studentAddresss[0].country

		//  }
		let studentDocs = [getSingleAddData && getSingleAddData.StudentDocs];
		let studentImageUpload = [
			getSingleAddData && getSingleAddData.StudentImageUpload
		];
		let studentSiblingDetails = [
			getSingleAddData && getSingleAddData.StudentSiblingDetails
		];
		let studentTransports = [
			getSingleAddData && getSingleAddData.StudentTransports
		];
		let widthdrawDetails = [
			getSingleAddData && getSingleAddData.WidthdrawDetails
		];
		let getAllData = [
			...student,
			...studentAddresss,
			...parentInfo,
			...studentDocs,
			...parentDocs,
			...medical,
			[...prevSchoolDetails],
			...widthdrawDetails,
			...otherInfo,
			...studentTransports
		];
		console.log(getAllData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		const Content = (
			<div>
				<div id="div1">
					{/* <section class="mb-4" style={{ marginTop: -50 }}> */}
					<div
						style={{
							justifyContent: 'center',
							textAlign: 'center',
							height: 180
						}}
					>
						<div>
							<div className="page-header">
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between'
									}}
								>
									<div style={{ marginTop: 20 }}>
										<h2 className="generalHead">Edit Details</h2>
										<br />
									</div>
								</div>
							</div>
							<div className="table-responsive">
								<table class="table table-hover">
									<thead style={{ background: '#01AC8A', color: 'white' }}>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Form Sections</th>

											<th scope="col">Actions</th>
										</tr>
									</thead>
									<tbody>
										<RenderEditModules
											{...this.props}
											student={student}
											studentAddresss={studentAddresss}
											parentInfo={parentInfo}
											studentDocs={studentDocs}
											studentSiblingDetails={studentSiblingDetails}
											medical={medical}
											prevSchoolDetails={prevSchoolDetails}
											widthdrawDetails={widthdrawDetails}
											otherInfo={otherInfo}
											studentTransports={studentTransports}
											parentDocs={parentDocs}
											studentImageUpload={studentImageUpload}
											btnTechHover={btnTechHover}
											stringConversion={stringConversion}
											moduleData={getSingleAddData}
											studentImage={this.state.imagePreviewUrl}
										/>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{/* </section> */}
				</div>
			</div>
		);
		console.log(this.state.imagePreviewUrl);

		return <div>{this.state.isLoader ? Loader : Content}</div>;
	}
}
function mapDispatchToProps(dispatch) {
	return {
		addAction: bindActionCreators(addActions, dispatch),
		shareAction: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		getData: state.setAdmissionReducer.getSingleFormData
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditAddRecord);
