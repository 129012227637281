import React, { useEffect, useRef } from 'react';
import Stepper from 'bs-stepper';
import ParentDocsUpload from '../ParentDocsUpload/ParentDocsUpload';
import StudentDocsUpload from '../StudentDocsUpload/StudentDocsUpload';
import StudentImageUploadSection from '../StudentImageUpload/StudentImageUploadSection';
export default function StudentDocumentSection(props) {
	const { id } = props;
	const stepper = useRef();
	useEffect(() => {
		stepper.current = new Stepper(document.querySelector('#stepper3'), {
			linear: false,
			animation: true
		});
	}, []);

	const onSkipClick = e => {
		// e.preventDefault();
		stepper.current.next();
	};
	const onSkipDocuments = e => {
		e.preventDefault();
		onSkipClick(e);
	};
	return (
		<div id="stepper3" class="bs-stepper">
			<div style={{ width: '70%', margin: '0 auto' }}>
				<div class="bs-stepper-header" role="tablist">
					<div class="step" data-target="#logins-part">
						<button
							type="button"
							class="step-trigger"
							role="tab"
							aria-controls="logins-part"
							// disabled={true}
							id="logins-part-trigger"
						>
							<span
								style={{ backgroundColor: '#01AC8A' }}
								class="bs-stepper-circle"
							>
								1
							</span>
							<span class="bs-stepper-label">Image Upload</span>
						</button>
					</div>
					<div class="line"></div>
					<div class="step" data-target="#information-part">
						<button
							// disabled={true}
							type="button"
							class="step-trigger"
							role="tab"
							aria-controls="information-part"
							id="information-part-trigger"
						>
							<span
								style={{ backgroundColor: '#01AC8A' }}
								class="bs-stepper-circle"
							>
								2
							</span>
							<span class="bs-stepper-label">Student Document Upload</span>
						</button>
					</div>
					<div class="line"></div>
					<div class="step" data-target="#parent-part">
						<button
							// disabled={true}
							type="button"
							class="step-trigger"
							role="tab"
							aria-controls="parent-part"
							id="parent-part-trigger"
						>
							<span
								style={{ backgroundColor: '#01AC8A' }}
								class="bs-stepper-circle"
							>
								3
							</span>
							<span class="bs-stepper-label">Parent Document Upload</span>
						</button>
					</div>
				</div>
			</div>
			<div class="bs-stepper-content">
				<div
					id="logins-part"
					class="content"
					role="tabpanel"
					aria-labelledby="logins-part-trigger"
				>
					<StudentImageUploadSection
						{...props}
						id={id}
						onSkipClick={e => onSkipClick(e)}
					/>
				</div>
				<div
					id="information-part"
					class="content"
					role="tabpanel"
					aria-labelledby="information-part-trigger"
				>
					<StudentDocsUpload
						{...props}
						onSkipDocuments={onSkipDocuments}
						id={id}
					/>
				</div>
				<div
					id="parent-part"
					class="content"
					role="tabpanel"
					aria-labelledby="parent-part-trigger"
				>
					<ParentDocsUpload
						{...props}
						onSkipDocuments={onSkipDocuments}
						id={id}
					/>
				</div>
			</div>
		</div>
	);
}
