import React, { Component } from 'react';

class HODPhysics extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return <div class="text-center">HOD Physics</div>;
	}
}

export default HODPhysics;
