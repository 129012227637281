import React from 'react';
import Colors from '../../../utils/app_constants/colors_constants';
import CustomBadge from '../../CustomBadge/CustomBadge';

export default function GraphGeneralLayout2({
	children,
	titleName,
	titleIcon,
	gradingScale = '0 - 4',
	graphList = false,
	onGraphChange
}) {
	return (
		<div
			className="lineGraph"
			style={{
				border: `3px solid ${Colors.DARK_SEA_GREEN_THEME}`
			}}
		>
			<CustomBadge
				fontSize={25}
				badgeText={titleName}
				badgeIconClass={titleIcon}
			/>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div
					style={{
						textAlign: 'right',
						color: `${Colors.SEA_GREEN_THEME}`,
						fontWeight: 'bold',
						marginLeft: 5,
						fontSize: 20
					}}
				>
					Grading Scale: {gradingScale}
				</div>
				{graphList ? (
					<div class="col-md-4">
						<div class="md-form mb-0">
							<select
								class="custom-select"
								name="post"
								style={{ backgroundColor: '#01425e', color: 'white' }}
								onChange={e => onGraphChange(e)}
							>
								<option selected={true}>Select Graph..</option>
								<option value="Line">Line</option>
								<option value="Pie">Pie</option>
								<option value="Bar">Bar</option>
							</select>
						</div>
					</div>
				) : (
					''
				)}
			</div>
			{children()}
		</div>
	);
}
