import React, { Component } from 'react';
import * as ParentActions from '../../Actions/Parents';
import * as SharedActions from '../../Actions/sharedActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import Snackbar from '../../components/SnackBar/SnackBar';

class FeesCollection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			FeeAfterSelect: [
				{
					id: '',
					amount: '',
					type: ''
				}
			],
			DuesInfo: [],
			newDuesInfo: [],
			mergeArray: [],
			modalData: [],
			parentData: [],
			feeType: [],
			afterRender: [],
			balance: 0,
			showAmount: false,
			classFee: '',
			paymentType: '',
			status: '',
			classId: '',
			sectionId: '',
			studentId: '',
			studentName: '',
			feeTypeAmount: 0,
			amountPaid: '',
			cnic: '',
			password: '',
			msg: '',
			errorMsg: '',
			errorText: '',
			enableDropdown: false,
			deleteFlag: false,
			updateFlag: false,
			formLoading: true,
			addFlag: false,
			btnLoad: false,
			backColor: '',

			id: '',
			paymentTypeUpdate: '',
			balanceUpdate: 0,
			status: '',
			amountPaidUpdate: '',
			tutionFee: 0,
			selectedOption: 'Monthly',
			lengthCheck: false,
			isChecked: false,
			registration: 'Registration',
			registrationFee: '',
			admission: 'Admission',
			admissionFee: '',
			canteen: 'Canteen',
			canteenFee: '',
			stationary: 'Stationary',
			stationaryFee: '',
			entryTest: 'Entry Test',
			entryTestFee: '',
			bus: 'Bus',
			busFee: '',
			security: 'Security',
			securityFee: '',
			uniform: 'Uniform',
			uniformFee: '',
			late: 'Late',
			lateFees: '',
			monthly: '',
			monthlyFee: '',
			quaterly: '',
			quaterlyFee: '',
			halfyearly: '',
			halfyearlyFee: '',
			annually: '',
			annuallyFee: '',
			month: '',
			year: '',
			totalAmount: 0,

			registrationFee2: '',

			admissionFee2: '',

			canteenFee2: '',

			stationaryFee2: '',

			entryTestFee2: '',

			busFee2: '',

			securityFee2: '',

			uniformFee2: '',

			lateFees2: '',

			monthlyFee2: '',

			quaterlyFee2: '',

			halfyearlyFee2: '',

			annuallyFee2: ''
		};
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		// this.HitFeeTypeApi();
		// this.props.SharedActions.getDataWithoutDispatch(`/api/Student`)
		// 	.then(success => {
		// 		this.setState({
		// 			studentFilterData: success
		// 		});
		// 		console.log(success);
		// 	})
		// 	.catch(error => {
		// 		console.log(error);
		// 	});
	}

	onClassChange = e => {
		console.log(e.target.value);
		const Sections = this.state.studentFilterData.sections;
		let filtering = Sections.filter(item => item.classId == e.target.value);
		console.log(filtering);

		this.setState({ classId: e.target.value, sections: filtering });
	};

	onSectionChange = e => {
		console.log(e.target.value);
		const Subjects = this.state.studentFilterData.student;
		let studentFiltering = Subjects.filter(
			item =>
				item.classId == this.state.classId && item.sectionId == e.target.value
		);
		this.setState({ sectionId: e.target.value, students: studentFiltering });
	};

	onSearchParent = e => {
		e.preventDefault();
		this.setState({
			btnLoad: true
		});
		this.props.SharedActions.getDataWithoutDispatch(
			`/api/ListOfChildrenFees/?cnic=${this.state.cnic}`
		)
			.then(success => {
				debugger;
				this.setState({
					btnLoad: false,
					parentData: success.BindStudentFeeInformations,
					// name: success.name,
					// password: success.password,
					// userName: success.userName,
					enableDropdown: true
				});
				console.log(success);
				// this.HitFeeTypeApi();
			})
			.catch(error => {
				this.setState({
					btnLoad: false,
					errorText: error.response.data.Message,
					badError: true,
					formLoading: true
				});
				console.log(error);
			});
	};

	HitFeeTypeApi = e => {
		debugger;
		this.props.SharedActions.getDataWithoutDispatch(
			`/api/FeeType/?classId=${this.state.classId}`
		)
			.then(success => {
				debugger;
				this.setState({
					formLoading: false,
					feeType: success,
					registrationFee2: success.RegistrationFee,
					admissionFee2: success.admissionFee,
					entryTestFee2: success.entryTestFee,
					busFee2: success.busFee,
					stationaryFee2: success.stationaryFee,
					securityFee2: success.securityFee,
					canteenFee2: success.canteenFee,
					uniformFee2: success.uniformFee,
					lateFees2: success.lateFees,
					monthlyFee2: success.monthlyFee,
					quaterlyFee2: success.quaterlyFee,
					halfyearlyFee2: success.halfyearlyFee,
					annuallyFee2: success.annuallyFee
				});
				console.log(success);
			})
			.catch(error => {
				debugger;
				this.setState({
					formLoading: true
				});
				console.log(error);
			});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const {
			studentId,
			classId,
			registration,
			registrationFee,
			admission,
			admissionFee,
			canteen,
			canteenFee,
			stationary,
			stationaryFee,
			entryTest,
			entryTestFee,
			bus,
			busFee,
			security,
			securityFee,
			uniform,
			uniformFee,
			late,
			lateFees,
			monthly,
			monthlyFee,
			quaterly,
			quaterlyFee,
			halfyearly,
			halfyearlyFee,
			annually,
			annuallyFee,
			year,
			month,
			sectionId,
			amountPaid,
			feeTypeAmount,
			paymentType,
			totalAmount
		} = this.state;
		if (Object.is(studentId, '') || Object.is(classId, '')) {
			this.setState({
				msg: 'Select Student First',
				backColor: 'red'
			});
			// this.props.snackbar();
		} else {
			var bodyParameters = {
				classId,
				registration,
				registrationFee,
				admission,
				admissionFee,
				canteen,
				canteenFee,
				stationary,
				stationaryFee,
				entryTest,
				entryTestFee,
				bus,
				busFee,
				security,
				securityFee,
				uniform,
				uniformFee,
				late,
				lateFees,
				monthly,
				monthlyFee,
				quaterly,
				quaterlyFee,
				halfyearly,
				halfyearlyFee,
				annually,
				annuallyFee,
				year,
				month,
				sectionId,
				totalAmount,
				balance: 0,
				studentId,
				paymentType,
				amountPaid: this.state.totalAmount,
				accountId: this.props.userData.unique_name,
				accountType: this.props.userData.role
			};
			this.props.SharedActions.simpleAddRequest(
				`/api/FeeCollection`,
				bodyParameters
			)
				.then(success => {
					debugger;
					this.setState({
						userName: '',
						classId: '',
						sectionId: '',
						studentId: '',
						cnic: '',
						msg: 'Fees Added Successfully',
						backColor: '#01ac8a',
						formLoading: true
					});
					// this.props.snackbar();
				})
				.catch(error => {
					debugger;
					this.setState({
						msg: error.response.data.Message,
						backColor: 'red'
					});
					// this.props.snackbar();
					console.log(error);
				});
		}
		console.log(this.state);
	};
	HideUnHide = e => {
		$('.toggle-password').click(function() {
			$(this).toggleClass('fa-eye fa-eye-slash');
			var input = $($(this).attr('toggle'));
			if (input.attr('type') == 'password') {
				input.attr('type', 'text');
			} else {
				input.attr('type', 'password');
			}
		});
	};

	// componentDidUpdate() {
	// 	if (
	// 		this.state.msg ||
	// 		this.state.errorMsg ||
	// 		this.state.badError ||
	// 		this.state.updateFlag ||
	// 		this.state.deleteFlag
	// 	) {
	// 		setTimeout(() => {
	// 			this.setState({
	// 				msg: false,
	// 				errorMsg: false,
	// 				badError: false,
	// 				updateFlag: false,
	// 				deleteFlag: false
	// 			});
	// 		}, 3000);
	// 	}
	// }

	componentDidUpdate(prevProps, prevState) {
		if (prevState.feeTypeAmount !== this.state.feeTypeAmount) {
			debugger;
			this.setState({
				totalAmount: +this.state.feeTypeAmount + +this.state.tutionFee
			});
		} else if (prevState.tutionFee !== this.state.tutionFee) {
			debugger;
			var dt = new Date();
			let monthNumber = new Date().getMonth();
			let monthNames = [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			];
			let monthName = monthNames[monthNumber];
			this.setState({
				year: dt.getFullYear(),
				month: monthName,
				totalAmount: +this.state.feeTypeAmount + +this.state.tutionFee
			});
		}
	}

	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onAddClick = (stdId, stdName) => {
		this.setState({
			addFlag: true,
			studentId: stdId,
			studentName: stdName
		});
	};

	onUpdate = e => {
		e.preventDefault();
		const {
			balanceUpdate,
			status,
			amountPaidUpdate,
			paymentTypeUpdate
		} = this.state;
		if (Object.is(amountPaidUpdate, '') || Object.is(paymentTypeUpdate, '')) {
			this.setState({
				msg: 'Select the Payment Type First',
				backColor: 'red'
			});
			// this.props.snackbar();
		} else {
			var bodyParameters = {
				balance: balanceUpdate,
				status: 'Paid',
				amountPaid: amountPaidUpdate,
				paymentType: paymentTypeUpdate
			};
			this.props.SharedActions.editPatchRecordWithoutDispatch(
				`/api/PayDues/?id=`,
				this.state.id,
				bodyParameters
			)
				.then(success => {
					var data = this.state.newDuesInfo.filter(
						item => item.id != this.state.id
					);
					debugger;
					this.setState({
						newDuesInfo: data,
						formLoading: false,
						msg: success.data,
						backColor: '#01ac8a'
					});
					// this.props.snackbar();
				})
				.catch(error => {
					debugger;
					this.setState({
						msg: error.response.data.Message,
						backColor: 'red',
						badError: true
					});
					// this.props.snackbar();
					console.log(error);
				});
		}
		console.log(this.state);
	};
	onStudentSelect = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		let filterDue = this.state.parentData.filter(
			item => item.studentId == result.studentId
		);
		var asn = filterDue[0].StudentFeeDues.length;
		if (asn == 0) {
			this.setState({
				lengthCheck: true
			});
		} else {
			this.setState({
				lengthCheck: false,
				DuesInfo: filterDue,
				newDuesInfo: filterDue[0].StudentFeeDues
			});
		}
		debugger;
		this.setState(
			{
				studentId: result.studentId,
				classId: result.classId,
				sectionId: result.sectionId
			},
			this.HitFeeTypeApi
		);
	};
	feeTypeSelect = (e, feeId) => {
		console.log(e.target.checked);
		console.log(e.target.name);
		debugger;
		if (e.target.checked == true) {
			this.setState({
				feeTypeAmount: +this.state.feeTypeAmount + +feeId,
				[e.target.name]: feeId
			});
		} else {
			this.setState({
				feeTypeAmount: +this.state.feeTypeAmount - +feeId,
				[e.target.name]: ''
			});
		}
		console.log(this.state);
		debugger;
	};

	updateSpecialArrObjOnChange = (e, index, arrName) => {
		const changeArrOfObj = this.state[`${arrName}`];
		changeArrOfObj[index][e.target.name] = e.target.value;
		this.setState({
			[arrName]: changeArrOfObj
		});
	};

	handleOptionChange = e => {
		console.log(e.target.checked);
		debugger;
		if (e.target.name == 'monthlyFee')
			this.setState({
				[e.target.name]: e.target.value,
				monthly: 'Monthly',
				quaterly: '',
				halfyearly: '',
				annually: '',
				halfyearlyFee: '',
				quaterlyFee: '',
				halfyearlyFee: '',
				annuallyFee: '',
				tutionFee: e.target.value
			});
		else if (e.target.name == 'quaterlyFee')
			this.setState({
				[e.target.name]: e.target.value,
				monthly: '',
				quaterly: 'Quater',
				halfyearly: '',
				annually: '',
				halfyearlyFee: '',
				monthlyFee: '',
				halfyearlyFee: '',
				annuallyFee: '',
				tutionFee: e.target.value
			});
		else if (e.target.name == 'halfyearlyFee')
			this.setState({
				[e.target.name]: e.target.value,
				monthly: '',
				quaterly: '',
				halfyearly: 'Half Yearly',
				annually: '',
				monthlyFee: '',
				quaterlyFee: '',
				annuallyFee: '',
				tutionFee: e.target.value
			});
		else if (e.target.name == 'annuallyFee')
			this.setState({
				[e.target.name]: e.target.value,
				monthly: '',
				quaterly: '',
				halfyearly: '',
				annually: 'Annually',
				monthlyFee: '',
				quaterlyFee: '',
				halfyearlyFee: '',
				tutionFee: e.target.value
			});
	};
	getDueInformation = dueId => {
		debugger;
		var getData = this.state.DuesInfo[0].StudentFeeDues.filter(
			item => item.id == dueId
		);
		this.setState({
			modalData: getData,
			id: getData[0].id,
			amountPaidUpdate: getData[0].balance,
			balanceUpdate: 0
		});
		console.log('Data After Filter', getData);
	};
	render() {
		const DataForFeeType = [
			{
				id: 1,
				name: 'admissionFee',
				value: this.state.admissionFee2,
				label: 'Admission'
			},
			{
				id: 2,
				name: 'registrationFee',
				value: this.state.registrationFee2,
				label: 'Registration'
			},
			{
				id: 3,
				name: 'canteenFee',
				value: this.state.canteenFee2,
				label: 'Canteen'
			},
			{
				id: 4,
				name: 'stationaryFee',
				value: this.state.stationaryFee2,
				label: 'Stationary'
			},
			{
				id: 5,
				name: 'entryTestFee',
				value: this.state.entryTestFee2,
				label: 'Entry Test'
			},
			{
				id: 7,
				name: 'busFee',
				value: this.state.busFee2,
				label: 'Bus'
			},
			{
				id: 8,
				name: 'securityFee',
				value: this.state.securityFee2,
				label: 'Security Deposit'
			},
			{
				id: 9,
				name: 'uniformFee',
				value: this.state.uniformFee2,
				label: 'Uniform'
			},
			{
				id: 10,
				name: 'lateFees',
				value: this.state.lateFees2,
				label: 'Late Fee'
			}
		];
		console.log('New Dues Info', this.state.newDuesInfo);
		console.log('Dues Information', this.state.DuesInfo);
		const { formLoading, parentData, DuesInfo, newDuesInfo } = this.state;

		const FormLoading = (
			<div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
				<label class="text-danger font-weight-bold">No Data </label>
			</div>
		);
		const DuesInformation = (
			<div>
				<div
					class="modal fade"
					id="exampleModal"
					tabindex="-1"
					role="dialog"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLabel">
									Remaining Balance
								</h5>
								<button
									type="button"
									class="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">
								<form id="button">
									<div class="row" style={{ justifyContent: 'center' }}>
										<div class="col-md-12">
											<div class="md-form mb-0">
												<label for="name" class="">
													Select Payment Type
												</label>
												<select
													onChange={this.onHandleTextChange}
													class="custom-select"
													name="paymentTypeUpdate"
												>
													<option>Payment Type..</option>
													<option value="Card">Card</option>
													<option value="Cash">Cash</option>
													<option value="Cheque">Cheque</option>
												</select>
											</div>
										</div>
									</div>
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									class="btn btn-secondary"
									data-dismiss="modal"
								>
									Close
								</button>
								<button
									type="button"
									data-dismiss="modal"
									onClick={this.onUpdate}
									class="btn btn-primary"
								>
									Pay
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card shadowForBox">
					<div class="card-body">
						<h4 class="card-title text-center" style={{ color: '#01ac8a' }}>
							Dues Information
						</h4>
						<hr />
						{/* <Snackbar msg={this.state.msg} backColor={this.state.backColor} /> */}
						{this.state.lengthCheck ? (
							<div class="text-center">
								<lable class="font-weight-bold text-primary">No Dues</lable>
							</div>
						) : (
							newDuesInfo.map((data, indexe) => (
								<React.Fragment>
									<hr />
									<div class="row">
										<div class="col-md-2">
											<lable class="font-weight-bold">Month:</lable>
											<label style={{ marginLeft: 5 }}>{data.month}</label>
										</div>
										<div class="col-md-2">
											<lable class="font-weight-bold">Year:</lable>
											<label style={{ marginLeft: 5 }}>{data.year}</label>
										</div>
										<div class="col-md-2">
											<lable class="font-weight-bold">Amount Paid:</lable>
											<label style={{ marginLeft: 5 }}>{data.amountPaid}</label>
										</div>
										<div class="col-md-2">
											<lable class="font-weight-bold">Total Amount:</lable>
											<label style={{ marginLeft: 5 }}>
												{data.totalAmount}
											</label>
										</div>
										<div class="col-md-2">
											<lable class="font-weight-bold">Balance:</lable>
											<label style={{ marginLeft: 5 }}>{data.balance}</label>
										</div>
										<div class="col-md-2">
											<lable class="font-weight-bold">Status:</lable>
											<label class="text-danger" style={{ marginLeft: 5 }}>
												{data.status}
											</label>
										</div>
										<div class="col-md-12">
											<div class="text-right">
												<button
													type="submit"
													class="buttonHoverFill"
													data-toggle="modal"
													data-target="#exampleModal"
													onClick={() => this.getDueInformation(data.id)}
												>
													Pay
												</button>
											</div>
										</div>
									</div>
								</React.Fragment>
							))
						)}
					</div>
				</div>
			</div>
		);
		const StudentListView = (
			<div>
				{DuesInformation}
				<br />
				<div class="frontside">
					<div class="card shadowForBox">
						<div class="card-body">
							<h4 class="card-title text-center">Add Fees</h4>
							{/* <Snackbar msg={this.state.msg} backColor={this.state.backColor} /> */}
							<p class="card-text text-center">
								Fees Collection of the Students
							</p>
							{/* <button
								href="#"
								class="btn btn-sm"
								style={{ backgroundColor: '#01425e', color: 'white' }}
							>
								<i class="fa fa-plus"></i>
							</button> */}
							<div class="row">
								<div class="col-md-6">
									<ul class="list-inline">
										<lable class="font-weight-bold">Fee Type:</lable>
										{DataForFeeType.map((item, index) => (
											<React.Fragment>
												<li class="">
													<input
														style={{ marginRight: 20 }}
														type="checkbox"
														onClick={e => this.feeTypeSelect(e, item.value)}
														checked={this.state.check}
														name={item.name}
													/>
													&nbsp;<a>{item.label}</a>
												</li>
											</React.Fragment>
										))}
									</ul>
								</div>
								<div class="col-md-6">
									<ul class="list-inline">
										<lable class="font-weight-bold">Amount:</lable>
										{DataForFeeType.map((item, index) => (
											<React.Fragment>
												<li class="">
													<a>{item.value}/.</a>
												</li>
											</React.Fragment>
										))}
									</ul>
								</div>
								<div class="col-md-6">
									<form>
										<label style={{ color: '#007b5e', fontWeight: 'bold' }}>
											<input
												type="radio"
												value={this.state.monthlyFee2}
												checked={this.state.tutionFee == this.state.monthlyFee2}
												onChange={this.handleOptionChange}
												name="monthlyFee"
											/>
											Monthly
										</label>

										<label
											style={{
												marginLeft: 8,
												color: '#007b5e',
												fontWeight: 'bold'
											}}
										>
											<input
												type="radio"
												value={this.state.quaterlyFee2}
												checked={
													this.state.tutionFee == this.state.quaterlyFee2
												}
												onChange={this.handleOptionChange}
												name="quaterlyFee"
											/>
											Quarterly
										</label>

										<label
											style={{
												marginLeft: 8,
												color: '#007b5e',
												fontWeight: 'bold'
											}}
										>
											<input
												type="radio"
												value={this.state.halfyearlyFee2}
												checked={
													this.state.tutionFee == this.state.halfyearlyFee2
												}
												onChange={this.handleOptionChange}
												name="halfyearlyFee"
											/>
											Half Yearly
										</label>

										<label
											style={{
												marginLeft: 8,
												color: '#007b5e',
												fontWeight: 'bold'
											}}
										>
											<input
												type="radio"
												value={this.state.annuallyFee2}
												checked={
													this.state.tutionFee == this.state.annuallyFee2
												}
												onChange={this.handleOptionChange}
												name="annuallyFee"
											/>
											Yearly
										</label>
									</form>
								</div>
								<div class="col-md-12 text-center">
									<lable class="font-weight-bold">Fee Type:</lable>
									<a>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.feeTypeAmount}</a>
								</div>
								<div class="col-md-12 text-center">
									<lable class="font-weight-bold">Tution Fee :</lable>
									<a>&nbsp;{this.state.tutionFee}</a>
								</div>
								<div class="col-md-12 text-center">
									<lable class="font-weight-bold">Total :</lable>
									<a>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
										{this.state.totalAmount}
									</a>
								</div>

								<div class="col-md-3">
									<div class="md-form mb-0">
										<select
											onChange={this.onHandleTextChange}
											class="custom-select"
											name="paymentType"
										>
											<option>Payment Type..</option>
											<option value="Card">Card</option>
											<option value="Cash">Cash</option>
											<option value="Cheque">Cheque</option>
										</select>
									</div>
								</div>
								<div class="col-md-12">
									<div class="text-right">
										<button
											type="submit"
											onClick={this.onHandleSubmit}
											class="buttonHoverFill"
										>
											Pay
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
		const FormReady = (
			<div style={{ marginTop: 0 }}>
				<div>
					<section class="mb-4">
						<p class="text-center w-responsive mx-auto mb-5"></p>

						<div
							class="row"
							style={{
								justifyContent: 'center',
								textAlign: 'center'
							}}
						>
							<div
								class="col-md-9 mb-md-0 mb-5"
								style={{
									border: 'solid',
									borderColor: '#01ac8a',
									padding: 40,
									borderRadius: 15,
									width: 'auto'
								}}
							>
								{this.Message(
									'Please fill the form properly!',
									'danger',
									20,
									this.state.errorMsg
								)}
								<form
									id="contact-form"
									name="contact-form"
									onSubmit={this.onHandleSubmit}
								>
									<div class="row">
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													Admin Name
												</label>
												<input
													readOnly={true}
													value={this.props.userData.unique_name}
													type="text"
													name="AdminName"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													Admin Type
												</label>
												<input
													readOnly={true}
													value={this.props.userData.role}
													type="text"
													name="AdminType"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													Status
												</label>
												<input
													readOnly={true}
													value={this.state.parentStatus}
													type="text"
													name="userName"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													Student Name
												</label>
												<input
													readOnly={true}
													value={this.state.studentName}
													type="text"
													name="name"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
									</div>
									<br />
									<div class="text-center text-md-center">
										<button
											style={{
												backgroundColor: '#01ac8a',
												color: 'white',
												width: 100
											}}
											type="submit"
											class="btn"
										>
											Add
										</button>
									</div>
								</form>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
		return (
			<React.Fragment>
				<h3 class="h1-responsive font-weight-bold text-center my-4">
					Fee Collection
				</h3>

				{this.state.badError ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						{this.state.errorText}
					</p>
				) : (
					''
				)}
				<div class="row text-center" style={{ justifyContent: 'center' }}>
					<div class="col-md-6" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<div class="input-group">
								<input
									type="text"
									class="form-control"
									placeholder="Enter parent CNIC # e.g : 00000-0000000-0"
									name="cnic"
									value={this.state.cnic}
									onChange={this.onHandleTextChange}
								/>
								<div class="input-group-append">
									<button
										class="btn"
										onClick={this.onSearchParent}
										style={{ backgroundColor: '#01ac8a' }}
										type="button"
									>
										<span
											style={{ marginBottom: 5 }}
											class={`${
												this.state.btnLoad
													? 'spinner-border spinner-border-sm'
													: 'fas fa-search'
											}`}
										></span>
									</button>
								</div>
							</div>
						</div>
					</div>
					{this.state.enableDropdown ? (
						<div class="col-md-3" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
                                <i
                                    style={{ marginRight: 4 }}
                                    className="fas fa-map animated fadeIn"
                                />
                                Class Name
                      </label> */}
								<select
									onChange={this.onStudentSelect}
									class="custom-select"
									name="className"
									style={{ backgroundColor: '#01425e', color: 'white' }}
								>
									<option selected={this.state.msg ? true : false}>
										Select Child
									</option>
									{parentData &&
										parentData.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.studentName}
											</option>
										))}
								</select>
							</div>
						</div>
					) : (
						''
					)}
				</div>
				<br />
				<br />
				{formLoading ? FormLoading : StudentListView}
				{this.state.addFlag ? FormReady : ''}
			</React.Fragment>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		SharedActions: bindActionCreators(SharedActions, dispatch),
		ParentActions: bindActionCreators(ParentActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FeesCollection);
