import React, { useState } from 'react';
import { Types } from '../../hooks/HookReducers/StaffReducer';
import RenderDynamicSiblingsRow from '../../Container/Admission/RenderDynamicSiblingsRow';
import RenderDynamicSiblingStaffRow from '../../Container/Admission/RenderDynamicSiblingStaffRow';
import Button from '../Button';

const Sibling = props => {
	const { ON_DYANMIC_ADD, ON_DYANMIC_DELETE } = Types;
	const {
		putLoader,
		dispatch,
		errors,
		onPreviousClick,
		getValidatedArray,
		edit = false,
		editData = {},
		onUpdate
	} = props;
	const [Siblings, setSiblings] = useState(
		edit
			? editData
			: [
					{
						siblingName: '',
						siblingRelation: '',
						siblingPassportNo: '',
						siblingIdcardno: '',
						siblingpassDOI: '',
						siblingpassDOE: '',
						siblingIdcardDOI: '',
						siblingIdcardDOE: '',
						siblingDob: ''
					}
			  ]
	);
	const onNextStep = e => {
		if (!getValidatedArray(e, Siblings, 'sibling')) {
			dispatch({
				type: Types.ON_SIBLING_DISPATCH,
				payload: { Siblings }
			});
		}
	};
	const updateSpecialArrObjOnChange = (e, index, arrName) => {
		const changeArrOfObj = [...Siblings];
		changeArrOfObj[index][e.target.name] = e.target.value;

		// arrName = changeArrOfObj;
		// console.log(arrName);
		setSiblings(changeArrOfObj);
	};
	const onClickAddItem = (arr, obj) => {
		let getStateArr = [...arr];
		getStateArr = [...getStateArr, obj];
		console.log(getStateArr);

		setSiblings(getStateArr);
	};
	const onDateChange = (date, feildName, rowIndex) => {
		const changeArrOfObj = [...Siblings];
		changeArrOfObj[rowIndex][date.target.name] = date.target.value;

		setSiblings(changeArrOfObj);
	};
	const onClickDeleteItem = arr => {
		let getStateArr = [...arr];
		let getDeleteLength = getStateArr.length - 1;

		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			setSiblings(getNewDetails);
		}
	};
	return (
		<div>
			{errors.sibling ? (
				<p style={{ color: '#ff0000' }}>Please fill the form properly!</p>
			) : (
				''
			)}
			<div style={{ width: '100%' }}>
				<table class="table table-hover">
					<thead style={{ background: '#01AC8A', color: 'white' }}>
						<tr>
							<th scope="col">#</th>
							<th scope="col"> Name</th>
							<th scope="col"> Relation</th>
							<th scope="col"> Passport No</th>
							<th> Id card no</th>
							<th>Passport Issue Date</th>

							<th>Passport Expire Date</th>

							<th>ID Issue Date</th>

							<th>ID Expire Date</th>

							<th>Date Of Birth</th>
						</tr>
					</thead>
					<tbody>
						{Siblings.map((item, index) => (
							<tr key={index}>
								<th scope={'row'}>{index + 1}</th>
								<RenderDynamicSiblingStaffRow
									arrName={'Siblings'}
									rowIndex={index}
									stateData={{ Siblings: [...Siblings] }}
									dispatch={dispatch}
									onDateChange={onDateChange}
									updateSpecialArrObjOnChange={updateSpecialArrObjOnChange}
									hookReuse={false}
									itemObj={item}
								/>
							</tr>
						))}
					</tbody>
					<div className={'row'} style={{ justifyContent: 'flex-end' }}>
						<div>
							<div>
								<button
									onClick={() =>
										onClickAddItem(Siblings, {
											siblingName: '',
											siblingRelation: '',
											siblingPassportNo: '',
											siblingIdcardno: '',
											siblingpassDOI: '',
											siblingpassDOE: '',
											siblingIdcardDOI: '',
											siblingIdcardDOE: '',
											siblingDob: ''
										})
									}
									style={{
										background: '#0A4F5E',
										color: '#FFFFFF',
										borderRadius: 100
									}}
									type="button"
									class="btn"
								>
									<i class="fas fa-plus"></i>
								</button>
							</div>
							<div>
								<button
									onClick={() => onClickDeleteItem(Siblings)}
									style={{ color: '#FFFFFF', borderRadius: 100 }}
									type="button"
									class="btn btn-danger"
								>
									<i class="far fa-trash-alt"></i>
								</button>
							</div>
						</div>
					</div>
				</table>
			</div>
			<br />
			<br />
			<br />
			{/*<div class="text-center text-md-center">*/}
			{/*<button class="btns">Add</button>*/}
			{/*</div>*/}
			{!edit ? (
				<div
					style={{
						marginTop: 10,
						display: 'flex',
						justifyContent: 'flex-start'
					}}
				>
					<div>
						<button
							class="btns"
							style={{ marginRight: 25 }}
							onClick={e => onNextStep(e)}
						>
							Next
						</button>
					</div>
					<div>
						<button class="btns" onClick={() => onPreviousClick()}>
							Previous
						</button>
					</div>
				</div>
			) : (
				''
			)}
			{edit ? (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						stateData={{ isLoading: putLoader }}
						btnName="Update"
						buttonClass="btn-smart"
						loaderBmargin={5}
						marginTop={20}
						onHandleSubmit={() =>
							onUpdate(dispatch, 'ON_SET_SIBLINGS_EDIT', {
								name: 'StaffSiblings',
								Siblings
							})
						}
					/>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default Sibling;
