import React from 'react';

const Spinner = props => {
	const {
		display = 'flex',
		justifyContent = 'center',
		color = '#01AC8A'
	} = props;
	return (
		<div style={{ display, justifyContent }}>
			<div class="spinner-border" style={{ color }}></div>
		</div>
	);
};

export default Spinner;
