import React, { Component } from 'react';
import {
	Editor,
	EditorState,
	RichUtils,
	convertToRaw,
	convertFromRaw
} from 'draft-js';
import Axios from 'axios/index';
import config from '../../../src/config/config';
import $ from 'jquery';

class ViewEditorComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: EditorState.createEmpty(),
			adminData: [],
			Ref: [],
			Entity: [],
			Full: [],
			Subjects: this.props.subject
		};
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		let token = '';
		if (localStorage.jwtToken) {
			token = localStorage.getItem('jwtToken');
		}
		Axios.get(`${config.localhttp}/api/SyllabusNew/2`, {
			headers: { Authorization: `Bearer  ${token}` }
		})
			.then(success => {
				console.log(success);

				this.setState({
					isLoading: false,
					adminData: success.data,
					Ref: success.data.blocks,
					Entity: success.data.entityMap
				});
				var blocks = [...this.state.Ref];
				var entityMap = {
					...this.state.Entity
				};
				this.setState({
					Full: { blocks, entityMap }
				});
				const contentState = convertFromRaw(this.state.Full);
				this.setState({
					editorState: EditorState.createWithContent(contentState)
				});
				return this.state.editorState;
			})
			.catch(error => {
				this.setState({
					isLoading: false
				});
				console.log(error);
			});

		// this.setState({
		//     Full: [...this.state.Ref , ...this.state.Entity]
		// })
		console.log('merge', this.state.Full);
		// const contentState = convertFromRaw(JSON.parse(this.state.adminData));
		// this.setState({
		// 	editorState: EditorState.createWithContent(contentState)
		// });
	}
	getSavedEditorData = () => {
		const contentState = convertFromRaw(this.state.Full);
		this.setState({
			editorState: EditorState.createWithContent(contentState)
		});
		return this.state.editorState;
	};
	getData = () => {
		var blocks = [...this.state.Ref];
		var entityMap = {
			...this.state.Entity
		};
		this.setState({
			Full: { blocks, entityMap }
		});
		console.log('Ref', this.state.Ref);
		console.log('Entity', this.state.Entity);
		console.log('After ', this.state.adminData);
	};
	render() {
		console.log('Testing ', this.state.Full);
		console.log('Testing ', this.state.editorState);
		return (
			<div>
				<div className="editors">
					<Editor editorState={this.state.editorState} />
				</div>
				<button onClick={this.getSavedEditorData}>Submit</button>
				<button onClick={this.getData}>Checking Button</button>
				<div></div>
			</div>
		);
	}
}

export default ViewEditorComponent;
