import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	staffData: [],
	getAppraisalData: [],
	getNotificationData: []
};

export default function setStaffReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.GET_ALL_STAFF_DATA:
			return {
				...state,
				staffData: { ...action.payload }
			};

		case actionTypes.GET_ALL_APPRAISAL_DATA:
			return {
				...state,
				getAppraisalData: [...action.payload]
			};

		case actionTypes.DELETE_ITEM_IN_STAFF:
			let filterAppraisalData = state.getAppraisalData.filter(
				filterOBJ => filterOBJ.t1.id !== action.payload
			);

			return {
				...state,
				getAppraisalData: filterAppraisalData
			};

		case actionTypes.EDIT_ITEM_IN_STAFF:
			var adminData = [...state.getAppraisalData];

			var index = adminData.findIndex(obj => obj.t1.id === action.payload.id);

			adminData[index].t1.percentagePerformance =
				action.payload.percentagePerformance;
			adminData[index].t1.percentageBasic = action.payload.percentageBasic;
			adminData[index].t1.type = action.payload.type;

			return { ...state, getAppraisalData: [...adminData] };
		case actionTypes.GET_NOTIFICATION_DATA:
			return {
				...state,
				getNotificationData: [...action.payload]
			};
		case actionTypes.DELETE_ITEM_IN_NOTIFICATION:
			let filterAppraisalNotification = state.getNotificationData.filter(
				filterOBJ => filterOBJ.AppraisalID !== action.payload
			);

			return {
				...state,
				getNotificationData: filterAppraisalNotification
			};
		default:
			return state;
	}
}
