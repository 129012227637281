import React, { Component } from 'react';
import Axios from 'axios/index';
import config from '../../config/config';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DateandTime from '../Transport/DateandTime';

class AddCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			description: '',
			accountId: '',
			accountType: '',
			msg: false,
			errorMsg: false
		};
	}
	onHandleTextChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	onHandleSubmit = e => {
		e.preventDefault();
		console.log(this.state);
		const { description, name, accountId, accountType } = this.state;
		if (Object.is(name, '') || Object.is(description, '')) {
			this.setState({
				errorMsg: true,
				msg: false
			});
		} else {
			let token = '';
			if (localStorage.jwtToken) {
				token = localStorage.getItem('jwtToken');
			}
			var Headconfig = {
				headers: { Authorization: 'Bearer ' + token }
			};
			var bodyParameters = {
				name,
				description,
				accountType: this.props.name,
				accountId: this.props.role
			};
			Axios.post(
				`${config.localhttp}/api/InventoryCategories`,
				bodyParameters,
				Headconfig
			)
				.then(response => {
					console.log(response);

					if (response.status == 200) {
						this.setState({
							name: '',
							description: '',
							msg: true
						});
					}
				})
				.catch(error => {
					if (error.response) {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received

						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
				});
		}
	};
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};
	render() {
		return (
			<div>
				<div className="container">
					<section class="mb-4">
						<h2 class="h1-responsive font-weight-bold text-center my-4">
							Add Category
						</h2>
						<p class="text-center w-responsive mx-auto mb-5" />
						<div
							class="row"
							style={{
								justifyContent: 'center',
								textAlign: 'center'
							}}
						>
							<div
								class="col-md-9 mb-md-0 mb-5"
								style={{
									border: 'solid',
									borderColor: '#01ac8a',
									padding: 40,
									borderRadius: 15,
									width: 'auto'
								}}
							>
								{this.Message(
									'Category Added Successfully!',
									'success',
									20,
									this.state.msg
								)}
								{this.Message(
									'Please fill the form properly!',
									'danger',
									20,
									this.state.errorMsg
								)}

								<form
									onSubmit={this.onHandleSubmit}
									id="contact-form"
									name="contact-form"
								>
									<div class="row">
										<div class="col-md-12">
											<div class="md-form mb-0">
												<DateandTime />
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-6">
											<div class="md-form mb-0">
												<label for="name" class="">
													Admin Name
												</label>
												<input
													style={{ borderColor: '#01AC8A' }}
													readOnly={true}
													type="text"
													name="AdminName"
													class="form-control"
													value={this.props.name}
												/>
											</div>
										</div>

										<div class="col-md-6">
											<div class="md-form mb-0">
												<label for="name" class="">
													Admin Type
												</label>
												<input
													style={{ borderColor: '#01AC8A' }}
													readOnly={true}
													type="text"
													name="AdminType"
													value={this.props.role}
													class="form-control"
												/>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													Category Name
												</label>
												<input
													style={{ borderColor: '#01AC8A' }}
													class="form-control"
													type="text"
													value={this.state.name}
													placeholder="Name"
													name="name"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													Description
												</label>
												<textarea
													style={{ borderColor: '#01AC8A' }}
													type="text"
													placeholder="Description"
													value={this.state.description}
													name="description"
													onChange={this.onHandleTextChange}
													class="form-control"
												></textarea>
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="text-center text-md-center">
										<button type="submit" class="btns">
											Add
										</button>
									</div>
								</form>
								<div class="status" />
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId
	};
}
export default connect(
	mapStateToProps,
	null
)(AddCategory);
