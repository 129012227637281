import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { prototype } from 'events';
const GeneralRadioOptionSelection = props => {
	const {
		stateData,
		onHandleTextChange,
		Name,
		idForYes,
		idForNo,
		feildName,
		onChangeTransportStatus
	} = props;
	let getFun =
		Name === 'School Bus:'
			? e => onChangeTransportStatus(e)
			: e => onHandleTextChange(e);
	return (
		<div class="col-md-6">
			<div style={{ fontSize: 20, padding: 10 }} class="md-form mb-0">
				<label style={{ width: 'max-content' }} for="name" class="">
					<i
						className="fas fa-house animated fadeIn"
						style={{ marginRight: 4 }}
					/>
					{Name}
				</label>
				<div className="row" style={{ marginLeft: 4 }}>
					<div class="custom-control custom-radio">
						<input
							onChange={e => getFun(e)}
							type="radio"
							checked={stateData[feildName] === 'Yes' ? true : false}
							class="custom-control-input"
							id={idForYes}
							name={feildName}
							value={'Yes'}
						/>
						<label class="custom-control-label" for={idForYes}>
							Yes
						</label>
					</div>
					&nbsp;&nbsp;&nbsp;
					<div class="custom-control custom-radio custom-control-inline">
						<input
							checked={stateData[feildName] === 'No' ? true : false}
							onChange={e => getFun(e)}
							type="radio"
							class="custom-control-input"
							id={idForNo}
							name={feildName}
							value="No"
						/>
						<label class="custom-control-label" for={idForNo}>
							No
						</label>
					</div>
				</div>
			</div>
		</div>
	);
};
GeneralRadioOptionSelection.prototype = {
	stateData: PropTypes.object.isRequired,
	onHandleTextChange: PropTypes.func.isRequired,
	Name: PropTypes.string.isRequired,
	idForYes: PropTypes.string.isRequired,
	idForNo: PropTypes.string.isRequired,
	feildName: PropTypes.string.isRequired
};
export default GeneralRadioOptionSelection;
