import React, { useState } from 'react';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextArea from '../../components/TextArea';
import TextInput from '../../components/TextInput/TextInput';
import { General } from '../../utils';
import { StaffWrapper } from '../Staff';
import { StaffField } from '../../utils';
import Button from '../Button';
import { Types } from '../../hooks/HookReducers/StaffReducer';

const StaffAddress = props => {
	const {
		onNextClick,
		onPreviousClick,
		putLoader,
		nonStateData,
		stateData,
		edit = false,
		dispatch,
		errors,
		onUpdate
	} = props;
	const [editState, setEditState] = useState(stateData);
	let getFieldData = StaffField.getStaffFeildData(
		edit ? editState : nonStateData,
		dispatch
	);

	const onChange = e => {
		let getOBJ = { ...editState };
		getOBJ[e.target.name] = e.target.value;
		setEditState(getOBJ);
	};
	const editHandleSubmit = e => {
		debugger;
		if (onNextClick(e, 'checkStaffAdress', editState)) {
			onUpdate(dispatch, 'ON_SET_STAFF_UPDATE_ADDRESS', {
				name: 'StaffAddress',
				data: editState
			});
		}
	};
	return (
		<React.Fragment>
			<StaffWrapper>
				{getFieldData.map(item =>
					item.type === 'text' ? (
						<TextInput
							newError={errors}
							enterWidth={item.enterWidth}
							customWidth={item.customWidth}
							isValidte={edit}
							edit={edit}
							onEditHandleChange={onChange}
							onHandleChange={item.onHandleChange}
							stateData={item.stateData}
							feildName={item.feildName}
							inputLabelName={item.inputLabelName}
							errors={item.errors}
							placeholder={item.placeholder}
							dispatchFlag={false}
							dispatch={dispatch}
							// readOnly={true}
							design={item.design}
							type={item.type}
						/>
					) : item.type === 'textarea' ? (
						<TextArea
							edit={edit}
							onEditHandleChange={onChange}
							newError={errors}
							enterWidth={item.enterWidth}
							customWidth={item.customWidth}
							isValidte={edit}
							onHandleChange={edit ? onChange : item.onHandleChange}
							stateData={item.stateData}
							feildName={item.feildName}
							inputLabelName={item.inputLabelName}
							errors={item.errors}
							dispatchFlag={false}
							dispatch={dispatch}
							placeholder={item.placeholder}
							row={item.row}
							col={item.col}
							// readOnly={true}
							design={true}
						/>
					) : item.type === 'select' ? (
						<SelectionInput
							edit={edit}
							editClassOnChange={onChange}
							newError={errors}
							marginTop={item.marginTop}
							feildName={item.feildName}
							selectName={item.selectName}
							design={item.design}
							isValidte={edit}
							dispatchFlag={edit}
							dispatch={dispatch}
							onHandleChange={item.onHandleChange}
							handleFlag={item.handleFlag}
							stateData={item.stateData}
							errors={item.errors}
							optionsArrys={item.optionsArrays}
							selectedText={item.selectedText}
							optionType={item.optionType}
						/>
					) : (
						''
					)
				)}
			</StaffWrapper>
			{!edit ? (
				<React.Fragment>
					<div
						style={{
							marginTop: 10,
							display: 'flex',
							justifyContent: 'flex-end'
						}}
					>
						<div>
							<button
								class="btns"
								style={{ marginRight: 25 }}
								onClick={e => onNextClick(e, 'checkStaffAdress')}
							>
								Next
							</button>
						</div>
						<div>
							<button class="btns" onClick={() => onPreviousClick()}>
								Previous
							</button>
						</div>
					</div>
				</React.Fragment>
			) : (
				''
			)}
			{edit ? (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						stateData={{ isLoading: putLoader }}
						btnName="Update"
						buttonClass="btn-smart"
						loaderBmargin={5}
						marginTop={20}
						onHandleSubmit={editHandleSubmit}
					/>
				</div>
			) : (
				''
			)}
		</React.Fragment>
	);
};

export default StaffAddress;
