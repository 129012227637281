import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import isNull from 'lodash/isNull';

class AddBook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: false,
			successMsg: false,
			msg: '',
			isLoading: false,
			classAndSubject: null,
			className: null,
			categoryName: null,
			bookName: null,
			author: null,
			quantity: null,
			barcode: null
		};
	}

	componentDidMount() {
		this.getSimple('classSectionGradeTeacher', 'classAndSubject');
	}

	getSimple = (type, state) => {
		this.props.sharedActions
			.getDataWithoutDispatch(`/api/${type}`)
			.then(success => {
				this.setState({
					[state]: success,
					isLoading: false
				});
			})
			.catch(error => {
				this.setState({
					errorMsg: true,
					successMsg: false,
					msg: 'Api Error',
					isLoading: false
				});
				console.error('Error Name: ', error);
			});
	};

	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {}, 3000);
	};

	onHandleTextChange = e => {
		console.log([e.target.name], e.target.value);

		this.setState({
			[e.target.name]: e.target.value
		});
	};

	handleSubmit = e => {
		e.preventDefault();
		const {
			className,
			categoryName,
			bookName,
			author,
			quantity,
			barcode
		} = this.state;
		if (
			isNull(className) ||
			isNull(categoryName) ||
			isNull(bookName) ||
			isNull(author) ||
			isNull(quantity) ||
			isNull(barcode)
		) {
			this.setState({
				successMsg: false,
				errorMsg: true,
				msg: 'Please fill the form respectively!'
			});
		} else {
			let data = {
				accountId: this.props.userData.unique_name,
				accountType: this.props.userData.role,
				className: className,
				category: categoryName,
				bookName: bookName,
				Author: author,
				quantity: quantity,
				barCodeNo: barcode
			};

			this.props.sharedActions
				.simpleAddRequest(`/api/Books`, data)
				.then(success => {
					console.log(success);

					this.setState({
						msg: 'Book Added!',
						successMsg: true,
						isLoading: false,
						className: '',
						categoryName: '',
						bookName: '',
						author: '',
						quantity: '',
						barcode: ''
					});
				})
				.catch(error => {
					this.setState({
						msg: error.response.data.Message || 'Error Occured!!',
						errorMsg: true,
						isLoading: false
					});
					console.error('Error occured', error);
				});
		}
	};
	componentDidUpdate() {
		const { successMsg, errorMsg } = this.state;

		if (successMsg || errorMsg) {
			setTimeout(() => {
				this.setState({
					successMsg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}

	render() {
		const {
			successMsg,
			errorMsg,
			msg,
			isLoading,
			classAndSubject,
			className,
			categoryName,
			bookName,
			author,
			quantity,
			barcode
		} = this.state;

		return (
			<div className="container">
				<section className="mb-4">
					<h2 className="h1-responsive font-weight-bold text-center my-4">
						Add Book
					</h2>
					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						className="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							className="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{errorMsg && (
								<p
									style={{
										color: 'red',
										textAlign: 'center'
									}}
								>
									{msg}
								</p>
							)}
							{successMsg && (
								<p
									style={{
										color: 'green',
										textAlign: 'center'
									}}
								>
									{msg}
								</p>
							)}

							<form
								id="book-form"
								name="book-form"
								onSubmit={this.handleSubmit}
							>
								<div className="row">
									<div className="col-md-12">
										<div className="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminName" class="font-weight-bold">
												Admin Name
											</label>
											<input
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminType" class="font-weight-bold">
												Admin Type
											</label>
											<input
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
									</div>
								</div>
								<br />
								<div className="row">
									<div className="col-md-6">
										<div className="md-form mb-0">
											<label for="className" class="font-weight-bold">
												Class
											</label>
											<select
												class="custom-select"
												value={className}
												name="className"
												onChange={this.onHandleTextChange}
												disabled={!classAndSubject}
											>
												<option value="">Please Select Class</option>
												{classAndSubject &&
													classAndSubject.classes.map((value, index) => {
														return (
															<option value={value.className}>
																{value.className}
															</option>
														);
													})}
											</select>
										</div>
									</div>
									<div className="col-md-6">
										<div className="md-form mb-0">
											<label for="categoryName" class="font-weight-bold">
												Category
											</label>
											<select
												class="custom-select"
												value={categoryName}
												name="categoryName"
												onChange={this.onHandleTextChange}
												disabled={!className}
											>
												<option value="">Please Select Category</option>
												{className &&
													classAndSubject.subject.map((value, index) => {
														return (
															classAndSubject.classes.find(
																o => o.className === className
															).classId == value.classId && (
																<option value={value.subjectName}>
																	{value.subjectName}
																</option>
															)
														);
													})}
											</select>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="bookName" class="font-weight-bold">
												Book Name
											</label>
											<input
												type="text"
												name="bookName"
												class="form-control"
												value={bookName}
												onChange={this.onHandleTextChange}
												disabled={!categoryName}
											/>
										</div>
									</div>
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="author" class="font-weight-bold">
												Author
											</label>
											<input
												type="text"
												name="author"
												class="form-control"
												value={author}
												onChange={this.onHandleTextChange}
												disabled={!categoryName}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="quantity" class="font-weight-bold">
												Quantity
											</label>
											<input
												type="number"
												name="quantity"
												class="form-control"
												value={quantity}
												onChange={this.onHandleTextChange}
												disabled={!categoryName}
											/>
										</div>
									</div>
									<div className="col-md-6" style={{ marginTop: 20 }}>
										<div className="md-form mb-0">
											<label for="barcode" class="font-weight-bold">
												Barcode
											</label>
											<input
												type="text"
												name="barcode"
												class="form-control"
												value={barcode}
												onChange={this.onHandleTextChange}
												disabled={!categoryName}
											/>
										</div>
									</div>
								</div>
								<br />
								<br />
								<div className="text-center text-md-center">
									<button type="submit" className="btns">
										Add
										<span
											style={{
												marginBottom: 5
											}}
											className={
												isLoading && 'spinner-border spinner-border-sm'
											}
										></span>
									</button>
								</div>
							</form>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddBook);
