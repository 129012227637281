import React from 'react'
import CardForVisitor from './CardForVisitorView'
import { connect } from 'react-redux';
import TextInput from '../../components/TextInput/TextInput'
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import Button from '../../components/Button';
import ColorPalette from '../../components/ColorPalette/ColorPalette'
import SelectionInput from '../../components/SelectionInput/SelectionInput';

class GenerateVisitorCardView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            accountId: props.name,
            accountType: props.role,
            cardColor: '',
            copies: 1,
            schoolName: '',
            passType: '',
            visible: false,
            errorMsg: false
        }
    }
    clicked = (e, item) => {
        this.setState({
            cardColor: item
        })
    }
    onHandleTextChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onHandleSelectChange = e => {
        if (e.target.value === '# of Copies') {
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false
                });
            }, 3000);
        }
    }

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { schoolName, passType, cardColor } = this.state;
        if (Object.is(schoolName, '') ||
            Object.is(passType, '') ||
            Object.is(cardColor, '')) {
            this.setState({
                errorMsg: true
            })
        } else {
            this.setState({
                visible: true
            })
        }
    }

    render() {
        const { errors, cardColor, copies, schoolName, passType, visible } = this.state;

        const Form = (
            <FormLayoutWrapper
                formName='Generate Visitor Card'
                borderColor='#01ac8a'
                borderRadius={15}
            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly
                        </p>
                ) : (
                        ''
                    )}


                <div className="row">

                    <TextInput
                        feildName={'accountId'}
                        inputLabelName={'Admin Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Admin Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        feildName={'accountType'}
                        inputLabelName={'Account Type'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Account Type"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'schoolName'}
                        inputLabelName={'School Name'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'School Name'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'passType'}
                        inputLabelName={'Pass Type'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Pass Type'}
                        isValidte={false}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'copies'}
                        selectName={'Number of Copies'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={[1, 2, 3, 4, 5, 6, 7, 8,]}
                        selectedText={'# of Copies'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="static"
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}

                    />

                    <ColorPalette
                        marginTop={20}
                        colors={['#01ac8a', '#01425e', '#5bc0de', '#d9534f', '#292b2c']}
                        inputLabelName={'Card Color'}
                        onHandleSubmit={(e, item) => this.clicked(e, item)}
                    />
                </div>
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Generate"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={40}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                    textColor="white"
                />
            </FormLayoutWrapper>
        )

        const Cards = (
            <div class='row'>
                <div class='col-md-12'>
                    <h2 class="h1-responsive font-weight-bold text-center my-4">
                        Generated Cards
                    </h2>
                </div>
                {[...Array(parseInt(copies))].map(item => (
                    <div class='col-md-6' style={{ marginBottom: '20px' }}>
                        <CardForVisitor schoolName={schoolName} passType={passType} cardColor={cardColor} selectedColor={cardColor}/>
                    </div>
                ))}
            </div>
        )

        return (
            <div>
                {Form}
                <div id='div1'>
                    {visible ? Cards : ''}
                </div>
                <div class="text-center text-md-right">
                    <label for="name" class="" onClick={() => this.PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
                            </label>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
export default connect(
    mapStateToProps,
    null
)(GenerateVisitorCardView);
