import * as actionTypes from '../actionTypes';

export function setAllParentsInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_PARENT,
		payload: allData
	};
}
export function addParent(Data) {
	return {
		type: actionTypes.ADD_PARENT_RECORD,
		payload: Data
	};
}
export function updateParentFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_PARENT,
		payload: setFlag
	};
}
export function updateParent(data) {
	return {
		type: actionTypes.EDIT_PARENT_RECORD,
		payload: data
	};
}
export function deleteParent(id) {
	return {
		type: actionTypes.DELETE_PARENT,
		payload: id
	};
}
