import React from 'react';
import TextArea from '../../components/TextArea';
import TextInput from '../../components/TextInput/TextInput';
import { General } from '../../utils';
import SpecificFileUpload from '../../components/Admission/SpecificFileUpload';
import GeneralFileUploadOption from '../../components/Admission/GeneralFileUploadOption';
import CustomDatePicker from '../../components/CustomDatePicker';
import { StaffWrapper } from '../Staff';
import { StaffField } from '../../utils';
import ValidationText from '../../utils/app_constants/validate_messages_constants';
const StaffDoc = props => {
	const {
		stateData,
		dispatch,
		edit,
		errors,
		docsChange,
		fieldsData = null
	} = props;
	debugger;
	let getDocsField = fieldsData(stateData, dispatch);
	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
				{Object.keys(errors).length > 0 && errors['fileType'] ? (
					<p style={{ color: 'red', fontSize: 15 }}>
						{ValidationText.FILE_EXTENSION_MESSAGE}
					</p>
				) : (
					''
				)}
			</div>
			<StaffWrapper>
				{getDocsField.map(item =>
					item.type === 'radio' ? (
						<React.Fragment>
							<div>
								<SpecificFileUpload
									// onHandleTextChange={onCustomRadioStudentDocs}
									errors={errors}
									dispatchFlag={true}
									dispatch={item.dispatch}
									stateData={item.stateData}
									Name={item.Name}
									idForYes={item.idForYes}
									idForNo={item.idForNo}
									feildName={item.feildName}
									uploadName={item.uploadName}
								/>
								<div style={{ width: '50%' }}>
									<GeneralFileUploadOption
										dispatchFlag={true}
										name={item.uploadName}
										feildName={item.feildName}
										fileOnChange={docsChange}
										dispatch={dispatch}
										stateData={stateData}
									/>
								</div>
							</div>
						</React.Fragment>
					) : (
						''
					)
				)}
			</StaffWrapper>
		</div>
	);
};

export default StaffDoc;
