import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';
import $ from 'jquery';
import * as sharedActions from '../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MultipleFees from './MultipleFees';
import Snackbar from '../../components/SnackBar/SnackBar';

class AddFeeTypeNew extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: false,
			msg: '',
			backColor: '',
			selectClassData: [],
			btnLoad: false,
			check: false,
			classId: '',
			registration: 'Registration',
			registrationFee: '',
			admission: 'Admission',
			admissionFee: '',
			canteen: 'Canteen',
			canteenFee: '',
			stationary: 'Stationary',
			stationaryFee: '',
			entryTest: 'Entry Test',
			entryTestFee: '',
			bus: 'Bus',
			busFee: '',
			security: 'Security',
			securityFee: '',
			uniform: 'Uniform',
			uniformFee: '',
			late: 'Late',
			lateFees: '',
			monthly: 'Monthly',
			monthlyFee: '',
			quaterly: 'Quaterly',
			quaterlyFee: '',
			halfyearly: 'Half Yearly',
			halfyearlyFee: '',
			annually: 'Annually',
			annuallyFee: ''
		};
	}

	onClickDeleteItem = arrName => {
		let getStateArr = this.state[`${arrName}`];
		let getDeleteLength = getStateArr.length - 1;
		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			this.setState({
				[arrName]: [...getNewDetails]
			});
		}
	};

	onClickAddItem = (arrName, obj) => {
		let getStateArr = this.state[`${arrName}`];
		getStateArr = [...getStateArr, obj];
		console.log(arrName);
		this.setState({
			[arrName]: getStateArr
		});
	};
	updateSpecialArrObjOnChange = (e, index, arrName) => {
		const changeArrOfObj = this.state[`${arrName}`];
		changeArrOfObj[index][e.target.name] = e.target.value;
		this.setState({
			[arrName]: changeArrOfObj
		});
	};
	onChangeText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleSubmitCheck = e => {
		e.preventDefault();
		const { driverIdCardNo } = this.state;
		if (Object.is(driverIdCardNo, '')) {
			this.setState = {
				checking: false
			};
		} else {
			this.setState = {
				checking: true
			};
		}
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const {
			classId,
			registration,
			registrationFee,
			admission,
			admissionFee,
			canteen,
			canteenFee,
			stationary,
			stationaryFee,
			entryTest,
			entryTestFee,
			bus,
			busFee,
			security,
			securityFee,
			uniform,
			uniformFee,
			late,
			lateFees,
			monthly,
			monthlyFee,
			quaterly,
			quaterlyFee,
			halfyearly,
			halfyearlyFee,
			annually,
			annuallyFee
		} = this.state;
		e.preventDefault();
		if (Object.is(classId, '')) {
			this.setState({
				error: true,
				errorMsg: true
			});
		} else {
			console.log(this.state);
			var bodyParameters = {
				classId,
				registration,
				registrationFee,
				admission,
				admissionFee,
				canteen,
				canteenFee,
				stationary,
				stationaryFee,
				entryTest,
				entryTestFee,
				bus,
				busFee,
				security,
				securityFee,
				uniform,
				uniformFee,
				late,
				lateFees,
				accountType: this.props.role,
				accountId: this.props.name
			};

			this.props.sharedActions
				.simpleAddRequest('/api/StudentFeeStructure', bodyParameters)
				.then(success => {
					this.setState({
						backColor: '#01ac8a',
						msg: 'Fee Type Added Succeffully',
						errorMsg: false,
						registrationFee: '',
						admissionFee: '',
						canteenFee: '',
						stationaryFee: '',
						entryTest: '',
						entryTestFee: '',
						busFee: '',
						securityFee: '',
						uniformFee: '',
						lateFees: '',
						monthlyFee: '',
						quaterlyFee: '',
						halfyearlyFee: '',
						annuallyFee: ''
					});
					this.props.snackbar();
				})
				.catch(err => {
					debugger;
					this.setState({
						msg: err.response.data.Message,
						backColor: 'red'
					});
					this.props.snackbar();
					console.log(err);
				});
		}
	};
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.getDataWithoutDispatch('/api/classSection')
			.then(success => {
				this.setState({
					selectClassData: success
				});
			})
			.catch(err => {
				this.setState({});

				console.log(err);
			});
	}
	componentDidUpdate() {
		if (this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					errorMsg: false
				});
			}, 3000);
		}
	}
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};
	onSelectChange = e => {
		console.log(e.target.value);
		this.setState({
			classId: e.target.value
		});
		// // 1. Make a shallow copy of the items
		// let items = [...this.state.feeTypes];
		// // 2. Make a shallow copy of the item you want to mutate
		// let item = { ...items[0] };
		// // 3. Replace the property you're intested in
		// item.classId = e.target.value;
		// // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
		// items[0] = item;
		// // 5. Set the state to our new copy
		// this.setState({ feeTypes: items });
		// console.log(this.state);
	};
	render() {
		const DataForFeeType = [
			{
				id: 1,
				name: 'admissionFee',
				value: this.state.admissionFee,
				label: 'Admission'
			},
			{
				id: 2,
				name: 'registrationFee',
				value: this.state.registrationFee,
				label: 'Registration'
			},
			{
				id: 3,
				name: 'canteenFee',
				value: this.state.canteenFee,
				label: 'Canteen'
			},
			{
				id: 4,
				name: 'stationaryFee',
				value: this.state.stationaryFee,
				label: 'Stationary'
			},
			{
				id: 5,
				name: 'entryTestFee',
				value: this.state.entryTestFee,
				label: 'Entry Test'
			},
			{
				id: 7,
				name: 'busFee',
				value: this.state.busFee,
				label: 'Bus'
			},
			{
				id: 8,
				name: 'securityFee',
				value: this.state.securityFee,
				label: 'Security Deposit'
			},
			{
				id: 9,
				name: 'uniformFee',
				value: this.state.uniformFee,
				label: 'Uniform'
			},
			{
				id: 10,
				name: 'lateFees',
				value: this.state.lateFees,
				label: 'Late Fee'
			}
		];
		console.log('Classes Ka Data', this.state.selectClassData);
		const { classList } = this.state.selectClassData;
		const { feeTypes } = this.state;
		console.log('Fee data', feeTypes);
		return (
			<div>
				<Snackbar msg={this.state.msg} backColor={this.state.backColor} />
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Create Fees Type
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.Message(
								'Please fill the form properly!',
								'danger',
								20,
								this.state.errorMsg
							)}
							<form
								onSubmit={this.onHandleSubmit}
								id="contact-form"
								name="contact-form"
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-2"></div>
									<div class="col-md-8">
										<div class="md-form mb-0">
											<select
												onChange={this.onSelectChange}
												class="custom-select"
												name="className"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Class...
												</option>
												{classList &&
													classList.map((item, index) => (
														<option key={index} value={`${item.id}`}>
															{item.className}
														</option>
													))}
											</select>
										</div>
									</div>
									<div class="col-md-2"></div>
								</div>
								{DataForFeeType.map((item, index) => (
									<React.Fragment>
										<MultipleFees
											key={index}
											onHandleTextChange={this.onChangeText}
											stateData={this.state}
											DataForFeeType={item}
											updateSpecialArrObjOnChange={
												this.updateSpecialArrObjOnChange
											}
											onClickAddItem={this.onClickAddItem}
											onClickDeleteItem={this.onClickDeleteItem}
										/>
									</React.Fragment>
								))}
								<div class="text-center text-md-center">
									<button type="submit" class="btns">
										Add
										<span
											style={{ marginBottom: 5 }}
											class={`${
												this.state.btnLoad
													? 'spinner-border spinner-border-sm'
													: ''
											}`}
										></span>
									</button>
								</div>
							</form>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
		// BudgetItemsAction: bindActionCreators(BudgetItemsAction, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddFeeTypeNew);
