import React, { Component } from 'react';
import Stepper from 'bs-stepper';
import StudentAdmission from './StudentAdmission';
import ParentInfo from './ParentInfo';
import StudentDocument from './StudentDocument';
import StudentAdress from './StudentAdress';
import ParentsDocuments from './ParentsDocuments';
import MedicalCondition from './MedicalCondition';
import StudentSiblingDetail from './StudentSiblingDetail';
import PreviousSchoolDetails from './PreviousSchoolDetails';
import WidthdrawDetail from './WidthdrawDetail';
import OtherInformation from './OtherInformation';
import StudentTransport from './StudentTransport';
import { Prompt } from 'react-router-dom';
import * as sharedActions from '../../Actions/sharedActions';
import * as actions from '../../Actions/Admission';
import StepperButtonsCom from '../../components/StepperGeneralButtons/StepperButtonsCom';
import * as campusAction from '../../Actions/Campus';
import LayoutWrapper from '../../components/HOC/LayoutWrapper';
import Axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from '../../validator/registrationValidator';
import StudentDocumentSection from './DocumentSection/StudentDocumentSection';

// const Stepes = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7', 'Step 8', 'Step 9', 'Step 10', 'Step 11'];
const sports = [
	'cricket',
	'football',
	'hockey',
	'tennis',
	'basketball',
	'baseball'
];
class AdmissionForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//steppers
			//studnet image
			isDoc: false,
			imagePreviewUrl:
				'http://ctt.trains.com/sitefiles/images/no-preview-available.png',
			studentImage: '',

			sType: 'AddSection',
			//end of image
			Stepes: [
				'Step 1',
				'Step 2',
				'Step 3',
				'Step 4',
				'Step 5',
				'Step 6',
				'Step 7',
				'Step 8',
				'Step 9'
			],
			studentId: null,
			comStep: [],
			//end of steppers

			//extra feilds of parent docs
			filePath_passportFather: '',
			filePath_passportMother: '',
			filePath_idCardFather: '',
			filePath_idCardMother: '',

			//end of parent docs extar feilds
			//extra feild of Student document
			filePath: '',
			filePath1: '',
			filePath_ministryVacination: '',
			filePath_medicalHistory: '',
			filePath_passportCopy: '',
			filePath_idCardCopy: '',
			filePath_lastResult: '',
			filePath_testResult: '',
			profileimage: '',

			//end of student docs
			//extra feild for transport
			pickUp: '',
			otherDriverName: '',
			otherCarNo: '',
			otherDriverCardIdNo: '',
			otherDriverMobileNo: '',

			// end of extra transport

			fullNameArabic: '',

			//extra data feilds of medical condition
			threatConditionReason: null,
			needMedicationReason: null,
			otherMedicationReason: null,
			//end of extra feilds
			RouteOBJ: null,
			campusData: [],
			sessionData: [],
			valid: false,
			//student transport
			transportStatus: null,
			routeId: '',
			driverId: '',
			vehicleNo: '',
			driverName: '',
			driverLicense: '',
			driverIdCardNo: '',
			driverMobileNo: '',
			driverIdCardCopy: '',
			parentOrByWalk: null,
			transportType: '',
			routeName: '',
			// parentDocs
			passportFather: null,
			passportMother: null,
			idCardFather: null,
			idCardMother: null,
			// student otherinformation
			sports: '',
			otherActivity: '',
			//student widthdrawDetails
			dateLeaving: null,
			classLeft: '',
			reason: '',
			// student previous school details
			previousSchoolDetails: [
				{
					prevSchoolName: '',
					enrollNo: '',
					fromYear: '',
					yearUpto: '',
					reasonLeaving: ''
				}
			],
			// student Medical Condition
			threatCondition: '',
			needMedication: '',
			otherMedication: '',
			//student siblings form
			siblingsDetails: [
				{
					siblingName: '',
					age: '',
					relation: '',
					education: '',
					school: ''
				}
			],
			// Student Documents
			dobCertificate: null,
			transferCertificate: null,
			ministryVacination: null,
			medicalHistory: null,
			passportCopy: null,
			idCardCopy: null,
			lastResult: null,
			testResult: null,
			// Student Parent Info fields
			fatherPName: '', //change on submit
			motherName: '',
			garduainName: '',
			occupation: '',
			income: '',
			emergencyContact: '',
			parentMobileNo: '',
			parentEmailId: '',
			parentCNIC: '',
			parentIdCardNo: '',
			// Student Address
			address: '',
			houseNo: '',
			studentIdCardNo: '',
			studentEmailId: '',
			wayNo: '',
			streetNo: '',
			area: '',
			city: '',
			country: 'Choose Country...',
			//Student Admission Form
			firstName: '',
			lastName: '',
			registrationNo: '', // statefull
			admissionNo: '', // statefull => regN0 + ParentId + gender + yearn
			fatherName: '',
			admissionType: 'Admission Type...',
			currentSessionId: 'Current Session...',
			language: 'Choose Language...',
			rollNo: '',
			parentId: '',
			parentName: '',
			gender: 'Choose Gender...',
			dob: null,
			age: '',
			placeOfBirth: '',
			nationality: 'Choose Nationality...',
			religion: 'Religion...',
			isEnable: false,
			campusId: '',
			classId: '',
			sectionId: '',
			section: '',
			campusName: 'Campus Name...',
			className: 'Class Name...',
			gradeId: '',
			userName: '',
			password: '',
			grade: null,
			gradeName: '',
			status: 'Choose Status...',
			accountId: this.props.name,
			accountName: 'qwerqw',
			accountType: this.props.role,
			accountStatus: 'sdf',
			error: false,
			errors: {},
			dummySample: {
				classes: []
				// grade: [
				//     {
				//         className: "Pre-Nursery",
				//         gradeName: "Pre-Nursery",
				//         section: "A"
				//     },
				//     {
				//         className: "1",
				//         gradeName: "Primary",
				//         section: "A"
				//     },
				//     {
				//         className: "1",
				//         gradeName: "Primary",
				//         section: "B"
				//     }
				// ]
			}
		};
		this.main = React.createRef();
	}

	onChangeDate = (date, state) => {
		let getAge = new Date().getFullYear() - new Date(date).getFullYear();

		this.setState({ [state]: date, age: getAge });
	};
	handleScroll = e => {
		e.preventDefault();
		const main = this.main.current;
		window.scrollTo({
			top: main.offsetTop,
			left: 0,
			behavior: 'instant'
		});
	};
	onChangeNoOptions = e => {
		switch (e.target.value) {
			case 'ByWalk':
				this.setState({
					parentOrByWalk: e.target.value,
					otherDriverName: '',
					otherCarNo: '',
					otherDriverCardIdNo: '',
					otherDriverMobileNo: ''
				});
				break;
			case 'ParentPick':
				this.setState({
					parentOrByWalk: e.target.value,
					otherDriverName: '',
					otherCarNo: '',
					otherDriverCardIdNo: '',
					otherDriverMobileNo: ''
				});
				break;
			case 'DriverPick':
				this.setState({
					parentOrByWalk: e.target.value
				});
				break;
			default:
				break;
		}
	};
	onChangeTransportStatus = e => {
		switch (e.target.value) {
			case 'Yes':
				this.setState({
					transportStatus: e.target.value,
					parentOrByWalk: null
				});
				break;
			case 'No':
				this.setState({
					transportStatus: e.target.value,
					routeName: '',
					driverName: '',
					routeId: '',
					driverIdCardNo: '',
					driverLicense: '',
					transportType: '',
					driverMobileNo: '',
					vehicleNo: '',
					driverId: '',
					otherDriverName: '',
					otherCarNo: '',
					otherDriverCardIdNo: '',
					otherDriverMobileNo: ''
				});
				break;
			default:
				break;
		}
	};
	onChangeRoutesName = e => {
		console.log(e.target.value);
		const { route, transport } =
			this.props.admissionState && this.props.admissionState.getData;
		let getRouteNameObj = transport.filter(
			item => item.routeName !== e.target.value
		);
		console.log(route, transport);

		if (e.target.value === 'Routes Name...') {
			this.setState({
				pickUp: '',
				driverName: '',
				routeId: '',
				driverIdCardNo: '',
				driverLicense: '',
				transportType: '',
				driverMobileNo: '',
				vehicleNo: '',
				driverId: ''
			});
		} else {
			this.setState({
				pickUp: getRouteNameObj[0].pickUp,
				driverName: getRouteNameObj[0].driverName,
				routeId: getRouteNameObj[0].id,
				driverIdCardNo: getRouteNameObj[0].driverIdCardNo,
				driverLicense: getRouteNameObj[0].driverLicense,
				transportType: getRouteNameObj[0].transportType,
				driverMobileNo: getRouteNameObj[0].driverMobileNo,
				vehicleNo: getRouteNameObj[0].vehicleNo,
				driverId: getRouteNameObj[0].id
			});
		}
	};
	onChangeClassName = e => {
		console.log(e.target.value);
		const { grade } = this.state.dummySample;
		let getGradeData = grade.filter(
			(item, index) => item.classId === parseInt(e.target.value)
		);
		console.log(getGradeData);

		this.setState({
			grade: getGradeData,
			gradeName: getGradeData[0] && getGradeData[0].gradeName,
			className: getGradeData[0] && getGradeData[0].className,
			classId: parseInt(e.target.value),
			gradeId: getGradeData[0] && getGradeData[0].gradeId
		});
	};
	onGeneralSelectChangeHandler = (e, statePropName, statePropId, id) => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);

		this.setState({
			[statePropName]: result[statePropName],
			[statePropId]: result[id]
		});
	};
	isValidstudentAddForm = formType => {
		const {
			//parentDocs
			filePath_idCardFather,
			filePath_passportFather,
			filePath_idCardMother,
			filePath_passportMother,
			//end of yes or no checks
			passportFather,
			passportMother,
			idCardFather,
			idCardMother,
			//student transport details
			transportStatus,
			routeName,
			pickUp,
			transportType,
			driverLicense,
			driverName,
			parentOrByWalk,
			driverMobileNo,
			driverIdCardCopy,
			vehicleNo,
			driverIdCardNo,
			otherDriverName,
			otherCarNo,
			otherDriverCardIdNo,
			otherDriverMobileNo,

			//student other details
			otherActivity,
			sports,
			//widthdrawdetails
			dateLeaving,
			reason,
			classLeft,
			//previousSchoolDetails
			previousSchoolDetails,
			//medical Condition
			threatConditionReason,
			needMedicationReason,
			otherMedicationReason,
			threatCondition,
			needMedication,
			otherMedication,
			//student siblings form
			siblingsDetails,
			// student document Info
			filePath, //dobcer
			filePath1, //trans
			filePath_ministryVacination,
			filePath_medicalHistory,
			filePath_passportCopy,
			filePath_idCardCopy,
			filePath_lastResult,
			filePath_testResult,

			//yes no check
			dobCertificate,
			transferCertificate,
			ministryVacination,
			medicalHistory,
			passportCopy,
			idCardCopy,
			lastResult,
			testResult,
			// student parent Info
			parentIdCardNo,
			parentCNIC,
			parentEmailId,
			parentMobileNo,
			emergencyContact,
			income,
			occupation,
			garduainName,
			motherName,
			fatherPName,

			// student Address Details fields
			wayNo,
			area,
			city,
			streetNo,
			houseNo,
			studentEmailId,
			address,
			studentIdCardNo,
			country,
			//Student Admission form fields
			file,
			registrationNo,
			firstName,
			lastName,
			fatherName,
			rollNo,
			admissionNo,
			admissionType,
			currentSessionId,
			language,
			parentName,
			gender,
			dob,
			age,
			placeOfBirth,
			religion,
			campusName,
			className,
			grade,
			section,
			userName,
			password,
			nationality,
			status,
			gradeName,
			parentId,
			fullNameArabic
		} = this.state;
		let getAddressFeilds = {
			wayNo,
			area,
			city,
			streetNo,
			houseNo,
			studentEmailId,
			address,
			studentIdCardNo,
			country
		};
		let getFeilds = {
			file,
			firstName,
			lastName,
			fatherName,
			rollNo,
			admissionNo,
			admissionType,
			currentSessionId,
			language,
			parentName,
			gender,
			dob,
			age,
			placeOfBirth,
			religion,
			campusName,
			className,
			grade,
			section,
			userName,
			password,
			nationality,
			status,
			gradeName,
			parentId,
			country
		};

		if (Object.is(formType, 'studentAddForm')) {
			if (
				Object.is(registrationNo, '') ||
				Object.is(fullNameArabic, '') ||
				Object.is(firstName, '') ||
				Object.is(lastName, '') ||
				Object.is(fatherName, '') ||
				Object.is(rollNo, '') ||
				Object.is(admissionType, 'Admission Type...') ||
				Object.is(parentId, '') ||
				Object.is(admissionNo, '') ||
				Object.is(currentSessionId, 'Current Session...') ||
				Object.is(language, 'Choose Language...') ||
				Object.is(gender, 'Choose Gender...') ||
				Object.is(dob, null) ||
				Object.is(age, '') ||
				Object.is(placeOfBirth, '') ||
				Object.is(religion, 'Religion...') ||
				Object.is(campusName, 'Campus Name...') ||
				Object.is(className, 'Class Name...') ||
				Object.is(gradeName, '') ||
				Object.is(section, '') ||
				Object.is(userName, '') ||
				Object.is(password, '') ||
				Object.is(status, 'Choose Status...') ||
				Object.is(nationality, 'Choose Nationality...')
			) {
				let { errors, isValid } = validator(getFeilds, 'checkStudentForm');
				this.setState({
					errors
				});
				return false;
			} else {
				this.setState({
					errors: {}
				});
				return true;
			}
		} else if (Object.is(formType, 'StudentAdress')) {
			if (
				Object.is(wayNo, '') ||
				Object.is(studentIdCardNo, '') ||
				Object.is(address, '') ||
				Object.is(city, '') ||
				Object.is(streetNo, '') ||
				Object.is(houseNo, '') ||
				Object.is(studentEmailId, '') ||
				Object.is(area, '') ||
				Object.is(country, 'Choose Country...')
			) {
				debugger;
				let { errors, isValid } = validator(
					getAddressFeilds,
					'checkAddressForm'
				);
				this.setState({
					errors
				});
				return false;
			} else {
				this.setState({
					errors: {}
				});
				return true;
			}
		} else if (Object.is(formType, 'studentParentInfo')) {
			debugger;
			if (
				Object.is(parentIdCardNo, '') ||
				Object.is(parentEmailId, '') ||
				Object.is(parentMobileNo, '') ||
				Object.is(emergencyContact, '') ||
				Object.is(income, '') ||
				Object.is(occupation, '') ||
				Object.is(garduainName, '') ||
				Object.is(motherName, '') ||
				Object.is(fatherPName, '')
			) {
				return false;
			} else {
				return true;
			}
		} else if (Object.is(formType, 'studentSiblings')) {
			debugger;
			let isValid = siblingsDetails.map((item, index) => {
				console.log(this.IsObjectEmpty(item));
				if (!this.IsObjectEmpty(item)) {
					return false;
				} else {
					return true;
				}
			});
			console.log(isValid);
			let getValue = false;
			if (isValid.includes(false)) {
				getValue = false;
			} else {
				getValue = true;
			}
			return getValue;
		} else if (Object.is(formType, 'studentMedicalCondition')) {
			debugger;
			if (
				Object.is(threatConditionReason, 'Yes') &&
				Object.is(threatCondition, '')
			) {
				return false;
			} else if (
				Object.is(needMedicationReason, 'Yes') &&
				Object.is(needMedication, '')
			) {
				return false;
			} else if (
				Object.is(otherMedicationReason, 'Yes') &&
				Object.is(otherMedication, '')
			) {
				return false;
			} else if (
				Object.is(otherMedicationReason, null) ||
				Object.is(needMedicationReason, null) ||
				Object.is(threatConditionReason, null)
			) {
				return false;
			} else {
				return true;
			}
		} else if (Object.is(formType, 'studentPreviousSchoolDetails')) {
			debugger;
			let isValid = previousSchoolDetails.map((item, index) => {
				console.log(this.IsObjectEmpty(item));
				if (!this.IsObjectEmpty(item)) {
					return false;
				} else {
					return true;
				}
			});
			console.log(isValid);
			let getValue = false;
			if (isValid.includes(false)) {
				getValue = false;
			} else {
				getValue = true;
			}
			return getValue;
		} else if (Object.is(formType, 'StudentWidthDetails')) {
			debugger;
			if (
				Object.is(reason, '') ||
				Object.is(classLeft, '') ||
				Object.is(classLeft, 'Class Name...') ||
				Object.is(dateLeaving, null)
			) {
				return false;
			} else {
				return true;
			}
		} else if (Object.is(formType, 'studentOtherDetails')) {
			debugger;
			if (
				Object.is(sports, '') ||
				Object.is(otherActivity, '') ||
				Object.is(sports, 'Sports Name...')
			) {
				return false;
			} else {
				return true;
			}
		} else if (Object.is(formType, 'studentTransportForm')) {
			debugger;
			if (Object.is(transportStatus, null)) {
				return false;
			} else if (transportStatus === 'Yes' && pickUp === '') {
				return false;
			} else if (transportStatus === 'No' && parentOrByWalk === null) {
				return false;
			} else if (
				transportStatus === 'No' &&
				parentOrByWalk === 'DriverPick' &&
				(otherDriverName === '' ||
					otherDriverMobileNo === '' ||
					otherDriverCardIdNo === '' ||
					otherCarNo === '')
			) {
				return false;
			} else {
				this.setState({
					errors: {}
				});
				return true;
			}
		}
	};
	onCustomRadioStudentDocs = (e, fieldName, set) => {
		switch (e.target.value) {
			case 'Yes':
				this.setState({
					[e.target.name]: e.target.value
				});
				break;
			case 'No':
				this.setState({
					[fieldName]: set,
					[e.target.name]: e.target.value
				});
				break;
			default:
				break;
		}
	};
	onCustomAdmissionChange = e => {
		const { registrationNo, parentId, gender } = this.state;
		console.log(e.target.value);

		if (e.target.value === 'male' || e.target.value === 'female') {
			this.setState({
				gender: e.target.value,
				admissionNo:
					registrationNo +
					'-' +
					parentId +
					'-' +
					(e.target.value === 'male'
						? 'M'
						: e.target.value === 'female'
						? 'F'
						: '(F/M)') +
					'-' +
					new Date().getUTCFullYear()
			});
		} else {
			this.setState({
				admissionNo:
					registrationNo +
					'-' +
					(e.target.value !== '' ? e.target.value : 'Fcode') +
					'-' +
					gender.charAt(0).toUpperCase() +
					'-' +
					new Date().getUTCFullYear(),
				parentId: e.target.value
			});
		}
		console.log(this.state);
	};

	IsObjectEmpty = Obj => {
		let checkFlag = false;
		for (let value of Object.values(Obj)) {
			if (Object.is(value, '')) {
				checkFlag = false; // John, then 30
				break;
			}
			checkFlag = true;
		}
		console.log(checkFlag);
		return checkFlag;
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.dummySample !== nextProps.getClassSectionGrade.Data) {
			return {
				dummySample: { ...nextProps.getClassSectionGrade.Data },
				campusData: nextProps.campusData,
				sessionData: nextProps.sessionData
			};
		}
		return null;
	}
	componentDidMount() {
		const { registrationNo, gender, parentId } = this.state;
		Promise.all([
			this.props.sharedAction.gAllData(
				'/api/StudentAdmissionTransport',
				this.props.actions.getAllRoutesDetails
			),
			this.props.sharedAction.getDataWithoutDispatch('/api/RegistrationCount')
		])
			.then(success => {
				console.log(success);

				this.setState({
					registrationNo: success[1],
					admissionNo:
						success[1] +
						'-' +
						(parentId !== '' ? parentId : 'FCode') +
						'-' +
						(gender === 'male' ? 'M' : gender === 'female' ? 'F' : '(F/M)') +
						'-' +
						new Date().getUTCFullYear()
				});
			})
			.catch(error => {
				console.log(error);
			});

		// if(this.props.getClassSectionGrade.apiFlag) {
		Promise.all([
			this.props.sharedAction.getAllData(
				'/api/getclasssectiongrade',
				this.props.actions.setAllClassSectonGrade,
				this.props.actions.updateSectionGradeLoader
			),
			this.props.sharedAction.gAllData(
				'/api/Session',
				this.props.actions.getCurrentSession
			),
			this.props.sharedAction.gAllData(
				'/api/CampusDetail',
				this.props.actionCampus.setAllCampusInRedux
			)
		])
			.then(success => {
				this.setState({
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});
			});
		// }
		// }
		this.stepper = new Stepper(document.querySelector('#stepper1'), {
			animation: true
		});
	}
	onFileProfileChange = e => {
		var file = e.target.files[0];

		this.setState({
			studentImage: window.URL.createObjectURL(file),
			imageFile: e.target.files[0]
		});
	};
	imageChange = e => {
		e.preventDefault();
		try {
			let reader = new FileReader();
			let file = e.target.files[0];

			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result
				});
			};

			reader.readAsDataURL(file);
		} catch (e) {
			console.log(e);
		}
	};
	onClickDeleteItem = arrName => {
		let getStateArr = this.state[`${arrName}`];
		let getDeleteLength = getStateArr.length - 1;
		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			this.setState({
				[arrName]: [...getNewDetails]
			});
		}
	};
	onClickAddItem = (arrName, obj) => {
		let getStateArr = this.state[`${arrName}`];
		getStateArr = [...getStateArr, obj];
		console.log(arrName);
		this.setState({
			[arrName]: getStateArr
		});
	};
	updateSpecialArrObjOnChange = (e, index, arrName) => {
		const changeArrOfObj = this.state[`${arrName}`];
		changeArrOfObj[index][e.target.name] = e.target.value;
		// arrName = changeArrOfObj;
		// console.log(arrName);
		this.setState({
			[arrName]: changeArrOfObj
		});
	};
	onChangeText = e => {
		if (e.target.name === 'section') {
			const { grade } = this.state.dummySample;
			let getSectionName = grade.filter(
				item => item.sectionId === parseInt(e.target.value)
			)[0].section;

			this.setState({
				section: getSectionName,
				sectionId: e.target.value
			});
		} else {
			console.log(e.target.value);

			this.setState({
				[e.target.name]: e.target.value
			});
		}
	};
	studentImgUpload = (studentId, file) => {
		let data = new FormData();
		data.append('file', file[0], file[0].name);
		data.append('accountId', this.props.name);
		data.append('studentId', studentId);
		data.append('accountType', this.props.role);
		let token = '';
		if (localStorage.jwtToken) {
			token += localStorage.getItem('jwtToken') + token;
		}
		var Headconfig = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: 'Bearer ' + token
			}
		};
		// var bodyParameters = { data }

		return new Promise(resolve => {
			return Axios.post(
				'https://embassyapp.linkit.org.uk/api/StudentImageUpload',
				data,
				Headconfig
			)
				.then(success => {
					console.log(success);

					resolve(success);
				})
				.catch(error => {
					console.log(error);
				});
		});
	};
	fileUpload(studentId, file, ...moreFiles) {
		const {
			passportFather,
			passportMother,
			idCardFather,
			idCardMother,
			dobCertificate,
			transferCertificate,
			ministryVacination,
			medicalHistory,
			passportCopy,
			idCardCopy,
			lastResult,
			testResult
		} = this.state;
		let imageData = new FormData();
		imageData.append('file', file, file.name);
		imageData.append('accountId', this.props.name);
		imageData.append('studentId', studentId);
		imageData.append('accountType', this.props.role);

		let data = new FormData();
		data.append('studentId', studentId);

		//parent docs
		if (passportFather === 'Yes') {
			data.append('filePath_passportFather', moreFiles[9], moreFiles[9].name);
			data.append('passportFather', passportFather + '_' + moreFiles[9].name);
		} else {
			data.append('passportFather', passportFather);
		}
		if (passportMother === 'Yes') {
			data.append('filePath_passportMother', moreFiles[10], moreFiles[10].name);
			data.append('passportMother', passportMother + '_' + moreFiles[10].name);
		} else {
			data.append('passportMother', passportMother);
		}
		if (idCardFather === 'Yes') {
			data.append('filePath_idCardFather', moreFiles[3], moreFiles[3].name);
			data.append('idCardFather', idCardFather + '_' + moreFiles[3].name);
		} else {
			data.append('idCardFather', idCardFather);
		}
		if (idCardMother === 'Yes') {
			data.append('filePath_idCardMother', moreFiles[4], moreFiles[4].name);
			data.append('idCardMother', idCardMother + '_' + moreFiles[4].name);
		} else {
			data.append('idCardMother', idCardMother);
		}
		// parent docs

		data.append('accountId', this.props.name);
		data.append('accountType', this.props.role);
		let studData = new FormData();
		studData.append('studentId', studentId);

		if (dobCertificate === 'Yes') {
			studData.append(
				'filePath_dobCertificate',
				moreFiles[0],
				moreFiles[0].name
			);
			studData.append(
				'dobCertificates',
				dobCertificate + '_' + moreFiles[0].name
			);
		} else {
			studData.append('dobCertificates', dobCertificate);
		}
		if (transferCertificate === 'Yes') {
			studData.append(
				'filePath_transferCertificate',
				moreFiles[1],
				moreFiles[1].name
			);
			studData.append(
				'transferCertificates',
				transferCertificate + '_' + moreFiles[1].name
			);
		} else {
			studData.append('transferCertificates', transferCertificate);
		}
		if (ministryVacination === 'Yes') {
			studData.append(
				'filePath_ministryVacination',
				moreFiles[7],
				moreFiles[7].name
			);
			studData.append(
				'ministryVacinations',
				ministryVacination + '_' + moreFiles[7].name
			);
		} else {
			studData.append('ministryVacinations', ministryVacination);
		}
		if (medicalHistory === 'Yes') {
			studData.append(
				'filePath_medicalHistory',
				moreFiles[6],
				moreFiles[6].name
			);
			studData.append(
				'medicalHistorys',
				medicalHistory + '_' + moreFiles[6].name
			);
		} else {
			studData.append('medicalHistorys', medicalHistory);
		}
		if (passportCopy === 'Yes') {
			studData.append('filePath_passportCopy', moreFiles[8], moreFiles[8].name);
			studData.append('passportCopys', passportCopy + '_' + moreFiles[8].name);
		} else {
			studData.append('passportCopys', passportCopy);
		}
		if (lastResult === 'Yes') {
			studData.append('filePath_lastResult', moreFiles[5], moreFiles[5].name);
			studData.append('lastResults', lastResult + '_' + moreFiles[5].name);
		} else {
			studData.append('lastResults', lastResult);
		}
		if (testResult === 'Yes') {
			studData.append('filePath_testResult', moreFiles[11], moreFiles[11].name);
			studData.append('testResults', testResult + '_' + moreFiles[11].name);
		} else {
			studData.append('testResults', testResult);
		}
		if (idCardCopy === 'Yes') {
			studData.append('filePath_idCardCopy', moreFiles[2], moreFiles[2].name);
			studData.append('idCardCopys', idCardCopy + '_' + moreFiles[2].name);
		} else {
			studData.append('idCardCopys', idCardCopy);
		}

		// data.append('dobCertificate', moreFiles[0], dobCertificate);
		// data.append('transferCertificate', moreFiles[1], transferCertificate);
		// data.append('ministryVacination', moreFiles[7], ministryVacination);
		// data.append('medicalHistory', moreFiles[6], medicalHistory);
		// data.append('passportCopy', moreFiles[8], passportCopy);
		// data.append('lastResult', moreFiles[5], lastResult);
		// data.append('testResult', moreFiles[11], testResult);
		// data.append('idCardCopy', moreFiles[2], idCardCopy);

		// for (let i = 0; i < moreFiles.length; i++) {
		//
		//     if (moreFiles[i] === '') {
		//         continue;
		//     } else {
		//         data.append(`filePath${i}`, moreFiles[i], moreFiles[i].name);
		//     }
		// }
		let token = '';
		if (localStorage.jwtToken) {
			token += localStorage.getItem('jwtToken') + token;
		}
		var Headconfig = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: 'Bearer ' + token
			}
		};
		// var bodyParameters = { data }

		return Promise.all([
			Axios.post(
				`http://www.https://embassyapp.linkit.org.uk/api/ParentDocument`,
				data,
				Headconfig
			),
			Axios.post(
				`http://www.https://embassyapp.linkit.org.uk/api/StudentDocument`,
				studData,
				Headconfig
			),
			Axios.post(
				'http://www.https://embassyapp.linkit.org.uk/api/StudentImageUpload',
				imageData,
				Headconfig
			)
		])
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}
	onSubmit = e => {
		e.preventDefault();
		const {
			// alll docs including student docs and parents docs
			filePath,
			filePath1,
			filePath_idCardCopy,
			filePath_idCardFather,
			filePath_idCardMother,
			filePath_lastResult,
			filePath_medicalHistory,
			filePath_ministryVacination,
			filePath_passportCopy,
			filePath_passportFather,
			filePath_passportMother,
			filePath_testResult,

			//end of docs
			//parentDocs
			passportFather,
			passportMother,
			idCardFather,
			idCardMother,
			//student transport details
			transportStatus,
			routeId,
			driverId,
			vehicleNo,
			driverName,
			driverLicense,
			driverMobileNo,
			driverIdCardCopy,
			driverIdCardNo,
			parentOrByWalk,
			transportType,
			otherDriverName,
			otherCarNo,
			otherDriverCardIdNo,
			otherDriverMobileNo,
			//student other details
			otherActivity,
			sports,
			//widthdrawdetails
			dateLeaving,
			reason,
			classLeft,
			//previousSchoolDetails
			previousSchoolDetails,
			//medical Condition
			threatConditionReason,
			needMedicationReason,
			otherMedicationReason,
			threatCondition,
			needMedication,
			otherMedication,
			//student siblings form
			siblingsDetails,
			// student document Info
			dobCertificate,
			transferCertificate,
			ministryVacination,
			medicalHistory,
			passportCopy,
			idCardCopy,
			lastResult,
			testResult,
			// student parent Info
			parentIdCardNo,
			parentCNIC,
			parentEmailId,
			parentMobileNo,
			emergencyContact,
			income,
			occupation,
			garduainName,
			motherName,
			fatherPName, // change

			// student Address Details fields
			wayNo,
			area,
			city,
			streetNo,
			houseNo,
			studentEmailId,
			address,
			studentIdCardNo,
			//Student Admission form fields
			file,
			registrationNo,
			firstName,
			lastName,
			fatherName,
			rollNo,
			admissionNo,
			admissionType,
			currentSessionId,
			language,
			parentName,
			gender,
			dob,
			age,
			campusId,
			parentId,
			gradeId,
			placeOfBirth,
			religion,
			campusName,
			className,
			grade,
			section,
			classId,
			userName,
			password,
			nationality,
			status,
			gradeName,
			sectionId,
			country,
			fullNameArabic
		} = this.state;
		//data sending structure
		if (!this.isValidstudentAddForm('studentTransportForm')) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else {
			const Data = {
				// classId , sectionId ,
				StudentAdmissionForms: {
					fullNameArabic,
					firstName,
					lastName,
					fatherName,
					registrationNo,
					rollNo,
					admissionNo,
					admissionType,
					currentSessionId,
					language,
					parentId,
					gender,
					dob,
					age,
					placeOfBirth,
					nationality,
					religion,
					gradeId,
					section,
					sectionId,
					className,
					classId,
					userName,
					password,
					campusName,
					campusId,
					resultStatus: 'dsf',
					status,
					accountId: this.props.name,
					accountType: this.props.role
				},
				StudentAddresss: {
					address,
					houseNo,
					studentIdCardNo,
					studentEmailId,
					wayNo,
					streetNo,
					area,
					city,
					country
				},
				ParentInfos: {
					fatherName: fatherPName,
					motherName,
					garduainName,
					occupation,
					income,
					emergencyContact,
					parentMobileNo,
					parentEmailId,
					parentIdCardNo
				},
				StudentDocs: {
					dobCertificate,
					transferCertificate,
					ministryVacination,
					medicalHistory,
					passportCopy,
					idCardCopy,
					lastResult,
					testResult
				},
				ParentDocs: {
					passportFather,
					passportMother,
					idCardFather,
					idCardMother
				},
				MedicalConditions: {
					threatConditionReason,
					needMedicationReason,
					otherMedicationReason,
					threatCondition,
					needMedication,
					otherMedication
				},
				StudentSiblingDetails: [...siblingsDetails],
				PreviousSchoolDetails: [...previousSchoolDetails],
				OtherInformations: {
					sports,
					otherActivity
				},
				WidthdrawDetails: {
					dateLeaving,
					classLeft,
					reason
				},
				StudentTransports: {
					otherDriverName,
					otherCarNo,
					otherDriverCardIdNo,
					otherDriverMobileNo,
					transportStatus,
					routeId,
					driverId,
					vehicleNo,
					driverName,
					driverMobileNo,
					driverIdCardNo,
					driverIdCardCopy,
					parentOrByWalk,
					transportType
				}
			};
			this.props.sharedAction
				.addRecord(
					'/api/StudentAdmissionForm',
					Data,
					this.props.actions.addStudentForm
				)
				.then(success => {
					console.log(success.StudentAdmissionForms.studentAdmissionFormId);

					this.setState({
						isDoc: true,
						studentId: success.StudentAdmissionForms.studentAdmissionFormId
					});
				})
				.catch(error => {
					console.log(error);
				});
		}
	};
	fileSpecificOnChange = e => {
		this.setState({
			filePath1: e.target.files[0]
		});
	};
	fileOnChange = e => {
		console.log(e.target.files);
		this.setState({
			[e.target.name]: e.target.files[0]
		});
	};
	onChangeTransportModule = e => {
		this.setState({
			transportStatus: e.target.value
		});
		console.log(this.state.transportStatus);
	};
	skipStep = e => {
		this.stepper.next();

		this.setState({
			error: false
			// valid: true,
		});
		this.onNextClick(e, '');
	};
	onNextClick = (e, formType) => {
		if (
			Object.is(formType, 'studentAddForm') &&
			!this.isValidstudentAddForm('studentAddForm')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'StudentAdress') &&
			!this.isValidstudentAddForm('StudentAdress')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'studentParentInfo') &&
			!this.isValidstudentAddForm('studentParentInfo')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'studentDocument') &&
			!this.isValidstudentAddForm('studentDocument')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'studentSiblings') &&
			!this.isValidstudentAddForm('studentSiblings')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'studentMedicalCondition') &&
			!this.isValidstudentAddForm('studentMedicalCondition')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'studentPreviousSchoolDetails') &&
			!this.isValidstudentAddForm('studentPreviousSchoolDetails')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'StudentWidthDetails') &&
			!this.isValidstudentAddForm('StudentWidthDetails')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'studentOtherDetails') &&
			!this.isValidstudentAddForm('studentOtherDetails')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'studentTransportForm') &&
			!this.isValidstudentAddForm('studentTransportForm')
		) {
			this.handleScroll(e);

			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'ParentDocsForm') &&
			!this.isValidstudentAddForm('ParentDocsForm')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else {
			this.handleScroll(e);
			const { Stepes } = this.state;
			const Step = [...Stepes];
			let cloneComStep = [];
			cloneComStep.push(Step.shift());
			this.setState({
				Stepes: Step,
				comStep: cloneComStep
			});
			if (formType !== '') {
				this.stepper.next();
				this.setState({
					error: false,
					valid: true
				});
			}
		}
	};
	onChangeFormFlag = () => {
		this.setState({
			valid: false
		});
	};
	onPreviousClick = e => {
		this.stepper.previous();
		this.handleScroll(e);
	};
	splitingData = item => {
		let getSplittingObj = item.split(' ');
		return getSplittingObj[1];
	};

	render() {
		const docSection = (
			<React.Fragment>
				<div id="stepper1" style={{ display: 'block' }}>
					{/* ss */}
				</div>
				<div className="animated fadeIn">
					<StudentDocumentSection {...this.props} id={this.state.studentId} />
				</div>
			</React.Fragment>
		);
		const { regNo, Stepes, isDoc } = this.state;
		console.log(this.state['siblingsDetails']);
		console.log(this.props);
		const { errors } = this.state;

		return !isDoc ? (
			<div className={'table-responsive'}>
				<Prompt
					when={this.state.valid !== true}
					message="Leaving this form will lose your data"
				/>
				<div id="stepper1" class="bs-stepper">
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<div ref={this.main}>
							<div class="bs-stepper-header">
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										flexWrap: 'wrap',
										paddingRight: 20
									}}
								>
									{Stepes.map((item, index) => (
										<div>
											<div
												class="step"
												data-target={`#test-l-${this.splitingData(item)}`}
											>
												<button class="step-trigger">
													<div
														style={{ display: 'flex', flexDirection: 'column' }}
													>
														<div>
															<span
																class="bs-stepper-circle"
																style={{ backgroundColor: '#95DCCE' }}
															>
																{this.splitingData(item)}
															</span>
														</div>
														<div>
															<span
																class="bs-stepper-label"
																style={{ color: '#A0A6AB' }}
															>
																{item}
															</span>
														</div>
													</div>
												</button>
											</div>
											{/*<div style={{fontSize:30,textAlign:'center'}} >|</div>*/}
										</div>
									))}
								</div>
							</div>
						</div>
						<div style={{ width: '100%' }}>
							<div style={{ alignSelf: 'center' }} class="bs-stepper-content">
								<form onSubmit={this.onSubmit}>
									{this.state.error ? (
										<p style={{ color: 'red' }}>
											Please fill the Form Properly!
										</p>
									) : (
										''
									)}

									<div id="test-l-1" class="content">
										<LayoutWrapper formType="Student Admission Form" step="1">
											<div>
												<div>
													<StudentAdmission
														sType={this.state.sType}
														imageChange={this.imageChange}
														onChangeDate={this.onChangeDate}
														onChangeClassName={this.onChangeClassName}
														onGeneralSelectChangeHandler={
															this.onGeneralSelectChangeHandler
														}
														onHandleTextChange={this.onChangeText}
														stateData={this.state}
														typeForm={this.props.typeForm}
														campusData={this.props.campusData}
														sessionData={this.props.sessionData}
														onCustomAdmissionChange={
															this.onCustomAdmissionChange
														}
														fileOnChange={this.fileOnChange}
														onFileProfileChange={this.onFileProfileChange}
														errors={errors}
													/>
												</div>
												<div style={{ width: '70%' }}>
													<StepperButtonsCom
														formType={'studentAddForm'}
														onPreviousClick={this.onPreviousClick}
														onNextClick={this.onNextClick}
													/>
												</div>
											</div>
										</LayoutWrapper>
									</div>

									<div id="test-l-2" class="content">
										<LayoutWrapper formType="Student Address" step="2">
											<StudentAdress
												onChangeFormFlag={this.onChangeFormFlag}
												onHandleTextChange={this.onChangeText}
												stateData={this.state}
												errors={errors}
											/>
											<StepperButtonsCom
												formType={'StudentAdress'}
												onPreviousClick={this.onPreviousClick}
												onNextClick={this.onNextClick}
											/>
										</LayoutWrapper>
									</div>
									<div id="test-l-3" class="content">
										<LayoutWrapper formType="Parent Information" step="3">
											<ParentInfo
												onHandleTextChange={this.onChangeText}
												stateData={this.state}
											/>
											<StepperButtonsCom
												formType={'studentParentInfo'}
												onPreviousClick={this.onPreviousClick}
												onNextClick={this.onNextClick}
											/>
										</LayoutWrapper>
										{/*<button class="btn btn-primary" onClick={(e)=>this.onNextClick(e,'studentParentInfo')}>Next</button>*/}
										{/*<button class="btn btn-primary" onClick={this.onPreviousClick}>Previous</button>*/}
									</div>

									<div id="test-l-4" class="content">
										<LayoutWrapper formType="Student Siblings Form" step="4">
											<StudentSiblingDetail
												onHandleTextChange={this.onChangeText}
												stateData={this.state}
												updateSpecialArrObjOnChange={
													this.updateSpecialArrObjOnChange
												}
												onClickAddItem={this.onClickAddItem}
												onClickDeleteItem={this.onClickDeleteItem}
											/>
											<StepperButtonsCom
												formType={'studentSiblings'}
												onPreviousClick={this.onPreviousClick}
												onNextClick={this.onNextClick}
												skipStep={this.skipStep}
											/>
										</LayoutWrapper>

										{/*<button class="btn btn-primary" onClick={(e)=>this.onNextClick(e,'studentSiblings')}>Next</button>*/}
										{/*<button class="btn btn-primary" onClick={this.onPreviousClick}>Previous</button>*/}
									</div>
									<div id="test-l-5" class="content">
										<LayoutWrapper formType="Medical Condition" step="5">
											<MedicalCondition
												onHandleTextChange={this.onChangeText}
												stateData={this.state}
											/>
											<StepperButtonsCom
												formType={'studentMedicalCondition'}
												onPreviousClick={this.onPreviousClick}
												onNextClick={this.onNextClick}
											/>
										</LayoutWrapper>
									</div>
									<div id="test-l-6" class="content">
										<LayoutWrapper formType="Previous School Details" step="6">
											<PreviousSchoolDetails
												onHandleTextChange={this.onChangeText}
												stateData={this.state}
												updateSpecialArrObjOnChange={
													this.updateSpecialArrObjOnChange
												}
												onClickAddItem={this.onClickAddItem}
												onClickDeleteItem={this.onClickDeleteItem}
												skipStep={this.skipStep}
											/>
											<StepperButtonsCom
												formType={'studentPreviousSchoolDetails'}
												onPreviousClick={this.onPreviousClick}
												onNextClick={this.onNextClick}
												skipStep={this.skipStep}
											/>
										</LayoutWrapper>
										{/*<button class="btn btn-primary" onClick={(e)=>this.onNextClick(e,'studentPreviousSchoolDetails')}>Next</button>*/}
										{/*<button class="btn btn-primary" onClick={this.onPreviousClick}>Previous</button>*/}
									</div>
									<div id="test-l-7" class="content">
										<LayoutWrapper formType="Widthdraw Detail" step="7">
											<WidthdrawDetail
												onChangeDate={this.onChangeDate}
												classData={this.state && this.state.dummySample}
												onHandleTextChange={this.onChangeText}
												stateData={this.state}
											/>
											<StepperButtonsCom
												formType={'StudentWidthDetails'}
												onPreviousClick={this.onPreviousClick}
												onNextClick={this.onNextClick}
											/>
										</LayoutWrapper>
										{/*<button class="btn btn-primary" onClick={(e) => this.onNextClick(e,'StudentWidthDetails')}>Next</button>*/}
										{/*<button class="btn btn-primary" onClick={this.onPreviousClick}>Previous</button>*/}
									</div>
									<div id="test-l-8" class="content">
										<LayoutWrapper formType="Other Information" step="8">
											<OtherInformation
												onHandleTextChange={this.onChangeText}
												stateData={this.state}
												data={sports}
											/>
											<StepperButtonsCom
												formType={'studentOtherDetails'}
												onPreviousClick={this.onPreviousClick}
												onNextClick={this.onNextClick}
											/>
										</LayoutWrapper>
										{/*<button class="btn btn-primary"*/}
										{/*onClick={(e) => this.onNextClick(e, 'studentOtherDetails')}>Next*/}
										{/*</button>*/}
										{/*<button class="btn btn-primary" onClick={this.onPreviousClick}>Previous</button>*/}
									</div>
									<div id="test-l-9" class="content">
										<LayoutWrapper formType="Student Transport" step="9">
											<StudentTransport
												onHandleTextChange={this.onChangeText}
												onCustomTranportTextChange={
													this.onChangeTransportModule
												}
												stateData={this.state}
												routesData={this.props.admissionState.getData}
												onCustomChange={this.onChangeRoutesName}
												onChangeTransportStatus={this.onChangeTransportStatus}
												onChangeNoOptions={this.onChangeNoOptions}
											/>

											<div style={{ marginBottom: 5 }} class="text-center">
												<button type={'submit'} class="btnAdd">
													<i
														style={{ marginRight: 4 }}
														class="fas fa-arrow-circle-right"
													></i>
													Submit
												</button>
											</div>
										</LayoutWrapper>
										{/*<button class="btn btn-primary"*/}
										{/*onClick={(e) => this.onNextClick(e, 'studentTransportForm')}>Submit*/}
										{/*</button>*/}
										{/*<button class="btn btn-primary" onClick={this.onPreviousClick}>Previous</button>*/}
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : (
			docSection
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		sharedAction: bindActionCreators(sharedActions, dispatch),
		actionCampus: bindActionCreators(campusAction, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		getClassSectionGrade: state.setClassSectionGradeReducer,
		admissionState: state.setAdmissionReducer,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		campusData: state.setCampusReducer.Data,
		sessionData: state.setAdmissionReducer.getCurrenSession
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdmissionForm);
