import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination'
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import TextInput from '../../components/TextInput/TextInput'
import Button from '../../components/Button';
import { Link } from 'react-router-dom';

let tHead = [
    'Name',
    'Certificate',
    'Actions'
];

let renderFields = [
    'name',
    'certificateTitle',
]

const ViewOtherCertificate = props => {

    const [eId, setEId] = useState(null);
    const [enabled, setEnabled] = useState(false);
    const [name, setName] = useState('')
    const [dId, setDId] = useState(null);
    const [errorMsg, setErrorMsg] = useState('')

    const [modalName, setModalName] = useState('')
    const [certificateId, setCertificateId] = useState('')
    const [certificateTitle, setCertificateTitle] = useState('')
    const [modalData, setModalData] = useState([]);

    const [certificateData, setCertificateData] = useState([]);
    const [certificateListData, setCertificateListData] = useState([]);

    const [isCertificateLoading, cData] = httpWithRequiredSetData(
        '/api/CertificateCategory',
        [],
        data => {
            setCertificateData(data);
        }
    );

    const [searchURL, setSearchURL] = useState(
        '/api/othercertificate?pageNumber=1&pageSize=10&name='
    );

    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});


    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setCertificateListData(data['list']);

        setPaginationData(data['paginationMetadata']);
    });

    const handleClickNextFrwd = () => {
        console.log('pagelength: ', pageNumber.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);
            console.log(currentPage);

            setdisableBPage(false);
        }
    };

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesOtherCertificate(
                    name,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setCertificateListData(success['list']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
            });
    }, [currentPage, /* getdepartmentId, */ customPageSize]);
    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };
    const [msg, setSnackMsg] = useState('');

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const setDeleteID = id => {
        setDId(id);
    };

    const onClickDelete = id => {
        setIsLoading(true)

        props.sharedActions
            .deleteRecordWithoutDispatch(
                '/api/OtherCertificate/',
                dId
            )
            .then(success => {
                // if (success.status === 200) {
                let getNewFilterArray = certificateListData.filter(item => item.id !== dId);
                setSnackMsg('Remove Successfully!');
                setCertificateListData(getNewFilterArray);
                setIsLoading(false)
                props.snackbar();
                setDId(null);
                // }
            })
            .catch(error => {
                setIsLoading(false)
                console.log(error);
            });
    };

    const onHandleTextChange = (e) => {
        setName(e.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setErrorMsg(true)
        } else {
            setIsLoading(true)
            props.sharedActions
                .getDataWithoutDispatch(
                    general.condtionalServicesOtherCertificate(
                        name,
                        currentPage,
                        customPageSize
                    )
                )
                .then(success => {
                    setCertificateListData(success['list']);
                    setIsLoading(false);
                    let pageNumber = [];
                    for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                        pageNumber.push(i);
                    }
                    setPageNumber(pageNumber);
                    setPaginationData(success['paginationMetadata']);
                })
                .catch(error => {
                    setIsLoading(false);
                    setCertificateListData([])
                });

        }
    }

    const btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    const onEditClick = itemId => {
        onTakeItem(itemId);
        setEnabled(false)
    };

    const onHandleModalTextChange = (e) => {
        setModalName(e.target.value)
    }

    const onTakeItem = itemId => {
        setModalData([])

        let getData = certificateListData.filter(item => item.id === itemId);
        setModalData(getData)
        setEId(getData[0].id)
        setModalName(getData[0].name)
    };

    const onHandleSelectChange = (e) => {
        let title = certificateData.filter(item => parseInt(item.id) === parseInt(e));
        setCertificateId(e)
        setCertificateTitle(title[0].certificateTitle)
    }

    const onHandleSubmit = (e) => {
        e.preventDefault();

        const data = {
            name: modalName,
            certificateId,
        }

        modalData[0].name = modalName
        modalData[0].certificateId = certificateId
        modalData[0].certificateTitle = certificateTitle

        props.sharedActions
            .editRecordWithoutDispatch(
                '/api/OtherCertificate/',
                eId,
                data
            )
            .then(
                certificateListData.forEach((item, index) => {
                    if (item.id === eId) {
                        certificateListData.splice(index, 1, modalData[0])
                    }
                }),
                setSnackMsg('Edited Record Successfully!'),
                setEId(''),
                setModalData([]),
                props.snackbar()
            )
        $('#myModal').modal('toggle'); //or  $('#IDModal').modal('hide');
    }

    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        borderColor: Colors.SEA_GREEN_THEME
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                            padding: 2,
                            borderColor: Colors.SEA_GREEN_THEME
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
                <td
                    style={{
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        padding: 2,
                        borderColor: Colors.SEA_GREEN_THEME
                    }}
                >
                    <Link to={`/dashboard/OtherCertificateTemplate1/${item.id}`}>
                        <button
                            id="firstbutton"
                            // onClick={() => this.onEditClick(item.id)}
                            onMouseOver={btnTechHover()}
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <a href="#" data-toggle="tooltip" title="View!">
                                <i style={{ color: '#01AC8A' }} class="fas fa-certificate"></i>
                            </a>
                        </button>
                    </Link>

                    <Link to={`/dashboard/OtherCertificateTemplate2/${item.id}`}>
                        <button
                            id="firstbutton"
                            // onClick={() => this.onEditClick(item.id)}
                            onMouseOver={btnTechHover()}
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <a href="#" data-toggle="tooltip" title="View!">
                                <i style={{ color: '#01AC8A' }} class="fas fa-certificate"></i>
                            </a>
                        </button>
                    </Link>

                    <a href="#" data-toggle="tooltip" title="Edit!">
                        <button
                            id="firstbutton"
                            onClick={() => onEditClick(item.id)}
                            onMouseOver={btnTechHover()}
                            data-toggle="modal"
                            data-target="#myModal"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <i class="fas fa-pen"></i>
                        </button>
                    </a>

                    <a href="#" data-toggle="tooltip" title="Delete!">
                        <button
                            onClick={() => setDeleteID(item.id)}
                            data-toggle="modal"
                            data-target="#myModal1"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                background: 'none',
                                marginLeft: 5,
                                marginBottom: 5
                            }}
                        >
                            <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                        </button>
                    </a>
                </td>
            </tr>
        ));

    return (
        <div>
            <SnackBar msg={msg} />
            <div class="modal" id="myModal1">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Confirm
							</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
							</button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Are you sure ?</h4>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}
                                >
                                    <div>
                                        <button
                                            // disabled={this.state.enabled}
                                            data-dismiss="modal"
                                            onClick={() => onClickDelete(dId)}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                        >
                                            Yes
										</button>
                                    </div>
                                    <div>
                                        <button
                                            data-dismiss="modal"
                                            // disabled={this.state.enabled}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                        >
                                            No
										</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Details
								</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
								</button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={e => onHandleSubmit(e)}>
                                {modalData &&
                                    modalData.map((item, index) => (
                                        <div key={index}>
                                            <fieldset disabled={!enabled}>

                                                <TextInput
                                                    feildName={'modalName'}
                                                    inputLabelName={'Name'}
                                                    onHandleChange={onHandleModalTextChange}
                                                    errors={{}}
                                                    stateData={
                                                        { modalName: modalName }
                                                    }
                                                    // iconClassName={'fas fa-percentage'}
                                                    placeholder={'Name'}
                                                    isValidte={false}
                                                />

                                                <SelectionInput
                                                    feildName={'certificateId'}
                                                    selectName={'Certificate'}
                                                    onHandleChange={e => onHandleSelectChange(e.target.value)}
                                                    errors={{}}
                                                    optionsArrys={certificateData}
                                                    selectedText={'Certificate'}
                                                    stateData={{
                                                        certificateId: certificateId
                                                    }}
                                                    // iconClassName={'fas fa-building'}
                                                    optionType="dynamicWithPropIdAndName"
                                                    property={'certificateTitle'}
                                                    propertyId={'id'}
                                                    successflag={'successflag'}
                                                    isValidte={false}
                                                // editUse={'staffEdit'}
                                                />

                                            </fieldset>
                                            <br />
                                            <br />
                                            <button
                                                disabled={enabled}
                                                onClick={() => setEnabled(true)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                Edit
												</button>
                                            <button
                                                style={{ marginLeft: 5 }}
                                                disabled={!enabled}
                                                type="submit"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                Save
												</button>
                                        </div>
                                    ))}
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                style={{ background: '#00435D' }}
                                class="btn btn-danger"
                                data-dismiss="modal"
                            >
                                Close
								</button>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead">
                            {' '}
                            Certificate Details{' '}
                        </h2>
                    </div>
                    <div
                        style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                    >
                        <Pagination
                            disableBPage={disableBPage}
                            disablepage={disablepage}
                            currentPage={currentPage}
                            pageNumber={pageNumber}
                            handleClickNext={handleClickNext}
                            handleClickNextBack={handleClickNextBack}
                            handleClickNextFrwd={handleClickNextFrwd}
                        />
                        <div
                            style={{
                                width: 'fit-content',
                                marginTop: -34,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginLeft: 'auto'
                            }}
                        >
                            <SelectionInput
                                selectName="Entries"
                                selectFont={14}
                                selectFontWeight={'bold'}
                                newFlag="customWidth"
                                feildName="customPageSize"
                                selectedText={'Page Entries'}
                                optionsArrys={['10', '20', '30', '40', '50']}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    customPageSize: customPageSize
                                }}
                                optionType="static"
                                onHandleChange={onChangePageSize}
                                useFlag={false}
                            />
                        </div>
                    </div>
                </div>
                <CustomFilterSectionLayout displayDirection="column">
                    <div
                        style={{
                            width: '30%',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <React.Fragment>

                            <TextInput
                                customWidth={true}
                                enterWidth={'110%'}
                                feildName={'name'}
                                inputLabelName={'Name'}
                                onHandleChange={onHandleTextChange}
                                errors={{}}
                                stateData={{ name: name }}
                                // iconClassName={'fas fa-percentage'}
                                placeholder={'Name'}
                                isValidte={false}
                            />

                            <Button
                                width="100%"
                                buttonClass="customButton"
                                btnName="Submit"
                                loaderBmargin={5}
                                stateData={{}}
                                marginTop={55}
                                textColor={'white'}
                                onHandleSubmit={onSubmit}
                                margin={'0 auto'}
                            />

                        </React.Fragment>
                    </div>
                </CustomFilterSectionLayout>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) :
                certificateListData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Data</strong>
                </div>
                    : (
                        <div className="table-responsive">
                            <table class="table table-hover">
                                <thead style={{ background: '#01AC8A', color: 'white' }}>
                                    <tr>
                                        <th scope="col">#</th>
                                        {tHead.map(item => (
                                            <th
                                                style={{
                                                    border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                    borderColor: Colors.WHITE
                                                }}
                                                scope="col"
                                            >
                                                {item}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{bodyRendering(certificateListData)}</tbody>
                            </table>
                        </div>
                    )}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    null,
    mapDispatchToProps
)(ViewOtherCertificate);