import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PostActions from '../../Actions/Post';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import ShowData from '../Department/ShowData';

class SearchStaffByPost extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			vehicleNo: '',
			capacity: '',
			transportType: '',
			enabled: false,
			id: null,
			select: [],
			routeId: '',
			routeName: '',
			startingServiceKm: '',
			midServiceKm: '',
			endingServiceKm: '',
			vehicleDateOfReg: '',
			vehicleDateOfRegExpiry: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			increament: 1,
			selectClassData: '',
			campusId: '',
			departmentId: '',
			departments: [],
			postId: '',
			Post: [],
			btnLoad: false,
			errorMsg: false,
			checking: [],
			filecheck: false
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.adminData !== nextProps.AllTransportData ||
			prevState.selectClassData !== nextProps.selectData
		) {
			return {
				adminData: nextProps.AllTransportData,
				selectClassData: nextProps.selectData
			};
		}
		return null;
	}
	onSelectChange = e => {
		console.log(e.target.value);

		const department = this.state.selectClassData.department;
		let check = department.filter(item => item.campusId == e.target.value);
		console.log(check);

		this.setState({
			departments: check,
			campusId: e.target.value
		});
	};
	onSectionChange = e => {
		console.log(e.target.value);
		const Posts = this.state.selectClassData.post;
		let check2 = Posts.filter(item => item.departmentId == e.target.value);
		console.log(check2);
		this.setState({
			Post: check2,
			departmentId: e.target.value
		});
	};
	onPostChange = e => {
		console.log(e.target.value);
		this.setState({
			postId: e.target.value
		});
	};
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.gAllData(
				'/api/CampusDepartment',
				this.props.PostActions.getPostForSearchStaff
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}
	onSearchStudent = e => {
		this.setState({
			btnLoad: true
		});
		const { postId, campusId, departmentId } = this.state;
		if (
			Object.is(departmentId, '') ||
			Object.is(campusId, '') ||
			Object.is(postId, '')
		) {
			this.setState({
				errorMsg: true,
				btnLoad: false
			});
		} else {
			this.props.sharedActions
				.getAllData(
					`/api/getStaffByDepartmentPost?departmentId=${this.state.departmentId}&campusId=${this.state.campusId}&postId=${this.state.postId}&pageNumber=1&pageSize=10`,
					this.props.PostActions.setAllSearchStaffThroughPostInRedux,
					this.props.PostActions.updatePostFlag
				)
				.then(success => {
					this.setState({
						isLoading: false,
						btnLoad: false,
						checking: this.state.adminData.paginationMetadata,
						filecheck: true
					});
				})
				.catch(err => {
					this.props.PostActions.updatePostFlag(false);

					this.setState({
						isLoading: false,
						btnLoad: false
					});

					console.log(err);
				});
		}
	};
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};
	paginationRequest = number => {
		console.log(number);

		this.props.sharedActions
			.getAllData(
				`/api/getStaffByDepartmentPost?departmentId=${this.state.departmentId}&campusId=${this.state.campusId}&postId=${this.state.postId}&pageNumber=1&pageSize=10`,
				this.props.PostActions.setAllSearchStaffThroughPostInRedux,
				this.props.PostActions.updatePostFlag
			)
			.then(success => {
				this.setState({
					isLoading: false,
					checking: this.state.adminData.paginationMetadata
				});
			})
			.catch(err => {
				this.props.DepartmentActions.updateDepartmentFlag(false);

				this.setState({
					isLoading: false
				});

				console.log(err);
			});
	};
	render() {
		const { adminData, modalData, select } = this.state;
		console.log('Admin Data', this.state.adminData);
		console.log('Campus Depart Ka Data', this.state.selectClassData);
		const { campus } = this.state.selectClassData;
		const { staff } = this.state.adminData;
		const { paginationMetadata } = this.state.adminData;
		console.log('Pagination Ka Data', paginationMetadata);
		console.log('Pagination Checking Ka Data', this.state.checking);

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<label style={{ color: 'red' }}>No Data</label>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <=
			Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return (
				<li
					class={`page-item ${
						this.state.checking.currentPage == number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={() => this.paginationRequest(number)}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			);
		});
		const MainContent = (
			<div>
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Staff Name</th>
								<th scope="col">Class</th>
								<th scope="col">Department</th>
								<th scope="col">Campus</th>
								<th scope="col">Post</th>
								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>
							<ShowData data={staff} />
						</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				{this.state.errorMsg ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Please Select the Fields!
					</p>
				) : (
					''
				)}
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Search Staff by Post</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								{renderPageNumbers}
								{this.state.filecheck ? (
									<a
										href={`http://https://embassyapp.linkit.org.uk/api/downloadStaffByCampDeptPost?departmentId=${this.state.departmentId}&postId=${this.state.postId}&campusId=${this.state.campusId}`}
										data-toggle="tooltip"
										title="Download File"
									>
										{/* <form action={`http://https://embassyapp.linkit.org.uk/api/downloadStaffByCampDeptPost?departmentId=${this.state.departmentId}&postId=${this.state.postId}&campusId=${this.state.campusId}`}> */}
										<button
											type="submit"
											style={{
												cursor: 'pointer',
												fontSize: 20,
												border: 'none',
												marginLeft: 5,
												background: 'none',
												marginBottom: 5
											}}
										>
											<i
												style={{ color: '#01425e', fontSize: 35 }}
												class="fas fa-file-csv"
											></i>
										</button>
									</a>
								) : (
									''
								)}
							</ul>
							{/* {this.state.filecheck ?  <ul>
                                    <a href="#" data-toggle="tooltip" title="Download File">
                                        <form action={`http://https://embassyapp.linkit.org.uk/api/api/downloadStaffByCampDeptPost?departmentId=${this.state.departmentId}&postId=${this.state.postId}&campusId=${this.state.campusId}`}>
                                            <button type="submit" style={{ cursor: 'pointer', fontSize: 20, border: 'none', marginLeft: 5, background: 'none', marginBottom: 5 }}>
                                                <i style={{ color: '#01425e' }} class="fas fa-file-csv"></i>
                                            </button></form></a></ul> : "" } */}
						</nav>
					</div>
				</div>
				<div class="row" style={{ justifyContent: 'center' }}>
					<div class="col-md-4" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								Campus
							</label>
							<select
								onChange={this.onSelectChange}
								class="custom-select"
								name="className"
								style={{ borderColor: '#01ac8a' }}
							>
								<option selected={this.state.msg ? true : false}>
									Choose Campus...
								</option>
								{campus &&
									campus.map((item, index) => (
										<option key={index} value={`${item.campusId}`}>
											{item.campusName}
										</option>
									))}
							</select>
						</div>
					</div>

					<div class="col-md-4" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								Department
							</label>
							<select
								onChange={this.onSectionChange}
								class="custom-select"
								name="className"
								style={{ borderColor: '#01ac8a' }}
							>
								<option selected={this.state.msg ? true : false}>
									Choose Department...
								</option>
								{this.state.departments.map((item, index) => (
									<option key={index} value={`${item.departmentId}`}>
										{item.departmentName}
									</option>
								))}
							</select>
						</div>
					</div>

					<div class="col-md-4" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								Post
							</label>
							<select
								onChange={this.onPostChange}
								class="custom-select"
								name="className"
								style={{ borderColor: '#01ac8a' }}
							>
								<option selected={this.state.msg ? true : false}>
									Choose Post...
								</option>
								{this.state.Post.map((item, index) => (
									<option key={index} value={`${item.postId}`}>
										{item.postName}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
				<div class="text-center text-md-right">
					<button type="submit" class="btnss" onClick={this.onSearchStudent}>
						Search
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
					</button>
				</div>
				<br />
				{this.props.allTransportState.apiFlag ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		PostActions: bindActionCreators(PostActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllTransportData: state.setPostReducer.SearchData,
		selectData: state.setPostReducer.SelectPost,
		allTransportState: state.setPostReducer,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchStaffByPost);
