import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class HrDashboard extends Component {
	state = {};
	componentDidMount() {
		$(document).click(function(e) {
			if (!$(e.target).is('.panel-body')) {
				$('.collapse').collapse('hide');
			}
		});
	}
	render() {
		return (
			<div>
				<div class="container">
					<div class="modal" id="myModal">
						<div class="modal-dialog">
							<div class="modal-content">
								<div class="modal-header">
									<h4 class="modal-title">
										We Are Sorry{' '}
										<i className="fas fa-skull-crossbones animated fadeIn" />
									</h4>
									<button type="button" class="close" data-dismiss="modal">
										&times;
									</button>
								</div>

								<div class="modal-body">This Module is Under Construction</div>

								<div class="modal-footer">
									<button
										type="button"
										class="btn"
										style={{ backgroundColor: '#01425e', color: 'white' }}
										data-dismiss="modal"
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section class="mb-4">
					<div class="h1-responsive font-weight-bold text-center my-4">
						<img
							src={require('../../Assets/images/HR-04.svg')}
							style={{ width: 500 }}
						/>
					</div>
					<div
						class="row"
						style={{ justifyContent: 'center', textAlign: 'center' }}
					>
						<div class="col-md-8">
							<div class="md-form mb-0">
								<hr class="style-head" />
							</div>
						</div>
					</div>
					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{ textAlign: 'center', justifyContent: 'center' }}
					>
						<div class="col-md-9 mb-md-0 mb-5">
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/SearchStudent">
												<img
													src={require('../../Assets/HRIcons/final-06.svg')}
													data-toggle="collapse"
													data-target="#collapseExample"
													aria-expanded="false"
													aria-controls="collapseExample"
													style={{ width: 150, height: 160 }}
												/>
											</Link>
										</div>
									</div>

									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/HrDevelopmentDashboard">
												<img
													src={require('../../Assets/HRIcons/final-02.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>

									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/FrontDeskDashboard">
												<img
													src={require('../../Assets/HRIcons/final-03.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>
								</div>
								<div class="container" style={{ width: 900, height: 50 }} />

								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/DepartmentDashboard">
												<img
													src={require('../../Assets/HRIcons/final-04.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>

									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/PostDashboard">
												<img
													src={require('../../Assets/HRIcons/final-05.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>

									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/StaffModule">
												<img
													src={require('../../Assets/HRIcons/final-01.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>
								</div>

								<div class="container" style={{ width: 900, height: 50 }} />
								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/Appraisal">
												<img
													src={require('../../Assets/HRIcons/final-07.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/viewAppraisal">
												<img
													src={require('../../Assets/Appraisal/viewappraisal.png')}
													width="80"
													height="100"
												/>
												<h3 style={{ textDecoration: 'none' }}>
													View Appraisal
												</h3>
											</Link>
										</div>
									</div>

									<div class="col-md-4">
										<div class="md-form mb-0">
											{/* <Link to="/dashboard/AddVacancy"> */}
											<img
												src={require('../../Assets/HRIcons/final-09.svg')}
												width="150"
												height="160"
												data-toggle="modal"
												data-target="#myModal"
											/>
											{/* </Link> */}
										</div>
									</div>
									<div class="col-md-4">
										<div class="md-form mb-0">
											{/* <Link to="/dashboard/AddInterview"> */}
											<img
												src={require('../../Assets/HRIcons/final-10.svg')}
												width="150"
												height="160"
												data-toggle="modal"
												data-target="#myModal"
											/>
											{/* </Link> */}
										</div>
									</div>
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/PayRollDashboard">
												<img
													src={require('../../Assets/PayRolDashboard/payroll.svg')}
													width="150"
													height="160"
												/>
											</Link>
										</div>
									</div>
								</div>
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default HrDashboard;
