import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import LoaderWrapper from '../../../components/HOC/LoaderWrapper';
import SnackBar from '../../../components/SnackBar/SnackBar';
import ViewComponent from '../../../components/ViewComponent/ViewComponent';
import useCustomHttpWithDataMiddleware from '../../../hooks/Services/httpWithCustomDataMiddleware';
import useHttp from '../../../hooks/http';
import CustomHeading from '../../../components/CustomHeading';
import { addDataMiddleware, MAIN_HEADING } from '../../../utils/general/index';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';

function ContinouseAssesmentViewList(props) {
	const [snackbarMessage, setMsg] = useState('');
	const [studentData, setStudentData] = useState('');
	const [classId, setClassId] = useState('');
	const [sectionId, setSectionId] = useState('');
	const [studentId, setStudentId] = useState('');

	const [subjectId, setSubjectId] = useState('');
	const [sessionId, setSessionId] = useState('');
	const [term, setTerm] = useState('');
	const [customLoader, setCustomLoader] = useState(false);
	var [isLoading, fetchDropData] = useHttp(
		`/api/GetChildByParent?userName=${props.name}`,
		[]
	);

	var [isLoader, fetchData, setFetchData] = useCustomHttpWithDataMiddleware(
		`/api/PrimaryInfo`,
		[],
		{
			Action: [
				{
					term: '',
					isEnable: true,
					type: 'actionLink',
					title: 'View',
					initialPath: '/dashboard/ContinouseAssesmentViewList',
					iconName: 'fas fa-list',
					iconColor: '#01AC8A'
				}
			]
		}
	);

	debugger;

	return (
		<div>
			<CustomHeading
				headingText={'Continous Assessment Details'}
				type={MAIN_HEADING}
			/>
			<SnackBar backColor={'#000'} msg={snackbarMessage} />

			<LoaderWrapper marginTop={20} isLoading={isLoader ? true : false}>
				<ViewComponent
					mainData={fetchData}
					headData={[
						'S #',
						'Name',
						'Term',
						'Class Name',
						'Section',
						'Session',
						'Subject Name',
						'Grade Name',
						'Course Type',
						'Actions'
					]}
					excludedData={[
						'primaryInfoId',
						'classId',
						'sectionId',
						'sessionId',
						'subjectId',
						'gradeId'
					]}
					redirectIds={['primaryInfoId']}
				/>
			</LoaderWrapper>
		</div>
	);
}
function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ContinouseAssesmentViewList);
