export const ON_DOCUMENTS_DISPATCH = 'ON_DOCUMENTS_DISPATCH';
export const ON_FILE_CHANGE = 'onFileChange';
export const ON_RADIO_CHANGE = 'onChangeRadio';

const StudentDocState = {
	// staff docs done
	dobCertificate: '',
	transferCertificate: '',
	ministryVaccination: '',
	passportCopy: '',
	idCardCopy: '',
	medicalHistory: '',
	lastResult: '',
	testResult: '',
	fileNameDobCertificate: '',
	fileNameTransferCertifcate: '',
	fileNameMinistryVaccination: '',
	fileNamePassportCopy: '',
	fileNameIdCard: '',
	fileNameMedicalHistory: '',
	fileNameLastResult: '',
	fileNameTestResult: ''
	// end of staff docs
};

const ParentDocState = {
	// Staff docs done
	idCardFather: '',
	passportFather: '',
	idCardMother: '',
	passportMother: '',
	fileNameIdCardFather: '',
	fileNamePassportFather: '',
	fileNameIdCardMother: '',
	fileNamePassportMother: ''
	// end of staff docs
};

const studentNonStateFull = {



	// Student Parent Info Fields
	fatherPName: '',
	motherName: '',
	garduainName: '',
	occupation: '',
	income: '',
	emergencyContact: '',
	parentMobileNo: '',
	parentEmailId: '',
	parentCNIC: '',
	parentIdCardNo: '',

	// End of parent info fields
	// Student Address Details
	address: '',
	houseNo: '',
	studentIdCardNo: '',
	studentEmailId: '',
	wayNo: '',
	streetNo: '',
	area: '',
	city: '',

	// End of Student Address Details

	// for sports otherActivity

	otherActivity: '',

	// end of sport otherActivity

	// Widthdrawl Reason
	reason: '',
	// end of widthdrawl

  gender:'',
  parentId:'',
	fullNameArabic: '',
	firstName: '',
	lastName: '',
	fatherName: '',
	rollNo: '',
	age: '',
	userName: '',
	password: '',
	placeOfBirth: '',

	//  static drop down
	nationality: 'Choose Nationality...',
	country: 'Choose Country...',
	religion: 'Choose Religion...',
	status: 'Choose Status...',
	admissionType: 'Admission Type...',
	currentSessionId: 'Current Session...',
	language: 'Choose Language...',





	// Student Details
	// parentId: '', statefull
	// parentName: '',

	// accountId: this.props.name,
	// accountType: this.props.role,


};

const studentInitailState = {

	// Account 
	
	isAccountAccess:'',
	// 

	//  on Change matters
	gender: 'Choose Gender...',
	//  end of gender
	// admission form statefull things 
	registrationNo: '', // statefull
	admissionNo: '',    // statefull => regN0 + ParentId + gender + yearn
	parentId: '',

	// WidthDrawlDetails

	dateLeaving: null,
	classLeft: '',
	// end of WidthdrawlDetails

	// Sports
	sports: '',

	// end of sports

	// transport 
	//extra feild for transport
	pickUp: '',
	otherDriverName: '',
	otherCarNo: '',
	otherDriverCardIdNo: '',
	otherDriverMobileNo: '',

	transportStatus: null,
	routeId: '',
	driverId: '',
	vehicleNo: '',
	driverName: '',
	driverLicense: '',
	driverIdCardNo: '',
	driverMobileNo: '',
	driverIdCardCopy: '',
	parentOrByWalk: null,
	transportType: '',
	routeName: '',

	// end of extra transport
	// transport end

	// medical condition 
	threatConditionReason: null,
	needMedicationReason: null,
	otherMedicationReason: null,

	threatCondition: '',
	needMedication: '',
	otherMedication: '',


	// end of medical condition

	// end of upload image
	// step 1 Student Information
	// class id start done
	classId: 'Choose Class',
	className: '',
	// class id end
	// start of grade done
	gradeId: '',
	gradeName: '',
	// end of grade
	// post start
	postName: '',
	postId: 'Choose Post',
	// post end done
	// campus start
	campusData: [],
	campusName: '',
	campusId: 'Choose Campus...',

	// class start
	classes: [],

	// end class
	// accountSection
	isAccountAccess: false,
	// end accountSection
	// dept start
	depart: [],
	// dept end
	// campus end done
	// subjectData
	subjectData: [],
	// end of subject data
	// for containing grade data
	grade: [],
	// end of data
	// for containing post data
	post: [],
	// end of post data
	// section done
	sectionId: 'Choose Section',
	section: '',
	sessionData: [],
	// section end
	// subject start done
	subjectName: '',
	subjectId: 'Choose Subject',
	// end done
	// depart done
	departmentName: 'Choose Department',
	departmentId: 'Choose Depart',
	// depart end
	dob: null,
	age: '',
	staffIdcardCopy: '',
	staffIdfilePath1: '',
	staffPassportDOI: '',
	staffPassportDOE: '',
	staffIdcardDOI: '',
	staffIdcardDOE: '',
	dateOfJoining: new Date().toLocaleDateString(),
	// End of Step 1 Staff information
	// staff docs done
	experienceLetter: '',
	iletsCertificate: '',
	ministryApproval: '',
	lastQualification: '',
	staffpassportCopy: '',
	passportCopy: '',
	idCardCopys: '',
	experienceLetterfilePath: '',
	iletsCertificatefilePath: '',
	ministryApprovalfilePath: '',
	lastQualificationfilePath: '',
	staffpassportCopyfilePath: '',
	passportCopyfilePath: '',
	idCardCopysfilePath: '',
	// end of staff docs
	// start of staff Qualification
	siblingsDetails: [
		{
			siblingName: '',
			age: '',
			relation: '',
			education: '',
			school: ''
		}
	],
	previousSchoolDetails: [
		{
			prevSchoolName: '',
			enrollNo: '',
			fromYear: '',
			yearUpto: '',
			reasonLeaving: ''
		}
	],
	Qualifications: [
		{
			qualificationName: '',
			board: '',
			qualificationYear: '',
			institute: ''
		}
	],
	Skills: [
		{
			skillName: ''
		}
	],
	Experiences: [
		{
			experienceName: '',
			companyName: '',
			experinceYear: ''
		}
	],
	Certificate: [
		{
			certificateName: '',
			certificateOrg: '',
			certificateDuration: '',
			certificatePlace: '',
			certificateYear: ''
		}
	],

	// end of qualification
	count2: 0,
	errors: {}
};


const onCustomRadioDocs = (state, action) => {
	switch (action.payload.value) {
		case 'Yes':
			return {
				...state,
				[action.payload.name]: action.payload.value
			};
		case 'No':
			return {
				...state,
				[action.payload.name]: action.payload.value,
				[action.payload.fieldName]: action.payload.set
			};
		default:
			break;
	}
};
const staffDocsDispatch = (state, action) => {
	debugger;
	return { ...state, ...action.payload.documents };
};
const fileOnChange = (state, action) => {
	debugger;
	return { ...state, [action.payload.name]: action.payload.value };
};
const StudentDocsReducer = (state, action) => {
	debugger;
	switch (action.type) {
		case ON_DOCUMENTS_DISPATCH:
			debugger;
			return staffDocsDispatch(state, action);
		case ON_RADIO_CHANGE:
			return onCustomRadioDocs(state, action);
		case ON_FILE_CHANGE:
			debugger;
			return fileOnChange(state, action);
		default:
			throw new Error('Unexpected action');
	}
};
export default {
	ParentDocState,
	StudentDocState,
	StudentDocsReducer
};
