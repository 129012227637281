import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SkillAction from '../../Actions/StudentSkill';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import { Link } from 'react-router-dom';

import $ from 'jquery';
// import TableComponent from "./TableComponent";
class StudentSkillViewP extends Component {
	constructor(props) {
		super(props);
		this.state = {
			SkillReports: [],
			adminData: [],
			studentNames: [],
			studentUserName: '',
			modalData: null,
			vehicleNo: '',
			capacity: '',
			transportType: '',
			enabled: false,
			id: null,
			select: [],
			efforts: '',
			participation: '',
			average: '',
			academicProgress: '',
			studentName: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			errorMsg: '',
			increament: 1,
			selectClassData: '',
			classId: '',
			sectionId: '',
			sections: [],
			subjects: [],
			session: [],
			sessionId: '',
			subjectId: '',
			staffId: '',
			term: '',
			btnLoad: false,
			exist: false,
			checking: [],
			searchCheck: true,
			Loading: true,
			multiple: []
		};
	}

	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip('hide');

		if (this.state.msg || this.state.errorMsg || this.state.exist) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					exist: false
				});
			}, 3000);
		}
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/GetChildByParent?userName=${this.props.users.unique_name}`
			)
			.then(success => {
				this.setState({
					studentNames: success,
					searchCheck: false
				});
			})
			.catch(error => {
				this.setState({
					searchCheck: true
				});
				console.log(error);
			});
	}

	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};

	onChangeText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onStudentChange = e => {
		console.log(e.target.value);
		this.setState({ studentUserName: e.target.value }, this.SearchChildData);
	};
	SearchChildData = e => {
		this.setState({ searchCheck: true });
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/StudentDetail?userName=${this.state.studentUserName}`
			)
			.then(success => {
				this.setState({
					adminData: success,
					searchCheck: false,
					Loading: false
				});
			})
			.catch(err => {
				// this.props.CensusActions.updateCensusFlag(false);
				this.setState({
					isLoading: false,
					Loading: true,
					check: true,
					searchCheck: true,
					btnLoad: false
				});

				console.log(err);
			});
		console.log(this.state);
	};

	render() {
		const { adminData, studentNames } = this.state;
		console.log('Students', this.state.studentNames);
		console.log('Students All Data', this.state.adminData);

		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);

		const renderTodos = adminData.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" style={{ padding: 10 }}>
						{index + 1}
					</th>
					<td style={{ padding: 10 }}>{item.name}</td>
					<td style={{ padding: 10 }}>{item.className}</td>
					<td style={{ padding: 10 }}>{item.section}</td>
					<td style={{ padding: 10 }}>{item.session}</td>
					<td style={{ padding: 10 }}>{item.status}</td>
					<td style={{ padding: 10 }}>
						<div class="col-md-12">
							<div class="md-form mb-0">
								<select
									style={{ borderColor: '#01AC8A' }}
									onChange={this.onHandleText}
									class="custom-select"
									name="term"
								>
									<option>Choose Term..</option>
									<option value="1st">1st Term</option>
									<option value="2nd">2nd Term</option>
									<option value="3rd">3rd Term</option>
								</select>
							</div>
						</div>
					</td>
					{this.state.term !== '' ? (
						<td>
							<Link
								to={`/dashboard/PrintDescriptiveReport/${item.studentId}/${item.classId}/${item.sectionId}/${this.state.term}/${item.sessionId}`}
							>
								<a id="tooltipId" data-toggle="tooltip" title="Print Report">
									<button
										style={{
											cursor: 'pointer',
											fontSize: 20,
											border: 'none',
											background: 'none',
											marginLeft: 5,
											marginBottom: 5
										}}
									>
										<i style={{ color: '#01AC8A' }} class="fas fa-list"></i>
									</button>
								</a>
							</Link>
						</td>
					) : (
						''
					)}
				</tr>
			);
		});
		const MainContent = (
			<div>
				{this.state.Loading ? (
					<p class="text-center text-danger">No Data</p>
				) : (
					<div className="table-responsive">
						<table class="table table-hover text-center table-bordered">
							<thead style={{ background: '#01AC8A', color: 'white' }}>
								<tr>
									<th scope="col" style={{ padding: 2 }}>
										#
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Student Name
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Class
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Section
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Session
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Status
									</th>
									<th scope="col" style={{ padding: 2 }}>
										Term
									</th>
									{this.state.term !== '' ? (
										<th scope="col" style={{ padding: 2 }}>
											Action
										</th>
									) : (
										''
									)}
								</tr>
							</thead>
							<tbody>{renderTodos}</tbody>
						</table>
					</div>
				)}

				{this.state.errorMsg ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Grading Marks Already Exist For This Term
					</p>
				) : (
					''
				)}
				{this.state.msg ? (
					<p style={{ color: 'green', textAlign: 'center' }}>
						Added Successfully!
					</p>
				) : (
					''
				)}
				{this.state.exist ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Please Fill the Fields
					</p>
				) : (
					''
				)}
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Student Descriptive View</h2>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-md-4 text-center" style={{ marginTop: 0 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								Select your child
							</label>
							<select
								onChange={this.onStudentChange}
								class="custom-select"
								name="sectionName"
							>
								<option selected={this.state.msg ? true : false}>
									Select Child..
								</option>
								{studentNames.map((item, index) => (
									<option key={index} value={`${item.studentUserName}`}>
										{item.studentName}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
				<br />
				{this.state.searchCheck ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		SkillAction: bindActionCreators(SkillAction, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllTransportData: state.setStudentSkillReducer.DataP,
		selectData: state.setStudentSkillReducer.selectStudent,
		SessionSelect: state.setStudentSkillReducer.selectSession,
		allTransportState: state.setStudentSkillReducer,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StudentSkillViewP);
