import * as actionTypes from '../actionTypes';

export function setAllTransportInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_TRANSPORT,
		payload: allData
	};
}
export function addTransport(Data) {
	return {
		type: actionTypes.ADD_TRANSPORT,
		payload: Data
	};
}
export function updateTransportFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_TRANSPORT,
		payload: setFlag
	};
}
export function updateTransport(data) {
	return {
		type: actionTypes.EDIT_TRANSPORT_RECORD,
		payload: data
	};
}
export function deleteTransport(id) {
	return {
		type: actionTypes.DELETE_TRANSPORT,
		payload: id
	};
}
