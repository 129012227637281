import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

class ViewIssuedBook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			modalEdit: false,
			editToggle: false,
			generateToggle: false,
			generateIndex: '',
			msg: '',
			editIndex: '',
			accountId: null,
			accountType: null,

			issuedBookData: [],
			issuedBookStaffData: [],
			issuedBookStudentData: [],
			type: 'student',
			searchId: '',

			bookId: null,
			bookName: null,
			dateOfIssue: null,
			dateOfExpiry: null,
			status: null,

			classId: null,
			sectionId: null,
			studentId: null,
			className: null,
			section: null,
			studentName: null,

			staffId: null,
			staffName: null
		};
	}

	componentDidMount() {
		this.getSimple('IssueBooks/Students', 'issuedBookStudentData');
		this.getSimple('IssueBooks/Staff', 'issuedBookStaffData');
	}

	getSimple = (type, state) => {
		this.props.sharedActions
			.getDataWithoutDispatch(`/api/${type}`)
			.then(success => {
				this.setState({
					[state]: success,
					isLoading: false
				});
			})
			.catch(error => {
				this.setState({
					msg: 'Error Occured!!',
					errorMsg: true,
					isLoading: false
				});
				console.error('Error occured', error);
			});
	};

	onStudentEditClick = id => {
		const { issuedBookStudentData } = this.state;
		let modalData = issuedBookStudentData.filter((items, index) => {
			if (id === items.id) {
				this.setState({
					editIndex: index
				});
				console.log(index);
				return id === items.id;
			}
		});
		console.log(modalData);
		this.setState(
			{
				id: modalData[0].id,
				bookId: modalData[0].bookId,
				bookName: modalData[0].bookName,
				studentId: modalData[0].studentId,
				studentName: modalData[0].studentName,
				classId: modalData[0].classId,
				className: modalData[0].className,
				sectionId: modalData[0].sectionId,
				section: modalData[0].section,
				dateOfIssue: modalData[0].dateOfIssue,
				dateOfExpiry: modalData[0].dateOfExpiry,
				date: modalData[0].date,
				time: modalData[0].time,
				status: modalData[0].status,
				accountId: modalData[0].accountId,
				accountType: modalData[0].accountType,
				staffId: modalData[0].staffId
			},
			() => {
				$('#studentModal').modal({
					show: true
				});
			}
		);
	};

	onStaffEditClick = id => {
		const { issuedBookStaffData } = this.state;
		let modalData = issuedBookStaffData.filter((items, index) => {
			if (id === items.id) {
				this.setState({
					editIndex: index
				});
				console.log(index);
				return id === items.id;
			}
		});
		console.log(modalData);
		this.setState(
			{
				id: modalData[0].id,
				bookId: modalData[0].bookId,
				bookName: modalData[0].bookName,
				staffId: modalData[0].staffId,
				staffName: modalData[0].staffName,
				classId: modalData[0].classId,
				sectionId: modalData[0].sectionId,
				dateOfIssue: modalData[0].dateOfIssue,
				dateOfExpiry: modalData[0].dateOfExpiry,
				date: modalData[0].date,
				time: modalData[0].time,
				status: modalData[0].status,
				accountId: modalData[0].accountId,
				accountType: modalData[0].accountType,
				studentId: modalData[0].studentId
			},
			() => {
				$('#staffModal').modal({
					show: true
				});
			}
		);
	};

	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {}, 3000);
	};

	handleStaffSubmit = e => {
		e.preventDefault();
		console.log('Submit Called');

		const {
			id,
			bookId,
			bookName,
			staffId,
			staffName,
			classId,
			sectionId,
			dateOfIssue,
			dateOfExpiry,
			date,
			time,
			status,
			accountId,
			accountType,
			studentId
		} = this.state;

		let data = {
			id,
			bookId,
			bookName,
			staffId,
			staffName,
			classId,
			sectionId,
			dateOfIssue,
			dateOfExpiry,
			date,
			time,
			status,
			accountId,
			accountType,
			studentId
		};

		console.log('data model', data);
		this.props.sharedActions
			.editRecordWithoutDispatch('/api/IssueBooks/', id, data)
			.then(success => {
				console.log('success', success);
				this.state.issuedBookStaffData.splice(this.state.editIndex, 1, data);

				$('#staffModal').modal('hide');

				this.setState({
					msg: 'Record Changed Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {
				this.setState({
					msg: error.response.data.Message || 'Error Occured!!',
					errorMsg: true,
					isLoading: false
				});
				console.error('Error occured', error);

				$('#staffModal').modal('hide');

				this.props.snackbar();
			});
	};

	handleStudentSubmit = e => {
		e.preventDefault();
		console.log('Submit Called');

		const {
			id,
			bookId,
			bookName,
			studentId,
			studentName,
			classId,
			className,
			sectionId,
			section,
			dateOfIssue,
			dateOfExpiry,
			date,
			time,
			status,
			accountId,
			accountType,
			staffId
		} = this.state;

		let data = {
			id,
			bookId,
			bookName,
			studentId,
			studentName,
			classId,
			className,
			sectionId,
			section,
			dateOfIssue,
			dateOfExpiry,
			date,
			time,
			status,
			accountId,
			accountType,
			staffId
		};

		console.log('data model', data);
		this.props.sharedActions
			.editRecordWithoutDispatch('/api/IssueBooks/', id, data)
			.then(success => {
				console.log('success', success);
				this.state.issuedBookStudentData.splice(this.state.editIndex, 1, data);

				$('#studentModal').modal('hide');

				this.setState({
					msg: 'Record Changed Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {
				this.setState({
					msg: error.response.data.Message || 'Error Occured!!',
					errorMsg: true,
					isLoading: false
				});
				console.error('Error occured', error);

				$('#studentModal').modal('hide');

				this.props.snackbar();
			});
	};

	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
		console.log([e.target.name], e.target.value);
	};

	onHandleSearchText = e => {
		if (e.target.value.length < 1) {
			console.log();
			this.setState(
				{
					[e.target.name]: e.target.value
				},
				() => {
					this.state.issuedBookStudentData.length = 0;
					this.state.issuedBookStaffData.length = 0;
					this.getSimple('IssueBooks/Students', 'issuedBookStudentData');
					this.getSimple('IssueBooks/Staff', 'issuedBookStaffData');
				}
			);
		} else {
			this.setState({
				[e.target.name]: e.target.value
			});
		}
	};

	handleSearch = () => {
		if (this.state.type == 'student') {
			this.state.issuedBookStudentData.length = 0;
			this.getSimple(
				`StudentIssueBooks/?studentId=${this.state.searchId}`,
				'issuedBookStudentData'
			);
			//getsimple
		} else {
			this.state.issuedBookStaffData.length = 0;
			//getsimple
			this.getSimple(
				`StaffIssueBooks/?staffId=${this.state.searchId}`,
				'issuedBookStaffData'
			);
		}
	};

	render() {
		const {
			isLoading,
			editToggle,
			msg,
			issuedBookStaffData,
			issuedBookStudentData,
			type,
			searchId,

			bookId,
			bookName,
			dateOfIssue,
			dateOfExpiry,
			status,

			classId,
			sectionId,
			studentId,
			className,
			section,
			studentName,

			staffId,
			staffName,

			accountId,
			accountType
		} = this.state;

		const editStudentData = (
			<div>
				<div className="modal" id="studentModal">
					<div className="modal-dialog">
						<div className="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								className="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} className="modal-title">
									Edit
								</h4>
								<button
									style={{ color: 'white' }}
									on
									type="button"
									className="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={this.handleStudentSubmit}>
									<fieldset disabled={!editToggle}>
										<div className="form-group">
											<label for="category">Status:</label>
											<select
												class="custom-select"
												value={status}
												name="status"
												onChange={this.onHandleText}
											>
												<option value="Issued">Issued</option>
												<option value="Returned">Returned</option>
											</select>
										</div>
										<div className="form-group">
											<label for="accountId">Account Name:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={accountId}
												name="modalDataAccountId"
												className="form-control"
												id="accountId"
											/>
										</div>
										<div className="form-group">
											<label for="accountType">Account Type:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={accountType}
												name="modalDataAccountType"
												className="form-control"
												id="accountType"
											/>
										</div>
										<div className="form-group">
											<label for="studentName">Student Name:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={studentName}
												name="studentName"
												className="form-control"
												id="studentName"
											/>
										</div>
										<div className="form-group">
											<label for="className">Class</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={className}
												name="className"
												className="form-control"
												id="className"
											/>
										</div>
										<div className="form-group">
											<label for="section">Section:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={section}
												name="section"
												className="form-control"
												id="section"
											/>
										</div>
										<div className="form-group">
											<label for="bookName">Book Name:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={bookName}
												name="bookName"
												className="form-control"
												id="bookName"
											/>
										</div>
										<div className="form-group">
											<label for="dateOfIssue">Issue Date:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={dateOfIssue}
												name="dateOfIssue"
												className="form-control"
												id="dateOfIssue"
											/>
										</div>
										<div className="form-group">
											<label for="dateOfExpiry">Expiry Date:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={dateOfExpiry}
												name="dateOfExpiry"
												className="form-control"
												id="dateOfExpiry"
											/>
										</div>
									</fieldset>
									<br />
									<br />
									<button
										disabled={editToggle}
										onClick={() => this.setState({ editToggle: true })}
										type="button"
										class="btn btn-primary buttonAppear"
									>
										Edit
									</button>
									<button
										style={{ marginLeft: 5 }}
										disabled={!editToggle}
										type="submit"
										class="btn btn-primary buttonAppear"
									>
										Save
									</button>
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									onClick={() => this.setState({ editToggle: false })}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);

		const editStaffData = (
			<div>
				<div className="modal" id="staffModal">
					<div className="modal-dialog">
						<div className="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								className="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} className="modal-title">
									Edit
								</h4>
								<button
									style={{ color: 'white' }}
									on
									type="button"
									className="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={this.handleStaffSubmit}>
									<fieldset disabled={!editToggle}>
										<div className="form-group">
											<label for="category">Status:</label>
											<select
												class="custom-select"
												value={status}
												name="status"
												onChange={this.onHandleText}
											>
												<option value="Issued">On Hold</option>
												<option value="Returned">Returned</option>
											</select>
										</div>
										<div className="form-group">
											<label for="accountId">Account Name:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={accountId}
												name="modalDataAccountId"
												className="form-control"
												id="accountId"
											/>
										</div>
										<div className="form-group">
											<label for="accountType">Account Type:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={accountType}
												name="modalDataAccountType"
												className="form-control"
												id="accountType"
											/>
										</div>
										<div className="form-group">
											<label for="staffName">Staff Name:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={staffName}
												name="staffName"
												className="form-control"
												id="staffName"
											/>
										</div>
										<div className="form-group">
											<label for="bookName">Book Name:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={bookName}
												name="bookName"
												className="form-control"
												id="bookName"
											/>
										</div>
										<div className="form-group">
											<label for="dateOfIssue">Issue Date:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={dateOfIssue}
												name="dateOfIssue"
												className="form-control"
												id="dateOfIssue"
											/>
										</div>
										<div className="form-group">
											<label for="dateOfExpiry">Expiry Date:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={dateOfExpiry}
												name="dateOfExpiry"
												className="form-control"
												id="dateOfExpiry"
											/>
										</div>
									</fieldset>
									<br />
									<br />
									<button
										disabled={editToggle}
										onClick={() => this.setState({ editToggle: true })}
										type="button"
										class="btn btn-primary buttonAppear"
									>
										Edit
									</button>
									<button
										style={{ marginLeft: 5 }}
										disabled={!editToggle}
										type="submit"
										class="btn btn-primary buttonAppear"
									>
										Save
									</button>
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									onClick={() => this.setState({ editToggle: false })}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);

		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);

		const renderStudentData = issuedBookStudentData.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" style={{ padding: 1 }}>
						{index + 1}
					</th>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.bookName}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.studentName}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.accountId}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.accountType}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.dateOfIssue}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.dateOfExpiry}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.status}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						<a href="#" data-toggle="tooltip" title="Edit!">
							<button
								id="firstbutton"
								onClick={() => this.onStudentEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</a>
					</td>
				</tr>
			);
		});

		const renderStaffData = issuedBookStaffData.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" className="bordersSpace" style={{ padding: 1 }}>
						{index + 1}
					</th>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.bookName}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.staffName}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.accountId}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.accountType}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.dateOfIssue}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.dateOfExpiry}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.status}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						<a href="#" data-toggle="tooltip" title="Edit!">
							<button
								id="firstbutton"
								onClick={() => this.onStaffEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</a>
					</td>
				</tr>
			);
		});

		return (
			<div className="page-header">
				<SnackBar msg={msg} />
				<div
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}
				>
					<div class="row">
						<div class="col-md-7">
							<h2>Issued Books</h2>
						</div>
						<div class="col-md-2 align-right">
							<select
								class="form-control mr-sm-2"
								type="type"
								name="type"
								placeholder="Type"
								onChange={this.onHandleText}
								value={type}
								aria-label="Type"
							>
								<option value="student">Student</option>
								<option value="staff">Staff</option>
							</select>
						</div>
						<div class="col-md-2 align-right">
							<input
								style={{
									borderTop: 0,
									borderRight: 0,
									borderLeft: 0
								}}
								type="text"
								value={searchId}
								placeholder="Search..."
								name="searchId"
								className="form-control mr-sm-2"
								id="searchId"
								onChange={this.onHandleSearchText}
							/>
						</div>

						<div class="col-md-1 align-right">
							<button
								class="btn btn-primary"
								disabled={!searchId}
								onClick={this.handleSearch}
							>
								Search
							</button>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-hover text-center table-bordered">
							<thead style={{ background: '#01AC8A', color: 'white' }}>
								<tr>
									<th scope="col" style={{ padding: 1 }}>
										#
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Book Name
									</th>
									<th scope="col" style={{ padding: 1 }}>
										{type == 'student' ? 'Student Name' : 'Staff Name'}
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Account Name
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Acoount Role
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Issue Date
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Expiry Date
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Status
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{isLoading && Loader}
								{!isLoading && type == 'student' && renderStudentData}
								{!isLoading && type == 'staff' && renderStaffData}
							</tbody>
						</table>
					</div>
				</div>
				{editStudentData}
				{editStaffData}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewIssuedBook);
