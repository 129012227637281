import React, { Component } from 'react';
import {
	Editor,
	EditorState,
	RichUtils,
	convertToRaw,
	convertFromRaw
} from 'draft-js';
import './Editor.css';
import Axios from 'axios/index';
import config from '../../../src/config/config';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ViewEditor from './ViewEditorComponent';

class EditorComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: EditorState.createEmpty(),
			Syllabus: [],
			Checking: [],
			Subjects: this.props.location.state.id.Subject,
			blocks: [],
			blockF: [],
			adminData: [],
			classId: this.props.location.state.id.classId,
			sectionId: this.props.location.state.id.sectionId,
			sessionId: this.props.location.state.id.sessionId,
			className: this.props.location.state.id.className,
			sectionName: this.props.location.state.id.sectionName,
			sessionName: this.props.location.state.id.sessionName,
			term: this.props.location.state.id.examType,
			title: '',
			accountId: '',
			accountType: '',
			errorMsg: null,
			msg: null
		};
	}
	saveContent = content => {
		window.localStorage.setItem(
			'content',
			JSON.stringify(convertToRaw(content))
		);
	};
	onChange = editorState => {
		const contentState = editorState.getCurrentContent();
		console.log('content state', convertToRaw(contentState));
		this.setState({
			Syllabus: convertToRaw(contentState),
			editorState
		});
		console.log('Block Ka Data', this.state.Syllabus);
		const { blocks } = this.state.Syllabus;
		this.setState({
			blocks: blocks
		});
	};
	handleKeyCommand = command => {
		const newState = RichUtils.handleKeyCommand(
			this.state.editorState,
			command
		);

		if (newState) {
			this.onChange(newState);
			return 'handled';
		}

		return 'not-handled';
	};
	onUnderlineClick = () => {
		this.onChange(
			RichUtils.toggleInlineStyle(this.state.editorState, 'UNDERLINE')
		);
	};
	onToggleCode = () => {
		this.onChange(RichUtils.toggleCode(this.state.editorState));
	};
	onBoldClick = () => {
		this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
	};
	onItalicClick = () => {
		this.onChange(
			RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC')
		);
	};
	toggleBulletPoints = () => {
		this.onChange(
			RichUtils.toggleBlockType(this.state.editorState, 'unordered-list-item')
		);
	};
	toggleOrderedBulletPoints = () => {
		this.onChange(
			RichUtils.toggleBlockType(this.state.editorState, 'ordered-list-item')
		);
	};
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		let token = '';
		if (localStorage.jwtToken) {
			token = localStorage.getItem('jwtToken');
		}
		Axios.get(`${config.localhttp}/api/SyllabusNew/5`, {
			headers: { Authorization: `Bearer  ${token}` }
		})
			.then(success => {
				console.log(success);

				this.setState({
					isLoading: false,
					adminData: success.data.blocks
				});
			})
			.catch(error => {
				this.setState({
					isLoading: false
				});
				console.log(error);
			});
	}
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	onHandleSubmit = e => {
		e.preventDefault();
		const {
			sectionId,
			classId,
			sessionId,
			title,
			accountId,
			accountType,
			blocks,
			term
		} = this.state;
		if (
			Object.is(sectionId, '') ||
			Object.is(classId, '') ||
			Object.is(title, '')
		) {
			this.setState({
				error: true,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			let token = '';
			if (localStorage.jwtToken) {
				token += localStorage.getItem('jwtToken') + token;
			}
			var Headconfig = {
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: 'Bearer ' + token
				}
			};
			var bodyParameters = {
				Word_Syllabuses: {
					classId,
					sectionId,
					sessionId,
					title,
					accountType: this.props.userData.role,
					accountId: this.props.userData.unique_name,
					term
				},
				blocks
			};
			Axios.post(
				`${config.localhttp}/api/SyllabusNew`,
				bodyParameters,
				Headconfig
			)
				.then(response => {
					console.log(response);

					if (response.status == 200) {
						this.setState({
							title: '',
							msg: true,
							errorMsg: false
						});
						this.state.editorState = EditorState.createEmpty();
					}
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code

						// that falls out of the range of 2xx
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received

						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
				});
		}
	};
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	render() {
		const { blocks } = this.state.Syllabus;
		console.log(this.state);
		console.log('TEsting and', this.state.Syllabus);
		console.log('Admin Data', this.state.adminData);
		console.log('Sub', this.state.Subjects);
		return (
			<div className="container">
				<div class="row">
					<div class="col-md-4">
						<div class="md-form mb-0">
							<label for="name" class="">
								Exam: &nbsp;
							</label>
							<label
								for="name"
								class=""
								style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
							>
								{this.state.term}
							</label>
							{/* <input
								style={{ borderColor: '#01AC8A' }}
								type="text"
								name="AdminType"
								class="form-control"
								readOnly={true}
								value={this.state.sectionId}
							/> */}
						</div>
					</div>

					<div class="col-md-4">
						<div class="md-form mb-0">
							<label for="name" class="">
								Session: &nbsp;
							</label>
							<label
								for="name"
								class=""
								style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
							>
								{this.state.sessionName}
							</label>
							{/* <input
								style={{ borderColor: '#01AC8A' }}
								type="text"
								name="AdminName"
								class="form-control"
								readOnly={true}
								value={this.state.classId}
							/> */}
						</div>
					</div>

					<div class="col-md-4">
						<div class="md-form mb-0">
							<label for="name" class="">
								Subjects:
							</label>
							{this.state.Subjects.map((item, index) => (
								<small class="font-weight-bold">
									<div style={{ width: 200 }}>
										{index + 1}. {item.subjectName}
									</div>
								</small>
							))}
						</div>
					</div>
					<div class="col-md-4" style={{ marginTop: -200 }}>
						<div class="md-form mb-0">
							<label for="name" class="">
								Class: &nbsp;
							</label>
							<label
								for="name"
								class=""
								style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
							>
								{this.state.className}
							</label>
							{/* <input
								style={{ borderColor: '#01AC8A' }}
								type="text"
								name="AdminName"
								class="form-control"
								readOnly={true}
								value={this.state.classId}
							/> */}
						</div>
					</div>
					<div class="col-md-4" style={{ marginTop: -200 }}>
						<div class="md-form mb-0">
							<label for="name" class="">
								Section: &nbsp;
							</label>
							<label
								for="name"
								class=""
								style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
							>
								{this.state.sectionName}
							</label>
							{/* <input
								style={{ borderColor: '#01AC8A' }}
								type="text"
								name="AdminType"
								class="form-control"
								readOnly={true}
								value={this.state.sectionId}
							/> */}
						</div>
					</div>
				</div>

				<div class="row" style={{ marginTop: -140 }}>
					<div class="col-md-8">
						<div class="md-form mb-0">
							<label>Add Title:</label>
							<label
								for="name"
								class=""
								style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
							></label>
							<input
								type="text"
								placeholder="Enter Title Here"
								class="form-control"
								name="title"
								value={this.state.title}
								onChange={this.onHandleTextChange}
							/>
						</div>
					</div>
					<div class="col-md-6" />
				</div>
				<div class="row justify-content-center">
					<div className="editorContainer">
						{this.state.errorMsg ? (
							<p style={{ color: 'red', textAlign: 'center' }}>
								Please fill the form properly
							</p>
						) : (
							''
						)}
						{this.state.msg ? (
							<p style={{ color: 'green', textAlign: 'center' }}>
								Added Successfully!
							</p>
						) : (
							''
						)}
						<div class="btn-group mr-2" role="group" aria-label="Second group">
							<button
								class="btn btn-dark btnSpace"
								onClick={this.onUnderlineClick}
							>
								<i
									style={{ position: 'relative' }}
									class="fas fa-underline"
								></i>
							</button>
							<button class="btn btn-dark btnSpace" onClick={this.onToggleCode}>
								<i style={{ position: 'relative' }} class="fas fa-square"></i>
							</button>
							<button class="btn btn-dark btnSpace" onClick={this.onBoldClick}>
								<i style={{ position: 'relative' }} class="fas fa-bold"></i>
							</button>
							<button
								class="btn btn-dark btnSpace"
								onClick={this.onItalicClick}
							>
								<i style={{ position: 'relative' }} class="fas fa-italic"></i>
							</button>
							<button
								class="btn btn-dark btnSpace"
								onClick={this.toggleBulletPoints}
							>
								<i
									style={{ position: 'relative' }}
									class="fas fa-dot-circle"
								></i>
							</button>
							<button
								class="btn btn-dark btnSpace"
								onClick={this.toggleOrderedBulletPoints}
							>
								<i
									style={{ position: 'relative' }}
									class="fas fa-sort-numeric-down"
								></i>
							</button>
						</div>
					</div>
				</div>
				<div className="editors">
					<Editor
						editorState={this.state.editorState}
						onChange={this.onChange}
						handleKeyCommand={this.handleKeyCommand}
					/>
				</div>
				<button class="btns" onClick={this.onHandleSubmit}>
					{' '}
					Submit
				</button>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	null
)(EditorComponent);
