import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import Colors from '../../utils/app_constants/colors_constants';
import Spinner from '../../components/Spinner/Spinner';
import $ from 'jquery'

let tHead1 = [
    'GENERAL',
    'A',
    'B',
    'C',
    'D',
    'E',
    'N',
    'Remarks'
];

let tHead2 = [
    'LESSON', '/', '/', '/', '/', '/', '/', ''
];

let tHead3 = [
    'TEACHING SKILLS', '/', '/', '/', '/', '/', '/', ''
];

class EvaluationByStaffTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            teacherEvaluationDetails: [],
            teacherEvaluation: {},
            evaluationComments: {}
        }
    }

    componentDidMount() {
        const { match } = this.props;
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/TeacherEvaluationForm/${match.params.id}`
            )
            .then(success => {
                this.setState({
                    teacherEvaluationDetails: success.TeacherEvaluationDetails,
                    teacherEvaluation: success.TeacherEvaluations,
                    evaluationComments: success.EvaluationComments,
                    isLoading: false
                })
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.log(error);
            });
    }

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
    };

    render() {
        const { isLoading, teacherEvaluation, teacherEvaluationDetails, evaluationComments } = this.state;

        const Template = (
            <section class='mb-4'>
                <div class="divclass">
                    <img src={require('../../Assets/images/3 headers-03.jpg')} />
                </div>

                {/*                 <div class='row text-center'  >
                    <h2 class='print' className="h1-responsive font-weight-bold my-4 generalHead" >
                        {' '}
                        DORAT AL KHALEEJ PRIVATE SCHOOL{' '}
                    </h2>
                </div>
 */}                <div class='row ' style={{ flexGrow: 1, borderRight: 0, borderLeft: 0, borderStyle: 'solid', borderColor: '#01AC8A', margin: '5px', marginTop: 5 }}>

                    <div class="col-md-4">
                        <strong><ins>Teacher Observation </ins></strong>&nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >
                        </label>
                    </div>
                    <div class='col-md-4'>
                        <strong>Subject: </strong>&nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        > {teacherEvaluation.subjectName}</label>
                    </div>
                    <div class="col-md-4">
                        <strong>Prior Notification </strong>&nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 17, color: '#01425e' }}
                        >{teacherEvaluation.withOrwithoutNotficiation}
                        </label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        School: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.schoolName}</label>
                    </div>
                    <div class='col-md-4' style={{ marginTop: 8 }}>
                        Teacher: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.staff}</label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        Date: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.date}</label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        Class: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.className}</label>
                    </div>
                    <div class='col-md-4' style={{ marginTop: 8 }}>
                        Section: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.sectionName}</label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        Topic/Level: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.topic}</label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        Observed From: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.observerFrom}</label>
                    </div>
                    <div class='col-md-4' style={{ marginTop: 8 }}>
                        Observer's Name: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.observerName}</label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        Designation: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.designation}</label>
                    </div>

                </div>

                <div style={{ marginTop: 30, color: '#01ac8a' }}>
                    <div className="table-responsive">
                        <table class="table table-hover" >
                            <thead class='headCol borderSpace' style={{ background: '#01AC8A', textAlign: 'center' }}>
                                <tr class="vendorListHeading">
                                    {tHead1.map(item => (
                                        <th scope="col" style={{
                                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                            borderColor: Colors.SEA_GREEN_THEME
                                        }}
                                        >{item}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {teacherEvaluationDetails.slice(0, 5).map(item => (
                                    <tr>
                                        <th width='35%' style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.evaluationSkill}</th>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeA === 'A' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeB === 'B' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeC === 'C' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeD === 'D' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeE === 'E' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeN === 'N' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td width='35%' style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.remarks !== '' ? item.remarks : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table class="table table-hover" >

                            <thead class='headCol borderSpace' style={{ background: '#01AC8A', textAlign: 'center' }}>
                                <tr class="vendorListHeading">
                                    {tHead2.map(item => (
                                        <th scope="col" style={{
                                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                            borderColor: Colors.SEA_GREEN_THEME
                                        }}
                                        >{item}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {teacherEvaluationDetails.slice(5, 14).map(item => (
                                    <tr>
                                        <th width='35%' style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.evaluationSkill}</th>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeA === 'A' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeB === 'B' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeC === 'C' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeD === 'D' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeE === 'E' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeN === 'N' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td width='35%' style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.remarks !== '' ? item.remarks : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table class="table table-hover" >

                            <thead class='headCol borderSpace' style={{ background: '#01AC8A', textAlign: 'center' }}>
                                <tr class="vendorListHeading">
                                    {tHead3.map(item => (
                                        <th scope="col" style={{
                                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                            borderColor: Colors.SEA_GREEN_THEME
                                        }}
                                        >{item}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {teacherEvaluationDetails.slice(14, 30).map(item => (
                                    <tr>
                                        <th width='35%' style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.evaluationSkill}</th>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeA === 'A' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeB === 'B' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeC === 'C' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeD === 'D' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeE === 'E' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.gradeN === 'N' ? <i style={{ color: '#01AC8A' }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td width='35%' style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}`, borderColor: Colors.SEA_GREEN_THEME }}>{item.remarks !== '' ? item.remarks : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class='row ' style={{ flexGrow: 1, borderBottom: 5, borderRight: 0, borderLeft: 0, borderTop: 0, borderStyle: 'solid', borderColor: '#01AC8A', margin: '5px', marginTop: 30 }}>

                    <div class="col-md-12">
                        <strong>Any Other Comments: </strong>
                        <label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{evaluationComments.otherComments}</label>
                    </div>
                </div>
                <div class='row ' style={{ flexGrow: 1, borderBottom: 5, borderRight: 0, borderLeft: 0, borderTop: 0, borderStyle: 'solid', borderColor: '#01AC8A', margin: '5px', marginTop: 30 }}>

                    <div class="col-md-12">
                        <strong>Recommendations: </strong>
                        <label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{evaluationComments.recommendation}</label>
                    </div>
                </div>
                <div class='row' style={{ margin: '15px' }}>
                    <div class="col-md-7" style={{ marginTop: 46 }}>
                        <h7>This report has been discussed with the Teacher<strong> YES</strong> / <strong>NO: </strong></h7>
                        <label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{evaluationComments.discussion}</label>
                    </div>
                </div>
                <div class='row text-center' style={{ marginTop: 100, alignItems: 'center', justifyContent: 'center' }}>
                    <div class='col-md-6'>
                        <h7 style={{ textDecoration: 'overline' }}>Observer's Signature</h7>
                    </div>
                    <div class='col-md-6'>
                        <h7 style={{ textDecoration: 'overline' }}>Teacher's Signature</h7>
                    </div>
                </div>



            </section>

        )
        return (
            <div>
                <div id='div1'>
                    <div class='container'>
                        {isLoading ? <Spinner /> : Template}
                    </div>
                    <div id="footer">
                        <div class="h1-responsive font-weight-bold text-center my-4">
                            <img
                                src={require('../../Assets/images/Powerd-01.svg')}
                                style={{ width: 200 }}
                            />
                        </div>
                    </div>
                </div>

                <div class="text-center text-md-right">
                    <label for="name" class="" onClick={() => this.PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
					</label>
                </div>

            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(EvaluationByStaffTemplate);