import React from 'react';
import {
	checkForFilteration,
	checkingProperty,
	extractIds,
	RENDER_TOOLS,
	RENDER_SUB_TOOLS
} from '../../../../utils/general';
export default function ListItems(props) {
	const {
		data = [],
		excludedData = [],
		itemObj,
		stateData,
		rowIndex,
		updateSpecialArrObjOnChange = () => alert('hell'),
		arrName,
		type = ''
	} = props;
	let keys = Object.keys(itemObj);

	if (checkingProperty(keys, excludedData)) {
		keys = checkForFilteration(keys, excludedData);
	}

	return (
		<React.Fragment>
			{keys.map((item, index) =>
				item === 'studentName' ? (
					<td style={{ padding: 0 }}>
						<div style={{ marginTop: 15, width: '85%' }}>
							{stateData[arrName][rowIndex][`${item}`]}
						</div>
					</td>
				) : item === 'marks' ? (
					<td key={index}>
						<input
							maxLength="1"
							type="number"
							placeholder={`${item}`}
							class="form-control"
							name={`${item}`}
							value={stateData[arrName][rowIndex][`${item}`]}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
						/>
					</td>
				) : item === 'toolName' && type === RENDER_TOOLS ? (
					<td key={index}>
						<input
							type="text"
							placeholder={`${item}`}
							class="form-control"
							name={`${item}`}
							value={stateData[arrName][rowIndex][`${item}`]}
							onChange={e =>
								updateSpecialArrObjOnChange(e, rowIndex, e.target.name)
							}
						/>
					</td>
				) : item === 'toolName' && type === RENDER_SUB_TOOLS ? (
					<td key={index}>
						<select
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, item)}
							class="form-control"
						>
							<option>Choose Tool</option>
							{data &&
								data['toolDropDown'].map((item, index) => (
									<option key={index} value={item.toolId}>
										{item.toolName}
									</option>
								))}
						</select>
					</td>
				) : item === 'subToolName' ? (
					<td key={index}>
						<input
							type="text"
							placeholder={`${item}`}
							class="form-control"
							name={`${item}`}
							value={stateData[arrName][rowIndex][`${item}`]}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, item)}
						/>
					</td>
				) : item === 'max' ? (
					<td key={index}>
						<input
							type="text"
							placeholder={`${item}`}
							class="form-control"
							name={`${item}`}
							value={stateData[arrName][rowIndex][`${item}`]}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, item)}
						/>
					</td>
				) : item === 'min' ? (
					<td key={index}>
						<input
							type="text"
							placeholder={`${item}`}
							class="form-control"
							name={`${item}`}
							value={stateData[arrName][rowIndex][`${item}`]}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, item)}
						/>
					</td>
				) : (
					''
				)
			)}
		</React.Fragment>
	);
}
