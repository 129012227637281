import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';
import * as BudgetItemsAction from '../../Actions/BudgetItems';
import * as sharedActions from '../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AddMultipleItems from './AddMultipleItems';

class AddBudgetItems extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: false,
			msg: false,
			sessionId: '',
			select: '',
			itemsBudget: [
				{
					particular: '',
					description: '',
					amount: '',
					accountId: this.props.name,
					accountType: this.props.role
				}
			]
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.select !== nextProps.selectData) {
			return {
				select: nextProps.selectData
			};
		}
		return null;
	}

	onClickDeleteItem = arrName => {
		let getStateArr = this.state[`${arrName}`];
		let getDeleteLength = getStateArr.length - 1;
		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			this.setState({
				[arrName]: [...getNewDetails]
			});
		}
	};

	onClickAddItem = (arrName, obj) => {
		let getStateArr = this.state[`${arrName}`];
		getStateArr = [...getStateArr, obj];
		console.log(arrName);
		this.setState({
			[arrName]: getStateArr
		});
	};
	updateSpecialArrObjOnChange = (e, index, arrName) => {
		const changeArrOfObj = this.state[`${arrName}`];
		changeArrOfObj[index][e.target.name] = e.target.value;
		this.setState({
			[arrName]: changeArrOfObj
		});
	};
	onChangeText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	handleChange = (date, state) => {
		this.setState({
			[state]: date
		});
		console.log(date);
	};
	handleChange2 = (date, state) => {
		this.setState({
			[state]: date
		});
		console.log(date);
	};
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			sessionId: result.sessionId
		});
	};
	onHandleSubmitCheck = e => {
		e.preventDefault();
		const { driverIdCardNo } = this.state;
		if (Object.is(driverIdCardNo, '')) {
			this.setState = {
				checking: false
			};
		} else {
			this.setState = {
				checking: true
			};
		}
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const { sessionId, itemsBudget } = this.state;
		if (
			Object.is(sessionId, '') ||
			Object.is(itemsBudget[0].amount, '') ||
			Object.is(itemsBudget[0].description, '') ||
			Object.is(itemsBudget[0].particular, '')
		) {
			this.setState({
				errorMsg: true
			});
		} else {
			const data = {
				sessionId,
				itemsBudget
			};
			this.props.sharedActions
				.addRecord(
					'/api/BudgetPlan',
					data,
					this.props.BudgetItemsAction.addBudgetItem
				)
				.then(success => {
					this.setState({
						errorMsg: false,
						msg: true
					});
				})
				.catch(error => {});
		}
		console.log(this.state);
	};
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/Session',
				this.props.BudgetItemsAction.getSessionForBudgetItems
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};
	render() {
		const { session } = this.state.select;
		console.log('Session data', session);
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Budget Items
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.Message(
								'Budget Items Added Successfully!',
								'success',
								20,
								this.state.msg
							)}
							{this.Message(
								'Please fill the form properly!',
								'danger',
								20,
								this.state.errorMsg
							)}
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												value={this.props.name}
												readOnly={true}
												type="text"
												name="AdminName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Type
											</label>
											<input
												value={this.props.role}
												readOnly={true}
												type="text"
												name="AdminType"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-12" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Select Session
											</label>
											<select
												onChange={this.onSelectChange}
												class="custom-select"
												name="vehicleId"
											>
												<option selected={this.state.msg ? true : false}>
													Sessions..
												</option>
												{session &&
													session.map((item, index) => (
														<option
															key={index}
															value={`${JSON.stringify(item)}`}
														>
															{item.currentSession}
														</option>
													))}
											</select>
										</div>
									</div>
									<div class="col-md-12" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<AddMultipleItems
												onHandleTextChange={this.onChangeText}
												stateData={this.state}
												updateSpecialArrObjOnChange={
													this.updateSpecialArrObjOnChange
												}
												onClickAddItem={this.onClickAddItem}
												onClickDeleteItem={this.onClickDeleteItem}
											/>
										</div>
									</div>
								</div>
								<div class="text-center text-md-center">
									<button onClick={this.onHandleSubmit} class="btns">
										Add
									</button>
								</div>
							</form>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		BudgetItemsAction: bindActionCreators(BudgetItemsAction, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId,
		selectData: state.setBudgetItemsReducer.sessionSelect
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddBudgetItems);
