import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	select: [],
	sessionSelect: []
};

export default function setHomeWorkReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.SET_ALL_CLASS_NAME_INTO_HOMEWORK_SELECT:
			return {
				...state,
				select: action.payload
			};
		case actionTypes.SET_ALL_SESSION_NAME_INTO_HOMEWORK_SELECT:
			return {
				...state,
				sessionSelect: action.payload
			};

		case actionTypes.GET_ALL_HOMEWORK:
			return { ...state, Data: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_HOMEWORK:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_HOMEWORK:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.Id !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_HOMEWORK_RECORD:
			var adminData = [...state.Data];
			// var index = adminData.findIndex(obj => obj.Id === action.payload.id);
			// adminData[index].homeworkName = action.payload.homeworkName;
			// adminData[index].topicDescription = action.payload.topicDescription;
			// adminData[index].submissionDate = action.payload.submissionDate;
			// adminData[index].gradeName = action.payload.gradeName;
			// adminData[index].subjectName = action.payload.subjectName;
			// adminData[index].sectionName = action.payload.sectionName;
			// adminData[index].className = action.payload.className;
			// adminData[index].StaffFirstName = action.payload.staffName;
			// adminData[index].sessionName = action.payload.sessionName;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
