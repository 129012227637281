import React, { Component } from 'react';
import $ from 'jquery';
// import './viewAdmin.css';
import Axios from 'axios';
import config from '../../config/config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StaffActions from '../../Actions/StaffActions';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput';
import validator from '../../validator/registrationValidator';

const options = ['Mixed', 'Performance', 'Fixed'];

class NotificationAppraisal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,

			HOD: '',
			Management: '',
			Collegue: '',
			Student: '',
			Parents: '',

			type: '',
			staffId: '',
			nextDateOfInc: '',
			basicSalary: '',
			percentageBasic: '',
			percentagePerformance: '',
			accountId: '',
			accountType: '',
			name: '',
			count: 1,
			timeDuration: '********',
			enabled: false,
			id: null,
			getNextDate: null,
			isLoading: true,
			disablepage: false,
			disabledPB: true,
			disabledP: true,
			currentPage: 1,
			todosPerPage: 10,
			msg: '',
			diaOrCon: null,
			successflag: false,
			errors: {}
		};
	}

	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	isValid = () => {
		const { errors, isValid } = validator(this.state, 'checkRattingForm');
		if (!isValid) {
			this.setState({
				errors
			});
		}
		return isValid;
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.adminData !== nextProps.getNotificationData) {
			return {
				adminData: nextProps.getNotificationData
			};
		}
		return null;
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		// if(this.props.allAdminState.apiFlag){
		this.props.sharedActions
			.gAllData(
				'/api/AppraisalsCalculations',
				this.props.staffActions.getNotificationData
			)
			.then(success => {
				this.setState({
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});

				console.log(err);
			});
	}
	// }
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.props.staffActions.removeAppraisalData(itemId);
	};
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.t1.id === itemId);

		this.setState({
			modalData: getData,
			type: getData[0].t1.type,
			staffId: getData[0].t1.staffId,
			timeDuration: getData[0].t1.timeDuration,
			basicSalary: getData[0].t1.basicSalary,
			percentageBasic: getData[0].t1.percentageBasic,
			percentagePerformance: getData[0].t1.percentagePerformance,
			accountId: getData[0].t1.accountId,
			accountType: getData[0].t1.accountType,
			nextDateOfInc: getData[0].t1.nextDateOfInc,
			id: getData[0].t1.id
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleAppraisalTypeChange = e => {
		switch (e.target.value) {
			case 'Mixed':
				this.setState({
					disabledP: false,
					disabledPB: false,
					[e.target.name]: e.target.value,
					successflag: false
				});
				break;
			case 'Performance':
				{
					let { errors } = this.state;
					let getNewError = { ...errors };
					getNewError.percentageBasic = false;
					this.setState({
						disabledP: false,
						disabledPB: true,
						[e.target.name]: e.target.value,
						errors: getNewError,
						percentageBasic: '',
						percentagePerformance: '',
						successflag: false
					});
				}
				break;
			case 'Fixed':
				{
					let { errors } = this.state;
					let getNewError = { ...errors };
					getNewError.percentagePerformance = false;
					this.setState({
						disabledPB: false,
						disabledP: true,
						[e.target.name]: e.target.value,
						errors: getNewError,
						percentagePerformance: '',
						successflag: false
					});
				}
				break;
			default:
				const { errors } = this.state;
				let getNewError = { ...errors };
				getNewError.percentageBasic = false;
				getNewError.percentagePerformance = false;
				this.setState({
					disabledP: true,
					disabledPB: true,
					[e.target.name]: e.target.value,
					percentagePerformance: '',
					percentageBasic: '',
					errors: getNewError,
					successflag: false
				});
				break;
		}
	};
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const {
			type,
			timeDuration,
			staffId,
			id,
			basicSalary,
			percentageBasic,
			percentagePerformance,
			nextDateOfInc,
			errors,
			HOD,
			Management,
			Student,
			Parents,
			Collegue
		} = this.state;
		if (this.isValid()) {
			let data = {
				HOD,
				Management,
				Student,
				Parents,
				Collegue,
				accountId: this.props.name,
				accountType: this.props.role
			};
			this.props.sharedActions
				.simplePostReqWithData('/api/AppraisalsCalculations/', data, id)
				.then(success => {
					this.onTakeItemDelete(id);

					this.setState({ msg: 'Edit Record Successfully!' });
					this.props.snackbar();
				})
				.catch(error => {
					console.log(error);
				});
		}
	};
	setPreStateOverEditData = type => {
		switch (type) {
			case 'Mixed':
				this.setState({
					disabledP: false,
					disabledPB: false,
					successflag: false
				});
				break;
			case 'Performance':
				this.setState({
					disabledP: false,
					disabledPB: true,
					percentageBasic: '',
					successflag: false
				});
				break;
			case 'Fixed':
				this.setState({
					disabledPB: false,
					disabledP: true,
					percentagePerformance: '',
					successflag: false
				});
				break;

			default:
				this.setState({
					disabledP: true,
					disabledPB: true,
					percentagePerformance: '',
					percentageBasic: '',
					successflag: false
				});
				break;
		}
	};
	onFixedHit = () => {
		const { id, getNextDate } = this.state;
		let getNextDateTime = new Date(getNextDate).getTime();
		let getDateNow = new Date().getTime();
		let difference = getDateNow - getNextDateTime;

		this.props.sharedActions
			.simplePostReqWithData(
				'/api/AppraisalsCalculations/',
				{
					accountId: this.props.name,
					accountType: this.props.role
				},
				id
			)
			.then(success => {
				console.log(success);
				if (difference > 0 || difference === 0) {
					this.onTakeItemDelete(id);
				}
				this.setState({ msg: 'Done!' });
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onEditModalOpen = () => {
		const { adminData } = this.state;

		this.setState({ enabled: true });
		// this.setPreStateOverEditData(adminData['type']);
	};
	onEditClick = (type, id, nextDate) => {
		const { adminData } = this.state;
		if (type === 'Performance' || type === 'Mixed') {
			this.setState({
				diaOrCon: true,
				id,
				getNextDate: nextDate
			});
		} else {
			this.setState({
				diaOrCon: false,
				id,
				getNextDate: nextDate
			});
		}
	};
	onCloseModal = () => {
		console.log('check');
	};
	render() {
		const { adminData, modalData, errors } = this.state;

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return index <= 3 ? (
				<li
					class={`page-item ${
						this.state.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={this.handleClickNext}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			) : (
				''
			);
		});
		const { currentPage, todosPerPage } = this.state;
		const indexOfLastTodo = currentPage * todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
		const renderTodos = currentTodos.map((item, index) => {
			return (
				<tr style={{ padding: 2 }} key={index}>
					<th style={{ padding: 2 }} scope="row">
						{index + 1}
					</th>
					<td style={{ padding: 2 }}>{item.FirstName}</td>
					<td style={{ padding: 2 }}>{item.LastName}</td>
					<td style={{ padding: 2 }}>{item.Salary}</td>

					<td style={{ padding: 2 }}>{item.Joining}</td>
					<td style={{ padding: 2 }}>{item.AppraisalPeriod}</td>
					<td style={{ padding: 2 }}>{item.Type}</td>
					<td style={{ padding: 2 }}>{item.BasicPercentage + '%'}</td>
					<td style={{ padding: 2 }}>{item.PerformancePercentage + '%'}</td>
					<td style={{ padding: 2 }}>{item.NextDateofAppraisal}</td>
					<td style={{ padding: 2 }}>{item.Date}</td>
					<td style={{ padding: 2 }}>{item.Time}</td>
					<td style={{ padding: 2 }}>{item.AccountId}</td>
					<td style={{ padding: 2 }}>{item.AccountType}</td>

					<td style={{ padding: 2 }}>
						<a href="#" data-toggle="tooltip" title="Done!">
							<button
								id="firstbutton"
								onClick={() =>
									this.onEditClick(
										item.Type,
										item.AppraisalID,
										item.NextDateofAppraisal
									)
								}
								onMouseOver={this.btnTechHover()}
								data-toggle="modal"
								data-target="#myModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i
									style={{ color: '#43A047', fontSize: 30 }}
									class="fas fa-check-circle"
								></i>
							</button>
						</a>
					</td>
				</tr>
			);
		});
		const confirmationDialog = (
			<React.Fragment>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '30%',
						margin: '0 auto'
					}}
				>
					<div>
						<button
							disabled={this.state.enabled}
							data-dismiss="modal"
							onClick={this.onFixedHit}
							type="button"
							style={{ color: 'white' }}
							class="btn buttonAppear"
						>
							Yes
						</button>
					</div>
					<div>
						<button
							data-dismiss="modal"
							style={{ color: 'white' }}
							disabled={this.state.enabled}
							type="button"
							class="btn buttonAppear"
						>
							No
						</button>
					</div>
				</div>
			</React.Fragment>
		);
		const gradingForm = (
			<form onSubmit={this.onHandleSubmit}>
				{/* {modalData && modalData.map((item, index) => ( */}
				<div>
					<TextInput
						marginTop={20}
						feildName={'HOD'}
						inputLabelName={'HOD Ratting'}
						onHandleChange={this.onHandleTextChange}
						errors={errors}
						stateData={this.state}
						iconClassName={'fas fa-star'}
						placeholder={'rate between 1 to 10'}
						type={'number'}
						iconColor="	#FFD700"
						// disabledField={'disabledP'}
					/>
					<TextInput
						marginTop={20}
						feildName={'Management'}
						inputLabelName={'Management Ratting'}
						onHandleChange={this.onHandleTextChange}
						errors={errors}
						stateData={this.state}
						iconClassName={'fas fa-star'}
						placeholder={'rate between 1 to 10'}
						type={'number'}
						iconColor="	#FFD700"
						// disabledField={'disabledP'}
					/>
					<TextInput
						marginTop={20}
						feildName={'Student'}
						inputLabelName={'Student Ratting'}
						onHandleChange={this.onHandleTextChange}
						errors={errors}
						stateData={this.state}
						iconClassName={'fas fa-star'}
						placeholder={'rate between 1 to 10'}
						type={'number'}
						iconColor="	#FFD700"
						// disabledField={'disabledP'}
					/>
					<TextInput
						marginTop={20}
						feildName={'Parents'}
						inputLabelName={'Parents Ratting'}
						onHandleChange={this.onHandleTextChange}
						errors={errors}
						stateData={this.state}
						iconClassName={'fas fa-star'}
						placeholder={'rate between 1 to 10'}
						type={'number'}
						iconColor="	#FFD700"
						// disabledField={'disabledP'}
					/>
					<TextInput
						marginTop={20}
						feildName={'Collegue'}
						inputLabelName={'Collegue Ratting'}
						onHandleChange={this.onHandleTextChange}
						errors={errors}
						stateData={this.state}
						iconClassName={'fas fa-star'}
						placeholder={'rate between 1 to 10'}
						type={'number'}
						iconColor="	#FFD700"
						// disabledField={'disabledP'}
					/>
					{/* <div class="modal-footer"> */}
					<button
						type="submit"
						style={{ background: '#00435D', color: 'white', margin: 10 }}
						class="btn"
					>
						Done
					</button>
					{/* </div> */}
				</div>
				{/* ))} */}
			</form>
		);
		const MainContent = (
			<div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div>
									<div style={{ textAlign: 'center' }}>
										<h4>Are you sure ?</h4>
									</div>
									<div
										style={{ display: 'flex', justifyContent: 'space-evenly' }}
									>
										<div>
											<button
												disabled={this.state.enabled}
												data-dismiss="modal"
												onClick={() => this.onTakeItemDelete(this.state.id)}
												type="button"
												class="btn btn-primary buttonAppear"
											>
												Yes
											</button>
										</div>
										<div>
											<button
												data-dismiss="modal"
												disabled={this.state.enabled}
												type="button"
												class="btn btn-primary buttonAppear"
											>
												No
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									{this.state.diaOrCon ? 'Ratting' : 'Confirmation'}
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{this.state.diaOrCon ? gradingForm : confirmationDialog}
							</div>
							<div class="modal-footer">
								<button
									type="button"
									onClick={this.onCloseModal}
									style={{ background: '#00435D', color: 'white' }}
									class="btn"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">First Name</th>
								<th scope="col">Last Name</th>
								<th scope="col">Salary</th>
								<th scope="col">Joining</th>

								<th scope="col">Appraisal Period</th>
								<th scope="col">Type</th>
								<th scope="col">Basic %</th>

								<th scope="col">Performance %</th>
								<th scope="col">Next Appraisal %</th>
								<th scope="col">Date</th>
								<th scope="col">Time</th>
								<th scope="col">AccountType</th>
								<th scope="col">AccountId</th>
								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>{renderTodos}</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>
								Notification's&nbsp;<small class="text-muted">Details</small>
							</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								<li
									class={`page-item ${
										this.state.disableBPage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextBack}
										class="page-link"
										href="#"
									>
										{this.state.disableBPage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-backward"></i>
										)}
									</a>
								</li>
								{renderPageNumbers}
								<li
									class={`page-item ${
										this.state.disablepage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextFrwd}
										class="page-link"
										href="#"
									>
										{this.state.disablepage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-forward"></i>
										)}
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				{this.state.isLoading ? Loader : MainContent}
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		staffActions: bindActionCreators(StaffActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		getNotificationData: state.setStaffReducer.getNotificationData,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NotificationAppraisal);
