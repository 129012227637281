import * as actionTypes from '../actionTypes';

export function setAllPlansInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_PLANS,
		payload: allData
	};
}

export function getClassesForPlans(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_PLAN_SELECT,
		payload: allData
	};
}
export function getSessionsForPlans(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_PLAN_SELECT,
		payload: allData
	};
}
export function addPlans(Data) {
	return {
		type: actionTypes.ADD_PLANS,
		payload: Data
	};
}
export function updatePlansFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_PLANS,
		payload: setFlag
	};
}
export function updatePlans(data) {
	return {
		type: actionTypes.EDIT_PLANS_RECORD,
		payload: data
	};
}
export function deletePlans(id) {
	return {
		type: actionTypes.DELETE_PLANS,
		payload: id
	};
}
