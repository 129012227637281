import React from 'react';
import { RENDER_TOOLS } from '../../../utils/general';
import ContinuousCreateTools from '../Forms/Tool/ContinuousCreateTools';
import CaSubToolCreation from '../Forms/Subtool/CaSubToolCreation';

export default function ContnuousRenderTools(props) {
	const { id, type } = props.match.params;
	debugger;

	return (
		<div>
			{type === RENDER_TOOLS ? (
				<ContinuousCreateTools {...props} />
			) : (
				<CaSubToolCreation {...props} />
			)}
		</div>
	);
}
