import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import LoaderWrapper from '../../../components/HOC/LoaderWrapper';
import SnackBar from '../../../components/SnackBar/SnackBar';
import ViewComponent from '../../../components/ViewComponent/ViewComponent';
import useCustomHttpWithDataMiddleware from '../../../hooks/Services/httpWithCustomDataMiddleware';
import {
	addDataMiddleware,
	viewClassReturn,
	disableColorOverCondition
} from '../../../utils/general/index';

function StudentRoleDiscipline(props) {
	const [snackbarMessage, setMsg] = useState('');
	const [studentData, setStudentData] = useState('');
	const [classId, setClassId] = useState('');
	const [sectionId, setSectionId] = useState('');
	const [subjectId, setSubjectId] = useState('');
	const [sessionId, setSessionId] = useState('');
	const [term, setTerm] = useState('');
	const [customLoader, setCustomLoader] = useState(false);

	var [isLoader, fetchData, setFetchData] = useCustomHttpWithDataMiddleware(
		`/api/StudentDetail?userName=${props.name}`,
		[],
		{
			fields: [
				{
					type: 'select',
					selectName: 'Choose Term',
					feildName: 'term',
					optionsArray: ['1st Term', '2nd Term', '3rd Term'],
					optionType: 'static',
					errors: {}
				}
			],
			Action: [
				{
					term: '',
					isEnable: false,
					exceptionCase: true,

					type: 'actionLink',
					title: 'View',
					initialPath: '/dashboard/StudentRoleDiscipline',
					iconName: 'fas fa-list',
					iconColor: '#01AC8A'
				}
			]
		}
	);

	const onSetTerm = (e, rowIndex, feildName, mainData) => {
		const getCloneData = JSON.parse(JSON.stringify(fetchData));

		if (e.target.value == 'Choose Term') {
			setTerm('');

			getCloneData[rowIndex]['Action'][0]['isEnable'] = false;

			getCloneData[rowIndex]['Action'][0]['term'] = e.target.value;

			setFetchData(getCloneData);
		} else {
			setTerm(e.target.value);

			getCloneData[rowIndex]['Action'][0]['isEnable'] = true;
			getCloneData[rowIndex]['Action'][0]['term'] = e.target.value;

			setFetchData(getCloneData);
		}
	};

	const onSearch = () => {
		setCustomLoader(true);
		props.sharedAction
			.getDataWithoutDispatch(
				`/api/StudentListDisciplinary?classId=${classId}&sectionId=${sectionId}&term=${term}&subjectId=${subjectId}&sessionId=${sessionId}`
			)
			.then(success => {
				let requiredData = addDataMiddleware(
					{
						marks: '',
						classId,
						sessionId,
						subjectId,
						sectionId,
						term,
						accountId: props.name,
						accountType: props.role
					},
					success
				);
				setStudentData(requiredData);
				setCustomLoader(false);
			})
			.catch(error => {
				console.log(error);
				if (error && error.response && error.response.status == 404) {
					// 404-Error no understanding of 404 Error
					setStudentData([]);

					setMsg('404 Not Found');
					props.snackbar();
					setCustomLoader(false);
				}
				if (error && error.response && error.response.status == 400) {
					setStudentData([]);
					console.log(error);

					setMsg(error.response.data.Message);
					props.snackbar();

					setCustomLoader(false);
				}
			});
	};

	const onSubmit = () => {
		props.sharedAction
			.simpleAddRequest(`/api/DisciplinaryRecord`, {
				DisciplinaryRecord: [...studentData]
			})
			.then(success => {
				setTerm('Choose Term');
				setSectionId('');
				setClassId('');
				setSubjectId('');
				setSessionId('');
				setStudentData([]);
				setMsg('Successfully Submitted');
				props.snackbar();
			})
			.catch(error => {
				if (error && error.response && error.response.status == 404) {
					//404-Error no understanding of 404 Error
					setMsg('404 Not Found');

					props.snackbar();
				}
			});
	};

	return (
		<div>
			<h2 className="text-center generalHead">Student Discipline</h2>
			<SnackBar backColor={'#000'} msg={snackbarMessage} />

			<LoaderWrapper marginTop={20} isLoading={isLoader ? true : false}>
				<ViewComponent
					exceptionalHandler={viewClassReturn}
					conditionalIconsColor={disableColorOverCondition}
					extraRedirectProperty={'term'}
					mainData={fetchData}
					headData={[
						'S #',
						'Student Name',
						'Class Name',
						'Section',
						'Session',
						'Status',
						'Term',
						'Actions'
					]}
					excludedData={[
						'userName',
						'studentId',
						'classId',
						'sectionId',
						'sessionId'
					]}
					onSetTerm={onSetTerm}
					redirectIds={[
						'studentId',
						'classId',
						'sectionId',
						'sessionId',
						'term'
					]}
				/>
			</LoaderWrapper>
		</div>
	);
}
function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StudentRoleDiscipline);
