import React, { useState } from 'react';
import { Types } from '../../hooks/HookReducers/StaffReducer';
import RenderDynamicSiblingsRow from '../../Container/Admission/RenderDynamicSiblingsRow';
import Button from '../Button';
import ValidationText from '../../utils/app_constants/validate_messages_constants';

const Skills = props => {
	const {
		staffId,
		dispatch,
		errors,
		onPreviousClick,
		getValidatedArray,
		edit = false,
		onUpdate,
		editData = {},
		putLoader,
		newState
	} = props;
	debugger;
	const { ON_DYANMIC_ADD, ON_DYANMIC_DELETE } = Types;
	const [Skills, setSkills] = useState(
		edit
			? editData
			: [
					{
						skillName: ''
					}
			  ]
	);
	debugger;
	const onNextStep = async e => {
		if (!getValidatedArray(e, Skills, 'skills')) {
			debugger;
			await dispatch({ type: Types.ON_SKILLS_DISPATCH, payload: { Skills } });
			debugger;
			console.log(newState);
		}
	};
	const updateSpecialArrObjOnChange = (e, index, arrName) => {
		const changeArrOfObj = [...Skills];
		changeArrOfObj[index][e.target.name] = e.target.value;

		// arrName = changeArrOfObj;
		// console.log(arrName);
		setSkills(changeArrOfObj);
	};
	const onClickAddItem = (arr, obj) => {
		let getStateArr = [...arr];
		getStateArr = [...getStateArr, obj];
		console.log(getStateArr);

		setSkills(getStateArr);
	};
	const onClickDeleteItem = arr => {
		let getStateArr = [...arr];
		let getDeleteLength = getStateArr.length - 1;

		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			setSkills(getNewDetails);
		}
	};
	const onEditHandleSubmit = e => {
		debugger;
		const arrayItem = Skills.map(
			({ staffId, interviewId, id, ...keepAttrs }) => keepAttrs
		);
		if (!getValidatedArray(e, arrayItem, 'skills')) {
			onUpdate(dispatch, 'ON_SET_SKILLS_EDIT', {
				name: 'StaffSkills',
				Skills: Skills
			});
		}
	};

	return (
		<div>
			{errors.skills ? (
				<p style={{ color: '#ff0000' }}>
					{ValidationText.DYANMIC_ARRAY_VALIDATION}
				</p>
			) : (
				''
			)}

			<div style={{ width: '100%' }}>
				<table class="table table-hover">
					<thead style={{ background: '#01AC8A', color: 'white' }}>
						<tr>
							<th style={{ textAlign: 'center' }} scope="col">
								#
							</th>
							<th style={{ textAlign: 'center' }} scope="col">
								Skill Name
							</th>
						</tr>
					</thead>
					<tbody>
						{Skills.map((item, index) => (
							<tr key={index}>
								<th style={{ textAlign: 'center' }} scope={'row'}>
									{index + 1}
								</th>
								<RenderDynamicSiblingsRow
									arrName={'Skills'}
									rowIndex={index}
									stateData={{ Skills: [...Skills] }}
									dispatch={dispatch}
									hookReuse={false}
									updateSpecialArrObjOnChange={updateSpecialArrObjOnChange}
									itemObj={item}
								/>
							</tr>
						))}
					</tbody>
					<div className={'row'} style={{ justifyContent: 'center' }}>
						<div>
							<div>
								<button
									onClick={() =>
										onClickAddItem(Skills, {
											skillName: ''
										})
									}
									style={{
										background: '#0A4F5E',
										color: '#FFFFFF',
										borderRadius: 100
									}}
									type="button"
									class="btn"
								>
									<i class="fas fa-plus"></i>
								</button>
							</div>
							<div>
								<button
									onClick={() => onClickDeleteItem(Skills)}
									style={{ color: '#FFFFFF', borderRadius: 100 }}
									type="button"
									class="btn btn-danger"
								>
									<i class="far fa-trash-alt"></i>
								</button>
							</div>
						</div>
					</div>
				</table>
			</div>
			<br />
			<br />
			<br />
			{/*<div class="text-center text-md-center">*/}
			{/*<button class="btns">Add</button>*/}
			{/*</div>*/}
			{!edit ? (
				<div
					style={{
						marginTop: 10,
						display: 'flex',
						justifyContent: 'flex-start'
					}}
				>
					<div>
						<button
							class="btns"
							style={{ marginRight: 25 }}
							onClick={e => onNextStep(e)}
						>
							Next
						</button>
					</div>
					<div>
						<button class="btns" onClick={() => onPreviousClick()}>
							Previous
						</button>
					</div>
				</div>
			) : (
				''
			)}
			{edit ? (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						stateData={{ isLoading: putLoader }}
						btnName="Update"
						buttonClass="btn-smart"
						loaderBmargin={5}
						marginTop={20}
						onHandleSubmit={onEditHandleSubmit}
					/>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default Skills;
