import React from 'react';

const ConfirmationButton = props => {
	const { removeElement } = props;
	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-evenly'
				}}
			>
				<div>
					<input
						onClick={() => removeElement()}
						style={{ borderColor: '#fff' }}
						name=""
						id=""
						class="btn btn-primary buttonAppear"
						type="button"
						value="Yes"
					/>
				</div>
				<div>
					<input
						style={{ borderColor: '#fff' }}
						data-dismiss="modal"
						name=""
						id=""
						class="btn btn-primary buttonAppear"
						type="button"
						value="No"
					/>
				</div>
			</div>
		</div>
	);
};

export default ConfirmationButton;
