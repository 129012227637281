import React, { Component } from 'react';
import './Form.css';

class StudentParentInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { parentData } = this.props;
		console.log(parentData, 'Parent Info Haii');
		return (
			<div>
				<section class="mb-4">
					<div class="row print">
						<div class="col-md-12">
							<div class="md-form mb-0">
								<h2
									style={{
										backgroundColor: '#0A4F5E',
										textAlign: 'center',
										color: 'white'
									}}
								>
									Parent Information:
								</h2>
							</div>
						</div>
					</div>
					<div class="container">
						<div class="row rowCheck" style={{ marginTop: 10 }}>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Father Name:</label>{' '}
								&nbsp;&nbsp; {parentData && parentData.fatherName}{' '}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Occupation:</label>{' '}
								&nbsp;&nbsp; {parentData && parentData.occupation}{' '}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Parent Mobile No: </label>
								&nbsp;&nbsp; {parentData && parentData.parentMobileNo}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Mother Name:</label>
								&nbsp;&nbsp; {parentData && parentData.motherName}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Emergency Contact:</label>
								&nbsp;&nbsp; {parentData && parentData.emergencyContact}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Parent Email Id:</label>
								&nbsp;&nbsp; {parentData && parentData.parentEmailId}{' '}
							</div>

							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Guardian Name:</label>
								&nbsp;&nbsp; {parentData && parentData.garduainName}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Income:</label>
								&nbsp;&nbsp; {parentData && parentData.income}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Parent CNIC:</label>{' '}
								&nbsp;&nbsp; {parentData && parentData.parentIdCardNo}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Parent Id Card No:</label>{' '}
								&nbsp;&nbsp; {parentData && parentData.parentIdCardNo}
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default StudentParentInfo;
