import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	selectSupplier: [],
	select: [],
	barcodeSelect: []
};

export default function setStockReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.ADD_STOCK:
			return { ...state };

		case actionTypes.SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_SELECT:
			return {
				...state,
				select: [...action.payload]
			};
		case actionTypes.SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_BARCODE:
			return {
				...state,
				barcodeSelect: action.payload
			};
		case actionTypes.SET_ALL_SUPPLIER_NAME_INTO_ADD_INVENTORY_ITEM_SELECT:
			return {
				...state,
				selectSupplier: [...action.payload]
			};
		case actionTypes.GET_ALL_STOCK:
			return {
				...state,
				Data: [...action.payload]
			};
		case actionTypes.SET_LOADER_FLAG_STOCK_ADD:
			return {
				...state,
				apiFlag: action.payload
			};
		case actionTypes.DELETE_STOCK:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);

			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_STOCK_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.id === action.payload.id);

			adminData[index].name = action.payload.name;
			adminData[index].price = action.payload.price;
			adminData[index].type = action.payload.type;
			adminData[index].t1.name = action.payload.supplierName;
			adminData[index].t3.name = action.payload.categoryName;
			return {
				...state,
				Data: [...adminData]
			};
		default:
			return state;
	}
}
