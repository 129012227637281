import React, { useEffect, useState, useRef } from 'react';
import { ViewDataTable } from '../../components/Edit';
import Spinner from '../../components/Spinner/Spinner';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import SnackBar from '../../components/SnackBar/SnackBar';
import Axios from 'axios';
import * as sharedActions from '../../Actions/sharedActions';

import config from '../../config/config';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Button from '../../components/Button';
import Colors from '../../utils/app_constants/colors_constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import Pagination from '../../components/Pagination/Pagination';
import useHttp from '../../hooks/http';
import useHttpWithGetSpecificData from '../../hooks/Services/httpWithGetSpecificData';
import CustomBadge from '../../components/CustomBadge/CustomBadge';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import general, { dataFilteration } from '../../utils/general';
import { General } from '../../utils';

let tHead = [
	'Application Name',
	'Father Name',
	'Class Name',
	'Section',
	'Session',
	'Account ID',
	'Account Type',
	'Actions'
];
let renderFields = [
	'applicantName',
	'fatherName',
	'className',
	'section',
	'session',
	'accountId',
	'accountType'
];

const ViewAddForm = props => {
	const [isSessionLoading, sessions] = useHttp('/api/Session', []);

	const [sessionId, setSessionId] = useState('');

	const [sections, setSections] = useState([]);
	const [sectionId, setSectionId] = useState('');
	const [classes, setClasses] = useState([]);
	const [isClassSectionLoading, classSectionData] = httpWithRequiredSetData(
		'/api/ClassesSections',
		[],
		data => {
			setClasses(data['classes']);
		}
	);
	const [classId, setClassId] = useState('');

	const [disableBPage, setdisableBPage] = useState(true);

	const [customPageSize, setCustomPageSize] = useState('10');
	const [dropDownSearch, setDropDownSearch] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [num, setNum] = useState(1);
	const [prevNum, setPrevNum] = useState(1);
	const [numOfRenderRecord, setNumOfRenderRecord] = useState(10);
	const [pageNumber, setPageNumber] = useState([]);
	const [staffData, setStaffData] = useState(null);
	const [disablepage, setdisablepage] = useState(false);
	const [customLoader, setCustomLoader] = useState(false);
	const [pagination, setPaginationData] = useState({});
	const [getdepartmentId, setDepartmentId] = useState(null);
	const [postId, setPostId] = useState('');
	const [filteredPost, setFilteredPost] = useState([]);
	const mounted = useRef();

	const [
		isLoading,
		fetchData,
		setFetchData,
		setIsLoading
	] = httpWithRequiredSetData(
		'/api/AllStudentAdmission?pageNumber=1&pageSize=10',
		[],
		data => {
			let pageNumber = [];
			for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
				pageNumber.push(i);
			}
			setPageNumber(pageNumber);
			setStaffData(data['enq11']);

			setPaginationData(data['paginationMetadata']);
		}
	);
	const [isDepartLoading, departmentData = []] = useHttpWithGetSpecificData(
		'/api/StaffPostDepart',
		[],
		'department'
	);
	const [isPostLoading, posts = []] = useHttpWithGetSpecificData(
		'/api/StaffPostDepart',
		[],
		'post'
	);

	const onSectionChange = inputSectionId => {
		if (inputSectionId !== 'Choose Section') {
			setIsLoading(true);
			setSectionId(inputSectionId);
			props.sharedAction
				.getDataWithoutDispatch(
					`/api/StudentAdmissionForm?classId=${classId}&sectionId=${parseInt(
						inputSectionId
					)}&sessionId=${sessionId}&pageNumber=${currentPage}&pageSize=${parseInt(
						customPageSize
					)}`
				)
				.then(success => {
					console.log('postid data: ', success['enq11']);
					setStaffData(success['enq11']);
					let pageNumber = [];
					for (
						let i = 1;
						i <= success['paginationMetadata']['totalPages'];
						i++
					) {
						pageNumber.push(i);
					}
					setPageNumber(pageNumber);
					setPaginationData(success['paginationMetadata']);

					setIsLoading(false);
				})
				.catch(error => {
					setIsLoading(false);
				});
		}
	};
	const onSessionChange = sessionId => {
		setClassId('');
		setSectionId('');
		if (sessionId !== 'Choose Session') {
			setIsLoading(true);
			setSessionId(sessionId);

			props.sharedAction
				.getDataWithoutDispatch(
					`/api/StudentSession?sessionId=${parseInt(
						sessionId
					)}&pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}`
				)
				.then(success => {
					console.log('postid data: ', success['enq11']);
					setStaffData(success['enq11']);
					let pageNumber = [];
					for (
						let i = 1;
						i <= success['paginationMetadata']['totalPages'];
						i++
					) {
						pageNumber.push(i);
					}
					setPageNumber(pageNumber);
					setPaginationData(success['paginationMetadata']);

					setIsLoading(false);
				})
				.catch(error => {
					setIsLoading(false);
				});
		}
	};
	const onClassChange = inputClassId => {
		setSectionId('');
		if (inputClassId !== 'Choose Class') {
			console.log(classSectionData);

			let getSection = General.dataFilteration(
				classSectionData['gradeSection'],
				['sectionId', 'section'],
				{
					id: inputClassId
				}
			);

			setIsLoading(true);
			setClassId(inputClassId);
			props.sharedAction
				.getDataWithoutDispatch(
					`/api/StudentAdmissionForm?classId=${parseInt(
						inputClassId
					)}&sessionId=${sessionId}&pageNumber=${currentPage}&pageSize=${parseInt(
						customPageSize
					)}`
				)
				.then(success => {
					console.log('postid data: ', success['enq11']);
					setStaffData(success['enq11']);
					let pageNumber = [];
					for (
						let i = 1;
						i <= success['paginationMetadata']['totalPages'];
						i++
					) {
						pageNumber.push(i);
					}
					setPageNumber(pageNumber);
					setPaginationData(success['paginationMetadata']);
					setSections(getSection);

					setIsLoading(false);
				})
				.catch(error => {
					setIsLoading(false);
				});
		}
	};

	useEffect(() => {
		if (!mounted.current) {
			mounted.current = true;
		} else {
			$('[data-toggle="tooltip"]').tooltip();
		}
	});
	useEffect(() => {
		return () => {
			$('[data-toggle="tooltip"]').tooltip('hide');
		};
	}, []);
	const handleClickNextFrwd = () => {
		console.log('pagelength: ', pageNumber.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumber.length) {
			setdisablepage(true);
		} else {
			setCurrentPage(prevState => prevState + 1);
			console.log(currentPage);

			setdisableBPage(false);
		}
	};

	useEffect(() => {
		console.log('Do something after counter has changed', currentPage);
		setIsLoading(true);
		props.sharedAction
			.getDataWithoutDispatch(
				General.condtionalServices(
					classId,
					sectionId,
					sessionId,
					currentPage,
					customPageSize
				)
			)
			.then(success => {
				setStaffData(success['enq11']);
				setIsLoading(false);
				let pageNumber = [];
				for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
					pageNumber.push(i);
				}
				setPageNumber(pageNumber);
				setPaginationData(success['paginationMetadata']);
			})
			.catch(error => {
				setIsLoading(false);
			});
	}, [currentPage, getdepartmentId, customPageSize]);
	const handleClickNext = event => {
		setCurrentPage(Number(event.target.id));
		setdisableBPage(false);
		setdisableBPage(false);
	};
	const handleClickNextBack = () => {
		if (currentPage === 1) {
			setdisableBPage(true);
			setdisablepage(false);
		} else {
			setCurrentPage(prevState => prevState - 1);
		}
	};
	const [msg, setSnackMsg] = useState('');
	const [dId, setDId] = useState(null);
	const btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

	const onChangePageSize = e => {
		if (e.target.value !== 'Page Entries') {
			setCustomPageSize(e.target.value);
			setCurrentPage(1);
		}
	};
	// Delete function

	const setDeleteID = id => {
		setDId(id);
	};
	const onClickDelete = id => {
		console.log(dId);

		let token = '';
		if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
		Axios.delete(`${config.localhttp}/api/StudentAdmissionForm/${dId}`, {
			data: { id }, // or data: jdId, depending on how you handle it in the back end
			headers: {
				Authorization: 'Bearer ' + token
			}
		})
			.then(success => {
				// if (success.status === 200) {
				let getNewFilterArray = staffData.filter(item => item.id !== dId);
				setSnackMsg('Remove Successfully!');
				setStaffData(getNewFilterArray);
				props.snackbar();
				setDId(null);
				// }
			})
			.catch(error => {
				console.log(error);
			});
	};

	useEffect(() => {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
	}, []);

	console.log('fetchd data: ', fetch);
	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					style={{
						padding: 2,
						border: `1px solid ${Colors.SEA_GREEN_THEME}`,
						borderColor: Colors.SEA_GREEN_THEME
					}}
					scope="row"
				>
					{index + 1}
				</th>
				{renderFields.map((item1, index) => (
					<td
						style={{
							fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
							border: `1px solid ${Colors.SEA_GREEN_THEME}`,
							padding: 2,
							borderColor: Colors.SEA_GREEN_THEME
						}}
					>
						{item[item1]}
					</td>
				))}
				<td
					style={{
						border: `1px solid ${Colors.SEA_GREEN_THEME}`,
						padding: 2,
						borderColor: Colors.SEA_GREEN_THEME
					}}
				>
					<a href="#" data-toggle="tooltip" title="Edit!">
						<Link to={`/dashboard/EditAddRecord/${item.id}`}>
							<button
								id="firstbutton"
								// onClick={() => this.onEditClick(item.id)}
								onMouseOver={btnTechHover()}
								data-toggle="modal"
								data-target="#myModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i class="fas fa-pen"></i>
							</button>
						</Link>
					</a>
					<Link to={`/dashboard/PrintAdmissionFormFinal/${item.id}`}>
						<button
							id="firstbutton"
							// onClick={() => this.onEditClick(item.id)}
							onMouseOver={btnTechHover()}
							data-toggle="modal"
							data-target="#myModal"
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								marginLeft: 5,
								background: 'none',
								marginBottom: 5
							}}
						>
							<a href="#" data-toggle="tooltip" title="View!">
								<i style={{ color: '#01AC8A' }} class="fas fa-list"></i>
							</a>
						</button>
					</Link>
					<a href="#" data-toggle="tooltip" title="Delete!">
						<button
							onClick={() => setDeleteID(item.id)}
							data-toggle="modal"
							data-target="#myModal1"
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								background: 'none',
								marginLeft: 5,
								marginBottom: 5
							}}
						>
							<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
						</button>
					</a>
					<Link to={`/dashboard/UploadFilesView/${item.id}`}>
						<a data-toggle="tooltip" title="Files!">
							<button
								// onClick={() => this.onEditClick(item.id)}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i
									style={{ color: Colors.LIGHT_GREEN }}
									class="fas fa-file-upload"
								></i>
							</button>
						</a>
					</Link>
					<Link to={`/dashboard/StudentImageUpload/${item.id}/edit`}>
						<a data-toggle="tooltip" title="picture">
							<button
								// onClick={() => this.onEditClick(item.id)}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i style={{ color: Colors.BLACK }} class="fas fa-portrait"></i>
							</button>
						</a>
					</Link>
				</td>
			</tr>
		));

	return (
		<div>
			<SnackBar msg={msg} />
			<div class="modal" id="myModal1">
				<div class="modal-dialog">
					<div class="modal-content modal_content_custom">
						<div
							style={{
								borderBottomLeftRadius: 30,
								borderBottomRightRadius: 30,
								borderTopLeftRadius: 18,
								borderTopRightRadius: 18
							}}
							class="modal-header modal_custom_header"
						>
							<h4 style={{ color: 'white' }} class="modal-title">
								Confirm
							</h4>
							<button
								style={{ color: 'white' }}
								type="button"
								class="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div class="modal-body">
							<div>
								<div style={{ textAlign: 'center' }}>
									<h4>Are you sure ?</h4>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-evenly'
									}}
								>
									<div>
										<button
											// disabled={this.state.enabled}
											data-dismiss="modal"
											onClick={() => onClickDelete(dId)}
											type="button"
											class="btn btn-primary buttonAppear"
										>
											Yes
										</button>
									</div>
									<div>
										<button
											data-dismiss="modal"
											// disabled={this.state.enabled}
											type="button"
											class="btn btn-primary buttonAppear"
										>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ textAlign: 'right' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h2 className="h1-responsive font-weight-bold text-left my-4 generalHead">
							{' '}
							Admission Details{' '}
						</h2>
					</div>
					<div
						style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
					>
						<Pagination
							disableBPage={disableBPage}
							disablepage={disablepage}
							currentPage={currentPage}
							pageNumber={pageNumber}
							handleClickNext={handleClickNext}
							handleClickNextBack={handleClickNextBack}
							handleClickNextFrwd={handleClickNextFrwd}
						/>
						<div
							style={{
								width: 'fit-content',
								marginTop: -34,
								display: 'flex',
								justifyContent: 'flex-end',
								marginLeft: 'auto'
							}}
						>
							<SelectionInput
								selectName="Entries"
								selectFont={14}
								selectFontWeight={'bold'}
								newFlag="customWidth"
								feildName="customPageSize"
								selectedText={'Page Entries'}
								optionsArrys={['10', '20', '30', '40', '50']}
								errors={{}}
								isValidte={false}
								stateData={{
									customPageSize: customPageSize
								}}
								optionType="static"
								onHandleChange={onChangePageSize}
								useFlag={false}
							/>
						</div>
					</div>
				</div>
				<CustomFilterSectionLayout displayDirection="column">
					<div
						style={{
							width: '30%',
							margin: '0 auto',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<SelectionInput
							newFlag="customWidth"
							feildName="sessionId"
							selectedText={'Choose Session'}
							optionsArrys={sessions && sessions['session']}
							errors={{}}
							isValidte={false}
							stateData={{
								sessionId: sessionId
							}}
							optionType="dynamic"
							onHandleChange={e => onSessionChange(e.target.value)}
							property={'currentSession'}
							propertyId={'sessionId'}
							useFlag={false}
						/>

						{sessionId !== '' ? (
							<React.Fragment>
								<SelectionInput
									newFlag="customWidth"
									feildName="classId"
									selectedText={'Choose Class'}
									optionsArrys={classes}
									errors={{}}
									isValidte={false}
									stateData={{
										classId: classId
									}}
									optionType="dynamic"
									onHandleChange={e => onClassChange(e.target.value)}
									property={'className'}
									propertyId={'id'}
									useFlag={false}
								/>
								<SelectionInput
									newFlag="customWidth"
									feildName="sectionId"
									selectedText={'Choose Section'}
									optionsArrys={sections}
									errors={{}}
									isValidte={false}
									stateData={{
										sectionId: sectionId
									}}
									optionType="dynamic"
									onHandleChange={e => onSectionChange(e.target.value)}
									property={'section'}
									propertyId={'sectionId'}
									useFlag={false}
								/>
							</React.Fragment>
						) : (
							''
						)}
					</div>
				</CustomFilterSectionLayout>

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span className="customBadge" style={{ fontSize: 14 }}>
						Page: {currentPage}
					</span>

					<span className="customBadge" style={{ fontSize: 14 }}>
						Total Page: {pagination['totalPages']}
					</span>
				</div>
			</div>

			{isLoading ? (
				<Spinner />
			) : (
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								{tHead.map(item => (
									<th
										style={{
											border: `1px solid ${Colors.SEA_GREEN_THEME}`,
											borderColor: Colors.WHITE
										}}
										scope="col"
									>
										{item}
									</th>
								))}
							</tr>
						</thead>
						<tbody>{bodyRendering(staffData)}</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	null,
	mapDispatchToProps
)(ViewAddForm);
