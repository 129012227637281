import React from 'react';
import ViewRendering from '../../../components/ViewRendering';
import { renderingStuff, images } from '../../../utils';

export default function StaffMainScreen(props) {
	return (
		<div>
			<h3 className="dashboardHead text-center">Staff Module</h3>

			<ViewRendering
				linkImageWidth={170}
				data={renderingStuff.StaffModules_Links}
			/>
		</div>
	);
}
