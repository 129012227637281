import React, { Component } from 'react';
import * as sharedActions from '../../Actions/sharedActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../../components/HOC/LoaderWrapper';

class FeeReceipt extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ReceiptData: '',
			StudentFeeTypes: [],
			isLoading: true
		};
	}
	componentDidMount() {
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/GetInVoiceFee/?voucherId=${this.props.match.params.id}`
			)
			.then(success => {
				debugger;
				this.setState({
					isLoading: false,
					ReceiptData: success.BindStudentFeeRecords,
					StudentFeeTypes: success.StudentFeeTypes
				});
			})
			.catch(error => {
				this.setState({
					isLoading: true
				});
				debugger;
			});
	}
	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};
	render() {
		const { ReceiptData, StudentFeeTypes } = this.state;
		return (
			<div>
				<Loader isLoading={this.state.isLoading}>
					<div id="div1">
						<div class="container">
							<div class="text-left font-weight-bold">
								<h3>Dorat Al Khaleej</h3>
							</div>
							<div class="text-left font-weight-bold">
								<h5>SCHOOL COPY</h5>
							</div>
							<table class="table table-sm">
								<thead>
									<tr>
										<th scope="col">Date: {ReceiptData.date}</th>
										<th scope="col">Receipt No: {ReceiptData.id}</th>
									</tr>
								</thead>
							</table>
							<div class="row">
								<div class="col-md-6">
									<label>Student Name:</label>
									<label class="fontBoldColor">{ReceiptData.name}</label> <br />
									<label>Class:</label>
									<label class="fontBoldColor">
										{ReceiptData.className} - {ReceiptData.section}
									</label>
									<br />
									<label>Fee Month:</label>
									<label class="fontBoldColor">{ReceiptData.feeMonths}</label>
									<br />
									<label>Paid By:</label>
									<label class="fontBoldColor">
										{ReceiptData.paymentType}
									</label>{' '}
									<br />
								</div>
								<div class="col-md-6">
									<label>Amount: </label>
									<label class="fontBoldColor text-center">
										{ReceiptData.totalAmount}/.
									</label>{' '}
									<br />
									{this.state.StudentFeeTypes.map((item, index) => (
										<React.Fragment>
											<li>
												{item.feeType} :{' '}
												<label class="fontBoldColor">{item.fee}</label>
											</li>
										</React.Fragment>
									))}
									<br />
									<label>Payable Type:</label>
									<label class="fontBoldColor">{ReceiptData.payableType}</label>
									<br />
									<label>Balance:</label>
									<label class="fontBoldColor">
										{ReceiptData.balance}/.
									</label>{' '}
									<br />
									<label>Total Amount: </label>
									<label class="fontBoldColor">
										{ReceiptData.totalAmount}/.
									</label>
								</div>
							</div>
							<br />
							<br />
							<hr
								style={{ backgroundColor: 'green', borderStyle: 'bold' }}
							></hr>
							<div class="text-left">
								<p class="font-weight-bold">Powered By: School Smart</p>
							</div>
							{/* Parent Copy */}
							<br />
							<br />
							<br />
							<br />
							<div class="text-left font-weight-bold">
								<h3>Dorat Al Khaleej</h3>
							</div>
							<div class="text-left font-weight-bold">
								<h5>PARENT COPY</h5>
							</div>
							<table class="table table-sm">
								<thead>
									<tr>
										<th scope="col">Date: {ReceiptData.date}</th>
										<th scope="col">Receipt No: {ReceiptData.id}</th>
									</tr>
								</thead>
							</table>
							<div class="row">
								<div class="col-md-6">
									<label>Student Name:</label>
									<label class="fontBoldColor">{ReceiptData.name}</label> <br />
									<label>Class:</label>
									<label class="fontBoldColor">
										{ReceiptData.className} - {ReceiptData.section}
									</label>
									<br />
									<label>Fee Month:</label>
									<label class="fontBoldColor">{ReceiptData.feeMonths}</label>
									<br />
									<label>Paid By:</label>
									<label class="fontBoldColor">
										{ReceiptData.paymentType}
									</label>{' '}
									<br />
								</div>
								<div class="col-md-6">
									<label>Amount: </label>
									<label class="fontBoldColor text-center">
										{ReceiptData.totalAmount}/.
									</label>{' '}
									<br />
									{this.state.StudentFeeTypes.map((item, index) => (
										<React.Fragment>
											<li>
												{item.feeType} :{' '}
												<label class="fontBoldColor">{item.fee}</label>
											</li>
										</React.Fragment>
									))}
									<br />
									<label>Payable Type:</label>
									<label class="fontBoldColor">{ReceiptData.payableType}</label>
									<br />
									<label>Balance:</label>
									<label class="fontBoldColor">
										{ReceiptData.balance}/.
									</label>{' '}
									<br />
									<label>Total Amount: </label>
									<label class="fontBoldColor">
										{ReceiptData.totalAmount}/.
									</label>
								</div>
							</div>
							<br />
							<br />

							<hr
								style={{ backgroundColor: 'green', borderStyle: 'bold' }}
							></hr>
							<div class="text-left">
								<p class="font-weight-bold">Powered By: School Smart</p>
							</div>
						</div>
					</div>
					<div class="text-center text-md-right">
						<label
							for="name"
							class=""
							onClick={() => this.PrintContent('div1')}
						>
							<i
								className="fas fa-print fa-2x animated fadeIn"
								style={{ marginRight: 4 }}
							/>
							Print It Out
						</label>
					</div>
				</Loader>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	null,
	mapDispatchToProps
)(FeeReceipt);
