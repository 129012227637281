import React from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import general from '../../utils/general'

class ViewLeaveByStudent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leaveData: [],
            isLoading: false,
            isLoadingInitialData: true,
            disablepage: false,
            disableBPage: true,
            currentPage: 1,
            todosPerPage: 10,
            pageCounter: 10,
            errorMsg: false
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/StudentDetail?userName=${this.props.name}`
            )
            .then(success => {
                this.setState({
                    userData: success,
                    studentId: success[0].studentId,
                    isLoadingInitialData: false
                })
                this.props.sharedActions
                    .getDataWithoutDispatch(
                        `/api/StudentLeaveView?studentId=${this.state.studentId}`
                    )
                    .then(success => {
                        this.setState({
                            leaveData: success
                        })
                        console.log(this.state.leaveData);
                    })
                console.log(this.state.studentId)
            })
            .catch(err => {
                this.setState({
                    isLoadingInitialData: false
                })
            })
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: '',
                    errorMsg: false,
                });
            }, 3000);
        }
    }


    render() {
        const { errors, classData, sectionData, leaveData, subjectData } = this.state;
        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div class="spinner-border" style={{ color: '#01AC8A' }}></div>
            </div>
        );
        let pageNumbers = [];
        for (
            let i = 1;
            i <= Math.ceil(leaveData.length / this.state.todosPerPage);
            i++
        ) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return index <= 3 ? (
                <li
                    class={`page-item ${
                        this.state.currentPage === number ? 'active' : ''
                        } `}
                >
                    <a
                        key={number}
                        id={number}
                        onClick={this.handleClickNext}
                        class="page-link "
                        href="#"
                    >
                        {number}
                    </a>
                </li>
            ) : (
                    ''
                );
        });
        // parseFloat
        const { currentPage, todosPerPage } = this.state;
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentTodos = leaveData.slice(indexOfFirstTodo, indexOfLastTodo);

        const renderTodos = currentTodos.map((item, index) => {
            return (
                <tr className="bordersSpace" key={index}>
                    <th className="bordersSpace" scope="row">
                        {index + 1}
                    </th>
                    <td className="bordersSpace">{item.studentName}</td>
                    <td className="bordersSpace">{item.subjectName}</td>
                    <td className="bordersSpace">{item.term}</td>
                    <td className="bordersSpace">{item.leaveType}</td>
                    <td className="bordersSpace">{item.fromdate}</td>
                    <td className="bordersSpace">{item.todate}</td>
                    <td className="bordersSpace">{item.status}</td>

                </tr>
            );
        });
        const MainContent = (
            <div>
                <div class="modal" id="myModal1">
                    <div class="modal-dialog">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Confirm
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <h4>Are you sure ?</h4>
                                    </div>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-evenly' }}
                                    >
                                        <div>
                                            <button
                                                disabled={this.state.enabled}
                                                data-dismiss="modal"
                                                onClick={() => this.onTakeItemApprove(this.state.id)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                Yes
											</button>
                                        </div>
                                        <div>
                                            <button
                                                data-dismiss="modal"
                                                disabled={this.state.enabled}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                No
											</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table class="table table-hover" style={{ textAlign: 'center' }}>
                        <thead style={{ background: '#01AC8A', color: 'white' }}>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Student Name</th>
                                <th scope="col">Subject Name</th>
                                <th scope="col">Term</th>
                                <th scope="col"> Leave Type</th>
                                <th scope="col">Date From</th>
                                <th scope="col">Date To</th>
                                <th scope="col"> Status </th>

                            </tr>
                        </thead>
                        <tbody>{renderTodos}</tbody>
                    </table>
                </div>
            </div>
        );


        return (
            <div>
                <div className="page-header">
                    <SnackBar backColor={this.state.snackColor} msg={this.state.msg} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <h2>Student Leave&nbsp;Details</h2>
                            </div>
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul style={{ color: '#01AC8A' }} class="pagination">
                                <li
                                    class={`page-item ${
                                        this.state.disableBPage ? 'disabled' : ''
                                        }`}
                                >
                                    <a
                                        onClick={this.handleClickNextBack}
                                        class="page-link"
                                        href="#"
                                    >
                                        {this.state.disableBPage ? (
                                            <i
                                                style={{ color: 'grey' }}
                                                class="far fa-stop-circle"
                                            ></i>
                                        ) : (
                                                <i class="fas fa-backward"></i>
                                            )}
                                    </a>
                                </li>
                                {renderPageNumbers}
                                <li
                                    class={`page-item ${
                                        this.state.disablepage ? 'disabled' : ''
                                        }`}
                                >
                                    <a
                                        onClick={this.handleClickNextFrwd}
                                        class="page-link"
                                        href="#"
                                    >
                                        {this.state.disablepage ? (
                                            <i
                                                style={{ color: 'grey' }}
                                                class="far fa-stop-circle"
                                            ></i>
                                        ) : (
                                                <i class="fas fa-forward"></i>
                                            )}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                {this.state.isLoadingInitialData ? Loader : MainContent}
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewLeaveByStudent);