import React, { Component } from 'react';
import $ from 'jquery';
import '../Admin/viewAdmin.css';
import Axios from 'axios';
import config from '../../config/config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as getAllAdminActions from '../../Actions/getAllAdmin';
import * as HrDevelopmentActions from '../../Actions/HrDevelopment';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import HrCard from './HrCard';

class ViewHrDevelopment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			description: '',
			chooseFile: '',
			enabled: false,
			id: null,
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',

			CourseCheck: false,
			ConferenceCheck: false,
			SeminarCheck: false,
			TrainingCheck: false,
			WorkshopCheck: false
		};
	}

	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}

		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
			this.setState({
				disablepage: false
			});
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.adminData !== nextProps.AllAdminData) {
			return {
				adminData: nextProps.AllAdminData
			};
		}
		return null;
	}
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
		$('#chooseFile').bind('change', function() {
			var filename = $('#chooseFile').val();
			if (/^\s*$/.test(filename)) {
				$('.file-upload').removeClass('active');
				$('#noFile').text('No file chosen...');
			} else {
				$('.file-upload').addClass('active');
				$('#noFile').text(filename.replace('C:\\fakepath\\', ''));
			}
		});
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		// if (this.props.allAdminState.apiFlag) {
		// 	this.props.sharedActions
		// 		.getAllData(
		// 			'/api/HrDevTrainning',
		// 			this.props.HrDevelopmentActions.setAllHrDevelopementInRedux,
		// 			this.props.HrDevelopmentActions.updateHrDevelopementFlag
		// 		)
		// 		.then(success => {
		//
		// 			this.setState({
		// 				isLoading: false
		// 			});
		// 		})
		// 		.catch(err => {
		// 			this.setState({
		// 				isLoading: false
		// 			});
		//
		// 			console.log(err);
		// 		});
		// }
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/HrDevTrainning/',
				itemId,
				this.props.HrDevelopmentActions.deleteHrDevelopment
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onTakeItem = itemId => {
		// campusAddress:'',
		// campusName:'',
		// campusPhoneNumber:"",
		// campusCity:"",
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.id === itemId);

		this.setState({
			modalData: getData,
			description: getData[0].description,
			id: getData[0].id
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const { description, id } = this.state;
		let file = this.state.chooseFile;
		let data = new FormData();
		data.append('file', file);
		data.append('description', description);
		console.log('DATA Part', data);
		this.props.sharedActions
			.editRecord(
				'/api/HrDevTrainning/',
				id,
				data,
				this.props.HrDevelopmentActions.updateHrDevelopment
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				this.props.snackbar();
			})
			.catch(err => {});
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	onChange = e => {
		this.setState({
			chooseFile: e.target.files[0]
		});
		console.log(this.state.chooseFile);
	};
	onSearchCourse = e => {
		this.props.sharedActions
			.getAllData(
				`/api/HrDevTrainning?type=Courses`,
				this.props.HrDevelopmentActions.setAllHrDevelopementInRedux,
				this.props.HrDevelopmentActions.updateHrDevelopementFlag
			)
			.then(success => {
				this.setState({
					isLoading: false,
					CourseCheck: true,
					ConferenceCheck: false,
					SeminarCheck: false,
					WorkshopCheck: false,
					TrainingCheck: false
					// btnLoad: false,
					// checking: this.state.adminData.paginationMetadata
				});
			})
			.catch(err => {
				this.props.HrDevelopmentActions.updateHrDevelopementFlag(false);

				this.setState({
					isLoading: false
					// btnLoad: false
				});

				console.log(err);
			});
	};
	onSearchConference = e => {
		this.props.sharedActions
			.getAllData(
				`/api/HrDevTrainning?type=Conference`,
				this.props.HrDevelopmentActions.setAllHrDevelopementInRedux,
				this.props.HrDevelopmentActions.updateHrDevelopementFlag
			)
			.then(success => {
				this.setState({
					isLoading: false,
					CourseCheck: false,
					ConferenceCheck: true,
					SeminarCheck: false,
					WorkshopCheck: false,
					TrainingCheck: false
					// btnLoad: false,
					// checking: this.state.adminData.paginationMetadata
				});
			})
			.catch(err => {
				this.props.HrDevelopmentActions.updateHrDevelopementFlag(false);
				this.setState({
					isLoading: false
					// btnLoad: false
				});

				console.log(err);
			});
	};
	onSearchSeminars = e => {
		this.props.sharedActions
			.getAllData(
				`/api/HrDevTrainning?type=Seminars`,
				this.props.HrDevelopmentActions.setAllHrDevelopementInRedux,
				this.props.HrDevelopmentActions.updateHrDevelopementFlag
			)
			.then(success => {
				this.setState({
					isLoading: false,
					CourseCheck: false,
					ConferenceCheck: false,
					SeminarCheck: true,
					WorkshopCheck: false,
					TrainingCheck: false
					// btnLoad: false,
					// checking: this.state.adminData.paginationMetadata
				});
			})
			.catch(err => {
				this.props.HrDevelopmentActions.updateHrDevelopementFlag(false);

				this.setState({
					isLoading: false
					// btnLoad: false
				});

				console.log(err);
			});
	};
	onSearchTraining = e => {
		this.props.sharedActions
			.getAllData(
				`/api/HrDevTrainning?type=Training`,
				this.props.HrDevelopmentActions.setAllHrDevelopementInRedux,
				this.props.HrDevelopmentActions.updateHrDevelopementFlag
			)
			.then(success => {
				this.setState({
					isLoading: false,
					CourseCheck: false,
					ConferenceCheck: false,
					SeminarCheck: false,
					WorkshopCheck: false,
					TrainingCheck: true
					// btnLoad: false,
					// checking: this.state.adminData.paginationMetadata
				});
			})
			.catch(err => {
				this.props.HrDevelopmentActions.updateHrDevelopementFlag(false);

				this.setState({
					isLoading: false
					// btnLoad: false
				});

				console.log(err);
			});
	};
	onSearchWorkshop = e => {
		this.props.sharedActions
			.getAllData(
				`/api/HrDevTrainning?type=Workshop`,
				this.props.HrDevelopmentActions.setAllHrDevelopementInRedux,
				this.props.HrDevelopmentActions.updateHrDevelopementFlag
			)
			.then(success => {
				this.setState({
					isLoading: false,
					CourseCheck: false,
					ConferenceCheck: false,
					SeminarCheck: false,
					WorkshopCheck: true,
					TrainingCheck: false
					// btnLoad: false,
					// checking: this.state.adminData.paginationMetadata
				});
			})
			.catch(err => {
				this.props.HrDevelopmentActions.updateHrDevelopementFlag(false);

				this.setState({
					isLoading: false
					// btnLoad: false
				});

				console.log(err);
			});
	};
	render() {
		const { adminData, modalData } = this.state;

		console.log('Data for Cards', this.state.adminData);
		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div style={{ color: '#01AC8A' }}>
					Select the Type of Development and Training from above
				</div>
			</div>
		);
		const MainContent = (
			<div>
				{this.state.adminData &&
					this.state.adminData.map((item, index) => {
						return <HrCard data={item} />;
					})}
			</div>
		);
		return (
			<div>
				<div
					class="row"
					style={{
						textAlign: 'center',
						justifyContent: 'center',
						margin: 'auto'
					}}
				>
					<div
						class="col-md-3"
						style={{ borderColor: 'black', borderStyle: 'solid' }}
					>
						<div class="md-form mb-0">
							<h4 style={{ color: '#01ac8a' }}>View Training</h4>
						</div>
					</div>
				</div>
				<br />
				<br />
				<div class="row justify-content-around">
					<div class="col-md-2.4">
						<div class="md-form mb-0">
							<button
								type="button"
								class="btn btn-dark"
								style={
									this.state.CourseCheck
										? { background: '#01ac8a', width: 150, height: 40 }
										: { width: 150, height: 40 }
								}
								onClick={this.onSearchCourse}
							>
								<i
									style={{ marginRight: 4 }}
									className="fas fa-user-alt animated fadeIn"
								/>
								Courses
							</button>
						</div>
					</div>

					<div class="col-md-2.4">
						<div class="md-form mb-0">
							<button
								type="button"
								class="btn btn-dark"
								style={
									this.state.ConferenceCheck
										? { background: '#01ac8a', width: 150, height: 40 }
										: { width: 150, height: 40 }
								}
								onClick={this.onSearchConference}
							>
								<i
									style={{ marginRight: 4 }}
									className="fas fa-user-alt animated fadeIn"
								/>
								Conference
							</button>
						</div>
					</div>

					<div class="col-md-2.4">
						<div class="md-form mb-0">
							<button
								type="button"
								class="btn btn-dark"
								style={
									this.state.SeminarCheck
										? { background: '#01ac8a', width: 150, height: 40 }
										: { width: 150, height: 40 }
								}
								onClick={this.onSearchSeminars}
							>
								<i
									style={{ marginRight: 4 }}
									className="fas fa-user-alt animated fadeIn"
								/>
								Seminar
							</button>
						</div>
					</div>

					<div class="col-md-2.4">
						<div class="md-form mb-0">
							<button
								type="button"
								class="btn btn-dark"
								style={
									this.state.TrainingCheck
										? { background: '#01ac8a', width: 150, height: 40 }
										: { width: 150, height: 40 }
								}
								onClick={this.onSearchTraining}
							>
								<i
									style={{ marginRight: 4 }}
									className="fas fa-user-alt animated fadeIn"
								/>
								Training
							</button>
						</div>
					</div>

					<div class="col-md-2.4">
						<div class="md-form mb-0">
							<button
								type="button"
								class="btn btn-dark"
								style={
									this.state.WorkshopCheck
										? { background: '#01ac8a', width: 150, height: 40 }
										: { width: 150, height: 40 }
								}
								onClick={this.onSearchWorkshop}
							>
								<i
									style={{ marginRight: 4 }}
									className="fas fa-user-alt animated fadeIn"
								/>
								Workshop
							</button>
						</div>
					</div>
				</div>
				<br />
				{/* <HrCard/> */}
				{this.props.allAdminState.apiFlag ? Loader : MainContent}
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(getAllAdminActions, dispatch),
		HrDevelopmentActions: bindActionCreators(HrDevelopmentActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllAdminData: state.setHrDevelopmentReducer.Data,
		allAdminState: state.setHrDevelopmentReducer,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewHrDevelopment);
