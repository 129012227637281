import React, { useState, useEffect } from 'react';
import { Types } from '../../hooks/HookReducers/StaffReducer';
import RenderDynamicSiblingsRow from '../../Container/Admission/RenderDynamicSiblingsRow';
import Button from '../Button';

const Certificates = props => {
	const {
		edit = false,
		customLoader = false,
		editData,
		onUpdate,
		putLoader,
		onSubmit,
		newState
	} = props;
	const [checkField, setCheckField] = useState('');
	const [newStatePart2, setNewState] = useState(null);

	const [Certificate, setCertificate] = useState(
		edit
			? editData
			: [
					{
						certificateName: '',
						certificateOrg: '',
						certificateDuration: '',
						certificatePlace: '',
						certificateYear: ''
					}
			  ]
	);

	const updateSpecialArrObjOnChange = (e, index, arrName) => {
		const changeArrOfObj = [...Certificate];
		changeArrOfObj[index][e.target.name] = e.target.value;

		// arrName = changeArrOfObj;
		// console.log(arrName);
		setCertificate(changeArrOfObj);
	};
	const onClickAddItem = (arr, obj) => {
		let getStateArr = [...arr];
		getStateArr = [...getStateArr, obj];
		console.log(getStateArr);

		setCertificate(getStateArr);
	};
	const onClickDeleteItem = arr => {
		let getStateArr = [...arr];
		let getDeleteLength = getStateArr.length - 1;

		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			setCertificate(getNewDetails);
		}
	};
	const { ON_DYANMIC_ADD, ON_DYANMIC_DELETE, ON_CERTIFICATE_DISPATCH } = Types;
	console.log('render certificate components');
	const {
		stateData,
		dispatch,
		errors,
		getValidatedArray,
		onPreviousClick
	} = props;

	const onNextStep = async e => {
		debugger;
		if (!getValidatedArray(e, Certificate, 'certificate')) {
			debugger;
			await dispatch({
				type: ON_CERTIFICATE_DISPATCH,
				payload: { Certificate }
			});
			debugger;
			await onSubmit(e, Certificate);
		}
	};
	const finalSubmit = async e => {
		if (onNextStep(e)) {
			debugger;
			console.log('final state', newStatePart2);
			await onSubmit(e, Certificate);
		}
	};
	const onEditHandleSubmit = e => {
		debugger;

		const arrayItem = Certificate.map(
			({ staffId, interviewId, id, ...keepAttrs }) => keepAttrs
		);
		debugger;
		if (!getValidatedArray(e, arrayItem, 'certificate')) {
			onUpdate(dispatch, 'ON_SET_CERTIFICATE_EDIT', {
				name: 'StaffCertificate',
				data: Certificate
			});
		}
	};

	return (
		<div>
			{errors.certificate ? (
				<p style={{ color: '#ff0000' }}>Please fill the form properly!</p>
			) : (
				''
			)}

			<div style={{ width: '100%' }}>
				{/* <input onChange={(e)=>setCheckField(e.target.value)} value={checkField} type="text"/> */}
				<table class="table table-hover">
					<thead style={{ background: '#01AC8A', color: 'white' }}>
						<tr>
							<th style={{ textAlign: 'center' }} scope="col">
								#
							</th>
							<th style={{ textAlign: 'center' }} scope="col">
								Certificate Name
							</th>
							<th style={{ textAlign: 'center' }} scope="col">
								Certificate Org
							</th>
							<th style={{ textAlign: 'center' }} scope="col">
								Certificate Duration
							</th>
							<th style={{ textAlign: 'center' }} scope="col">
								Certificate Place
							</th>
							<th style={{ textAlign: 'center' }} scope="col">
								Certificate Year
							</th>
						</tr>
					</thead>
					<tbody>
						{Certificate.map((item, index) => (
							<tr key={index}>
								<th style={{ textAlign: 'center' }} scope={'row'}>
									{index + 1}
								</th>
								<RenderDynamicSiblingsRow
									arrName={'Certificate'}
									rowIndex={index}
									stateData={{ Certificate: [...Certificate] }}
									dispatch={dispatch}
									hookReuse={false}
									updateSpecialArrObjOnChange={updateSpecialArrObjOnChange}
									itemObj={item}
								/>
							</tr>
						))}
					</tbody>
					<div className={'row'} style={{ justifyContent: 'center' }}>
						<div>
							<div>
								<button
									onClick={() =>
										onClickAddItem(Certificate, {
											certificateName: '',
											certificateOrg: '',

											certificateDuration: '',
											certificatePlace: '',
											certificateYear: ''
										})
									}
									style={{
										background: '#0A4F5E',
										color: '#FFFFFF',
										borderRadius: 100
									}}
									type="button"
									class="btn"
								>
									<i class="fas fa-plus"></i>
								</button>
							</div>
							<div>
								<button
									onClick={() => onClickDeleteItem(Certificate)}
									style={{ color: '#FFFFFF', borderRadius: 100 }}
									type="button"
									class="btn btn-danger"
								>
									<i class="far fa-trash-alt"></i>
								</button>
							</div>
						</div>
					</div>
				</table>
			</div>
			<br />
			<br />
			<br />
			{/*<div class="text-center text-md-center">*/}
			{/*<button class="btns">Add</button>*/}
			{/*</div>*/}
			{!edit ? (
				<div
					style={{
						marginTop: 10,
						display: 'flex',
						justifyContent: 'flex-start'
					}}
				>
					<div style={{ marginRight: 25 }}>
						<Button
							stateData={{ isLoading: customLoader }}
							textColor="#fff"
							customClause={customLoader}
							btnName="Submit"
							onHandleSubmit={onNextStep}
							buttonClass="btns"
						/>
						{/* <button
							class="btns"
							style={{ marginRight: 25 }}
							onClick={onNextStep}
						>
							Submit
						</button> */}
					</div>
					<div>
						<button
							disabled={customLoader}
							class="btns"
							onClick={() => onPreviousClick()}
						>
							Previous
						</button>
					</div>
				</div>
			) : (
				''
			)}
			{edit ? (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						stateData={{ isLoading: putLoader }}
						btnName="Update"
						buttonClass="btn-smart"
						loaderBmargin={5}
						marginTop={20}
						onHandleSubmit={onEditHandleSubmit}
					/>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default Certificates;
