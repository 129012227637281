import React from 'react';
import ViewRendering from '../../../components/ViewRendering';
import { renderingStuff, images } from '../../../utils';

function AcademicTimeTable() {
	return (
		<div>
			<ViewRendering
				centered={true}
				centerWidth={'60%'}
				mainHead={true}
				backColor={true}
				mainHeading={images.imgTThead}
				data={renderingStuff.timeTable_module_links}
			/>
		</div>
	);
}

export default AcademicTimeTable;
