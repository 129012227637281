import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PlansAction from '../../Actions/Plans';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';

class SubViewLesson extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			planType: '',
			classId: '',
			className: '',
			sectionId: '',
			msg: '',
			section: '',
			sessionId: '',
			session: '',
			sections: [],
			chooseFile: '',
			file: '',
			enabled: false,
			id: null,
			select: [],
			sessions: [],
			status: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.select !== nextProps.selectData ||
			prevState.sessions !== nextProps.selectSessionData
		) {
			return {
				select: nextProps.selectData,
				sessions: nextProps.selectSessionData
			};
		}
		return null;
	}
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.gAllData('/api/classSection', this.props.PlansAction.getClassesForPlans)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		this.props.sharedActions
			.gAllData('/api/Session', this.props.PlansAction.getSessionsForPlans)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}
	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);

		const sections = this.state.select.section;
		let check = sections.filter(item => item.classId == result.id);
		console.log(check);

		this.setState({
			sections: check,
			classId: result.id,
			className: result.className
		});
	};
	onSectionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		this.setState({
			sectionId: result.sectionId,
			section: result.section
		});
	};
	onChange = e => {
		this.setState({
			chooseFile: e.target.files[0]
		});
		console.log(this.state.chooseFile);
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);

		this.setState({
			sessionId: result.sessionId,
			session: result.currentSession
		});
		console.log(this.state);
	};
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/LessonPlan/',
				itemId,
				this.props.PlansAction.deletePlans
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const { planType, id, classId, sectionId, sessionId } = this.state;
		let file = this.state.chooseFile;
		let accountId = this.props.users.unique_name;
		let accountType = this.props.users.role;
		let data = new FormData();
		data.append('file', file);
		data.append('planType', planType);
		data.append('classId', classId);
		data.append('sectionId', sectionId);
		data.append('sessionId', sessionId);
		data.append('accountId', accountId);
		data.append('accountType', accountType);
		console.log('DATA Part', data);
		this.props.sharedActions
			.editRecord(
				`/api/LessonPlan/?planId=`,
				id,
				data,
				this.props.PlansAction.updatePlans
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				this.props.snackbar();
			})
			.catch(err => {});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = this.props.data.filter(item => item.planId === itemId);
		this.setState({
			modalData: getData,
			id: getData[0].planId,
			planType: getData[0].planType,
			classId: getData[0].classId,
			className: getData[0].className,
			sectionId: getData[0].sectionId,
			section: getData[0].section,
			sessionId: getData[0].sessionId,
			session: getData[0].session
		});
	};
	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};
	render() {
		const { classList } = this.state.select;
		const { session } = this.state.sessions;
		const { modalData } = this.state;
		const MainContent = (
			<div>
				<div class="modal" id="myModalDel">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModalUpdate">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<form onSubmit={this.onHandleSubmit}>
									{modalData &&
										modalData.map((item, index) => (
											<div key={index}>
												<fieldset disabled={!this.state.enabled}>
													<div class="form-group">
														<label for="transportType">Plan Type:</label>
														<input
															readOnly={true}
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.planType}
															onChange={this.onHandleText}
															name="planType"
															class="form-control"
															id="transportType"
														/>
													</div>
													<div class="col-md-12" style={{ marginTop: 20 }}>
														<div class="md-form mb-0">
															<label for="name" class="">
																Plan Type
															</label>
															<select
																style={{ borderColor: '#01AC8A' }}
																onChange={this.onHandleText}
																class="custom-select"
																name="planType"
															>
																<option>Choose Plan..</option>
																<option value="Weekly">Weekly</option>
																<option value="Monthly">Monthly</option>
																<option value="Termly">Termly</option>
																<option value="Yearly">Yearly</option>
															</select>
														</div>
													</div>
													<div class="form-group">
														<label for="transportType">Class</label>
														<input
															readOnly={true}
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.className}
															onChange={this.onHandleText}
															name="planType"
															class="form-control"
															id="transportType"
														/>
													</div>
													<div class="col-md-12" style={{ marginTop: 20 }}>
														<div class="md-form mb-0">
															<label for="email" class="">
																Class Name
															</label>
															<select
																style={{ borderColor: '#01AC8A' }}
																onChange={this.onSelectChange}
																class="custom-select"
																name="className"
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	Choose Class...
																</option>
																{classList &&
																	classList.map((item, index) => (
																		<option
																			key={index}
																			value={`${JSON.stringify(item)}`}
																		>
																			{item.className}
																		</option>
																	))}
															</select>
														</div>
													</div>

													<div class="form-group">
														<label for="transportType">Section</label>
														<input
															readOnly={true}
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.section}
															onChange={this.onHandleText}
															name="planType"
															class="form-control"
															id="transportType"
														/>
													</div>
													<div class="col-md-12" style={{ marginTop: 20 }}>
														<div class="md-form mb-0">
															<label for="email" class="">
																Section
															</label>
															<select
																style={{ borderColor: '#01AC8A' }}
																onChange={this.onSectionChange}
																class="custom-select"
																name="className"
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	Choose Section...
																</option>
																{this.state.sections.map((item, index) => (
																	<option
																		key={index}
																		value={`${JSON.stringify(item)}`}
																	>
																		{item.section}
																	</option>
																))}
															</select>
														</div>
													</div>

													<div class="form-group">
														<label for="transportType">Session</label>
														<input
															readOnly={true}
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.session}
															onChange={this.onHandleText}
															name="planType"
															class="form-control"
															id="transportType"
														/>
													</div>
													<div class="col-md-12" style={{ marginTop: 20 }}>
														<div class="md-form mb-0">
															<label for="email" class="">
																Session's
															</label>
															<select
																style={{ borderColor: '#01AC8A' }}
																onChange={this.onSelectSessionChange}
																class="custom-select"
																name="sectionName"
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	Select Current Session..
																</option>
																{session &&
																	session.map((item, index) => (
																		<option value={`${JSON.stringify(item)}`}>
																			{item.currentSession}
																		</option>
																	))}
															</select>
														</div>
													</div>
													<div class="col-md-12" style={{ marginTop: 50 }}>
														<div class="md-form mb-0">
															<div class="file-upload">
																<div class="file-select">
																	<div class="file-select-button" id="fileName">
																		Choose File
																	</div>
																	<div class="file-select-name" id="noFile">
																		No File Choosen
																	</div>
																	<input
																		type="file"
																		name="chooseFile"
																		id="chooseFile"
																		onChange={this.onChange}
																	/>
																</div>
															</div>
														</div>
													</div>
												</fieldset>
												<br />
												<br />
												<button
													disabled={this.state.enabled}
													onClick={() => this.setState({ enabled: true })}
													type="button"
													class="btn btn-primary buttonAppear"
												>
													Edit
												</button>
												<button
													style={{ marginLeft: 5 }}
													disabled={!this.state.enabled}
													type="submit"
													class="btn btn-primary buttonAppear"
												>
													Save
												</button>
											</div>
										))}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
		return (
			<React.Fragment>
				<SnackBar msg={this.state.msg} />
				{this.props.data.map((item, index) => (
					<tr key={index}>
						<th style={{ padding: 3 }} scope="row">
							{index + 1}
						</th>
						<td style={{ padding: 3 }}>{item.fileName}</td>
						<td style={{ padding: 3 }}>{item.className}</td>
						<td style={{ padding: 3 }}>{item.section}</td>
						<td style={{ padding: 3 }}>{item.session}</td>
						<td style={{ padding: 3 }}>{item.planType}</td>
						<td style={{ padding: 3 }}>{item.date}</td>
						<td style={{ padding: 3 }}>{item.time}</td>
						<td style={{ padding: 3 }}>{item.accountId}</td>
						<td style={{ padding: 3 }}>{item.accountType}</td>
						<td style={{ padding: 3 }}>
							<button
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<a
									href={
										'http://linkit.life/api/downloadLessonPlans?fileName=' +
										item.fileName
									}
									id="tooltipId"
									data-toggle="tooltip"
									title="Download File"
								>
									<i style={{ color: '#34495e' }} class="fas fa-download"></i>
								</a>
							</button>
							<a href="#" data-toggle="tooltip" title="Edit!">
								<button
									id="firstbutton"
									onClick={() => this.onEditClick(item.planId)}
									onMouseOver={this.btnTechHover()}
									data-toggle="modal"
									data-target="#myModalUpdate"
									style={{
										cursor: 'pointer',
										fontSize: 20,
										border: 'none',
										marginLeft: 5,
										background: 'none',
										marginBottom: 5
									}}
								>
									<i class="fas fa-pen"></i>
								</button>
							</a>
							<a href="#" data-toggle="tooltip" title="Delete!">
								<button
									onClick={() => this.onEditClick(item.planId)}
									onMouseOver={this.btnTechHover()}
									data-toggle="modal"
									data-target="#myModalDel"
									style={{
										cursor: 'pointer',
										fontSize: 20,
										border: 'none',
										background: 'none',
										marginLeft: 5,
										marginBottom: 5
									}}
								>
									<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
								</button>
							</a>
						</td>
					</tr>
				))}
				{MainContent}
			</React.Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		PlansAction: bindActionCreators(PlansAction, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		selectData: state.setPlansReducer.select,
		selectSessionData: state.setPlansReducer.selectSession,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubViewLesson);
