import * as actionTypes from '../actionTypes';

export function setAllTestInfoInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_TEST_INFO,
		payload: allData
	};
}

export function getClassesForTestInfo(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_TEST_INFO_SELECT,
		payload: allData
	};
}
export function getSessionForTestInfo(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_TEST_INFO_SELECT,
		payload: allData
	};
}
export function addTestInfo(Data) {
	return {
		type: actionTypes.ADD_TEST_INFO,
		payload: Data
	};
}
export function updateTestInfoFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_TEST_INFO,
		payload: setFlag
	};
}
export function updateTestInfo(data) {
	return {
		type: actionTypes.EDIT_TEST_INFO_RECORD,
		payload: data
	};
}
export function deleteTestInfo(id) {
	return {
		type: actionTypes.DELETE_TEST_INFO,
		payload: id
	};
}
