import React, { Component } from 'react';
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../Actions/sharedActions';
import * as timeActions from '../../Actions/TimeTable';
import * as actions from '../../Actions/Admission';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button';
import Snackbar from '../../components/SnackBar/SnackBar';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class LessonPerWeek extends Component {
	constructor(props) {
		super(props);
		this.state = {
			section: '',
			quantity: '',
			subjectId: '',
			gradeId: '',
			successflag: false,
			sectionId: '',
			gradeName: '',
			classId: '',
			className: '',
			subjects: [],
			disabledField: true,
			msg: '',
			teachAndStaff: [],
			classSectionData: [],
			errors: {},
			grade: [],
			isLoading: true,
			isLoading1: false
		};
	}

	componentDidMount() {
		this.props.sharedAction
			.getAllData(
				'/api/ClassesSections',
				this.props.actions.setAllClassSectonGrade,
				this.props.actions.updateSectionGradeLoader
			)
			.then(_success => {
				this.setState({
					isLoading: false
				});
			})
			.catch(error => {
				console.log(error);
			});
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.classSectionData !== nextProps.getClassSectionGrade.Data) {
			return {
				classSectionData: { ...nextProps.getClassSectionGrade.Data }
			};
		}
		return null;
	}
	onSectionChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onGeneralChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onChangeClassName = e => {
		console.log(e.target.value);
		const { gradeSection } = this.state.classSectionData;
		let getGradeData = gradeSection.filter(
			(item, index) => item.id === parseInt(e.target.value)
		);

		console.log(getGradeData);
		if (e.target.value !== 'Choose Class') {
			this.setState({
				isLoading1: true,
				disabledField: true
			});
			this.props.sharedAction
				.gAllData(
					`/api/getSubjectByClass?classId=${e.target.value}`,
					this.props.ttActions.getTeachAndStudent
				)
				.then(success => {
					console.log(success);

					this.setState({
						isLoading1: false,
						teachAndStaff: success,
						subjects: success,
						disabledField: false
					});
				})
				.catch(error => {
					console.log(error);
				});
		}
		this.setState({
			grade: getGradeData,
			gradeName: getGradeData[0] && getGradeData[0].gradeName,
			className: e.target.value,
			gradeId: getGradeData[0] && getGradeData[0].gradeId,
			classId: getGradeData[0] && getGradeData[0].id,
			successflag: false
		});
	};
	validation = () => {
		const {
			classId,
			sectionId,
			quantity,
			subjectId,
			gradeId,
			errors
		} = this.state;
		if (
			classId !== '' &&
			sectionId !== '' &&
			quantity !== '' &&
			subjectId !== '' &&
			gradeId !== ''
		) {
			let newErrors = { ...errors };
			console.log('every thing is fine');
			newErrors.fieldValidate = false;
			this.setState({
				errors: newErrors
			});
			return false;
		} else {
			console.log('some thing is fishy');
			let newErrors = { ...errors };
			newErrors.fieldValidate = true;
			this.setState({
				errors: newErrors
			});
			return true;
		}
	};
	onSubmit = () => {
		if (!this.validation()) {
			const { classId, sectionId, gradeId, subjectId, quantity } = this.state;

			this.props.sharedAction
				.simpleAddRequest('/api/lessonperweeks', {
					classId,
					sectionId,
					gradeId,
					subjectId,
					quantity,
					accountId: this.props.name,
					accountType: this.props.role
				})
				.then(success => {
					this.setState({
						errors: {},
						section: '',
						quantity: '',
						subjectId: 'Choose Subject',
						gradeId: '',
						sectionId: 'Choose Section',
						gradeName: '',
						classId: 'Choose Class',
						successflag: true,
						className: '',
						msg: 'Submit Successfully!'
					});
					this.props.snackbar();
				})
				.catch(error => {
					console.log(error);
				});
		}
	};
	render() {
		const {
			classSectionData,
			errors,
			isLoading,
			disabledField,
			teachAndStaff,
			subjects,
			isLoading1
		} = this.state;
		return (
			<div>
				<Snackbar msg={this.state.msg} />
				<span
					style={{ color: '#01ac8a' }}
					class={`${isLoading1 ? 'spinner-border spinner-border-lg' : ''}`}
				></span>
				<FormLayoutWrapper
					flagName={'fieldValidate'}
					errorFlag={errors}
					type="row"
					formName="Lesson Per Week"
					borderColor="#01ac8a"
					borderRadius={15}
				>
					{isLoading ? (
						<p>Loading...</p>
					) : (
						<React.Fragment>
							<SelectionInput
								selectName="Class"
								feildName="className"
								selectedText="Choose Class"
								optionsArrys={classSectionData.classes}
								errors={errors}
								stateData={this.state}
								successflag={'successflag'}
								onHandleChange={this.onChangeClassName}
								optionType="dynamic"
								property="className"
								// iconClassName='fas fa-level-down-alt'
								propertyId="id"
							/>
							<SelectionInput
								selectName="Section"
								feildName="sectionId"
								selectedText="Choose Section"
								optionsArrys={isLoading1 ? [] : this.state.grade}
								errors={errors}
								successflag={'successflag'}
								stateData={this.state}
								optionType="dynamic"
								onHandleChange={this.onSectionChange}
								property="section"
								// iconClassName='fas fa-level-down-alt'
								propertyId="sectionId"
							/>
							{/* </div> */}
							<TextInput
								feildName="gradeName"
								inputLabelName="Grade Name"
								errors={errors}
								stateData={this.state}
								placeholder="Grade Name"
								readOnly={true}
								type={'text'}
							/>
							<SelectionInput
								selectName="Subject"
								feildName="subjectId"
								successflag={'successflag'}
								selectedText="Choose Subject"
								optionsArrys={isLoading1 ? [] : subjects}
								errors={errors}
								stateData={this.state}
								optionType="dynamic"
								onHandleChange={this.onGeneralChange}
								property="subjectName"
								// iconClassName='fas fa-level-down-alt'
								propertyId="subjectId"
								//  useFlag={true}
							/>
							<TextInput
								feildName="quantity"
								inputLabelName="quantity"
								errors={errors}
								stateData={this.state}
								onHandleChange={this.onGeneralChange}
								placeholder="Enter Quantity"
								readOnly={disabledField || subjects.length < 1}
								type={'text'}
								required={false}
							/>

							<div style={{ visibility: 'hidden' }}>
								<TextInput
									feildName="quantity"
									inputLabelName="Quantity"
									errors={errors}
									stateData={this.state}
									onHandleChange={this.onGeneralChange}
									placeholder="Enter Quantity"
									readOnly={false}
									disabledField={false}
									type={'text'}
								/>
							</div>

							<Button
								width="100%"
								buttonClass="customButton"
								btnName="Submit"
								loaderBmargin={5}
								stateData={this.state}
								marginTop={20}
								onHandleSubmit={this.onSubmit}
								margin={'0 auto'}
							/>
						</React.Fragment>
					)}
				</FormLayoutWrapper>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		getClassSectionGrade: state.setClassSectionGradeReducer,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		sharedAction: bindActionCreators(sharedActions, dispatch),
		ttActions: bindActionCreators(timeActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LessonPerWeek);
