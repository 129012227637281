import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';




class ViewPayslip extends Component {
    constructor(props){
        super(props);
        this.state = {
            payslipData: [],
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            id: null,
            staffName: null,
            campusName: null,
            departmentName: null,
            postName: null,
            month: null,
            year: null,
            basicSalary: null,
            taxAmount: null,
            allowances: null,
            bonusAmount: null,
            loanamountDeduction: null,
            attendancededuction: null,
            taxdeduction: null,
            totatAmountDeduct: null,
            grossIncome: null,
            NetIncome: null,
            status: null,
            accountId: null,
            accountType: null, 

        }

        


    }

    componentDidMount(){
        this.getSimple('EmployPaySlip', 'payslipData')

    }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: error.response.data.Message || 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    onEditClick = id => {
        const { payslipData } = this.state
        let modalData = payslipData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        console.log(modalData)
        this.setState({
            id: modalData[0].id,
            sessionId: modalData[0].sessionId,
            campusId: modalData[0].campusId,
            departmentId: modalData[0].departmentId,
            PostId: modalData[0].PostId,
            staffId: modalData[0].staffId,
            date: modalData[0].date,
            time: modalData[0].time,
            staffName: modalData[0].staffName,
            campusName: modalData[0].campusName,
            departmentName: modalData[0].departmentName,
            postName: modalData[0].postName,
            month: modalData[0].month,
            year: modalData[0].year,
            basicSalary: modalData[0].basicSalary,
            taxAmount: modalData[0].taxAmount,
            allowances: modalData[0].allowances,
            bonusAmount: modalData[0].bonusAmount,
            loanamountDeduction: modalData[0].loanamountDeduction,
            attendancededuction: modalData[0].attendancededuction,
            taxdeduction: modalData[0].taxdeduction,
            totatAmountDeduct: modalData[0].totatAmountDeduct,
            grossIncome: modalData[0].grossIncome,
            NetIncome: modalData[0].NetIncome,
            status: modalData[0].status,
            accountId: modalData[0].accountId,
            accountType: modalData[0].accountType, 
            
        }, () => {
            $('#myModal1').modal({
                show: true
            })
        })
    }

    
    onHandlePayslip = id => {
        const { payslipData } = this.state
        let payslip = payslipData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                generateIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })



    }

    

    

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    handleSubmit = e => {
        e.preventDefault();
        console.log('Submit Called')

        const {  id,
            sessionId,
            campusId,
            departmentId,
            PostId,
            staffId,
            time,
            date,
            staffName,
            campusName,
            departmentName,
            postName,
            month,
            year,
            basicSalary,
            taxAmount,
            allowances,
            bonusAmount,
            loanamountDeduction,
            attendancededuction,
            taxdeduction,
            totatAmountDeduct,
            grossIncome,
            NetIncome,
            status,
            accountId,
            accountType,  } = this.state;

        let data = {
            id,
            sessionId,
            campusId,
            departmentId,
            PostId,
            staffId,
            time,
            date,
            staffName,
            campusName,
            departmentName,
            postName,
            month,
            year,
            basicSalary,
            taxAmount,
            allowances,
            bonusAmount,
            loanamountDeduction,
            attendancededuction,
            taxdeduction,
            totatAmountDeduct,
            grossIncome,
            NetIncome,
            status,
            accountId,
            accountType,
        }

        console.log('data model', data)
        this.props.sharedActions.editRecordWithoutDispatch(
            '/api/Payslip/',
            id,
            data
        ).then(success => {
            console.log('success', success)
            this.state.payslipData.splice(this.state.editIndex, 1, data)
            
                
                $('#myModal1').modal('hide')
            
            this.setState({
                msg: 'Record Changed Successfully!',

                
            })
            this.props.snackbar();
            

        }).catch(error => {
            this.setState({
                msg: error.response.data.Message || 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
                
                $('#myModal1').modal('hide')
            
            this.props.snackbar();


        })
    }


    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};


    render(){

        const { isLoading, editToggle, payslipData, msg, slipId,
            slipNo,
            staffId,
            staffName,
            month,
            year,
            basicSalary,
            loanAmount,
            allowances,
            bonusAmount,
            taxAmount,
            totatAmountDeduct,
            attendancededuction,
            noOfAbsents,
            noOfPresents,
            noOfLates,
            noOfLeaves,
            noOfShortLeaves,
            perdaySalary,
            consecutiveLate,
            consecutiveLateSalaryCut,
            absentsalaryCut,
            lateSalaryCut,
            lateHours,
            lateMinutes,
            grossIncome,
            NetIncome,
            date,
            time,
            accountId,
            accountType, } = this.state;

        



        // const editPayslipData = (
        //     <div>
        //     <div className="modal" id="myModal1">
        //         <div className="modal-dialog">
        //             <div className="modal-content modal_content_custom">
        //                 <div
        //                     style={{
        //                         borderBottomLeftRadius: 30,
        //                         borderBottomRightRadius: 30,
        //                         borderTopLeftRadius: 18,
        //                         borderTopRightRadius: 18
        //                     }}
        //                     className="modal-header modal_custom_header"
        //                 >
        //                     <h4 style={{ color: 'white' }} className="modal-title">
        //                         Edit
        //                     </h4>
        //                     <button
        //                         style={{ color: 'white' }}
        //                         on
        //                         type="button"
        //                         className="close"
        //                         data-dismiss="modal"
        //                     >
        //                         &times;
        //                     </button>
        //                 </div>
        //                 <div className="modal-body">
        //                     <form onSubmit={this.handleSubmit} >
        //                     <fieldset disabled={!editToggle}>
                                
        //                         <div className="form-group">
        //                         <label for="category">
        //                                         Status:
        //                                     </label>
        //                         <select class="custom-select" 
        //                                         value={status}
        //                                         name="status"
        //                                         onChange={this.onHandleText}

        //                                     >
        //                                                    <option value="On Hold">On Hold</option>
        //                                                    <option value="Approved">Approved</option>
        //                                     </select>
        //                         </div>
        //                         <div className="form-group">
        //                         <label for="accountId">Account Name:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={accountId}
        //                         name="modalDataAccountId"
        //                         className="form-control"
        //                         id="accountId"
        //                         />
        //                         </div>
        //                         <div className="form-group">
        //                         <label for="accountType">Account Type:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={accountType}
        //                         name="modalDataAccountType"
        //                         className="form-control"
        //                         id="accountType"
        //                         />
        //                         </div>
        //                         <div className="form-group">
        //                         <label for="staffName">Staff Name:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={staffName}
        //                         name="staffName"
        //                         className="form-control"
        //                         id="staffName"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="campusName">Campus Name:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={campusName}
        //                         name="campusName"
        //                         className="form-control"
        //                         id="campusName"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="departmentName">Department Name:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={departmentName}
        //                         name="departmentName"
        //                         className="form-control"
        //                         id="departmentName"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="postName">Post Name:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={postName}
        //                         name="postName"
        //                         className="form-control"
        //                         id="postName"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="month">Month</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={month}
        //                         name="month"
        //                         className="form-control"
        //                         id="month"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="year">Year:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={year}
        //                         name="year"
        //                         className="form-control"
        //                         id="year"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="basicSalary">Basic Salary:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={basicSalary}
        //                         name="basicSalary"
        //                         className="form-control"
        //                         id="basicSalary"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="taxAmount">Tax Amount in %:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={taxAmount}
        //                         name="taxAmount"
        //                         className="form-control"
        //                         id="taxAmount"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="allowances">Allownaces:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={allowances}
        //                         name="allowances"
        //                         className="form-control"
        //                         id="allowances"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="bonusAmount">Bouns Amount:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={bonusAmount}
        //                         name="bonusAmount"
        //                         className="form-control"
        //                         id="bonusAmount"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="loanamountDeduction">Loan Amount Deduction:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={loanamountDeduction}
        //                         name="loanamountDeduction"
        //                         className="form-control"
        //                         id="loanamountDeduction"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="attendancededuction">Attendence Deduction:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={attendancededuction}
        //                         name="attendancededuction"
        //                         className="form-control"
        //                         id="attendancededuction"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="taxdeduction">Tax Deduction:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={taxdeduction}
        //                         name="taxdeduction"
        //                         className="form-control"
        //                         id="taxdeduction"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="totatAmountDeduct">Total Amount Deduct:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={totatAmountDeduct}
        //                         name="totatAmountDeduct"
        //                         className="form-control"
        //                         id="totatAmountDeduct"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="grossIncome">Gross Income:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={grossIncome}
        //                         name="grossIncome"
        //                         className="form-control"
        //                         id="grossIncome"
        //                         />
        //                         </div><div className="form-group">
        //                         <label for="NetIncome">Net Income:</label>
        //                         <input style={{
        //                             borderTop: 0,
        //                             borderRight: 0,
        //                             borderLeft: 0
        //                         }}
        //                         type="text"
        //                         disabled
        //                         value={NetIncome}
        //                         name="NetIncome"
        //                         className="form-control"
        //                         id="NetIncome"
        //                         />
        //                         </div>
        //                     </fieldset>
        //                     <br />
		// 					<br />
		// 						<button
		// 							disabled={editToggle}
		// 							onClick={() => this.setState({ editToggle: true })}
		// 							type="button"
		// 							class="btn btn-primary buttonAppear"
		// 							>
		// 						    	Edit
		// 							</button>
		// 							<button
		// 								style={{ marginLeft: 5 }}
		// 								disabled={!editToggle}
		// 								type="submit"
		// 								class="btn btn-primary buttonAppear"
		// 								>
		// 								Save
		// 							</button>
        //                     </form>
        //                 </div>
        //                 <div class="modal-footer">
		// 						<button
		// 							type="button"
		// 							style={{ background: '#00435D' }}
        //                             class="btn btn-danger"
        //                             onClick={() => this.setState({editToggle: false})}
		// 							data-dismiss="modal"
		// 						>
		// 							Close
		// 						</button>
		// 					</div>
        //             </div>
        //         </div>
        //     </div>
        //     </div>                 
        // )

        const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
        );
        
        const renderPayslipData = payslipData.map((item, index) => {
            return (

            <tr key={index} >
                <th scope="row" className="bordersSpace" style={{ padding: 1 }} >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.slipNo}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.staffName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.month}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.year}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.grossIncome}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.totatAmountDeduct}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.NetIncome}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    <Link to={{
                        pathname: "PayslipDetails",
                        state: {item: item  }
                    }}  >
                <button className="btn btn-primary"
								onClick={() => this.onHandlePayslip(item.id)}
								style={{
									cursor: 'pointer',
								}}
							>
								View
							</button>
                    </Link>

							{/* {item.status === 'On Hold' ? 
                <a href="#" data-toggle="tooltip" title="Edit!">
                            
                            <button
								id="firstbutton"
                                onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</a> :
							<button className="btn btn-primary"
								onClick={() => this.onHandlePayslip(item.id)}
								style={{
									cursor: 'pointer',
								}}
							>
								View
							</button>
                            } */}
                </td>
                
            </tr>
            
            );
        }) 

        return (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">
                        
                    <div class="col-md-6" >
                        <h2>Payslips</h2>
                    </div>
                   
                    </div>
                    
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Slip No.
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Staff Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Acoount Role
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Month
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Year
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Gross Income
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Total Amount Deduct
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Net Income
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
                                

                                
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderPayslipData}</tbody>
					</table>
				</div>
                </div>
                {/* {editPayslipData} */}
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewPayslip);
