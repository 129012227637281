import React from 'react';
import Colors from '../../utils/app_constants/colors_constants';

const Button = ({
	width = '100%',
	margin,
	iconTopMargin,
	iconSize = 20,
	iconColor = '#fff',
	iconClass = 'fas fa-search',
	icon = false,
	textColor = '#000',
	btnBackColor = '#01AC8A',
	marginTop,
	mouseInEvent,
	buttonClass,
	customPositionText,
	customPositionTextBottom,
	customClause,
	loaderBmargin,
	onHandleSubmit = () => alert('new button'),
	btnName = 'Default Text',
	stateData = {
		isLoading: false
	}
}) => {
	return (
		// <div style={{ width: '50%', margin: '0 auto', marginTop: 20 }} >
		//               <button className='btns' >
		//                     Add
		//             <span style={{ marginBottom: 5 }} class={`${this.state.isLoading ? 'spinner-border spinner-border-sm' : ''}`}></span>
		//                 </button>
		//                 </div>
		<div
			style={{
				width: width,
				margin: margin,
				marginTop: marginTop
			}}
		>
			<button
				onMouseEnter={mouseInEvent}
				disabled={stateData.isLoading || customClause}
				onClick={e => onHandleSubmit(e)}
				className={`${buttonClass}`}
				style={{ backgroundColor: customClause ? '#ABD0BC' : btnBackColor }}
			>
				<span
					style={{
						color: textColor,
						position: customPositionText,
						bottom: customPositionTextBottom
					}}
				>
					{btnName}
				</span>
				{icon ? (
					<i
						style={{
							fontSize: iconSize,
							padding: 5,
							cursor: 'pointer',
							color: iconColor,
							marginTop: iconTopMargin
						}}
						class={iconClass}
					></i>
				) : (
					''
				)}
				<span
					style={{ marginBottom: loaderBmargin }}
					class={`${
						stateData.isLoading ? 'spinner-border spinner-border-sm' : ''
					}`}
				></span>
			</button>
		</div>
	);
};

export default Button;
