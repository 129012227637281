import React, { useState, useEffect } from 'react';
import TimePicker from '../../../components/TimePicker';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import BreakObjRendering from './BreakObjRendering';

const format = 'h:mm a';

function BreakRendering(props) {
	const { numOfBreaks, getBreakNumber, getBreakData } = props;
	const [breakDetails, setBreaks] = useState([
		{
			type: '',
			startTime: '',
			endTime: '',
			periodNum: ''
		}
	]);
	const onChangeText = (e, index) => {
		let getBreaks = [...breakDetails];
		getBreaks[index][e.target.name] = e.target.value;
		console.log(getBreaks);
		getBreakData(getBreaks);
		setBreaks(getBreaks);
	};
	const onChangeTime = (value, name, rowIndex) => {
		let getBreaks = [...breakDetails];
		getBreaks[rowIndex][name] = new Date(value && value._d).setSeconds(0);
		console.log(getBreaks);
		getBreakData(getBreaks);
		setBreaks(getBreaks);
	};
	useEffect(() => {
		let arrObj = [];
		console.log('we are in component did mount of break renering');
		console.log(numOfBreaks);
		if (numOfBreaks > 0) {
			for (let i = 0; i < numOfBreaks; i++) {
				arrObj.push({
					type: 'break',
					startTime: '',
					endTime: '',
					periodNum: ''
				});
			}
			console.log(arrObj);

			setBreaks(arrObj);
		}
	}, [numOfBreaks]);
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexWrap: 'wrap'
			}}
		>
			<div style={{ width: '100%' }}>
				<table class="table table-hover">
					<thead style={{ background: '#01AC8A', color: 'white' }}>
						<tr>
							<th style={{ fontSize: 17 }} scope="col">
								#
							</th>
							<th style={{ fontSize: 17 }} scope="col">
								{' '}
								Start Time
							</th>
							<th style={{ fontSize: 17 }} scope="col">
								{' '}
								End Time
							</th>
							<th style={{ fontSize: 17 }} scope="col">
								{' '}
								Break No
							</th>
						</tr>
					</thead>
					<tbody>
						{breakDetails.map((item, index) => (
							<tr key={index}>
								<th scope={'row'}>
									<span
										style={{
											background: '#0A4F5E',
											color: '#fff',
											fontWeight: 'lighter'
										}}
										class="badge badge-warning"
									>
										{'Break ' + parseInt(index + 1)}
									</span>
								</th>
								<BreakObjRendering
									rowIndex={index}
									getBreakNumber={getBreakNumber}
									onChangeText={onChangeText}
									onChange={onChangeTime}
									obj={item}
								/>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default BreakRendering;
