import React, { Component } from 'react';
import $ from 'jquery';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class AddMultipleItems extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('#addBtn').hover(
				function() {
					$(this).css(
						'background',
						'linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)'
					);
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('background', '#0A4F5E');
				}
			);
		});
	};
	render() {
		const {
			onHandleTextChange,
			onHandleCheckChange,
			stateData,
			updateSpecialArrObjOnChange,
			updateSpecialArrObjOnCheck,
			onClickAddItem,
			onClickDeleteItem,
			addButton
		} = this.props;
		return (
			<div>
				<h3 class="h1-responsive font-weight-bold text-left my-4">
					Multiple Items
				</h3>

				<div style={{ width: '100%' }}>
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Category</th>
								<th scope="col">Check Box</th>
								<th scope="col">Comment</th>
								{/* <th scope="col">Account Id</th>
								<th scope="col">Account Type</th> */}
							</tr>
						</thead>
						<tbody>
							{stateData &&
								stateData.CyclicCleaness.map((item, index) => (
									<tr key={index}>
										<th scope={'row'}>{index + 1}</th>
											<td >
											<select class="custom-select" 
                                                value={item.categoryId}
                                                name="categoryId"
                                                onChange={e => updateSpecialArrObjOnChange(e, index, 'CyclicCleaness')}
                                                disabled={!stateData.roomId}

                                            >
                                                        <option value="">Please Select Category</option>
                                                       {stateData.floorData.category.map((value, index) => {
                                                                return <option value={value.id}>{value.categoryName}</option>

                                                       })}
                                            </select>
											</td>
											<td >
											<div className="my-2 custom-control custom-checkbox" >
											<input 
                                                type="checkbox" 
                                                class="custom-control-input" 
                                                id={`checkBox${index}`} 
												name="checkBox" 
												disabled={!item.categoryId}
                                                // checked={item.checkBox}
                                                onChange={e => updateSpecialArrObjOnCheck(e, index, 'CyclicCleaness')}
                                                />
												<label class="custom-control-label" for={`checkBox${index}`}></label>  
                                        </div>
											</td>
											<td  >
											<textarea 
                                                class="form-control"
                                                value={item.Comment}
                                                rows={1}
                                                disabled={!item.categoryId}
                                                name="Comment"
                                                id="Comment"
                                                onChange={e => updateSpecialArrObjOnChange(e, index, 'CyclicCleaness')}
                                            />
											</td>
											{/* <td  >
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={item.accountId}
												
											/>
											</td>
											<td >
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={item.accountType}
												
											/>
											</td> */}
									</tr>
								))}
						</tbody>
					</table>
					<div className={'row'} style={{ justifyContent: 'flex-end' }}>
						<div>
							<button
								id={'addBtn'}
								// disabled={!addButton}
								onMouseEnter={this.btnTechHover()}
								onClick={() =>
									onClickAddItem('CyclicCleaness', {
                					    categoryId: '',
                					    checkBox: 'No',
                					    Comment: '',
									})
								}
								style={{
									background: '#0A4F5E',
									color: '#FFFFFF',
									borderRadius: 100
								}}
								type="button"
								class="btn"
							>
								<i class="fas fa-plus" />
							</button>
						</div>
						<div style={{ marginLeft: 8 }}>
							<button
								onClick={() => onClickDeleteItem('CyclicCleaness')}
								style={{ color: '#FFFFFF', borderRadius: 100 }}
								type="button"
								class="btn btn-danger"
							>
								<i class="far fa-trash-alt" />
							</button>
						</div>
					</div>
				</div>
				<br />
				<br />
				<br />
				{/*<div class="text-right text-md-center">*/}
				{/*<button class="btns">Add</button>*/}
				{/*</div>*/}
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

export default connect(mapStateToProps)(AddMultipleItems);
