import * as actionTypes from '../Actions/actionTypes';
import isEmpty from 'lodash/isEmpty';

const defaultState = {
	apiFlag: true,
	Data: []
};

export default function setAllAdminReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.ADD_ADMIN_RECORD:
			return { ...state };
		case actionTypes.GET_ALL_ADMIN:
			return { ...state, Data: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_ADMIN:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_ADMIN_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.id === action.payload.id);
			adminData[index].userName = action.payload.userName;
			adminData[index].post = action.payload.post;
			adminData[index].password = action.payload.password;
			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
