import React, { useEffect, useState } from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import Button from '../../../components/Button';
import $ from 'jquery';
import { General } from '../../../utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import ValidationText from '../../../utils/app_constants/validate_messages_constants';
import SnackBar from '../../../components/SnackBar/SnackBar';
import useHttp from '../../../hooks/http';
import URL_CONSTANTS from '../../../utils/app_constants/URL_constants';
import Axios from 'axios';
import UploadProgressBar from '../../../components/UploadProgressBar/UploadProgressBar';
import config from '../../../config/config';

function StudentImageUploadSection(props) {
	const [snackbarMessage, setMsg] = useState('');
	const [isEditMode, setIsEditMode] = useState(true);
	const [percent, setPercent] = useState(0);

	const [editData, setEditData] = useState(null);
	const { urlId, type } = props.match.params;

	debugger;
	const [isLoading, setLoading] = useState(false);
	const { id } = props;
	const [file, setFile] = useState(null);
	const [isLoader, getData, setGetData] = useHttp(
		`/api/StudentImageUpload/?studentId=${urlId}`,
		[]
	);
	debugger;
	const [errors, setErrors] = useState({});
	const [imagePreviewUrl, setPreviewUrl] = useState(
		'http://ctt.trains.com/sitefiles/images/no-preview-available.png'
	);
	const [imageFile, setFileName] = useState(null);
	const [editImageFile, setEditImageFile] = useState(null);

	const {
		onSkipClick,
		title = 'Student Image Upload',
		imageChangeEdit = 'http://ctt.trains.com/sitefiles/images/no-preview-available.png',
		edit,
		stateData = {
			imagePreviewUrl
		},
		imgEdit,
		imageDetailsEdit,
		enableUploadButton
	} = props;
	const skipRecord = e => {
		e.preventDefault();
		onSkipClick();
	};
	useEffect(() => {
		$('#chooseFile').bind('change', function() {
			var filename = $('#chooseFile').val();
			if (/^\s*$/.test(filename)) {
				$('.file-upload').removeClass('active');
				$('#noFile').text('No file chosen...');
			} else {
				$('.file-upload').addClass('active');
				$('#noFile').text(filename.replace('C:\\fakepath\\', ''));
			}
		});
	}, []);
	useEffect(() => {
		let getId = id || urlId;
		props.sharedAction
			.getDataWithoutDispatch(`/api/CheckStudentImageExist/?studentId=${getId}`)
			.then(success => {
				debugger;
				setIsEditMode(false);
			})
			.catch(error => {
				setIsEditMode(true);
				debugger;
			});
	}, []);
	const imageChange = e => {
		e.preventDefault();
		try {
			let reader = new FileReader();
			let file = e.target.files[0];

			if (
				!General.checkForImageOrFile(
					true,
					false,
					errors,
					file.size,
					2.1,
					file.name,
					'imgType',
					setErrors
				)
			) {
				reader.onloadend = () => {
					setFile(file);
					setPreviewUrl(reader.result);
					setFileName(file.name);
					// dispatch({
					// 	type: 'ON_IMAGE_CHANGE',
					// 	payload: {
					// 		file,
					// 		imagePreviewUrl: reader.result,
					// 		imageFile: file.name
					// 	}
					// });
				};
				reader.readAsDataURL(file);
			} else {
			}
		} catch (e) {
			console.log(e);
		}
	};
	const onEditImageChange = e => {
		e.preventDefault();
		try {
			let reader = new FileReader();
			let file = e.target.files[0];

			if (
				!General.checkForImageOrFile(
					true,
					false,
					errors,
					file.size,
					2.1,
					file.name,
					'imgType',
					setErrors
				)
			) {
				reader.onloadend = () => {
					setEditData(file);
					setPreviewUrl(reader.result);
					setEditImageFile(file.name);
				};
				reader.readAsDataURL(file);
			} else {
			}
		} catch (e) {
			console.log(e);
		}
	};
	const onEditUpload = e => {
		let getId = id || urlId;
		let imageData = new FormData();
		imageData.append('file', editData, editData.name);
		imageData.append('accountId', props.name);
		imageData.append('staffId', id || urlId);
		imageData.append('accountType', props.role);
		console.log(editData);
		debugger;
		setLoading(true);
		props.sharedAction
			.putDocumentsWithProgressCallback(
				`/api/StudentImageUpload/?id=${getId}`,
				imageData,
				setPercent
			)
			.then(success => {
				debugger;
				setMsg(success);
				setLoading(false);
				props.snackbar();

				setTimeout(() => {
					props.history.push('/dashboard/ViewAddForm');
				}, 1000);
			})
			.catch(error => {
				debugger;
				setLoading(false);
				setMsg(
					error &&
						error.response &&
						error.response.data &&
						error.response.data.Message
				);

				props.snackbar();
			});
	};
	const onUpload = e => {
		let imageData = new FormData();
		imageData.append('file', file, file.name);
		imageData.append('accountId', props.name);
		imageData.append('studentId', id || urlId);
		imageData.append('accountType', props.role);
		console.log(file);
		debugger;
		setLoading(true);
		props.sharedAction
			.addDocumentsWithProgressCallback(
				'/api/StudentImageUpload',
				imageData,
				setPercent
			)
			.then(success => {
				debugger;
				setMsg(success);
				setLoading(false);
				props.snackbar();
				if (typeof id !== 'undefined') {
					setTimeout(() => {
						onSkipClick();
					}, 1000);
				} else {
					props.history.push('/dashboard/ViewAddForm');
				}
			})
			.catch(error => {
				debugger;
				setLoading(false);
				setMsg(
					error &&
						error.response &&
						error.response.data &&
						error.response.data.Message
				);

				props.snackbar();
			});
	};

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				width: '35%',
				margin: '0 auto',
				boxShadow: '5px 5px 5px grey',
				borderRadius: 8,
				padding: 15
			}}
		>
			<SnackBar backColor={'#000'} msg={snackbarMessage} />
			<div>
				<div>
					<h3 className="generalHead">{title}</h3>
					<br />
					<UploadProgressBar percent={percent} />
					<br />
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{getData == null && isEditMode && editImageFile == null ? (
							<Spinner />
						) : (
							<img
								// style={{
								// 	height: 'auto',
								// 	borderRadius: 5,
								// 	boxShadow: '5px 5px 5px grey'
								// }}
								src={`${
									getData != null && isEditMode && editImageFile == null
										? `${URL_CONSTANTS.URL_STUDENT_IMAGE + getData['fileName']}`
										: stateData.imagePreviewUrl
								}`}
								width={254}
								height={160}
								className="img-thumbnail"
								alt="#img"
							/>
						)}
					</div>

					{/* <i className="fas fa-user-shield animated fadeIn" style={{ marginRight: 4 }}/> */}
					<br />
				</div>
				{!isEditMode ? (
					<div class="custom-file" style={{ width: '100%', borderRadius: 20 }}>
						{errors.file ? (
							<p style={{ color: 'red', fontSize: 15 }}>
								{ValidationText.FIELD_REQUIRED}
							</p>
						) : (
							''
						)}
						{errors.imgType ? (
							<React.Fragment>
								<p className="text-info" style={{ color: 'red', fontSize: 15 }}>
									image format should be in jpg,png,jpeg
								</p>

								<p className="text-info" style={{ color: 'red', fontSize: 15 }}>
									image size should be less than, 2MB
								</p>
							</React.Fragment>
						) : (
							''
						)}
						<div class="file-upload">
							<div class="file-select">
								<div class="file-select-button" id="fileName">
									Choose File
								</div>
								<div class="file-select-name" id="noFile">
									{imageFile}
								</div>
								<input
									type="file"
									name={'imageFile'}
									onChange={e => imageChange(e)}
								/>
							</div>
						</div>
					</div>
				) : (
					''
				)}
				{isEditMode ? (
					<div>
						<div>
							{errors.imgType ? (
								<React.Fragment>
									<p
										className="text-info"
										style={{ color: 'red', fontSize: 15 }}
									>
										image format should be in jpg,png,jpeg
									</p>

									<p
										className="text-info"
										style={{ color: 'red', fontSize: 15 }}
									>
										image size should be less than, 2MB
									</p>
								</React.Fragment>
							) : (
								''
							)}
						</div>
						<div class="file-upload">
							<div class="file-select">
								<div class="file-select-button" id="fileName">
									Choose File
								</div>
								<div class="file-select-name" id="noFile">
									{editImageFile == null
										? getData && getData['fileName']
										: editImageFile}
								</div>
								<input
									type="file"
									name={'imageFile'}
									onChange={e => onEditImageChange(e)}
								/>
							</div>
						</div>
					</div>
				) : (
					''
				)}
				<br />
				{isEditMode ? (
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div>
							<Button
								icon={true}
								iconSize={12}
								textColor="#fff"
								iconClass="fas fa-upload"
								btnName="Upload"
								buttonClass="btn-smart-one"
								loaderBmargin={2}
								customClause={editImageFile == null ? true : false}
								stateData={{
									isLoading: isLoading
								}}
								onHandleSubmit={onEditUpload}
							/>
						</div>
					</div>
				) : (
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div>
							<Button
								icon={true}
								iconSize={12}
								textColor="#fff"
								iconClass="fas fa-upload"
								btnName="Upload"
								buttonClass="btn-smart-one"
								loaderBmargin={2}
								customClause={file == null ? true : false}
								stateData={{
									isLoading: isLoading
								}}
								onHandleSubmit={onUpload}
							/>
						</div>
						{type !== 'edit' ? (
							<div style={{ marginTop: 8 }}>
								<span onClick={skipRecord} class="badge badge-light">
									{' '}
									<a style={{ textDecoration: 'none', fontSize: 15 }} href="">
										Skip Step{' '}
										<i
											style={{
												fontSize: 13,
												padding: 5,
												cursor: 'pointer',
												color: '#000'
											}}
											class={'fas fa-forward'}
										></i>{' '}
									</a>
								</span>
							</div>
						) : (
							''
						)}
					</div>
				)}
			</div>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StudentImageUploadSection);
