import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SyllabusAction from '../../Actions/Syllabus';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import ShowSyllabusData from './ShowSyllabusData';

class ViewSyllabus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			sections: [],
			termType: '',
			classId: '',
			sectionId: '',
			sessionId: '',
			modalData: null,
			vehicleNo: '',
			capacity: '',
			transportType: '',
			enabled: false,
			id: null,
			select: [],
			routeId: '',
			routeName: '',
			startingServiceKm: '',
			midServiceKm: '',
			endingServiceKm: '',
			vehicleDateOfReg: '',
			vehicleDateOfRegExpiry: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			increament: 1,
			selectClassData: '',
			sessionData: '',
			campusId: '',
			departmentId: '',
			departments: [],
			postId: '',
			Post: [],
			btnLoad: false,
			errorMsg: false,
			checking: [],
			filecheck: false
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.adminData !== nextProps.AllTransportData ||
			prevState.selectClassData !== nextProps.selectData ||
			prevState.sessionData !== nextProps.sessionSelect
		) {
			return {
				adminData: nextProps.AllTransportData,
				selectClassData: nextProps.selectData,
				sessionData: nextProps.sessionSelect
			};
		}
		return null;
	}
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.gAllData(
				'/api/Session',
				this.props.SyllabusAction.getSessionsForSyllabus
			)
			.then(success => {})
			.catch(error => {});
		this.props.sharedActions
			.gAllData(
				'/api/classSection',
				this.props.SyllabusAction.getClassGradeSubjectForSyllabus
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}
	onSearchStudent = e => {
		this.setState({
			btnLoad: true
		});
		const { classId, sectionId, sessionId } = this.state;
		if (
			Object.is(classId, '') ||
			Object.is(sectionId, '') ||
			Object.is(sessionId, '')
		) {
			this.setState({
				errorMsg: true,
				btnLoad: false
			});
		} else {
			this.props.sharedActions
				.getAllData(
					`/api/getSyllabus/?termType=${this.state.termType}&classId=${this.state.classId}&sectionId=${this.state.sectionId}&sessionId=${this.state.sessionId}`,
					this.props.SyllabusAction.setAllSyllabusInRedux,
					this.props.SyllabusAction.updateSyllabusFlag
				)
				.then(success => {
					this.setState({
						isLoading: false,
						btnLoad: false,
						filecheck: true
					});
				})
				.catch(err => {
					this.props.SyllabusAction.updateSyllabusFlag(false);

					this.setState({
						isLoading: false,
						btnLoad: false
					});

					console.log(err);
				});
		}
	};
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};
	paginationRequest = number => {
		console.log(number);

		this.props.sharedActions
			.getAllData(
				`/api/getStaffByDepartmentPost?departmentId=${this.state.departmentId}&campusId=${this.state.campusId}&postId=${this.state.postId}&pageNumber=1&pageSize=10`,
				this.props.PostActions.setAllSearchStaffThroughPostInRedux,
				this.props.PostActions.updatePostFlag
			)
			.then(success => {
				this.setState({
					isLoading: false,
					checking: this.state.adminData.paginationMetadata
				});
			})
			.catch(err => {
				this.props.DepartmentActions.updateDepartmentFlag(false);

				this.setState({
					isLoading: false
				});

				console.log(err);
			});
	};
	onSelectChange = e => {
		console.log(e.target.value);

		const sections = this.state.selectClassData.section;
		let check = sections.filter(item => item.classId == e.target.value);
		console.log(check);

		this.setState({
			sections: check,
			classId: e.target.value
		});
	};
	onSectionChange = e => {
		console.log(e.target.value);
		this.setState({
			sectionId: e.target.value
		});
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);
		this.setState({
			sessionId: e.target.value
		});
	};
	render() {
		const { adminData, modalData, select } = this.state;
		console.log('Admin Data', this.state.adminData);
		console.log('Class Ka Data', this.state.selectClassData);
		console.log('Session Ka Data', this.state.sessionData);
		const { classList } = this.state.selectClassData;
		const { session } = this.state.sessionData;
		const { staff } = this.state.adminData;
		const { paginationMetadata } = this.state.adminData;
		console.log('Pagination Ka Data', paginationMetadata);
		console.log('Pagination Checking Ka Data', this.state.checking);

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<label style={{ color: 'red' }}>No Data</label>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <=
			Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return (
				<li
					class={`page-item ${
						this.state.checking.currentPage == number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={() => this.paginationRequest(number)}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			);
		});
		const MainContent = (
			<div>
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th style={{ padding: 3 }} scope="col">
									#
								</th>
								<th style={{ padding: 3 }} scope="col">
									Class
								</th>
								<th style={{ padding: 3 }} scope="col">
									Section
								</th>
								<th style={{ padding: 3 }} scope="col">
									Session
								</th>
								<th style={{ padding: 3 }} scope="col">
									Term
								</th>
								<th style={{ padding: 3 }} scope="col">
									File Name
								</th>
								<th style={{ padding: 3 }} scope="col">
									Date
								</th>
								<th style={{ padding: 3 }} scope="col">
									Time
								</th>
								<th style={{ padding: 3 }} scope="col">
									Account Id
								</th>
								<th style={{ padding: 3 }} scope="col">
									Account Type
								</th>
								<th style={{ padding: 3 }} scope="col">
									Actions
								</th>
							</tr>
						</thead>
						<tbody>
							<ShowSyllabusData data={this.state.adminData} />
						</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				{this.state.errorMsg ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Please Select the Fields!
					</p>
				) : (
					''
				)}
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Search Syllabus</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								{renderPageNumbers}
							</ul>
							{/* {this.state.filecheck ?  <ul>
                                    <a href="#" data-toggle="tooltip" title="Download File">
                                        <form action={`http://https://embassyapp.linkit.org.uk/api/api/downloadStaffByCampDeptPost?departmentId=${this.state.departmentId}&postId=${this.state.postId}&campusId=${this.state.campusId}`}>
                                            <button type="submit" style={{ cursor: 'pointer', fontSize: 20, border: 'none', marginLeft: 5, background: 'none', marginBottom: 5 }}>
                                                <i style={{ color: '#01425e' }} class="fas fa-file-csv"></i>
                                            </button></form></a></ul> : "" } */}
						</nav>
					</div>
				</div>
				<div class="row" style={{ justifyContent: 'center' }}>
					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="name" class="">
								Plan Type
							</label>
							<select
								style={{ borderColor: '#01AC8A' }}
								onChange={this.onHandleTextChange}
								class="custom-select"
								name="termType"
							>
								<option>Choose Type..</option>
								<option value="1st Terminal">1st Terminal</option>
								<option value="2nd Terminal">2nd Terminal</option>
								<option value="3rd Terminal">3rd Terminal</option>
							</select>
						</div>
					</div>
					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								Class Name
							</label>
							<select
								style={{ borderColor: '#01AC8A' }}
								onChange={this.onSelectChange}
								class="custom-select"
								name="className"
							>
								<option selected={this.state.msg ? true : false}>
									Choose Class...
								</option>
								{classList &&
									classList.map((item, index) => (
										<option key={index} value={`${item.id}`}>
											{item.className}
										</option>
									))}
							</select>
						</div>
					</div>

					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								Section
							</label>
							<select
								style={{ borderColor: '#01AC8A' }}
								onChange={this.onSectionChange}
								class="custom-select"
								name="className"
							>
								<option selected={this.state.msg ? true : false}>
									Choose Section...
								</option>
								{this.state.sections.map((item, index) => (
									<option key={index} value={`${item.sectionId}`}>
										{item.section}
									</option>
								))}
							</select>
						</div>
					</div>
					<div class="col-md-3" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							<label for="email" class="">
								Session's
							</label>
							<select
								style={{ borderColor: '#01AC8A' }}
								onChange={this.onSelectSessionChange}
								class="custom-select"
								name="sectionName"
							>
								<option selected={this.state.msg ? true : false}>
									Select Current Session..
								</option>
								{session &&
									session.map((item, index) => (
										<option value={`${item.sessionId}`}>
											{item.currentSession}
										</option>
									))}
							</select>
						</div>
					</div>
				</div>
				<div class="text-center text-md-right">
					<button type="submit" class="btnss" onClick={this.onSearchStudent}>
						Search
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
					</button>
				</div>
				<br />
				{this.props.allTransportState.apiFlag ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		SyllabusAction: bindActionCreators(SyllabusAction, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllTransportData: state.setSyllabusReducer.Data,
		selectData: state.setSyllabusReducer.selectStudent,
		sessionSelect: state.setSyllabusReducer.selectSession,
		allTransportState: state.setSyllabusReducer,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewSyllabus);
