import * as actionTypes from '../actionTypes';

export function getSupplierForAddInventory(allData) {
	return {
		type: actionTypes.SET_ALL_SUPPLIER_NAME_INTO_ADD_INVENTORY_ITEM_SELECT,
		payload: allData
	};
}
export function getCategoryForAddInventory(allData) {
	return {
		type: actionTypes.SET_ALL_CATEGORY_NAME_INTO_ADD_INVENTORY_ITEM_SELECT,
		payload: allData
	};
}

export function setAllInventoryItemsInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_INVENTORY,
		payload: allData
	};
}

export function addInventoryItem(Data) {
	return {
		type: actionTypes.ADD_INVENTORY_ITEM,
		payload: Data
	};
}

export function updateAddInventoryFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_INVENTORY_ADD,
		payload: setFlag
	};
}

export function updateInventoryItem(data) {
	return {
		type: actionTypes.EDIT_INVENTORY_ITEM_RECORD,
		payload: data
	};
}

export function deleteInventoryItem(id) {
	return {
		type: actionTypes.DELETE_INVENTORY_ITEM,
		payload: id
	};
}
