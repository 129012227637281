import * as actionTypes from '../actionTypes';

export function setAllQuizInfoInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_QUIZ_INFO,
		payload: allData
	};
}

export function getClassesForQuizInfo(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_QUIZ_INFO_SELECT,
		payload: allData
	};
}
export function getSessionForQuizInfo(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_QUIZ_INFO_SELECT,
		payload: allData
	};
}
export function addQuizInfo(Data) {
	return {
		type: actionTypes.ADD_QUIZ_INFO,
		payload: Data
	};
}
export function updateQuizInfoFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_QUIZ_INFO,
		payload: setFlag
	};
}
export function updateQuizInfo(data) {
	return {
		type: actionTypes.EDIT_QUIZ_INFO_RECORD,
		payload: data
	};
}
export function deleteQuizInfo(id) {
	return {
		type: actionTypes.DELETE_QUIZ_INFO,
		payload: id
	};
}
