import React, { Component } from 'react';
import StudentAdmission from './StudentAdmission';
import ParentInfo from './ParentInfo';
import StudentDocument from './StudentDocument';
import StudentAdress from './StudentAdress';
import ParentsDocuments from './ParentsDocuments';
import MedicalCondition from './MedicalCondition';
import StudentSiblingDetail from './StudentSiblingDetail';
import PreviousSchoolDetails from './PreviousSchoolDetails';
import WidthdrawDetail from './WidthdrawDetail';
import OtherInformation from './OtherInformation';
import StudentTransport from './StudentTransport';
import { Prompt } from 'react-router-dom';
import * as sharedActions from '../../Actions/sharedActions';
import * as actions from '../../Actions/Admission';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as campusAction from '../../Actions/Campus';
import Axios from 'axios';
import LayoutWrapper from '../../components/HOC/LayoutWrapper';

import validator from '../../validator/registrationValidator';
const sports = [
	'cricket',
	'football',
	'hockey',
	'tennis',
	'basketball',
	'baseball'
];
class EditAdmissionForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//start of image upload
			imagePreviewUrl:
				'http://ctt.trains.com/sitefiles/images/no-preview-available.png',
			studentImage: '',
			file: null,
			fileName: '',
			editSection: false,
			sType: 'EditSection',
			//end of image upload
			//extra feild for transport
			otherDriverName: '',
			otherCarNo: '',
			otherDriverCardIdNo: '',
			otherDriverMobileNo: '',
			pickUp: '',
			// end of extra transport

			//extra data feilds of medical condition
			threatConditionReason: null,
			needMedicationReason: null,
			otherMedicationReason: null,
			//end of extra feilds
			//end of all id's
			RouteOBJ: null,
			campusData: [],
			sessionData: [],
			valid: false,
			isLoading: false,
			successFlag: false,
			// all id's
			//
			studentAdmissionFormId: '',
			studentAddressId: '',
			parentInfoId: '',
			studentDocId: '',
			parentDocId: '',
			MedicalConditionId: '',
			OtherInformationId: '',
			StudentTransportId: '',
			WidthdrawDetailId: '',
			studentId: '',

			//student transport
			transportStatus: null,

			routeId: '',
			driverId: '',
			driverName: '',
			driverLicense: '',
			driverIdCardCopy: '',
			parentOrByWalk: '',
			driverMobileNo: '',
			transportType: '',
			routeName: '',
			vehicleNo: '',
			// parentDocs
			passportFather: '',
			passportMother: '',
			idCardFather: '',
			idCardMother: '',
			// student otherinformation

			sports: '',
			otherActivity: '',

			//student widthdrawDetails
			dateLeaving: null,
			classLeft: '',
			reason: '',
			// student previous school details
			previousSchoolDetails: [],
			// student Medical Condition
			threatCondition: '',
			needMedication: '',
			otherMedication: '',
			//student siblings form
			siblingsDetails: [],
			// Student Documents
			//changes ??
			dobCertificate: null,
			transferCertificate: '',
			ministryVacination: '',
			medicalHistory: '',
			passportCopy: null,
			idCardCopy: null,
			lastResult: '',
			testResult: '',
			// Student Parent Info fields
			fatherPName: '', //change on submit
			fullNameArabic: '',
			motherName: '',
			garduainName: '',
			occupation: '',
			income: '',
			emergencyContact: '',
			parentMobileNo: '',
			parentEmailId: '',
			parentCNIC: '',
			parentIdCardNo: '',
			// Student Address
			studentIdCardNo: '',
			address: '',
			studentEmailId: '',
			houseNo: '',
			streetNo: '',
			city: '',
			area: '',
			wayNo: '',
			country: '',
			//Student Admission Form
			firstName: '',
			lastName: '',
			registrationNo: '',
			admissionNo: '',
			fatherName: '',
			admissionType: '',
			currentSessionId: '',
			language: '',
			rollNo: '',
			parentId: '',
			parentName: '',
			gender: '',
			dob: null,
			age: '',
			placeOfBirth: '',
			nationality: '',
			religion: '',
			isEnable: false,
			campusId: '',
			campusName: '',
			className: '',
			classId: '',
			gradeId: '',
			section: '',
			sectionId: '',
			userName: '',
			password: '',
			grade: null,
			gradeName: '',
			status: '',
			accountId: '',
			accountName: 'q',
			accountType: '',
			accountStatus: '',
			error: false,
			errors: {},
			dummySample: {
				classes: []
				// grade: [
				//     {
				//         className: "Pre-Nursery",
				//         gradeName: "Pre-Nursery",
				//         section: "A"
				//     },
				//     {
				//         className: "1",
				//         gradeName: "Primary",
				//         section: "A"
				//     },
				//     {
				//         className: "1",
				//         gradeName: "Primary",
				//         section: "B"
				//     }
				// ]
			}
		};
		this.main = React.createRef();
	}

	onChangeDate = (date, state) => {
		let getAge = new Date().getFullYear() - new Date(date).getFullYear();

		this.setState({ [state]: date, age: getAge });
	};
	handleScroll = e => {
		e.preventDefault();
		const main = this.main.current;
		window.scrollTo({
			top: main.offsetTop,
			left: 0,
			behavior: 'instant'
		});
	};
	onChangeNoOptions = e => {
		switch (e.target.value) {
			case 'ByWalk':
				this.setState({
					parentOrByWalk: e.target.value,
					otherDriverName: '',
					otherCarNo: '',
					otherDriverCardIdNo: '',
					otherDriverMobileNo: ''
				});
				break;
			case 'ParentPick':
				this.setState({
					parentOrByWalk: e.target.value,
					otherDriverName: '',
					otherCarNo: '',
					otherDriverCardIdNo: '',
					otherDriverMobileNo: ''
				});
				break;
			case 'DriverPick':
				this.setState({
					parentOrByWalk: e.target.value
				});
				break;
			default:
				break;
		}
	};
	onChangeTransportStatus = e => {
		switch (e.target.value) {
			case 'Yes':
				this.setState({
					transportStatus: e.target.value,
					parentOrByWalk: null
				});
				break;
			case 'No':
				this.setState({
					transportStatus: e.target.value,
					routeName: '',
					driverName: '',
					routeId: '',
					driverIdCardNo: '',
					driverLicense: '',
					transportType: '',
					driverMobileNo: '',
					vehicleNo: '',
					driverId: '',
					otherDriverName: '',
					otherCarNo: '',
					otherDriverCardIdNo: '',
					otherDriverMobileNo: ''
				});
				break;
			default:
				break;
		}
	};
	appearUploadButton = () => {
		this.setState({
			editSection: true
		});
	};
	componentDidUpdate() {
		// console.log('dummysmaple:',this.state.dummySample.classes)
		// const { className } = this.state
		// if(this.state.dummySample && this.state.dummySample.grade){
		//   const { grade } = this.state.dummySample;
		//   ;
		//   let getGradeData = grade.filter((item, index) => item.className === className)
		//   console.log(getGradeData);
		//   this.setState({
		//     grade: getGradeData,
		//     gradeName: getGradeData[0] && getGradeData[0].gradeName,
		//     className: className,
		//   })
		// }
	}
	onChangeClassName = e => {
		console.log(e.target.value);
		const { grade } = this.state.dummySample;
		let getGradeData = grade.filter(
			(item, index) => item.classId === parseInt(e.target.value)
		);
		console.log(getGradeData);

		this.setState({
			grade: getGradeData,
			gradeName: getGradeData[0] && getGradeData[0].gradeName,
			className: getGradeData[0] && getGradeData[0].className,
			classId: parseInt(e.target.value),
			gradeId: getGradeData[0] && getGradeData[0].gradeId
		});
	};
	onChangeRoutesName = e => {
		console.log(e.target.value);
		const { route, transport } =
			this.props.admissionState && this.props.admissionState.getData;
		let getRouteNameObj = transport.filter(
			item => item.routeName !== e.target.value
		);
		console.log(route, transport);
		if (e.target.value === 'Routes Name...') {
			this.setState({
				routeName: '',
				driverName: '',
				routeId: '',
				driverIdCardNo: '',
				driverLicense: '',
				transportType: '',
				driverMobileNo: '',
				vehicleNo: '',
				driverId: ''
			});
		} else {
			this.setState({
				routeName: getRouteNameObj[0].routeName,
				driverName: getRouteNameObj[0].driverName,
				routeId: getRouteNameObj[0].id,
				driverIdCardNo: getRouteNameObj[0].driverIdCardNo,
				driverLicense: getRouteNameObj[0].driverLicense,
				transportType: getRouteNameObj[0].transportType,
				driverMobileNo: getRouteNameObj[0].driverMobileNo,
				vehicleNo: getRouteNameObj[0].vehicleNo,
				driverId: getRouteNameObj[0].id
			});
		}
	};
	enableUploadButton = () => {
		this.setState({
			sType: 'AddSection'
		});
	};
	onGeneralSelectChangeHandler = (e, statePropName, statePropId, id) => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		console.log(result['campusId']);

		this.setState({
			[statePropName]: result[statePropName],
			[statePropId]: result[id]
		});
	};
	isValidstudentAddForm = formType => {
		const {
			//parentDocs
			passportFather,
			passportMother,
			idCardFather,
			idCardMother,
			//student transport details
			transportStatus,
			routeName,
			transportType,
			driverLicense,
			driverName,
			parentOrByWalk,
			otherDriverName,
			otherCarNo,
			otherDriverCardIdNo,
			otherDriverMobileNo,
			//student other details
			otherActivity,
			sports,
			//widthdrawdetails
			dateLeaving,
			reason,
			classLeft,
			//previousSchoolDetails
			previousSchoolDetails,
			//medical Condition
			threatConditionReason,
			needMedicationReason,
			otherMedicationReason,
			threatCondition,
			needMedication,
			otherMedication,
			//student siblings form
			siblingsDetails,
			// student document Info
			dobCertificate,
			transferCertificate,
			ministryVacination,
			medicalHistory,
			passportCopy,
			idCardCopy,
			lastResult,
			testResult,
			// student parent Info
			parentIdCardNo,
			parentCNIC,
			parentEmailId,
			parentMobileNo,
			emergencyContact,
			income,
			occupation,
			garduainName,
			motherName,
			fatherPName,
			fullNameArabic,

			// student Address Details fields
			wayNo,
			area,
			city,
			streetNo,
			houseNo,
			studentEmailId,
			address,
			studentIdCardNo,
			//Student Admission form fields
			file,
			sType,
			registrationNo,
			firstName,
			lastName,
			fatherName,
			rollNo,
			admissionNo,
			admissionType,
			currentSessionId,
			language,
			parentName,
			gender,
			dob,
			age,
			parentId,
			placeOfBirth,
			religion,
			campusName,
			className,
			grade,
			section,
			userName,
			password,
			nationality,
			status,
			gradeName,
			country
		} = this.state;
		let getAddressFeilds = {
			wayNo,
			area,
			city,
			streetNo,
			houseNo,
			studentEmailId,
			address,
			studentIdCardNo,
			country
		};
		let getFeilds = {
			file,
			firstName,
			fullNameArabic,
			lastName,
			fatherName,
			rollNo,
			admissionNo,
			admissionType,
			currentSessionId,
			language,
			parentName,
			gender,
			dob,
			age,
			placeOfBirth,
			religion,
			campusName,
			className,
			grade,
			section,
			userName,
			password,
			nationality,
			status,
			gradeName,
			parentId,
			country
		};
		if (Object.is(formType, 'StudentAdmissionForms')) {
			if (
				(Object.is(file, null) && Object.is(sType, 'AddSection')) ||
				Object.is(registrationNo, '') ||
				Object.is(firstName, '') ||
				Object.is(lastName, '') ||
				Object.is(fatherName, '') ||
				Object.is(rollNo, '') ||
				Object.is(admissionNo, '') ||
				Object.is(admissionType, 'Admission Type...') ||
				Object.is(parentId, '') ||
				Object.is(currentSessionId, 'Current Session...') ||
				Object.is(language, 'Choose Language...') ||
				// Object.is(parentName, '')

				Object.is(gender, 'Choose Gender...') ||
				Object.is(dob, null) ||
				Object.is(age, '') ||
				Object.is(placeOfBirth, '') ||
				Object.is(religion, 'Religion...') ||
				Object.is(campusName, 'Campus Name...') ||
				Object.is(className, 'Class Name...') ||
				Object.is(gradeName, '') ||
				Object.is(section, 'Section...') ||
				Object.is(userName, '') ||
				Object.is(password, '') ||
				Object.is(status, 'Choose Status...') ||
				Object.is(nationality, 'Choose Nationality...')
			) {
				let { errors, isValid } = validator(getFeilds, 'checkStudentForm');
				this.setState({
					errors
				});
				return false;
			} else {
				return true;
			}
		} else if (Object.is(formType, 'StudentAddresss')) {
			if (
				Object.is(wayNo, '') ||
				Object.is(studentIdCardNo, '') ||
				Object.is(address, '') ||
				Object.is(city, '') ||
				Object.is(streetNo, '') ||
				Object.is(houseNo, '') ||
				Object.is(studentEmailId, '') ||
				Object.is(area, '') ||
				Object.is(country, 'Choose Country...')
			) {
				let { errors, isValid } = validator(
					getAddressFeilds,
					'checkAddressForm'
				);
				this.setState({
					errors
				});
				return false;
			} else {
				this.setState({
					errors: {}
				});
				return true;
			}
		} else if (Object.is(formType, 'ParentInfos')) {
			if (
				Object.is(parentIdCardNo, '') ||
				Object.is(parentEmailId, '') ||
				Object.is(parentMobileNo, '') ||
				Object.is(emergencyContact, '') ||
				Object.is(income, '') ||
				Object.is(occupation, '') ||
				Object.is(garduainName, '') ||
				Object.is(motherName, '') ||
				Object.is(fatherPName, '')
			) {
				return false;
			} else {
				return true;
			}
		} else if (Object.is(formType, 'StudentDocs')) {
			if (
				Object.is(dobCertificate, '') ||
				Object.is(transferCertificate, '') ||
				Object.is(ministryVacination, '') ||
				Object.is(medicalHistory, '') ||
				Object.is(idCardCopy, null) ||
				Object.is(passportCopy, null) ||
				Object.is(lastResult, '') ||
				Object.is(testResult, '')
			) {
				return false;
			} else {
				return true;
			}
		} else if (Object.is(formType, 'StudentSiblingDetails')) {
			let isValid = siblingsDetails.map((item, index) => {
				console.log(this.IsObjectEmpty(item));
				if (!this.IsObjectEmpty(item)) {
					return false;
				} else {
					return true;
				}
			});
			console.log(isValid);
			let getValue = false;
			if (isValid.includes(false)) {
				getValue = false;
			} else {
				getValue = true;
			}
			return getValue;
		} else if (Object.is(formType, 'MedicalConditions')) {
			if (
				Object.is(threatConditionReason, 'Yes') &&
				Object.is(threatCondition, '')
			) {
				return false;
			} else if (
				Object.is(needMedicationReason, 'Yes') &&
				Object.is(needMedication, '')
			) {
				return false;
			} else if (
				Object.is(otherMedicationReason, 'Yes') &&
				Object.is(otherMedication, '')
			) {
				return false;
			} else if (
				Object.is(otherMedicationReason, null) ||
				Object.is(needMedicationReason, null) ||
				Object.is(threatConditionReason, null)
			) {
				return false;
			} else {
				return true;
			}
		} else if (Object.is(formType, 'PreviousSchoolDetails')) {
			let isValid = previousSchoolDetails.map((item, index) => {
				console.log(this.IsObjectEmpty(item));
				if (!this.IsObjectEmpty(item)) {
					return false;
				} else {
					return true;
				}
			});
			console.log(isValid);
			let getValue = false;
			if (isValid.includes(false)) {
				getValue = false;
			} else {
				getValue = true;
			}
			return getValue;
		} else if (Object.is(formType, 'ParentDocsForm')) {
			if (
				Object.is(passportFather, '') ||
				Object.is(passportMother, '') ||
				Object.is(idCardFather, '') ||
				Object.is(idCardMother, '')
			) {
				return false;
			} else {
				return true;
			}
		} else if (Object.is(formType, 'WidthdrawDetails')) {
			if (
				Object.is(reason, '') ||
				Object.is(classLeft, '') ||
				Object.is(classLeft, 'Class Name...') ||
				Object.is(dateLeaving, null)
			) {
				return false;
			} else {
				return true;
			}
		} else if (Object.is(formType, 'OtherInformations')) {
			if (
				Object.is(sports, '') ||
				Object.is(otherActivity, '') ||
				Object.is(sports, 'Sports...')
			) {
				return false;
			} else {
				return true;
			}
		} else if (Object.is(formType, 'StudentTransports')) {
			if (Object.is(transportStatus, null)) {
				return false;
			} else if (transportStatus === 'Yes' && routeName === '') {
				return false;
			} else if (transportStatus === 'No' && parentOrByWalk === null) {
				return false;
			} else if (
				transportStatus === 'No' &&
				parentOrByWalk === 'DriverPick' &&
				(otherDriverName === '' ||
					otherDriverMobileNo === '' ||
					otherDriverCardIdNo === '' ||
					otherCarNo === '')
			) {
				return false;
			} else {
				return true;
			}
		}
	};
	imageChange = e => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			this.setState({
				file: file,
				imagePreviewUrl: reader.result,
				fileName: file.name
			});
		};

		reader.readAsDataURL(file);
	};
	IsObjectEmpty = Obj => {
		let checkFlag = false;
		for (let value of Object.values(Obj)) {
			if (Object.is(value, '')) {
				checkFlag = false; // John, then 30
				break;
			}
			checkFlag = true;
		}
		console.log(checkFlag);
		return checkFlag;
	};
	onCustomAdmissionChange = e => {
		const { registrationNo, parentId, gender } = this.state;
		console.log(e.target.value);

		if (e.target.value === 'male' || e.target.value === 'female') {
			this.setState({
				gender: e.target.value,
				admissionNo:
					registrationNo +
					'-' +
					parentId +
					'-' +
					(e.target.value === 'male'
						? 'M'
						: e.target.value === 'female'
						? 'F'
						: '(F/M)') +
					'-' +
					new Date().getUTCFullYear()
			});
		} else {
			this.setState({
				admissionNo:
					registrationNo +
					'-' +
					(e.target.value !== '' ? e.target.value : 'Fcode') +
					'-' +
					gender.charAt(0).toUpperCase() +
					'-' +
					new Date().getUTCFullYear(),
				parentId: e.target.value
			});
		}
		console.log(this.state);
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.dummySample !== nextProps.getClassSectionGrade.Data) {
			return {
				dummySample: { ...nextProps.getClassSectionGrade.Data },
				// section: nextProps.location.state.StudentAdmissionForms.section,
				campusData: nextProps.campusData,
				sessionData: nextProps.sessionData
			};
		}
		return null;
	}

	conversionOfImageUrl = url => {
		let getSplitResult = url.split('\\');
		let result = '';
		for (let i = 4; i < getSplitResult.length; i++) {
			if (getSplitResult.length - 1 == i) result += getSplitResult[i];
			else result += getSplitResult[i].concat('\\\\');
		}
		let finalResult = 'http://'.concat(`${result}`);
		return finalResult;
	};
	componentDidMount() {
		// if (this.props.getClassSectionGrade.apiFlag) {
		this.props.sharedAction
			.gAllData(
				'/api/StudentAdmissionTransport',
				this.props.actions.getAllRoutesDetails
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});

		Promise.all([
			this.props.sharedAction.getAllData(
				'/api/getclasssectiongrade',
				this.props.actions.setAllClassSectonGrade,
				this.props.actions.updateSectionGradeLoader
			),
			this.props.sharedAction.gAllData(
				'/api/Session',
				this.props.actions.getCurrentSession
			),
			this.props.sharedAction.gAllData(
				'/api/CampusDetail',
				this.props.actionCampus.setAllCampusInRedux
			)
		])
			.then(success => {
				this.setState({
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});
			});
		// }
		console.log('dummysmaple:', this.state.dummySample.classes);
		const { location } = this.props;
		let getData = { ...location.state };
		let getParentInfoData = getData.ParentInfos;
		let updateParentInfo = {
			...getParentInfoData,
			fatherPName: getParentInfoData.fatherName
		};
		delete updateParentInfo.fatherName;
		console.log('updateParentData: ', updateParentInfo);

		let extractData = {
			...getData.RoutesName,
			...getData.DriverName,
			...getData.StudentAdmissionForms,
			...getData.StudentAddresss,
			...getData.StudentDocs,
			...getData.ParentDocs,
			...getData.MedicalConditions,
			siblingsDetails: [...getData.StudentSiblingDetails],
			previousSchoolDetails: [...getData.PreviousSchoolDetails],
			...getData.OtherInformations,
			...getData.WidthdrawDetails,
			...getData.StudentTransports,
			...updateParentInfo,
			...getData.CampusGrade
		};
		debugger;
		this.setState({
			...extractData
		});

		// let ge
		// this.setState({
		//   getData
		// })
	}

	onClickDeleteItem = arrName => {
		let getStateArr = this.state[`${arrName}`];
		let getDeleteLength = getStateArr.length - 1;
		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			this.setState({
				[arrName]: [...getNewDetails]
			});
		}
	};
	onClickAddItem = (arrName, obj) => {
		let getStateArr = this.state[`${arrName}`];
		getStateArr = [...getStateArr, obj];
		console.log(arrName);
		this.setState({
			[arrName]: getStateArr
		});
	};
	updateSpecialArrObjOnChange = (e, index, arrName) => {
		const changeArrOfObj = this.state[`${arrName}`];
		changeArrOfObj[index][e.target.name] = e.target.value;
		// arrName = changeArrOfObj;
		// console.log(arrName);
		this.setState({
			[arrName]: changeArrOfObj
		});
	};
	onChangeText = e => {
		if (e.target.name === 'section') {
			const { grade } = this.state.dummySample;
			let getSectionName = grade.filter(
				item => item.sectionId === parseInt(e.target.value)
			)[0].section;

			this.setState({
				section: getSectionName,
				sectionId: e.target.value
			});
		} else {
			console.log(e.target.value);

			this.setState({
				[e.target.name]: e.target.value
			});
		}
	};

	onSubmit = e => {
		e.preventDefault();

		const {
			//file
			file,
			//end of file
			//parentDocs
			passportFather,
			passportMother,
			idCardFather,
			idCardMother,
			//student transport details
			transportStatus,
			routeId,
			driverId,
			driverMobileNo,
			driverIdCardCopy,
			driverIdCardNo,
			vehicleNo,
			driverName,
			driverLicense,
			parentOrByWalk,
			transportType,
			otherDriverName,
			otherCarNo,
			otherDriverCardIdNo,
			otherDriverMobileNo,
			//student other details
			otherActivity,
			sports,
			//widthdrawdetails
			dateLeaving,
			reason,
			classLeft,
			//previousSchoolDetails
			previousSchoolDetails,
			//medical Condition
			threatConditionReason,
			needMedicationReason,
			otherMedicationReason,
			threatCondition,
			needMedication,
			otherMedication,
			//student siblings form
			siblingsDetails,
			// student document Info
			dobCertificate,
			transferCertificate,
			ministryVacination,
			medicalHistory,
			passportCopy,
			idCardCopy,
			lastResult,
			testResult,
			// student parent Info
			parentIdCardNo,
			parentCNIC,
			parentEmailId,
			parentMobileNo,
			emergencyContact,
			income,
			occupation,
			garduainName,
			motherName,
			fatherPName, // change

			// student Address Details fields
			wayNo,
			area,
			city,
			streetNo,
			houseNo,
			studentEmailId,
			address,
			studentIdCardNo,
			//Student Admission form fields
			registrationNo,
			firstName,
			lastName,
			fatherName,
			rollNo,
			classId,

			campusId,
			admissionNo,
			gradeId,
			admissionType,
			currentSessionId,
			parentId,
			language,
			parentName,
			gender,
			dob,
			age,
			placeOfBirth,
			religion,
			campusName,
			className,
			grade,
			section,
			sectionId,
			userName,
			password,
			nationality,
			status,
			gradeName,
			country,
			studentAdmissionFormId,
			studentAddressId,
			parentInfoId,
			studentDocId,
			MedicalConditionId,
			OtherInformationId,
			StudentTransportId,
			WidthdrawDetailId,
			parentDocId,
			studentId,
			fullNameArabic
		} = this.state;
		console.log(this.state);

		//data sending structure
		const { match } = this.props;

		let checkFormValidation = this.isValidstudentAddForm(
			match.params.sectionType
		);
		console.log(checkFormValidation);
		if (!this.isValidstudentAddForm(match.params.sectionType)) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else {
			debugger;
			const Data = {
				StudentAdmissionForms: {
					fullNameArabic,
					studentAdmissionFormId,
					firstName,
					lastName,
					fatherName,
					registrationNo,
					rollNo,
					classId,
					sectionId,
					admissionNo,
					admissionType,
					currentSessionId,
					language,
					parentId,
					gender,
					dob,
					age,
					placeOfBirth,
					nationality,
					religion,
					campusId,
					campusName,
					className,
					gradeId,
					section,
					userName,
					password,
					accountId: this.props.name,
					accountType: this.props.role,
					resultStatus: '',
					status
				},
				StudentAddresss: {
					studentAddressId,
					address,
					studentId,
					houseNo,
					studentIdCardNo,
					studentEmailId,
					wayNo,
					streetNo,
					area,
					city,
					country
				},
				ParentInfos: {
					parentInfoId,
					studentId,
					fatherName: fatherPName,
					motherName,
					garduainName,
					occupation,
					income,
					emergencyContact,
					parentMobileNo,
					parentEmailId,
					parentIdCardNo
				},
				MedicalConditions: {
					threatConditionReason,
					needMedicationReason,
					otherMedicationReason,

					MedicalConditionId,
					studentId,
					threatCondition,
					needMedication,
					otherMedication
				},
				StudentSiblingDetails: [...siblingsDetails],
				PreviousSchoolDetails: [...previousSchoolDetails],
				OtherInformations: {
					OtherInformationId,
					studentId,
					sports,
					otherActivity
				},
				WidthdrawDetails: {
					WidthdrawDetailId,
					studentId,
					dateLeaving,
					classLeft,
					reason
				},
				StudentTransports: {
					StudentTransportId,
					studentId,
					otherDriverName,
					otherCarNo,
					otherDriverCardIdNo,
					otherDriverMobileNo,
					transportStatus,
					routeId,
					driverId,
					vehicleNo,
					driverName,
					driverMobileNo,
					driverIdCardNo,
					driverIdCardCopy,
					parentOrByWalk,
					transportType
				}
			};
			debugger;
			// this.handleScroll(e)
			this.setState({
				isLoading: true
			});
			this.props.sharedAction
				.editRecordWithoutDispatch(
					`/api/StudentAdmissionForm/`,
					studentAdmissionFormId,
					Data
				)
				.then(success => {
					console.log(success);
					// this.props.history.push('/dashboard/ViewAddForm');
					this.props.history.push('/dashboard/ViewAddForm');
				})
				.catch(error => {
					console.log(error);
				});
			this.setState({
				error: false
			});
		}
	};

	fileUpload(studentId, file) {
		let imageData = new FormData();
		imageData.append('file', file, file.name);
		imageData.append('accountId', this.props.name);
		imageData.append('studentId', studentId);
		imageData.append('accountType', this.props.role);

		let token = '';
		if (localStorage.jwtToken) {
			token += localStorage.getItem('jwtToken') + token;
		}
		var Headconfig = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: 'Bearer ' + token
			}
		};
		// var bodyParameters = { data }
		if (file !== null) {
			return Promise.all([
				Axios.post(
					'http://www.https://embassyapp.linkit.org.uk/api/StudentImageUpload',
					imageData,
					Headconfig
				)
			])
				.then(success => {
					console.log(success);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
	onNextClick = (e, formType) => {
		if (
			Object.is(formType, 'StudentAdmissionForms') &&
			!this.isValidstudentAddForm('StudentAdmissionForms')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'StudentAddresss') &&
			!this.isValidstudentAddForm('StudentAddresss')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'ParentInfos') &&
			!this.isValidstudentAddForm('ParentInfos')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'StudentDocs') &&
			!this.isValidstudentAddForm('StudentDocs')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'StudentSiblingDetails') &&
			!this.isValidstudentAddForm('StudentSiblingDetails')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'MedicalConditions') &&
			!this.isValidstudentAddForm('MedicalConditions')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'PreviousSchoolDetails') &&
			!this.isValidstudentAddForm('PreviousSchoolDetails')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'WidthdrawDetails') &&
			!this.isValidstudentAddForm('WidthdrawDetails')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'OtherInformations') &&
			!this.isValidstudentAddForm('OtherInformations')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'StudentTransports') &&
			!this.isValidstudentAddForm('StudentTransports')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else if (
			Object.is(formType, 'ParentDocs') &&
			!this.isValidstudentAddForm('ParentDocs')
		) {
			this.handleScroll(e);
			this.setState({
				error: true
			});
		} else {
			this.stepper.next();
			this.handleScroll(e);
			this.setState({
				error: false
			});
		}
	};
	onChangeFormFlag = () => {
		this.setState({
			valid: false
		});
	};
	onPreviousClick = e => {
		this.stepper.previous();
		this.handleScroll(e);
	};
	renderSection = (sectionType, data) => {
		const { errors } = this.state;
		console.log(this.state);
		switch (sectionType) {
			case 'StudentAdmissionForms':
				return (
					<LayoutWrapper step="1" formType="Student Admission Form">
						<div>
							<StudentAdmission
								sectionType="EditSection"
								onChangeDate={this.onChangeDate}
								onChangeClassName={this.onChangeClassName}
								onGeneralSelectChangeHandler={this.onGeneralSelectChangeHandler}
								onHandleTextChange={this.onChangeText}
								stateData={this.state}
								editData={data.StudentAdmissionForms}
								campusData={this.props.campusData}
								sessionData={this.props.sessionData}
								onCustomAdmissionChange={this.onCustomAdmissionChange}
								imageChange={this.imageChange}
								sType={this.state.sType}
								enableUploadButton={this.enableUploadButton}
								errors={errors}
							/>
						</div>
					</LayoutWrapper>
				);
			case 'StudentAddresss':
				return (
					<LayoutWrapper formType="Student Address" step="2">
						<div>
							<StudentAdress
								onChangeFormFlag={this.onChangeFormFlag}
								onHandleTextChange={this.onChangeText}
								stateData={this.state}
								editData={data.StudentAddresss}
								errors={errors}
							/>
						</div>
					</LayoutWrapper>
				);

			case 'ParentInfos':
				return (
					<LayoutWrapper formType="Parent Information" step="3">
						<ParentInfo
							onHandleTextChange={this.onChangeText}
							stateData={this.state}
							editData={data.ParentInfos}
						/>
					</LayoutWrapper>
				);

			case 'StudentDocs':
				return (
					<StudentDocument
						onHandleTextChange={this.onChangeText}
						stateData={this.state}
						editData={data.StudentDocs}
					/>
				);

			case 'StudentSiblingDetails':
				return (
					<LayoutWrapper formType="Student Siblings Form" step="4">
						<StudentSiblingDetail
							onHandleTextChange={this.onChangeText}
							stateData={this.state}
							updateSpecialArrObjOnChange={this.updateSpecialArrObjOnChange}
							onClickAddItem={this.onClickAddItem}
							onClickDeleteItem={this.onClickDeleteItem}
							editData={data.StudentSiblingDetails}
						/>
					</LayoutWrapper>
				);

			case 'MedicalConditions':
				return (
					<LayoutWrapper formType="Medical Condition" step="5">
						<MedicalCondition
							onHandleTextChange={this.onChangeText}
							stateData={this.state}
							editData={data.MedicalConditions}
						/>
					</LayoutWrapper>
				);

			case 'PreviousSchoolDetails':
				return (
					<LayoutWrapper formType="Previous School Details" step="6">
						<PreviousSchoolDetails
							onHandleTextChange={this.onChangeText}
							stateData={this.state}
							updateSpecialArrObjOnChange={this.updateSpecialArrObjOnChange}
							onClickAddItem={this.onClickAddItem}
							onClickDeleteItem={this.onClickDeleteItem}
							editData={data.PreviousSchoolDetails}
						/>
					</LayoutWrapper>
				);

			case 'WidthdrawDetails':
				return (
					<LayoutWrapper formType="Widthdraw Detail" step="7">
						<WidthdrawDetail
							onChangeDate={this.onChangeDate}
							classData={this.state && this.state.dummySample}
							onHandleTextChange={this.onChangeText}
							stateData={this.state}
							editData={data.WidthdrawDetails}
						/>
					</LayoutWrapper>
				);

			case 'OtherInformations':
				return (
					<LayoutWrapper formType="Other Information" step="8">
						<OtherInformation
							onHandleTextChange={this.onChangeText}
							stateData={this.state}
							data={sports}
							editData={data.OtherInformations}
						/>
					</LayoutWrapper>
				);

			case 'StudentTransports':
				return (
					<LayoutWrapper formType="Student Transport" step="9">
						<StudentTransport
							onHandleTextChange={this.onChangeText}
							stateData={this.state}
							editData={data.StudentTransports}
							routesData={this.props.admissionState.getData}
							onCustomChange={this.onChangeRoutesName}
							onChangeTransportStatus={this.onChangeTransportStatus}
							onChangeNoOptions={this.onChangeNoOptions}
						/>
					</LayoutWrapper>
				);

			case 'ParentDocs':
				return (
					<ParentsDocuments
						onHandleTextChange={this.onChangeText}
						stateData={this.state}
						editData={data.ParentDocs}
					/>
				);

			default:
				break;
		}
	};
	render() {
		const { regNo } = this.state;

		console.log(this.state['siblingsDetails']);

		const { match, location } = this.props;
		return (
			<div className={'table-responsive'}>
				{/* <Prompt when={this.state.valid !== true}
          message='Leaving this form will lose your data'
        /> */}
				<div>
					<form onSubmit={this.onSubmit}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<div style={{ width: '100%' }} ref={this.main}>
								{this.state.successFlag ? (
									<p style={{ color: 'green' }}>Record Update Successfully!</p>
								) : (
									''
								)}
								{this.state.error ? (
									<p style={{ color: 'red' }}>Please fill the Form Properly!</p>
								) : (
									''
								)}
								{this.renderSection(match.params.sectionType, location.state)}
							</div>
						</div>
						<div style={{ marginBottom: 5 }} class="text-center">
							<button type={'submit'} style={{ width: '30%' }} class="btnAdd">
								<i
									style={{ marginRight: 4 }}
									class="fas fa-arrow-circle-right"
								></i>
								Edit
								<span
									style={{ marginBottom: 5 }}
									class={`${
										this.state.isLoading
											? 'spinner-border spinner-border-sm'
											: ''
									}`}
								></span>
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		sharedAction: bindActionCreators(sharedActions, dispatch),
		actionCampus: bindActionCreators(campusAction, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		getClassSectionGrade: state.setClassSectionGradeReducer,
		admissionState: state.setAdmissionReducer,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		campusData: state.setCampusReducer.Data,
		sessionData: state.setAdmissionReducer.getCurrenSession
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditAdmissionForm);
