import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import Spinner from '../../components/Spinner/Spinner';
import Pagination from '../../components/Pagination/Pagination'
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import DatePicker from 'react-date-picker';
import TextArea from '../../components/TextArea'

let tHead = [
    'Visitor Image',
    'Visitor Name',
    'CNIC',
    'Phone Number',
    'Purpose',
    'Date',
    'Check In',
    'Check Out',
    'Actions'
];

let renderFields = [
    'image',
    'name',
    'cnic',
    'phoneNo',
    'purpose',
    'date',
    'checkIn',
    'checkOut'
]

const VisitorEntryRecord = (props) => {

    const [description, setDescription] = useState('')
    const [cId, setCId] = useState(null);
    const [dId, setDId] = useState(null);
    const [date, setDate] = useState('');

    const [visitorData, setVisitorData] = useState([]);

    const [msg, setSnackMsg] = useState('');
    const [searchURL, setSearchURL] = useState(
        '/api/VisitorEntryRecord?PageNumber=1&pageSize=10'
    );


    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});
    const mounted = useRef();

    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setVisitorData(data['visitors']);

        setPaginationData(data['paginationMetadata']);
    });

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            $('[data-toggle="tooltip"]').tooltip();
        }
    });
    useEffect(() => {
        return () => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        };
    }, []);

    const handleClickNextFrwd = () => {
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);

            setdisableBPage(false);
        }
    };

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesVisitor(
                    date,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setVisitorData(success['visitors']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
                setVisitorData([])
            });
    }, [currentPage, date, customPageSize]);
    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const onDateChange = date => {
        setIsLoading(true)
        setDate(date)

        let tempDate = new Date(date)
        let apiDate = ((tempDate.getMonth() > 8) ? (tempDate.getMonth() + 1) : ('0' + (tempDate.getMonth() + 1))) + '/' + ((tempDate.getDate() > 9) ? tempDate.getDate() : ('0' + tempDate.getDate())) + '/' + tempDate.getFullYear();


        props.sharedActions
            .getDataWithoutDispatch(
                `/api/SearchByDate?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&date=${apiDate}`
            )
            .then(success => {
                setVisitorData(success['visitors'])
                let pageNumber = [];
                for (
                    let i = 1;
                    i <= success['paginationMetadata']['totalPages'];
                    i++
                ) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
                setIsLoading(false);
            })
            .catch(error => {
                setVisitorData([])
                setIsLoading(false);
            });

    }

    const onHandleDescriptionChange = e => {
        setDescription(e.target.value)
    }

    const setCheckOutID = id => {
        setCId(id);

    };

    const onClickCheckOut = id => {
        setIsLoading(true);

        var dateOman = new Date();
        var hours = dateOman.getHours();
        var minutes = dateOman.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;

        const data = {
            description
        }

        props.sharedActions
            .editPatchRecordWithoutDispatch(
                '/api/VisitorEntryRecord/',
                id,
                data
            )
            .then(success => {
                const updatedItem = general.dataFilteration(
                    visitorData,
                    ['name', 'cnic', 'phoneNo', 'purpose', 'checkIn', 'checkOut', 'date', 'id', 'fileName'],
                    {
                        id: id
                    })
                updatedItem[0].checkOut = strTime;
                visitorData.forEach((item, index) => {
                    if (item.id === id) {
                        visitorData.splice(index, 1, updatedItem[0]);

                    }
                })
                setSnackMsg('CheckOut Successfull!');
                setIsLoading(false);
                props.snackbar();
                setCId(null);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    const setDeleteID = id => {
        console.log('delete');

        setDId(id);
    };

    const onClickDelete = id => {
        setIsLoading(true)

        props.sharedActions
            .deleteRecordWithoutDispatch(
                '/api/VisitorEntryRecord/',
                dId
            )
            .then(success => {
                // if (success.status === 200) {
                let getNewFilterArray = visitorData.filter(item => item.id !== dId);
                setSnackMsg('Remove Successfully!');
                setVisitorData(getNewFilterArray);
                setIsLoading(false)
                props.snackbar();
                setDId(null);
                // }
            })
            .catch(error => {
                setIsLoading(false)
                console.log(error);
            });
    };


    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        borderColor: Colors.SEA_GREEN_THEME
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => {
                    if (item1 === 'image') {
                        return (
                            <td class='text-center'
                                style={{
                                    fontWeight: `${index === 0 ? 'bold' : 'normal'}`,
                                    border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                    padding: 5,
                                    borderColor: Colors.SEA_GREEN_THEME
                                }}
                            >
                                <img
                                    src={`https://embassyapp.linkit.org.uk/Uploads/VisitorImages/${item['fileName']}`}
                                    class="card-img"
                                    alt="Visitor img"
                                    style={{ width: 100, height: 80 }}
                                />
                            </td>
                        )
                    } else {
                        return (
                            <td
                                style={{
                                    fontWeight: `${index === 0 ? 'bold' : 'normal'}`,
                                    border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                    padding: 2,
                                    borderColor: Colors.SEA_GREEN_THEME
                                }}
                            >
                                {item[item1]}
                            </td>
                        )
                    }
                })}
                <td
                    style={{
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        padding: 2,
                        borderColor: Colors.SEA_GREEN_THEME
                    }}
                >
                    <a href="#" data-toggle="tooltip" title="Checkout!">
                        <button
                            onClick={() => setCheckOutID(item.id)}
                            data-toggle="modal"
                            data-target="#myModal"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                background: 'none',
                                marginLeft: 5,
                                marginBottom: 5
                            }}
                            disabled={item.checkOut === '-' ? false : true}
                        >
                            <i style={item.checkOut === '-' ? { color: '#01ac8a' } : { color: '#cccccc' }} class="fas fa-walking"></i>
                        </button>
                    </a>

                    <a href="#" data-toggle="tooltip" title="Delete!">
                        <button
                            onClick={() => setDeleteID(item.id)}
                            data-toggle="modal"
                            data-target="#myModal1"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                background: 'none',
                                marginLeft: 5,
                                marginBottom: 5
                            }}
                        >
                            <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                        </button>
                    </a>
                </td>
            </tr>
        ));

    return (
        <div>
            <SnackBar msg={msg} />
            <div class="modal" id="myModal1">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Confirm
                    </h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
                    </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Are you sure ?</h4>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}
                                >
                                    <div>
                                        <button
                                            // disabled={this.state.enabled}
                                            data-dismiss="modal"
                                            onClick={() => onClickDelete(dId)}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                        >
                                            Yes
                                </button>
                                    </div>
                                    <div>
                                        <button
                                            data-dismiss="modal"
                                            // disabled={this.state.enabled}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                        >
                                            No
                                </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Confirm
                    </h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
                    </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Are you sure ?</h4>
                                </div>
                                <div class='col-md-12'>
                                    <TextArea
                                        customWidth={true}
                                        enterWidth={'220%'}
                                        feildName={description}
                                        onHandleChange={onHandleDescriptionChange}
                                        errors={{}}
                                        stateData={{
                                            description: description
                                        }}
                                        // iconClassName={'fas fa-percentage'}
                                        placeholder={'Add Description'}
                                        isValidte={false}
                                    />
                                </div>
                                <br />
                                <br />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}
                                >
                                    <div>
                                        <button
                                            // disabled={this.state.enabled}
                                            data-dismiss="modal"
                                            onClick={() => onClickCheckOut(cId)}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                        >
                                            Yes
                                </button>
                                    </div>
                                    <div>
                                        <button
                                            data-dismiss="modal"
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                        >
                                            No
                                </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead">
                            {' '}
                            Visitor Entry Record{' '}
                        </h2>
                    </div>
                    <div
                        style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                    >
                        <Pagination
                            disableBPage={disableBPage}
                            disablepage={disablepage}
                            currentPage={currentPage}
                            pageNumber={pageNumber}
                            handleClickNext={handleClickNext}
                            handleClickNextBack={handleClickNextBack}
                            handleClickNextFrwd={handleClickNextFrwd}
                        />
                        <div
                            style={{
                                width: 'fit-content',
                                marginTop: -34,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginLeft: 'auto'
                            }}
                        >
                            <SelectionInput
                                selectName="Entries"
                                selectFont={14}
                                selectFontWeight={'bold'}
                                newFlag="customWidth"
                                feildName="customPageSize"
                                selectedText={'Page Entries'}
                                optionsArrys={['10', '20', '30', '40', '50']}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    customPageSize: customPageSize
                                }}
                                optionType="static"
                                onHandleChange={onChangePageSize}
                                useFlag={false}
                            />
                        </div>
                    </div>
                </div>
                <CustomFilterSectionLayout displayDirection="column">
                    <div
                        style={{
                            width: '30%',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <div class="col-md-6" style={{ marginTop: 25 }}>
                            <div class="md-form mb-0">
                                <label for="email" class="">
                                    Date On
											</label>
                                <br />
                                <DatePicker
                                    //                                    maxDate={true}
                                    onChange={e => onDateChange(e)}
                                    value={date}
                                    selectDateOnly
                                />
                            </div>
                        </div>

                        <React.Fragment>
                        </React.Fragment>
                    </div>
                </CustomFilterSectionLayout>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) :
                visitorData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Data Found</strong>
                </div>
                    : (
                        <div className="table-responsive">
                            <table class="table table-hover">
                                <thead style={{ background: '#01AC8A', color: 'white' }}>
                                    <tr>
                                        <th scope="col">#</th>
                                        {tHead.map(item => (
                                            <th
                                                style={{
                                                    border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                    borderColor: Colors.WHITE
                                                }}
                                                scope="col"
                                            >
                                                {item}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody> {bodyRendering(visitorData)}</tbody>
                            </table>
                        </div>
                    )
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisitorEntryRecord);