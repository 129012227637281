import * as actionTypes from '../actionTypes';

export function setAllDriverInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_DRIVER,
		payload: allData
	};
}
export function getRouteForVehicle(allData) {
	return {
		type: actionTypes.SET_ALL_ROUTE_NAME_INTO_VEHICLE_SELECT,
		payload: allData
	};
}

export function getVehicleForDriver(allData) {
	return {
		type: actionTypes.SET_ALL_VEHICLE_NAME_INTO_DRIVER_SELECT,
		payload: allData
	};
}
export function addDriver(Data) {
	return {
		type: actionTypes.ADD_DRIVER,
		payload: Data
	};
}
export function updateDriverFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_DRIVER,
		payload: setFlag
	};
}
export function updateDriver(data) {
	return {
		type: actionTypes.EDIT_DRIVER_RECORD,
		payload: data
	};
}
export function deleteDriver(id) {
	return {
		type: actionTypes.DELETE_DRIVER,
		payload: id
	};
}
