import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StaffEnquiryActions from '../../Actions/StaffEnquiry';
import * as sharedActions from '../../Actions/sharedActions';
// import ClassComponent from "./ClassComponent";

class StaffEnquiry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			AdminName: '',
			AdminType: '',
			applicantName: '',
			fatherName: '',
			motherName: '',
			address: '',
			city: '',
			state: '',
			country: '',
			emailId: '',
			postalCode: '',
			dob: '',
			phoneNo: '',
			mobileNo: '',
			status: '',
			departmentId: '',
			postAppliedFor: '',
			classId: '',
			previousAcademics: '',
			referenceType: '',
			referenceName: '',
			details: '',
			select: '',
			selectTwo: '',
			post: [],
			errorMsg: false,
			msg: false
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.select !== nextProps.selectData ||
			prevState.selectTwo !== nextProps.selectDepartment
		) {
			return {
				select: nextProps.selectData,
				selectTwo: nextProps.selectDepartment
			};
		}
		return null;
	}
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/ClassSection',
				this.props.StaffEnquiryActions.getClassesForStaffEnquiry
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		this.props.sharedActions
			.gAllData(
				'/api/StaffPostDepart',
				this.props.StaffEnquiryActions.getDepartmentForStaffEnquiry
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onSelectChange = e => {
		console.log(e.target.value);
		let result = e.target.value;
		console.log(result);
		this.setState({
			classId: result
		});
	};
	onSelectDepartmentChange = e => {
		console.log(e.target.value);

		const posts = this.state.selectTwo.post;
		let checking = posts.filter(
			item => item.departmentId === parseInt(e.target.value)
		);
		console.log(checking);

		this.setState({
			post: checking,
			// gradeName: check[0] && check[0].section,
			departmentId: e.target.value
		});
	};
	onSelectPostChange = e => {
		console.log(e.target.value);

		this.setState({
			postId: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const {
			applicantName,
			fatherName,
			motherName,
			mobileNo,
			address,
			city,
			state,
			country,
			emailId,
			postalCode,
			dob,
			phoneNo,
			classId,
			departmentId,
			postId,
			previousAcademics,
			referenceType,
			referenceName,
			details
		} = this.state;
		if (
			Object.is(applicantName, '') ||
			Object.is(fatherName, '') ||
			Object.is(motherName, '') ||
			Object.is(address, '') ||
			Object.is(city, '') ||
			Object.is(state, '') ||
			Object.is(phoneNo, '') ||
			Object.is(mobileNo, '') ||
			Object.is(details, '') ||
			Object.is(country, '') ||
			Object.is(postalCode, '') ||
			Object.is(emailId, '') ||
			Object.is(referenceName, '') ||
			Object.is(referenceType, '') ||
			Object.is(previousAcademics, '') ||
			Object.is(classId, '') ||
			Object.is(dob, '') ||
			Object.is(departmentId, '') ||
			Object.is(postId, '')
		) {
			this.setState({
				errorMsg: true
			});
		} else {
			const data = {
				applicantName,
				fatherName,
				motherName,
				mobileNo,
				address,
				city,
				state,
				country,
				emailId,
				postalCode,
				dob,
				phoneNo,
				classId,
				previousAcademics,
				referenceType,
				referenceName,
				details,
				departmentId,
				postId,
				accountType: this.props.userData.role,
				accountId: this.props.userData.unique_name
			};
			this.props.sharedActions
				.addRecord(
					'/api/StaffEnquiryForm',
					data,
					this.props.StaffEnquiryActions.addStaffEnquiry
				)
				.then(success => {
					this.setState({
						applicantName: '',
						fatherName: '',
						motherName: '',
						mobileNo: '',
						address: '',
						city: '',
						state: '',
						country: '',
						emailId: '',
						postalCode: '',
						dob: '',
						phoneNo: '',
						status: '',
						className: '',
						previousAcademics: '',
						referenceType: '',
						referenceName: '',
						details: '',
						errorMsg: false,
						msg: true
					});
				})
				.catch(error => {});
		}
		console.log(this.state);
	};
	render() {
		const { classList } = this.state.select;
		const { department } = this.state.selectTwo;
		console.log('Department', department);
		console.log('Classes', classList);
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Staff Enquiry
					</h2>

					<p class="text-center w-responsive mx-auto mb-2" align="center" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.state.errorMsg ? (
								<p style={{ color: 'red' }}>Please fill the form properly!</p>
							) : (
								''
							)}
							{this.state.msg ? (
								<p style={{ color: 'green' }}>
									Staff Enquiry Added Successfully!
								</p>
							) : (
								''
							)}
							<form
								id="contact-form"
								name="contact-form"
								onSubmit={this.onHandleSubmit}
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-user-shield animated fadeIn"
                                            /> */}
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												onChange={this.onHandleTextChange}
												value={this.props.userData.unique_name}
												readOnly={true}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-user-alt animated fadeIn"
                                            /> */}
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												class="form-control"
												onChange={this.onHandleTextChange}
												value={this.props.userData.role}
												readOnly={true}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-child animated fadeIn"
                                            /> */}
												Applicant Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Applicant Name"
												type="text"
												id="name"
												name="applicantName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-male animated fadeIn"
                                            /> */}
												Father Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Father Name"
												type="text"
												id="email"
												name="fatherName"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-female animated fadeIn"
                                            /> */}
												Mother Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Mother Name"
												type="text"
												id="email"
												name="motherName"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-address-card animated fadeIn"
                                            /> */}
												Address
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Address"
												type="text"
												id="email"
												name="address"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-building animated fadeIn"
                                            /> */}
												City
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="City"
												type="text"
												id="email"
												name="city"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-globe-asia animated fadeIn"
                                            /> */}
												Country
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Country"
												type="text"
												id="email"
												name="country"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-flag animated fadeIn"
                                            /> */}
												State
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="State"
												type="text"
												id="email"
												name="state"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-envelop-open-text animated fadeIn"
                                            /> */}
												Email Id
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Email Id"
												type="text"
												id="email"
												name="emailId"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-mail-bulk animated fadeIn"
                                            /> */}
												Postal Code
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Postal Code"
												type="text"
												id="email"
												name="postalCode"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-birthday-cake animated fadeIn"
                                            /> */}
												Date Of Birth
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Date Of Birth"
												type="text"
												id="email"
												name="dob"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-phone-volume animated fadeIn"
                                            /> */}
												Phone Number
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Phone Number"
												type="text"
												id="email"
												name="phoneNo"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-phone animated fadeIn"
                                            /> */}
												Mobile Number
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Mobile Number"
												type="text"
												id="email"
												name="mobileNo"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-door-open animated fadeIn"
                                            /> */}
												Class Name
											</label>
											<select
												onChange={this.onSelectChange}
												class="custom-select"
												name="className"
												style={{ borderColor: '#01AC8A' }}
											>
												<option selected={this.state.msg ? true : false}>
													Choose Class...
												</option>
												{classList &&
													classList.map((item, index) => (
														<option key={index} value={`${item.className}`}>
															{item.className}
														</option>
													))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-door-open animated fadeIn"
                                            /> */}
												Department
											</label>
											<select
												onChange={this.onSelectDepartmentChange}
												class="custom-select"
												name="className"
												style={{ borderColor: '#01AC8A' }}
											>
												<option selected={this.state.msg ? true : false}>
													Choose Department...
												</option>
												{department &&
													department.map((item, index) => (
														<option key={index} value={`${item.departmentId}`}>
															{item.departmentName}
														</option>
													))}
											</select>
										</div>
									</div>

									{/* <ClassComponent classes={classList} onHandleChange={this.onSelectChange} /> */}

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												<i
													style={{ marginRight: 4 }}
													className="fas fa-university animated fadeIn"
												/>
												Post Applied For
											</label>
											<select
												onChange={this.onSelectPostChange}
												class="custom-select"
												name="className"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Post...
												</option>
												{this.state.post.map((item, index) => (
													<option key={index} value={`${item.postId}`}>
														{item.postName}
													</option>
												))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-university animated fadeIn"
                                            /> */}
												Post Applied For
											</label>
											<select
												onChange={this.onSelectPostChange}
												class="custom-select"
												name="className"
												style={{ borderColor: '#01AC8A' }}
											>
												<option selected={this.state.msg ? true : false}>
													Choose Post...
												</option>
												{this.state.post.map((item, index) => (
													<option key={index} value={`${item.postId}`}>
														{item.postName}
													</option>
												))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-university animated fadeIn"
                                            /> */}
												Previous Academics
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Previous Academics"
												type="text"
												id="email"
												name="previousAcademics"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-link animated fadeIn"
                                            /> */}
												Reference Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Reference Type"
												type="text"
												id="email"
												name="referenceType"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-link animated fadeIn"
                                            /> */}
												Reference Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Reference Name"
												type="text"
												id="email"
												name="referenceName"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-info animated fadeIn"
                                            /> */}
												Details
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Details"
												type="text"
												id="email"
												name="details"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
								</div>
								<div class="text-center text-md-center">
									<button type="submit" class="btns">
										Add
									</button>
								</div>
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		StaffEnquiryActions: bindActionCreators(StaffEnquiryActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectData: state.setStaffEnquiryReducer.select,
		selectDepartment: state.setStaffEnquiryReducer.selectDepart
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StaffEnquiry);
