import * as actionTypes from '../actionTypes';

export function setAllHrDevelopementInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_HR_DEVELOPMENT,
		payload: allData
	};
}
export function setAllHrDevelopementForEditInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_HR_DEVELOPMENT_For_Edit,
		payload: allData
	};
}
// export function getRouteForVehicle(allData) {
//
//     return {s
//         type: actionTypes.SET_ALL_ROUTE_NAME_INTO_VEHICLE_SELECT,
//         payload: allData
//     };
// }

// export function getVehicleForDriver(allData) {
//
//     return {
//         type: actionTypes.SET_ALL_VEHICLE_NAME_INTO_DRIVER_SELECT,
//         payload: allData
//     };
// }
export function addDevelopment(Data) {
	return {
		type: actionTypes.ADD_HR_DEVELOPMENT,
		payload: Data
	};
}
export function updateHrDevelopementFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_HR_DEVELOPMENT_ADD,
		payload: setFlag
	};
}
export function updateHrDevelopementFlagEdit(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_HR_DEVELOPMENT_EDIT,
		payload: setFlag
	};
}
export function updateHrDevelopment(data) {
	return {
		type: actionTypes.EDIT_HR_DEVELOPMENT_RECORD,
		payload: data
	};
}
export function deleteHrDevelopment(id) {
	return {
		type: actionTypes.DELETE_HR_DEVELOPMENT,
		payload: id
	};
}
