import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import * as SharedAction from '../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DateandTime from '../Transport/DateandTime';
import SnackBar from '../../components/SnackBar/SnackBar';

class AddFinanceManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			post: '',
			staffId: '',
			campusAllData: [],
			department: [],
			postData: [],
			staff: [],
			click: false,
			valid: false,
			msg: '',
			errorMsg: false,
			name: '',
			backColor: ''
		};
	}

	componentDidMount() {
		this.props.SharedAction.getDataWithoutDispatch('/api/campusDepartment')
			.then(success => {
				debugger;
				this.setState({
					campusAllData: success
				});
			})
			.catch(err => {
				this.setState({
					msg: 'No Record Found',
					isLoading: false
				});
				this.props.snackbar();
				console.log(err);
			});
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const { username, password, staffId } = this.state;
		if (
			Object.is(username, '') ||
			Object.is(password, '') ||
			Object.is(staffId, '')
		) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			let token = '';
			if (localStorage.jwtToken) {
				token += localStorage.getItem('jwtToken') + token;
			}
			var Headconfig = {
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: 'Bearer ' + token
				}
			};
			var bodyParameters = {
				staffId,
				userName: this.state.username,
				name: this.state.name,
				password: this.state.password,
				post: this.state.post,
				accountType: this.props.role,
				accountId: this.props.name
			};
			this.props.SharedAction.simpleAddRequest(
				`/api/FinanceManager`,
				bodyParameters
			)
				.then(response => {
					console.log(response);
					this.setState({
						username: '',
						password: '',
						name: '',
						post: '',
						staffId: '',
						msg: 'Manager Added Successfully',
						backColor: '#01ac8a',
						click: true
					});
					this.props.snackbar();
				})
				.catch(error => {
					this.setState({
						backColor: 'red',
						msg: error.response.data.Message
					});
					this.props.snackbar();
				});
		}
	};
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};

	onCampusChange = e => {
		const { campusAllData } = this.state;
		let Department = campusAllData.department.filter(
			item => item.campusId == e.target.value
		);
		this.setState({
			department: Department
		});
	};

	onDepartmentChange = e => {
		const { campusAllData } = this.state;
		let Post = campusAllData.post.filter(
			item => item.departmentId == e.target.value
		);
		this.setState({
			postData: Post
		});
	};

	onPostChange = e => {
		const { campusAllData } = this.state;
		let result = JSON.parse(e.target.value);
		let Staff = campusAllData.staff.filter(
			item => item.postId == result.postId
		);
		this.setState({
			staff: Staff,
			post: result.postName
		});
	};

	onStaffChange = e => {
		let result = JSON.parse(e.target.value);
		this.setState({
			staffId: result.staffId,
			name: result.name
		});
	};
	render() {
		const { department, postData, staff } = this.state;
		const { campus } = this.state.campusAllData;
		return (
			<div className="container">
				<SnackBar msg={this.state.msg} backColor={this.state.backColor} />
				<Prompt
					when={this.state.valid !== true}
					message="Leaving this form will lose your data"
				/>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Finance Manager
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.Message(
								'Please fill the form properly!',
								'danger',
								20,
								this.state.errorMsg
							)}

							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="font-weight-bold">
												Admin Name
											</label>
											<input
												type="text"
												readOnly={true}
												value={this.props.name}
												name="AdminName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="font-weight-bold">
												Admin Type
											</label>
											<input
												type="text"
												readOnly={true}
												value={this.props.role}
												name="AdminType"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="font-weight-bold">
												Campus
											</label>
											<select
												onChange={this.onCampusChange}
												class="custom-select"
												name="campusId"
											>
												<option selected={this.state.msg ? true : false}>
													Select Campus...
												</option>
												{campus &&
													campus.map((item, index) => (
														<option key={index} value={`${item.campusId}`}>
															{item.campusName}
														</option>
													))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="font-weight-bold">
												Department
											</label>
											<select
												onChange={this.onDepartmentChange}
												class="custom-select"
												name="campusId"
											>
												<option selected={this.state.msg ? true : false}>
													Select Department...
												</option>
												{department.map((item, index) => (
													<option key={index} value={`${item.departmentId}`}>
														{item.departmentName}
													</option>
												))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="font-weight-bold">
												Post
											</label>
											<select
												onChange={this.onPostChange}
												class="custom-select"
												name="campusId"
											>
												<option selected={this.state.msg ? true : false}>
													Select Post...
												</option>
												{postData.map((item, index) => (
													<option key={index} value={`${JSON.stringify(item)}`}>
														{item.postName}
													</option>
												))}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="font-weight-bold">
												Staff
											</label>
											<select
												onChange={this.onStaffChange}
												class="custom-select"
												name="name"
											>
												<option selected={this.state.msg ? true : false}>
													Select Staff...
												</option>
												{staff.map((item, index) => (
													<option key={index} value={`${JSON.stringify(item)}`}>
														{item.name}
													</option>
												))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="font-weight-bold">
												Name
											</label>
											<input
												readOnly={true}
												type="text"
												name="name"
												value={this.state.name}
												class="form-control"
												placeholder={'Enter Name'}
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="font-weight-bold">
												Username
											</label>
											<input
												type="text"
												name="username"
												value={this.state.username}
												class="form-control"
												placeholder={'Enter Username'}
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="font-weight-bold">
												Password
											</label>
											<input
												type="password"
												name="password"
												value={this.state.password}
												class="form-control"
												placeholder={'Enter Password'}
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
								</div>
							</form>
							<br />
							<br />
							<div class="text-right text-md-right">
								<button onClick={this.onHandleSubmit} class="buttonHover2">
									Add
								</button>
							</div>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		SharedAction: bindActionCreators(SharedAction, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddFinanceManager);
