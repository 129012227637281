import React, { useState } from 'react';
import { Types } from '../../hooks/HookReducers/StaffReducer';
import RenderDynamicSiblingsRow from '../../Container/Admission/RenderDynamicSiblingsRow';
import Button from '../Button';

const Qualification = props => {
	const {
		dispatch,
		errors,
		onPreviousClick,
		getValidatedArray,
		edit = false,
		editData = {},
		putLoader,
		onUpdate
	} = props;
	debugger;
	const { ON_DYANMIC_ADD, ON_DYANMIC_DELETE } = Types;
	const [Qualifications, setQualification] = useState(
		edit
			? editData
			: [
					{
						qualificationName: '',
						board: '',
						qualificationYear: '',
						institute: ''
					}
			  ]
	);
	const onNextStep = e => {
		if (!getValidatedArray(e, Qualifications, 'qualification')) {
			dispatch({
				type: Types.ON_QUALIFICATION_DISPATCH,
				payload: { Qualifications }
			});
		}
	};
	const updateSpecialArrObjOnChange = (e, index, arrName) => {
		const changeArrOfObj = [...Qualifications];
		changeArrOfObj[index][e.target.name] = e.target.value;

		// arrName = changeArrOfObj;
		// console.log(arrName);
		setQualification(changeArrOfObj);
	};
	const onClickAddItem = (arr, obj) => {
		let getStateArr = [...arr];
		getStateArr = [...getStateArr, obj];
		console.log(getStateArr);

		setQualification(getStateArr);
	};
	const onClickDeleteItem = arr => {
		let getStateArr = [...arr];
		let getDeleteLength = getStateArr.length - 1;

		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			setQualification(getNewDetails);
		}
	};
	const onEditHandleSubmit = e => {
		debugger;
		let originalFormOfArrObj = Qualifications.map(
			({ staffId, id, interviewId, ...keepProps }) => keepProps
		);

		if (!getValidatedArray(e, originalFormOfArrObj, 'qualification')) {
			onUpdate(dispatch, 'ON_SET_QUALIFICATON_EDIT', {
				name: 'StaffQualification',
				Qualifications
			});
		}
	};
	return (
		<div>
			{errors.qualification ? (
				<p style={{ color: '#ff0000' }}>Please fill the form properly!</p>
			) : (
				''
			)}

			<div style={{ width: '100%' }}>
				<table class="table table-hover">
					<thead style={{ background: '#01AC8A', color: 'white' }}>
						<tr>
							<th scope="col">#</th>
							<th scope="col">Qualification Name</th>
							<th scope="col">Board</th>
							<th scope="col">Qualification Year</th>
							<th scope="col">Institution</th>
						</tr>
					</thead>
					<tbody>
						{Qualifications.map((item, index) => (
							<tr key={index}>
								<th scope={'row'}>{index + 1}</th>
								<RenderDynamicSiblingsRow
									arrName={'Qualifications'}
									rowIndex={index}
									stateData={{ Qualifications: [...Qualifications] }}
									dispatch={dispatch}
									updateSpecialArrObjOnChange={updateSpecialArrObjOnChange}
									hookReuse={false}
									itemObj={item}
								/>
							</tr>
						))}
					</tbody>
					<div className={'row'} style={{ justifyContent: 'flex-end' }}>
						<div>
							<div>
								<button
									onClick={() =>
										onClickAddItem(Qualifications, {
											qualificationName: '',
											board: '',
											qualificationYear: '',
											institute: ''
										})
									}
									style={{
										background: '#0A4F5E',
										color: '#FFFFFF',
										borderRadius: 100
									}}
									type="button"
									class="btn"
								>
									<i class="fas fa-plus"></i>
								</button>
							</div>
							<div>
								<button
									onClick={() => onClickDeleteItem(Qualifications)}
									style={{ color: '#FFFFFF', borderRadius: 100 }}
									type="button"
									class="btn btn-danger"
								>
									<i class="far fa-trash-alt"></i>
								</button>
							</div>
						</div>
					</div>
				</table>
			</div>
			<br />
			<br />
			<br />
			{/*<div class="text-center text-md-center">*/}
			{/*<button class="btns">Add</button>*/}
			{/*</div>*/}
			{!edit ? (
				<div
					style={{
						marginTop: 10,
						display: 'flex',
						justifyContent: 'flex-start'
					}}
				>
					<div>
						<button
							class="btns"
							style={{ marginRight: 25 }}
							onClick={e => onNextStep(e)}
						>
							Next
						</button>
					</div>
					<div>
						<button class="btns" onClick={() => onPreviousClick()}>
							Previous
						</button>
					</div>
				</div>
			) : (
				''
			)}
			{edit ? (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						stateData={{ isLoading: putLoader }}
						btnName="Update"
						buttonClass="btn-smart"
						loaderBmargin={5}
						marginTop={20}
						onHandleSubmit={onEditHandleSubmit}
					/>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default Qualification;
