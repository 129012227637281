import React from 'react';
import ViewRendering from '../components/ViewRendering';
import { renderingStuff, images } from '../utils';

function MedicalDashboard(props) {
    return (
        <div>
            <ViewRendering
                centered={true}
                centerWidth={'70%'}
                mainHeading={images.imgMedicalCenter}
                data={renderingStuff.Medical_Dashboard_Links}
                mainHead={true}
            />
        </div>
    );
}

export default MedicalDashboard;
