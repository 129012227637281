import React, { Component } from 'react';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import Year from '../../components/Year';
import months from '../../utils/general';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import IncomeView from './IncomeView';
import LoaderWrapper from '../../components/HOC/LoaderWrapper';
import SnackBar from '../../components/SnackBar/SnackBar';

class IncomeSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			dropDowns: false,
			TotalIncome: '',
			balanceDue: '',
			monthlyAmount: '',
			monthlyDue: '',
			AllOverSearch: false,
			monthlySearch: false,
			msg: '',
			year: '',
			month: ''
		};
	}
	onHandleText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	AllIncomeSearch = () => {
		this.setState({
			isLoading: true
		});
		this.props.sharedActions
			.getDataWithoutDispatch('/api/AllSchoolIncome')
			.then(success => {
				this.setState({
					TotalIncome: success.schoolFees,
					balanceDue: success.balanceDue,
					monthlySearch: false,
					AllOverSearch: true,
					dropDowns: false,
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({
					msg: 'No Record Found',
					isLoading: false
				});
				this.props.snackbar();
				console.log(err);
			});
	};

	MonthlyIncomeSearch = e => {
		this.setState({
			isLoading: true
		});
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/IncomeMonthlyWise?month=${e.target.value}&year=${this.state.year}`
			)
			.then(success => {
				this.setState({
					monthlyAmount: success.monthlyAmount,
					monthlyDue: success.monthlyDues,
					monthlySearch: true,
					AllOverSearch: false,
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({
					msg: 'No Record Found',
					isLoading: false
				});
				this.props.snackbar();

				console.log(err);
			});
	};
	render() {
		const mystyle = {
			boxShadow: `0 12px 16px 0 rgba(0, 0, 0, 0.24),
            0 17px 50px 0 rgba(0, 0, 0, 0.19)`,
			backgroundColor: '#fff',
			color: 'black'
		};
		return (
			<div>
				<SnackBar msg={this.state.msg} backColor={'Red'} />
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center'
					}}
				>
					<div>
						<h4 class="generalHead">School Income Search</h4>
					</div>
					<nav aria-label="Page navigation example">
						<ul style={{ color: '#01AC8A' }} class="pagination">
							{/* {renderPageNumbers} */}
						</ul>
					</nav>
				</div>
				<br />
				<br />
				<CustomFilterSectionLayout displayDirection="column">
					<div class="row justify-content-center">
						<div class="col-md-3 text-right">
							<button
								class="buttonHover2"
								onClick={this.AllIncomeSearch}
								style={this.state.AllOverSearch ? mystyle : { marginRight: 2 }}
							>
								All Over Income
							</button>
						</div>
						<div class="col-md-3 text-left">
							<button
								class="buttonHover2"
								style={this.state.monthlySearch ? mystyle : { marginRight: 2 }}
								onClick={() => this.setState({ dropDowns: true })}
							>
								Monthly Income
							</button>
						</div>
					</div>
					<br />
					{this.state.dropDowns ? (
						<div class="row justify-content-center">
							<div class="col-md-4">
								<Year onMonthChange={this.onHandleText}></Year>
							</div>
							<div class="col-md-4">
								<select
									onChange={this.MonthlyIncomeSearch}
									class="custom-select"
									name="month"
								>
									<option selected={this.state.msg ? true : false}>
										Select Month...
									</option>
									{months.Months.map((item, index) => (
										<option key={index} value={`${item}`}>
											{item}
										</option>
									))}
								</select>
							</div>
						</div>
					) : (
						''
					)}
				</CustomFilterSectionLayout>
				<br />
				<LoaderWrapper isLoading={this.state.isLoading}>
					<IncomeView
						monthlySearch={this.state.monthlySearch}
						AllOverSearch={this.state.AllOverSearch}
						TotalIncome={this.state.TotalIncome}
						BalanceDue={this.state.balanceDue}
						MonthlyAmount={this.state.monthlyAmount}
						MonthlyDue={this.state.monthlyDue}
					/>
				</LoaderWrapper>
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	null,
	mapDispatchToProps
)(IncomeSearch);
