import imgFDash from '../../Assets/icons/humza icons-01.svg';
import imgBDash from '../../Assets/icons/humza icons-02.svg';
import imgAdDash from '../../Assets/icons/humza icons-03.svg';
import imgadmis from '../../Assets/AdministrationDashboard/humza icon new-06.svg';
import imgAcDash from '../../Assets/icons/humza icons-04.svg';
import imgAdmissionDash from '../../Assets/icons/humza icons-05.svg';
import imgComDash from '../../Assets/icons/humza icons-06.svg';
import imgHRDash from '../../Assets/icons/humza icons-07.svg';
import imgPasDash from '../../Assets/icons/humza icons-08.svg';
import imgSysDash from '../../Assets/icons/humza icons-09.svg';
import imgGMDash from '../../Assets/icons/humza icons-10.svg';
import imgTT from '../../Assets/images/timetable1.svg';
import imgLPW from '../../Assets/images/timetable2.svg';
import addTT from '../../Assets/images/Timetable-01.svg';
import viewTT from '../../Assets/images/timetable-16-01.svg';
import addLPW from '../../Assets/images/lesson-01.svg';
import viewLPW from '../../Assets/images/lesson-16-01.svg';
import imgTThead from '../../Assets/images/timetable smart-03.svg';
import imgAcHead from '../../Assets/images/Academic-01.svg';
import imgFHead from '../../Assets/images/Finance-02.svg';
import imgALDash from '../../Assets/FinanceDashboardimg/new icon for humza-11.svg';
import imgBudgetDash from '../../Assets/FinanceDashboardimg/new icon for humza-12.svg';
import imgAddIcon from '../../Assets/images/add.svg';
import imgViewIcon from '../../Assets/images/view.svg';
import imgHRSRM from '../../Assets/HRIcons/final-06.svg';
import imgHRDevTrain from '../../Assets/HRIcons/final-02.svg';
import imgHRFrontDesk from '../../Assets/HRIcons/final-03.svg';
import imgHRDept from '../../Assets/HRIcons/final-04.svg';
import imgHRpost from '../../Assets/HRIcons/final-05.svg';
import imgHRStaffDoc from '../../Assets/HRIcons/final-01.svg';
import imgHRSalary from '../../Assets/HRIcons/final-07.svg';
import imgHRVacany from '../../Assets/HRIcons/final-09.svg';
import imgHrInterview from '../../Assets/HRIcons/final-10.svg';
import imgHrHead from '../../Assets/images/HR-04.svg';

//New Icons Of Dashboard Owner
import imgFDash1 from '../../Assets/icons/DashboardIcon/FinalDashboardICons/finance smart final icon.132bb31d.svg';
import imgBDash1 from '../../Assets/icons/DashboardIcon/FinalDashboardICons/business smart final icon.svg';
import imgAdDash1 from '../../Assets/icons/DashboardIcon/FinalDashboardICons/administration final icon.svg';
import imgAcDash1 from '../../Assets/icons/DashboardIcon/FinalDashboardICons/academic smart final icon.svg';
import imgAdmissionDash1 from '../../Assets/icons/DashboardIcon/FinalDashboardICons/admission smart final icon.svg';
import imgComDash1 from '../../Assets/icons/DashboardIcon/FinalDashboardICons/communication final icon.svg';
import imgHRDash1 from '../../Assets/icons/DashboardIcon/FinalDashboardICons/hr smart final icon.svg';
import imgPasDash1 from '../../Assets/icons/DashboardIcon/FinalDashboardICons/pastoral final icon.svg';
import imgSysDash1 from '../../Assets/icons/DashboardIcon/FinalDashboardICons/system smart final icon.svg';
import imgGMDash1 from '../../Assets/icons/DashboardIcon/FinalDashboardICons/general manager smart final icon.svg';
import imgLabSmart from '../../Assets/icons/DashboardIcon/FinalDashboardICons/lab smart.svg';
//End New Icons of Dashboard Owner

// temp images of academics
import imgCaPmTool from '../../Assets/Academic/primary-information.svg';
import imgCAImage from '../../Assets/Academic/continuous-assessment.svg';
import imgCAViewImage from '../../Assets/Academic/view-continuous-assessment.svg';

import imgAcademicLMS from '../../Assets/Academic/learning managment sysytem.svg';
import imgAcademicCMS from '../../Assets/Academic/competency management system.svg';
import imgAcademicSID from '../../Assets/Academic/student info desk.svg';
import imgAcademicHomeWork from '../../Assets/Academic/homework.svg';
import imgAcademicAssignment from '../../Assets/Academic/assignment.svg';
import imgAcademicLesson from '../../Assets/Academic/lesson plans.svg';
import imgAcademicExam from '../../Assets/Academic/exam.svg';
import imgAcademicSyllabus from '../../Assets/Academic/syllabus.svg';
import imgAcademicTimeTable from '../../Assets/Academic/time-table.png';
import imgAcademicQuiz from '../../Assets/Academic/quiz.svg';
import imgAcademicTest from '../../Assets/Academic/test.svg';
import imgAcademicPastPaper from '../../Assets/Academic/past paper.svg';
import imgAcademicClass from '../../Assets/Academic/class.svg';
import imgAcademicSection from '../../Assets/Academic/section.svg';
import imgAcademicSession from '../../Assets/Academic/session.svg';
import imgAcademicSubject from '../../Assets/Academic/subject.svg';
import imgAcademicAssignClassTeacher from '../../Assets/Academic/past paper.svg';
import imgAcademicIndent from '../../Assets/Academic/indent form.svg';
import imgAcademicTeacherEvaluation from '../../Assets/Academic/teachers evaluation.svg';

import imgAcademicSkill from '../../Assets/SkillDashboard/student skills.svg';
import imgAcademicDescriptive from '../../Assets/DescriptiveDashboard/student descriptive report.svg';
// end of temp images
// images of discipline : student and subject
import imgAddSubDiscipline from '../../Assets/SubjectAndStudentDiscipline/add_subject_discipline.svg';
import imgSubDiscipline from '../../Assets/SubjectAndStudentDiscipline/subject_discipline.svg';
import imgViewSubDiscipline from '../../Assets/SubjectAndStudentDiscipline/view_subject_discipline.svg';

// image of discipline :end
// student Class Dsiciline images
import imgAddClassDiscipline from '../../Assets/ClassDisciplineDashboard/add class discipline.svg';
import imgViewClassDiscipline from '../../Assets/ClassDisciplineDashboard/view class discpline.svg';
import imgClassDiscipline from '../../Assets/ClassDisciplineDashboard/class discipline.svg';
import imgUpdateClassDiscipline from '../../Assets/ClassDisciplineDashboard/update class discipline.svg';
//end class disciplie images

// Communication Dashboard Images
import imgStudentManager from '../../Assets/CommunicationDashboard/student portal manager.svg';
import imgParentManager from '../../Assets/CommunicationDashboard/parent portal manager.svg';
import imgAddParentResgitration from '../../Assets/CommunicationDashboard/add parent registration.svg';
import imgUpdateParentResgitration from '../../Assets/CommunicationDashboard/update parent registration.svg';
import imgRegisterParentResgitration from '../../Assets/CommunicationDashboard/assign child 1.svg';
//end Communication Dashboard Images
// staff imports
import addstaff from '../../Assets/StaffModule/staffform.svg';
import viewstaff from '../../Assets/StaffModule/staffview.svg';
// end of staff imports

// Manager Dashboard Images
import imgManagerDashboard from '../../Assets/ManagerDashboard/manager.svg';
import imgAddManager from '../../Assets/ManagerDashboard/add manager.svg';
import imgViewManager from '../../Assets/ManagerDashboard/view manager.svg';
// End Manager Dashboard Images

//Accounts Dashboard Icons
import imgFeesMain from '../../Assets/FinanceDashboardimg/fees.svg';
import imgBillingMain from '../../Assets/FinanceDashboardimg/billing.svg';
import imgAddFeeType from '../../Assets/FinanceDashboardimg/add fees type.svg';
import imgViewFeeType from '../../Assets/FinanceDashboardimg/view fees type.svg';
import imgFeeCollection from '../../Assets/FinanceDashboardimg/fee collection.svg';
import imgFeeView from '../../Assets/FinanceDashboardimg/view fees.svg';
//End Accounts Dashboard Icons

//Billing Dashboard Icons
import imgaddBilling from '../../Assets/FinanceDashboardimg/add billing.svg';
import imgViewBilling from '../../Assets/FinanceDashboardimg/view billing.svg';
//End Billing Icons
//PayRoll Icons
import imgAddPayRoll from '../../Assets/PayRolDashboard/add payroll.svg';
import imgViewPayRoll from '../../Assets/PayRolDashboard/view payroll.svg';
import imgPaySlips from '../../Assets/PayRolDashboard/employee payslips.svg';
import imgPayRoll from '../../Assets/PayRolDashboard/payroll.svg';
//End PayRoll Icons
//Library Dashboard Icons
import imgAddBook from '../../Assets/LibraryDashboard/add book.svg';
import imgViewBook from '../../Assets/LibraryDashboard/view book.svg';
import imgViewIssued from '../../Assets/LibraryDashboard/book details.svg';
import imgIssuedBook from '../../Assets/LibraryDashboard/issue books.svg';
import imgLibraryManage from '../../Assets/LibraryDashboard/library smart.svg';
//End Library Dashboard Icons

//Event Dashboard Images
import imgAddEvent from '../../Assets/EventDashboard/add event.svg';
import imgViewEvent from '../../Assets/EventDashboard/view event.svg';
import imgUpdateEvent from '../../Assets/EventDashboard/update event.svg';
//End Event Dashboard Images

//Student Progress Report
import AddStudentProgressReport from '../../Assets/StudentProgressDashboard/add progress report.svg'
import ViewStudentProgressReport from '../../Assets/StudentProgressDashboard/view progress report.svg'
import DashboardStudentProgressReport from '../../Assets/StudentProgressDashboard/student progress report.svg'
//End Student Progress Report

//Maintenace Manager
import DashboardCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/campus maintenance.svg'
//End Maintenace Manager

//Campus Maintenance
import AddFloorCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/add floor.svg'
import ViewFloorCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/view floor.svg'
import AddRoomCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/add room.svg'
import ViewRoomCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/view room.svg'
import AddCleanlinessCategoryCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/add cleanliness category.svg'
import ViewCleanlinessCategoryCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/view cleanliness category.svg'
import AddCyclicCleanlinessCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/add cyclic cleanliness.svg'
import ViewCyclicCleanlinessCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/view cyclic cleanliness.svg'
import AddEmergencyMaintenanceCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/add emergency maintenance.svg'
import ViewEmergencyMaintenanceCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/view emergency maintenance.svg'
import NotificationEmergencyMaintenanceCampusMaintenance from '../../Assets/CampusMaintenanceDashboard/emergency maintenance notification.svg'
//End Campus Maintenance


//#region Class imports
import imgAddClass from '../../Assets/ClassDashboard/add class.svg'
import imgViewClass from '../../Assets/ClassDashboard/view class.svg'
//#endregion

//#region Section imports
import imgAddSection from '../../Assets/SectionDashboard/add section.svg'
import imgViewSection from '../../Assets/SectionDashboard/view section.svg'
//#endregion

//#region Session imports
import imgAddSession from '../../Assets/SessionDashboard/add session.svg'
import imgViewSession from '../../Assets/SessionDashboard/view session.svg'
//#endregion

//#region Subject imports
import imgAddSubject from '../../Assets/SubjectDashboard/add subject.svg'
import imgViewSubject from '../../Assets/SubjectDashboard/view subject.svg'
//#endregion

//#region Assign Class Teacher imports
import imgAddClassTeacher from '../../Assets/ClassTeacherDashboard/assign class teacher.svg'
//import imgViewClassTeacher from '../../Assets/ClassDashboard/view class teacher.svg'
//#endregion

//#region Subject imports
import imgAddIndent from '../../Assets/IndentDashboard/add indent form.svg'
import imgViewIndent from '../../Assets/IndentDashboard/view indent form.svg'
import imgAdminProcurement from '../../Assets/AdministrationDashboard/procurement.svg'
//#endregion

//#region Subject imports
import imgAddTeacherEvaluation from '../../Assets/TeacherEvaluationDashboard/add teachers evaluation.svg'
import imgViewTeacherEvaluation from '../../Assets/TeacherEvaluationDashboard/view teachers evaluation.svg'
//#endregion

//# Pastoral Imports  
import imgPastoralMedicalCenter from '../../Assets/Pastoral/medical center.svg'
//#end pastoral imports

//#region Medical Center imports
import imgDoctor from '../../Assets/MedicalCenterDashboard/doctor/doctor.svg'
import imgAddDoctor from '../../Assets/MedicalCenterDashboard/doctor/add doctor.svg'
import imgViewDoctor from '../../Assets/MedicalCenterDashboard/doctor/view doctor.svg'

import imgPatient from '../../Assets/MedicalCenterDashboard/patient/patient.svg'
import imgAddPatient from '../../Assets/MedicalCenterDashboard/patient/add patient.svg'
import imgViewPatient from '../../Assets/MedicalCenterDashboard/patient/view patient details.svg'

import imgViewMedHistory from '../../Assets/MedicalCenterDashboard/view medical history.svg'
//#endregion

//#region Campus Security imports
import imgCSecurity from '../../Assets/VisitorDashboard/visitor.svg'
import imgCSAddVisitor from '../../Assets/VisitorDashboard/add visitor entries.svg'
import imgCSViewVisitor from '../../Assets/VisitorDashboard/view visitor entries.svg'
import imgCSGenerateCard from '../../Assets/VisitorDashboard/generate visting card.svg'
//#endregion

//# Leave imports
import imgAcademicLeave from '../../Assets/LeaveDashboard/leave.svg'
import imgAddLeave from '../../Assets/LeaveDashboard/add leave.svg'
import imgViewLeaveStaff from '../../Assets/LeaveDashboard/view leave by staff.svg'
import imgViewLeaveStudent from '../../Assets/LeaveDashboard/view leave by student.svg'
//#endregion

//# Headings import
import imgPastoralSmart from '../../Assets/images/pastoral smart heading.svg'
import imgMedicalCenter from '../../Assets/images/medical center heading.svg'
//#endregion

//# Tour import
import imgAddTour from '../../Assets/TourDashboard/add tour.svg'
import imgViewTour from '../../Assets/TourDashboard/view tour.svg'
import imgPastoralTour from '../../Assets/TourDashboard/tour.svg'
import imgViewTourFilters from '../../Assets/TourDashboard/tour search.svg'
//#endregion

//# Tour import
import imgPastoralActivity from '../../Assets/ActivityDashboard/activity icon 2.svg'
//#endregion

//#region Other Certificate
import imgCertificateTemplate1 from '../../Assets/OtherCertificateDashboard/certificate1.png'
import imgCertificateTemplate2 from '../../Assets/OtherCertificateDashboard/certificate2.png'
import imgAddCertificate from '../../Assets/OtherCertificateDashboard/add certificate.svg'
import imgViewCertificate from '../../Assets/OtherCertificateDashboard/view certificate.svg'
import imgActivityCertificate from '../../Assets/OtherCertificateDashboard/certificate.svg'
import imgAddCertificateCategory from '../../Assets/OtherCertificateDashboard/add category.svg'
import imgViewCertificateCategory from '../../Assets/OtherCertificateDashboard/view category.svg'
import imgCertificateCategory from '../../Assets/OtherCertificateDashboard/certificate category.svg'
import imgAddOtherCertificate from '../../Assets/OtherCertificateDashboard/add other certificate.svg'
import imgViewOtherCertificate from '../../Assets/OtherCertificateDashboard/view other certificate.svg'
import imgOtherCertificate from '../../Assets/OtherCertificateDashboard/other certificate.svg'
//#endregion

//# Award
import imgAddAward from '../../Assets/AwardDashboard/add award.svg'
import imgViewAward from '../../Assets/AwardDashboard/view award.svg'
import imgAward from '../../Assets/AwardDashboard/award.svg'
//#endregion

//# Game
import imgGames from '../../Assets/GameDashboard/games.svg'
import imgAddGames from '../../Assets/GameDashboard/add game.svg'
import imgViewGames from '../../Assets/GameDashboard/view game.svg'
import imgOffGames from '../../Assets/GameDashboard/off games.svg'
import imgAddOffGame from '../../Assets/GameDashboard/add off game.svg'
import imgViewOffGame from '../../Assets/GameDashboard/view off game .svg'
//#endregion

export default {
	imgAddFeeType,
	imgViewFeeType,
	imgFeeCollection,
	imgFeeView,
	imgFeesMain,
	imgBillingMain,
	imgBDash,
	imgFDash,
	imgAdDash,
	imgAcDash,
	imgAdmissionDash,
	imgComDash,
	imgHRDash,
	imgPasDash,
	imgSysDash,
	imgGMDash,

	imgBDash1,
	imgFDash1,
	imgAdDash1,
	imgAcDash1,
	imgAdmissionDash1,
	imgComDash1,
	imgHRDash1,
	imgPasDash1,
	imgSysDash1,
	imgGMDash1,
	imgLPW,
	imgTT,
	addTT,
	viewTT,
	addLPW,
	viewLPW,
	imgTThead,
	imgAcHead,
	imgFHead,
	imgALDash,
	imgBudgetDash,
	imgAddIcon,
	imgViewIcon,
	imgHRSRM,
	imgHRDevTrain,
	imgHRFrontDesk,
	imgHRDept,
	imgHRpost,
	imgHRStaffDoc,
	imgHRVacany,
	imgHRSalary,
	imgHrInterview,
	imgHrHead,
	imgAcademicLMS,
	imgCaPmTool,
	imgCAImage,
	imgCAViewImage,
	imgAcademicCMS,
	imgAcademicSID,
	imgAcademicHomeWork,
	imgAcademicAssignment,
	imgAcademicLesson,
	imgAcademicExam,
	imgAcademicSyllabus,
	imgAcademicTimeTable,
	imgAcademicQuiz,
	imgAcademicTest,
	imgAcademicPastPaper,
	imgAcademicClass,
	imgAcademicSection,
	imgAcademicSession,
	imgAcademicSubject,
	imgAcademicAssignClassTeacher,
	imgAcademicIndent,
	imgAcademicTeacherEvaluation,

	imgAcademicSkill,
	imgAcademicDescriptive,
	imgAddSubDiscipline,
	imgSubDiscipline,
	imgViewSubDiscipline,

	imgAddClassDiscipline,
	imgViewClassDiscipline,
	imgClassDiscipline,
	imgUpdateClassDiscipline,

	imgStudentManager,
	imgParentManager,
	imgAddParentResgitration,
	imgUpdateParentResgitration,
	imgRegisterParentResgitration,
	addstaff,
	viewstaff,
	imgManagerDashboard,
	imgAddManager,
	imgViewManager,
	imgadmis,

	imgaddBilling,
	imgViewBilling,
	imgAddPayRoll,
	imgViewPayRoll,
	imgPaySlips,
	imgPayRoll,
	imgAddBook,
	imgViewBook,
	imgIssuedBook,
	imgViewIssued,
	imgLibraryManage,
	imgLabSmart,

	imgAddEvent,
	imgViewEvent,
	imgUpdateEvent,

	AddStudentProgressReport,
	ViewStudentProgressReport,
	DashboardStudentProgressReport,

	DashboardCampusMaintenance,

	AddFloorCampusMaintenance,
	ViewFloorCampusMaintenance,
	AddRoomCampusMaintenance,
	ViewRoomCampusMaintenance,
	AddCleanlinessCategoryCampusMaintenance,
	ViewCleanlinessCategoryCampusMaintenance,
	AddCyclicCleanlinessCampusMaintenance,
	ViewCyclicCleanlinessCampusMaintenance,
	AddEmergencyMaintenanceCampusMaintenance,
	ViewEmergencyMaintenanceCampusMaintenance,
	NotificationEmergencyMaintenanceCampusMaintenance,
	imgAddClass,
	imgViewClass,
	imgAddSection,
	imgViewSection,
	imgAddSession,
	imgViewSession,
	imgAddSubject,
	imgViewSubject,
	imgAddClassTeacher,
	imgAddIndent,
	imgViewIndent,
	imgAdminProcurement,
	imgAddTeacherEvaluation,
	imgViewTeacherEvaluation,

	imgPastoralMedicalCenter,

	imgDoctor,
	imgAddDoctor,
	imgViewDoctor,
	imgPatient,
	imgAddPatient,
	imgViewPatient,
	imgViewMedHistory,

	imgCSecurity,
	imgCSGenerateCard,
	imgCSViewVisitor,
	imgCSAddVisitor,

	imgAcademicLeave,
	imgAddLeave,
	imgViewLeaveStaff,
	imgViewLeaveStudent,

	imgMedicalCenter,
	imgPastoralSmart,

	imgAddTour,
	imgPastoralTour,
	imgViewTourFilters,
	imgViewTour,
	imgPastoralActivity,

	imgCertificateTemplate1,
	imgCertificateTemplate2,
	imgActivityCertificate,
	imgAddCertificate,
	imgViewCertificate,
	imgOtherCertificate,
	imgAddOtherCertificate,
	imgViewOtherCertificate,
	imgAddCertificateCategory,
	imgCertificateCategory,
	imgViewCertificateCategory,

	imgAward,
	imgAddAward,
	imgViewAward,

	imgGames,
	imgAddGames,
	imgViewGames,
	imgOffGames,
	imgAddOffGame,
	imgViewOffGame
};
