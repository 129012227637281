import * as actionTypes from '../actionTypes';

export function setAllExamInfoInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_EXAM_INFO,
		payload: allData
	};
}

export function getClassesForExamInfo(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_EXAM_INFO_SELECT,
		payload: allData
	};
}
export function getSessionForExamInfo(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_EXAM_INFO_SELECT,
		payload: allData
	};
}
export function addExamInfo(Data) {
	return {
		type: actionTypes.ADD_EXAM_INFO,
		payload: Data
	};
}
export function updateExamInfoFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_EXAM_INFO,
		payload: setFlag
	};
}
export function updateExamInfo(data) {
	return {
		type: actionTypes.EDIT_EXAM_INFO_RECORD,
		payload: data
	};
}
export function deleteExamInfo(id) {
	return {
		type: actionTypes.DELETE_EXAM_INFO,
		payload: id
	};
}
