import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	select: [],
	SearchData: [],
	SelectSesssion: []
};

export default function setQuizReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.SET_ALL_CLASS_GRADE_NAME_INTO_QUIZ_SELECT:
			return {
				...state,
				select: action.payload
			};
		case actionTypes.SET_ALL_SESSION_NAME_INTO_QUIZ_SELECT:
			return {
				...state,
				SelectSesssion: action.payload
			};

		case actionTypes.GET_ALL_QUIZ:
			return { ...state, Data: [...action.payload] };
		// case actionTypes.GET_ALL_STAFFSEARCH_BY_POST:
		//
		// 	return { ...state, SearchData: action.payload };
		case actionTypes.SET_LOADER_FLAG_QUIZ:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_QUIZ:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.quizId !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_QUIZ_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.quizId === action.payload.id);
			adminData[index].status = action.payload.status;
			// adminData[index].quizName = action.payload.quizName;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
