import React, { Component } from 'react';
import './viewroutes.css';
const SnackBar = props => {
	return (
		<div
			style={{ backgroundColor: props.backColor }}
			className="animated fadeInDown"
			id="snackbar"
		>
			{props.msg}
		</div>
	);
};

export default SnackBar;
