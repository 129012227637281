import React, { Component } from 'react';
import $ from 'jquery';

class ShowResultTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			enabled: false,
			className: '',
			sectionName: '',
			resultStatus: '',
			registrationNo: '',
			modalData: []
		};
	}
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		let getData = this.props.data.filter(
			item => item.registrationNo === itemId
		);

		this.setState({
			modalData: getData,
			className: getData[0].className,
			sectionName: getData[0].section,
			registrationNo: getData[0].registrationNo
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const { id, stockIn } = this.state;
		var bodyParameters = {
			id,
			date: new Date().toDateString(),
			time: new Date().toTimeString()
		};
		this.props.sharedActions
			.editRecord(
				'/api/Route/',
				id,
				bodyParameters,
				this.props.routesActions.updateTransport
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				this.props.snackbar();
			})
			.catch(err => {});
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	render() {
		const { modalData } = this.state;
		const Show = this.props.data.map((item, index) => (
			<tr key={index}>
				<th scope="row" style={{ padding: 2 }}>
					{index + 1}
				</th>
				<td style={{ padding: 2 }}>{item.userName}</td>
				<td style={{ padding: 2 }}>{item.registrationNo}</td>
				<td style={{ padding: 2 }}>{item.className}</td>
				<td style={{ padding: 2 }}>{item.section}</td>
				<td style={{ padding: 2 }}>{item.resultStatus}</td>
				<td style={{ padding: 2 }}>
					<a href="#" data-toggle="tooltip" title="Edit!">
						<button
							id="firstbutton"
							onClick={() => this.onEditClick(item.registrationNo)}
							onMouseOver={this.btnTechHover()}
							data-toggle="modal"
							data-target="#myModalc"
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								marginLeft: 5,
								background: 'none',
								marginBottom: 5
							}}
						>
							<i class="fas fa-pen"></i>
						</button>
					</a>
				</td>
			</tr>
		));
		return <React.Fragment>{Show}</React.Fragment>;
	}
}

export default ShowResultTable;
