import React, { useState } from 'react';
import useHttp from '../../../../hooks/http';
import {
	checkingProperty,
	checkForFilteration
} from '../../../../utils/general';
import LoaderWrapper from '../../../../components/HOC/LoaderWrapper';
import Button from '../../../../components/Button';
import RowWrapper from '../../../../components/style/RowWrapper';
import * as sharedActions from '../../../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SnackBar from '../../../../components/SnackBar/SnackBar';

function PrimaryInfoDelete(props) {
	const [snackbarMessage, setMsg] = useState('');
	const { id } = props.match.params;
	const [isReqLoader, setLoading] = useState(false);
	const [isLoading, fetchData = {}] = useHttp(`/api/PrimaryInfo/${id}`, []);

	debugger;
	let getKeys = [];
	let excludedData = [
		'primaryInfoId',
		'classId',
		'sectionId',
		'sessionId',
		'subjectId',
		'gradeId'
	];
	if (fetchData != null) {
		getKeys = Object.keys(fetchData);
		if (checkingProperty(getKeys, excludedData)) {
			getKeys = checkForFilteration(getKeys, excludedData);
		}
		debugger;
	}
	const onCancleClick = () => {
		props.history.push({
			pathname: '/dashboard/CAPrimaryInfoView',
			state: { deleteFlag: false }
		});
	};
	const onHandleDelete = () => {
		setLoading(true);
		props.sharedAction
			.deleteRecordWithoutDispatch('/api/PrimaryInfo', id)
			.then(success => {
				setLoading(false);
				debugger;
				// if (success && success.response && success.response.status == 200) {
				// setMsg('Delete Record Successfully');
				props.snackbar();
				props.history.push({
					pathname: '/dashboard/CAPrimaryInfoView',
					state: { deleteFlag: true }
				});

				debugger;
				// }
			})
			.catch(error => {
				debugger;
				setLoading(false);
				if (error && error.response && error.response.status == 400) {
					setLoading(false);
					//404-Error no understanding of 404 Error
					setMsg(error.response.data.Message);

					props.snackbar();
				}
			});
	};
	return (
		<div>
			<LoaderWrapper isLoading={isLoading}>
				<SnackBar backColor={'#000'} msg={snackbarMessage} />
				<div
					style={{
						width: '40%',
						margin: '0 auto',
						boxShadow: '5px 5px 5px #888888'
					}}
					class="card"
				>
					<div
						style={{ textAlign: 'center', backgroundColor: '#01AC8A' }}
						class="card-header"
					>
						<h4 style={{ color: '#fff' }}>Confirmation</h4>
					</div>
					<div class="card-body">
						<table class="table">
							<thead>
								<tr>
									<th className="text-center" colSpan={2} scope="col">
										Details{' '}
									</th>
								</tr>
							</thead>
							<tbody>
								{getKeys.length > 0 ? (
									getKeys.map(item => (
										<tr>
											<td>
												{item.charAt(0).toUpperCase() + item.substring(1)}
											</td>
											<td>{fetchData[item]}</td>
										</tr>
									))
								) : (
									<tr>
										<td>No Data</td>
									</tr>
								)}
							</tbody>
						</table>
						<RowWrapper contextPosition="space-around">
							<Button
								icon={true}
								iconClass={'fas fa-trash-alt'}
								backgroundColor={
									getKeys.length < 1 || isReqLoader ? '#ffb2b2' : '#C70000'
								}
								iconSize={17}
								btnPadding={6}
								buttonClass={'btnStandard'}
								stateData={{
									isLoading:
										getKeys.length < 1 || isReqLoader
											? true
											: isReqLoader
											? true
											: false
								}}
								onHandleSubmit={onHandleDelete}
								btnName="Delete"
							/>
							<Button
								icon={true}
								iconClass={'fas fa-backward'}
								btnPadding={6}
								backgroundColor={
									getKeys.length < 1 || isReqLoader ? '#78A6C6' : '#01425E'
								}
								onHandleSubmit={onCancleClick}
								iconSize={17}
								stateData={{
									isLoading:
										getKeys.length < 1 || isReqLoader
											? true
											: isReqLoader
											? true
											: false
								}}
								buttonClass={'btnStandard'}
								btnName="Cancel"
							/>
						</RowWrapper>
					</div>
				</div>
			</LoaderWrapper>
		</div>
	);
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	null,
	mapDispatchToProps
)(PrimaryInfoDelete);
