import React, { Component } from 'react';
import * as ParentActions from '../../Actions/Parents';
import * as SharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';

class SearchParentByChild extends Component {
	constructor(props) {
		super(props);
		this.state = {
			parentData: [],
			studentFilterData: [],
			sections: [],
			students: [],
			classId: '',
			sectionId: '',
			studentId: '',
			parentName: '',
			userName: '',
			password: '',
			msg: '',
			errorMsg: '',
			errorText: '',
			deleteFlag: false,
			updateFlag: false,
			formLoading: true
		};
	}

	componentDidMount() {
		this.props.SharedActions.getDataWithoutDispatch(`/api/Student`)
			.then(success => {
				this.setState({
					studentFilterData: success
				});
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}

	onClassChange = e => {
		console.log(e.target.value);
		const Sections = this.state.studentFilterData.sections;
		let filtering = Sections.filter(item => item.classId == e.target.value);
		console.log(filtering);

		this.setState({ classId: e.target.value, sections: filtering });
	};

	onSectionChange = e => {
		console.log(e.target.value);
		const Subjects = this.state.studentFilterData.student;
		let studentFiltering = Subjects.filter(
			item =>
				item.classId == this.state.classId && item.sectionId == e.target.value
		);
		this.setState({ sectionId: e.target.value, students: studentFiltering });
	};

	onSearchParent = e => {
		e.preventDefault();
		this.setState({
			btnLoad: true
		});

		this.props.SharedActions.getDataWithoutDispatch(
			`/api/GetParentByStudent/?studentId=${this.state.studentId}`
		)
			.then(success => {
				this.setState({
					parentData: success,
					formLoading: false,
					btnLoad: false
				});
				console.log(success);
			})
			.catch(error => {
				this.setState({
					// errorText: error.response.data.Message,
					badError: true,
					formLoading: true,
					btnLoad: false
				});
				console.log(error);
			});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const { classId, sectionId, studentId } = this.state;
		if (
			Object.is(classId, '') ||
			Object.is(sectionId, '') ||
			Object.is(studentId, '')
		) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			var bodyParameters = {
				id: this.state.parentData.id,
				studentId,
				accountId: this.props.userData.unique_name,
				accountType: this.props.userData.role
			};
			this.props.SharedActions.addRecord(
				`/api/AlreadyRegistered`,
				bodyParameters,
				this.props.ParentActions.addParent
			)
				.then(success => {
					this.props.ParentActions.updateParentFlag(true);
					this.setState({
						userName: '',
						classId: '',
						sectionId: '',
						studentId: '',
						msg: true,
						errorMsg: false,
						formLoading: true
					});
				})
				.catch(error => {
					debugger;
					this.setState({
						errorText: error.response.data.Message,
						badError: true
					});
					console.log(error);
				});
		}
		console.log(this.state);
	};
	HideUnHide = e => {
		$('.toggle-password').click(function() {
			$(this).toggleClass('fa-eye fa-eye-slash');
			var input = $($(this).attr('toggle'));
			if (input.attr('type') == 'password') {
				input.attr('type', 'text');
			} else {
				input.attr('type', 'password');
			}
		});
	};

	componentDidUpdate() {
		if (
			this.state.msg ||
			this.state.errorMsg ||
			this.state.badError ||
			this.state.updateFlag ||
			this.state.deleteFlag
		) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					badError: false,
					updateFlag: false,
					deleteFlag: false
				});
			}, 3000);
		}
	}

	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onDelete = e => {
		e.preventDefault();
		this.props.SharedActions.deleteRecordWithoutDispatch(
			'/api/ParentRegistration/',
			this.state.parentData.id
		)
			.then(success => {
				this.setState({
					formLoading: true,
					deleteFlag: true,
					userName: ''
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onUpdate = e => {
		e.preventDefault();
		const { classId, sectionId, studentId, parentName, password } = this.state;
		if (
			Object.is(classId, '') ||
			Object.is(sectionId, '') ||
			Object.is(studentId, '') ||
			Object.is(parentName, '') ||
			Object.is(password, '')
		) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			var bodyParameters = {
				studentId,
				parentName,
				password,
				userName: this.state.parentData.userName,
				accountId: this.props.userData.unique_name,
				accountType: this.props.userData.role
			};
			this.props.SharedActions.editPatchRecordWithoutDispatch(
				`/api/ParentRegistration/`,
				this.state.parentData.id,
				bodyParameters
			)
				.then(success => {
					this.setState({
						userName: '',
						classId: '',
						sectionId: '',
						studentId: '',
						updateFlag: true,
						errorMsg: false,
						formLoading: true,
						updateMsg: success.data
					});
				})
				.catch(error => {
					debugger;
					this.setState({
						errorText: error.response.data.Message,
						badError: true
					});
					console.log(error);
				});
		}
		console.log(this.state);
	};
	render() {
		console.log('Students Data', this.state.studentFilterData);
		const { classes } = this.state.studentFilterData;
		const { formLoading, parentData } = this.state;

		const FormLoading = (
			<div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
				<p>No Data</p>
			</div>
		);

		const renderTodos = parentData.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" className="bordersSpace" style={{ padding: 2 }}>
						{index + 1}
					</th>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.parentName}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.parentUserName}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.parentMobileNo}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.emergencyContact}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.password}
					</td>
				</tr>
			);
		});

		const FormReady = (
			<div style={{ marginTop: 0 }}>
				<div className="table-responsive">
					<table class="table table-hover text-center table-bordered">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 2 }}>
									#
								</th>
								<th scope="col" style={{ padding: 2 }}>
									Parent Name
								</th>
								<th scope="col" style={{ padding: 2 }}>
									User Name
								</th>
								<th scope="col" style={{ padding: 2 }}>
									Mobile No
								</th>
								<th scope="col" style={{ padding: 2 }}>
									Emergency No
								</th>
								<th scope="col" style={{ padding: 2 }}>
									Password
								</th>
							</tr>
						</thead>
						<tbody>{renderTodos}</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<React.Fragment>
				<h3 class="h1-responsive font-weight-bold text-center my-4">
					Parent Search
				</h3>
				{this.state.badError ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						{this.state.errorText}
					</p>
				) : (
					''
				)}
				{this.state.updateFlag ? (
					<p style={{ color: 'green', textAlign: 'center' }}>
						Update Successfully
					</p>
				) : (
					''
				)}
				{this.state.deleteFlag ? (
					<p style={{ color: 'green', textAlign: 'center' }}>
						Delete Successfully
					</p>
				) : (
					''
				)}
				{this.state.msg ? (
					<p style={{ color: 'green', textAlign: 'center' }}>
						Sibling Registered Successfully
					</p>
				) : (
					''
				)}
				<div class="row text-center" style={{ justifyContent: 'center' }}>
					<div class="row">
						<div class="col-md-4" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								<label for="email" class="">
									Class
								</label>
								<select
									onChange={this.onClassChange}
									class="custom-select"
									name="sectionName"
								>
									<option selected={this.state.msg ? true : false}>
										Choose Class...
									</option>
									{classes &&
										classes.map((item, index) => (
											<option key={index} value={`${item.classId}`}>
												{item.className}
											</option>
										))}
								</select>
							</div>
						</div>
						<div class="col-md-4" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								<label for="email" class="">
									Section
								</label>
								<select
									onChange={this.onSectionChange}
									class="custom-select"
									name="sectionName"
								>
									<option selected={this.state.msg ? true : false}>
										Choose Section...
									</option>
									{this.state.sections.map((item, index) => (
										<option value={`${item.sectionId}`}>{item.section}</option>
									))}
								</select>
							</div>
						</div>
						<div class="col-md-4" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								<label for="email" class="">
									Students
								</label>
								<select
									onChange={this.onHandleTextChange}
									class="custom-select"
									name="studentId"
								>
									<option selected={this.state.msg ? true : false}>
										Select Student...
									</option>
									{this.state.students.map((item, index) => (
										<option value={`${item.studentId}`}>{item.Student}</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center text-md-right">
					<button
						class="btn"
						style={{
							backgroundColor: '#01ac8a',
							color: 'white',
							width: 100,
							marginTop: 20
						}}
						onClick={this.onSearchParent}
					>
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
						Search
					</button>
				</div>
				<br />
				<br />
				{formLoading ? FormLoading : FormReady}
			</React.Fragment>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		SharedActions: bindActionCreators(SharedActions, dispatch),
		ParentActions: bindActionCreators(ParentActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchParentByChild);
