import React, { Component } from 'react';
import * as DepartmentActions from '../../Actions/Department';
import * as sharedActions from '../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DateandTime from '../Transport/DateandTime';

class AddDepartment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			AdminName: '',
			AdminType: '',
			departmentName: '',
			campusId: '',
			select: '',
			errorMsg: false,
			msg: false
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.select !== nextProps.selectData) {
			return {
				select: nextProps.selectData
			};
		}
		return null;
	}

	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			campusId: result.id
		});
	};

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const { departmentName, campusId } = this.state;
		if (Object.is(departmentName, '') || Object.is(campusId, '')) {
			this.setState({
				errorMsg: true
			});
		} else {
			const data = {
				departmentName,
				campusId,
				accountType: this.props.role,
				accountId: this.props.name
			};
			this.props.sharedActions
				.addRecord(
					'/api/Department',
					data,
					this.props.DepartmentActions.addDepartment
				)
				.then(success => {
					this.setState({
						errorMsg: false,
						departmentName: '',
						msg: true
					});
				})
				.catch(error => {});
		}
		console.log(this.state);
	};
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/CampusDetail',
				this.props.DepartmentActions.getCampusForDepartment
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}
	render() {
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Department
					</h2>

					<p class="text-center w-responsive mx-auto mb-5">School Smart ®</p>

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.state.errorMsg ? (
								<p style={{ color: 'red' }}>Please fill the form properly!</p>
							) : (
								''
							)}
							{this.state.msg ? (
								<p style={{ color: 'green' }}>Department Added Successfully!</p>
							) : (
								''
							)}
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-user-shield animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												value={this.props.name}
												readOnly={true}
												type="text"
												name="AdminName"
												class="form-control"
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-user-alt animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												value={this.props.role}
												readOnly={true}
												type="text"
												name="AdminType"
												class="form-control"
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          className="fas fa-building"
                          style={{ marginRight: 4 }}
                        /> */}
												Department Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Department Name"
												type="text"
												name="departmentName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-map animated fadeIn"
                        /> */}
												Campus Name
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onSelectChange}
												class="custom-select"
												name="routeId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Campus...
												</option>
												{this.state.select.map((item, index) => (
													<option key={index} value={`${JSON.stringify(item)}`}>
														{item.campusName}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>

								<br />
								<br />
								<div class="text-center text-md-center">
									<button onClick={this.onHandleSubmit} class="btns">
										Add
									</button>
								</div>
							</form>

							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		DepartmentActions: bindActionCreators(DepartmentActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId,
		selectData: state.setDepartmentReducer.select
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddDepartment);
