import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import TextInput from '../../components/TextInput/TextInput'
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../Actions/sharedActions';
import Button from '../../components/Button';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import TextArea from '../../components/TextArea'
import DatePicker from 'react-date-picker';
import Spinner from '../../components/Spinner/Spinner';
import general from '../../utils/general'
import TimePicker from '../../components/TimePicker';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import moment from 'moment';

class AddAward extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingInitialData: true,
            isLoading: false,
            accountId: props.name,
            accountType: props.role,
            errors: {},
            errorMsg: false,

            sessionId: '',
            month: '',
            date: '',
            time: '',
            description: '',
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                '/api/session'
            )
            .then(success => {
                this.setState({
                    sessionData: success.session,
                    isLoadingInitialData: false
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoisLoadingInitialDataading: false })
            })

    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false
                });
            }, 3000);
        }
    }

    handleChange = (date) => {
        this.setState({
            date: date,
            month: general.Months[date.getMonth()]
        });
    };

    onHandleTimeChange = (time) => {
        this.setState({
            time: time
        })
    }

    onHandleTextChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onHandleSelectChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        const { month, date, time, sessionId, description, accountId, accountType } = this.state;

        if (month === '' ||
            date === '' ||
            time === '' ||
            sessionId === '' ||
            description === '' ||
            accountId === '' ||
            accountType === ''
        ) {
            this.setState({
                errorMsg: true
            });
        } else {
            this.setState({
                isLoading: true
            })
            let tempDate = new Date(date)
            tempDate = ((tempDate.getMonth() > 8) ? (tempDate.getMonth() + 1) : ('0' + (tempDate.getMonth() + 1))) + '/' + ((tempDate.getDate() > 9) ? tempDate.getDate() : ('0' + tempDate.getDate())) + '/' + tempDate.getFullYear();

            let tempTime = moment(time).format("hh:mm a");

            const data = {
                month,
                date: tempDate,
                time: tempTime,
                sessionId,
                description,
                accountId,
                accountType
            }
            this.props.sharedActions
                .simpleAddRequest(
                    '/api/Award/',
                    data,
                )
                .then(success => {
                    this.setState({
                        month: '',
                        date: '',
                        time: '',
                        sessionId: '',
                        description: '',
                        errorMsg: false,
                        isLoading: false,
                        msg: 'Award Added Successfully!',
                    });
                    this.props.snackbar();
                })
                .catch(error => {
                    this.setState({
                        isLoading: false
                    })
                });
        }
    }

    render() {
        const { errors, isLoadingInitialData, sessionData, date } = this.state;

        const Form = (
            <FormLayoutWrapper
                formName='Add Award'
                borderColor='#01ac8a'
                borderRadius={15}
            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly
                    </p>
                ) : (
                        ''
                    )}

                <div className="row">

                    <TextInput
                        feildName={'accountId'}
                        inputLabelName={'Admin Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Admin Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        feildName={'accountType'}
                        inputLabelName={'Account Type'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Account Type"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'sessionId'}
                        selectName={'Session'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={sessionData}
                        selectedText={'Session'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'currentSession'}
                        propertyId={'sessionId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'month'}
                        inputLabelName={'Month'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Month"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                            <label for="email" class="">
                                <p style={{ margin: 0, color: '#000' }}>
                                    Date
                                </p>
                            </label>
                            <br />
                            <DatePicker
                                onChange={e => this.handleChange(e)}
                                value={date}
                                selectDateOnly
                            />
                        </div>
                    </div>

                    <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                            <label for="email" class="">
                                <p style={{ margin: 0, color: '#000' }}>
                                    Time
                                </p>
                            </label>
                            <br />
                            <TimePicker
                                className="dateTimePicker"
                                showSecond={false}
                                placeholder={'Hr-Min-(AM/PM)'}
                                name="time"
                                defaultValue={null}
                                onChange={e => this.onHandleTimeChange(e)}
                                format={general.timeFormat}

                            // inputReadOnly
                            />
                        </div>
                    </div>


                    <TextArea
                        marginTop={20}
                        feildName={'description'}
                        inputLabelName={'Description'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Description'}
                        isValidte={false}
                    />


                </div>
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Submit"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={20}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                    textColor='white'
                />

            </FormLayoutWrapper >
        )

        return (
            <React.Fragment>
                <SnackBar msg={this.state.msg} />
                {isLoadingInitialData ? <Spinner /> : Form}
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAward);