import $ from 'jquery';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../src/Container/HOC/Layout';
import InActivePage from './components/InActive';
import './components/SnackBar/viewroutes.css';
// academicDetails
import { AcademicLMS, AcademicSID } from './Container/Academic';
import AcademicCMS from './Container/Academic/AcadenucCMS';
import AcademicsDashboard from './Container/AcademicsDashboard';
import AddAdmin from './Container/Admin/Add_Admin_Component/AddAdmin';
import GM from './Container/Admin/Add_Admin_Component/AddGm';
import ViewGm from './Container/Admin/Add_Admin_Component/ViewGm';
import ViewAdmin from './Container/Admin/ViewAdmin';
import AdministrationDashboard from './Container/AdministrationDashboard';
import AdministrationManagers from './Container/AdministrationManagers/AdministrationManagers';
import CensusManager from './Container/AdministrationManagers/CensusManager';
import ViewCensusManager from './Container/AdministrationManagers/ViewCensusManager';
import AdmissionForm from './Container/Admission/AdmissionForm';
import EditAddRecord from './Container/Admission/EditAddRecord';
import EditAdmissionForm from './Container/Admission/EditAdmissionForm';
import PrintAdmissionForm from './Container/Admission/PrintAdmissionForm';
import UploadedFilesView from './Container/Admission/UploadedFilesView';
import ViewAddForm from './Container/Admission/ViewAddForm';
import AddAssignment from './Container/Assignment/AddAssignment';
import ViewAssignment from './Container/Assignment/ViewAssignment';
import ViewParticularStudent from './Container/Assignment/ViewParticularStudent';
import AssignmentDashboard from './Container/AssignmentDashboard';
import AddBudget from './Container/Budget/AddBudget';
import BudgetScreen from './Container/Budget/BudgetScreen';
import ViewBudget from './Container/Budget/ViewBudget';
import BudgetDashboard from './Container/BudgetDashboard';
import AddCampus from './Container/Campus/AddCampus';
import ViewCampus from './Container/Campus/ViewCampus';
import CampusDashboard from './Container/CampusDashboard';
import AddCanteen from './Container/Canteen/AddCanteen';
import AddCanteenCategory from './Container/Canteen/AddCanteenCategory';
import AddCanteenItems from './Container/Canteen/AddCanteenItems';
import ViewCanteen from './Container/Canteen/ViewCanteen';
import ViewCanteenCategory from './Container/Canteen/ViewCanteenCategory';
import ViewCanteenItems from './Container/Canteen/ViewCanteenItems';
import CanteenDashboard from './Container/CanteenDashboard';
import CensusDashboard from './Container/CensusDashboard';
import AgeWiseSearch from './Container/Census_Information/AgeWiseSearch';
import CategoryWiseSearch from './Container/Census_Information/CategoryWiseSearch';
import ClassWiseSearch from './Container/Census_Information/ClassWiseSearch';
import FamilyWiseSearch from './Container/Census_Information/FamilyWiseSearch';
import ProgressBarComponent from './Container/Census_Information/ProgressBarComponent';
import ClassSection from './Container/Census_Information/SearchStudent';
import StudentResultStatus from './Container/Census_Information/StudentResultStatus';
import UserWiseSearch from './Container/Census_Information/UserWiseSearch';
import Check from './Container/Check/Check';
import AddDepartment from './Container/Department/AddDepartment';
import SearchStaffByDepartment from './Container/Department/SearchStaffByDepartment';
import ViewDepartment from './Container/Department/ViewDepartment';
import DepartmentDashboard from './Container/DepartmentDashboard';
import AccountLedger from './Container/Finance/AccountLedger';
import AddAccountLedger from './Container/Finance/AddAccountLedger';
import AddBudgetItems from './Container/Finance/AddBudgetItems';
import SearchItemsBySession from './Container/Finance/SearchItemsBySession';
import ViewBudgetItems from './Container/Finance/ViewBudgetItems';
import FinanceDashboard from './Container/FinanceDashboard';
import FrontDeskDashboard from './Container/FrontDeskDashboard';
import StaffEnquiry from './Container/FrontDeskOfficer/StaffEnquiry';
import StudentEnquiry from './Container/FrontDeskOfficer/StudentEnquiry';
import ViewAdmittedStaff from './Container/FrontDeskOfficer/ViewAdmittedStaff';
import ViewAdmittedStudent from './Container/FrontDeskOfficer/ViewAdmittedStudent';
import ViewStaffEnquiry from './Container/FrontDeskOfficer/ViewStaffEnquiry';
import ViewStaffEnquiryPagination from './Container/FrontDeskOfficer/ViewStaffEnquiryPagination';
import ViewStudentEnquiry from './Container/FrontDeskOfficer/ViewStudentEnquiry';
import ViewStudentEnquiryPagination from './Container/FrontDeskOfficer/ViewStudentEnquiryPagination';
import AddHomeWork from './Container/HomeWork/AddHomeWork';
import ViewHomeWork from './Container/HomeWork/ViewHomeWork';
import HomeWorkDashboard from './Container/HomeWorkDashboard';
import HrDevelopmentDashboard from './Container/HrDevelopmentDashboard';
import EditHrDevelopment from './Container/Hr_Component/EditHrDevelopment';
import HrDashboard from './Container/Hr_Component/HrDashboard';
import HrDevelopmentAndTraining from './Container/Hr_Component/HrDevelopmentAndTraining';
import ViewHrDevelopment from './Container/Hr_Component/ViewHrDevelopment';
import AddInterview from './Container/Interview/AddInterview';
import AddCategory from './Container/Inventory/AddCategory';
import AddItems from './Container/Inventory/AddItems';
import AddStock from './Container/Inventory/AddStock';
import AddStockUsage from './Container/Inventory/AddStockUsage';
import AddSuppliers from './Container/Inventory/AddSuppliers';
import ViewCategory from './Container/Inventory/ViewCategory';
import ViewItems from './Container/Inventory/ViewItems';
// import from './Container/Transport/'
import ViewStock from './Container/Inventory/ViewStock';
import ViewStockUsage from './Container/Inventory/ViewStockUsage';
import ViewSuppliers from './Container/Inventory/ViewSuppliers';
import InventoryDashboard from './Container/InventoryDashboard';
import {
	LessonPerWeek,
	LessonPerWeekScreen,
	ViewLessonWeek
} from './Container/LessonPerWeek';
import LessonPlanDashboard from './Container/LessonPlanDashboard';
import AddLessonPlan from './Container/LessonPlans/AddLessonPlan';
import ViewLessonPlan from './Container/LessonPlans/ViewLessonPlan';
import AddPost from './Container/Post/AddPost';
import SearchStaffByPost from './Container/Post/SearchStaffByPost';
import ViewPost from './Container/Post/ViewPost';
import PostDashboard from './Container/PostDashboard';
import AddQuiz from './Container/Quiz/AddQuiz';
import ViewQuiz from './Container/Quiz/ViewQuiz';
import QuizDashboard from './Container/QuizDashboard';
import AddQuizInfo from './Container/QuizInfo/AddQuizInfo';
import ViewQuizInfo from './Container/QuizInfo/ViewQuizInfo';
import NotificationAppraisal from './Container/Salary/NotificationAppraisal';
import Salary from './Container/Salary/Salary.jsx';
import ViewAppraisal from './Container/Salary/ViewAppraisal';
// staff imports
import StaffImageUploadSection from './Container/Staff/imageUploadSection/StaffImageUploadSection';
import Staff from './Container/Staff';
import EditStaffFrom from './Container/Staff/staffEditForm.jsx/EditStaffForm';
import StaffEditStepsRendering from './Container/Staff/staffEditForm.jsx/StaffEditStepsRendering';
import StaffFileUploadView from './Container/Staff/uploadFileView/StaffFileUploadView';
import StaffView from './Container/Staff/View/ViewStaff';
import PrintStaffAdmissionForm from './Container/StaffAdmissionForm/PrintStaffAdmissionForm';
import StudentAdmissionDashboard from './Container/StudentAdmissionDashboard';
import PrintAdmissionFormFinal from './Container/StudentAdmissionForm/PrintAdmissionFormFinal';
import StaffAdmissionForm from './Container/StudentAdmissionForm/PrintStaffForm';
import TestDashboard from './Container/TestDashboard';
import AddTestInfo from './Container/TestInfo/AddTestInfo';
import ViewTestInfo from './Container/TestInfo/ViewTestInfo';
// time table start
import {
	TimeTable,
	TimeTableScreen,
	AcademicTimeTableModule
} from './Container/Time-Table';
import { TimeTableView } from './Container/Time-Table/Time-Table-View';
import AddDriver from './Container/Transport/AddDriver';
import AddRoute from './Container/Transport/AddRoute';
import AddRoutes from './Container/Transport/AddRoutes';
import AddVehicle from './Container/Transport/AddVehicle';
import AssignVehicle from './Container/Transport/AssignVehicle';
import CreateTransportFees from './Container/Transport/CreateTransportFees';
import ViewAssignVehicle from './Container/Transport/ViewAssignVehicle';
import ViewDriver from './Container/Transport/ViewDriver';
import ViewDriverHistory from './Container/Transport/ViewDriverHistory';
import ViewRoutes from './Container/Transport/ViewRoutes';
import ViewVehicle from './Container/Transport/ViewVehicle';
import TransportDashboard from './Container/TransportDashboard';
import Vacancy from './Container/Vacancy/Vacancy';
import AddTest from './Container/Test/AddTest';
import ViewTest from './Container/Test/ViewTest';
import AddExamDetails from './Container/Exam/AddExamDetails';
import AddExam from './Container/Exam/AddExam';
import ViewExamDetails from './Container/Exam/ViewExamDetails';
import AddExamInfo from './Container/Exam/AddExamInfo';
import ViewExamInfo from './Container/Exam/ViewExamInfo';
import ExamDashboard from './Container/ExamDashboard';
import AddPastPapers from './Container/PastPapers/AddPastPapers';
import ViewPastPapers from './Container/PastPapers/ViewPastPapers';
import AddSyllabus from './Container/Syllabus/AddSyllabus';
import EditorComponent from './Container/Syllabus/EditorComponent';
import ViewEditorComponent from './Container/Syllabus/ViewEditorComponent';
import ViewSyllabus from './Container/Syllabus/ViewSyllabus';
import ViewSyllabusTemplate from './Container/Syllabus/ViewSyllabusTemplate';
import ViewAllSyllabus from './Container/Syllabus/ViewAllSyllabus';
import SyllabusDashboard from './Container/SyllabusDashboard';
import PastPaperDashboard from './Container/PastPaperDashboard';
import StudentSkill from './Container/StaffLoginModule/StudentSkill';
import ViewStudentSkills from './Container/StaffLoginModule/ViewStudentSkills';
import PrintAllStudentSkills from './Container/StaffLoginModule/PrintAllStudentSkills';
import ViewStudentSkillList from './Container/StaffLoginModule/ViewStudentSkillList';
import PrintSkillReport from './Container/StaffLoginModule/PrintSkillReport';
import StudentSkillDashboard from './Container/StudentSkillDashboard';
import DescriptiveReport from './Container/DescriptiveReportModule/DescriptiveReport';
import EditDescriptiveReport from './Container/DescriptiveReportModule/EditDescriptiveReport';
import { EDIT_DESCRIPTIVE_RECORD } from './Actions/actionTypes';
import PrintDescriptiveReport from './Container/DescriptiveReportModule/PrintDescriptiveReport';
import StudentSkillView from './Container/StudentLoginModule/StudentSkillView';
import DiscriptiveDashboard from './Container/DiscriptiveDashboard';
import StudentDescriptiveView from './Container/StudentLoginModule/StudentDescriptiveView';
import StudentSkillViewAdmin from './Container/StudentSkillAdmin/StudentSkillViewAdmin';
import StudentDescriptiveViewAdmin from './Container/StudentSkillAdmin/StudentDescriptiveViewAdmin';
import StudentSkillViewP from './Container/ParentLoginModule/StudentSkillViewP';
import StudentDescriptiveViewP from './Container/ParentLoginModule/StudentDescriptiveViewP';
import AddParent from './Container/Parent/AddParent';
import AlreadyRegister from './Container/Parent/AlreadyRegister';
import SearchChildByParent from './Container/Parent/SearchChildByParent';
import SearchParentByChild from './Container/Parent/SearchParentByChild';
import RegisterStudentToParent from './Container/Parent/RegisterStudentToParent';

// end academicDetails

//#region Leave
import { AddLeave, ViewLeave, ViewLeaveByStudent } from './Container/Leave';
//#endregion

//#region AssignSubjectTeacher
import {
	AssignSubject,
	ViewAssignSubject
} from './Container/AssignSubjectTeacher';
//#endregion

//#region Indent
import {
	/* ViewBillingTemplate, */ IndentFormTemplate,
	AddIndent,
	ViewIndentForm,
	ViewIndentByStaff
} from './Container/Indent';
//#endregion

//#region TeacherObservation
import {
	TeacherObservationBlankTemplate,
	AddTeacherEvaluation,
	ViewTeacherEvaluation,
	EditEvaluationForm,
	ViewEvaluationByStaff,
	EvaluationByStaffTemplate
} from './Container/TeacherObservation';
//#endregion

//#region VisitorCard
import {
	GenerateVisitorCardView,
	AddVisitorEntry,
	VisitorEntryRecord
} from './Container/Visitor';
//#endregion

//#region MedicalCenter Module
import {
	AddDoctor,
	ViewDoctor,
	AddPatient,
	PatientDetail,
	MedicalSlipTemplate,
	MedicalHistory,
	AddPatient2
} from './Container/MedicalCentre';
//#endregion

//# Dashboards
import ClassDashboard from './Container/ClassDashboard';
import SectionDashboard from './Container/SectionDashboard';
import SessionDashboard from './Container/SessionDashboard';
import SubjectDashboard from './Container/SubjectDashboard';
import ClassTeacherDashboard from './Container/ClassTeacherDashboard';
import IndentDashboard from './Container/IndentDashboard';
import IndentStaffDashboard from './Container/IndentStaffDashboard';
import TeacherEvaluationDashboard from './Container/TeacherEvaluationDashboard';
import PastoralDashboard from './Container/PastoralDashboard';
import MedicalDashboard from './Container/MedicalDashboard';
import DoctorDashboard from './Container/DoctorDashboard';
import PatientDashboard from './Container/PatientDashboard';
import CampusSecurityDashboard from './Container/CampusSecurityDashboard';
import LeaveDashboard from './Container/LeaveDashboard';
import StudentLeaveDashboard from './Container/StudentLeaveDashboard';
import TourDashboard from './Container/TourDashboard';
import ActivityDashboard from './Container/ActivityDashboard';
import CertificateDashboard from './Container/CertificateDashboard';
import OtherCertificateDashboard from './Container/OtherCertificateDashboard';
import AwardDashboard from './Container/AwardDashboard';
import CertificateCategoryDashboard from './Container/CertificateCategoryDashboard';
import GameDashboard from './Container/GameDashboard';
import OffGameDashboard from './Container/OffGameDashboard';
//# Dashboards

//#region Tour Module
import {
	AddTour,
	ViewTour,
	ViewClassesInTour,
	ViewFilterTour,
	ViewTourByStaff,
	ViewTourByStudent
} from './Container/Tour';
//#endregion

//#region Other Certificate Module
import {
	AddOtherCertificate,
	ViewOtherCertificate,
	OtherCertificateTemplate1,
	OtherCertificateTemplate2,
	AddCertificateCategory,
	ViewCertificateCategory,
	OtherCertificateCategoryTemplate1,
	OtherCertificateCategoryTemplate2
} from './Container/OtherCertificate';
//#endregion

//#region Game
import { AddGame, ViewGame, AddOffGame, ViewOffGame } from './Container/Game';
//#endregion

//#region Student Coordinator
import {
	AssignClassNewAdmit,
	ClassOfStudent,
	TransferStudent
} from './Container/StudentCoordinater';
//#endregion

//#region Award Module
import {
	AddAwardCategory,
	ViewAwardCategory,
	AddAward,
	ViewAward,
	ViewStudentInAward,
	AwardTemplate1,
	AwardTemplate2,
	ViewAwardByStudent
} from './Container/Award';
//#endregion

// Role: Staff imports of Student Discipline Record
import StudentDisciplineRecord from './Container/StaffLoginModule/StudentDisciplineRecord';
import ViewStudentDiscipline from './Container/StaffLoginModule/ViewStudentDiscipline';
import EditStudentDisciplineRecord from './Container/StaffLoginModule/EditStudentDisciplineRecord';
import StudentDisciplineReports from './Container/StaffLoginModule/Reports/StudentDisciplineReports';
import SubjectDisciplineMarks from './Container/StaffLoginModule/SubjectDisciplineMarks/SubjectDisciplineMarks';
import SubjectDisciplineMarksReport from './Container/StaffLoginModule/Reports/SubjectDisciplineMarksReport';
import ParentStudentDiscipline from './Container/ParentLoginModule/ParentStudentDiscipline/ParentStudentDiscipline';
// end Role : STAFF  imports of Student Discipline Record

// Role : Student => Student Discipline

import { StudentRoleDiscipline } from './Container/StudentLoginModule';

// End Imports of Role : Student => Student Discipline

//  Role : Admin => Admin Student Discipline

import { AdminStudentDiscipline, EditAdminDiscipline } from './Container/Admin';
import ClassDiscipline from './Container/StaffLoginModule/ClassDisciplineModule/ClassDiscipline';
import AddClassDiscipline from './Container/StaffLoginModule/ClassDisciplineModule/AddClassDiscipline';
import ViewClassDiscipline from './Container/StaffLoginModule/ClassDisciplineModule/ViewClassDiscipline';
import ViewClassDisciplinaryRecords from './Container/StaffLoginModule/ClassDisciplineModule/ViewClassDisciplinaryRecords';
import Sheet1 from './Container/StaffLoginModule/ClassDisciplineModule/Sheet1';
import ClassDisciplineDashboard from './Container/ClassDisciplineDashboard';
import UpdateClassDiscipline from './Container/ClassDisciplineAdmin/UpdateClassDiscipline';
import StaffDocumentUploadSection from './Container/Staff/StaffDocumentUploadSection/StaffDocumentUploadSection';
import DocumentSectionStep from './Container/Staff/DocumentSectionStep/DocumentSectionStep';
// imports from Admission Form

import { StudentImageUpload, ParentDocsUpload } from './Container/Admission';
import StudentDocsUpload from './Container/Admission/StudentDocsUpload/StudentDocsUpload';

// end imports from Admission form
import CommunicationDashboard from './Container/ComunicationDashboard';
import ParentPortalManagerDashboard from './Container/ParentPortalManagerDashboard';
import StaffMainScreen from './Container/Staff/StaffMainScreen/StaffMainScreen';
import StudentAttendance from './Container/StudentAttendance/StudentAttendance';
import ViewStudentAttendance from './Container/StudentAttendance/ViewStudentAttendance';
import AddGm from './Container/Admin/Add_Admin_Component/AddGm';
import AddAdmissionManager from './Container/AdmissionManager/AddManager';
import AddManager from './Container/AdmissionManager/AddManager';
import ViewManager from './Container/AdmissionManager/ViewManager';
import ManagersDashboard from './Container/ManagersDashboard';
import AdmissionDashboard from './Container/AdmissionDashboard';
import StudentAttendanceDashboard from './Container/StudentAttendanceDashboard';
import AddFeeType from './Container/FeesModule/AddFeeType';
import ViewFeeType from './Container/FeesModule/ViewFeeType';
import FeesCollection from './Container/FeesModule/FeesCollection';
import MonthlyFeeList from './Container/FeesModule/MonthlyFeeList';
import StudentAttendanceView from './Container/StudentLoginModule/StudentAttendanceView';
import AccountsDashboard from './Container/AccountsDashboard';
import FeesDashboard from './Container/FeesDashboard';
import StudentFeeHistoryP from './Container/ParentLoginModule/StudentFeeHistoryP';
import MonthlyAttendanceView from './Container/StudentAttendance/MonthlyAttendanceView';
import RangeAttendanceView from './Container/StudentAttendance/RangeAttendanceView';
import FeesCollectionNew from './Container/FeesModule/FeesCollectionNew';
import MonthlyFeeListNew from './Container/FeesModule/MonthlyFeeListNew';
import FeeReceipt from './Container/FeesModule/FeeReceipt';
import AddBilling from './Container/FeesModule/Billing/AddBilling';
import ViewBilling from './Container/FeesModule/Billing/ViewBilling';
import BillingTemplate from './Container/FeesModule/Billing/BillingTemplate';
import AddFeeTypeNew from './Container/FeesModule/AddFeeTypeNew';
import ViewFeeTypeNew from './Container/FeesModule/ViewFeeTypeNew';
import IncomeSearch from './Container/Finance/IncomeSearch';
import AddFinanceManager from './Container/FinanceManager/AddFinanceManager';
import ViewFinanceManager from './Container/FinanceManager/ViewFinanceManager';
import FinanceManagerDashboard from './Container/FinanceManager/FinanceManagerDashboard';
import BillingDashboard from './Container/BillingDashboard';

// End of Role : Admin =>  Admin Student Discipline

//  Start of Continouse Assesment imports

import {
	ContinousAssesmentReport,
	ContinouseAssesmentViewList,
	PrimaryInfo,
	PrimaryInfoView,
	PrimaryInfoDelete,
	ContinuousSubToolView,
	CaSubToolCreation,
	ContinuousCreateTools,
	ContnuousRenderTools,
	AssignMarks,
	ContinousEditAssignMarksModule
} from './Container/Continouse-Assesment';
import CADashboard from './Container/Continouse-Assesment/Dashboard/CADashboard';

import AddPayroll from './Container/PayRoll/AddPayroll';
import ViewPayroll from './Container/PayRoll/ViewPayroll';
import ViewPayslip from './Container/PayRoll/ViewPayslip';
import PayslipDetails from './Container/PayRoll/PayslipDetails';
import PayRollDashboard from './Container/PayRollDashboard';
import AddBook from './Container/Library/AddBook';
import ViewBook from './Container/Library/ViewBook';
import IssueBook from './Container/Library/IssueBook';
import ViewIssuedBook from './Container/Library/ViewIssuedBook';
import LibraryDashboard from './Container/LibraryDashboard';
import AddEvent from './Container/Event/AddEvent';
import ViewEvent from './Container/Event/ViewEvent';
import UpdateEvent from './Container/Event/UpdateEvent';
import ViewStudentEvents from './Container/StudentLoginModule/ViewStudentEvents';
import StaffEventView from './Container/Event/StaffEventView';
import EventDashboard from './Container/EventDashboard';

// End of continouse Assesment imports

//Start of Student Progrss

import AddStudentProgressReport from './Container/StudentProgressReport/AddStudentProgressReport';
import ViewStudentProgressReport from './Container/StudentProgressReport/ViewStudentProgressReport';
import PrintStudentProgressReport from './Container/StudentProgressReport/PrintStudentProgressReport';
import StudentProgressReportDashboard from './Container/StudentProgressReportDashboard';

//End of Student Progress

//Campus Maintenance Start

import AddFloor from './Container/CampusMaintenance/AddFloor';
import ViewFloor from './Container/CampusMaintenance/ViewFloor';
import AddRoom from './Container/CampusMaintenance/AddRoom';
import ViewRoom from './Container/CampusMaintenance/ViewRoom';
import AddCleanlinessCategory from './Container/CampusMaintenance/AddCleanlinessCategory';
import ViewCleanlinessCategory from './Container/CampusMaintenance/ViewCleanlinessCategory';
import AddCyclicCleanliness from './Container/CampusMaintenance/AddCyclicCleanliness';
import ViewCyclicCleanliness from './Container/CampusMaintenance/ViewCyclicCleanliness';
import PrintCyclicCleanlines from './Container/CampusMaintenance/PrintCyclicCleanlines';
import AddEmergencyMaintenance from './Container/CampusMaintenance/AddEmergencyMaintenance';
import ViewEmergencyMaintenance from './Container/CampusMaintenance/ViewEmergencyMaintenance';
import PrintEmergencyMaintenance from './Container/CampusMaintenance/PrintEmergencyMaintenance';
import NotificationEmergencyMaintenance from './Container/CampusMaintenance/NotificationEmergencyMaintenance';
import CampusMaintenanceDashboard from './Container/CampusMaintenanceDashboard';
import AcademicDeanDashboard from './Container/AcademicDeanDashboard';
import AddDean from './Container/AcademicHead/addDean';
import ViewDean from './Container/AcademicHead/ViewDean';
import EnglishDeanDashboard from './Container/EnglishDeanDashboard';
import ViewEnglishCoordinatior from './Container/AcademicHead/ViewEnglishCoordinatior';
import EnglishHODDashboard from './Container/EnglishHODDashboard';
import ArabicHODDashboard from './Container/ArabicHODDashboard';
import EnrolledStaffInHOD from './Container/AcademicHead/EnrolledStaffInHOD';
import AcademicFormViews from './Container/AcademicHead/AcademicFormViews';
import HODDescriptiveView from './Container/HODFormViews/HODDescriptiveView';
import HODSkillsView from './Container/HODFormViews/HODSkillsView';
import HODSubjectDisciplineView from './Container/HODFormViews/HODSubjectDisciplineView';
import HODEvaluationFormView from './Container/HODFormViews/HODEvaluationFormView';
import HODClassDisciplineView from './Container/HODFormViews/HODClassDisciplineView';
import HODSubjectDisciplineEdit from './Container/HODFormViews/HODSubjectDisciplineEdit';
import CoordinatorsDashboard from './Container/DeanDashboards/CoordinatorsDashboard';

//Campus Maintenance End

const Dashboard = lazy(() => import('./Container/Dashboard'));

// end staff imports

// import from './Container/Transport/'
// TODO apply code spliting over all component
class Routes extends React.Component {
	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
		setTimeout(function() {
			window.location.reload();
		}, 700);
	};
	myFunction = () => {
		var x = document.getElementById('snackbar');

		// Add the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};
	stringConversion = input => {
		return [...input.match(/[A-Z][a-z]+/g)].join(' ');
	};
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	render() {
		return (
			<Layout>
				<Switch>
					<Route
						exact
						component={() => (
							<Suspense fallback={<div class="spinner-border text-primary" />}>
								<Dashboard {...this.props} />
							</Suspense>
						)}
						path="/dashboard"
					/>
					<Route
						render={props => <AddAdmin {...props} snackbar={this.myFunction} />}
						path="/dashboard/AddAdmin"
					/>
					<Route
						render={props => (
							<ViewAdmin {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewAdmin"
					/>
					<Route
						render={props => (
							<AcademicLMS {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AcademicLMS"
					/>

					<Route
						render={props => (
							<AcademicCMS {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AcademicCMS"
					/>

					<Route
						render={props => (
							<CADashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CADashboard"
					/>

					<Route
						render={props => (
							<AcademicSID {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AcademicSID"
					/>
					<Route
						render={props => (
							<AcademicSID {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AcademicSID"
					/>
					<Route
						component={AddCampus}
						path="/dashboard/CampusDashboard/AddCampus"
					/>
					<Route
						render={props => (
							<ViewCampus {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CampusDashboard/ViewCampus"
					/>
					<Route component={AddCategory} path="/dashboard/AddCategory" />
					<Route
						render={props => (
							<ViewCategory {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewCategory"
					/>
					<Route
						render={props => (
							<ViewItems {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewItems"
					/>
					<Route component={AddItems} path="/dashboard/AddItems" />
					<Route component={ClassSection} path="/dashboard/SearchStudent" />
					<Route
						component={ClassWiseSearch}
						path="/dashboard/ClassWiseSearch"
					/>
					<Route component={ViewAdmin} path="/dashboard/ViewAdmin" />
					<Route component={GM} path="/dashboard/AddGM" />
					<Route
						render={props => (
							<AdmissionForm {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddForm"
					/>
					<Route
						render={props => <AddGm {...props} snackbar={this.myFunction} />}
						path="/dashboard/AddGm"
					/>
					<Route component={AdmissionForm} path="/dashboard/AddForm" />
					<Route component={HrDashboard} path="/dashboard/HrDashboard" />
					<Route
						component={CanteenDashboard}
						path="/dashboard/CanteenDashboard"
					/>
					<Route
						render={props => (
							<ViewHrDevelopment {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewHrDevelopment"
					/>
					<Route
						component={props => (
							<Salary {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/Appraisal"
					/>
					<Route component={AddDepartment} path="/dashboard/AddDepartment" />
					<Route
						render={props => (
							<ViewDepartment {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewDepartment"
					/>
					<Route component={AddPost} path="/dashboard/AddPost" />
					<Route component={StudentEnquiry} path="/dashboard/StudentEnquiry" />
					<Route component={StaffEnquiry} path="/dashboard/StaffEnquiry" />
					<Route component={Vacancy} path="/dashboard/AddVacancy" />
					<Route component={AddInterview} path="/dashboard/AddInterview" />
					<Route component={ProgressBarComponent} path="/dashboard/Progress" />
					<Route
						component={CampusDashboard}
						path="/dashboard/CampusDashboard"
					/>
					<Route
						component={ViewCanteenItems}
						path="/dashboard/ViewCanteenItems"
					/>
					<Route
						component={TransportDashboard}
						path="/dashboard/TransportDashboard"
					/>
					<Route
						component={AddCanteenItems}
						path="/dashboard/AddCanteenItems"
					/>
					<Route
						component={ViewCanteenCategory}
						path="/dashboard/ViewCanteenCategory"
					/>
					<Route
						component={AddCanteenCategory}
						path="/dashboard/AddCanteenCategory"
					/>
					<Route
						component={HrDevelopmentAndTraining}
						path="/dashboard/HrDevelopmentAndTraining"
					/>
					<Route
						render={props => <ViewGm {...props} snackbar={this.myFunction} />}
						path="/dashboard/ViewGm"
					/>
					<Route component={ViewGm} path="/dashboard/ViewGm" />
					<Route component={AddSuppliers} path="/dashboard/AddSuppliers" />
					<Route component={ViewSuppliers} path="/dashboard/ViewSuppliers" />
					<Route component={AddStock} path="/dashboard/AddStock" />
					<Route component={AddStockUsage} path="/dashboard/AddStockUsage" />
					<Route component={ViewStockUsage} path="/dashboard/ViewStockUsage" />
					<Route component={ViewStock} path="/dashboard/ViewStock" />
					<Route component={AddCanteen} path="/dashboard/AddCanteen" />
					<Route component={AgeWiseSearch} path="/dashboard/AgeWiseSearch" />
					<Route
						component={CategoryWiseSearch}
						path="/dashboard/CategoryWiseSearch"
					/>
					<Route component={UserWiseSearch} path="/dashboard/UserWiseSearch" />
					<Route
						component={FamilyWiseSearch}
						path="/dashboard/FamilyWiseSearch"
					/>
					<Route
						component={StudentResultStatus}
						path="/dashboard/StudentResultStatus"
					/>
					<Route
						render={props => (
							<ViewCanteen {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewCanteen"
					/>
					<Route component={AddRoutes} path="/dashboard/AddRoutes" />
					<Route component={AddRoute} path="/dashboard/AddRoute" />
					<Route
						render={props => (
							<ViewRoutes {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewRoutes"
					/>
					<Route
						render={props => <ViewPost {...props} snackbar={this.myFunction} />}
						path="/dashboard/ViewPost"
					/>
					<Route
						render={props => (
							<InActivePage {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/inActivePage"
					/>
					<Route
						component={ViewStaffEnquiry}
						path="/dashboard/ViewStaffEnquiry/:id"
					/>
					<Route
						component={ViewStudentEnquiry}
						path="/dashboard/ViewStudentEnquiry/:id"
					/>
					<Route
						component={ViewAdmittedStudent}
						path="/dashboard/ViewAdmittedStudent"
					/>
					<Route
						component={DepartmentDashboard}
						path="/dashboard/DepartmentDashboard"
					/>
					<Route component={PostDashboard} path="/dashboard/PostDashboard" />
					<Route
						component={FrontDeskDashboard}
						path="/dashboard/FrontDeskDashboard"
					/>
					<Route
						component={BudgetDashboard}
						path="/dashboard/BudgetDashboard"
					/>
					<Route
						component={HrDevelopmentDashboard}
						path="/dashboard/HrDevelopmentDashboard"
					/>
					<Route
						component={ViewStudentEnquiryPagination}
						path="/dashboard/ViewStudentEnquiryPagination"
					/>
					<Route
						component={ViewAdmittedStaff}
						path="/dashboard/ViewAdmittedStaff"
					/>
					<Route component={AddVehicle} path="/dashboard/AddVehicle" />
					<Route component={ViewVehicle} path="/dashboard/ViewVehicle" />
					<Route component={AddDriver} path="/dashboard/AddDriver" />
					<Route
						component={StudentAdmissionDashboard}
						path="/dashboard/StudentAdmissionDashboard"
					/>
					<Route
						render={props => (
							<AddAccountLedger {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AccountLedger/AddAccountLedger"
					/>
					<Route
						render={props => (
							<AccountLedger {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AccountLedger"
					/>
					<Route component={AddBudget} path="/dashboard/AddBudget" />
					<Route component={ViewBudget} path="/dashboard/ViewBudget" />
					<Route
						component={ViewDriverHistory}
						path="/dashboard/ViewDriverHistory"
					/>
					<Route component={ViewDriver} path="/dashboard/ViewDriver" />
					<Route component={AssignVehicle} path="/dashboard/AssignVehicle" />
					<Route
						component={ViewAssignVehicle}
						path="/dashboard/ViewAssignVehicle"
					/>
					<Route
						component={CreateTransportFees}
						path="/dashboard/CreateTransportFees"
					/>
					<Route component={Check} path="/dashboard/Check" />
					<Route
						component={AdministrationDashboard}
						path="/dashboard/AdministrationDashboard"
					/>
					<Route
						component={InventoryDashboard}
						path="/dashboard/InventoryDashboard"
					/>
					<Route
						component={CensusDashboard}
						path="/dashboard/CensusDashboard"
					/>
					<Route
						component={FinanceDashboard}
						path="/dashboard/FinanceDashboard"
					/>
					<Route
						component={StaffAdmissionForm}
						path="/dashboard/StaffAdmissionForm"
					/>
					<Route
						component={PrintAdmissionForm}
						path="/dashboard/PrintAdmissionForm"
					/>
					<Route
						component={PrintAdmissionFormFinal}
						path="/dashboard/PrintAdmissionFormFinal/:id"
					/>
					<Route component={ViewAddForm} path="/dashboard/ViewAddForm" />
					<Route
						component={ViewStaffEnquiryPagination}
						path="/dashboard/ViewStaffEnquiryPagination"
					/>
					<Route
						component={PrintAdmissionForm}
						path="/dashboard/PrintForm/:id"
					/>
					<Route
						component={props => (
							<EditAddRecord
								btnTechHover={this.btnTechHover}
								{...props}
								stringConversion={this.stringConversion}
							/>
						)}
						path="/dashboard/EditAddRecord/:id"
					/>
					<Route
						component={EditAdmissionForm}
						path="/dashboard/EditAdmissionForm/:sectionType"
					/>
					<Route component={Check} path="/dashboard/Check" />
					<Route component={CensusManager} path="/dashboard/CensusManager" />
					<Route
						component={ViewCensusManager}
						path="/dashboard/ViewCensusManager"
					/>
					<Route
						component={AdministrationManagers}
						path="/dashboard/AdministrationManagers"
					/>
					<Route
						render={props => (
							<UploadedFilesView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/UploadFilesView/:id"
					/>
					<Route
						component={HrDevelopmentAndTraining}
						path="/dashboard/HrDevelopmentAndTraining"
					/>
					<Route
						render={props => <ViewGm {...props} snackbar={this.myFunction} />}
						path="/dashboard/ViewGm"
					/>
					<Route component={ViewGm} path="/dashboard/ViewGm" />
					<Route component={AddSuppliers} path="/dashboard/AddSuppliers" />
					<Route component={ViewSuppliers} path="/dashboard/ViewSuppliers" />
					<Route component={AddStock} path="/dashboard/AddStock" />
					<Route component={AddStockUsage} path="/dashboard/AddStockUsage" />
					<Route component={ViewStockUsage} path="/dashboard/ViewStockUsage" />
					<Route component={ViewStock} path="/dashboard/ViewStock" />
					<Route component={AddCanteen} path="/dashboard/AddCanteen" />
					<Route component={AgeWiseSearch} path="/dashboard/AgeWiseSearch" />
					<Route
						component={CategoryWiseSearch}
						path="/dashboard/CategoryWiseSearch"
					/>
					<Route component={UserWiseSearch} path="/dashboard/UserWiseSearch" />
					<Route
						component={FamilyWiseSearch}
						path="/dashboard/FamilyWiseSearch"
					/>
					<Route
						component={StudentResultStatus}
						path="/dashboard/StudentResultStatus"
					/>
					<Route
						render={props => (
							<ViewCanteen {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewCanteen"
					/>
					<Route component={AddRoutes} path="/dashboard/AddRoutes" />
					<Route component={AddRoute} path="/dashboard/AddRoute" />
					<Route
						render={props => (
							<ViewRoutes {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewRoutes"
					/>
					<Route
						render={props => <ViewPost {...props} snackbar={this.myFunction} />}
						path="/dashboard/ViewPost"
					/>
					<Route
						component={ViewStaffEnquiry}
						path="/dashboard/ViewStaffEnquiry/:id"
					/>
					<Route
						component={ViewStudentEnquiry}
						path="/dashboard/ViewStudentEnquiry/:id"
					/>
					<Route
						component={ViewAdmittedStudent}
						path="/dashboard/ViewAdmittedStudent"
					/>
					<Route
						component={ViewStudentEnquiryPagination}
						path="/dashboard/ViewStudentEnquiryPagination"
					/>
					<Route
						component={ViewAdmittedStaff}
						path="/dashboard/ViewAdmittedStaff"
					/>
					<Route component={AddVehicle} path="/dashboard/AddVehicle" />
					<Route component={ViewVehicle} path="/dashboard/ViewVehicle" />
					<Route component={AddDriver} path="/dashboard/AddDriver" />
					<Route
						render={props => (
							<AddAccountLedger {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AccountLedger/AddAccountLedger"
					/>
					<Route
						render={props => (
							<AccountLedger {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AccountLedger"
					/>
					<Route component={AddBudget} path="/dashboard/AddBudget" />
					<Route component={ViewBudget} path="/dashboard/ViewBudget" />
					<Route
						render={props => <BudgetScreen {...props} />}
						path="/dashboard/BudgetScreen"
					/>
					<Route
						component={ViewDriverHistory}
						path="/dashboard/ViewDriverHistory"
					/>
					<Route component={ViewDriver} path="/dashboard/ViewDriver" />
					<Route component={AssignVehicle} path="/dashboard/AssignVehicle" />
					<Route
						component={ViewAssignVehicle}
						path="/dashboard/ViewAssignVehicle"
					/>
					<Route component={StaffMainScreen} path="/dashboard/StaffModule" />
					<Route
						component={CreateTransportFees}
						path="/dashboard/CreateTransportFees"
					/>
					<Route
						component={AdministrationDashboard}
						path="/dashboard/AdministrationDashboard"
					/>
					<Route
						component={PrintStaffAdmissionForm}
						path="/dashboard/PrintStaffAdmissionForm/:id"
					/>
					<Route
						component={FinanceDashboard}
						path="/dashboard/FinanceDashboard"
					/>
					<Route
						component={StaffAdmissionForm}
						path="/dashboard/StaffAdmissionForm"
					/>
					<Route
						component={PrintAdmissionForm}
						path="/dashboard/PrintAdmissionForm"
					/>
					<Route
						component={PrintAdmissionFormFinal}
						path="/dashboard/PrintAdmissionFormFinal/:id"
					/>
					<Route component={ViewAddForm} path="/dashboard/ViewAddForm" />
					<Route
						component={ViewStaffEnquiryPagination}
						path="/dashboard/ViewStaffEnquiryPagination"
					/>
					<Route
						component={PrintAdmissionForm}
						path="/dashboard/PrintForm/:id"
					/>
					<Route
						component={props => (
							<EditAddRecord
								btnTechHover={this.btnTechHover}
								{...props}
								stringConversion={this.stringConversion}
							/>
						)}
						path="/dashboard/EditAddRecord/:id"
					/>
					<Route
						component={EditAdmissionForm}
						path="/dashboard/EditAdmissionForm/:sectionType"
					/>
					<Route component={Check} path="/dashboard/Check" />
					<Route component={CensusManager} path="/dashboard/CensusManager" />
					<Route
						component={ViewCensusManager}
						path="/dashboard/ViewCensusManager"
					/>
					<Route
						component={AdministrationManagers}
						path="/dashboard/AdministrationManagers"
					/>
					<Route
						component={UploadedFilesView}
						path="/dashboard/UploadFilesView/:id"
					/>
					{/* Staff  */}
					<Route
						render={props => (
							<StaffImageUploadSection {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/StaffImageUpload/:urlId/:type"
					/>
					<Route
						render={props => <Staff {...props} snackbar={this.myFunction} />}
						path="/dashboard/Staff"
					/>
					{/* <Route
						render={props => (
							<StaffDocumentUploadSection
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/StaffDocumentsUpload/:id"
					/> */}
					<Route
						render={props => (
							<DocumentSectionStep {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/StaffDocumentSection/:id"
					/>
					<Route
						render={props => (
							<StaffDocumentUploadSection
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/DocumentsSectionForStaff/:paramsId"
					/>

					<Route
						render={props => (
							<StaffDocumentUploadSection
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/DocumentsSectionForStaff/:paramsId"
					/>

					<Route
						render={props => (
							<StaffEditStepsRendering
								stringConversion={this.stringConversion}
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/StaffEdit/:id"
					/>
					{/* end Staff */}
					{/* Appraisal */}
					<Route
						render={props => (
							<ViewAppraisal {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewAppraisal"
					/>
					<Route
						render={props => (
							<NotificationAppraisal {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AppraisalNotification"
					/>
					{/* End Apprails */}
					{/* Time-Table */}
					<Route
						component={props => (
							<LessonPerWeek {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/Lessonperweek"
					/>
					<Route
						component={props => (
							<LessonPerWeekScreen {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/LessonperweekScreen"
					/>
					<Route
						render={props => (
							<ViewLessonWeek {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/Viewlessonperweek"
					/>
					<Route
						render={props => (
							<AcademicTimeTableModule {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/TimeTableModule"
					/>
					<Route
						render={props => (
							<TimeTableScreen {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/TimeTableScreen"
					/>
					<Route
						render={props => (
							<TimeTable {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/timeTable"
					/>
					<Route
						render={props => (
							<StaffView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/viewStaff"
					/>
					<Route
						render={props => (
							<StaffFileUploadView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/staffUploadFile/:id/:type"
					/>
					<Route
						render={props => (
							<EditStaffFrom {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/EditStaffFrom/:sectionType"
					/>
					<Route
						render={props => (
							<TimeTableView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/viewtimeTable"
					/>
					{/* end of time table */}
					<Route component={AddAssignment} path="/dashboard/AddAssignment" />
					<Route
						component={ViewParticularStudent}
						path="/dashboard/ViewParticularStudent"
					/>
					<Route
						render={props => (
							<ViewAssignment {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewAssignment"
					/>
					{/* end of time table */}
					<Route component={AddHomeWork} path="/dashboard/AddHomeWork" />
					<Route
						render={props => (
							<ViewHomeWork {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewHomeWork"
					/>
					{/* end of time table */}
					<Route component={AddLessonPlan} path="/dashboard/AddLessonPlan" />
					<Route
						render={props => (
							<ViewLessonPlan {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewLessonPlan"
					/>
					<Route
						component={SearchStaffByDepartment}
						path="/dashboard/SearchStaffByDepartment"
					/>
					<Route
						component={SearchStaffByPost}
						path="/dashboard/SearchStaffByPost"
					/>
					<Route
						component={AcademicsDashboard}
						path="/dashboard/AcademicsDashboard"
					/>
					<Route
						component={HomeWorkDashboard}
						path="/dashboard/HomeWorkDashboard"
					/>
					<Route
						component={AssignmentDashboard}
						path="/dashboard/AssignmentDashboard"
					/>
					<Route
						component={LessonPlanDashboard}
						path="/dashboard/LessonPlanDashboard"
					/>
					<Route
						component={EditHrDevelopment}
						path="/dashboard/EditHrDevelopment"
					/>
					<Route component={AddBudgetItems} path="/dashboard/AddBudgetItems" />
					<Route
						component={ViewBudgetItems}
						path="/dashboard/ViewBudgetItems"
					/>
					<Route
						component={SearchItemsBySession}
						path="/dashboard/SearchItemsBySession"
					/>
					<Route component={AddQuiz} path="/dashboard/AddQuiz" />
					<Route component={ViewQuiz} path="/dashboard/ViewQuiz" />
					<Route component={AddQuizInfo} path="/dashboard/AddQuizInfo" />
					<Route component={ViewQuizInfo} path="/dashboard/ViewQuizInfo" />
					<Route component={AddTestInfo} path="/dashboard/AddTestInfo" />
					<Route
						render={props => (
							<ViewTestInfo {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewTestInfo"
					/>
					<Route component={QuizDashboard} path="/dashboard/QuizDashboard" />
					<Route component={TestDashboard} path="/dashboard/TestDashboard" />
					<Route component={AddTest} path="/dashboard/AddTest" />
					<Route component={ViewTest} path="/dashboard/ViewTest" />
					<Route component={AddExam} path="/dashboard/AddExam" />
					<Route component={AddExamDetails} path="/dashboard/AddExamDetails" />
					<Route
						component={ViewExamDetails}
						path="/dashboard/ViewExamDetails"
					/>
					<Route component={AddExamInfo} path="/dashboard/AddExamInfo" />
					<Route component={ViewExamInfo} path="/dashboard/ViewExamInfo" />
					<Route component={ExamDashboard} path="/dashboard/ExamDashboard" />
					<Route component={AddPastPapers} path="/dashboard/AddPastPapers" />
					<Route component={ViewPastPapers} path="/dashboard/ViewPastPapers" />
					<Route component={AddSyllabus} path="/dashboard/AddSyllabus" />
					<Route
						component={EditorComponent}
						path="/dashboard/EditorComponent"
					/>
					<Route component={ViewEditorComponent} path="/dashboard/ViewEditor" />
					<Route component={ViewSyllabus} path="/dashboard/ViewSyllabus" />
					<Route
						component={ViewSyllabusTemplate}
						path="/dashboard/ViewSyllabusTemplate/:id"
					/>
					<Route
						component={ViewAllSyllabus}
						path="/dashboard/ViewAllSyllabus"
					/>
					<Route
						component={SyllabusDashboard}
						path="/dashboard/SyllabusDashboard"
					/>
					<Route
						component={PastPaperDashboard}
						path="/dashboard/PastPaperDashboard"
					/>
					<Route component={StudentSkill} path="/dashboard/StudentSkill" />
					<Route
						component={ViewStudentSkills}
						path="/dashboard/ViewStudentSkills"
					/>
					<Route
						component={PrintAllStudentSkills}
						path="/dashboard/PrintAllStudentSkills"
					/>
					<Route
						component={ViewStudentSkillList}
						path="/dashboard/ViewStudentSkillList"
					/>
					<Route
						component={PrintSkillReport}
						path="/dashboard/PrintSkillReport/:id/:class/:section/:term/:session"
					/>
					<Route
						component={StudentSkillDashboard}
						path="/dashboard/StudentSkillDashboard"
					/>
					<Route
						component={DescriptiveReport}
						path="/dashboard/DescriptiveReport"
					/>
					<Route
						render={props => (
							<ViewCampus {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CampusDashboard/ViewCampus"
					/>
					<Route
						render={props => (
							<EditDescriptiveReport {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/EditDescriptiveReport"
					/>
					<Route
						component={PrintDescriptiveReport}
						path="/dashboard/PrintDescriptiveReport/:id/:class/:section/:term/:session"
					/>
					<Route
						component={StudentSkillView}
						path="/dashboard/StudentSkillView"
					/>
					<Route
						component={DiscriptiveDashboard}
						path="/dashboard/DescriptiveDashboard"
					/>
					<Route
						component={StudentDescriptiveView}
						path="/dashboard/StudentDescriptiveView"
					/>
					{/*  Student Discipline Record Module */}
					<Route
						path="/dashboard/EditStudentDisciplineRecord"
						render={props => (
							<EditStudentDisciplineRecord
								{...props}
								snackbar={this.myFunction}
							/>
						)}
					/>
					<Route
						render={props => (
							<StudentDisciplineRecord {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/StudentDisciplineRecord"
					/>
					<Route
						path="/dashboard/ViewStudentDiscipline/:classId/:section/:session/:term"
						render={props => (
							<StudentDisciplineReports {...props} snackbar={this.myFunction} />
						)}
					/>
					<Route
						render={props => (
							<ViewStudentDiscipline {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewStudentDiscipline"
					/>
					<Route
						render={props => (
							<SubjectDisciplineMarksReport
								{...props}
								snackbar={this.myFunction}
								printFunc={this.PrintContent}
							/>
						)}
						path="/dashboard/SubjectDisciplineMarks/:studentId/:classId/:section/:session/:term"
					/>
					<Route
						render={props => (
							<SubjectDisciplineMarks {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/SubjectDisciplineMarks"
					/>
					{/* End of Student Discipline Staff Module */}
					{/* Start of Role : Student Discipline */}
					<Route
						render={props => (
							<SubjectDisciplineMarksReport
								{...props}
								snackbar={this.myFunction}
								printFunc={this.PrintContent}
							/>
						)}
						path="/dashboard/StudentRoleDiscipline/:studentId/:classId/:section/:session/:term"
					/>
					<Route
						render={props => (
							<StudentRoleDiscipline {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/StudentRoleDiscipline"
					/>
					<Route
						render={props => (
							<SubjectDisciplineMarksReport
								{...props}
								snackbar={this.myFunction}
								printFunc={this.PrintContent}
							/>
						)}
						path="/dashboard/ParentStudentDiscipline/:studentId/:classId/:section/:session/:term"
					/>
					<Route
						render={props => (
							<ParentStudentDiscipline {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ParentStudentDiscipline"
					/>
					{/* End of Role : STudent Discipline */}
					{/* Start of Role: Admin => Admin Student Discipline */}
					<Route
						render={props => (
							<EditAdminDiscipline {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AdminRoleStudentDiscipline/:studentId/:classId/:section/:session/:term/:subjectId"
					/>
					<Route
						render={props => (
							<SubjectDisciplineMarksReport
								{...props}
								snackbar={this.myFunction}
								printFunc={this.PrintContent}
							/>
						)}
						path="/dashboard/AdminRoleStudentDiscipline/:studentId/:classId/:section/:session/:term"
					/>
					<Route
						render={props => (
							<AdminStudentDiscipline {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AdminRoleStudentDiscipline"
					/>
					{/* End of Role: Admin => Admin_Student_Discipline */}

					{/* Start of Continouse Assesments Routes */}
					<Route
						render={props => (
							<ContinousEditAssignMarksModule
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/CAEditAssignMarks/:stId/:pId"
					/>

					<Route
						render={props => (
							<AssignMarks {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CA_ASSIGN_MAKRS/:id/:type"
					/>

					<Route
						render={props => (
							<ContinousAssesmentReport {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ContinouseAssesmentViewList/:pid"
					/>

					<Route
						render={props => (
							<ContinouseAssesmentViewList
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/ContinouseAssesmentViewList"
					/>
					<Route
						render={props => (
							<PrimaryInfo {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CAPrimaryInfo"
					/>
					<Route
						render={props => (
							<PrimaryInfoDelete {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CAPrimaryInfoView/:id"
					/>

					<Route
						render={props => (
							<PrimaryInfoView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CAPrimaryInfoView"
					/>

					<Route
						render={props => (
							<ContnuousRenderTools {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CASubToolView/:id/:type"
					/>

					<Route
						render={props => (
							<ContinuousSubToolView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CASubToolView"
					/>

					{/* Primary Info Routes */}

					{/* End of Primary Info */}

					{/* End of Continouse Assesment Routes */}

					<Route
						component={StudentSkillViewAdmin}
						path="/dashboard/StudentSkillViewAdmin"
					/>
					<Route
						component={StudentSkillViewP}
						path="/dashboard/StudentSkillViewP"
					/>
					<Route
						component={StudentDescriptiveViewAdmin}
						path="/dashboard/StudentDescriptiveViewAdmin"
					/>
					<Route
						component={StudentDescriptiveViewP}
						path="/dashboard/StudentDescriptiveViewP"
					/>
					{/* Start of Role : Class Discipline For Staff */}
					<Route
						render={props => (
							<ClassDiscipline {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ClassDiscipline"
					/>
					<Route
						render={props => (
							<AddClassDiscipline {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddClassDiscipline"
					/>
					<Route
						render={props => (
							<ViewClassDiscipline {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewClassDiscipline"
					/>
					<Route
						render={props => (
							<ViewClassDisciplinaryRecords
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/ViewClassDisciplinaryRecords"
					/>
					<Route
						render={props => (
							<Sheet1
								{...props}
								snackbar={this.myFunction}
								printFunc={this.PrintContent}
							/>
						)}
						path="/dashboard/ClassDisciplineReport"
					/>
					{/* End of Role : Class Discipline For Staff */}
					{/* Start of Role : Class Discipline For Admin/Owner */}
					<Route
						render={props => <ClassDisciplineDashboard {...props} />}
						path="/dashboard/ClassDisciplineDashboard"
					/>
					<Route
						render={props => (
							<UpdateClassDiscipline {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/UpdateClassDiscipline"
					/>
					{/* End of Role : Class Discipline For Admin/Owner */}
					<Route
						render={props => (
							<AddParent {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddParent"
					/>
					<Route
						component={AlreadyRegister}
						path="/dashboard/UpdateParentRegistration"
					/>
					<Route
						component={SearchParentByChild}
						path="/dashboard/SearchParentByChild"
					/>
					<Route
						component={RegisterStudentToParent}
						path="/dashboard/RegisterStudentToParent"
					/>

					{/* Student Admission version-v2 imports */}
					<Route
						render={props => (
							<StudentImageUpload {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/StudentImageUpload/:urlId/:type"
					/>

					<Route
						render={props => (
							<StudentDocsUpload {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/StudentDocsUpload/:urlId/:type"
					/>

					<Route
						render={props => (
							<ParentDocsUpload {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ParentDocsUpload/:urlId/:type"
					/>

					{/* End of Admission version-v2 imports ends */}
					{/* Dashboards for communication */}
					<Route
						component={CommunicationDashboard}
						path="/dashboard/CommunicationDashboard"
					/>
					<Route
						component={ParentPortalManagerDashboard}
						path="/dashboard/ParentPortalManagerDashboard"
					/>
					{/* End Dashboards For Communication */}

					{/* Dashboards for communication */}
					<Route
						render={props => (
							<StudentAttendance {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/StudentAttendance"
					/>
					<Route
						render={props => (
							<ViewStudentAttendance {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewStudentAttendance"
					/>
					<Route
						render={props => (
							<MonthlyAttendanceView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/MonthlyAttendanceView"
					/>
					<Route
						render={props => (
							<RangeAttendanceView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/RangeAttendanceView"
					/>
					{/* End Dashboards For Communication */}
					{/* Admission Manager CRUD Start  */}
					<Route
						render={props => (
							<AddManager {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddManager"
					/>
					<Route
						render={props => (
							<ViewManager {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewManager"
					/>
					<Route
						component={ManagersDashboard}
						path="/dashboard/ManagersDashboard"
					/>
					{/* Admission Manager CRUD END  */}
					<Route
						component={AdmissionDashboard}
						path="/dashboard/AdmissionDashboard"
					/>
					<Route
						component={StudentAttendanceDashboard}
						path="/dashboard/StudentAttendanceDashboard"
					/>
					{/* Fees Finance Module */}
					<Route
						render={props => (
							<AddFeeType {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddFeeType"
					/>
					<Route
						render={props => (
							<ViewFeeType {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewFeeType"
					/>
					<Route
						render={props => (
							<FeesCollection {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/FeesCollection"
					/>
					<Route
						render={props => (
							<MonthlyFeeList {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/MonthlyFeeList"
					/>
					<Route
						render={props => (
							<MonthlyFeeListNew {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/MonthlyFeeListNew"
					/>
					<Route
						render={props => (
							<StudentAttendanceView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/StudentFeeView"
					/>
					<Route
						render={props => (
							<AccountsDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AccountsDashboard"
					/>
					<Route
						render={props => (
							<FeesDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/FeesDashboard"
					/>
					<Route
						render={props => (
							<StudentFeeHistoryP {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/StudentFeeHistoryP"
					/>
					<Route
						render={props => (
							<FeesCollectionNew {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/FeesCollectionNew"
					/>
					<Route
						render={props => (
							<FeeReceipt {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/FeeReceipt/:id"
					/>
					<Route
						render={props => (
							<AddBilling {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddBilling"
					/>
					<Route
						render={props => (
							<ViewBilling {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewBilling"
					/>
					<Route
						render={props => (
							<BillingTemplate {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/BillingTemplate/:id"
					/>
					<Route
						render={props => (
							<AddFeeTypeNew {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddFeeTypeNew"
					/>
					<Route
						render={props => (
							<ViewFeeTypeNew {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewFeeTypeNew"
					/>
					<Route
						render={props => (
							<IncomeSearch {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/IncomeSearch"
					/>
					<Route
						render={props => (
							<AddFinanceManager {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddFinanceManager"
					/>
					<Route
						render={props => (
							<ViewFinanceManager {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewFinanceManager"
					/>
					<Route
						render={props => (
							<FinanceManagerDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/FinanceManagerDashboard"
					/>
					<Route
						render={props => (
							<BillingDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/BillingDashboard"
					/>
					{/* End of Fees Finance Module */}

					{/* Pay Roll Module Start */}
					<Route
						render={props => (
							<AddPayroll {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddPayroll"
					/>
					<Route
						render={props => (
							<ViewPayroll {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewPayroll"
					/>
					<Route
						render={props => (
							<ViewPayslip {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewPayslip"
					/>
					<Route
						render={props => (
							<PayslipDetails {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/PayslipDetails"
					/>
					<Route
						render={props => (
							<PayRollDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/PayRollDashboard"
					/>
					{/*End  Pay Roll Module Start */}
					{/* Library Module */}
					<Route
						render={props => <AddBook {...props} snackbar={this.myFunction} />}
						path="/dashboard/AddBook"
					/>
					<Route
						render={props => <ViewBook {...props} snackbar={this.myFunction} />}
						path="/dashboard/ViewBook"
					/>
					<Route
						render={props => (
							<IssueBook {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/IssueBook"
					/>
					<Route
						render={props => (
							<ViewIssuedBook {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewIssuedBook"
					/>
					<Route
						render={props => (
							<LibraryDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/LibraryDashboard"
					/>
					{/* End of Library Module */}
					{/* Start of Leave */}
					<Route
						render={props => <AddLeave {...props} snackbar={this.myFunction} />}
						path="/dashboard/AddLeave"
					/>

					<Route
						render={props => (
							<ViewLeave {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewLeave"
					/>

					<Route
						render={props => (
							<ViewLeaveByStudent {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewLeaveByStudent"
					/>

					{/* End of Leave */}

					{/* Start Assign Subject to Teacher */}
					<Route
						render={props => (
							<AssignSubject {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AssignSubject"
					/>

					<Route
						render={props => (
							<ViewAssignSubject {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewAssignSubject"
					/>

					{/* End Assign Subject to Teacher */}

					{/* Start Indent*/}

					{/* 					<Route
						render={props => (
							<ViewBillingTemplate {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewBillingTemplate"
					/>
 */}
					<Route
						render={props => (
							<IndentFormTemplate {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/IndentFormTemplate/:id"
					/>

					<Route
						render={props => (
							<AddIndent {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddIndent"
					/>

					<Route
						render={props => (
							<ViewIndentForm {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewIndentForm"
					/>

					<Route
						render={props => (
							<ViewIndentByStaff {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewIndentByStaff"
					/>

					{/* End Indent */}

					{/* Start Teacher Observation */}
					<Route
						render={props => (
							<TeacherObservationBlankTemplate
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/TeacherObservationBlankTemplate/:id"
					/>
					<Route
						render={props => (
							<AddTeacherEvaluation {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddTeacherEvaluation"
					/>
					<Route
						render={props => (
							<ViewTeacherEvaluation {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewTeacherEvaluation"
					/>

					<Route
						render={props => (
							<EditEvaluationForm {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/EditEvaluationForm/:id"
					/>

					<Route
						render={props => (
							<ViewEvaluationByStaff {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewEvaluationByStaff"
					/>

					<Route
						render={props => (
							<EvaluationByStaffTemplate
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/EvaluationByStaffTemplate/:id"
					/>

					{/* End Teacher Observation */}

					{/* Start Generate Visitor Card */}
					<Route
						render={props => (
							<GenerateVisitorCardView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/GenerateVisitorCardView"
					/>

					<Route
						render={props => (
							<AddVisitorEntry {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddVisitorEntry"
					/>

					<Route
						render={props => (
							<VisitorEntryRecord {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/VisitorEntryRecord"
					/>

					{/* End Generate Visitor Card */}

					{/* Start Medical Centre module */}
					<Route
						render={props => (
							<AddDoctor {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddDoctor"
					/>

					<Route
						render={props => (
							<ViewDoctor {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewDoctor"
					/>

					<Route
						render={props => (
							<AddPatient {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddPatient"
					/>

					<Route
						render={props => (
							<AddPatient2 {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddPatient2"
					/>

					<Route
						render={props => (
							<PatientDetail {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/PatientDetail"
					/>

					<Route
						render={props => (
							<MedicalSlipTemplate {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/MedicalSlipTemplate/:id/:post"
					/>
					{/* End Medical Centre module */}

					<Route
						render={props => (
							<MedicalHistory {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/MedicalHistory"
					/>

					{/* Student Progress Module */}
					<Route
						render={props => (
							<AddStudentProgressReport {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddStudentProgressReport"
					/>
					<Route
						render={props => (
							<ViewStudentProgressReport
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/ViewStudentProgressReport"
					/>
					<Route
						render={props => (
							<PrintStudentProgressReport
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/PrintStudentProgressReport"
					/>
					<Route
						render={props => (
							<StudentProgressReportDashboard
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/StudentProgressReportDashboard"
					/>
					{/* End of Student Progress Module */}
					{/* Start of Campus Maintenance */}

					<Route
						render={props => <AddFloor {...props} snackbar={this.myFunction} />}
						path="/dashboard/AddFloor"
					/>
					<Route
						render={props => (
							<ViewFloor {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewFloor"
					/>
					<Route
						render={props => <AddRoom {...props} snackbar={this.myFunction} />}
						path="/dashboard/AddRoom"
					/>
					<Route
						render={props => <ViewRoom {...props} snackbar={this.myFunction} />}
						path="/dashboard/ViewRoom"
					/>
					<Route
						render={props => (
							<AddCleanlinessCategory {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddCleanlinessCategory"
					/>
					<Route
						render={props => (
							<ViewCleanlinessCategory {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewCleanlinessCategory"
					/>
					<Route
						render={props => (
							<AddCyclicCleanliness {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddCyclicCleanliness"
					/>
					<Route
						render={props => (
							<ViewCyclicCleanliness {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewCyclicCleanliness"
					/>
					<Route
						render={props => (
							<PrintCyclicCleanlines {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/PrintCyclicCleanliness"
					/>
					<Route
						render={props => (
							<AddEmergencyMaintenance {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddEmergencyMaintenance"
					/>
					<Route
						render={props => (
							<ViewEmergencyMaintenance {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewEmergencyMaintenance"
					/>
					<Route
						render={props => (
							<PrintEmergencyMaintenance
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/PrintEmergencyMaintenance"
					/>

					<Route
						render={props => (
							<NotificationEmergencyMaintenance
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/NotificationEmergencyMaintenance"
					/>
					<Route
						render={props => (
							<CampusMaintenanceDashboard
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/CampusMaintenanceDashboard"
					/>

					{/* End of Campus Maintenance*/}
					{/* Dashboards */}
					<Route
						render={props => (
							<ClassDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ClassDashboard"
					/>
					<Route
						render={props => (
							<SectionDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/SectionDashboard"
					/>
					<Route
						render={props => (
							<SessionDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/SessionDashboard"
					/>
					<Route
						render={props => (
							<SubjectDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/SubjectDashboard"
					/>
					<Route
						render={props => (
							<ClassTeacherDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ClassTeacherDashboard"
					/>
					<Route
						render={props => (
							<IndentDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/IndentDashboard"
					/>

					<Route
						render={props => (
							<IndentStaffDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/IndentStaffDashboard"
					/>
					<Route
						render={props => (
							<TeacherEvaluationDashboard
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/TeacherEvaluationDashboard"
					/>

					<Route
						render={props => (
							<PastoralDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/PastoralDashboard"
					/>
					<Route
						render={props => (
							<MedicalDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/MedicalDashboard"
					/>

					<Route
						render={props => (
							<DoctorDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/DoctorDashboard"
					/>

					<Route
						render={props => (
							<PatientDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/PatientDashboard"
					/>

					<Route
						render={props => (
							<CampusSecurityDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CampusSecurityDashboard"
					/>

					<Route
						render={props => (
							<LeaveDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/LeaveDashboard"
					/>

					<Route
						render={props => (
							<StudentLeaveDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/StudentLeaveDashboard"
					/>

					<Route
						render={props => (
							<TourDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/TourDashboard"
					/>

					<Route
						render={props => (
							<ActivityDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ActivityDashboard"
					/>

					<Route
						render={props => (
							<CertificateDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CertificateDashboard"
					/>

					<Route
						render={props => (
							<OtherCertificateDashboard
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/OtherCertificateDashboard"
					/>

					<Route
						render={props => (
							<AwardDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AwardDashboard"
					/>

					<Route
						render={props => (
							<CertificateCategoryDashboard
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/CertificateCategoryDashboard"
					/>

					<Route
						render={props => (
							<EventDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/EventDashboard"
					/>

					<Route
						render={props => (
							<GameDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/GameDashboard"
					/>

					<Route
						render={props => (
							<OffGameDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/OffGameDashboard"
					/>
					{/* Dashboards */}

					{/* Start Tour Module */}

					<Route
						render={props => <AddTour {...props} snackbar={this.myFunction} />}
						path="/dashboard/AddTour"
					/>

					<Route
						render={props => <ViewTour {...props} snackbar={this.myFunction} />}
						path="/dashboard/ViewTour"
					/>

					<Route
						render={props => (
							<ViewClassesInTour {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewClassesInTour/:id"
					/>

					<Route
						render={props => (
							<ViewFilterTour {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewFilterTour"
					/>

					<Route
						render={props => (
							<ViewTourByStaff {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewTourByStaff"
					/>

					<Route
						render={props => (
							<ViewTourByStudent {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewTourByStudent"
					/>

					{/* End Tour Module*/}

					{/* Certificate Module*/}

					<Route
						render={props => (
							<AddOtherCertificate {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddOtherCertificate"
					/>

					<Route
						render={props => (
							<ViewOtherCertificate {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewOtherCertificate"
					/>

					<Route
						render={props => (
							<OtherCertificateTemplate1
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/OtherCertificateTemplate1/:id"
					/>

					<Route
						render={props => (
							<OtherCertificateTemplate2
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/OtherCertificateTemplate2/:id"
					/>

					<Route
						render={props => (
							<AddCertificateCategory {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddCertificateCategory"
					/>

					<Route
						render={props => (
							<ViewCertificateCategory {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewCertificateCategory"
					/>

					<Route
						render={props => (
							<OtherCertificateCategoryTemplate1
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/OtherCertificateCategoryTemplate1/:id"
					/>

					<Route
						render={props => (
							<OtherCertificateCategoryTemplate2
								{...props}
								snackbar={this.myFunction}
							/>
						)}
						path="/dashboard/OtherCertificateCategoryTemplate2/:id"
					/>

					{/* End Certificate Module*/}

					{/* Award Module*/}

					<Route
						render={props => (
							<AddAwardCategory {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddAwardCategory"
					/>

					<Route
						render={props => (
							<ViewAwardCategory {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewAwardCategory"
					/>

					<Route
						render={props => <AddAward {...props} snackbar={this.myFunction} />}
						path="/dashboard/AddAward"
					/>

					<Route
						render={props => (
							<ViewAward {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewAward"
					/>

					<Route
						render={props => (
							<ViewStudentInAward {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewStudentInAward/:id"
					/>

					<Route
						render={props => (
							<AwardTemplate1 {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AwardTemplate1/:id"
					/>

					<Route
						render={props => (
							<AwardTemplate2 {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AwardTemplate2/:id"
					/>

					<Route
						render={props => (
							<ViewAwardByStudent {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewAwardByStudent"
					/>
					{/* End Award Module*/}

					{/* Start Game Module*/}

					<Route
						render={props => <AddGame {...props} snackbar={this.myFunction} />}
						path="/dashboard/AddGame"
					/>

					<Route
						render={props => <ViewGame {...props} snackbar={this.myFunction} />}
						path="/dashboard/ViewGame"
					/>

					<Route
						render={props => (
							<AddOffGame {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AddOffGame"
					/>

					<Route
						render={props => (
							<ViewOffGame {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewOffGame"
					/>

					{/* End Game Module*/}

					{/* Start Student Coordinator Module*/}

					<Route
						render={props => (
							<AssignClassNewAdmit {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AssignClassNewAdmit"
					/>

					<Route
						render={props => (
							<ClassOfStudent {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ClassOfStudent"
					/>

					<Route
						render={props => (
							<TransferStudent {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/TransferStudent"
					/>

					{/* End Student Coordinator Module*/}

					{/* Start Game Module*/}

					<Route
						render={props => <AddEvent {...props} snackbar={this.myFunction} />}
						path="/dashboard/AddEvent"
					/>

					<Route
						render={props => (
							<ViewEvent {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewEvent"
					/>

					<Route
						render={props => (
							<UpdateEvent {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/UpdateEvent"
					/>

					<Route
						render={props => (
							<StaffEventView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/StaffEventView"
					/>

					<Route
						render={props => (
							<ViewStudentEvents {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewStudentEvents"
					/>

					{/* End Game Module*/}

					{/* Academic Head Start */}
					<Route
						render={props => (
							<AcademicDeanDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AcademicDeanDashboard"
					/>
					<Route
						render={props => <ViewDean {...props} snackbar={this.myFunction} />}
						path="/dashboard/ViewDean"
					/>
					<Route
						render={props => <AddDean {...props} snackbar={this.myFunction} />}
						path="/dashboard/addDean"
					/>
					<Route
						render={props => (
							<EnglishDeanDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/EnglishDeanDashboard/:id"
					/>
					<Route
						render={props => (
							<ViewEnglishCoordinatior {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ViewEnglishCoordinatior"
					/>
					<Route
						render={props => (
							<EnglishHODDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/EnglishHODDashboard/:id"
					/>
					<Route
						render={props => (
							<ArabicHODDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/ArabicHODDashboard/:id"
					/>
					<Route
						render={props => (
							<EnrolledStaffInHOD {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/EnrolledStaffInHOD/:id"
					/>
					<Route
						render={props => (
							<AcademicFormViews {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/AcademicFormViews/:id"
					/>
					{/* Academic Head End */}

					{/* HOD Forms STart */}

					<Route
						render={props => (
							<HODDescriptiveView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/HODDescriptiveView/:name"
					/>
					<Route
						render={props => (
							<HODSkillsView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/HODSkillsView/:name"
					/>
					<Route
						render={props => (
							<HODSubjectDisciplineView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/HODSubjectDisciplineView/:name"
					/>
					<Route
						render={props => (
							<HODEvaluationFormView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/HODEvaluationFormView/:name"
					/>
					<Route
						render={props => (
							<HODClassDisciplineView {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/HODClassDisciplineView/:name"
					/>
					<Route
						render={props => (
							<HODSubjectDisciplineEdit {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/HODSubjectDisciplineEdit/:name"
					/>

					{/* HOD Forms End */}

					{/* HOD Forms by Roles Start */}
					<Route
						render={props => (
							<CoordinatorsDashboard {...props} snackbar={this.myFunction} />
						)}
						path="/dashboard/CoordinatorsDashboard/:name"
					/>

					{/* HOD Forms by Roles End */}
				</Switch>
			</Layout>
		);
	}
}

export default Routes;
