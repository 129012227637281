import * as actionTypes from '../Actions/actionTypes';
import isEmpty from 'lodash/isEmpty';

const defaultState = {
	apiFlag: true,
	Data: []
};

export default function setClassDisciplineReducer(
	state = defaultState,
	action = {}
) {
	switch (action.type) {
		case actionTypes.GET_ALL_STUDENT_IN_CLASS_DISCIPLINE:
			return { ...state, Data: [...action.payload] };
		case actionTypes.GET_LOADER_FLAG_ACTIVE:
			return { ...state, apiFlag: action.payload };
		// case actionTypes.DELETE_CAMPUS:
		// 	let filterCandidateReqList = state.Data.filter(
		// 		filterOBJ => filterOBJ.id !== action.payload
		// 	);
		// 	return {
		// 		...state,
		// 		Data: filterCandidateReqList
		// 	};
		// case actionTypes.EDIT_CAMPUS_RECORD:
		// 	var adminData = [...state.Data];
		// 	var index = adminData.findIndex(obj => obj.id === action.payload.id);
		// 	adminData[index].campusAddress = action.payload.campusName;
		// 	adminData[index].campusName = action.payload.campusName;
		// 	adminData[index].campusCity = action.payload.campusCity;
		// 	adminData[index].campusPhoneNumber = action.payload.campusPhoneNumber;

		// 	return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
