import React, { Component } from 'react';
import $ from 'jquery';
import '../Admin/viewAdmin.css';
import Axios from 'axios';
import config from '../../config/config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as inventoryActions from '../../Actions/Inventory';
import * as sharedActions from '../../Actions/sharedActions';
import setCanteenReducer from '../../Reducers/setCanteenReducer';
import SnackBar from '../../components/SnackBar/SnackBar';
import * as CanteenActions from '../../Actions/Canteen';

const AdminDetails = [
	{
		username: 'Muhammad Hamza',
		role: 'Admin',
		id: 1
	},
	{
		username: 'Muhammad Hamza',
		role: 'Admin',
		id: 1
	},
	{
		username: 'Muhammad Hamza',
		role: 'Admin',
		id: 1
	},
	{
		username: 'Muhammad Hamza',
		role: 'Admin',
		id: 1
	},
	{
		username: 'Muhammad Hamza',
		role: 'Admin',
		id: 1
	},
	{
		username: 'Muhammad Sumair',
		role: 'Admin',
		id: 2
	},
	{
		username: 'Asad Akhtar',
		role: 'Admin',
		id: 3
	}
];
class ViewCanteen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			price: '',
			type: '',
			name: '',
			enabled: false,
			id: null,
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			supplierId: null,
			supplierName: '',
			categoryName: '',
			currentStock: '',
			categoryId: null,
			category: [],
			supplier: [],
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: ''
		};
	}
	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
			this.setState({
				disablepage: false
			});
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	decrement = e => {
		this.setState({
			currentStock: this.state.currentStock - 1
		});
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.adminData !== nextProps.AllInventoryData &&
			prevState.supplier !== nextProps.selectSupplier &&
			prevState.category !== nextProps.selectCategory
		) {
			return {
				adminData: nextProps.AllInventoryData,
				supplier: nextProps.selectSupplier,
				category: nextProps.selectCategory
			};
		}
		return null;
	}
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/Suppliers',
				this.props.inventoryActions.getSupplierForAddInventory
			)
			.then(success => {})
			.catch(error => {});
		this.props.sharedActions
			.gAllData(
				'/api/InventoryCategories',
				this.props.inventoryActions.getCategoryForAddInventory
			)
			.then(success => {})
			.catch(error => {});
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		if (this.props.allInventoryState.apiFlag) {
			this.props.sharedActions
				.getAllData(
					'/api/InventoryItems',
					this.props.inventoryActions.setAllInventoryItemsInRedux,
					this.props.inventoryActions.updateAddInventoryFlag
				)
				.then(success => {
					this.setState({
						isLoading: false
					});
				})
				.catch(err => {
					this.setState({
						isLoading: false
					});

					console.log(err);
				});
		}
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/InventoryItems/',
				itemId,
				this.props.inventoryActions.deleteInventoryItem
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			categoryName: result.name,
			categoryId: result.id
		});
	};
	onSelectChangeHandler = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			supplierName: result.name,
			supplierId: result.id
		});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.id === itemId);

		this.setState({
			modalData: getData,
			name: getData[0].name,
			type: getData[0].type,
			price: getData[0].price,
			currentStock: getData[0].currentStock,
			id: getData[0].id,
			categoryId: getData[0].t3.id,
			supplierId: getData[0].t1.id,
			supplierName: getData[0].t1.name,
			categoryName: getData[0].t3.name
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const { id, name, price, type, currentStock } = this.state;
		const { supplierName, supplierId, categoryName, categoryId } = this.state;
		var bodyParameters = {
			supplierName,
			categoryName,
			id,
			name,
			price,
			type,
			supplierId,
			currentStock,
			date: new Date().toDateString(),
			time: new Date().toTimeString(),
			accountId: this.props.users.unique_name,
			accountType: this.props.users.role,
			categoryId,
			supplierId
		};

		this.props.sharedActions
			.editRecord(
				'/api/InventoryItems/',
				id,
				bodyParameters,
				this.props.inventoryActions.updateInventoryItem
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				this.props.snackbar();
			})
			.catch(err => {});
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	render() {
		const { adminData, modalData, supplier, category } = this.state;

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return index <= 3 ? (
				<li
					class={`page-item ${
						this.state.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={this.handleClickNext}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			) : (
				''
			);
		});
		const { currentPage, todosPerPage } = this.state;
		const indexOfLastTodo = currentPage * todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
		const renderTodos = currentTodos.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" className="bordersSpace" style={{ padding: 3 }}>
						{index + 1}
					</th>
					<td className="bordersSpace" style={{ padding: 3 }}>
						{item.name}
					</td>
					<td className="bordersSpace" style={{ padding: 3 }}>
						{item.type}
					</td>
					<td className="bordersSpace" style={{ padding: 3 }}>
						{item.price}
					</td>
					<td className="bordersSpace" style={{ padding: 3 }}>
						{item.currentStock}
					</td>
					<td className="bordersSpace" style={{ padding: 3 }}>
						{item.t1.name}
					</td>
					<td className="bordersSpace" style={{ padding: 3 }}>
						{item.t3.name}
					</td>
					<td className="bordersSpace" style={{ padding: 3 }}>
						{item.date}
					</td>
					<td className="bordersSpace" style={{ padding: 3 }}>
						{item.time}
					</td>
					<td className="bordersSpace" style={{ padding: 3 }}>
						{item.t3.accountId}
					</td>
					<td className="bordersSpace" style={{ padding: 3 }}>
						{item.t3.accountType}
					</td>
					<td className="bordersSpace" style={{ padding: 3 }}>
						<a href="#" data-toggle="tooltip" title="Edit!">
							<button
								id="firstbutton"
								onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								data-toggle="modal"
								data-target="#myModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i class="fas fa-pen"></i>
							</button>
						</a>
						<a href="#" data-toggle="tooltip" title="Delete!">
							<button
								onClick={() => this.onEditClick(item.id)}
								data-toggle="modal"
								data-target="#myModal1"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
							</button>
						</a>
					</td>
				</tr>
			);
		});
		const MainContent = (
			<div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<form onSubmit={this.onHandleSubmit}>
									{modalData &&
										modalData.map((item, index) => (
											<div key={index}>
												<fieldset disabled={!this.state.enabled}>
													<div class="form-group">
														<label for="name">Item Name:</label>
														<input
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.name}
															onChange={this.onHandleText}
															name="name"
															class="form-control"
															id="name"
														/>
													</div>
													<div class="form-group">
														<label for="type">Type</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.type}
															onChange={this.onHandleText}
															type="text"
															name="type"
															class="form-control"
															id="type"
														/>
													</div>
													<div class="form-group">
														<label for="price">Price</label>
														<input
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.price}
															onChange={this.onHandleText}
															name="price"
															class="form-control"
															id="price"
														/>
													</div>
													<div class="form-group">
														<label for="Supplier">Supplier</label>
														<input
															readOnly={true}
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.supplierName}
															onChange={this.onHandleText}
															name="supplierName"
															class="form-control"
															id="supplierName"
														/>
													</div>
													<div class="form-group">
														<label for="Category">Category</label>
														<input
															readOnly={true}
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.categoryName}
															onChange={this.onHandleText}
															name="categoryName"
															class="form-control"
															id="categoryName"
														/>
													</div>

													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="email" class="">
																<i
																	style={{ marginRight: 4 }}
																	className="fas fa-university animated fadeIn"
																/>
																Category Name
															</label>
															<select
																onChange={this.onSelectChange}
																class="custom-select"
																name="CategoryName"
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	{' '}
																	Category Names
																</option>
																{category.map((item, index) => (
																	<option value={`${JSON.stringify(item)}`}>
																		{item.name}
																	</option>
																))}
															</select>
														</div>
													</div>

													<div class="col-md-12">
														<div class="md-form mb-0">
															<label for="email" class="">
																<i
																	style={{ marginRight: 4 }}
																	className="fas fa-university animated fadeIn"
																/>
																Supplier Name
															</label>
															<select
																onChange={this.onSelectChangeHandler}
																class="custom-select"
																name="SupplierName"
															>
																<option
																	selected={this.state.msg ? true : false}
																>
																	Supplier Names
																</option>
																{supplier.map((item, index) => (
																	<option
																		key={index}
																		value={`${JSON.stringify(item)}`}
																	>
																		{item.name}
																	</option>
																))}
															</select>
														</div>
													</div>
												</fieldset>
												<br />
												<button
													disabled={this.state.enabled}
													onClick={() => this.setState({ enabled: true })}
													type="button"
													class="btn btn-primary buttonAppear"
												>
													Edit
												</button>
												<button
													style={{ marginLeft: 5 }}
													disabled={!this.state.enabled}
													type="submit"
													class="btn btn-primary buttonAppear"
												>
													Save
												</button>
											</div>
										))}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="table-responsive">
					<table class="table table-hover" style={{ textAlign: 'center' }}>
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 3 }}>
									#
								</th>
								<th scope="col" style={{ padding: 3 }}>
									Item Name
								</th>
								<th scope="col" style={{ padding: 3 }}>
									Type
								</th>
								<th scope="col" style={{ padding: 3 }}>
									Price
								</th>
								<th scope="col" style={{ padding: 3 }}>
									Current Stock
								</th>
								<th scope="col" style={{ padding: 3 }}>
									Supplier
								</th>
								<th scope="col" style={{ padding: 3 }}>
									Category
								</th>
								<th scope="col" style={{ padding: 3 }}>
									Date
								</th>
								<th scope="col" style={{ padding: 3 }}>
									Time
								</th>
								<th scope="col" style={{ padding: 3 }}>
									AccountId
								</th>
								<th scope="col" style={{ padding: 3 }}>
									AccountType
								</th>
								<th scope="col" style={{ padding: 3 }}>
									Actions
								</th>
							</tr>
						</thead>
						<tbody>{renderTodos}</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Item Details</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								<li
									class={`page-item ${
										this.state.disableBPage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextBack}
										class="page-link"
										href="#"
									>
										{this.state.disableBPage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-backward"></i>
										)}
									</a>
								</li>
								{renderPageNumbers}
								<li
									class={`page-item ${
										this.state.disablepage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextFrwd}
										class="page-link"
										href="#"
									>
										{this.state.disablepage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-forward"></i>
										)}
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				{this.props.allInventoryState.apiFlag ? Loader : MainContent}
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		inventoryActions: bindActionCreators(inventoryActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch),
		canteenActions: bindActionCreators(CanteenActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllInventoryData: state.setAddInventoryReducer.Data,
		allInventoryState: state.setAddInventoryReducer,
		users: state.setUserReducer.users,
		selectSupplier: state.setAddInventoryReducer.selectSupplier,
		selectCategory: state.setAddInventoryReducer.selectCategory
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewCanteen);
