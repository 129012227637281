import React from 'react';
import ViewRendering from '../components/ViewRendering';
import { renderingStuff, images } from '../utils';

function PastoralDashboard(props) {
    return (
        <div>
            <ViewRendering
                centered={true}
                centerWidth={'50%'}
                mainHeading={images.imgPastoralSmart}
                data={renderingStuff.PastoralsLinks}
                mainHead={true}
            />
        </div>
    );
}

export default PastoralDashboard;
