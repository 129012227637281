import * as actionTypes from '../actionTypes';

export function setAllStudentEnquiryInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_STUDENT_ENQUIRY,
		payload: allData
	};
}
export function getClassesForStudentEnquiry(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_STUDENT_ENQUIRY_SELECT,
		payload: allData
	};
}
export function getClassesForStudentSearchEnquiry(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_STUDENT_SEARCH_ENQUIRY_SELECT,
		payload: allData
	};
}

export function getAdmittedStudentEnquiryData(allData) {
	return {
		type: actionTypes.GET_ADMITTED_STUDENT_ENQUIRY_DATA,
		payload: allData
	};
}

export function getIndividualStudentForEnquiry(allData) {
	return {
		type: actionTypes.GET_INDIVIDUAL_STUDENT_ENQUIRY_DATA,
		payload: allData
	};
}
export function addStudentEnquiry(Data) {
	return {
		type: actionTypes.ADD_STUDENT_ENQUIRY,
		payload: Data
	};
}
export function updateStudentEnquiryFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_STUDENT_ENQUIRY_ADD,
		payload: setFlag
	};
}
export function updateStudentEnquiry(data) {
	return {
		type: actionTypes.EDIT_STUDENT_ENQUIRY_RECORD,
		payload: data
	};
}
export function updateAdmittedStudent(data) {
	return {
		type: actionTypes.EDIT_STUDENT_ADMITTED_RECORD,
		payload: data
	};
}
export function deleteStudentEnquiry(id) {
	return {
		type: actionTypes.DELETE_STUDENT_ENQUIRY,
		payload: id
	};
}
