import React, { Component } from 'react';
import DateandTime from '../../Transport/DateandTime';
import { GeneralCheckRendering } from '../../Time-Table';
// import TimePicker from 'react-time-picker';
import moment from 'moment';
const format = 'h:mm a';
const now = moment()
	.hour(0)
	.minute(0);

const TimeTableStep4 = props => {
	const { staticDays } = props;

	const renderEachDays = flag => {
		if (flag) {
			return (
				<div
					style={{
						display: 'flex',
						position: 'relative',
						bottom: 50,
						fontSize: 10,
						justifyContent: 'center'
					}}
				>
					<GeneralCheckRendering
						onChangeCheck={props.onCheckChange}
						periodsData={staticDays}
						valuePropName="day"
						staticDisplayProp=""
						nameCheckCom="daysRendering"
					/>
				</div>
			);
		}
	};
	return (
		<div
			style={{
				fontSize: 10,
				border: '1px solid #D3D3D3',
				boxShadow: '0px 0px 15px #0A4F5E',
				padding: 10,
				borderRadius: 5,
				position: 'relative',
				bottom: 75
				// height: '50vh'
			}}
		>
			<div class="row">
				<div class="col-md-12">
					<div class="md-form mb-0">
						<label for="name" class="">
							<i
								className="fas fa-house animated fadeIn"
								style={{ marginRight: 4, margin: 0 }}
							/>
							<p style={{ fontSize: 30 }}>
								<span
									style={{ position: 'relative', bottom: 60 }}
									class="badge badge-pill badge-dark"
								>
									<p style={{ margin: 0, color: '#fff' }}>Effective Days</p>
								</span>
							</p>
						</label>
						{props.stateData.errors.step4 ? (
							<p
								style={{
									color: 'red',
									fontSize: 17,
									textAlign: 'center',
									position: 'relative',
									bottom: 50,
									margin: 0
								}}
							>
								Please Fill Properly!
							</p>
						) : (
							''
						)}
						<br />
					</div>

					<div style={{ position: 'relative', bottom: 40 }}>
						<label class="containerRadio">
							<p style={{ fontSize: 19, color: '#000' }}>Mon to Fri</p>
							<input
								onChange={e => props.onRadioChange(e)}
								value={'Mon to Fri'}
								type="radio"
								name="workDayOptions"
							/>
							<span class="checkmark"></span>
						</label>
						<label class="containerRadio">
							<p style={{ fontSize: 19, color: '#000' }}>Mon to Sat</p>
							<input
								onChange={e => props.onRadioChange(e)}
								value={'Mon to Sat'}
								type="radio"
								name="workDayOptions"
							/>
							<span class="checkmark"></span>
						</label>
						<label class="containerRadio">
							<p style={{ fontSize: 19, color: '#000' }}>Others</p>
							<input
								onChange={e => props.onRadioChange(e)}
								value={'Others'}
								type="radio"
								name="workDayOptions"
							/>
							<span class="checkmark"></span>
						</label>
					</div>
					{renderEachDays(props.stateData.renderEachDays)}
				</div>
			</div>
			{/* <button class="btn btn-primary" onClick={() => this.props.stepperFun()}>Next</button> */}
		</div>

		// </div>
	);
};
export default TimeTableStep4;
