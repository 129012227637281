import React from 'react';
import ViewRendering from '../../../components/ViewRendering';
import { renderingStuff, images } from '../../../utils';

function AcademicCMS(props) {
	return (
		<ViewRendering
			centered={true}
			centerWidth={'50%'}
			mainHeading={images.imgAcHead}
			data={renderingStuff.academicCMSLinks}
			mainHead={true}
		/>
	);
}

export default AcademicCMS;
