import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../src/utils/images/index';
import $ from 'jquery';

class FinanceDashboard extends Component {
	state = {};
	componentDidMount() {
		$(document).click(function(e) {
			if (!$(e.target).is('.panel-body')) {
				$('.collapse').collapse('hide');
			}
		});
	}
	render() {
		return (
			<div>
				<section class="mb-4">
					<div class="h1-responsive font-weight-bold text-center my-4">
						<img
							src={require('../Assets/images/Finance-02.svg')}
							style={{ width: 500 }}
						/>
					</div>

					<div
						class="row"
						style={{ justifyContent: 'center', textAlign: 'center' }}
					>
						<div class="col-md-8">
							<div class="md-form mb-0">
								<hr class="style-head" />
							</div>
						</div>
					</div>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{ textAlign: 'center', justifyContent: 'center' }}
					>
						<div class="col-md-9 mb-md-0 mb-5">
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/AccountsDashboard">
												{/* <img
                                                src={require("../Assets/FinanceDashboardimg/new icon for humza-11.svg")}
                                                width="180"
                                                height="180"
                                            /> */}
												<img
													src={require('../Assets/FinanceDashboardimg/account.svg')}
													style={{ width: 180, height: 180 }}
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/BudgetDashboard">
												<img
													src={require('../Assets/FinanceDashboardimg/budget.svg')}
													style={{ width: 180, height: 180 }}
												/>
											</Link>
										</div>
									</div>
									<div class="col-md-4">
										<div class="md-form mb-0">
											<Link to="/dashboard/ManagersDashboard">
												<img
													src={images.imgManagerDashboard}
													style={{ width: 180, height: 180 }}
												/>
											</Link>
										</div>
									</div>
								</div>
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default FinanceDashboard;
