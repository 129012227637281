import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SkillAction from '../../Actions/StudentSkill';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import Year from '../../components/Year';
import months from '../../utils/general';
import ViewComponent from '../../components/ViewComponent/ViewComponent';
import {
	dataFilteration,
	addDataMiddleware,
	isEmptyFieldInArrOBJ
} from '../../utils/general/index';
// import MultipleSkills from './MultipleSkill';
import $ from 'jquery';
// import TableComponent from "./TableComponent";
class MonthlyFeeList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			monthlyStudentList: [],
			newMonthlyStudentList: [],
			adminData: [],
			filterCheck: false,
			status: '',
			msgSnack: '',
			backColor: '',
			year: '',
			month: '',
			modalData: null,
			enabled: false,
			id: null,
			select: [],
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			errorMsg: '',
			increament: 1,
			selectClassData: '',
			classId: '',
			sectionId: '',
			sections: [],
			subjects: [],
			session: [],
			sessionId: '',
			subjectId: '',
			staffId: '',
			term: '',
			btnLoad: false,
			exist: false,
			checking: [],
			searchCheck: true,
			multiple: [],
			badRequest: false,
			errorText: ''
		};
	}
	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
			this.setState({
				disablepage: false
			});
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	if (
	// 		prevState.adminData !== nextProps.AllTransportData ||
	// 		prevState.selectClassData !== nextProps.selectData ||
	// 		prevState.session !== nextProps.SessionSelect
	// 	) {
	// 		return {
	// 			adminData: nextProps.AllTransportData,
	// 			selectClassData: nextProps.selectData,
	// 			session: nextProps.SessionSelect
	// 		};
	// 	}
	// 	return null;
	// }
	onSelectChange = e => {
		console.log(e.target.value);

		const sections = this.state.selectClassData.section;
		let check = sections.filter(item => item.classId == e.target.value);
		console.log(check);

		this.setState({
			sections: check,
			classId: e.target.value
		});
	};
	onSectionChange = e => {
		console.log(e.target.value);
		this.setState({
			sectionId: e.target.value
		});
	};

	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();

		if (
			this.state.msg ||
			this.state.errorMsg ||
			this.state.exist ||
			this.state.badRequest
		) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					exist: false,
					badRequest: false
				});
			}, 3000);
		}
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.getDataWithoutDispatch('/api/classSection')
			.then(success => {
				this.setState({
					selectClassData: success
				});
			})
			.catch(err => {
				this.setState({});

				console.log(err);
			});
		this.props.sharedActions
			.getDataWithoutDispatch('/api/Session')
			.then(success => {
				this.setState({
					session: success
				});
			})
			.catch(err => {
				this.setState({});

				console.log(err);
			});
	}
	onSearchStudent = status => {
		debugger;
		this.setState({
			btnLoad: true
		});
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/StudentFeeList/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&month=${this.state.month}&year=${this.state.year}&status=${status}`
			)
			.then(success => {
				debugger;
				this.setState({
					filterCheck: true,
					monthlyStudentList: success,
					searchCheck: false,
					btnLoad: false
				});
				let requireData = addDataMiddleware(
					{
						Action: [
							{
								isEnable: true,
								type: 'downloadLink',
								title: 'View Receipt',
								initialPath: '/api/challan/?voucherId=',
								iconName: 'fas fa-receipt',
								iconColor: '#01AC8A'
							}
						]
					},
					success
				);
				this.setState({
					newMonthlyStudentList: requireData
				});
				debugger;
			})
			.catch(err => {
				debugger;
				this.setState({
					errorText: err.response.data.Message,
					badRequest: true,
					isLoading: false,
					btnLoad: false,
					searchCheck: true
				});

				console.log(err);
			});
	};
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/Vehicle/',
				itemId,
				this.props.routesActions.deleteVehicle
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.id === itemId);

		this.setState({
			modalData: getData,
			transportType: getData[0].transportType,
			capacity: getData[0].capacity,
			vehicleNo: getData[0].vehicleNo,
			id: getData[0].id,
			routeName: getData[0].dropOff,
			routeId: getData[0].routeId,
			startingServiceKm: getData[0].startingServiceKm,
			midServiceKm: getData[0].midServiceKm,
			endingServiceKm: getData[0].endingServiceKm,
			vehicleDateOfReg: getData[0].vehicleDateOfReg,
			vehicleDateOfRegExpiry: getData[0].vehicleDateOfRegExpiry
		});
	};

	onHandleText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
		debugger;
	};

	onStatusChange = e => {
		console.log(e.target.value);
		this.setState({
			status: e.target.value
		});
		debugger;
		this.onSearchStudent(e.target.value);
	};
	onHandleSubmit = e => {
		e.preventDefault();
		this.setState({
			btnLoad: true
		});
		const { SkillReports } = this.state;
		if (SkillReports.every(o => o.Efforts === '')) {
			this.setState({
				btnLoad: false,
				exist: true
			});
		} else {
			var bodyParameters = { SkillReports };
			this.props.sharedActions
				.addRecord(
					'/api/SkillReport',
					bodyParameters,
					this.props.SkillAction.addSkill
				)
				.then(success => {
					this.setState({
						examName: '',
						msg: true,
						errorMsg: false,
						btnLoad: false,
						searchCheck: true
					});
					// this.props.campusActions.updateCampusFlag(true);
				})
				.catch(err => {
					this.setState({
						errorMsg: true,
						btnLoad: false
					});
					console.log(err);
					console.log(err.data);
				});
		}
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};

	onClickDeleteItem = arrName => {
		let getStateArr = this.state[`${arrName}`];
		let getDeleteLength = getStateArr.length - 1;
		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			this.setState({
				[arrName]: [...getNewDetails]
			});
		}
	};

	onClickAddItem = (arrName, obj) => {
		let getStateArr = this.state[`${arrName}`];
		getStateArr = [...getStateArr, obj];
		console.log(arrName);
		this.setState({
			[arrName]: getStateArr
		});
	};
	updateSpecialArrObjOnChange = (e, index, arrName) => {
		if (e.target.value <= 5) {
			const changeArrOfObj = this.state[`${arrName}`];
			changeArrOfObj[index][e.target.name] = e.target.value;
			this.setState({
				[arrName]: changeArrOfObj
			});
		}
	};
	onChangeText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onChangingData = (index, event) => {
		let dataStrcuture = [
			{
				studentId: 1,
				studentName: 'Muhammad Hamza',
				effort: '',
				average: '',
				participation: ''
			}
		];

		const { multiple } = this.state;
		var check = [...multiple];
		check[index][event.target.name] = event.target.value;
		this.setState({ multiple: check });
	};
	hitNotPaidApi = e => {
		this.props.sharedActions
			.simplePostReq(
				'/api/NotPaidFee/',
				`?classId=${this.state.classId}&sectionId=${this.state.sectionId}&month=${this.state.month}&year=${this.state.year}$status=Paid`
			)
			.then(success => {
				debugger;
				this.setState({
					msgSnack: success,
					backColor: '#01ac8a'
				});
				this.props.snackbar();
			})
			.catch(error => {
				debugger;
				this.setState({
					msgSnack: error.response.data.Message,
					backColor: 'red'
				});
				this.props.snackbar();
			});
	};
	render() {
		const { classId, sectionId, month, year } = this.state;
		console.log('Daata', this.state.SkillReports);
		console.log('Admin Data', this.state.adminData);
		console.log('Classes Ka Data', this.state.selectClassData);
		console.log('Session Ka Data', this.state.session);
		const { session } = this.state.session;
		const { classList } = this.state.selectClassData;
		console.log('modal data: ', this.state.modalData);

		const DataForHeader = [
			'#',
			'Name',
			'Class',
			'Section',
			'Monthly Fee',
			'Total Amount',
			'Amount Paid',
			'Status',
			'Month',
			'Year',
			'Actions'
		];
		const DataForExclude = ['studentId', 'classId', 'sectionId', 'voucherId'];
		const redirectIds = ['voucherId'];
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<label style={{ color: 'red' }}>No Data</label>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <=
			Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return (
				<li
					class={`page-item ${
						this.state.checking.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={() => this.paginationRequest(number)}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			);
		});
		const MainContent = (
			<div>
				<ViewComponent
					mainData={this.state.newMonthlyStudentList}
					headData={DataForHeader}
					excludedData={DataForExclude}
					redirectIds={['voucherId']}
				/>
			</div>
		);
		return (
			<div>
				{this.state.errorMsg ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Grading Marks Already Exist For This Term
					</p>
				) : (
					''
				)}
				{this.state.msg ? (
					<p style={{ color: 'green', textAlign: 'center' }}>
						Added Successfully!
					</p>
				) : (
					''
				)}
				{this.state.exist ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Please Fill the Fields
					</p>
				) : (
					''
				)}
				{this.state.badRequest ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						{this.state.errorText}
					</p>
				) : (
					''
				)}
				<div className="page-header">
					<SnackBar
						msg={this.state.msgSnack}
						backColor={this.state.backColor}
					/>
					<div class="row">
						<div class="col-md-3"></div>
						<div class="col-md-6">
							<h4 class="generalHead">Monthly Fee List</h4>
						</div>
						<div class="col-md-3 text-right">
							<button
								class="btn btn-dark"
								onClick={this.hitNotPaidApi}
								disabled={
									classId !== '' &&
									sectionId !== '' &&
									year !== '' &&
									month !== ''
										? false
										: true
								}
								style={{
									backgroundColor:
										classId !== '' &&
										sectionId !== '' &&
										year !== '' &&
										month !== ''
											? ''
											: '#ABD0BC',
									border: 'none',
									borderRadius: 4,
									color: 'white'
								}}
							>
								Marked Not Paid
							</button>
						</div>
					</div>
				</div>
				<br />
				<div class="container">
					<div class="row" style={{ justifyContent: 'center' }}>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
                                <i
                                    style={{ marginRight: 4 }}
                                    className="fas fa-map animated fadeIn"
                                />
                                Class Name
                      </label> */}
								<select
									onChange={this.onSelectChange}
									class="custom-select"
									name="className"
								>
									<option selected={this.state.msg ? true : false}>
										Choose Class...
									</option>
									{classList &&
										classList.map((item, index) => (
											<option key={index} value={`${item.id}`}>
												{item.className}
											</option>
										))}
								</select>
							</div>
						</div>

						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
                                <i
                                    style={{ marginRight: 4 }}
                                    className="fas fa-map animated fadeIn"
                                />
                                Section
                      </label> */}
								<select
									onChange={this.onSectionChange}
									class="custom-select"
									name="className"
								>
									<option selected={this.state.msg ? true : false}>
										Select Section...
									</option>
									{this.state.sections.map((item, index) => (
										<option key={index} value={`${item.sectionId}`}>
											{item.section}
										</option>
									))}
								</select>
							</div>
						</div>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
                                <i
                                    style={{ marginRight: 4 }}
                                    className="fas fa-map animated fadeIn"
                                />
                                Section
                      </label> */}
								<select
									onChange={this.onHandleText}
									class="custom-select"
									name="month"
								>
									<option selected={this.state.msg ? true : false}>
										Select Month...
									</option>
									{months.Months.map((item, index) => (
										<option key={index} value={`${item}`}>
											{item}
										</option>
									))}
								</select>
							</div>
						</div>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								<Year onMonthChange={this.onHandleText} />
							</div>
						</div>
					</div>
					{this.state.filterCheck ? (
						<div class="row justify-content-center">
							<div class="col-md-4" style={{ marginTop: 20 }}>
								<div class="md-form mb-0">
									<select
										onChange={this.onStatusChange}
										class="custom-select"
										name="status"
									>
										<option value="">All Students</option>
										<option value="Paid">Paid</option>
										<option value="Not Paid">Not Paid</option>
									</select>
								</div>
							</div>
						</div>
					) : (
						''
					)}
				</div>
				<br />
				<div class="col-md-12 text-center">
					<button
						type="submit"
						disabled={
							classId !== '' && sectionId !== '' && year !== '' && month !== ''
								? false
								: true
						}
						style={{
							backgroundColor:
								classId !== '' &&
								sectionId !== '' &&
								year !== '' &&
								month !== ''
									? '#01ac8a'
									: '#ABD0BC',
							border: 'none',
							fontSize: 20,
							borderRadius: 4,
							color: 'white'
						}}
						onClick={() => this.onSearchStudent('')}
					>
						Search
						<i
							style={{ color: 'white', marginLeft: 4 }}
							class="fas fa-search"
						></i>
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
					</button>
				</div>
				<br />
				{this.state.searchCheck ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		SkillAction: bindActionCreators(SkillAction, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		// AllTransportData: state.setStudentSkillReducer.Data,
		// selectData: state.setStudentSkillReducer.selectStudent,
		// SessionSelect: state.setStudentSkillReducer.selectSession,
		allTransportState: state.setStudentSkillReducer,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MonthlyFeeList);
