import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import * as inventoryActions from '../../Actions/Inventory';
import $ from 'jquery';

class AddItems extends Component {
	constructor(props) {
		super(props);
		this.state = {
			AdminName: '',
			AdminType: '',
			name: '',
			price: '',
			type: '',
			CurrentStock: '',
			categoryId: '',
			supplierId: '',
			SupplierName: '',
			CategoryName: '',
			barcode: '',
			supplier: [],
			category: [],
			msg: false,
			errorMsg: false,
			barcodeCheck: true
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.supplier !== nextProps.selectSupplier &&
			prevState.category !== nextProps.selectCategory
		) {
			return {
				supplier: nextProps.selectSupplier,
				category: nextProps.selectCategory
			};
		}
		return null;
	}
	onHandleTextChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	barcodeValid = e => {
		e.preventDefault();
		this.setState({
			barcodeCheck: !this.state.barcodeCheck
		});
	};
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/Suppliers',
				this.props.inventoryActions.getSupplierForAddInventory
			)
			.then(success => {})
			.catch(error => {});
		this.props.sharedActions
			.gAllData(
				'/api/InventoryCategories',
				this.props.inventoryActions.getCategoryForAddInventory
			)
			.then(success => {})
			.catch(error => {});
	}
	onPrice = e => {
		const re = /^[0-9\b]+$/;

		// if value is not blank, then test the regex
		if (e.target.value === '' || re.test(e.target.value)) {
			this.setState({ price: e.target.value });
			console.log(this.state.Price);
		}
	};
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
		$('[data-toggle="tooltip"]').tooltip();
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onDisableCheck = e => {
		this.setState({
			barcodeCheck: !this.state.barcodeCheck
		});
	};
	onSelectCatChangeHandler = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			categoryId: result.id
		});
	};
	onSelectChangeHandler = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			supplierId: result.id
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		console.log(this.state);
		const { supplierId, categoryId, name, type, price, barcode } = this.state;
		let data = {
			supplierId,
			categoryId,
			name,
			type,
			price,
			barcode,
			accountId: this.props.role,
			accountType: this.props.name
		};

		if (
			Object.is(supplierId, '') ||
			Object.is(categoryId, '') ||
			Object.is(name, '') ||
			Object.is(price, '')
		) {
			this.setState({
				errorMsg: true
			});
		} else {
			this.props.sharedActions
				.addRecord(
					'/api/InventoryItems',
					data,
					this.props.inventoryActions.addInventoryItem
				)
				.then(success => {
					this.props.inventoryActions.updateAddInventoryFlag(true);
					this.setState({
						msg: true,
						supplierId: '',
						categoryId: '',
						name: '',
						type: '',
						price: '',
						barcode: '',
						barcodeCheck: true
					});
				})
				.catch(error => {});
		}
	};
	render() {
		const { supplier, category } = this.state;
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add items
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.state.msg ? (
								<p style={{ color: 'green' }}>Item Added Successfully!</p>
							) : (
								''
							)}
							{this.state.errorMsg ? (
								<p style={{ color: 'red' }}>Please fill the form properly!</p>
							) : (
								''
							)}
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												readOnly={true}
												value={this.props.name}
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Type
											</label>
											<input
												type="text"
												style={{ borderColor: '#01AC8A' }}
												name="AdminType"
												placeholder="Enter Type"
												readOnly={true}
												value={this.props.role}
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Item Name
											</label>
											<input
												value={this.state.name}
												style={{ borderColor: '#01AC8A' }}
												placeholder="Enter Name"
												type="text"
												name="name"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Item Type
											</label>
											<input
												value={this.state.type}
												style={{ borderColor: '#01AC8A' }}
												placeholder="Enter Type"
												type="text"
												name="type"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Price
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Enter Price"
												type="text"
												name="price"
												value={this.state.price}
												class="form-control"
												onChange={this.onPrice}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Category Name
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onSelectCatChangeHandler}
												class="custom-select"
												name="CategoryName"
											>
												<option selected={this.state.msg ? true : false}>
													{' '}
													Category Names
												</option>
												{category.map((item, index) => (
													<option value={`${JSON.stringify(item)}`}>
														{item.name}
													</option>
												))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Supplier Name
											</label>
											<select
												onChange={this.onSelectChangeHandler}
												style={{ borderColor: '#01AC8A' }}
												class="custom-select"
												name="SupplierName"
											>
												<option selected={this.state.msg ? true : false}>
													Supplier Names
												</option>
												{supplier.map((item, index) => (
													<option key={index} value={`${JSON.stringify(item)}`}>
														{item.name}
													</option>
												))}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Barcode
												<input
													type="checkbox"
													onClick={this.onDisableCheck}
													style={{ marginLeft: 20, borderColor: '#01AC8A' }}
												/>
											</label>
											<input
												placeholder="Barcode"
												type="text"
												disabled={this.state.barcodeCheck}
												name="barcode"
												value={this.state.barcode}
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
								</div>
							</form>
							<br />
							<br />
							<div class="text-center text-md-center">
								<button onClick={this.onHandleSubmit} class="btns">
									Add
								</button>
							</div>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		inventoryActions: bindActionCreators(inventoryActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId,
		selectSupplier: state.setAddInventoryReducer.selectSupplier,
		selectCategory: state.setAddInventoryReducer.selectCategory
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddItems);
