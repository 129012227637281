import React from 'react';

export default function RowWrapper(props) {
	const { contextPosition = 'flex-start', wrap } = props;
	return (
		<div
			style={{
				flexWrap: wrap,
				display: 'flex',
				flexDirection: 'row',
				justifyContent: contextPosition
			}}
		>
			{props.children}
		</div>
	);
}
