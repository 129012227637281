import React from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import Spinner from '../../components/Spinner/Spinner';
import Colors from '../../utils/app_constants/colors_constants';
import TextInput from '../../components/TextInput/TextInput'
import TextArea from '../../components/TextArea'
import { Link } from 'react-router-dom';

const tHead = [
    'Certificate Title',
    'Few Detail',
    'Large Detail',
    'Actions'
]

class ViewCertificateCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            certificateCategoryData: [],
            isLoading: true,
            errorMsg: false,
            id: '',
            modalData: [],
            modalLargeDetail: '',
            modalFewDetail: '',
            modalCertificateTitle: '',
            errors: {}
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/CertificateCategory/`
            )
            .then(success => {
                this.setState({
                    certificateCategoryData: success,
                    isLoading: false
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: '',
                    errorMsg: false,
                    snackColor: ''
                });
            }, 3000);
        }
    }

    onHandleTextChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onEditClick = itemId => {
        this.onTakeItem(itemId);
        this.setState({ enabled: false });
    };

    onTakeItem = itemId => {
        this.setState({
            modalData: []
        });
        const { certificateCategoryData } = this.state;
        let getData = certificateCategoryData.filter(item => item.id === itemId);

        this.setState({
            modalData: getData,
            id: itemId,
            modalFewDetail: getData[0].fewDetail,
            modalCertificateTitle: getData[0].certificateTitle,
            modalLargeDetail: getData[0].largeDetail
        })

    }

    btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    onTakeItemDelete = itemId => {
        const { certificateCategoryData } = this.state;

        this.setState({
            isLoading: true
        })

        this.props.sharedActions
            .deleteRecordWithoutDispatch(
                '/api/CertificateCategory/',
                itemId,
            )
            .then(success => {
                let getNewFilterArray = certificateCategoryData.filter(item => item.id !== itemId);
                this.setState({
                    msg: 'Delete Record Successfully!',
                    certificateCategoryData: getNewFilterArray,
                    isLoading: false
                });
                this.props.snackbar();
            })
            .catch(error => { });
    };

    onHandleSubmitUpdate = e => {
        e.preventDefault();

        const { modalData, id, certificateCategoryData, modalCertificateTitle, modalFewDetail, modalLargeDetail } = this.state;

        const data = {
            certificateTitle: modalCertificateTitle,
            fewDetail: modalFewDetail,
            largeDetail: modalLargeDetail
        }

        modalData[0].certificateTitle = modalCertificateTitle
        modalData[0].fewDetail = modalFewDetail
        modalData[0].largeDetail = modalLargeDetail

        this.props.sharedActions
            .editRecordWithoutDispatch(
                '/api/CertificateCategory/',
                id,
                data,
            )
            .then(success => {
                certificateCategoryData.forEach((item, index) => {
                    if (item.id === id) {
                        certificateCategoryData.splice(index, 1, modalData[0])
                    }
                })
                this.setState({ enabled: false, msg: 'Edited Record Successfully!' });
                this.props.snackbar();
                $('#myModal').modal('toggle'); //or  $('#IDModal').modal('hide');
            })
            .catch(err => { });
    };

    render() {
        const { errors, isLoading, certificateCategoryData, id, modalData, enabled } = this.state;

        const renderTodos = certificateCategoryData.map((item, index) => {
            return (
                <tr className="bordersSpace" key={index}>
                    <th className="bordersSpace" scope="row">
                        {index + 1}
                    </th>
                    <td className="bordersSpace">{item.certificateTitle}</td>
                    <td className="bordersSpace">{item.fewDetail}</td>

                    <td className="bordersSpace">{item.largeDetail}</td>

                    <td className="bordersSpace">

                        <a href="#" data-toggle="tooltip" title="Edit!">
                            <button
                                id="firstbutton"
                                onClick={() => this.onEditClick(item.id)}
                                onMouseOver={this.btnTechHover()}
                                data-toggle="modal"
                                data-target="#myModal"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <i class="fas fa-pen"></i>
                            </button>
                        </a>
                        <Link to={`/dashboard/OtherCertificateCategoryTemplate1/${item.id}`}>
                            <button
                                id="firstbutton"
                                // onClick={() => this.onEditClick(item.id)}
                                onMouseOver={this.btnTechHover()}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <a href="#" data-toggle="tooltip" title="View!">
                                    <i style={{ color: '#01AC8A' }} class="fas fa-certificate"></i>
                                </a>
                            </button>
                        </Link>

                        <Link to={`/dashboard/OtherCertificateCategoryTemplate2/${item.id}`}>
                            <button
                                id="firstbutton"
                                // onClick={() => this.onEditClick(item.id)}
                                onMouseOver={this.btnTechHover()}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <a href="#" data-toggle="tooltip" title="View!">
                                    <i style={{ color: '#01AC8A' }} class="fas fa-certificate"></i>
                                </a>
                            </button>
                        </Link>

                        <a href="#" data-toggle="tooltip" title="Delete!">
                            <button
                                onClick={() => this.onEditClick(item.id)}
                                data-toggle="modal"
                                data-target="#myModal1"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    background: 'none',
                                    marginLeft: 5,
                                    marginBottom: 5
                                }}
                            >
                                <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                            </button>
                        </a>
                    </td>
                </tr>
            );
        });


        const MainContent = (
            <div>
                <div class="modal" id="myModal1">
                    <div class="modal-dialog">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Confirm
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <h4>Are you sure ?</h4>
                                    </div>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-evenly' }}
                                    >
                                        <div>
                                            <button
                                                disabled={this.state.enabled}
                                                data-dismiss="modal"
                                                onClick={() => this.onTakeItemDelete(id)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                Yes
											</button>
                                        </div>
                                        <div>
                                            <button
                                                data-dismiss="modal"
                                                disabled={this.state.enabled}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                No
											</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" id="myModal">
                    <div class="modal-dialog">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Details
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.onHandleSubmitUpdate}>
                                    {modalData &&
                                        modalData.map((item, index) => (
                                            <div key={index}>
                                                <fieldset disabled={!this.state.enabled}>
                                                    <TextInput
                                                        customWidth={true}
                                                        enterWidth={'300px'}
                                                        feildName={'modalCertificateTitle'}
                                                        inputLabelName={'Certificate Title'}
                                                        onHandleChange={this.onHandleTextChange}
                                                        errors={errors}
                                                        stateData={this.state}
                                                        placeholder={'Certificate Title'}
                                                        isValidte={false}
                                                    />

                                                    <TextInput
                                                        customWidth={true}
                                                        enterWidth={'300px'}
                                                        feildName={'modalFewDetail'}
                                                        inputLabelName={'Few Detail'}
                                                        errors={errors}
                                                        stateData={this.state}
                                                        placeholder="Few Detail"
                                                        type={'text'}
                                                        isValidte={false}
                                                        onHandleChange={this.onHandleTextChange}
                                                    />

                                                    <TextArea
                                                        customWidth={true}
                                                        enterWidth={'300px'}
                                                        feildName={'modalLargeDetail'}
                                                        inputLabelName={'Detail'}
                                                        onHandleChange={this.onHandleTextChange}
                                                        errors={errors}
                                                        stateData={this.state}
                                                        // iconClassName={'fas fa-percentage'}
                                                        placeholder={'Add Detail'}
                                                        isValidte={false}
                                                    />
                                                </fieldset>
                                                <br />
                                                <br />
                                                <button
                                                    disabled={this.state.enabled}
                                                    onClick={() => this.setState({ enabled: true })}
                                                    type="button"
                                                    class="btn btn-primary buttonAppear"
                                                >
                                                    Edit
												</button>
                                                <button
                                                    style={{ marginLeft: 5 }}
                                                    disabled={!this.state.enabled}
                                                    type="submit"
                                                    class="btn btn-primary buttonAppear"
                                                >
                                                    Save
												</button>
                                            </div>
                                        ))}
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    data-dismiss="modal"
                                >
                                    Close
								</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <table class="table table-hover">
                        <thead style={{ background: '#01AC8A', color: 'white' }}>
                            <tr>
                                <th scope="col">#</th>
                                {tHead.map(item => (
                                    <th
                                        style={{
                                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                            borderColor: Colors.WHITE
                                        }}
                                        scope="col"
                                    >
                                        {item}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>{renderTodos}</tbody>
                    </table>
                </div>
            </div>
        );

        return (
            <div>
                <div className="page-header">
                    <SnackBar msg={this.state.msg} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <h2>Certificate Category&nbsp;Details</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <br />

                {isLoading ? <Spinner /> : MainContent}
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewCertificateCategory);