import React, { useEffect, useState, useRef } from 'react';
import { ViewDataTable } from '../../../components/Edit';
import http from '../../../hooks/http';
import Spinner from '../../../components/Spinner/Spinner';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import SnackBar from '../../../components/SnackBar/SnackBar';
import Axios from 'axios';
import * as sharedActions from '../../../Actions/sharedActions';

import config from '../../../config/config';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import Button from '../../../components/Button';
import Colors from '../../../utils/app_constants/colors_constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import httpWithRequiredSetData from '../../../hooks/Services/httpWithRequiredSetData';
import Pagination from '../../../components/Pagination/Pagination';
import useHttp from '../../../hooks/http';
import useHttpWithGetSpecificData from '../../../hooks/Services/httpWithGetSpecificData';
import CustomBadge from '../../../components/CustomBadge/CustomBadge';
import CustomFilterSectionLayout from '../../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import general, { dataFilteration } from '../../../utils/general';

let tHead = [
	'Staff Name',
	'Father Name',
	'Department Name',
	'Post Name',
	'Class Name',
	'Account ID',
	'Account Type',
	'Actions'
];
let renderFields = [
	'staffName',
	'fatherName',
	'departmentName',
	'postName',
	'className',
	'accountId',
	'accountType'
];

const StaffView = props => {
	const [disableBPage, setdisableBPage] = useState(true);
	const [searchURL, setSearchURL] = useState(
		'/api/getAllStaff?pageNumber=1&pageSize=10'
	);
	const [customPageSize, setCustomPageSize] = useState('10');
	const [dropDownSearch, setDropDownSearch] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [num, setNum] = useState(1);
	const [prevNum, setPrevNum] = useState(1);
	const [numOfRenderRecord, setNumOfRenderRecord] = useState(10);
	const [pageNumber, setPageNumber] = useState([]);
	const [staffData, setStaffData] = useState(null);
	const [disablepage, setdisablepage] = useState(false);
	const [customLoader, setCustomLoader] = useState(false);
	const [sections, setSection] = useState('');
	const [classId, setClassId] = useState('');
	const [pagination, setPaginationData] = useState({});
	const [getdepartmentId, setDepartmentId] = useState(null);
	const [sectionId, setSectionId] = useState('');
	const [postId, setPostId] = useState('');
	const [filteredPost, setFilteredPost] = useState([]);
	const mounted = useRef();

	const [
		isLoading,
		fetchData,
		setFetchData,
		setIsLoading
	] = httpWithRequiredSetData(searchURL, [], data => {
		let pageNumber = [];
		for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
			pageNumber.push(i);
		}
		setPageNumber(pageNumber);
		setStaffData(data['staff']);
		setPaginationData(data['paginationMetadata']);
	});
	const [isDepartLoading, departmentData = []] = useHttpWithGetSpecificData(
		'/api/StaffPostDepart',
		[],
		'department'
	);
	const [isPostLoading, posts = []] = useHttpWithGetSpecificData(
		'/api/StaffPostDepart',
		[],
		'post'
	);
	debugger;
	const onPostChange = postId => {
		debugger;
		setIsLoading(true);
		setPostId(postId);
		props.sharedAction
			.getDataWithoutDispatch(
				`/api/DepartmentalPostStaff?departmentId=${getdepartmentId}&postId=${postId}`
			)
			.then(success => {
				debugger;
				console.log('postid data: ', success['staff']);
				setStaffData(success['staff']);
				let pageNumber = [];
				for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
					pageNumber.push(i);
				}
				setPageNumber(pageNumber);
				setPaginationData(success['paginationMetadata']);

				setIsLoading(false);
				debugger;
			})
			.catch(error => {
				debugger;
				setIsLoading(false);
			});
	};
	const onDepartmentSearch = departmentId => {
		console.log(customPageSize);
		debugger;
		setPostId('');
		setCurrentPage(1);
		let getFilteredPost = dataFilteration(posts, ['postName', 'postId'], {
			departmentId: parseInt(departmentId)
		});
		setFilteredPost(getFilteredPost);
		debugger;
		setIsLoading(true);
		setDepartmentId(departmentId);
		setDropDownSearch(true);
		props.sharedAction
			.getDataWithoutDispatch(
				`/api/DepartmentalStaff?pageNumber=${currentPage}&pageSize=${parseInt(
					customPageSize
				)}&departmentId=${departmentId}`
			)
			.then(success => {
				debugger;
				setStaffData(success['staff']);
				let pageNumber = [];
				for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
					pageNumber.push(i);
				}
				setPageNumber(pageNumber);
				setPaginationData(success['paginationMetadata']);

				setIsLoading(false);
				debugger;
			})
			.catch(error => {
				debugger;
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (!mounted.current) {
			mounted.current = true;
		} else {
			$('[data-toggle="tooltip"]').tooltip();
		}
	});
	useEffect(() => {
		return () => {
			$('[data-toggle="tooltip"]').tooltip('hide');
		};
	}, []);
	const handleClickNextFrwd = () => {
		console.log('pagelength: ', pageNumber.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumber.length) {
			setdisablepage(true);
		} else {
			setCurrentPage(prevState => prevState + 1);
			console.log(currentPage);
			debugger;
			setdisableBPage(false);
		}
	};
	useEffect(() => {
		console.log('Do something after counter has changed', currentPage);
		setIsLoading(true);
		props.sharedAction
			.getDataWithoutDispatch(
				dropDownSearch
					? `/api/DepartmentalStaff?pageNumber=${currentPage}&pageSize=${parseInt(
							customPageSize
					  )}&departmentId=${getdepartmentId}`
					: `/api/getAllStaff?pageNumber=${currentPage}&pageSize=${parseInt(
							customPageSize
					  )}`
			)
			.then(success => {
				debugger;
				setStaffData(success['staff']);
				setIsLoading(false);
				let pageNumber = [];
				for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
					pageNumber.push(i);
				}
				setPageNumber(pageNumber);
				setPaginationData(success['paginationMetadata']);

				debugger;
			})
			.catch(error => {
				debugger;
				setIsLoading(false);
			});
		debugger;
	}, [currentPage, getdepartmentId, customPageSize]);
	const handleClickNext = event => {
		setCurrentPage(Number(event.target.id));
		setdisableBPage(false);
		setdisableBPage(false);
	};
	const handleClickNextBack = () => {
		if (currentPage === 1) {
			setdisableBPage(true);
			setdisablepage(false);
		} else {
			setCurrentPage(prevState => prevState - 1);
		}
	};
	const [msg, setSnackMsg] = useState('');
	const [dId, setDId] = useState(null);
	const btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

	const onChangePageSize = e => {
		if (e.target.value !== 'Page Entries') setCustomPageSize(e.target.value);
	};
	// Delete function

	const setDeleteID = id => {
		setDId(id);
	};
	const onClickDelete = id => {
		let token = '';
		if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
		Axios.delete(`${config.localhttp}/api/Staff/${dId}`, {
			data: { id }, // or data: jdId, depending on how you handle it in the back end
			headers: {
				Authorization: 'Bearer ' + token
			}
		})
			.then(success => {
				// if (success.status === 200) {
				let getNewFilterArray = fetchData.staff.filter(item => item.id !== dId);
				let getAllData = { ...fetchData };
				getAllData.staff = getNewFilterArray;

				setSnackMsg('Remove Successfully!');
				setFetchData(getAllData);
				props.snackbar();
				setDId(null);
				// }
			})
			.catch(error => {
				console.log(error);
			});
	};

	useEffect(() => {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
	}, []);

	console.log('fetchd data: ', fetch);
	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					style={{
						padding: 2,
						border: `1px solid ${Colors.SEA_GREEN_THEME}`,
						borderColor: Colors.SEA_GREEN_THEME
					}}
					scope="row"
				>
					{index + 1}
				</th>
				{renderFields.map((item1, index) => (
					<td
						style={{
							fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
							border: `1px solid ${Colors.SEA_GREEN_THEME}`,
							padding: 2,
							borderColor: Colors.SEA_GREEN_THEME
						}}
					>
						{item[item1]}
					</td>
				))}
				<td
					style={{
						border: `1px solid ${Colors.SEA_GREEN_THEME}`,
						padding: 2,
						borderColor: Colors.SEA_GREEN_THEME
					}}
				>
					<a href="#" data-toggle="tooltip" title="Edit!">
						<Link to={`/dashboard/StaffEdit/${item.id}`}>
							<button
								id="firstbutton"
								// onClick={() => this.onEditClick(item.id)}
								onMouseOver={btnTechHover()}
								data-toggle="modal"
								data-target="#myModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i class="fas fa-pen"></i>
							</button>
						</Link>
					</a>
					<Link to={`/dashboard/PrintStaffAdmissionForm/${item.id}`}>
						<button
							id="firstbutton"
							// onClick={() => this.onEditClick(item.id)}
							onMouseOver={btnTechHover()}
							data-toggle="modal"
							data-target="#myModal"
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								marginLeft: 5,
								background: 'none',
								marginBottom: 5
							}}
						>
							<a href="#" data-toggle="tooltip" title="View!">
								<i style={{ color: '#01AC8A' }} class="fas fa-list"></i>
							</a>
						</button>
					</Link>
					<a href="#" data-toggle="tooltip" title="Delete!">
						<button
							onClick={() => setDeleteID(item.id)}
							data-toggle="modal"
							data-target="#myModal1"
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								background: 'none',
								marginLeft: 5,
								marginBottom: 5
							}}
						>
							<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
						</button>
					</a>
					<Link to={`/dashboard/staffUploadFile/${item.id}/edit`}>
						<a data-toggle="tooltip" title="Files!">
							<button
								// onClick={() => this.onEditClick(item.id)}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i
									style={{ color: Colors.LIGHT_GREEN }}
									class="fas fa-file-upload"
								></i>
							</button>
						</a>
					</Link>
					<Link to={`/dashboard/StaffImageUpload/${item.id}/edit`}>
						<a data-toggle="tooltip" title="picture">
							<button
								// onClick={() => this.onEditClick(item.id)}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i style={{ color: Colors.BLACK }} class="fas fa-portrait"></i>
							</button>
						</a>
					</Link>
				</td>
			</tr>
		));

	// const renderPageNumbers = data => {
	// 	data.map((number, index) => (
	// 		<li class={`page-item ${currentPage === number ? 'active' : ''} `}>
	// 			<a
	// 				key={number}
	// 				id={number}
	// 				onClick={handleClickNext}
	// 				class="page-link "
	// 				href="#"
	// 			>
	// 				{number}
	// 			</a>
	// 		</li>
	// 	));
	// };
	return (
		<div>
			<SnackBar msg={msg} />
			<div class="modal" id="myModal1">
				<div class="modal-dialog">
					<div class="modal-content modal_content_custom">
						<div
							style={{
								borderBottomLeftRadius: 30,
								borderBottomRightRadius: 30,
								borderTopLeftRadius: 18,
								borderTopRightRadius: 18
							}}
							class="modal-header modal_custom_header"
						>
							<h4 style={{ color: 'white' }} class="modal-title">
								Confirm
							</h4>
							<button
								style={{ color: 'white' }}
								type="button"
								class="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div class="modal-body">
							<div>
								<div style={{ textAlign: 'center' }}>
									<h4>Are you sure ?</h4>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-evenly'
									}}
								>
									<div>
										<button
											// disabled={this.state.enabled}
											data-dismiss="modal"
											onClick={() => onClickDelete(dId)}
											type="button"
											class="btn btn-primary buttonAppear"
										>
											Yes
										</button>
									</div>
									<div>
										<button
											data-dismiss="modal"
											// disabled={this.state.enabled}
											type="button"
											class="btn btn-primary buttonAppear"
										>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ textAlign: 'right' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h2 className="h1-responsive font-weight-bold text-left my-4 generalHead">
							{' '}
							Staff Details{' '}
						</h2>
					</div>
					<div
						style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
					>
						<Pagination
							disableBPage={disableBPage}
							disablepage={disablepage}
							currentPage={currentPage}
							pageNumber={pageNumber}
							handleClickNext={handleClickNext}
							handleClickNextBack={handleClickNextBack}
							handleClickNextFrwd={handleClickNextFrwd}
						/>
						<div
							style={{
								width: 'fit-content',
								marginTop: -34,
								display: 'flex',
								justifyContent: 'flex-end',
								marginLeft: 'auto'
							}}
						>
							<SelectionInput
								selectName="Entries"
								selectFont={14}
								selectFontWeight={'bold'}
								newFlag="customWidth"
								feildName="customPageSize"
								selectedText={'Page Entries'}
								optionsArrys={['10', '20', '30', '40', '50']}
								errors={{}}
								isValidte={false}
								stateData={{
									customPageSize: customPageSize
								}}
								optionType="static"
								onHandleChange={onChangePageSize}
								useFlag={false}
							/>
						</div>
					</div>
				</div>
				<CustomFilterSectionLayout displayDirection="column">
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{departmentData &&
							departmentData.map((item, index) => (
								<div>
									<Button
										btnName={item['departmentName']}
										textColor="#fff"
										btnBackColor={
											item['departmentId'] === getdepartmentId
												? Colors.DARK_SEA_GREEN_THEME
												: Colors.SEA_GREEN_THEME
										}
										buttonClass="btn-smart-one"
										onHandleSubmit={() =>
											onDepartmentSearch(item['departmentId'])
										}
									/>
								</div>
							))}
					</div>
					{dropDownSearch ? (
						<div
							style={{
								width: '30%',
								margin: '0 auto',
								display: 'flex',
								justifyContent: 'center'
							}}
						>
							<SelectionInput
								newFlag="customWidth"
								feildName="postId"
								selectedText={'Choose Post'}
								optionsArrys={filteredPost}
								errors={{}}
								isValidte={false}
								stateData={{
									postId: postId
								}}
								optionType="dynamic"
								onHandleChange={e => onPostChange(parseInt(e.target.value))}
								property={'postName'}
								propertyId={'postId'}
								useFlag={false}
							/>
						</div>
					) : (
						''
					)}
				</CustomFilterSectionLayout>

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span className="customBadge" style={{ fontSize: 14 }}>
						Page: {currentPage}
					</span>

					<span className="customBadge" style={{ fontSize: 14 }}>
						Total Page: {pagination['totalPages']}
					</span>
				</div>
			</div>
			{/* <div
				style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}
				>
				<div>
				<SelectionInput
						newFlag="customWidth"
						feildName="classId"
						selectedText={'Choose Class Name'}
						optionsArrys={fetchData && fetchData.classes}
						errors={{}}
						isValidte={false}
						stateData={{
							classId: classId
						}}
						optionType="dynamic"
						// onHandleChange={onChangeClassName}
						property={'className'}
						propertyId={'classId'}
						useFlag={false}
					/>
				</div>
				<div>
					<SelectionInput
						newFlag="customWidth"
						feildName={'sectionId'}
						selectedText={'Choose Section'}
						isValidte={false}
						optionsArrys={sections && sections.length < 1 ? [] : sections}
						errors={{}}
						stateData={{
							sectionId: sectionId
						}}
						optionType="dynamic"
						// onHandleChange={onSectionChange}
						property={'section'}
						propertyId={'sectionId'}
						useFlag={false}
					/>
				</div>
				<div style={{ marginTop: 34 }}>
					<Button
						buttonClass="searchBtnClass"
						icon={true}
						btnName={''}
						customClause={
							(sectionId !== '' && classId !== '' ? false : true) ||
							customLoader
						}
						iconSize={19}
						// onHandleSubmit={onSearch}
					/>
				</div> */}
			{/* </div> */}
			{isLoading ? (
				<Spinner />
			) : (
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								{tHead.map(item => (
									<th
										style={{
											border: `1px solid ${Colors.SEA_GREEN_THEME}`,
											borderColor: Colors.WHITE
										}}
										scope="col"
									>
										{item}
									</th>
								))}
							</tr>
						</thead>
						<tbody>{bodyRendering(staffData)}</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	null,
	mapDispatchToProps
)(StaffView);
