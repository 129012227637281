import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as FinanceAction from '../../Actions/Finance';
import * as sharedActions from '../../Actions/sharedActions';
import $ from 'jquery';
import '../Admin/viewAdmin.css';
import SnackBar from '../../components/SnackBar/SnackBar';
import TableComponent from './TableComponent';

class AccountLedger extends Component {
	constructor(props) {
		super(props);

		this.state = {
			adminData: [],
			modalData: null,
			campusName: '',
			campusId: null,
			canteenName: '',
			handlerName: '',
			handlerId: null,
			enabled: false,
			id: null,
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			select: [],
			handler: [],
			get: [],
			getlegderlist: [],
			sessions: '',
			sessionId: '1'
		};
	}
	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
			this.setState({
				disablepage: false
			});
		}
	};

	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.getlegderlist !== nextProps.getLedgerData) {
			return {
				getlegderlist: nextProps.getLedgerData
			};
		}
		if (
			prevState.adminData !== nextProps.AllTransportData ||
			prevState.sessions !== nextProps.selectSession
		) {
			return {
				adminData: nextProps.AllTransportData,
				getlegderlist: nextProps.getLedgerData,
				sessions: nextProps.selectSession
			};
		}
		return null;
	}
	resetAdminData = data => {
		console.log('data in reset function:', data);
		this.setState({
			adminData: data
		});
	};
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.gAllData('/api/Session', this.props.FinanceAction.getSessionForFinance)
			.then(success => {})
			.catch(error => {});
		// if (this.props.allTransportState.apiFlag) {
		this.props.sharedActions
			.getAllData(
				'/api/AccountLedger',
				this.props.FinanceAction.setAllFinanceInRedux,
				this.props.FinanceAction.updateFinanceFlag
			)
			.then(success => {
				this.setState({
					isLoading: false
				});
			})
			.catch(err => {
				// this.props.FinanceAction.updateFinanceFlag(false);

				this.setState({
					isLoading: false
				});

				console.log(err);
			});
		// }
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/AccountLedger/',
				itemId,
				this.props.StockActions.deleteStock
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';
		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);

		this.setState({
			sessionId: e.target.value
		});
	};
	render() {
		const { session } = this.state.sessions;
		const { adminData, modalData, select } = this.state;
		const { getlegderlist } = this.state;
		// const getlegderlist = this.state.adminData && this.state.adminData.getlegderlist;
		// console.log(this.state.adminData);
		console.log(getlegderlist);
		console.log(this.props);
		// console.log(select);

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return index <= 3 ? (
				<li
					class={`page-item ${
						this.state.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={this.handleClickNext}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			) : (
				''
			);
		});
		const MainContent = (
			<div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>

				<div class="table-wrapper-scroll-y my-custom-scrollbar">
					<div className="table">
						<table class="table table-hover">
							<thead style={{ background: '#01AC8A', color: 'white' }}>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Account</th>
									<th scope="col">Description</th>
									<th scope="col">CashOut</th>
									<th scope="col">Withdrawal</th>
									<th scope="col">CashIn</th>
									<th scope="col">Depositor</th>
									<th scope="col">Date </th>
									<th scope="col">Time </th>
									<th scope="col">Acc.Id </th>
									<th scope="col">Acc.Type </th>
									<th scope="col">Actions</th>
								</tr>
							</thead>
							<tbody>
								<TableComponent data={getlegderlist} />
							</tbody>
						</table>
					</div>
				</div>
				<br />
				<form>
					<fieldset
						style={{ border: 'solid', borderColor: '#01AC8A', height: 100 }}
					>
						<legend class="scheduler-border">Summary:</legend>
						<div class="row" style={{ marginLeft: 80 }}>
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label style={{ fontWeight: 'bold', marginRight: 5 }}>
										Withdrawl:{' '}
									</label>{' '}
									<label style={{ fontSize: 20 }}>
										ر.ع. {this.state.adminData.sumOfWidthdraw}{' '}
									</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label style={{ fontWeight: 'bold', marginRight: 5 }}>
										Deposit:
									</label>{' '}
									<label style={{ fontSize: 20 }}>
										ر.ع. {this.state.adminData.sumOfDeposite}
									</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label style={{ fontWeight: 'bold', marginRight: 5 }}>
										Balance:
									</label>{' '}
									<label style={{ fontSize: 20 }}>
										ر.ع. {this.state.adminData.balance}{' '}
									</label>
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Ledger&nbsp;Details</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								<li>
									<div class="row">
										<div class="col-md-12">
											<div class="md-form mb-0">
												<select
													onChange={this.onSelectSessionChange}
													class="custom-select"
													name="staffId"
													style={{ borderColor: '#01AC8A' }}
												>
													<option selected={this.state.msg ? true : false}>
														Choose Session...
													</option>
													{session &&
														session.map((item, index) => (
															<option key={index} value={`${item.sessionId}`}>
																{item.currentSession}
															</option>
														))}
												</select>
											</div>
										</div>
									</div>
								</li>{' '}
								<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
								<li>
									<a
										href={`http://https://embassyapp.linkit.org.uk/api/ACLedgerPdf?sessionId=${this.state.sessionId}`}
										data-toggle="tooltip"
										title="Download PDF"
									>
										<button
											type="submit"
											style={{
												cursor: 'pointer',
												fontSize: 20,
												border: 'none',
												marginLeft: 5,
												background: 'none',
												marginBottom: 5
											}}
										>
											<i
												style={{ color: '#01425e' }}
												class="fas fa-file-pdf"
											></i>
										</button>
									</a>
								</li>
								<li>
									<a
										href={`http://https://embassyapp.linkit.org.uk/api/ACLedgerCsv?sessionId=${this.state.sessionId}`}
										data-toggle="tooltip"
										title="Download CSV"
									>
										<button
											type="submit"
											style={{
												cursor: 'pointer',
												fontSize: 20,
												border: 'none',
												marginLeft: 5,
												background: 'none',
												marginBottom: 5
											}}
										>
											<i
												style={{ color: '#01425e' }}
												class="fas fa-file-csv"
											></i>
										</button>
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				{this.props.allTransportState.apiFlag ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		FinanceAction: bindActionCreators(FinanceAction, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllTransportData: state.setFinanceReducer.Data,
		allTransportState: state.setFinanceReducer,
		getLedgerData: state.setFinanceReducer.getlegderlist,
		selectSession: state.setFinanceReducer.sessionSelect,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountLedger);
