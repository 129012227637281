import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	select: []
};

export default function setVehicleReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.SET_ALL_VEHICLE_NAME_INTO_VEHICLE_SELECT:
			return {
				...state,
				select: [...action.payload]
			};

		case actionTypes.SET_ALL_ROUTE_NAME_INTO_VEHICLE_SELECT:
			return {
				...state,
				select: [...action.payload]
			};

		case actionTypes.SET_ALL_VEHICLE_NAME_INTO_DRIVER_SELECT:
			return {
				...state,
				select: [...action.payload]
			};
		case actionTypes.GET_ALL_VEHICLE:
			return { ...state, Data: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_VEHICLE:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_VEHICLE:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_VEHICLE_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.id === action.payload.id);
			adminData[index].transportType = action.payload.transportType;
			adminData[index].vehicleNo = action.payload.vehicleNo;
			adminData[index].capacity = action.payload.capacity;
			adminData[index].routeName = action.payload.routeName;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
