import * as actionTypes from '../actionTypes';

export function setAllDepartmentInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_DEPARTMENT,
		payload: allData
	};
}

export function getCampusForDepartment(allData) {
	return {
		type: actionTypes.SET_ALL_CAMPUS_NAME_INTO_DEPARTMENT_SELECT,
		payload: allData
	};
}
export function setAllSearchStaffThroughDepartInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_STAFFSEARCH_BY_DEPART,
		payload: allData
	};
}
export function getDepartmentForSearchStaff(allData) {
	return {
		type: actionTypes.SET_ALL_DEPARTMENT_NAME_INTO_SEARCHSTAFFDEPARTMENT_SELECT,
		payload: allData
	};
}
export function addDepartment(Data) {
	return {
		type: actionTypes.ADD_DEPARTMENT,
		payload: Data
	};
}
export function updateDepartmentFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_DEPARTMENT,
		payload: setFlag
	};
}
export function updateDepartment(data) {
	return {
		type: actionTypes.EDIT_DEPARTMENT_RECORD,
		payload: data
	};
}
export function deleteDepartment(id) {
	return {
		type: actionTypes.DELETE_DEPARTMENT,
		payload: id
	};
}
