import React, { Component, useState, useEffect } from 'react';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import TextInput from '../../../components/TextInput/TextInput';
import Modal from '../../../components/Modal';
import useHttp from '../../../hooks/http';

import { GeneralCheckRendering } from '../../Time-Table';
import { MainTimeTable } from '../TimeTableRendering';

const className = ['one', 'two', 'three'];

function TimeTableStep5(props) {
	const { staticDays } = props;
	const renderEachDays = flag => {
		if (flag) {
			return (
				<div
					style={{
						display: 'flex',
						position: 'relative',
						bottom: 50,
						fontSize: 10,
						justifyContent: 'center'
					}}
				>
					<GeneralCheckRendering
						onChangeCheck={props.onCheckChange}
						periodsData={staticDays}
						valuePropName="day"
						staticDisplayProp=""
						nameCheckCom="daysRendering"
					/>
				</div>
			);
		}
	};
	const renderTime = remainTime => {
		return remainTime !== '' && remainTime !== 0 ? (
			<div
				style={{
					margin: '0 auto',
					width: '60%',
					position: 'relative',
					bottom: 10
				}}
			>
				<span
					style={{ backgroundColor: '#01AC8A', color: '#fff' }}
					class="badge"
				>
					{' '}
					Remaining Time:{' '}
					{props.millisToMinutesAndSeconds(remainTime) + 'minutes'}
				</span>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'wrap',
						justifyContent: 'center',
						marginLeft: 10
					}}
				>
					<div>
						Would you like to adjust remaining time equally among all periods ,
						excluding break ?{' '}
					</div>
					<div>
						<label class="containerRadio">
							Yes
							<input
								onChange={e => props.onRMChange(e)}
								value={'Yes'}
								type="radio"
								name="optionForDistribution"
							/>
							<span class="checkmark"></span>
						</label>
					</div>
					<div>
						<label class="containerRadio">
							No
							<input
								onChange={e => props.onRMChange(e)}
								value={'No'}
								type="radio"
								name="optionForDistribution"
							/>
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
			</div>
		) : (
			''
		);
	};

	const renderingPeriod = (
		<div
			style={{
				display: 'block',
				justifyContent: 'center',
				flexDirection: 'column',
				position: 'relative',
				bottom: 0,
				left: 10,
				margin: '0 auto',
				height: '50vh'
			}}
		>
			<div>
				<h3>Select Periods</h3>
			</div>
			<div>
				<GeneralCheckRendering
					onChangeCheck={props.onChangePeriodSelect}
					valuePropName="name"
					periodsData={props.stateData.periodArr}
					nameCheckCom="PeriodRendering"
				/>
			</div>
		</div>
	);

	return (
		<div
			style={{
				fontSize: 18,
				border: '1px solid #D3D3D3',
				boxShadow: '0px 0px 15px #0A4F5E',
				padding: 10,
				borderRadius: 5,
				position: 'relative',
				bottom: 110,
				height: '50vh'
			}}
		>
			<div class="row">
				<div class="col-md-12">
					<div class="md-form mb-0">
						<label for="name" class="">
							<i
								className="fas fa-house animated fadeIn"
								style={{ marginRight: 4, margin: 0 }}
							/>
							<p style={{ fontSize: 30 }}>
								<span
									style={{ position: 'relative', bottom: 60 }}
									class="badge badge-pill badge-dark"
								>
									<p style={{ margin: 0, color: '#fff' }}>Effective Days</p>
								</span>
							</p>
						</label>
						{props.stateData.errors.step4 ? (
							<p
								style={{
									color: 'red',
									fontSize: 17,
									textAlign: 'center',
									position: 'relative',
									bottom: 50,
									margin: 0
								}}
							>
								Please Fill Properly!
							</p>
						) : (
							''
						)}
						<br />
						<div style={{ position: 'relative', bottom: 40 }}>
							<label class="containerRadio">
								<p style={{ fontSize: 19, color: '#000' }}>Mon to Fri</p>
								<input
									onChange={e => props.onRadioChange(e)}
									value={'Mon to Fri'}
									type="radio"
									name="workDayOptions"
								/>
								<span class="checkmark"></span>
							</label>
							<label class="containerRadio">
								<p style={{ fontSize: 19, color: '#000' }}>Mon to Sat</p>
								<input
									onChange={e => props.onRadioChange(e)}
									value={'Mon to Sat'}
									type="radio"
									name="workDayOptions"
								/>
								<span class="checkmark"></span>
							</label>
							<label class="containerRadio">
								<p style={{ fontSize: 19, color: '#000' }}>Others</p>
								<input
									onChange={e => props.onRadioChange(e)}
									value={'Others'}
									type="radio"
									name="workDayOptions"
								/>
								<span class="checkmark"></span>
							</label>
						</div>
						{renderEachDays(props.stateData.renderEachDays)}
					</div>
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							position: 'relative',
							bottom: 100
						}}
					>
						<Modal
							mdId="exampleModalLong"
							animation="fade"
							modalTitle="Confirmation"
							modalBody="body modal"
							modalSize="modal-lg"
							handleSubmit={props.onDone}
							stateData={props.stateData}
							header={true}
							topCloseButton={true}
						>
							<div class="wraphai">
								<div class="testhai">Details Review</div>
								<div className="table-responsive">
									<table className="table table-bordered table-hover">
										<tbody>
											<tr>
												<td> Official Timing:</td>
												<td>
													Start Time:{' '}
													{new Date(
														props.stateData.offSDate
													).toLocaleTimeString()}{' '}
													to End Time:
													{new Date(
														props.stateData.offEDate
													).toLocaleTimeString()}{' '}
												</td>
											</tr>
											<tr>
												<td>Per Period Time:</td>
												<td> {props.msToTime(props.stateData.getPreDiff)}</td>
											</tr>
											<tr>
												<td>No Of Periods:</td>
												<td> {props.stateData.noOfPeriods}</td>
											</tr>
											<tr>
												<td>Break Periods:</td>
												<td> {props.stateData.breakNo}</td>
											</tr>
											<tr>
												<td> Break Timing:</td>
												<td>
													Start Time:{' '}
													{new Date(
														props.stateData.BsTime
													).toLocaleTimeString()}{' '}
													to End Time:
													{new Date(
														props.stateData.BeTime
													).toLocaleTimeString()}{' '}
												</td>
											</tr>
											<tr>
												<td>Working Days:</td>
												<td>
													{' '}
													{props.stateData.workingDays.map(item =>
														!item.off ? (
															<span style={{ padding: 5 }}>
																{item.day.substring(0, 3)}{' '}
															</span>
														) : (
															''
														)
													)}
												</td>
											</tr>
											<tr>
												<td>Class Name:</td>
												<td> {props.stateData.className}</td>
											</tr>
											<tr>
												<td>Grade Name:</td>
												<td> {props.stateData.gradeName}</td>
											</tr>

											<tr>
												<td>Section:</td>
												<td> {props.stateData.section}</td>
											</tr>
											{props.stateData.readyToDone !== null ? (
												<tr>
													<td>Remaining Time:</td>
													<td>
														{' '}
														{props.msToTime(props.stateData.getRemainingTime)}
													</td>
												</tr>
											) : (
												''
											)}
											{props.stateData.readyToDone ? (
												<tr>
													<td>Remaining Time Distribution</td>
													<td>Done Among All Periods</td>
												</tr>
											) : (
												''
											)}
											{!props.stateData.readyToDone ? (
												<tr>
													<td>Remaining Time Distribution</td>
													<td>
														{props.stateData.selectedDisPeriod.map(item => (
															<span style={{ padding: 5 }}>{item.name} </span>
														))}
													</td>
												</tr>
											) : (
												''
											)}
											{/* <tr>
                  <td  >No Of Periods:</td>
                </tr> */}
										</tbody>
									</table>
								</div>
							</div>
						</Modal>
						<Modal
							header={true}
							mdId="newtimeTableModal"
							animation="fade"
							modalTitle="Time Table"
							mainId="secondId"
							// titleColor='#fff'
							modalSize="modal-xl"
							backdrop={'static'}
							datakey={'false'}
							fullModal={'modal-full'}
							handleSubmit={props.onSubmit}
							stateData={props.stateData}
							topCloseButton={false}
							bottomCloseButtonName={'Discard'}
							bottomSaveButtonName={'Submit'}
							handleCloseButton={props.onDiscard}
							closeHandler={false}
						>
							<MainTimeTable
								timeData={props.timeTable}
								msToTime={props.msToTime}
								stateData={props.stateData}
								workingDays={props.stateData.workingDays}
								PeriodArray={props.stateData.periodArr}
								AllPeriods={props.stateData.finalArray}
								onChangeNewPeriodData={props.onChangeNewPeriodData}
								handleCloseButton={props.onDiscard}
							/>
						</Modal>
					</div>
				</div>
				{props.stateData.renderRemainTimeflag
					? renderTime(props.stateData.getRemainingTime)
					: ''}
				{props.stateData.readyToDone === true ? (
					<p style={{ textAlign: 'center' }}>Done!</p>
				) : props.stateData.readyToDone === false ? (
					renderingPeriod
				) : (
					''
				)}
			</div>
			{/* <button class="btn btn-primary" onClick={() => this.props.stepperFun()}>Next</button> */}
		</div>
	);
}
export default TimeTableStep5;
