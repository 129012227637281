import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import Colors from '../../../utils/app_constants/colors_constants';
import Spinner from '../../../components/Spinner/Spinner';
class ViewBillingTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			voucherData: {},
			vouchers: {},
			voucherDescription: [],
			total: '',
			isLoading: true
		};
	}
	componentDidMount() {
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/BillingVoucher/${this.props.match.params.id}`
			)
			.then(success => {
				this.setState({
					voucherData: success,
					vouchers: success.vouchers,
					voucherDescription: success.VoucherDescriptions,
					total: success.total,
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});
			});
	}
	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};
	render() {
		const { vouchers, voucherDescription, total, isLoading } = this.state;
		const renderVouchers = voucherDescription.map((item, index) => {
			return (
				<tr
					key={index}
					style={{
						border: `1px solid ${Colors.SEA_GREEN_THEME}`,
						padding: 2,
						borderColor: Colors.SEA_GREEN_THEME
					}}
				>
					<th
						style={{
							border: `1px solid ${Colors.SEA_GREEN_THEME}`,
							borderColor: Colors.SEA_GREEN_THEME
						}}
					>
						{index + 1}
					</th>
					<td
						style={{
							border: `1px solid ${Colors.SEA_GREEN_THEME}`,
							borderColor: Colors.SEA_GREEN_THEME
						}}
					>
						{item.paymentMethod}
					</td>
					<td
						style={{
							border: `1px solid ${Colors.SEA_GREEN_THEME}`,
							borderColor: Colors.SEA_GREEN_THEME
						}}
					>
						{item.id}
					</td>
					<td
						style={{
							border: `1px solid ${Colors.SEA_GREEN_THEME}`,
							borderColor: Colors.SEA_GREEN_THEME
						}}
					>
						{item.quantity}
					</td>
					<td
						width="50%"
						style={{
							border: `1px solid ${Colors.SEA_GREEN_THEME}`,
							borderColor: Colors.SEA_GREEN_THEME
						}}
					>
						{item.description}
					</td>
					<td
						width="20%"
						style={{
							border: `1px solid ${Colors.SEA_GREEN_THEME}`,
							borderColor: Colors.SEA_GREEN_THEME
						}}
					>
						{item.amount}
					</td>
				</tr>
			);
		});
		const voucher = (
			<div class="container">
				<div class="divclass">
					<img src={require('../../../Assets/images/3 headers-03.jpg')} />
				</div>
				<div class="row">
					<div class="col-md-4">
						<input
							type="image"
							src={require('../../../Assets/images/logo1.png')}
							class="rounded mx-auto d-block"
							style={{ width: 350, height: 250 }}
							alt="school-smart"
						/>
						<div>
							Address:{' '}
							<b class="fontBoldColor">
								2nd Floor, Tamimah Building, Al Nahdah Road, Al Wattayah,
								Muscat.{' '}
							</b>
						</div>
						<div>
							City State Zip Code: <b class="fontBoldColor">111</b>
						</div>
						<div>
							Telephone: <b class="fontBoldColor">+96891216688 </b>
						</div>
						<div>
							Email: <b class="fontBoldColor">linkitoman@gmail.com </b>
						</div>
					</div>
					<div class="col-md-3"></div>
					<div class="col-md-5" style={{ marginTop: 60 }}>
						<div>
							<table class="table table-hover table-sm">
								<thead
									class="headCol bordersSpace"
									style={{
										background: '#01AC8A',
										color: 'white',
										textAlign: 'center'
									}}
								>
									<tr class="vendorListHeading bordersSpace">
										<td
											class="bordersSpace"
											colspan="2"
											style={{ fontSize: 30 }}
										>
											PAYMENT VOUCHER
										</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th className="bordersSpace">Date:</th>
										<td className="bordersSpace">{vouchers.date}</td>
									</tr>
									<tr>
										<th className="bordersSpace" scope="row">
											Voucher No:
										</th>
										<td className="bordersSpace">{vouchers.id}</td>
									</tr>
									<tr>
										<th className="bordersSpace" scope="row">
											Ledger:
										</th>
										<td className="bordersSpace">{vouchers.ledger}</td>
									</tr>
									<tr>
										<th className="bordersSpace" scope="row">
											Accounts:
										</th>
										<td className="bordersSpace">{vouchers.account}</td>
									</tr>
									<tr>
										<th className="bordersSpace" scope="row">
											Pay To:
										</th>
										<td className="bordersSpace">{vouchers.payTo}</td>
									</tr>
									<tr>
										<th className="bordersSpace" scope="row">
											Amount:
										</th>
										<td className="bordersSpace">{vouchers.amount}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<br />
				<div
					class="row text-center"
					style={{ border: 'solid', borderColor: '#01425e' }}
				>
					<div class="col-md-4">
						Session: &nbsp;
						<label
							for="name"
							class=""
							style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
						>
							{vouchers.session}
						</label>
					</div>
					<div class="col-md-4">
						Month: &nbsp;
						<label
							for="name"
							class=""
							style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
						>
							{vouchers.month}
						</label>
					</div>
					<div class="col-md-4">
						Status: &nbsp;
						<label
							for="name"
							class=""
							style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
						>
							{vouchers.status}
						</label>
					</div>
				</div>
				<div style={{ marginTop: 30 }}>
					<div className="table-responsive">
						<table class="table text-center bordersSpace table-striped">
							<thead class="headCol bordersSpace font-weight-bold">
								<tr class="vendorListHeading bordersSpace">
									<td class="bordersSpace">#</td>
									<td class="bordersSpace">Payment Method</td>
									<td class="bordersSpace"> Reference No.</td>
									<td class="bordersSpace">Quantity</td>
									<td class="bordersSpace">Description</td>
									<td class="bordersSpace">Amount</td>
								</tr>
							</thead>
							<tbody>
								{renderVouchers}
								<tr
									style={{
										border: `1px solid ${Colors.SEA_GREEN_THEME}`,
										padding: 2,
										borderColor: Colors.SEA_GREEN_THEME
									}}
								>
									<td
										colspan="5"
										className="bordersSpace text-right"
										style={{ fontWeight: 'bold', fontSize: 20 }}
									>
										Total:{' '}
									</td>
									<td
										className="bordersSpace"
										style={{ fontWeight: 'bold', fontSize: 20 }}
									>
										{total}/.
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div
					class="row text-center"
					style={{
						marginTop: 100,
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<div class="col-md-6">
						<h5 class="font-weight-bold" style={{ textDecoration: 'overline' }}>
							Authorized By
						</h5>
					</div>
					<div class="col-md-6">
						<h5 class="font-weight-bold" style={{ textDecoration: 'overline' }}>
							Recieved By
						</h5>
					</div>
				</div>
			</div>
		);
		return (
			<div>
				<div id="div1">{isLoading ? <Spinner /> : voucher}</div>
				<div class="text-center text-md-right">
					<label for="name" class="" onClick={() => this.PrintContent('div1')}>
						<i
							className="fas fa-print fa-2x animated fadeIn"
							style={{ marginRight: 4 }}
						/>
						Print
					</label>
				</div>
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	null,
	mapDispatchToProps
)(ViewBillingTemplate);
