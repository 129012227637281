import * as actionTypes from '../actionTypes';

export function getCampusForCanteen(allData) {
	return {
		type: actionTypes.SET_ALL_CAMPUS_NAME_INTO_CANTEEN_SELECT,
		payload: allData
	};
}

export function getCategoryForItems(allData) {
	return {
		type: actionTypes.SET_ALL_CATEGORY_NAME_INTO_ITEMS_SELECT,
		payload: allData
	};
}

export function getCanteenForCategory(allData) {
	return {
		type: actionTypes.SET_ALL_CANTEEN_NAME_INTO_CATEGORY_SELECT,
		payload: allData
	};
}

export function getHandlerForCanteen(allData) {
	return {
		type: actionTypes.SET_ALL_HANDLER_NAME_INTO_CANTEEN_SELECT,
		payload: allData
	};
}

export function setAllCanteenItemsInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_CANTEEN_ITEMS,
		payload: allData
	};
}

export function addCanteenItems(Data) {
	return {
		type: actionTypes.ADD_CANTEEN_ITEMS,
		payload: Data
	};
}

export function updateCanteenItemsFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_CANTEEN_ITEMS,
		payload: setFlag
	};
}

export function updateCanteenItems(data) {
	return {
		type: actionTypes.EDIT_CANTEEN_ITEMS_RECORD,
		payload: data
	};
}

export function deleteCanteenItems(id) {
	return {
		type: actionTypes.DELETE_CANTEEN_ITEMS,
		payload: id
	};
}
