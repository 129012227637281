import React, { Component } from 'react';
import './DisciplineCard.css';
import CardsForDiscipline from './CardsForDiscipline';
import * as sharedActions from '../../../Actions/sharedActions';
import * as ClassDisciplineAction from '../../../Actions/ClassDiscipline';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SnackBar from '../../../components/SnackBar/SnackBar';
import $ from 'jquery';

class AddClassDiscipline extends Component {
	constructor(props) {
		super(props);
		this.state = {
			classWrn1: '',
			classWrn2: '',
			classAction: '',
			homeWorkWrn1: '',
			homeWorkWrn2: '',
			homeWorkAction: '',
			forgettenBooksWrn1: '',
			forgettenBooksWrn2: '',
			forgettenBooksAction: '',
			lateWrn1: '',
			lateWrn2: '',
			lateAction: '',
			BehaviourWrn1: '',
			BehaviourWrn2: '',
			BehaviourAction: '',
			otherWrn1: '',
			otherWrn2: '',
			otherAction: '',

			msg: ''
		};
	}
	componentDidUpdate() {
		$('.tooltip').tooltip('hide');
	}
	componentDidMount() {
		$('.tooltip').tooltip('hide');
	}
	onDropChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		this.setState({
			btnLoad: true
		});
		const {
			classWrn1,
			classWrn2,
			classAction,
			homeWorkWrn1,
			homeWorkWrn2,
			homeWorkAction,
			forgettenBooksWrn1,
			forgettenBooksWrn2,
			forgettenBooksAction,
			lateWrn1,
			lateWrn2,
			lateAction,
			BehaviourWrn1,
			BehaviourWrn2,
			BehaviourAction,
			otherWrn1,
			otherWrn2,
			otherAction
		} = this.state;
		const { state } = this.props.location;
		var bodyParameters = {
			sessionId: state.sessionId,
			classId: state.classId,
			sectionId: state.sectionId,
			subjectId: state.subjectId,
			staffId: state.staffId,
			studentId: state.studentId,
			term: state.term,
			accountId: state.accountId,
			accountType: state.accountType,
			classWrn1,
			classWrn2,
			classAction,
			homeWorkWrn1,
			homeWorkWrn2,
			homeWorkAction,
			forgettenBooksWrn1,
			forgettenBooksWrn2,
			forgettenBooksAction,
			lateWrn1,
			lateWrn2,
			lateAction,
			BehaviourWrn1,
			BehaviourWrn2,
			BehaviourAction,
			otherWrn1,
			otherWrn2,
			otherAction
		};
		this.props.sharedActions
			.addRecord(
				'/api/DisciplineClassInfo',
				bodyParameters,
				this.props.ClassDisciplineAction.addClassDiscipline
			)
			.then(success => {
				debugger;
				this.setState({
					msg: 'Discipline Information Added Successfully',
					btnLoad: false
				});
				setTimeout(() => {
					this.props.history.push({
						pathname: '/dashboard/ClassDiscipline'
					});
				}, 3000);
				this.props.snackbar();
				// this.props.campusActions.updateCampusFlag(true);
			})
			.catch(err => {
				debugger;
				this.setState({
					msg: err.response.data.Message,
					btnLoad: false
				});
				this.props.snackbar();
				console.log(err);
			});
	};
	render() {
		const { state } = this.props.location;
		const DataForDiscipline = [
			{
				Heading: 'Class Work',
				name1: 'classWrn1',
				name2: 'classWrn2',
				name3: 'classAction',
				state1: this.state.classWrn1,
				state2: this.state.classWrn2,
				state3: this.state.classAction,
				id: 1
			},
			{
				Heading: 'Home Work',
				name1: 'homeWorkWrn1',
				name2: 'homeWorkWrn2',
				name3: 'homeWorkAction',
				state1: this.state.homeWorkWrn1,
				state2: this.state.homeWorkWrn2,
				state3: this.state.homeWorkAction,
				id: 2
			},
			{
				Heading: 'Forgetting Book',
				name1: 'forgettenBooksWrn1',
				name2: 'forgettenBooksWrn2',
				name3: 'forgettenBooksAction',
				state1: this.state.forgettenBooksWrn1,
				state2: this.state.forgettenBooksWrn2,
				state3: this.state.forgettenBooksAction,
				id: 3
			},
			{
				Heading: 'Late Coming',
				name1: 'lateWrn1',
				name2: 'lateWrn2',
				name3: 'lateAction',
				state1: this.state.lateWrn1,
				state2: this.state.lateWrn2,
				state3: this.state.lateAction,
				id: 4
			},
			{
				Heading: 'Behaviour',
				name1: 'BehaviourWrn1',
				name2: 'BehaviourWrn2',
				name3: 'BehaviourAction',
				state1: this.state.BehaviourWrn1,
				state2: this.state.BehaviourWrn2,
				state3: this.state.BehaviourAction,
				id: 5
			},
			{
				Heading: 'Other',
				name1: 'otherWrn1',
				name2: 'otherWrn2',
				name3: 'otherAction',
				state1: this.state.otherWrn1,
				state2: this.state.otherWrn2,
				state3: this.state.otherAction,
				id: 6
			}
		];
		console.log(this.props);
		return (
			<div>
				<SnackBar backColor={'#000'} msg={this.state.msg} />
				<link
					href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
					rel="stylesheet"
					id="bootstrap-css"
				/>
				<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
				<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>

				<section id="team" class="pb-5">
					<div class="container">
						<div class="" style={{ marginTop: -30 }}>
							<div class="row justify-content-between">
								<div class="col-md-4">
									<div class="md-form mb-0">
										<label for="name" class="">
											Term:
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</label>
										<label
											for="name"
											class=""
											style={{
												fontWeight: 'bold',
												fontSize: 20,
												color: '#01425e'
											}}
										>
											{state.term}
										</label>
									</div>
								</div>
								<div class="col-md-4">
									<div class="md-form mb-0">
										<h4 class="text-uppercase" style={{ color: '#007b5e' }}>
											Add Class Discipline
										</h4>
									</div>
								</div>
								<div class="col-sm-3">
									<div class="md-form mb-0">
										<label for="name" class="">
											Class: &nbsp;&nbsp;&nbsp;&nbsp;
										</label>
										<label
											for="name"
											class=""
											style={{
												fontWeight: 'bold',
												fontSize: 20,
												color: '#01425e'
											}}
										>
											{state.className}
										</label>
									</div>
								</div>
							</div>
							<div class="row justify-content-between">
								<div class="col-md-4">
									<div class="md-form mb-0">
										<label for="name" class="">
											Session: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</label>
										<label
											for="name"
											class=""
											style={{
												fontWeight: 'bold',
												fontSize: 20,
												color: '#01425e'
											}}
										>
											{state.sessionName}
										</label>
									</div>
								</div>
								<div class="col-sm-3">
									<div class="md-form mb-0">
										<label for="name" class="">
											Section: &nbsp;
										</label>
										<label
											for="name"
											class=""
											style={{
												fontWeight: 'bold',
												fontSize: 20,
												color: '#01425e'
											}}
										>
											{state.sectionName}
										</label>
									</div>
								</div>
							</div>

							<div class="row justify-content-between">
								<div class="col-md-4">
									<div class="md-form mb-0">
										<label for="name" class="">
											Student: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</label>
										<label
											for="name"
											class=""
											style={{
												fontWeight: 'bold',
												fontSize: 20,
												color: '#01425e'
											}}
										>
											{state.studentName}
										</label>
									</div>
								</div>
								<div class="col-sm-3">
									<div class="md-form mb-0">
										<label for="name" class="">
											Subject: &nbsp;
										</label>
										<label
											for="name"
											class=""
											style={{
												fontWeight: 'bold',
												fontSize: 20,
												color: '#01425e'
											}}
										>
											{state.subjectName}
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							{DataForDiscipline.map((item, index) => (
								<div class="col-xs-12 col-sm-6 col-md-4">
									<CardsForDiscipline
										key={index}
										info={item}
										onChangeDrop={this.onDropChange}
										stateData={this.state}
									/>
								</div>
							))}
						</div>
						<div class="text-right">
							<button
								type="submit"
								onClick={this.onHandleSubmit}
								class="buttonHoverFill"
							>
								Add
							</button>
						</div>
					</div>
				</section>
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		ClassDisciplineAction: bindActionCreators(ClassDisciplineAction, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	null,
	mapDispatchToProps
)(AddClassDiscipline);
