import React, { Component } from 'react';
import Loader from '../../components/HOC/LoaderWrapper';
import DateandTime from '../Transport/DateandTime';
import * as sharedActions from '../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SnackBar from '../../components/SnackBar/SnackBar';
import LoaderWrapper from '../../components/HOC/LoaderWrapper';

class addDean extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.location.state.id,
			isLoading: false,
			Budget: [],
			AccountsData: [],
			backColor: '',
			session: [],
			sessionId: '',
			deanPost: this.props.location.state.deanPost,
			account: '',
			ledger: '',
			staffId: '',
			errorMsg: false,
			msg: false
		};
	}

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const { deanPost, id, staffId } = this.state;
		if (
			Object.is(id, '') ||
			Object.is(staffId, '') ||
			Object.is(deanPost, '')
		) {
			this.setState({
				errorMsg: true
			});
		} else {
			const data = {
				deanPostId: id,
				deanPostName: deanPost,
				staffId,
				accountType: this.props.role,
				accountId: this.props.name
			};
			this.props.sharedActions
				.simpleAddRequest('/api/Dean ', data)
				.then(success => {
					this.setState({
						errorMsg: false,
						ledger: '',
						amount: '',
						account: '',
						sessionId: '',
						payTo: '',
						msg: 'Dean Added Successfully',
						backColor: '#01ac8a'
					});
					this.props.snackbar();
				})
				.catch(error => {
					this.setState({
						msg: error.response.data.Message,
						backColor: 'red'
					});
					this.props.snackbar();
				});
		}
		console.log(this.state);
	};
	componentDidMount() {}

	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}

	render() {
		console.log('Redirect Data', this.props.location.state.deanPost);
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Dean
					</h2>

					<SnackBar msg={this.state.msg} backColor={this.state.backColor} />
					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							<LoaderWrapper isLoading={this.state.isLoading}>
								<form id="contact-form" name="contact-form">
									<div class="row">
										<div class="col-md-12">
											<div class="md-form mb-0">
												<DateandTime />
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="font-weight-bold">
													Admin Name
												</label>
												<input
													value={this.props.name}
													readOnly={true}
													type="text"
													name="AdminName"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="font-weight-bold">
													Admin Type
												</label>
												<input
													value={this.props.role}
													readOnly={true}
													type="text"
													name="AdminType"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="font-weight-bold">
													Post Name
												</label>
												<input
													disabled={true}
													placeholder="Pay to"
													type="text"
													name="payTo"
													class="form-control"
													value={this.state.deanPost}
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="email" class="font-weight-bold">
													Staff Id
												</label>
												<input
													placeholder="Enter Amount"
													type="number"
													name="staffId"
													value={this.state.staffId}
													className="form-control check"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
									</div>
									<br />
									<br />
									{this.state.errorMsg ? (
										<p style={{ color: 'red', textAlign: 'center' }}>
											Please fill the form properly
										</p>
									) : (
										''
									)}
									<div class="text-right text-md-right">
										<button onClick={this.onHandleSubmit} class="buttonHover2">
											Add
										</button>
									</div>
								</form>
							</LoaderWrapper>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(addDean);
