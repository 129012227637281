import React from 'react';
import ViewRendering from '../../../components/ViewRendering';
import { renderingStuff, images } from '../../../utils';

const LPWScreen = () => {
	return (
		<React.Fragment>
			<ViewRendering
				backColor={true}
				mainHead={true}
				mainHeading={images.imgTThead}
				data={renderingStuff.LPWLinks}
			/>
		</React.Fragment>
	);
};

export default LPWScreen;
