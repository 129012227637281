import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';

class ViewBook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bookData: [],
			search: '',
			isLoading: true,
			modalEdit: false,
			editToggle: false,
			msg: '',
			editIndex: '',
			classAndSubject: null,
			accountId: null,
			accountType: null,
			id: null,
			bookName: null,
			className: null,
			category: null,
			author: null,
			quantity: null,
			barCodeNo: null
		};
	}

	componentDidMount() {
		this.getBookData();
		this.getSimple('classSectionGradeTeacher', 'classAndSubject');
	}

	getSimple = (type, state) => {
		this.props.sharedActions
			.getDataWithoutDispatch(`/api/${type}`)
			.then(success => {
				this.setState({
					[state]: success,
					isLoading: false
				});
			})
			.catch(error => {
				this.setState({
					errorMsg: true,
					successMsg: false,
					msg: error.response.data.Message || 'Error Occured!!',
					isLoading: false
				});
				console.error('Error Name: ', error);
			});
	};

	getBookData = () => {
		this.props.sharedActions
			.getDataWithoutDispatch('/api/Books')
			.then(success => {
				this.setState({
					bookData: success,
					isLoading: false
				});
			})
			.catch(error => {
				this.setState({
					errorMsg: true,
					successMsg: false,
					msg: error.response.data.Message || 'Error Occured!!',
					isLoading: false
				});
				console.error('Error Name: ', error);
			});
	};

	onEditClick = id => {
		const { bookData } = this.state;
		let modalData = bookData.filter((items, index) => {
			if (id === items.id) {
				this.setState({
					editIndex: index
				});
				console.log(index);
				return id === items.id;
			}
		});
		console.log(modalData);
		this.setState(
			{
				id: modalData[0].id,
				bookName: modalData[0].bookName,
				className: modalData[0].className,
				category: modalData[0].category,
				author: modalData[0].author,
				quantity: modalData[0].quantity,
				barCodeNo: modalData[0].barCodeNo,
				accountId: modalData[0].accountId,
				accountType: modalData[0].accountType
			},
			() => {
				$('#myModal1').modal({
					show: true
				});
			}
		);
	};

	onDeleteClick = id => {
		const { bookData } = this.state;
		let modalData = bookData.filter((items, index) => {
			if (id === items.id) {
				this.setState({
					editIndex: index
				});
				console.log(index);
				return id === items.id;
			}
		});
		console.log(id);
		this.setState(
			{
				modalDataDeleteId: id
			},
			() => {
				$('#myModal2').modal({
					show: true
				});
			}
		);
	};

	handleDelete = id => {
		const { editIndex } = this.state;

		this.props.sharedActions
			.deleteRecordWithoutDispatch('/api/Books', id)
			.then(success => {
				this.state.bookData.splice(editIndex, 1);

				this.setState({
					msg: 'Record Deleted!'
				});
				this.props.snackbar();
			})
			.catch(error => {
				this.setState({
					errorMsg: true,
					successMsg: false,
					msg: error.response.data.Message || 'Error Occured!!',
					isLoading: false
				});
				this.props.snackbar();
			});
	};

	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {}, 3000);
	};

	handleSubmit = e => {
		e.preventDefault();
		const {
			id,
			accountId,
			accountType,
			bookName,
			className,
			category,
			author,
			quantity,
			barCodeNo
		} = this.state;
		let data = {
			id: id,
			accountId: accountId,
			accountType: accountType,
			className: className,
			category: category,
			bookName: bookName,
			author: author,
			quantity: quantity,
			barCodeNo: barCodeNo
		};
		this.props.sharedActions
			.editRecordWithoutDispatch('/api/Books/', id, data)
			.then(success => {
				console.log('success', success);
				this.state.bookData.splice(this.state.editIndex, 1, data);
				this.setState(
					{
						editIndex: '',
						accountId: null,
						accountType: null,
						id: null,
						bookName: null,
						className: null,
						category: null,
						author: null,
						quantity: null,
						barCodeNo: null
					},
					() => {
						$('#myModal1').modal('hide');
					}
				);

				this.setState({
					msg: 'Record Changed Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {
				console.error('Error name: ', error);

				$('#myModal1').modal('hide');

				this.setState({
					errorMsg: true,
					successMsg: false,
					msg: error.response.data.Message || 'Error Occured!!',
					isLoading: false
				});
				this.props.snackbar();
			});
	};

	handleSearch = e => {
		e.preventDefault();
		const { search } = this.state;
		if (isEmpty(search)) {
			this.getBookData();
		} else {
			this.setState({
				isLoading: true
			});
			this.props.sharedActions
				.getDataWithoutDispatch(`/api/Books/?barcode=${search}`)
				.then(success => {
					this.setState({
						bookData: success,
						isLoading: false
					});
				})
				.catch(error => {
					this.setState({
						msg: error.response.data.Message || 'Error Occured!!',
						isLoading: false
					});
					this.props.snackbar();
					console.error('Error occured!: ', error);
				});
		}
	};

	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const {
			bookData,
			search,
			isLoading,
			editToggle,
			classAndSubject,
			accountId,
			accountType,
			id,
			bookName,
			className,
			category,
			author,
			quantity,
			barCodeNo,
			msg
		} = this.state;

		const deleteBookData = (
			<div>
				<div className="modal" id="myModal2">
					<div className="modal-dialog">
						<div className="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								className="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} className="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									on
									type="button"
									className="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div className="modal-body">
								<div>
									<div style={{ textAlign: 'center' }}>
										<h4>Are you sure?</h4>
									</div>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-evenly'
										}}
									>
										<div>
											<button
												data-dismiss="modal"
												onClick={() =>
													this.handleDelete(this.state.modalDataDeleteId)
												}
												type="button"
												class="btn btn-primary buttonAppear"
											>
												Yes
											</button>
										</div>
										<div>
											<button
												data-dismiss="modal"
												type="button"
												class="btn btn-primary buttonAppear"
											>
												No
											</button>
										</div>
									</div>
								</div>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									onClick={() => this.setState({ editToggle: false })}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
		const editBookData = (
			<div>
				<div className="modal" id="myModal1">
					<div className="modal-dialog">
						<div className="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								className="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} className="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									on
									type="button"
									className="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={this.handleSubmit}>
									<fieldset disabled={!editToggle}>
										<div className="form-group">
											<label for="bookName">Book Name:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												value={bookName}
												onChange={this.onHandleText}
												name="bookName"
												className="form-control"
												id="bookName"
											/>
										</div>
										<div className="form-group">
											<label for="category">Class Name:</label>
											<select
												class="custom-select"
												value={className}
												name="className"
												onChange={this.onHandleTextChange}
												disabled={!editToggle}
											>
												{classAndSubject &&
													classAndSubject.classes.map((value, index) => {
														return (
															<option value={value.className}>
																{value.className}
															</option>
														);
													})}
											</select>
										</div>
										<div className="form-group">
											<label for="category">Category:</label>
											<select
												class="custom-select"
												value={category}
												name="category"
												onChange={this.onHandleTextChange}
												disabled={!editToggle}
											>
												{className &&
													classAndSubject.subject.map((value, index) => {
														return (
															classAndSubject.classes.find(
																o => o.className === className
															).classId == value.classId && (
																<option value={value.subjectName}>
																	{value.subjectName}
																</option>
															)
														);
													})}
											</select>
										</div>
										<div className="form-group">
											<label for="author">Author:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												value={author}
												onChange={this.onHandleText}
												name="author"
												className="form-control"
												id="author"
											/>
										</div>
										<div className="form-group">
											<label for="quantity">Quantity:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												value={quantity}
												onChange={this.onHandleText}
												name="quantity"
												className="form-control"
												id="quantity"
											/>
										</div>
										<div className="form-group">
											<label for="barCodeNo">Barcode</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												value={barCodeNo}
												disabled
												onChange={this.onHandleText}
												name="barCodeNo"
												className="form-control"
												id="barCodeNo"
											/>
										</div>
										<div className="form-group">
											<label for="accountId">Account Name:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={accountId}
												name="modalDataAccountId"
												className="form-control"
												id="accountId"
											/>
										</div>
										<div className="form-group">
											<label for="accountType">Account Type:</label>
											<input
												style={{
													borderTop: 0,
													borderRight: 0,
													borderLeft: 0
												}}
												type="text"
												disabled
												value={accountType}
												name="modalDataAccountType"
												className="form-control"
												id="accountType"
											/>
										</div>
									</fieldset>
									<br />
									<br />
									<button
										disabled={editToggle}
										onClick={() => this.setState({ editToggle: true })}
										type="button"
										class="btn btn-primary buttonAppear"
									>
										Edit
									</button>
									<button
										style={{ marginLeft: 5 }}
										disabled={!editToggle}
										type="submit"
										class="btn btn-primary buttonAppear"
									>
										Save
									</button>
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									onClick={() => this.setState({ editToggle: false })}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);

		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);

		const renderBookData = bookData.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" style={{ padding: 1 }}>
						{index + 1}
					</th>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.bookName}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.accountId}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.accountType}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.className}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.category}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.author}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.quantity}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						{item.barCodeNo}
					</td>
					<td className="borderSpace" style={{ padding: 1 }}>
						<a href="#" data-toggle="tooltip" title="Edit!">
							<button
								id="firstbutton"
								onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</a>
						<a href="#" data-toggle="tooltip" title="Delete!">
							<button
								onClick={() => this.onDeleteClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i
									style={{ color: '#D11A2A' }}
									className="fas fa-trash-alt"
								></i>
							</button>
						</a>
					</td>
				</tr>
			);
		});

		return (
			<div className="page-header">
				<SnackBar msg={msg} />
				<div
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}
				>
					<div class="row">
						<div class="col-md-6">
							<h2>Books</h2>
						</div>
						<div class="col-md-6">
							<form
								class="form-inline float-right"
								onSubmit={this.handleSearch}
							>
								<input
									class="form-control mr-sm-2"
									type="search"
									name="search"
									placeholder="Barcode"
									value={search}
									onChange={this.onHandleText}
									aria-label="Barcode"
								/>
								<button
									color="teal"
									class="btn btn-primary my-2 my-sm-0"
									type="submit"
								>
									Search
								</button>
							</form>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-hover text-center table-bordered">
							<thead style={{ background: '#01AC8A', color: 'white' }}>
								<tr>
									<th scope="col" style={{ padding: 1 }}>
										#
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Book Name
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Account Name
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Account Role
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Class
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Category
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Author
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Quantity
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Barcode
									</th>
									<th scope="col" style={{ padding: 1 }}>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>{isLoading ? Loader : renderBookData}</tbody>
						</table>
					</div>
				</div>
				{editBookData}
				{deleteBookData}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewBook);
