import React, { useState, useReducer } from 'react';
import { StaffDoc } from '../../../components/Staff';
import student from '../../../hooks/HookReducers/StudentReducer/StudentReducer';
import General from '../../../utils/general';
import Button from '../../../components/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import SnackBar from '../../../components/SnackBar/SnackBar';
import Document_File_Constant from '../../../utils/app_constants/document_file_constants';
import Axios from 'axios';
import config from '../../../config/config';
import Colors from '../../../utils/app_constants/colors_constants';
import UploadProgressBar from '../../../components/UploadProgressBar/UploadProgressBar';
import studentFields from '../../../utils/studentFields';
function ParentDocsUpload(props) {
	const { id } = props;
	const [customLoader, setCustomLoader] = useState(false);
	const { urlId, type } = props.match.params;
	debugger;
	const [snackbarMessage, setMsg] = useState('');
	const [check, setCheck] = useState(0);
	const { onSkipDocuments } = props;
	const [errors, setErrors] = useState({});
	const [newState, dispatch] = useReducer(
		student.StudentDocsReducer,
		student.ParentDocState
	);
	debugger;
	const docsChange = e => {
		e.preventDefault();
		try {
			let reader = new FileReader();
			let file = e.target.files[0];

			if (
				!General.checkForImageOrFile(
					false,
					true,
					errors,
					file.size,
					2.1,
					file.name,
					'fileType',
					setErrors
				)
			) {
				dispatch({
					type: 'onFileChange',
					payload: {
						name: e.target.name,
						value: e.target.files[0]
					}
				});
			} else {
			}
		} catch (e) {
			console.log(e);
		}
	};
	const onUpload = () => {
		console.log(newState);
		const {
			idCardFather,
			passportFather,
			idCardMother,
			passportMother,
			fileNameIdCardFather,
			fileNamePassportFather,
			fileNameIdCardMother,
			fileNamePassportMother
		} = newState;
		let data = new FormData();
		data.append('studentId', id || urlId);

		//parent docs
		if (idCardFather === 'Yes') {
			data.append(
				'filePath_idCardFather',
				fileNameIdCardFather,
				fileNameIdCardFather.name
			);
			data.append(
				'idCardFather',
				idCardFather + '_' + Document_File_Constant.idCardFather
			);
		} else {
			data.append(
				'idCardFather',
				idCardFather + '_' + Document_File_Constant.idCardFather
			);
			data.append('filePath_idCardFather', fileNameIdCardFather);
		}
		if (passportFather === 'Yes') {
			data.append(
				'filePath_passportFather',
				fileNamePassportFather,
				fileNamePassportFather.name
			);
			data.append(
				'passportFather',
				passportFather + '_' + Document_File_Constant.passportFather
			);
		} else {
			data.append(
				'passportFather',
				passportFather + '_' + Document_File_Constant.passportFather
			);
			data.append('filePath_passportFather', fileNamePassportFather);
		}
		if (idCardMother === 'Yes') {
			data.append(
				'filePath_idCardMother',
				fileNameIdCardMother,
				fileNameIdCardMother.name
			);
			data.append(
				'idCardMother',
				idCardMother + '_' + Document_File_Constant.idCardMother
			);
		} else {
			data.append(
				'idCardMother',
				idCardMother + '_' + Document_File_Constant.idCardMother
			);
			data.append('filePath_idCardMother', fileNameIdCardMother);
		}
		if (passportMother === 'Yes') {
			data.append(
				'filePath_passportMother',
				fileNamePassportMother,
				fileNamePassportMother.name
			);
			data.append(
				'passportMother',
				passportMother + '_' + Document_File_Constant.passportMother
			);
		} else {
			data.append(
				'passportMother',
				passportMother + '_' + Document_File_Constant.passportMother
			);
			data.append('filePath_passportMother', fileNamePassportMother);
		}

		data.append('accountId', props.name);
		data.append('accountType', props.role);
		let token = '';
		if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
		setCustomLoader(true);
		props.sharedAction
			.addDocumentsWithProgressCallback('/api/DocumentParent', data, setCheck)
			.then(success => {
				debugger;
				setMsg(success);
				props.snackbar();
				setCustomLoader(false);
				setTimeout(() => {
					setCheck(0);
					props.history.push('/dashboard/ViewAddForm');
				}, 2000);
			})
			.catch(error => {
				setMsg(
					error &&
						error.response &&
						error.response.data &&
						error.response.data.Message
				);
				props.snackbar();
				debugger;
			});
		// props.sharedAction
		// 	.simpleAddRequest('/api/DocumentStaff', data)
		// 	.then(success => {
		// 		setMsg(success);
		// 		props.snackbar();
		// 		setTimeout(() => {
		// 			props.history.push('/dashboard/ViewStaff');
		// 		}, 2000);
		// 		debugger;
		// 	})
		// 	.catch(error => {
		// 		setMsg(
		// 			error &&
		// 				error.response &&
		// 				error.response.data &&
		// 				error.response.data.Message
		// 		);
		// 		props.snackbar();
		// 		debugger;
		// 	});
	};

	return (
		<div
			style={{
				width: '80%',
				margin: '0 auto',
				boxShadow: '5px 5px 5px grey',
				borderRadius: 5
			}}
		>
			<SnackBar backColor={'#000'} msg={snackbarMessage} />
			<h1 className="generalHead">Parents Documents</h1>
			<br />
			<UploadProgressBar percent={check} />
			{/* <div
				style={{
					margin: '0 auto',
					width: 'max-content'
				}}
			> */}
			<StaffDoc
				fieldsData={studentFields.getParentDocDetails}
				errors={errors}
				edit={false}
				dispatch={dispatch}
				docsChange={docsChange}
				stateData={newState}
			/>
			{/* </div> */}
			<br />
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ marginLeft: 10, marginBottom: 5 }}>
					<Button
						icon={true}
						iconSize={12}
						textColor="#fff"
						iconClass="fas fa-upload"
						btnName="Upload"
						buttonClass="btn-smart-one"
						loaderBmargin={2}
						customClause={
							!newState['idCardFather'] ||
							!newState['passportFather'] ||
							!newState['idCardMother'] ||
							!newState['passportMother']
								? true
								: false
						}
						stateData={{
							isLoading: customLoader
						}}
						onHandleSubmit={onUpload}
					/>
				</div>
				{type !== 'edit' ? (
					<div>
						<span onClick={onSkipDocuments} class="badge badge-light">
							{' '}
							<a style={{ textDecoration: 'none', fontSize: 15 }} href="">
								Skip Step{' '}
								<i
									style={{
										fontSize: 13,
										padding: 5,
										cursor: 'pointer',
										color: '#000'
									}}
									class={'fas fa-forward'}
								></i>{' '}
							</a>
						</span>
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
}
function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ParentDocsUpload);
