import React from 'react';
import Colors from '../../../utils/app_constants/colors_constants';
import CustomBadge from '../../CustomBadge/CustomBadge';
import SelectionInput from '../../SelectionInput/SelectionInput';

export default function GraphGeneralLayout({
	children,
	titleName,
	titleIcon,
	setType,
	setProperty
}) {
	return (
		<div
			className="lineGraph"
			style={{
				border: `3px solid ${Colors.DARK_SEA_GREEN_THEME}`
			}}
		>
			<CustomBadge
				fontSize={25}
				badgeText={titleName}
				badgeIconClass={titleIcon}
			/>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div
					style={{
						color: `${Colors.SEA_GREEN_THEME}`,
						fontWeight: 'bold',
						// marginLeft: 5,
						fontSize: 20,
						display: 'flex'
					}}
				>
					Grading Scale: 0 - 5
				</div>
				<div style={{ width: '15%' }}>
					<SelectionInput
						newFlag="customWidth"
						isValidte={false}
						marginTop={-50}
						feildName={'graphType'}
						selectName={'Graph Type'}
						onHandleChange={e => {
							if (e.target.value !== 'Type') setType(e.target.value);
						}}
						errors={{}}
						optionsArrys={['barChart', 'pieChart', 'lineChart']}
						selectedText={'Type'}
						stateData={{
							graphType: setProperty
						}}
						// iconClassName={'fas fa-clock animated fadeIn'}
						optionType="static"
						successflag={'successflag'}
					/>
				</div>
			</div>
			{children()}
		</div>
	);
}
