class RoleTypes {
	static OWNER = 'owner';
	static ACADMIC_SMART = 'academic smart';
	static STUDENT_SMART = 'student';
	static STAFF_SMART = 'staff';
	static FINANCE_SMART = 'finance smart';
	static HR_SMART = 'hr smart';
	static PARENT_SMART = 'parent';
	static HOD_SMART = 'hod';
	static COMMUNICATION_SMART = 'communication smart';
	static GENERAL_MANAGER_SMART = 'general manager';
	static ADMISSION_SMART = 'admission smart';
	static ADMINISTRATION_SMART = 'administration smart';
	static ADMISSION_MANAGER = 'admission manager';
	static ACCOUNTANT_MANAGER = 'accountant';
	static BILLING_MANAGER = 'billing accountant';
	static ENTRY_EXAM_COORDINATOR = 'entry exam coordinator';
	static REGISTRATION_COORDINATOR = 'registration coordinator';
	static WELLBEING_COORDINATOR = 'wellbeing coordinator';
	static ACTIVITY_COORDINATOR = 'activity coordinator';
	static MEDICAL_STAFF = 'medical staff';
	static STAFF_COORDINATOR = 'staff coordinator';
	static STUDENT_COORDINATOR = 'student coordinator';
	static MAINTENANCE_COORDINATOR = 'maintenance coordinator';
	static TRANSPORT_COORDINATOR = 'transport coordinator';
	static CANTEEN_COORDINATOR = 'canteen coordinator';
	static PORTAL_COORDINATOR = 'portal coordinator';
	static EXAM_COORDINATOR = 'exam coordinator';
	static TIMETABLE_COORDINATOR = 'timetable coordinator';
	static NEWS_COORDINATOR = 'news coordinator';
	static FRONT_DESK = 'front desk (hr)';
	static SEN = 'sen';
	static SECURITY_MANAGER = 'security staff';
	static PROCUREMENT_MANAGER = 'procurement coordinator';
	static PASTORAL_SMART = 'pastoral smart';

	//Dean Roles
	static DEAN_ARABIC = 'dean arabic';
	static DEAN_ACTIVITIES = 'dean activities';
	static DEAN_ENGLISH = 'dean english';
	//End Dean Roles

	//Coordinator Roles
	static COORDINATOR_LANGUAGE = 'coordinator language';
	static Coordinator_Maths_Sciences = 'coordinator maths & sciences';
	static Coordinator_Business_Ict = 'coordinator business & ict';

	//End Coordinator Roles

	//HOD Dashboard Start
	static PHYSICS_HOD = 'physics';

	//End HOD Dashboard Start
}

export default RoleTypes;
