import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	valid: false,
	getAddData: [],
	paginationDetails: null,
	getSingleFormData: null,
	getCurrenSession: null,
	getData: null,
	getImageData: null,
	getStudentDocs: [],
	getParentDocs: []
};

export default function setAdmissionReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.GET_ALL_PARENT_DOCUMENTS:
			return {
				...state,
				getParentDocs: [...action.payload]
			};
		case actionTypes.GET_ALL_STUDENT_DOCUMENTS:
			return {
				...state,
				getStudentDocs: [...action.payload]
			};
		case actionTypes.GET_UPLOAD_STUDENT_IMAGE:
			return {
				...state,
				getImageData: action.payload
			};
		case actionTypes.GET_ALL_ROUTES_DETAILS:
			return {
				...state,
				getData: action.payload
			};

		case actionTypes.GET_CURRENT_SESSION:
			return {
				...state,
				getCurrenSession: [...action.payload.session]
			};
		case actionTypes.SET_FORM_LEAVE_FLAG:
			return {
				...state,
				valid: action.payload
			};
		case actionTypes.ADD_STUDENT_ADMISSION_FORM:
			return {
				...state
			};
		case actionTypes.SET_ADMISSION_FORM_DATA:
			return {
				...state,
				getAddData: [...action.payload.enq11],
				paginationDetails: {
					...action.payload.paginationMetadata
				}
			};

		case actionTypes.DELETE_ADD_FORM_FROM_VIEW:
			let filterCandidateReqList = state.getAddData.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);
			return {
				...state,
				getAddData: [...filterCandidateReqList]
			};

		case actionTypes.GET_INDIVIDUAL_STUDENT_DATA:
			return {
				...state,
				getSingleFormData: {
					...action.payload
				}
			};

		default:
			return state;
	}
}
