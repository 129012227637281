import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import isNull from 'lodash/isNull'






class AddFloor extends Component {
    constructor(props){
        super(props);
        this.state = {
            errorMsg: false,
            successMsg: false,
            msg: '',
            isLoading: false,
            floorName: ''
        }
    }

    componentDidMount() {

    }


    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    onHandleTextChange = e => {
        console.log([e.target.name], e.target.value);
        
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    handleSubmit = e => {
        e.preventDefault();
        const { floorName } = this.state;
        if(
            isNull(floorName) 
        ){
            this.setState({
                successMsg: false,
                errorMsg: true,
                msg: 'Please fill the form respectively!'
            })
        }else{

        let data = {
            accountId: this.props.userData.unique_name,
            accountType: this.props.userData.role,
            floorName
        }

        console.log(data);

        this.props.sharedActions.addRecordWithoutDispatch(
            `/api/Floors`,
            data
        ).then(success => {
            console.log(success);
            
            this.setState({
                msg: 'Floor Added!',
                successMsg: true,
                isLoading: false,
                floorName: '',
            })
        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
            
        })
    }
    }   
    componentDidUpdate() {
        const { successMsg, errorMsg } = this.state;

		if (successMsg || errorMsg) {
			setTimeout(() => {
				this.setState({
					successMsg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}

    render(){

        const { successMsg, errorMsg, msg, isLoading, 
                floorName 
            } = this.state;

        return (
            <div className="container" > 
                <section  className="mb-4">
                    <h2  className="h1-responsive font-weight-bold text-center my-4">
                        Add Floor
                    </h2>
                    <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>
                        <div className="col-md-9 mb-md-0 mb-5" style={{
                            border: 'solid',
                            borderColor: '#01ac8a',
                            padding: 40,
                            borderRadius: 15,
                            width: 'auto'

                        }}>
                            {errorMsg && <p style={{
                                color: 'red',
                                textAlign: 'center'
                            }}>
                                    {msg}
                                </p>}
                            {successMsg && <p style={{
                                color: 'green',
                                textAlign: 'center'
                            }}>
                                    {msg}
                                </p>}
                            
                            <form id="floor-form" name="floor-form" onSubmit={this.handleSubmit} >
                                <div className="row">
									<div className="col-md-12">
										<div className="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
                                <div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminName" class="">
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
								    </div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminType" class="">
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
								    </div>
                                </div>
                                <br/>
                                    <div className="row">
                                    <div className="col-md-6">
                                    <div className="md-form mb-0" >
                                    <label for="floorName">
                                            Floor Name
                                        </label>
                                        <input
                                            style={{ borderColor: '#01AC8A' }}
                                            type="text"
                                            name="floorName"
                                            class="form-control"
                                            value={floorName}
                                            onChange={this.onHandleTextChange}
                                        />
                                    </div>
                                    </div>
                                    </div>
                                        <br/>
                                        <br/>
                                        <div className="text-right text-md-center">
                                            <button type="submit" className="btns">
                                                Add
                                                <span style={{
                                                    marginBottom: 5
                                                }}
                                                className={isLoading && 'spinner-border spinner-border-sm'}>
                                                </span>
                                            </button>
                                        </div>
                                     
                            </form>

                        </div>

                    </div>
                </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddFloor);
