import * as actionTypes from '../actionTypes';

export function setAllQuizInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_QUIZ,
		payload: allData
	};
}
export function getSessionForQuiz(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_QUIZ_SELECT,
		payload: allData
	};
}
export function setAllClassGradeForQuizInRedux(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_GRADE_NAME_INTO_QUIZ_SELECT,
		payload: allData
	};
}
// export function getPostForSearchStaff(allData) {
//
// 	return {
// 		type: actionTypes.SET_ALL_POST_NAME_INTO_SEARCHSTAFFPOST_SELECT,
// 		payload: allData
// 	};
// }

export function addQuiz(Data) {
	return {
		type: actionTypes.ADD_QUIZ,
		payload: Data
	};
}
export function updateQuizFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_QUIZ,
		payload: setFlag
	};
}
export function updateQuiz(data) {
	return {
		type: actionTypes.EDIT_QUIZ_RECORD,
		payload: data
	};
}
export function deleteQuiz(id) {
	return {
		type: actionTypes.DELETE_QUIZ,
		payload: id
	};
}
