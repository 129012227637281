import React from 'react';
import images from '../utils/images';
import { Link } from 'react-router-dom';
export default function ClassDisciplineDashboard() {
	return (
		<div>
			<section class="mb-4">
				<div class="justify-content-center row">
					<div class="col-md-7">
						<h4 class=" text-center generalHead">Student Class Discipline</h4>
					</div>
				</div>
				<div
					class="row"
					style={{
						justifyContent: 'center',
						textAlign: 'center',
						marginBottom: -45
					}}
				>
					<div class="col-md-8">
						<div class="md-form mb-0">
							<hr class="style-head" />
						</div>
					</div>
				</div>

				<p class="text-center w-responsive mx-auto mb-5" />

				<div
					class="row"
					style={{ textAlign: 'center', justifyContent: 'center' }}
				>
					<div class="col-md-9 mb-md-0 mb-5">
						<form id="contact-form" name="contact-form">
							<div class="row">
								<div class="col-md-6">
									<div class="md-form mb-0">
										<Link to="/dashboard/UpdateClassDiscipline">
											<img
												src={images.imgUpdateClassDiscipline}
												width="200"
												height="200"
											/>
										</Link>
									</div>
								</div>
								<div class="col-md-6">
									<div class="md-form mb-0">
										<Link to="/dashboard/ViewAddForm">
											<img
												src={images.imgViewClassDiscipline}
												width="200"
												height="200"
											/>
										</Link>
									</div>
								</div>
							</div>
						</form>
						<div class="status" />
					</div>
				</div>
			</section>
		</div>
	);
}
