import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SkillAction from '../../Actions/StudentSkill';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import { Link } from 'react-router-dom';

import $ from 'jquery';
// import TableComponent from "./TableComponent";
class ViewStudentEvents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checking: [],
			SkillReports: [],
			adminData: [],
			modalData: null,
			vehicleNo: '',
			capacity: '',
			transportType: '',
			enabled: false,
			id: null,
			select: [],
			efforts: '',
			participation: '',
			average: '',
			academicProgress: '',
			studentName: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			errorMsg: '',
			increament: 1,
			selectClassData: '',
			classId: '',
			sectionId: '',
			sections: [],
			subjects: [],
			session: [],
			sessionId: '',
			subjectId: '',
			staffId: '',
			term: '',
			btnLoad: false,
			exist: false,
			checking: [],
			searchCheck: true,
			multiple: []
		};
	}

	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip('hide');

		if (this.state.msg || this.state.errorMsg || this.state.exist) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					exist: false
				});
			}, 3000);
		}
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/StudentDetailActive?userName=${this.props.users.unique_name}`
			)
			.then(success => {
				this.setState({
					classId: success.classId,
					sectionId: success.sectionId,
					sessionId: success.sessionId
				});
				debugger;
				this.props.sharedActions
					.getDataWithoutDispatch(
						`/api/StudentEvents?pageNumber=1&pageSize=10&classId=${success.classId}&sessionId=${success.sessionId}`
					)
					.then(success => {
						debugger;
						this.setState({
							searchCheck: false,
							adminData: success.eventList,
							checking: success.paginationMetadata
						});
					})
					.catch(error => {
						this.setState({
							searchCheck: true
						});
						console.log(error);
					});
			})
			.catch(error => {
				console.log(error);
			});
	}

	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
			x.className = x.className.replace('show', '');
		}, 3000);
	};

	onChangeText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	paginationRequest = number => {
		this.setState({
			searchCheck: true
		});
		console.log(number);

		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/StudentEvents?pageNumber=${number}&pageSize=10&classId=${this.state.classId}&sessionId=${this.state.sessionId}`
			)
			.then(success => {
				this.setState({
					adminData: success.eventList,
					checking: success.paginationMetadata,
					searchCheck: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});

				console.log(err);
			});
	};
	render() {
		const { adminData, modalData, select } = this.state;
		console.log('Students Data', this.state.adminData);

		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <=
			Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
			i++
		) {
			pageNumbers.push(i);
		}

		const renderPageNumbers = pageNumbers.map((number, index) => {
			return (
				<li
					class={`page-item ${
						this.state.checking.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={() => this.paginationRequest(number)}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			);
		});
		const renderTodos =
			adminData &&
			adminData.map((item, index) => {
				return (
					<tr key={index}>
						<th scope="row" style={{ padding: 10 }}>
							{index + 1}
						</th>
						<td style={{ padding: 10 }}>{item.month}</td>
						<td style={{ padding: 10 }}>{item.eventDate}</td>
						<td style={{ padding: 10 }}>{item.eventTime}</td>
						<td style={{ padding: 10 }}>{item.className}</td>
						<td style={{ padding: 10 }}>{item.subject}</td>
						<td style={{ padding: 10 }}>{item.accountType}</td>
						<td style={{ padding: 10 }}>{item.accountId}</td>
						<td style={{ padding: 10 }}>{item.description}</td>
					</tr>
				);
			});
		const MainContent = (
			<div>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span className="customBadge" style={{ fontSize: 14 }}>
						Page: {this.state.checking.currentPage}
					</span>

					<span className="customBadge" style={{ fontSize: 14 }}>
						Total Page: {this.state.checking.totalPages}
					</span>
				</div>
				<div className="table-responsive">
					<table class="table text-center table-bordered table-striped">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 2 }}>
									#
								</th>
								<th scope="col" style={{ padding: 2 }}>
									Month
								</th>
								<th scope="col" style={{ padding: 2 }}>
									Date
								</th>
								<th scope="col" style={{ padding: 2 }}>
									Time
								</th>
								<th scope="col" style={{ padding: 2 }}>
									Class
								</th>

								<th scope="col" style={{ padding: 2 }}>
									Subject/Event
								</th>
								<th scope="col" style={{ padding: 2 }}>
									Coordinator
								</th>
								<th scope="col" style={{ padding: 2 }}>
									Account
								</th>
								<th scope="col" style={{ padding: 2 }}>
									Description
								</th>
							</tr>
						</thead>
						<tbody>{renderTodos}</tbody>
					</table>
				</div>
				{this.state.errorMsg ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Grading Marks Already Exist For This Term
					</p>
				) : (
					''
				)}
				{this.state.msg ? (
					<p style={{ color: 'green', textAlign: 'center' }}>
						Added Successfully!
					</p>
				) : (
					''
				)}
				{this.state.exist ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Please Fill the Fields
					</p>
				) : (
					''
				)}
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<div class="row">
						<div class="col-md-3"></div>
						<div class="col-md-6">
							<h4 class="generalHead"> Events Details</h4>
						</div>
						<div class="col-md-3 text-right">
							<nav aria-label="Page navigation example">
								<ul style={{ color: '#01AC8A' }} class="pagination">
									{renderPageNumbers}
								</ul>
							</nav>
						</div>
					</div>
				</div>
				<br />
				{this.state.searchCheck ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		SkillAction: bindActionCreators(SkillAction, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllTransportData: state.setStudentSkillReducer.DataP,
		selectData: state.setStudentSkillReducer.selectStudent,
		SessionSelect: state.setStudentSkillReducer.selectSession,
		allTransportState: state.setStudentSkillReducer,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewStudentEvents);
