import React, { Component } from 'react';
import $ from 'jquery';
import TextBoxes from './TextBoxesForFees';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class MultipleFees extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('#addBtn').hover(
				function() {
					$(this).css(
						'background',
						'linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)'
					);
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('background', '#0A4F5E');
				}
			);
		});
	};
	render() {
		const {
			DataForFeeType,
			onHandleTextChange,
			stateData,
			updateSpecialArrObjOnChange,
			onClickAddItem,
			onClickDeleteItem
		} = this.props;
		return (
			<div class="row">
				<div
					class="col-md-3 text-left font-weight-bold"
					style={{ marginTop: 25 }}
				>
					<label for="name" class="">
						{DataForFeeType.label}:
					</label>
				</div>
				<div class="col-md-6" style={{ marginTop: 20 }}>
					<div class="md-form mb-0">
						<input
							type="number"
							placeholder={`Add ${DataForFeeType.label} Fees`}
							name={DataForFeeType.name}
							value={DataForFeeType.value}
							onChange={e => onHandleTextChange(e)}
							class="form-control"
						/>
					</div>
				</div>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

export default connect(mapStateToProps)(MultipleFees);
