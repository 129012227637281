import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';

class AssignVehicle extends Component {
	constructor(props) {
		super(props);

		this.state = {
			AdminName: '',
			AdminType: '',
			DriverName: '',
			DriverId: '',
			VehicleReg: '',
			TransportType: ''
		};
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		console.log(
			this.state.DriverName,
			this.state.DriverId,
			this.state.VehicleReg,
			this.state.TransportType,
			this.state.AdminName,
			this.state.AdminType
		);
		console.log(this.state);
	};
	render() {
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Assign Vehicle
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{ justifyContent: 'Center', textAlign: 'Center' }}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />

								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												type="text"
												name="AdminName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Type
											</label>
											<input
												type="text"
												name="AdminType"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Vehicle Registration
											</label>
											<input
												type="text"
												name="VehicleReg"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Transportation Type
											</label>
											<input
												type="text"
												name="TransportType"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Select Driver Name
											</label>
											<select
												onChange={this.onHandleTextChange}
												class="custom-select"
												name="DriverName"
											>
												<option selected>Names...</option>
												<option value="1">Sana Ullah</option>
												<option value="2">Hamza</option>
												<option value="3">Adil</option>
											</select>
										</div>
									</div>
								</div>
							</form>
							<br />
							<br />
							<div class="text-center text-md-center">
								<button onClick={this.onHandleSubmit} class="btns">
									Assign
								</button>
							</div>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default AssignVehicle;
