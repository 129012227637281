import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StudentEnquiryActions from '../../Actions/StudentEnquiry';
import * as sharedActions from '../../Actions/sharedActions';
import $ from 'jquery';

class ViewStudentEnquiry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			getSingleAddData: [],
			isLoader: true
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.getSingleAddData !== nextProps.getData) {
			return {
				getSingleAddData: nextProps.getData
			};
		}
		return null;
	}
	componentDidMount() {
		const { match } = this.props;
		this.props.sharedActions
			.gAllData(
				`/api/EnquiryForm/${match.params.id}`,
				this.props.StudentEnquiryActions.getIndividualStudentForEnquiry
			)
			.then(success => {
				console.log(success);
				this.setState({
					isLoader: false
				});
			})
			.catch(error => {
				console.log(error);
				this.setState({
					isLoader: false
				});
			});
	}
	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};

	render() {
		const { match } = this.props;
		console.log('IDDD', match.params.id);
		console.log('Checking', this.state.getSingleAddData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);

		const MainContent = (
			<div>
				<div id="div1">
					<section class="mb-4">
						<div class="divclass">
							<img src={require('../../Assets/images/3 headers-03.jpg')} />
						</div>
						<br />
						<div class="row print">
							<div class="col-md-12">
								<div class="md-form mb-0">
									<h2
										style={{
											backgroundColor: '#0A4F5E',
											textAlign: 'center',
											color: 'white'
										}}
									>
										Student Enquiry Form
									</h2>
								</div>
							</div>
						</div>

						<div class="container wow">
							<div class="row rowCheck" style={{ marginTop: 10 }}>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Applicant Name: </label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.applicantName}
								</div>
								<div class="col colCheck">
									{' '}
									<label style={{ fontWeight: 'bold' }}>Father Name:</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.fatherName}
								</div>
								<div class="col colCheck">
									{' '}
									<label style={{ fontWeight: 'bold' }}>Mother Name: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.motherName}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Address:</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.address}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>City:</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.city}{' '}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>State: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.state}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Country: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.country}{' '}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Email Id: </label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.emailId}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Postal Code:</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.postalCode}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Date Of Birth </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.dob}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Phone No</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.PhoneNo}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Mobile No: </label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.mobileNo}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Status:</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.status}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Class Name:</label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.className}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>
										Previous Academics:{' '}
									</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.previousAcademics}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Reference Type :</label>
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.referenceType}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Reference Name: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.referenceName}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Details: </label>
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.details}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Date: </label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.date}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Time: </label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.time}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Account Name: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.accountId}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Account Type: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.accountType}
								</div>
							</div>
						</div>
					</section>
				</div>
				<div class="text-center text-md-right">
					<label for="name" class="" onClick={() => this.PrintContent('div1')}>
						<i
							className="fas fa-print fa-2x animated fadeIn"
							style={{ marginRight: 4 }}
						/>
						Print It Out
					</label>
				</div>
			</div>
		);
		return <div>{this.state.isLoader ? Loader : MainContent}</div>;
	}
}

function mapDispatchToProps(dispatch) {
	return {
		StudentEnquiryActions: bindActionCreators(StudentEnquiryActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		getData: state.setStudentEnquiryReducer.DataEnquiry
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewStudentEnquiry);
