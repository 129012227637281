import React, { useEffect } from 'react';
import ListItems from './ListItems/ListItems';
const ListView = props => {
	const {
		excludedData = [],
		tableHeadData = [],
		tableMainData = [],
		onChangeMarks,
		type,
		data = {}
	} = props;
	return (
		<table class="table table-bordered">
			<thead>
				<tr className="table-row">
					{tableHeadData.length > 0 ? (
						tableHeadData.map(item => <th>{item}</th>)
					) : (
						<td>no data found</td>
					)}
				</tr>
			</thead>
			<tbody>
				{tableMainData && tableMainData.length > 0 ? (
					tableMainData.map((item, index) => (
						<tr>
							<td>{index + 1}</td>
							<ListItems
								data={data}
								excludedData={excludedData}
								type={type}
								updateSpecialArrObjOnChange={onChangeMarks}
								itemObj={item}
								stateData={{
									studentData: tableMainData
								}}
								rowIndex={index}
								arrName="studentData"
							/>
						</tr>
					))
				) : (
					<tr>
						<td></td>
						<td style={{ color: 'red', textAlign: 'center' }}>No Record</td>
						{type === 'dataEnterType' ? null : <td></td>}
					</tr>
				)}
			</tbody>
		</table>
	);
};
export default ListView;
