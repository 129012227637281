import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	select: [],
	selectClass: [],
	DataEnquiry: [],
	AdmittedData: []
};

export default function setStudentEnquiryReducer(
	state = defaultState,
	action = {}
) {
	switch (action.type) {
		case actionTypes.SET_ALL_CLASS_NAME_INTO_STUDENT_ENQUIRY_SELECT:
			return {
				...state,
				select: action.payload
			};

		case actionTypes.SET_ALL_CLASS_NAME_INTO_STUDENT_SEARCH_ENQUIRY_SELECT:
			return {
				...state,
				selectClass: action.payload
			};

		case actionTypes.GET_ADMITTED_STUDENT_ENQUIRY_DATA:
			return {
				...state,
				AdmittedData: [...action.payload]
			};

		case actionTypes.GET_INDIVIDUAL_STUDENT_ENQUIRY_DATA:
			return {
				...state,
				DataEnquiry: action.payload
			};
		case actionTypes.GET_ALL_STUDENT_ENQUIRY:
			return { ...state, Data: action.payload };
		case actionTypes.SET_LOADER_FLAG_STUDENT_ENQUIRY_ADD:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_STUDENT_ENQUIRY:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_STUDENT_ADMITTED_RECORD:
			var adminData = state.AdmittedData;
			var index = adminData.findIndex(obj => obj.id === action.payload.id);
			// adminData[index].transportType = action.payload.transportType;
			// adminData[index].vehicleNo = action.payload.vehicleNo;
			// adminData[index].capacity = action.payload.capacity;
			// adminData[index].routeName = action.payload.routeName;

			return { ...state, AdmittedData: adminData };
		default:
			return state;
	}
}
