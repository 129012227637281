import React, { useState, useEffect } from 'react';
import CustomHeading from '../../../../components/CustomHeading/index';
import { SUB_HEADING } from '../../../../utils/general';
import Button from '../../../../components/Button';

import ListView from '../../../../components/List/ListView/ListView';
import useHttp from '../../../../hooks/http';
import LoaderWrapper from '../../../../components/HOC/LoaderWrapper';
import validateInput, {
	CHECK_FIELD_IS_EMPTY
} from '../../../../validator/inputValidator';
import Colors from '../../../../utils/app_constants/colors_constants';
import * as sharedActions from '../../../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function CaSubToolCreation(props) {
	const [errors, setError] = useState({});
	const [msg, setMsg] = useState('');
	const { id, type } = props.match.params;
	const [isLoader, fetchData = []] = useHttp(
		`/api/ToolByPrimaryInfo/?pId=${id}`,
		[]
	);

	debugger;
	const [subTool, setSubTools] = useState([
		{
			pId: id,
			toolId: '',
			toolName: '',
			subToolName: '',
			max: '',
			min: ''
		}
	]);

	const checkValidation = data => {
		let flag = false;
		let getKeys = Object.keys(data);
		let getDesireKeys = getKeys.filter(item => item !== 'pId');

		for (let i = 0; i < getDesireKeys.length; i++) {
			if (!data[getDesireKeys[i]]) flag = true;
		}
		debugger;
		return flag;
	};
	// const onChangeDynamic = (e, index, fieldName) => {
	// 	debugger;
	// 	if (isValid({ toolName: e.target.value }, CHECK_FIELD_IS_EMPTY)) {
	// 		const getClone = JSON.parse(JSON.stringify(subTool));

	// 		getClone[index][fieldName] = e.target.value;

	// 		console.log(getClone);
	// 		debugger;
	// 		setSubTools(getClone);
	// 	} else {
	// 		const getClone = JSON.parse(JSON.stringify(subTool));

	// 		getClone[index][fieldName] = e.target.value;

	// 		console.log(getClone);
	// 		debugger;
	// 		setSubTools(getClone);
	// 	}
	// };
	const onChangeDynamic = (e, index, fieldName) => {
		debugger;

		if (fieldName === 'toolName') {
			console.log(fetchData);

			debugger;
			const getToolName = fetchData.filter(
				item => item.toolId === parseInt(e.target.value)
			)[0].toolName;
			debugger;
			// if (isValid({ toolName: e.target.value }, CHECK_FIELD_IS_EMPTY)) {
			const getClone = JSON.parse(JSON.stringify(subTool));

			getClone[index][fieldName] = getToolName;
			getClone[index]['toolId'] = e.target.value;
			debugger;
			console.log(getClone);
			debugger;
			setSubTools(getClone);
		} else {
			const getClone = JSON.parse(JSON.stringify(subTool));

			getClone[index][fieldName] = e.target.value;
			debugger;
			console.log(getClone);
			debugger;
			setSubTools(getClone);
		}
		// } else {
		// 	const getClone = JSON.parse(JSON.stringify(subTool));

		// 	getClone[index][fieldName] = e.target.value;

		// 	console.log(getClone);
		// 	debugger;
		// 	setSubTools(getClone);
		// }
	};

	const isValid = (data, validateType) => {
		const { errors, isValid } = validateInput(data, validateType);
		if (!isValid) {
			setError(errors);
		}
		return isValid;
	};
	const onAddTool = () => {
		let getSubTool = [...subTool];
		debugger;
		getSubTool.push({
			pId: id,
			toolId: '',
			toolName: '',
			subToolName: '',
			max: '',
			min: ''
		});
		setSubTools(getSubTool);
	};

	const onDeleteTool = () => {
		let getSubTool = [...subTool];
		debugger;
		if (subTool.length > 1) {
			getSubTool.pop();
			setSubTools(getSubTool);
		}
	};
	const IsObjectEmpty = Obj => {
		let isEmpty = false;
		let getKeys = Object.keys(Obj);
		let getDesireKeys = getKeys.filter(item => item !== 'pId');
		let getDesireObj = {};

		getDesireKeys.forEach(element => {
			if (Obj[element] === '') {
				isEmpty = true;
			}
		});

		return isEmpty;
	};
	function isEmptyChecking(arrObj = []) {
		let result = [];
		arrObj.map(item => result.push(IsObjectEmpty(item)));
		console.log(result);

		return result.some(value => value === true);
	}

	const onSubmit = () => {
		console.log(subTool);
		debugger;
		if (!isEmptyChecking(subTool)) {
			props.sharedAction
				.simpleAddRequest(`/api/SubTool`, { subTools: [...subTool] })
				.then(success => {
					console.log(success);
					debugger;
					props.history.push({
						pathname: '/dashboard/CASubToolView',
						state: { postFlag: true }
					});
				})
				.catch(error => {
					debugger;
					console.log(error);
					setMsg(error.response.data.Message);
					props.snackbar();
				});
		} else {
			let cloneError = { ...errors };
			cloneError['isError'] = true;
			setError(cloneError);
		}
	};
	return (
		<div>
			{errors && errors['isError'] ? (
				<p style={{ color: 'red' }}> Please fill the form properly</p>
			) : (
				<p></p>
			)}
			<CustomHeading headingText={'Create Sub Tools'} type={SUB_HEADING} />
			<br />
			<LoaderWrapper isLoading={isLoader}>
				<div style={{ margin: '0 auto', width: '80%' }}>
					<ListView
						excludedData={['pId', 'toolId']}
						data={{ toolDropDown: fetchData }}
						tableMainData={subTool}
						type={type}
						tableHeadData={['S #', 'Tool Name', 'Sub Tool Name', 'Min', 'Max']}
						onChangeMarks={onChangeDynamic}
					/>
					{subTool.length > 0 ? (
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Button
								buttonClass="customButton"
								textColor={'#fff'}
								btnName={'Submit'}
								customClause={isEmptyChecking(subTool)}
								iconSize={19}
								onHandleSubmit={onSubmit}
							/>
							<div>
								<Button
									btnBackColor={`${Colors.RED_DANGER}`}
									buttonClass="customButton"
									customBorder={60}
									btnName={''}
									icon={true}
									iconClass="fas fa-plus"
									iconSize={19}
									iconColor={'#fff'}
									onHandleSubmit={onAddTool}
								/>
								<br />
								<Button
									btnBackColor={`${Colors.BLACK}`}
									buttonClass="customButton"
									customBorder={60}
									btnName={''}
									icon={true}
									iconClass="fas fa-minus"
									// customClause={isEmptyFieldInArrOBJ(studentData, 'marks')}
									iconSize={19}
									iconColor={'#fff'}
									onHandleSubmit={onDeleteTool}
								/>{' '}
							</div>
						</div>
					) : null}
				</div>
			</LoaderWrapper>
		</div>
	);
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	null,
	mapDispatchToProps
)(CaSubToolCreation);
