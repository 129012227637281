import React, { useEffect, useState } from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import GraphGeneralLayout from '../../../components/Graphs/GraphGeneralLayout/GraphGeneralLayout.jsx';
import LoaderWrapper from '../../../components/HOC/LoaderWrapper.jsx';
import ReportWrapper from '../../../components/HOC/ReportWrapper.jsx';
import ViewComponent from '../../../components/ViewComponent/ViewComponent.jsx';
import useHttp from '../../../hooks/http.js';
import CoverLayout from '../../../components/HOC/CoverLayout.jsx';
export default function SubjectDisciplineMarksReport(props) {
	const [graphType, setGraphType] = useState('lineChart');
	const { classId, section, session, term, studentId } = props.match.params;
	const { isGraphLoading, setIsGraphLoading } = useState(false);
	const [data, setData] = useState({
		datasets: [
			{
				// barPercentage: 0.4,
				// minBarLength: 2,
				label: 'Course Average',
				data: [1, 2, 2, 3, 3, 4, 3, 2],
				backgroundColor: [
					'#ea766c',
					'#abe2b9',
					'#adbceb',
					'#be9e9d',
					'#9d5b4d',
					'#e4d86b',
					'#9ecf54',
					'#e4d86b'
				],
				borderColor: [
					'#ea766c',
					'#abe2b9',
					'#adbceb',
					'#be9e9d',
					'#9d5b4d',
					'#e4d86b',
					'#9ecf54',
					'#e4d86b'
				],
				borderWidth: 2
			}
		],

		// These labels appear in the legend and in the tooltips when hovering different arcs
		labels: ['BIO', 'ICT', 'MATH', 'SOCIAL', 'PHYS', 'CHEM', 'ENG', 'ISL'],
		options: {
			showLines: true
		}
	});
	const [isLoader, fetchData] = useHttp(
		`/api/GetSpecificStudentDiscipline?classId=${classId}&sectionId=${section}&sessionId=${session}&term=${term}&studentId=${studentId}`,
		[]
	);
	useEffect(() => {
		let graphData = { ...data };
		let StudentSubjectWiseMarks =
			fetchData && fetchData['StudentSubjectWiseMarks'];
		debugger;
		let getSubjectName =
			StudentSubjectWiseMarks &&
			StudentSubjectWiseMarks.map(item =>
				item.subjectName.slice(0, 3).toUpperCase()
			);
		let getMarks =
			StudentSubjectWiseMarks &&
			StudentSubjectWiseMarks.map(item =>
				item.marksList && item.marksList.length > 0
					? item.marksList[0] && item.marksList[0]['marks']
					: 0
			);
		graphData['datasets'][0]['data'] = getMarks;
		graphData['labels'] = getSubjectName;
		setData(graphData);

		debugger;
	}, [fetchData]);
	debugger;
	let requireFetchData = { ...fetchData };

	let getNewData = [];
	if (requireFetchData && requireFetchData['StudentSubjectWiseMarks']) {
		getNewData =
			requireFetchData &&
			requireFetchData['StudentSubjectWiseMarks'].map(item => {
				return { ...item, ...item['marksList'][0] };
			});
	}
	const returnGraphs = type => {
		switch (type) {
			case 'barChart':
				return <Bar height={50} data={data} />;

			case 'pieChart':
				return <Pie height={50} data={data} />;

			case 'lineChart':
				return <Line height={50} data={data} />;

			default:
				break;
		}
	};
	return (
		<div>
			{
				<LoaderWrapper isLoading={isLoader}>
					<ReportWrapper
						headRenderData={[
							{ displayData: 'Class Name', fieldName: 'className' },
							{ displayData: 'Section', fieldName: 'section' },
							{ displayData: 'Term', fieldName: 'term' },
							{ displayData: 'Session', fieldName: 'sessionName' }
						]}
						headingName="Student Discipline Marks Report"
						headData={fetchData}
						targetedObj="info"
						PrintContent={props.printFunc}
					>
						<LoaderWrapper isLoading={isLoader}>
							<br />
							<br />
							<br />
							<GraphGeneralLayout
								setType={setGraphType}
								setProperty={graphType}
								titleName={'Analysis'}
								titleIcon={'fas fa-chart-bar'}
								children={() => returnGraphs(graphType)}
							/>
							<br />
							<br />
							<br />
							<br />
							<CoverLayout
								titleName={'Report'}
								titleIcon={'fas fa-clipboard'}
								children={() => (
									<ViewComponent
										mainData={getNewData}
										headData={['S#', 'Subject Name', 'Marks']}
										excludedData={['subjectId', 'marksList']}
									/>
								)}
							/>
						</LoaderWrapper>
						<br />
						<br />
					</ReportWrapper>
				</LoaderWrapper>
			}
		</div>
	);
}
