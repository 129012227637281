import React, { Component } from 'react';
import RenderingObjects from './RenderingObjects';
const RenderArrayData = props => {
	const { sectionType, data, Icons } = props;
	return (
		<React.Fragment>
			{data[0].map((item, index) => (
				<RenderingObjects
					sectionType={sectionType}
					Icons={Icons}
					indVal={index}
					obj={item}
				/>
			))}
		</React.Fragment>
	);
};
export default RenderArrayData;
