import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import isNull from 'lodash/isNull'



const FormData = [{
    id: 1,
    question: 'Ability to understand, process and implement information introduced',
},{
    id: 2,
    question: 'Ideas generation and developing generic research activities'
},{
    id: 3,
    question: 'Creativity and critical thinking'
},{
    id: 4,
    question: 'Proficient at learning in arange of ways, including through information technologies'
},{
    id: 5,
    question: 'Tasks managment and process documentation'
},{
    id: 6,
    question: 'Knowledge of practical and technical consendirations related to tasks/projects'
},{
    id: 7,
    question: 'Attendance'
},{
    id: 8,
    question: 'Classroom interactivity'
},{
    id: 9,
    question: 'Ability of working and productivity under stress'
},{
    id: 10,
    question: 'Performance and ability of working within teamwork'
}, {
    id: 11,
    question: 'Artwork otcomes and presentations quality'
}, {
    id: 12,
    question: 'Communication and language skills'
},{
    id: 13,
    question: 'Meeting with dead lines'
},{
    id: 14,
    question: 'Interest in the course'
}]




class CreateReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            errorMsg: false,
            successMsg: false,
            msg: '',
            isLoading: false,

            student: null,
            classSectionGrade: null,

            studentId: null,

            classId: null,
            sectionId: null,
            studentId: null,
            subjectId: null,
            staffId: null,

            comment1: null,
            comment2: null,
            comment3: null,
            comment4: null,
            comment5: null,
            comment6: null,
            comment7: null,
            comment8: null,
            comment9: null,
            comment10: null,
            comment11: null,
            comment12: null,
            comment13: null,
            comment14: null,
        

        }
    }

    componentDidMount() {
            this.getSimple('Student', 'student')
            this.getSimple('classSectionGradeTeacher', 'classSectionGrade')

    }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                errorMsg: true,
                successMsg: false,
                msg: 'Error Occured!!',
                isLoading: false
            })
            console.error('Error Name: ', error)
        })
    }

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    onHandleTextChange = e => {
        console.log([e.target.name], e.target.value);
        
        this.setState({
            [e.target.name]: e.target.value,
            error1: false,
            error2: false,
            error3: false,
            error4: false,
            error5: false,
            error6: false,
            error7: false,
            error8: false,
            error9: false,
            error10: false,
            error11: false,
            error12: false,
            error13: false,
            error14: false,
                
        })
    }

   

    handleSubmit = e => {
        e.preventDefault();
        const { classId, sectionId, studentId, subjectId, staffId,
            comment1, comment2, comment3, comment4, comment5, comment6, comment7, comment8, comment9, comment10, comment11, comment12, comment13, comment14
        } = this.state;
        if(
            isNull(classId) || 
            isNull(sectionId) || 
            isNull(studentId) || 
            isNull(subjectId)  || 
            isNull(staffId) ||
            isNull(comment1) || isNull(comment2) || isNull(comment3) || isNull(comment4) || isNull(comment5) || isNull(comment6) || isNull(comment7) || isNull(comment8) || 
            isNull(comment9) || isNull(comment10) || isNull(comment11) || isNull(comment12) || isNull(comment13) || isNull(comment14) 

        ){
            this.setState({
                successMsg: false,
                errorMsg: true,
                msg: 'Please fill the form respectively!',
                error1: isNull(comment1),
                error2: isNull(comment2),
                error3: isNull(comment3),
                error4: isNull(comment4),
                error5: isNull(comment5),
                error6: isNull(comment6),
                error7: isNull(comment7),
                error8: isNull(comment8),
                error9: isNull(comment9),
                error10: isNull(comment10),
                error11: isNull(comment11),
                error12: isNull(comment12),
                error13: isNull(comment13),
                error14: isNull(comment14),
                
            })
        }else{


        let data = {
            accountId: this.props.userData.unique_name,
            accountType: this.props.userData.role,
            classId,
            sectionId,
            studentId,
            subjectId,
            staffId,
            comment1, 
            comment2, 
            comment3, 
            comment4, 
            comment5, 
            comment6, 
            comment7, 
            comment8, 
            comment9, 
            comment10, 
            comment11,
            comment12, 
            comment13, 
            comment14
        }

        console.log(data);

        this.props.sharedActions.addRecordWithoutDispatch(
            `/api/StudentProgressTrackReport`,
            data
        ).then(success => {
            console.log(success);
            
            this.setState({
                msg: 'Student Progress Report Added!',
                successMsg: true,
                isLoading: false,
                classId: '',
                sectionId: '',
                studentId: '',
                subjectId: '',
                comment1: '', 
                comment2: '', 
                comment3: '', 
                comment4: '', 
                comment5: '', 
                comment6: '', 
                comment7: '', 
                comment8: '', 
                comment9: '', 
                comment10: '', 
                comment11: '',
                comment12: '', 
                comment13: '', 
                comment14: ''
            })
        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
            
        })
    }
    }
    
    checkBook = (studentId) => {
        const { student } = this.state;
        let f = student.student.filter((data) => {
            return data.studentId == studentId
        })

        if(f.length > 0 ){
            this.setState({
                classId: f[0].classId,
                sectionId: f[0].sectionId,
                msg: 'Student Found!!',
                successMsg: true
            })
        }else{
            this.setState({
                msg: 'Student Not Found!!',
                errorMsg: true,
            })
        }
        

    }
    

    


    componentDidUpdate() {
        const { successMsg, errorMsg } = this.state;

		if (successMsg || errorMsg) {
			setTimeout(() => {
				this.setState({
					successMsg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}

    render(){

        const { successMsg, errorMsg, msg, isLoading, classSectionGrade, student,
            studentId, classId, sectionId, subjectId, staffId

         } = this.state;

        const renderFormData = FormData.map((data, index) => {
            return (
            <div> <br/>
                <div className="row">
                <div className="col-md-12">
                    <div className="md-form mb-0" >
                         <p class="text-justify" style={{color: this.state[`error${index + 1}`] == true ? 'red' :   'black', fontWeight: 'bold'}} ><strong>{data.id}). </strong>{data.question}</p>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                            <input disabled={!subjectId} type="radio" id={`Excellent${data.id}`} onChange={this.onHandleTextChange} value='Excellent' name={`comment${data.id}`} class="custom-control-input"/>
                            <label class="custom-control-label" for={`Excellent${data.id}`}>Excellent</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                            <input disabled={!subjectId} type="radio" id={`VeryGood${data.id}`} onChange={this.onHandleTextChange} value='Very Good' name={`comment${data.id}`} class="custom-control-input"/>
                            <label class="custom-control-label" for={`VeryGood${data.id}`}>Very Good</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                            <input disabled={!subjectId} type="radio" id={`Good${data.id}`} onChange={this.onHandleTextChange} value='Good' name={`comment${data.id}`} class="custom-control-input"/>
                            <label class="custom-control-label" for={`Good${data.id}`}>Good</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                            <input disabled={!subjectId} type="radio" id={`Average${data.id}`} onChange={this.onHandleTextChange} value='Average' name={`comment${data.id}`} class="custom-control-input"/>
                            <label class="custom-control-label" for={`Average${data.id}`}>Average</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                            <input disabled={!subjectId} type="radio" id={`NeedMoreWork${data.id}`} onChange={this.onHandleTextChange} value='Need More Work' name={`comment${data.id}`} class="custom-control-input"/>
                            <label class="custom-control-label" for={`NeedMoreWork${data.id}`}>Need More Work</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                            <input disabled={!subjectId} type="radio" id={`NotApplicable${data.id}`} onChange={this.onHandleTextChange} value='Not Applicable' name={`comment${data.id}`} class="custom-control-input"/>
                            <label class="custom-control-label" for={`NotApplicable${data.id}`}>Not Applicable</label>
                    </div>
                </div>

            </div>
        </div>
            );
        })

        const commentSection = (
            <div style={{
                padding: '5px'
            }}>
                <br/>
                {renderFormData}
                <br/>
                
            </div>
        );

        const studentForm = (
            <div>

                        <div className="row">
                                    <div className="col-md-6">
                                        <label for="studentId">
                                                Student ID
                                            </label>
                                        <div className="input-group md-form mb-3" >
                                            
                                            <input class="form-control" 
                                                type='text'
                                                value={studentId}
                                                name="studentId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!student && !classSectionGrade}
                                            />
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" 
                                                disabled={!studentId && !student && !classSectionGrade}
                                                onClick={() => this.checkBook(studentId)}
                                                type="button">
                                                    <i class="fas fa-search" ></i>
                                                    </button>
                                            </div>
                                        </div>
                                        </div>
                                    <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="classId">
                                                Class
                                            </label>
                                            <select class="custom-select" 
                                                value={classId}
                                                name="classId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!student}

                                            >
                                                        <option value="">Please Select Class</option>
                                                       {student && student.classes.map((value, index) => {


                                                                return <option value={value.classId}>{value.className}</option>

                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                        
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="sectionId">
                                                Section
                                            </label>
                                            <select class="custom-select" 
                                                value={sectionId}
                                                name="sectionId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!classId}
                                            >
                                                        <option value="">Please Select Section</option>
                                                       {student && student.sections.map((value, index) => {
                                                           
                                                           return  value.classId == classId && 
                                                           <option value={value.sectionId}>{value.section}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                    <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="studentId">
                                                Student
                                            </label>
                                            <select class="custom-select" 
                                                value={studentId}
                                                name="studentId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!sectionId}

                                            >
                                                        <option value="">Please Select Student</option>
                                                        {student && student.student.map((value, index) => {
                                                           
                                                           return  value.classId == classId && value.sectionId == sectionId &&
                                                           <option value={value.studentId}>{value.Student}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                                                               
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label for="subjectId">
                                                Subject
                                            </label>
                                            <select class="custom-select" 
                                                value={subjectId}
                                                name="subjectId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!studentId}

                                            >
                                                        <option value="">Please Select Subject</option>
                                                        {classSectionGrade && classSectionGrade.subject.map((value, index) => {
                                                           
                                                           return  value.classId == classId && 
                                                           <option value={value.subjectId}>{value.subjectName}</option>
                                                       })}
                                            </select>
                                        </div>
                                    <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="staffId">
                                                Teacher
                                            </label>
                                            <select class="custom-select" 
                                                value={staffId}
                                                name="staffId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!subjectId}

                                                >           
                                                        <option value="">Please Select Teacher</option>
                                                        {classSectionGrade && classSectionGrade.staff.map((value, index) => {
                                                                        
                                                           return  value.classId == classId && value.sectionId == sectionId && value.subjectId == subjectId && 
                                                           <option value={value.staffId}>{value.staffName}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>                                 
                                </div>
                                

            </div>
        );


        return (
            <div className="container" > 
                <section  className="mb-4">
                    <h2  className="h1-responsive font-weight-bold text-center my-4">
                        Add Student Progress Report
                    </h2>
                    <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>
                        <div className="col-md-9 mb-md-0 mb-5" style={{
                            border: 'solid',
                            borderColor: '#01ac8a',
                            padding: 40,
                            borderRadius: 15,
                            width: 'auto'

                        }}>
                            {errorMsg && <p style={{
                                color: 'red',
                                textAlign: 'center'
                            }}>
                                    {msg}
                                </p>}
                            {successMsg && <p style={{
                                color: 'green',
                                textAlign: 'center'
                            }}>
                                    {msg}
                                </p>}
                            
                            <form id="payroll-form" name="payroll-form" onSubmit={this.handleSubmit} >
                                <div className="row">
									<div className="col-md-12">
										<div className="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
                                <div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminName" class="">
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
								    </div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminType" class="">
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
								    </div>
                                </div>
                                {studentForm}
                                <br/><br/>
                                    {commentSection}
                                        <br/>
                                        <br/>
                                        <div className="text-right text-md-center">
                                            <button type="submit" className="btns">
                                                Add
                                                <span style={{
                                                    marginBottom: 5
                                                }}
                                                className={isLoading && 'spinner-border spinner-border-sm'}>
                                                </span>
                                            </button>
                                        </div>
                                     
                            </form>

                        </div>

                    </div>
                </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateReport);
