import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as BudgetActions from '../../Actions/Budget';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';

class AddBudget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			particular: '',
			budgetAmount: '',
			description: '',
			userRequestName: '',
			checked: false,
			errorMsg: false,
			msg: false,
			sessions: '',
			items: '',
			sessionId: '',
			budgetPlanId: '',
			openingAmount: '',
			checkAmount: false
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.sessions !== nextProps.selectSession ||
			prevState.items !== nextProps.selectItems
		) {
			return {
				sessions: nextProps.selectSession,
				items: nextProps.selectItems
			};
		}
		return null;
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleAmount = e => {
		if (
			this.state.openingAmount >= e.target.value &&
			e.target.value <= 500000
		) {
			this.setState({
				budgetAmount: e.target.value
			});
		} else {
			this.setState({ checkAmount: true });
		}
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const {
			particular,
			sessionId,
			budgetPlanId,
			budgetAmount,
			description,
			userRequestName
		} = this.state;
		if (
			Object.is(particular, '') ||
			Object.is(budgetAmount, '') ||
			Object.is(description, '') ||
			Object.is(sessionId, '') ||
			Object.is(budgetPlanId, '') ||
			Object.is(userRequestName, '')
		) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			var bodyParameters = {
				sessionId: this.state.sessionId,
				budgetPlanId: this.state.budgetPlanId,
				particular: this.state.particular,
				budgetAmount: this.state.budgetAmount,
				description: this.state.description,
				userRequestName: this.state.userRequestName,
				accountId: this.props.userData.unique_name,
				accountType: this.props.userData.role
			};
			this.props.sharedActions
				.addRecord(
					`/api/Budget`,
					bodyParameters,
					this.props.BudgetActions.addBudget
				)
				.then(success => {
					// this.props.adminActions.updateGlobalFlag(true);
					this.setState({
						particular: '',
						budgetAmount: '',
						sessionId: '',
						budgetPlanId: '',
						budgetAmount: '',
						description: '',
						userRequestName: '',
						msg: true,
						errorMsg: false
					});
				})
				.catch(error => {});
		}
		console.log(this.state);
	};
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg || this.state.checkAmount) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					checkAmount: false
				});
			}, 3000);
		}
	}
	componentDidMount() {
		this.props.sharedActions
			.gAllData('/api/Session', this.props.BudgetActions.getSessionForBudget)
			.then(success => {})
			.catch(error => {});
		this.props.sharedActions
			.gAllData(
				'/api/BudgetPlan',
				this.props.BudgetActions.getBudgetItemsForBudget
			)
			.then(success => {})
			.catch(error => {});
	}
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);

		this.setState({
			sessionId: e.target.value
		});
	};
	onSelectItemChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			budgetPlanId: result.budgetPlanId,
			openingAmount: result.amount
		});
	};
	render() {
		const { session } = this.state.sessions;
		const { items } = this.state;
		console.log('Sessions Data', session);
		return (
			<div className="container">
				<Prompt
					when={this.state.valid !== true}
					message="Leaving this form will lose your data"
				/>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Assign Budget
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.Message(
								'Budget Added Successfully!',
								'success',
								20,
								this.state.msg
							)}
							{this.Message(
								'Please fill the form properly!',
								'danger',
								20,
								this.state.errorMsg
							)}
							{this.Message(
								'Amount Exceeds! Enter Amount Less than 5 Lacs',
								'danger',
								20,
								this.state.checkAmount
							)}
							<form
								id="contact-form"
								name="contact-form"
								onSubmit={this.onHandleSubmit}
								noValidate
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												readOnly={true}
												value={this.props.userData.role}
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Sessions
											</label>
											<select
												onChange={this.onSelectSessionChange}
												class="custom-select"
												name="staffId"
												style={{ borderColor: '#01AC8A' }}
											>
												<option selected={this.state.msg ? true : false}>
													Choose Session...
												</option>
												{session &&
													session.map((item, index) => (
														<option key={index} value={`${item.sessionId}`}>
															{item.currentSession}
														</option>
													))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Budget Items
											</label>
											<select
												onChange={this.onSelectItemChange}
												class="custom-select"
												name="staffId"
												style={{ borderColor: '#01AC8A' }}
											>
												<option selected={this.state.msg ? true : false}>
													Choose Items...
												</option>
												{items &&
													items.map((item, index) => (
														<option
															key={index}
															value={`${JSON.stringify(item)}`}
														>
															{item.particular}
														</option>
													))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Remaining Amount
											</label>
											<input
												style={{ marginTop: 23, borderColor: '#01AC8A' }}
												class="form-control"
												type="text"
												value={this.state.openingAmount + ' ' + 'ر.ع.'}
												readOnly={true}
												placeholder="Budget Remaining Amount"
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Amount
											</label>
											<br />
											<small style={{ color: 'red' }}>
												*Amount should be less than opening amount
											</small>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												value={this.state.budgetAmount}
												placeholder="Budget Amount"
												name="budgetAmount"
												onChange={this.onHandleAmount}
												class="form-control"
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Budget For
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												class="form-control"
												type="text"
												value={this.state.particular}
												placeholder="Particular"
												name="particular"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Description
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												value={this.state.description}
												placeholder="Description"
												name="description"
												onChange={this.onHandleTextChange}
												class="form-control"
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												User Request Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												value={this.state.userRequestName}
												placeholder="User Request Name"
												name="userRequestName"
												onChange={this.onHandleTextChange}
												class="form-control"
											/>
										</div>
									</div>
								</div>

								<br />
								<br />
								<div class="text-center text-md-center">
									<button class="btns">Submit</button>
								</div>
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		BudgetActions: bindActionCreators(BudgetActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectSession: state.setBudgetReducer.sessionSelect,
		selectItems: state.setBudgetReducer.ItemsSelect
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddBudget);
