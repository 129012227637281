import React, { Component } from 'react';
import $ from 'jquery';
import RenderDynamicRows from './RenderDynamicDescription';

class MultipleDescriptive extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('#addBtn').hover(
				function() {
					$(this).css(
						'background',
						'linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)'
					);
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('background', '#0A4F5E');
				}
			);
		});
	};
	render() {
		const {
			onHandleTextChange,
			stateData,
			updateSpecialArrObjOnChange,
			onClickAddItem,
			onClickDeleteItem,
			onChanging
		} = this.props;
		const { session } = stateData.session;
		return (
			<div class="animated fadeInDown">
				<h5 class="h5-responsive font-weight-bold text-left my-4">
					Create Descriptive Report:
				</h5>

				<div style={{ width: '100%' }}>
					<div class="table-responsive">
						<table class="table table-hover text-center">
							<thead style={{ background: '#01AC8A', color: 'white' }}>
								<tr class="row">
									<th class="col-md-1">#</th>
									<th class="col-md-3">Student Name</th>
									<th class="col-md-7">Description</th>
								</tr>
							</thead>
							<tbody>
								{stateData &&
									stateData.SkillReports.map((item, index) => (
										<tr class="row" key={index}>
											<th class="col-md-1">{index + 1}</th>
											<RenderDynamicRows
												arrName={'SkillReports'}
												updateSpecialArrObjOnChange={
													updateSpecialArrObjOnChange
												}
												rowIndex={index}
												onHandleTextChange={onHandleTextChange}
												stateData={stateData}
												itemObj={item}
											/>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
				<br />
				<br />
				<br />
			</div>
		);
	}
}

export default MultipleDescriptive;
