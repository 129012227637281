import React, { useState } from 'react';
import LoaderWrapper from '../../../components/HOC/LoaderWrapper.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';

import ListView from '../../../components/List/ListView/ListView';
import useHttp from '../../../hooks/http.js';
import {
	isEmptyFieldInArrOBJ,
	MAIN_HEADING,
	SUB_HEADING
} from '../../../utils/general/index';
import Button from '../../../components/Button/index.jsx';
import CustomHeading from '../../../components/CustomHeading/index.jsx';
import validateinput, {
	CHECK_MARKS_RANGE
} from '../../../validator/inputValidator';
import InfoComponent from '../../../components/InfoComponent/InfoComponent.jsx';
import SnackBar from '../../../components/SnackBar/SnackBar';

function EditAdminDiscipline(props) {
	const [error, setError] = useState({});
	const [msg, setMsg] = useState('');
	const {
		classId,
		section,
		session,
		term,
		studentId,
		subjectId
	} = props.match.params;

	const [isLoader, fetchData, setFetchData] = useHttp(
		`/api/DisciplinaryRecord/?studentId=${studentId}&classId=${classId}&sectionId=${section}&term=${term}&subjectId=${subjectId}&sessionId=${session}`,
		[]
	);

	const isValid = (data, validateType) => {
		const { errors, isValid } = validateinput(data, validateType);
		if (!isValid) {
			setError(errors);
		}
		return isValid;
	};

	const onChangeMarks = (e, rowIndex) => {
		if (isValid({ marks: e.target.value }, CHECK_MARKS_RANGE)) {
			let data = [...fetchData];

			data[rowIndex][e.target.name] = e.target.value;
			data[rowIndex]['accountId'] = props.name;
			data[rowIndex]['accountType'] = props.role;

			setFetchData(data);
		}
	};
	const onEditSubmit = () => {
		props.sharedAction
			.editRecordWithoutDispatch(
				`/api/editStudentDiscipline/`,
				fetchData[0]['id'],
				fetchData[0]
			)
			.then(success => {
				debugger;
				setMsg('Update Successfully');
				debugger;
				props.snackbar();
				setTimeout(() => {
					props.history.push('/dashboard/AdminRoleStudentDiscipline');
				}, 800);
			})
			.catch(error => {
				if (error && error.response && error.response.status == 400) {
					setMsg(error.response.data.Message);
					props.snackbar();
				}
			});
	};
	return (
		<div>
			{
				<LoaderWrapper isLoading={isLoader}>
					<SnackBar backColor={'#000'} msg={msg} />

					<CustomHeading headingText={'Edit Marks'} type={SUB_HEADING} />
					<br />
					{error['rangeError'] ? (
						<InfoComponent
							classType="text-danger"
							fontSize={15}
							message={`Note: marks can only be assigned into the range of 0 - 4`}
						/>
					) : (
						''
					)}
					<ListView
						excludedData={[
							'id',
							'sessionId',
							'sessionName',
							'studentId',
							'section',
							'studentName',
							'classId',
							'className',
							'sectionId',
							'session',
							'subjectId',
							'subjectName',
							'term',
							'status',
							'date',
							'time',
							'accountId',
							'accountType'
						]}
						tableMainData={fetchData}
						tableHeadData={['S #', 'Student Name', 'Marks']}
						onChangeMarks={onChangeMarks}
					/>
					{fetchData && fetchData.length > 0 ? (
						<Button
							buttonClass="customButton"
							btnName={'Update'}
							customClause={isEmptyFieldInArrOBJ(fetchData, 'marks')}
							iconSize={19}
							onHandleSubmit={onEditSubmit}
						/>
					) : null}
				</LoaderWrapper>
			}
		</div>
	);
}
function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditAdminDiscipline);
