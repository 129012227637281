import React, { Component } from 'react';
import './Form.css';

class StudentSiblings extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { siblingData } = this.props;
		console.log(siblingData);
		return (
			<div>
				<section class="mb-4">
					{/* <div class="row print">
            <div class="col-md-12">
              <div class="md-form mb-0">
                <h1
                  style={{
                    backgroundColor: "#0A4F5E",
                    textAlign: "center",
                    color: "white"
                  }}
                >
                  Student Siblings:
                </h1>
              </div>
            </div>
          </div> */}
					<div class="container">
						<div class="row rowCheck" style={{ marginTop: 10 }}>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Sibling Name:</label>
								&nbsp;&nbsp; {siblingData.siblingName}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Age: </label>&nbsp;&nbsp;
								{siblingData.age}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Relation: </label>
								&nbsp;&nbsp;{siblingData.relation}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Education:</label>
								&nbsp;&nbsp;{siblingData.education}{' '}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>School:</label>
								&nbsp;&nbsp;{siblingData.school}
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default StudentSiblings;
