import React, { Component } from 'react';
import $ from 'jquery';
// import './viewAdmin.css';
import Axios from 'axios';
import config from '../../config/config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as getAllAdminActions from '../../Actions/getAllAdmin';
import { Link } from 'react-router-dom';
import setAllAdminReducer from '../../Reducers/setAllAdminReducer';
import * as AddActions from '../../Actions/Admission';
import Button from '../../components/Button';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import setAdmissionReducer from '../../Reducers/setAdmissionReducer';
import Colors from '../../utils/app_constants/colors_constants';
import { General } from '../../utils';
import Font_Constant from '../../utils/app_constants/fonts_constants';
import URL_CONSTANTS from '../../utils/app_constants/URL_constants';
import UploadProgressBar from '../../components/UploadProgressBar/UploadProgressBar';

class UploadedFilesView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stdPercent: 0,

			adminData: [],
			errors: {},
			msg: '',
			getValuesOfStudentProps: null,
			getStudentDocuments: null,
			getMeaningStudProps: null,

			getValuesOfParentProps: null,
			getParentDocuments: null,
			getMeaningParntProps: null,

			customLoader: false,
			getParentDocs: null,
			modalData: null,
			username: '',
			post: '',
			name: '',
			count: 1,
			password: '********',
			enabled: false,
			id: null,
			percent: 0,
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			msg: ''
		};
	}
	setStdPercent = percent => this.setState({ stdPercent: percent });
	setStudDocsData = data => {
		this.setState({
			getStudentDocuments: data
		});
	};
	setParentDocsData = data => this.setState({ getParentDocuments: data });
	setPercent = percent => {
		this.setState({
			percent
		});
	};
	setSnackbarMsg = msg => {
		this.setState({
			msg
		});
	};
	setErrors = data => {
		this.setState({
			errors: data
		});
	};
	getSplitingResult = (input, valueNum) => {
		if (input === 'No') return input;

		try {
			let takeInput = input.split('_');
			return takeInput[valueNum].toString();
		} catch (error) {}
	};
	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.getStudentDocs !== nextProps.getStudentAllDocs &&
			prevState.getParentDocs !== nextProps.getParentAllDocs
		) {
			return {
				getStudentDocs: nextProps.getStudentAllDocs,
				getParentDocs: nextProps.getParentAllDocs
			};
		}
		return null;
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});

		// if(this.props.allAdminState.apiFlag){
		Promise.all([
			this.props.sharedActions.getDataWithoutDispatch(
				`/api/DocumentParentStudent/?studentId=${this.props.match.params.id}`
			)
		])
			.then(success => {
				debugger;

				if (success[0]['parentDocument'] !== null) {
					let getKeysOfParntDocs =
						success[0]['parentDocument'] !== null
							? Object.keys(success[0]['parentDocument'])
							: null;
					let getMeaningPrntProp = getKeysOfParntDocs.filter(
						item =>
							item !== 'id' &&
							item !== 'studentId' &&
							item !== 'fileNameIdCardFather' &&
							item !== 'fileNamePassportFather' &&
							item !== 'fileNameIdCardMother' &&
							item !== 'fileNamePassportMother'
					);
					let getValueOfPrntProps = getKeysOfParntDocs.filter(
						item =>
							item !== 'id' &&
							item !== 'studentId' &&
							item !== 'idCardFather' &&
							item !== 'passportFather' &&
							item !== 'idCardMother' &&
							item !== 'passportMother'
					);
					this.setState({
						getParentDocuments: success[0]['parentDocument'],
						getMeaningParntProps: getMeaningPrntProp,
						getValuesOfParentProps: getValueOfPrntProps
					});
				}

				let getKeysOfStudDocs =
					success[0]['studentDocument'] !== null
						? Object.keys(success[0]['studentDocument'])
						: null;
				let getMeaningStudentProp = getKeysOfStudDocs.filter(
					item =>
						item !== 'id' &&
						item !== 'studentId' &&
						item !== 'fileNameDobCertificate' &&
						item !== 'fileNameTransferCertifcate' &&
						item !== 'fileNameMinistryVaccination' &&
						item !== 'fileNamePassportCopy' &&
						item !== 'fileNameIdCard' &&
						item !== 'fileNameMedicalHistory' &&
						item !== 'fileNameLastResult' &&
						item !== 'fileNameTestResult'
				);
				let getValueOfStudProps = getKeysOfStudDocs.filter(
					item =>
						item !== 'id' &&
						item !== 'studentId' &&
						item !== 'dobCertificate' &&
						item !== 'transferCertificate' &&
						item !== 'ministryVaccination' &&
						item !== 'passportCopy' &&
						item !== 'idCardCopy' &&
						item !== 'medicalHistory' &&
						item !== 'lastResult' &&
						item !== 'testResult'
				);
				debugger;
				this.setState({
					isLoading: false,
					getStudentDocuments: success[0]['studentDocument'],
					getMeaningStudProps: getMeaningStudentProp,
					getValuesOfStudentProps: getValueOfStudProps
				});
			})
			.catch(err => {
				debugger;
				this.setState({
					isLoading: false
				});
				console.log(err);
			});
		// }
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	setCustomLoader = state => {
		this.setState({
			customLoader: state
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});

		this.props.sharedActions
			.deleteRecord(
				'/api/StudentAdmissionForm/',
				itemId,
				this.props.AddAction.deleteSingleFormData
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.id === itemId);
		this.setState({
			modalData: getData,
			username: getData[0].userName,
			post: getData[0].post,
			password: '*****',
			name: getData[0].name,
			id: getData[0].id
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	docsChangeStudent = (e, data) => {
		debugger;
		const { errors, getStudentDocuments } = this.state;
		debugger;
		let updateData = { ...getStudentDocuments };
		debugger;
		let singleDoc = new FormData();
		e.preventDefault();
		try {
			let reader = new FileReader();
			let file = e.target.files[0];

			if (
				!General.checkForImageOrFile(
					false,
					true,
					errors,
					file.size,
					2.1,
					file.name,
					'fileType',
					this.setErrors
				)
			) {
				singleDoc.append('fileName', file);
				singleDoc.append('studentId', getStudentDocuments['studentId']);
				debugger;
				this.setCustomLoader(true);
				let token = '';
				if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
				this.props.sharedActions
					.patchDocumentsWithProgressCallback(
						`/api/DocumentStudent/${data['id']}/?field=${data['fieldName']}`,
						singleDoc,
						this.setStdPercent
					)
					.then(success => {
						debugger;
						debugger;
						updateData[data['fieldValue']] = 'Yes_' + data['fieldValue'];
						debugger;
						updateData[data['updatedProps']] = success[data['updatedProps']];
						debugger;
						debugger;
						console.log(updateData);
						this.setStudDocsData(updateData);
						this.setCustomLoader(false);
						this.setSnackbarMsg('File upload successfully!');
						this.props.snackbar();
						setTimeout(() => {
							this.setStdPercent(0);
						}, 2000);
					})
					.catch(error => {
						debugger;
						this.setSnackbarMsg(
							error &&
								error.response &&
								error.response.data &&
								error.response.data.Message
						);
						this.props.snackbar();
					});
			} else {
				debugger;
			}
		} catch (e) {
			console.log(e);
		}
	};
	docsChangeParent = (e, data) => {
		debugger;
		const { errors, getStudentDocs, getParentDocuments } = this.state;
		debugger;
		let updateData = { ...getParentDocuments };
		debugger;
		let singleDoc = new FormData();
		e.preventDefault();
		try {
			let reader = new FileReader();
			let file = e.target.files[0];

			if (
				!General.checkForImageOrFile(
					false,
					true,
					errors,
					file.size,
					2.1,
					file.name,
					'fileType',
					this.setErrors
				)
			) {
				singleDoc.append('fileName', file);
				singleDoc.append('studentId', getParentDocuments['studentId']);
				debugger;
				this.setCustomLoader(true);
				let token = '';
				if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
				this.props.sharedActions
					.patchDocumentsWithProgressCallback(
						`/api/DocumentParent/${data['id']}/?field=${data['fieldName']}`,
						singleDoc,
						this.setPercent
					)
					.then(success => {
						debugger;
						debugger;
						updateData[data['fieldValue']] = 'Yes_' + data['fieldValue'];
						updateData[data['updatedProps']] = success[data['updatedProps']];
						debugger;
						console.log(updateData);
						this.setParentDocsData(updateData);
						this.setCustomLoader(false);
						this.setSnackbarMsg('File upload successfully!');
						this.props.snackbar();
						setTimeout(() => {
							this.setPercent(0);
						}, 2000);
					})
					.catch(error => {
						debugger;
						this.setSnackbarMsg(
							error &&
								error.response &&
								error.response.data &&
								error.response.data.Message
						);
						this.props.snackbar();
					});
			} else {
				debugger;
			}
		} catch (e) {
			console.log(e);
		}
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const { username, password, id, post, name } = this.state;
		var bodyParameters = { id, userName: username, name, password, post };
		this.props.sharedActions
			.editRecord(
				'/api/Admin/',
				id,
				bodyParameters,
				this.props.adminActions.updateAdmin
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				this.props.snackbar();
			})
			.catch(err => {});
	};

	componentWillUnmount() {
		$('.tooltip').tooltip('hide');
	}
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	render() {
		const { adminData, modalData } = this.state;

		const {
			getParentDocs,
			getKeysOfParentDocs,
			getStudentDocs,
			getMeaningParntProps
		} = this.state;

		let getKeysOfStudDocs =
			getStudentDocs && getStudentDocs[0] && getStudentDocs[0].t1
				? Object.keys(getStudentDocs[0].t1)
				: null;
		let getMeaningDocProp =
			getKeysOfStudDocs &&
			getKeysOfStudDocs.filter(
				item =>
					item !== 'docId' &&
					item !== 'studentId' &&
					item !== 'fileLength' &&
					item !== 'date' &&
					item !== 'time' &&
					item !== 'filePath' &&
					item !== 'fileName'
			);

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return index <= 3 ? (
				<li
					class={`page-item ${
						this.state.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={this.handleClickNext}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			) : (
				''
			);
		});
		const {
			currentPage,
			todosPerPage,
			getMeaningStudProps,
			customLoader,
			getStudentDocuments,
			getValuesOfStudentProps,
			getParentDocuments,
			getValuesOfParentProps
		} = this.state;
		debugger;
		const indexOfLastTodo = currentPage * todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
		const renderParentDoc =
			getMeaningParntProps && getMeaningParntProps.length > 0 ? (
				getMeaningParntProps &&
				getMeaningParntProps.map((item, index) => {
					return (
						<tr key={index}>
							<th
								style={{
									padding: 2,
									borderColor: Colors.SEA_GREEN_THEME,
									border: '1px solid #01AC8A'
								}}
								scope="row"
							>
								{index + 1}
							</th>
							<td
								style={{
									fontWeight: 'bolder',
									borderColor: Colors.SEA_GREEN_THEME,
									border: '1px solid #01AC8A'
								}}
							>
								{General.camelCaseToCamelCase(item)}
							</td>
							<td
								style={{
									padding: 2,
									borderColor: Colors.SEA_GREEN_THEME,
									border: '1px solid #01AC8A'
								}}
							>
								{this.getSplitingResult(getParentDocuments[item], 0) ===
								'Yes' ? (
									<span
										style={{ fontWeight: 'bold', color: Colors.LIGHT_GREEN }}
									>
										Yes
									</span>
								) : (
									<span
										style={{ fontWeight: 'bold', color: Colors.RED_DANGER }}
									>
										No
									</span>
								)}
							</td>

							<td
								style={{
									padding: 2,
									borderColor: Colors.SEA_GREEN_THEME,
									border: '1px solid #01AC8A'
								}}
							>
								{getParentDocuments[getValuesOfParentProps[index]] === null ? (
									<span
										style={{ fontWeight: 'bold', color: Colors.RED_DANGER }}
									>
										No File
									</span>
								) : (
									<span style={{ fontWeight: 'bold' }}>
										{getParentDocuments[getValuesOfParentProps[index]]}
									</span>
								)}
							</td>

							<td
								style={{
									padding: 2,
									borderColor: Colors.SEA_GREEN_THEME,
									border: '1px solid #01AC8A'
								}}
							>
								{this.getSplitingResult(getParentDocuments[item], 0) ===
								'No' ? (
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										<div>
											<i
												style={{
													color: Colors.RED_DANGER,
													fontSize: Font_Constant.MEDIUM_ICON,
													marginRight: 6,
													marginTop: 8
												}}
												class="fas fa-ban"
											></i>
										</div>
										<div>
											<label class="btn-btn-default">
												<a id="tooltipId" data-toggle="tooltip" title="upload">
													<div
														style={{
															color: customLoader
																? Colors.DISABLE_GREY
																: Colors.BLACK,
															fontSize: Font_Constant.MEDIUM_ICON,
															marginTop: 8
														}}
														class="fa fa-upload fa-2x"
													></div>
												</a>
												<input
													disabled={customLoader}
													onChange={e =>
														this.docsChangeParent(e, {
															id: getParentDocuments['id'],
															fieldName: getParentDocuments[item],

															fieldValue: item,
															updatedProps: getValuesOfParentProps[index]
														})
													}
													id="inputFile"
													className="filenew-upload"
													type="file"
													// accept="image/*"
												/>
											</label>
										</div>
									</div>
								) : (
									<div style={{ display: 'flex' }}>
										<button
											onMouseOver={this.btnTechHover()}
											disabled={customLoader}
											style={{
												paddingLeft: 0,
												cursor: 'pointer',
												fontSize: 20,
												border: 'none',
												background: 'none',
												marginLeft: 5,
												marginBottom: 5
											}}
										>
											<a
												style={{ display: 'block' }}
												href={`${URL_CONSTANTS.URL_PARENT_DOCS}${
													getParentDocuments[getValuesOfParentProps[index]]
												}`}
											>
												<a
													id="tooltipId"
													data-toggle="tooltip"
													title="download"
												>
													<i
														style={{
															color: customLoader
																? Colors.DISABLE_GREEN
																: Colors.LIGHT_GREEN,
															fontSize: Font_Constant.MEDIUM_ICON
														}}
														class="fas fa-file-download"
													></i>
												</a>
											</a>
										</button>
										{/* <a
										href={`https://embassyapp.linkit.org.uk/\\Uploads\\StaffDocuments\\${getStudentDocuments[0].t1[item]}`}
									> */}
										<div>
											<label class="btn-btn-default">
												<a id="tooltipId" data-toggle="tooltip" title="upload">
													<div
														style={{
															color: customLoader
																? Colors.DISABLE_GREY
																: Colors.BLACK,
															fontSize: Font_Constant.MEDIUM_ICON,
															marginTop: 8
														}}
														class="fa fa-upload fa-2x"
													></div>
												</a>
												<input
													onChange={e =>
														this.docsChangeParent(e, {
															id: getParentDocuments['id'],
															fieldName: getParentDocuments[item],

															fieldValue: item,
															updatedProps: getValuesOfParentProps[index]
														})
													}
													id="inputFile"
													className="filenew-upload"
													type="file"
												/>
											</label>
										</div>
									</div>
								)}
							</td>
						</tr>
					);
				})
			) : (
				<div style={{ marginLeft: 30 }}>
					<Link
						to={`/dashboard/ParentDocsUpload/${this.props.match.params.id}/edit`}
					>
						<Button
							icon={true}
							iconClass="fas fa-plus"
							textColor={Colors.WHITE}
							iconColor={Colors.WHITE}
							customPositionText={'relative'}
							customPositionTextBottom={4}
							iconTopMargin={1}
							btnBackColor={Colors.DARK_GREY}
							onHandleSubmit={() => console.log('parent upload')}
							buttonClass="btn-smart-one"
							btnName="Add Documents"
						/>
					</Link>
				</div>
			);

		// ////////////////////////////////////// new Section \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

		const renderTodos =
			getMeaningStudProps && getMeaningStudProps.length > 0 ? (
				getMeaningStudProps &&
				getMeaningStudProps.map((item, index) => {
					return (
						<tr key={index}>
							<th
								style={{
									padding: 2,
									borderColor: Colors.SEA_GREEN_THEME,
									border: '1px solid #01AC8A'
								}}
								scope="row"
							>
								{index + 1}
							</th>
							<td
								style={{
									fontWeight: 'bolder',
									borderColor: Colors.SEA_GREEN_THEME,
									border: '1px solid #01AC8A'
								}}
							>
								{General.camelCaseToCamelCase(item)}
							</td>
							<td
								style={{
									padding: 2,
									borderColor: Colors.SEA_GREEN_THEME,
									border: '1px solid #01AC8A'
								}}
							>
								{this.getSplitingResult(getStudentDocuments[item], 0) ===
								'Yes' ? (
									<span
										style={{
											fontWeight: 'bold',
											color: Colors.LIGHT_GREEN
										}}
									>
										Yes
									</span>
								) : (
									<span
										style={{ fontWeight: 'bold', color: Colors.RED_DANGER }}
									>
										No
									</span>
								)}
							</td>

							<td
								style={{
									padding: 2,
									borderColor: Colors.SEA_GREEN_THEME,
									border: '1px solid #01AC8A'
								}}
							>
								{getStudentDocuments[getValuesOfStudentProps[index]] ===
								null ? (
									<span
										style={{ fontWeight: 'bold', color: Colors.RED_DANGER }}
									>
										No file
									</span>
								) : (
									<span style={{ fontWeight: 'bold' }}>
										{getStudentDocuments[getValuesOfStudentProps[index]]}
									</span>
								)}
							</td>

							<td
								style={{
									padding: 2,
									borderColor: Colors.SEA_GREEN_THEME,
									border: '1px solid #01AC8A'
								}}
							>
								{this.getSplitingResult(getStudentDocuments[item], 0) ===
								'No' ? (
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										<div>
											<i
												style={{
													color: Colors.RED_DANGER,
													fontSize: Font_Constant.MEDIUM_ICON,
													marginRight: 6,
													marginTop: 8
												}}
												class="fas fa-ban"
											></i>
										</div>
										<div>
											<label class="btn-btn-default">
												<a id="tooltipId" data-toggle="tooltip" title="upload">
													<div
														style={{
															color: customLoader
																? Colors.DISABLE_GREY
																: Colors.BLACK,
															fontSize: Font_Constant.MEDIUM_ICON,
															marginTop: 8
														}}
														class="fa fa-upload fa-2x"
													></div>
												</a>
												<input
													disabled={customLoader}
													onChange={e =>
														this.docsChangeStudent(e, {
															id: getStudentDocuments['id'],
															fieldName: getStudentDocuments[item],

															fieldValue: item,
															updatedProps: getValuesOfStudentProps[index]
														})
													}
													id="inputFile"
													className="filenew-upload"
													type="file"
													// accept="image/*"
												/>
											</label>
										</div>
									</div>
								) : (
									<div style={{ display: 'flex' }}>
										<button
											onMouseOver={this.btnTechHover()}
											disabled={customLoader}
											style={{
												paddingLeft: 0,
												cursor: 'pointer',
												fontSize: 20,
												border: 'none',
												background: 'none',
												marginLeft: 5,
												marginBottom: 5
											}}
										>
											<a
												style={{ display: 'block' }}
												href={`${URL_CONSTANTS.URL_STUDENT_DOCS}${
													getStudentDocuments[getValuesOfStudentProps[index]]
												}`}
											>
												<a
													id="tooltipId"
													data-toggle="tooltip"
													title="download"
												>
													<i
														style={{
															color: customLoader
																? Colors.DISABLE_GREEN
																: Colors.LIGHT_GREEN,
															fontSize: Font_Constant.MEDIUM_ICON
														}}
														class="fas fa-file-download"
													></i>
												</a>
											</a>
										</button>
										{/* <a
										href={`https://embassyapp.linkit.org.uk/\\Uploads\\StaffDocuments\\${getStudentDocuments[0].t1[item]}`}
									> */}
										<div>
											<label class="btn-btn-default">
												<a id="tooltipId" data-toggle="tooltip" title="upload">
													<div
														style={{
															color: customLoader
																? Colors.DISABLE_GREY
																: Colors.BLACK,
															fontSize: Font_Constant.MEDIUM_ICON,
															marginTop: 8
														}}
														class="fa fa-upload fa-2x"
													></div>
												</a>
												<input
													onChange={e =>
														this.docsChangeStudent(e, {
															id: getStudentDocuments['id'],
															fieldName: getStudentDocuments[item],

															fieldValue: item,
															updatedProps: getValuesOfStudentProps[index]
														})
													}
													id="inputFile"
													className="filenew-upload"
													type="file"
												/>
											</label>
										</div>
									</div>
								)}
							</td>
						</tr>
					);
				})
			) : (
				<div style={{ marginLeft: 30 }}>
					<Link
						to={`/dashboard/StudentDocsUpload/${this.props.match.params.id}/edit`}
					>
						<Button
							icon={true}
							iconClass="fas fa-plus"
							textColor={Colors.WHITE}
							iconColor={Colors.WHITE}
							iconTopMargin={1}
							customPositionText={'relative'}
							customPositionTextBottom={4}
							onHandleSubmit={() => console.log('button')}
							btnBackColor={Colors.DARK_GREY}
							buttonClass="btn-smart-one"
							btnName="Add Documents"
						/>
					</Link>
				</div>
			);
		const MainContent = (
			<div>
				<br />
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<form onSubmit={this.onHandleSubmit}>
									{modalData &&
										modalData.map((item, index) => (
											<div key={index}>
												<fieldset disabled={!this.state.enabled}>
													<div class="form-group">
														<label for="username">Username:</label>
														<input
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.username}
															onChange={this.onHandleText}
															name="username"
															class="form-control"
															id="username"
														/>
													</div>
													<div class="form-group">
														<label for="pwd">Password:</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.password}
															onChange={this.onHandleText}
															type="password"
															name="password"
															class="form-control"
															id="password"
														/>
													</div>
													<div class="form-group">
														<label for="pwd">Post:</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.post}
															onChange={this.onHandleText}
															type="text"
															name="post"
															class="form-control"
															id="post"
														/>
													</div>
												</fieldset>
												<button
													disabled={this.state.enabled}
													onClick={() =>
														this.setState({ enabled: true, password: '' })
													}
													type="button"
													class="btn btn-primary buttonAppear"
												>
													Edit
												</button>
												<button
													style={{ marginLeft: 5 }}
													disabled={!this.state.enabled}
													type="submit"
													class="btn btn-primary buttonAppear"
												>
													Save
												</button>
											</div>
										))}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="table-responsive">
					<UploadProgressBar percent={this.state.stdPercent} />
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Student Documents</th>
								<th scope="col">Status</th>
								<th scope="col">FileName</th>

								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>{renderTodos}</tbody>
					</table>
				</div>
				<div className="table-responsive">
					<UploadProgressBar percent={this.state.percent} />
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Parents Documents</th>
								<th scope="col">Status</th>
								<th scope="col">FileName</th>
								{/* <th scope="col">Account Id</th> */}
								{/* <th scope="col">Account Type</th> */}

								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>{renderParentDoc}</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2 className="generalHead">Documents</h2>
						</div>
						<br />
						<br />
					</div>
				</div>
				{this.state.isLoading ? Loader : MainContent}
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(getAllAdminActions, dispatch),
		AddAction: bindActionCreators(AddActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllAddData: state.setAdmissionReducer.getAddData,
		getStudentAllDocs: state.setAdmissionReducer.getStudentDocs,
		getParentAllDocs: state.setAdmissionReducer.getParentDocs,
		allAdminState: state.setAllAdminReducer,
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UploadedFilesView);
