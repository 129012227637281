import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import GoogleMap from "google-distance-matrix";

import * as sharedActions from '../../Actions/sharedActions';
import * as routesActions from '../../Actions/Transport';
import DateandTime from './DateandTime';

class AddRoutes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			routeName: '',
			routeFees: '',
			Date: '',
			errorMsg: false,
			msg: false
		};
	}

	handleFormSubmit = e => {
		// var distance = require("google-distance-matrix");
		// var origins = ["San Francisco CA", "40.7421,-73.9914"];
		// var destinations = [
		//   "New York NY",
		//   "Montreal",
		//   "41.8337329,-87.7321554",
		//   "Honolulu"
		// ];
		// distance.key("AIzaSyAPe9ECEQvQ5j4-vXsmHgVAX6WNTqWzCYw");
		// distance.units("imperial");
		// distance.matrix(origins, destinations, function(err, distances) {
		//   if (err) {
		//     return console.log(err);
		//   }
		//
		//   if (!distances) {
		//     return console.log("no distances");
		//   }
		//
		//   if (distances.status == "OK") {
		//     for (var i = 0; i < origins.length; i++) {
		//       for (var j = 0; j < destinations.length; j++) {
		//         var origin = distances.origin_addresses[i];
		//         var destination = distances.destination_addresses[j];
		//         if (distances.rows[0].elements[j].status == "OK") {
		//           var distance = distances.rows[i].elements[j].distance.text;
		//           console.log(
		//             "Distance from " +
		//               origin +
		//               " to " +
		//               destination +
		//               " is " +
		//               distance
		//           );
		//         } else {
		//           console.log(
		//             destination + " is not reachable by land from " + origin
		//           );
		//         }
		//       }
		//     }
		//   }
		// });
	};
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	onHandleSubmit = e => {
		const { routeName, routeFees } = this.state;
		e.preventDefault();
		if (Object.is(routeFees, '') || Object.is(routeName, '')) {
			this.setState({
				errorMsg: true
			});
		} else {
			let data = {
				routeName,
				routeFees,
				date: new Date().toDateString(),
				time: new Date().toTimeString(),
				accountType: this.props.role,
				accountId: this.props.name
			};
			this.props.sharedActions
				.addRecord('/api/Route', data, this.props.routesActions.addTransport)
				.then(success => {
					this.props.routesActions.updateTransportFlag(true);

					this.setState({
						errorMsg: false,
						routeName: '',
						routeFees: '',
						msg: true
					});
				})
				.catch(err => {});
			console.log(this.state.routeFees, this.state.routeName);
		}
		console.log(this.state);
	};
	render() {
		const { errorMsg } = this.state;
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Routes
					</h2>
					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.state.errorMsg ? (
								<p style={{ color: 'red' }}>please fill the form properly!</p>
							) : (
								''
							)}
							{this.state.msg ? (
								<p style={{ color: 'green' }}>Route Add Successfully!</p>
							) : (
								''
							)}

							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-user-shield animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Admin Name
											</label>
											<input
												type="text"
												name="AdminName"
												class="form-control"
												value={this.props.name}
												readOnly={true}
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-user-alt animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Admin Type
											</label>
											<input
												type="text"
												name="AdminType"
												value={this.props.role}
												readOnly={true}
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-route"
													style={{ marginRight: 4 }}
												/>
												Route Name
											</label>
											<input
												type="text"
												name="routeName"
												value={this.state.routeName}
												class="form-control"
												placeholder="Route Name"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												<i
													className="fas fa-money-check-alt"
													style={{ marginRight: 4 }}
												/>
												Fees
											</label>
											<input
												type="number"
												value={this.state.routeFees}
												name="routeFees"
												placeholder="Route Fee"
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
								</div>
							</form>
							<br />
							<br />
							<div class="text-center text-md-center">
								<button onClick={this.handleFormSubmit} class="btns">
									Add
								</button>
							</div>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		routesActions: bindActionCreators(routesActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddRoutes);
