import React from 'react';
import Layout from './Container/HOC/Layout';
import { Switch, Route } from 'react-router-dom';
import MainDCom from './components/MainDCom';
import Head from './components/Head';
import LoginLayout from './Container/Login_Component/LoginLayout';
import Main from './components/Container/Main_Component/Main';
import subroutes from './subroutes';
import requireAuth from './middleware/requireAuthCommon';
import PageNotFound from './components/PageNotFound.jsx';
const Routes = props => {
	return (
		// <Layout>
		<Switch>
			<Route exact component={requireAuth(Main)} path="/" />
			<Route
				path="/Login/:select"
				component={requireAuth(props => (
					<LoginLayout {...props} />
				))}
			/>
			<Route component={() => <div>Admin List</div>} path="/AdminList" />
			<Route component={subroutes} path="/dashboard" />
			<Route component={PageNotFound} />
		</Switch>
		// </Layout>
	);
};
export default Routes;
