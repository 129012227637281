import React from 'react';
import { MAIN_HEADING, SUB_HEADING, SMALL_HEADING } from '../../utils/general';

export default function CustomHeading(props) {
	const {
		type = 'default',
		headingText = 'default Text',
		className = 'text-center generalHead'
	} = props;
	return type == 'default' ? (
		<h4 className={className}>{headingText}</h4>
	) : type === MAIN_HEADING ? (
		<h1 className={className}>{headingText}</h1>
	) : type === SUB_HEADING ? (
		<h2 className={className}>{headingText}</h2>
	) : type === SMALL_HEADING ? (
		<h4 className={className}>{headingText}</h4>
	) : null;
}
