import React, { Component } from 'react';

const StepperButtonsCom = props => (
	<div
		className={'row'}
		style={{
			fontSize: 20,
			display: 'flex',
			justifyContent: 'space-between',
			paddingLeft: 30
		}}
	>
		<div style={{ display: 'flex' }}>
			<div style={{ marginBottom: 5 }} class="text-center">
				<button
					onClick={e => props.onNextClick(e, props.formType)}
					class="btnAdd"
				>
					<i style={{ marginRight: 4 }} class="fas fa-arrow-circle-right"></i>
					{props.formType === 'ParentDocsForm' ? 'Submit' : 'Next'}
				</button>
			</div>

			{props.formType === 'studentAddForm' ? (
				<p></p>
			) : (
				<div style={{ marginLeft: 80 }} class="text-center">
					<button class="btnAdd" onClick={e => props.onPreviousClick(e)}>
						<i style={{ marginRight: 4 }} class="fas fa-arrow-circle-left"></i>
						Previous
					</button>
				</div>
			)}
		</div>
		{props.formType === 'studentSiblings' ||
		props.formType === 'studentPreviousSchoolDetails' ? (
			<div class="text-center">
				<button class="btnAdd" onClick={e => props.skipStep(e)}>
					<i style={{ marginRight: 4 }} class="fas fa-arrow-circle-left"></i>
					Skip
				</button>
			</div>
		) : (
			<p></p>
		)}
	</div>
);
export default StepperButtonsCom;
