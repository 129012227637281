import React, { Component } from 'react';
import './main.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
class Main extends Component {
	constructor(props) {
		super(props);
		console.log(this.props);
	}

	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css(
						'background',
						'linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)'
					);
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('background', '#343A40');
				}
			);
		});
	};
	render() {
		return (
			<div className="nav mainBackImg">
				<div
					className="container"
					style={{
						flexDirection: 'row',
						flexWrap: 'wrap',
						width: 900,
						padding: 100
					}}
				>
					<div className="row">
						<div className="col" />
						<div className="col animated fadeInDown img-fluid">
							<img
								style={{
									width: 700,
									height: 300
								}}
								src={require('../../../Assets/images/logo1.png')}
							/>
						</div>
						<div className="col" />
					</div>
					<div class="row">
						<div class="col">
							<div class="dropdown">
								<button
									class="btn btn-dark"
									type="button"
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
									style={{ width: 150, height: 40 }}
								>
									<i
										style={{ marginRight: 6 }}
										className="fas fa-user-shield animated fadeIn"
									/>
									Select Role
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<Link to="/Login/:Owner">
										{' '}
										<a class="dropdown-item" href="#">
											{' '}
											<i
												style={{ position: 'relative', right: 8 }}
												class="fas fa-dot-circle"
											></i>
											Owner
										</a>
									</Link>
									<Link to="./Login/:General Manager">
										<a class="dropdown-item" href="#">
											<i
												style={{ position: 'relative', right: 8 }}
												class="fas fa-dot-circle"
											></i>
											General Manager
										</a>
									</Link>
									<Link to="./Login/:Admin">
										<a class="dropdown-item" href="#">
											<i
												style={{ position: 'relative', right: 8 }}
												class="fas fa-dot-circle"
											></i>
											Admin
										</a>
									</Link>
									<Link to="./Login/:Manager">
										<a class="dropdown-item" href="#">
											<i
												style={{ position: 'relative', right: 8 }}
												class="fas fa-dot-circle"
											></i>
											Manager
										</a>
									</Link>
								</div>
							</div>
						</div>
						<div class="col">
							{/* <div className="b" style={{ width: 1000 }}> */}
							<Link to="/Login/:Student">
								<button
									type="button"
									class="btn btn-dark"
									style={{ width: 150, height: 40 }}
								>
									<i
										style={{ marginRight: 6 }}
										className="fas fa-user-alt animated fadeIn"
									/>
									Student
								</button>
							</Link>
						</div>
						<div className="col">
							<Link to="/Login/:Parent">
								<button
									type="button"
									class="btn btn-dark"
									style={{ width: 150, height: 40, margin: 1 }}
								>
									<i
										style={{ marginRight: 6 }}
										className="fas fa-restroom animated fadeIn"
									/>
									Parent
								</button>
							</Link>
						</div>
						<div class="col">
							<div class="dropdown">
								<button
									class="btn btn-dark"
									type="button"
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
									style={{ width: 150, height: 40 }}
								>
									<i
										style={{ marginRight: 6 }}
										className="fas fa-user-shield animated fadeIn"
									/>
									Staff Roles
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<Link to="/Login/:Staff">
										{' '}
										<a class="dropdown-item" href="#">
											{' '}
											<i
												style={{ position: 'relative', right: 8 }}
												class="fas fa-dot-circle"
											></i>
											Staff
										</a>
									</Link>
									<Link to="./Login/:Dean">
										<a class="dropdown-item" href="#">
											<i
												style={{ position: 'relative', right: 8 }}
												class="fas fa-dot-circle"
											></i>
											Dean
										</a>
									</Link>
									<Link to="./Login/:Hod">
										<a class="dropdown-item" href="#">
											<i
												style={{ position: 'relative', right: 8 }}
												class="fas fa-dot-circle"
											></i>
											HOD
										</a>
									</Link>
									<Link to="./Login/:Coordinator">
										<a class="dropdown-item" href="#">
											<i
												style={{ position: 'relative', right: 8 }}
												class="fas fa-dot-circle"
											></i>
											Coordinator
										</a>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Main;
