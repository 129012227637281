import React, { Component } from 'react';
import $ from 'jquery';
import RenderDynamicRows from '../StaffLoginModule/RenderTextBoxex';
import CheckBoxForAttendance from './CheckBoxForAttendance';
import DateandTime from '../Transport/DateandTime';
import moment from 'moment';

class AttendanceList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date: '',
			absentLength: '',
			absentFlag: false
		};
	}
	componentDidMount() {
		var answer = Array.isArray(this.props.stateData.absentList);
		// const date = moment("2015-07-02");
		// const dow = date.day();
		debugger;
		if (answer == false) {
			this.setState({
				date: new Date().toLocaleString(),
				absentFlag: false
			});
		} else {
			this.setState({
				date: new Date().toLocaleString(),
				absentLength: this.props.stateData.absentList.length,
				absentFlag: true
			});
		}
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('#addBtn').hover(
				function() {
					$(this).css(
						'background',
						'linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)'
					);
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('background', '#0A4F5E');
				}
			);
		});
	};

	render() {
		const {
			handleCheckChildElement,
			handleAllChecked,
			onHandleTextChange,
			stateData,
			updateSpecialArrObjOnChange,
			onClickAddItem,
			onClickDeleteItem,
			onChanging
		} = this.props;
		const { session } = stateData.session;
		return (
			<div class="animated fadeInDown">
				<div class="row">
					<div class="col-md-12 text-center">
						{this.state.absentFlag ? (
							<label
								style={{ fontSize: 15, color: '#01425e', fontWeight: 'bold' }}
							>
								{this.state.absentLength} Student is on Leave click below to see
								the Students
							</label>
						) : (
							<label
								style={{ fontSize: 15, color: '#01425e', fontWeight: 'bold' }}
							>
								No Student is on Leave{' '}
							</label>
						)}

						<br />
						{this.state.absentFlag ? (
							<React.Fragment>
								{' '}
								<a
									style={{ backgroundColor: '#01ac8a', color: 'white' }}
									class="btn"
									data-toggle="collapse"
									href="#collapseExample"
									role="button"
									aria-expanded="false"
									aria-controls="collapseExample"
								>
									View Student on Leave
									<i style={{ marginLeft: 5 }} class="fas fa-caret-down"></i>
								</a>
								<div class="collapse" id="collapseExample">
									<div class="table-responsive">
										<table class="table table-hover table-sm">
											<thead style={{ background: '#01AC8A', color: 'white' }}>
												<tr>
													<th scope="col">#</th>
													<th scope="col">Student Name</th>
													<th scope="col">Attendance</th>
													<th scope="col">Leave Type</th>
													<th scope="col">Leave Date</th>
												</tr>
											</thead>
											<tbody>
												{stateData &&
													stateData.absentList.map((item, index) => (
														<tr key={index}>
															<th>{index + 1}</th>
															<th>{item.studentName}</th>
															<th>{item.attendanceStatus}</th>
															<th>{item.leaveType}</th>
															<th>{item.leaveDate}</th>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
							</React.Fragment>
						) : (
							''
						)}
					</div>
					<div class="col-md-4">
						{/* <h5 class="h5-responsive font-weight-bold text-left my-4">
							Mark Student Attendence:
						</h5> */}
					</div>
					<div
						class="col-md-4"
						style={{
							marginTop: 5,
							textAlign: 'center',
							justifyContent: 'left'
						}}
					>
						<h5 class="h5-responsive font-weight-bold my-4">
							{this.state.date}
						</h5>
					</div>
					{/* <div
						class="col-md-4 text-right"
						style={{ fontSize: 18, marginTop: 20 }}
					>
						<div class="custom-control custom-checkbox text-right">
							<input
								class="custom-control-input"
								type="checkbox"
								onClick={e => handleAllChecked(e)}
								value="checkedall"
								id="defaultChecked"
							/>
							<label
								class="font-weight-bold custom-control-label"
								for="defaultChecked"
							>
								Marked All Present
							</label>
						</div>
					</div> */}
				</div>
				<div style={{ width: '100%' }}>
					<div class="table-responsive">
						<table class="table table-hover">
							<thead style={{ background: '#01AC8A', color: 'white' }}>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Student Name</th>
									<th scope="col">
										Attendance
										<div
											class="col-md-4 text-right"
											style={{ fontSize: 15, float: 'right' }}
										>
											<div class="custom-control custom-checkbox text-right">
												<input
													class="custom-control-input"
													type="checkbox"
													onClick={e => handleAllChecked(e)}
													value="checkedall"
													id="defaultChecked"
												/>
												<label
													class="font-weight-bold custom-control-label"
													for="defaultChecked"
												>
													Marked All Present
												</label>
											</div>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{stateData &&
									stateData.SubjectWiseAttendance.map((item, index) => (
										<tr key={index}>
											<th>{index + 1}</th>
											<th>{item.studentName}</th>
											<th>
												<CheckBoxForAttendance
													handleCheckChildElement={handleCheckChildElement}
													onHandleChange={updateSpecialArrObjOnChange}
													rowIndex={index}
													{...item}
												/>
											</th>
										</tr>
									))}
							</tbody>
						</table>
					</div>
					{/* <div className={'row'} style={{ justifyContent: 'flex-end' }}>
						<div>
							<button
								id={'addBtn'}
								onMouseEnter={this.btnTechHover()}
								onClick={() =>
									onClickAddItem('DriverHistories', {
										FineDetails: '',
										FineFee: '',
										FineType: '',
										TicketNo: ''
									})
								}
								style={{
									background: '#0A4F5E',
									color: '#FFFFFF',
									borderRadius: 100
								}}
								type="button"
								class="btn"
							>
								<i class="fas fa-plus" />
							</button>
						</div>
						<div style={{ marginLeft: 8 }}>
							<button
								onClick={() => onClickDeleteItem('DriverHistories')}
								style={{ color: '#FFFFFF', borderRadius: 100 }}
								type="button"
								class="btn btn-danger"
							>
								<i class="far fa-trash-alt" />
							</button>
						</div>
					</div> */}
				</div>
				<br />
				<br />
				<br />
				{/*<div class="text-center text-md-center">*/}
				{/*<button class="btns">Add</button>*/}
				{/*</div>*/}
			</div>
		);
	}
}

export default AttendanceList;
