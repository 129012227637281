import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput';
import TextArea from '../../components/TextArea'
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../Actions/sharedActions';
import Button from '../../components/Button';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import general from '../../utils/general';
import DatePicker from 'react-date-picker';

const teacherEvaluationDetailsList = [
    'Appearence',
    'Confidence',
    'Level Of Interest',
    'Class Control',
    'Relationship With Pupils',
    'Lesson Preparation',
    'Fluency of Language',
    'Clarity/Tone of Language',
    'Handwriting on W/Board',
    'Appropriate use of W/Board',
    'Use of other Teaching Material',
    'Use of Technology in Teaching',
    'Subject Knowledge',
    'Dealing With Homework',
    'Introduction',
    'Explaining New Ideas/Material',
    'Questioning Techniques',
    'Involvement of All Pupils',
    'Encouraing Participation',
    'Eliciting Information/Ideas',
    'Organization of Activities',
    'Giving Instruction',
    'Demonstration',
    'Movement Round Class',
    'Time Management',
    'Lession Planning',
    'Lesson Sequencing',
    'Dealing With People Question',
    'Balance of Oral/Writen Work',
    'Oral/Written Corrections'
]

class AddTeacherEvaluation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainData: {},
            classData: [],
            sectionData: [],
            sessionData: [],
            subjectData: [],
            staffData: [],
            emptyFields: [],
            a1: { grade: '', evaluationSkill: '', remarks: '' },
            a2: { grade: '', evaluationSkill: '', remarks: '' },
            a3: { grade: '', evaluationSkill: '', remarks: '' },
            a4: { grade: '', evaluationSkill: '', remarks: '' },
            a5: { grade: '', evaluationSkill: '', remarks: '' },
            a6: { grade: '', evaluationSkill: '', remarks: '' },
            a7: { grade: '', evaluationSkill: '', remarks: '' },
            a8: { grade: '', evaluationSkill: '', remarks: '' },
            a9: { grade: '', evaluationSkill: '', remarks: '' },
            a10: { grade: '', evaluationSkill: '', remarks: '' },
            a11: { grade: '', evaluationSkill: '', remarks: '' },
            a12: { grade: '', evaluationSkill: '', remarks: '' },
            a13: { grade: '', evaluationSkill: '', remarks: '' },
            a14: { grade: '', evaluationSkill: '', remarks: '' },
            a15: { grade: '', evaluationSkill: '', remarks: '' },
            a16: { grade: '', evaluationSkill: '', remarks: '' },
            a17: { grade: '', evaluationSkill: '', remarks: '' },
            a18: { grade: '', evaluationSkill: '', remarks: '' },
            a19: { grade: '', evaluationSkill: '', remarks: '' },
            a20: { grade: '', evaluationSkill: '', remarks: '' },
            a21: { grade: '', evaluationSkill: '', remarks: '' },
            a22: { grade: '', evaluationSkill: '', remarks: '' },
            a23: { grade: '', evaluationSkill: '', remarks: '' },
            a24: { grade: '', evaluationSkill: '', remarks: '' },
            a25: { grade: '', evaluationSkill: '', remarks: '' },
            a26: { grade: '', evaluationSkill: '', remarks: '' },
            a27: { grade: '', evaluationSkill: '', remarks: '' },
            a28: { grade: '', evaluationSkill: '', remarks: '' },
            a29: { grade: '', evaluationSkill: '', remarks: '' },
            a30: { grade: '', evaluationSkill: '', remarks: '' },
            classId: '',
            sectionId: '',
            sessionId: '',
            staffId: '',
            subjectId: '',
            observerName: '',
            schoolName: 'Dorat Al Khaleej',
            designation: '',
            topic: '',
            discussion: '',
            recommendation: '',
            otherComments: '',
            withOrWithout: '',
            observeFrom: '',
            accountId: props.name,
            accountType: props.role,
            errors: {},
            msg: '',
            errorMsg: false,
            isLoadingInitialData: true,
            isLoading: false
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/classsectiongradeteacher`
            )
            .then(success => {
                this.setState({
                    classData: success.classes,
                    mainData: success
                })
                this.props.sharedActions.getDataWithoutDispatch(
                    '/api/session'
                )
                    .then(success => {
                        this.setState({
                            sessionData: success.session,
                            isLoadingInitialData: false
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ isLoisLoadingInitialDataading: false })
                    })
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoadingInitialData: false })
            })
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false,
                    snackColor: ''
                });
            }, 5000);
        }
    }

    onHandleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onHandleRemarksChange = (e, stateVar) => {
        const target = e.target
        this.setState((prevState) => ({
            [stateVar]: { ...prevState[`${stateVar}`], remarks: target.value }
        }));
    }

    onHandleClassChange = e => {
        const { mainData } = this.state;

        const sectionData = general.dataFilteration(
            mainData.sections,
            ['sectionId', 'section'],
            {
                classId: e.target.value
            }
        )

        const subjectData = general.dataFilteration(
            mainData.subject,
            ['subjectId', 'subjectName'],
            {
                classId: e.target.value
            }
        )
        this.setState({
            [e.target.name]: e.target.value,
            sectionData: sectionData,
            subjectData: subjectData,
            staffData: []
        })
    }

    onHandleSelectChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onHandleSubjectChange = (e) => {
        const { mainData, classId, sectionId } = this.state;

        const staffData = general.dataFilteration(
            mainData.staff,
            ['staffName', 'staffId'],
            {
                classId: classId,
                sectionId: sectionId,
                subjectId: e.target.value
            }
        )
        this.setState({
            [e.target.name]: e.target.value,
            staffData: staffData
        })
    }

    handleOptionChange = (e, stateVar) => {
        const target = e.target
        this.setState((prevState) => ({
            [stateVar]: {
                ...prevState[`${stateVar}`],
                grade: target.value,
                evaluationSkill: target.name
            }
        }));
    };

    onDateChange = (date) => {
        this.setState({
            observeFrom: date
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        const { accountId, accountType, schoolName, observerName, designation, topic, classId, sectionId, sessionId, staffId, subjectId, discussion, recommendation, otherComments, observeFrom, withOrWithout, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13, a14, a15, a16, a17, a18, a19, a20, a21, a22, a23, a24, a25, a26, a27, a28, a29, a30 } = this.state;

        const teacherEvaluationDetails = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13, a14, a15, a16, a17, a18, a19, a20, a21, a22, a23, a24, a25, a26, a27, a28, a29, a30]

        if (a1.grade === '' ||
            a2.grade === '' ||
            a3.grade === '' ||
            a4.grade === '' ||
            a5.grade === '' ||
            a6.grade === '' ||
            a7.grade === '' ||
            a8.grade === '' ||
            a9.grade === '' ||
            a10.grade === '' ||
            a11.grade === '' ||
            a12.grade === '' ||
            a13.grade === '' ||
            a14.grade === '' ||
            a15.grade === '' ||
            a16.grade === '' ||
            a17.grade === '' ||
            a18.grade === '' ||
            a19.grade === '' ||
            a20.grade === '' ||
            a21.grade === '' ||
            a22.grade === '' ||
            a23.grade === '' ||
            a24.grade === '' ||
            a25.grade === '' ||
            a26.grade === '' ||
            a27.grade === '' ||
            a28.grade === '' ||
            a29.grade === '' ||
            a30.grade === '' ||
            Object.is(schoolName, '') ||
            Object.is(observerName, '') ||
            Object.is(designation, '') ||
            Object.is(topic, '') ||
            Object.is(classId, '') ||
            Object.is(sectionId, '') ||
            Object.is(sessionId, '') ||
            Object.is(staffId, '') ||
            Object.is(subjectId, '') ||
            Object.is(discussion, '') ||
            Object.is(recommendation, '') ||
            Object.is(otherComments, '') ||
            Object.is(withOrWithout, '') ||
            Object.is(observeFrom, '')) {
            $(window).scrollTop(0, 0);
            const emptyFields = []
            teacherEvaluationDetails.forEach((item, index) => {
                if (item.grade === '') {
                    emptyFields.push(index + 1)
                }
            })
            console.log(emptyFields);
            this.setState({
                errorMsg: true,
                emptyFields: emptyFields
            });
        } else {
            this.setState({
                isLoading: true
            })
            let date = general.dateToFormat(observeFrom)
            const data = {
                TeacherEvaluations: {
                    schoolName,
                    classId,
                    sectionId,
                    sessionId,
                    staffId,
                    subjectId,
                    observerName,
                    designation,
                    accountId,
                    accountType,
                    topic,
                    observeFrom: date,
                    withOrWithout
                },
                TeacherEvaluationDetails: teacherEvaluationDetails,
                EvaluationComments: {
                    discussion,
                    recommendation,
                    otherComments
                }
            }

            this.props.sharedActions
                .simpleAddRequest('/api/TeacherEvaluationForm',
                    data)
                .then(success => {
                    this.setState({
                        msg: 'Added Evaluation Successfully',
                        isLoading: false,
                        sectionData: [],
                        subjectData: [],
                        staffData: [],
                        a1: { grade: '', evaluationSkill: '', remarks: '' },
                        a2: { grade: '', evaluationSkill: '', remarks: '' },
                        a3: { grade: '', evaluationSkill: '', remarks: '' },
                        a4: { grade: '', evaluationSkill: '', remarks: '' },
                        a5: { grade: '', evaluationSkill: '', remarks: '' },
                        a6: { grade: '', evaluationSkill: '', remarks: '' },
                        a7: { grade: '', evaluationSkill: '', remarks: '' },
                        a8: { grade: '', evaluationSkill: '', remarks: '' },
                        a9: { grade: '', evaluationSkill: '', remarks: '' },
                        a10: { grade: '', evaluationSkill: '', remarks: '' },
                        a11: { grade: '', evaluationSkill: '', remarks: '' },
                        a12: { grade: '', evaluationSkill: '', remarks: '' },
                        a13: { grade: '', evaluationSkill: '', remarks: '' },
                        a14: { grade: '', evaluationSkill: '', remarks: '' },
                        a15: { grade: '', evaluationSkill: '', remarks: '' },
                        a16: { grade: '', evaluationSkill: '', remarks: '' },
                        a17: { grade: '', evaluationSkill: '', remarks: '' },
                        a18: { grade: '', evaluationSkill: '', remarks: '' },
                        a19: { grade: '', evaluationSkill: '', remarks: '' },
                        a20: { grade: '', evaluationSkill: '', remarks: '' },
                        a21: { grade: '', evaluationSkill: '', remarks: '' },
                        a22: { grade: '', evaluationSkill: '', remarks: '' },
                        a23: { grade: '', evaluationSkill: '', remarks: '' },
                        a24: { grade: '', evaluationSkill: '', remarks: '' },
                        a25: { grade: '', evaluationSkill: '', remarks: '' },
                        a26: { grade: '', evaluationSkill: '', remarks: '' },
                        a27: { grade: '', evaluationSkill: '', remarks: '' },
                        a28: { grade: '', evaluationSkill: '', remarks: '' },
                        a29: { grade: '', evaluationSkill: '', remarks: '' },
                        a30: { grade: '', evaluationSkill: '', remarks: '' },
                        classId: '',
                        sectionId: '',
                        sessionId: '',
                        staffId: '',
                        subjectId: '',
                        observerName: '',
                        schoolName: 'Dorat Al Khaleej',
                        designation: '',
                        topic: '',
                        discussion: '',
                        recommendation: '',
                        otherComments: '',
                        withOrWithout: '',
                        observeFrom: '',
                        errorMsg: false,
                        error: false,
                    })
                    teacherEvaluationDetailsList.map(item => {
                        var ele = document.getElementsByName(item);
                        for (var i = 0; i < ele.length; i++)
                            ele[i].checked = false;
                    })
                    this.props.snackbar();
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 400) {
                        // 404-Error no understanding of 404 Error
                        this.setState({
                            msg: error.response.data.Message,
                            isLoading: false,
                            snackColor: 'red'
                        })
                    }
                    this.props.snackbar();
                })
        }
    }

    render() {
        const { errors, isLoadingInitialData, sectionData, classData, staffData, subjectData, sessionData, observeFrom, emptyFields, snackColor } = this.state;

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div class="spinner-border" style={{ color: '#01AC8A' }}></div>
            </div>
        );

        const Form = (
            <FormLayoutWrapper
                formName='Add Teacher Evaluation'
                borderColor='#01ac8a'
                borderRadius={15}
            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly! {emptyFields.length !== 0 ? <strong><br />Evaluation {emptyFields.map(item => ` ${item}`)} are missing!</strong> : ''}
                    </p>
                ) : (
                        ''
                    )}


                <div className="row">

                    <div class='col-md-12'>
                        <h5 className="h1-responsive font-weight-bold my-4 generalHead">
                            {' '}
                            Information{' '}
                        </h5>
                    </div>


                    <TextInput
                        marginTop={20}
                        feildName={'schoolName'}
                        inputLabelName={'School Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="School Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'observerName'}
                        inputLabelName={'Observer Name'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Observer Name'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'designation'}
                        inputLabelName={'Designation'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Designation'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'topic'}
                        inputLabelName={'Topic'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Topic'}
                        isValidte={false}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'classId'}
                        selectName={'Class Name'}
                        onHandleChange={e => this.onHandleClassChange(e)}
                        errors={errors}
                        optionsArrys={classData}
                        selectedText={'Class Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'className'}
                        propertyId={'classId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'sectionId'}
                        selectName={'Section Name'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={sectionData}
                        selectedText={'Section Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'section'}
                        propertyId={'sectionId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'subjectId'}
                        selectName={'Subject Name'}
                        onHandleChange={e => this.onHandleSubjectChange(e)}
                        errors={errors}
                        optionsArrys={subjectData}
                        selectedText={'Subject Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'subjectName'}
                        propertyId={'subjectId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'sessionId'}
                        selectName={'Session'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={sessionData}
                        selectedText={'Session'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'currentSession'}
                        propertyId={'sessionId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'staffId'}
                        selectName={'Staff Name'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={staffData}
                        selectedText={'Staff'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'staffName'}
                        propertyId={'staffId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'withOrWithout'}
                        selectName={'Prior Notification'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={general.withOrWithout}
                        selectedText={'Prior Notification'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="static"
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />
                    <div class="col-md-6" style={{ marginTop: 25 }}>
                        <div class="md-form mb-0">
                            <label for="email" class="">
                                Observed From
											</label>
                            <br />
                            <DatePicker
                                //                                    maxDate={true}
                                onChange={e => this.onDateChange(e)}
                                value={observeFrom}
                                selectDateOnly
                            />
                        </div>
                    </div>


                    <div class='col-md-12' style={{ marginTop: 30 }}>
                        <h5 className="h1-responsive font-weight-bold my-4 generalHead">
                            {' '}
                            Evaluation{' '}
                        </h5>
                    </div>

                    <div class="col-md-12">
                        <h7 style={{ fontSize: 14 }}>**A=EXCELLENT(5)   B=V.GOOD(4) C=GOOD(3)   D=ACCEPTABLE(2)     E=UNSATISFACTORY(1)     N=Not Applicable(0)</h7>
                    </div>
                    <br />
                    <br />
                    <br />

                    <div class="col-md-12"  >
                        {teacherEvaluationDetailsList.map((item, index) => (
                            <form class='form-inline' name={`a${index + 1}`} style={{ marginBottom: 45 }}>
                                <div class='col-md-4' style={{ textAlign: 'left' }}>
                                    <text class='text-left' style={{ fontSize: 18 }}><strong>{index + 1}. {item}</strong></text>
                                </div>
                                <div class='col-md-4 form-inline'>
                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 7, fontSize: 20 }}>
                                        <input
                                            style={{ width: 15, height: 15 }}
                                            type="radio"
                                            value='A'
                                            name={item}
                                            onChange={e => this.handleOptionChange(e, `a${index + 1}`)}
                                        />
                                        A</label>
                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 7, fontSize: 20 }}>
                                        <input
                                            style={{ width: 15, height: 15 }}
                                            type="radio"
                                            value='B'
                                            name={item}
                                            onChange={e => this.handleOptionChange(e, `a${index + 1}`)}
                                        />
                                        B</label>

                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 7, fontSize: 20 }}>
                                        <input
                                            style={{ width: 15, height: 15 }}
                                            type="radio"
                                            value='C'
                                            name={item}
                                            onChange={e => this.handleOptionChange(e, `a${index + 1}`)}
                                        />
                                        C</label>

                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 7, fontSize: 20 }}>
                                        <input
                                            style={{ width: 15, height: 15 }}
                                            type="radio"
                                            value='D'
                                            name={item}
                                            onChange={e => this.handleOptionChange(e, `a${index + 1}`)}
                                        />
                                        D</label>

                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 7, fontSize: 20 }}>
                                        <input
                                            style={{ width: 15, height: 15 }}
                                            type="radio"
                                            value='E'
                                            name={item}
                                            onChange={e => this.handleOptionChange(e, `a${index + 1}`)}
                                        />
                                        E</label>

                                    <label style={{ color: '#007b5e', fontWeight: 'bold', marginRight: 7, fontSize: 20 }}>
                                        <input
                                            style={{ width: 15, height: 15 }}
                                            type="radio"
                                            value='N'
                                            name={item}
                                            onChange={e => this.handleOptionChange(e, `a${index + 1}`)}
                                        />
                                        N</label>
                                </div>
                                <div class='col-md-3'>
                                    <TextInput
                                        feildName={`a${index + 1}`.remarks}
                                        onHandleChange={e => this.onHandleRemarksChange(e, `a${index + 1}`)}
                                        errors={errors}
                                        stateData={this.state}
                                        placeholder={'Remarks'}
                                        isValidte={false}
                                    />
                                </div>
                            </form>
                        ))}
                    </div>


                    <TextArea
                        feildName={'recommendation'}
                        inputLabelName={'Recommendation'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Recommendation'}
                        isValidte={false}
                    />
                    <TextArea
                        feildName={'otherComments'}
                        inputLabelName={'Other Comments'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Add Other Comments'}
                        isValidte={false}
                    />
                    <SelectionInput
                        marginTop={20}
                        feildName={'discussion'}
                        selectName={'Discussion'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={general.YesNO}
                        selectedText={'Discussion'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="static"
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                </div>
                <br />

                <div class="text-center text-md-center">
                    <button type="submit" class="btns" onClick={e => this.onSubmit(e)}>
                        Add
										<span
                            style={{ marginBottom: 5 }}
                            class={`${
                                this.state.isLoading
                                    ? 'spinner-border spinner-border-sm'
                                    : ''
                                }`}
                        ></span>
                    </button>
                </div>

                {/*             <Button
                width="100%"
                buttonClass="customButton"
                btnName="Submit"
                loaderBmargin={5}
                stateData={this.state}
                marginTop={20}
                onHandleSubmit={this.onSubmit}
                margin={'0 auto'}
            />
 */}
            </FormLayoutWrapper>
        )
        return (
            <React.Fragment>
                <SnackBar backColor={snackColor} msg={this.state.msg} />
                {isLoadingInitialData ? Loader : Form}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddTeacherEvaluation);