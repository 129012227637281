import React, { Component } from 'react';
import DateandTime from '../../Transport/DateandTime';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
// import TimePicker from 'react-time-picker';
import moment from 'moment';

import TimePicker from '../../../components/TimePicker';
import BreakRendering from './BreakRendering';
import { alphaNumericReturn } from '../../../utils';

const format = 'h:mm a';

const now = moment()
	.hour(0)
	.minute(0);

const TimeTableStep3 = props => {
	const { stateData, getBreakData } = props;
	let getBreakNumber = [];
	for (let index = 1; index <= parseInt(stateData.noOfPeriods); index++) {
		getBreakNumber.push(alphaNumericReturn(index));
	}
	return (
		<div
			style={{
				fontSize: 20,
				border: '1px solid #D3D3D3',
				boxShadow: '0px 0px 15px #0A4F5E',
				padding: 10,
				borderRadius: 5,
				marginBottom: 30
			}}
		>
			<div class="row">
				<div class="col-md-12">
					<div style={{ textAlign: 'center' }} class="md-form mb-0">
						{props.stateData.errors.step3 ? (
							<p
								style={{
									color: 'red',
									fontSize: 17,
									textAlign: 'center',
									// position: 'relative',
									// bottom: 50,
									margin: 0
								}}
							>
								Please Enter Correct Time
							</p>
						) : (
							''
						)}
						<br />
						<div
							style={{
								// position: 'relative',
								// bottom: 90,
								display: 'flex',
								justifyContent: 'center'
							}}
						>
							<BreakRendering
								getBreakData={getBreakData}
								getBreakNumber={getBreakNumber}
								numOfBreaks={stateData.noOfBreaks}
							/>
							{/* <SelectionInput
								selectFont={17}
								selectName="Select Break Period"
								feildName="breakNo"
								selectedText="Choose Break"
								optionsArrys={props.stateData.periodArr}
								errors={props.stateData.errors}
								stateData={props.stateData}
								optionType="dynamic"
								onHandleChange={e => props.onTextChange(e)}
								property="name"
								// iconClassName='fas fa-level-down-alt'
								propertyId="name"
							/>
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							justifyContent: 'center',
							position: 'relative',
							bottom: 60
						}}
					>
						<div>
							<p
								style={{
									margin: 0,
									fontSize: 17,
									textAlign: 'center',
									color: '#000'
								}}
							>
								Start Time
							</p>
							<div>
								<TimePicker
									className="dateTimePicker"
									showSecond={false}
									placeholder={'Hr-Min-(AM/PM)'}
									name="BsTime"
									defaultValue={null}
									onChange={e => props.onChange(e, 'BsTime')}
									format={format}
									use12Hours
									// inputReadOnly
								/>
							</div>
						</div>
						<div style={{ margin: 25 }}>-</div>
						<div>
							<p
								style={{
									margin: 0,
									fontSize: 17,
									textAlign: 'center',
									color: '#000'
								}}
							>
								End Time
							</p>
							<div>
								<TimePicker
									className="dateTimePicker"
									showSecond={false}
									placeholder={'Hr-Min-(AM/PM)'}
									name="BeTime"
									defaultValue={null}
									onChange={e => props.onChange(e, 'BeTime')}
									format={format}
									use12Hours
									// inputReadOnly
								/>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			{/* <button class="btn btn-primary" onClick={() => this.props.stepperFun()}>Next</button> */}
		</div>

		// </div>
	);
};
export default TimeTableStep3;
