import React, { Component } from 'react';
import $ from 'jquery';
import '../Admin/viewAdmin.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as getAllAdminActions from '../../Actions/getAllAdmin';
import * as BudgetActions from '../../Actions/Budget';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';

class viewBudget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			particular: '',
			budgetAmount: '',
			status: '',
			description: '',
			userRequestName: '',
			enabled: false,
			id: null,
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			sessions: [],
			btnLoad: false,
			budgetPlanId: '',
			sessionId: ''
		};
	}

	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}

		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
			this.setState({
				disablepage: false
			});
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.adminData !== nextProps.AllAdminData ||
			prevState.sessions !== nextProps.selectSession
		) {
			return {
				adminData: nextProps.AllAdminData,
				sessions: nextProps.selectSession
			};
		}
		return null;
	}
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.gAllData('/api/Session', this.props.BudgetActions.getSessionForBudget)
			.then(success => {})
			.catch(error => {});
		if (this.props.allAdminState.apiFlag) {
			this.props.sharedActions
				.getAllData(
					'/api/getBudgetBySession/?sessionId=1',
					this.props.BudgetActions.setAllBudgetInRedux,
					this.props.BudgetActions.updateBudgetFlag
				)
				.then(success => {
					this.setState({
						isLoading: false
					});
				})
				.catch(err => {
					this.setState({
						isLoading: false
					});

					console.log(err);
				});
		}
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	inputData = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/Budget/',
				itemId,
				this.props.BudgetActions.deleteBudget
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.budgetId === itemId);

		this.setState({
			modalData: getData,
			particular: getData[0].particular,
			budgetAmount: getData[0].budgetAmount,
			status: getData[0].status,
			description: getData[0].description,
			userRequestName: getData[0].userRequestName,
			id: getData[0].budgetId,
			sessionId: getData[0].sessionId,
			budgetPlanId: getData[0].budgetPlanId
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const {
			particular,
			id,
			budgetAmount,
			status,
			description,
			userRequestName,
			sessionId,
			budgetPlanId
		} = this.state;
		var bodyParameters = {
			id,
			sessionId,
			budgetPlanId,
			particular,
			budgetAmount,
			status,
			description,
			userRequestName,
			accountId: this.props.users.unique_name,
			accountType: this.props.users.role
		};
		this.props.sharedActions
			.editRecord(
				'/api/Budget/',
				id,
				bodyParameters,
				this.props.BudgetActions.updateBudget
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				this.props.snackbar();
			})
			.catch(err => {});
	};
	onHandleSubmitSearch = e => {
		this.setState({
			btnLoad: true
		});
		this.props.sharedActions
			.getAllData(
				`/api/getBudgetBySession/?sessionId=${this.state.sessionId}`,
				this.props.BudgetActions.setAllBudgetInRedux,
				this.props.BudgetActions.updateBudgetFlag
			)
			.then(success => {
				this.setState({
					isLoading: false,
					btnLoad: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false,
					btnLoad: false
				});

				console.log(err);
			});
		console.log(this.state);
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);
		this.setState({
			sessionId: e.target.value
		});
	};
	render() {
		const { adminData, modalData } = this.state;
		const { session } = this.state.sessions;

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return index <= 3 ? (
				<li
					class={`page-item ${
						this.state.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={this.handleClickNext}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			) : (
				''
			);
		});
		const { currentPage, todosPerPage } = this.state;
		const indexOfLastTodo = currentPage * todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
		const renderTodos = currentTodos.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" style={{ padding: 3 }} className="bordersSpace">
						{index + 1}
					</th>
					<td style={{ padding: 3 }} className="bordersSpace">
						{item.particular}
					</td>
					<td style={{ padding: 3 }} className="bordersSpace">
						{item.description}
					</td>
					<td style={{ padding: 3 }} className="bordersSpace">
						{item.userRequestName}
					</td>
					<td style={{ padding: 3 }} className="bordersSpace">
						{item.budgetAmount}
					</td>
					<td style={{ padding: 3 }} className="bordersSpace">
						{item.status}
					</td>
					<td style={{ padding: 3 }} className="bordersSpace">
						{item.date}
					</td>
					<td style={{ padding: 3 }} className="bordersSpace">
						{item.time}
					</td>
					<td style={{ padding: 3 }} className="bordersSpace">
						{item.accountId}
					</td>
					<td style={{ padding: 3 }} className="bordersSpace">
						{item.accountType}
					</td>
					<td style={{ padding: 3 }} className="bordersSpace">
						<a href="#" data-toggle="tooltip" title="Edit!">
							<button
								id="firstbutton"
								onClick={() => this.onEditClick(item.budgetId)}
								onMouseOver={this.btnTechHover()}
								data-toggle="modal"
								data-target="#myModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i class="fas fa-pen"></i>
							</button>
						</a>
						<a href="#" data-toggle="tooltip" title="Delete!">
							<button
								onClick={() => this.onEditClick(item.budgetId)}
								data-toggle="modal"
								data-target="#myModal1"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
							</button>
						</a>
					</td>
				</tr>
			);
		});
		const MainContent = (
			<div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<form onSubmit={this.onHandleSubmit}>
									{modalData &&
										modalData.map((item, index) => (
											<div key={index}>
												<fieldset disabled={!this.state.enabled}>
													<div class="form-group">
														<label for="campusName">Particular:</label>
														<input
															readOnly={true}
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.particular}
															onChange={this.onHandleText}
															name="particular"
															class="form-control"
															id="particular"
														/>
													</div>
													<div class="form-group">
														<label for="Campus City">Budget Amount</label>
														<input
															readOnly={true}
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.budgetAmount}
															onChange={this.onHandleText}
															type="text"
															name="budgetAmount"
															class="form-control"
															id="budgetAmount"
														/>
													</div>
													<div class="form-group">
														<label for="Campus City">Description</label>
														<input
															readOnly={true}
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.description}
															onChange={this.onHandleText}
															type="text"
															name="description"
															class="form-control"
															id="budgetAmount"
														/>
													</div>
													<div class="form-group">
														<label for="Campus City">User Request Name</label>
														<input
															readOnly={true}
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.userRequestName}
															onChange={this.onHandleText}
															type="text"
															name="userRequestName"
															class="form-control"
															id="budgetAmount"
														/>
													</div>
													<div class="form-group">
														<label for="Campus Address">Status</label>
														<input
															readOnly={true}
															required
															readOnly={true}
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.status}
															onChange={this.onHandleText}
															type="text"
															name="campusAddress"
															class="form-control"
															id="campusAddress"
														/>
													</div>
													<div class="col-md-12" style={{ marginTop: 20 }}>
														<div class="md-form mb-0">
															<label for="name" class="">
																<i
																	className="fas fa-info-circle"
																	style={{ marginRight: 4 }}
																/>
																Change Status
															</label>
															<select
																onChange={this.inputData}
																class="custom-select"
																name="status"
															>
																<option>Choose Status..</option>
																<option value="Approved">Approve Status</option>
																<option value="Not Approved">
																	Disapprove Status
																</option>
															</select>
														</div>
													</div>
												</fieldset>
												<br />
												<button
													disabled={this.state.enabled}
													onClick={() => this.setState({ enabled: true })}
													type="button"
													class="btn btn-primary buttonAppear"
												>
													Edit
												</button>
												<button
													style={{ marginLeft: 5 }}
													disabled={!this.state.enabled}
													type="submit"
													class="btn btn-primary buttonAppear"
												>
													Save
												</button>
											</div>
										))}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th style={{ padding: 3 }} scope="col">
									#
								</th>
								<th style={{ padding: 3 }} scope="col">
									Particular
								</th>
								<th style={{ padding: 3 }} scope="col">
									Description
								</th>
								<th style={{ padding: 3 }} scope="col">
									Requestor
								</th>
								<th style={{ padding: 3 }} scope="col">
									Amount
								</th>
								<th style={{ padding: 3 }} scope="col">
									Status
								</th>
								<th style={{ padding: 3 }} scope="col">
									Date
								</th>
								<th style={{ padding: 3 }} scope="col">
									Time
								</th>
								<th style={{ padding: 3 }} scope="col">
									Acc.Id
								</th>
								<th style={{ padding: 3 }} scope="col">
									Acc.Type
								</th>
								<th style={{ padding: 3 }} scope="col">
									Actions
								</th>
							</tr>
						</thead>
						<tbody>{renderTodos}</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>Budget Details Session Wise</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								<li
									class={`page-item ${
										this.state.disableBPage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextBack}
										class="page-link"
										href="#"
									>
										{this.state.disableBPage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-backward"></i>
										)}
									</a>
								</li>
								{renderPageNumbers}
								<li
									class={`page-item ${
										this.state.disablepage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextFrwd}
										class="page-link"
										href="#"
									>
										{this.state.disablepage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-forward"></i>
										)}
									</a>
								</li>
							</ul>
						</nav>
					</div>
					<div class="row justify-content-end">
						<div class="col-md-3" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								<label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-bars animated fadeIn"
									/>
									Session's
								</label>
								<select
									onChange={this.onSelectSessionChange}
									class="custom-select"
									name="sectionName"
								>
									<option selected={this.state.msg ? true : false}>
										Select Current Session..
									</option>
									{session &&
										session.map((item, index) => (
											<option value={`${item.sessionId}`}>
												{item.currentSession}
											</option>
										))}
								</select>
							</div>
						</div>
						<div class="col-md-9" style={{ marginTop: 50 }}>
							<div class="md-form mb-0">
								<button
									type="submit"
									class="btns"
									onClick={this.onHandleSubmitSearch}
								>
									Search
									<span
										style={{ marginBottom: 5 }}
										class={`${
											this.state.btnLoad
												? 'spinner-border spinner-border-sm'
												: ''
										}`}
									></span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<br />
				{this.props.allAdminState.apiFlag ? Loader : MainContent}
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(getAllAdminActions, dispatch),
		BudgetActions: bindActionCreators(BudgetActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllAdminData: state.setBudgetReducer.Data,
		allAdminState: state.setBudgetReducer,
		selectSession: state.setBudgetReducer.sessionSelect,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(viewBudget);
