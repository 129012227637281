import { images } from '../../utils';
let ownerLinks = [
	{
		imagePath: images.imgFDash1,
		linkPath: '/dashboard/FinanceDashboard'
	},
	{
		linkPath: '/dashboard/',
		imagePath: images.imgBDash1
	},
	{
		linkPath: '/dashboard/AdministrationDashboard',
		imagePath: images.imgAdDash1
	},
	{
		linkPath: '/dashboard/AcademicsDashboard',
		imagePath: images.imgAcDash1
	},
	{
		linkPath: '/dashboard/AdmissionDashboard',
		imagePath: images.imgAdmissionDash1
	},
	//new enwe nwe
	// {
	// 	imagePath: images.imgFDash,
	// 	linkPath: '/dashboard/FinanceDashboard'
	// },
	// {
	// 	linkPath: '/dashboard/',
	// 	imagePath: images.imgBDash
	// },
	// {
	// 	linkPath: '/dashboard/AdministrationDashboard',
	// 	imagePath: images.imgAdDash
	// },
	// {
	// 	linkPath: '/dashboard/AcademicsDashboard',
	// 	imagePath: images.imgAcDash
	// },
	// {
	// 	linkPath: '/dashboard/',
	// 	imagePath: images.imgAdmissionDash
	// },
	//end end end
	// {
	// 	linkPath: '/dashboard/',
	// 	imagePath: images.imgComDash
	// },
	// {
	// 	linkPath: '/dashboard/HrDashboard',
	// 	imagePath: images.imgHRDash
	// },
	// {
	// 	linkPath: '/dashboard/',
	// 	imagePath: images.imgPasDash
	// },
	// {
	// 	linkPath: '/dashboard/',
	// 	imagePath: images.imgSysDash
	// },
	// {
	// 	linkPath: '/dashboard',
	// 	imagePath: images.imgGMDash
	// },
	//New NEw NEw
	{
		linkPath: '/dashboard/CommunicationDashboard',
		imagePath: images.imgComDash1
	},
	{
		linkPath: '/dashboard/HrDashboard',
		imagePath: images.imgHRDash1
	},
	{
		linkPath: '/dashboard/PastoralDashboard',
		imagePath: images.imgPasDash1
	},
	{
		linkPath: '/dashboard/',
		imagePath: images.imgSysDash1
	},
	{
		linkPath: '/dashboard/LibraryDashboard',
		imagePath: images.imgLibraryManage
	}
	//ENd NEw NEw New
];
let academicCMSLinks = [
	{
		linkPath: '/dashboard/TimeTableModule',
		imagePath: images.imgTT
	},
	{
		linkPath: '/dashboard/IndentDashboard',
		imagePath: images.imgAcademicIndent
	},
	{
		linkPath: '/dashboard/TeacherEvaluationDashboard',
		imagePath: images.imgAcademicTeacherEvaluation
	},
];
let academicSIDLinks = [
	{
		linkPath: '/dashboard/StudentSkillViewAdmin',
		imagePath: images.imgAcademicSkill
	},
	{
		linkPath: '/dashboard/StudentDescriptiveViewAdmin',
		imagePath: images.imgAcademicDescriptive
	},
	{
		linkPath: '/dashboard/AdminRoleStudentDiscipline',
		imagePath: images.imgViewSubDiscipline
	},
	{
		linkPath: '/dashboard/UpdateClassDiscipline',
		imagePath: images.imgClassDiscipline
	},
	{
		linkPath: '/dashboard/CADashboard',
		imagePath: images.imgCAImage
	},
	{
		linkPath: '/dashboard/StudentProgressReportDashboard',
		imagePath: images.DashboardStudentProgressReport
	},
	{
		linkPath: '/dashboard/LeaveDashboard',
		imagePath: images.imgAcademicLeave
	}
];
let caModuleLinks = [
	{
		linkPath: '/dashboard/caPrimaryInfo',
		imagePath: images.imgCaPmTool
	},
	{
		linkPath: '/dashboard/caSubToolView',
		imagePath: images.imgCAViewImage
	}
];
let academicLmsLink = [
	{
		linkPath: '/dashboard/homeworkdashboard',
		imagePath: images.imgAcademicHomeWork
	},
	{
		linkPath: '/dashboard/assignmentdashboard',
		imagePath: images.imgAcademicAssignment
	},
	{
		linkPath: '/dashboard/lessonplandashboard',
		imagePath: images.imgAcademicLesson
	},
	{
		linkPath: '/dashboard/ExamDashboard',
		imagePath: images.imgAcademicExam
	},
	{
		linkPath: '/dashboard/SyllabusDashboard',
		imagePath: images.imgAcademicSyllabus
	},
	{
		linkPath: '/dashboard/QuizDashboard',
		imagePath: images.imgAcademicQuiz
	},
	{
		linkPath: '/dashboard/TestDashboard',
		imagePath: images.imgAcademicTest
	},
	{
		linkPath: '/dashboard/PastPaperDashboard',
		imagePath: images.imgAcademicPastPaper
	},
	{
		linkPath: '/dashboard/ClassDashboard',
		imagePath: images.imgAcademicClass
	},
	{
		linkPath: '/dashboard/SectionDashboard',
		imagePath: images.imgAcademicSection
	},
	{
		linkPath: '/dashboard/SessionDashboard',
		imagePath: images.imgAcademicSession
	},
	{
		linkPath: '/dashboard/SubjectDashboard',
		imagePath: images.imgAcademicSubject
	},
];
let academicLinks = [
	{
		linkPath: '/dashboard/AcademicLMS',
		imagePath: images.imgAcademicLMS
	},
	{
		linkPath: '/dashboard/AcademicCMS',
		imagePath: images.imgAcademicCMS
	},
	{
		linkPath: '/dashboard/AcademicSID',
		imagePath: images.imgAcademicSID
	}
];

let timetableLinks = [
	{
		linkPath: '/dashboard/timeTable',
		imagePath: images.addTT
	},
	{
		linkPath: '/dashboard/viewTimeTable',
		imagePath: images.viewTT
	}
];
let LPWLinks = [
	{
		linkPath: '/dashboard/lessonperweek',
		imagePath: images.addLPW
	},
	{
		linkPath: '/dashboard/viewlessonperweek',
		imagePath: images.viewLPW
	}
];
let financeLinks = [
	{
		linkPath: '/dashboard/AccountLedger',
		imagePath: images.imgALDash
	},
	{
		linkPath: '/dashboard/BudgetScreen',
		imagePath: images.imgBudgetDash
	}
];
let budgetLinks = [
	{
		linkPath: '/dashboard/AddBudget',
		imagePath: images.imgAddIcon
	},
	{
		linkPath: '/dashboard/ViewBudget',
		imagePath: images.imgViewIcon
	}
];
let HrsmartLinks = [
	{
		linkPath: '/dashboard/SRCScreen',
		imagePath: images.imgHRSRM
	},
	{
		linkPath: '/dashboard/HRDevAndTrainScreen',
		imagePath: images.imgHRDevTrain
	},
	{
		linkPath: '/dashboard/FrontDeskOffScreen',
		imagePath: images.imgHRFrontDesk
	},
	{
		linkPath: '/dashboard/DepartmentScreen',
		imagePath: images.imgHRDept
	},
	{
		linkPath: '/dashboard/AssignPostScreen',
		imagePath: images.imgHRpost
	},
	{
		linkPath: '/dashboard/Appraisal',
		imagePath: images.imgHRSalary
	},
	{
		linkPath: '/dashboard/printStaffAdmissionForm',
		imagePath: images.imgHRStaffDoc
	},
	{
		linkPath: '/dashboard/AddBudget',
		imagePath: images.imgHRVacany
	},
	{
		linkPath: '/dashboard/AddBudget',
		imagePath: images.imgHrInterview
	}
];
const timeTable_module_links = [
	{
		linkPath: '/dashboard/timetable',
		imagePath: images.imgAcademicTimeTable
	},
	{
		linkPath: '/dashboard/viewTimetable',
		imagePath: images.viewTT
	},
	{
		linkPath: '/dashboard/LessonperweekScreen',
		imagePath: images.imgLPW
	},
	{
		linkPath: '/dashboard/viewLessonPerWeek',
		imagePath: images.viewLPW
	}
];
const Communication_Dashboard_Links = [
	{
		linkPath: '/dashboard/',
		imagePath: images.imgStudentManager
	},
	{
		linkPath: '/dashboard/ParentPortalManagerDashboard',
		imagePath: images.imgParentManager
	}
];
const ParentPortalManager_Links = [
	{
		linkPath: '/dashboard/AddParent',
		imagePath: images.imgAddParentResgitration
	},
	{
		linkPath: '/dashboard/UpdateParentRegistration',
		imagePath: images.imgUpdateParentResgitration
	},
	{
		linkPath: '/dashboard/RegisterStudentToParent',
		imagePath: images.imgRegisterParentResgitration
	},
	{
		linkPath: '/dashboard/SearchParentByChild',
		imagePath: images.imgRegisterParentResgitration
	}
];
const StaffModules_Links = [
	{
		linkPath: '/dashboard/staff',
		imagePath: images.addstaff
	},
	{
		linkPath: '/dashboard/ViewStaff',
		imagePath: images.viewstaff
	}
];
const Admission_Smart_Links = [
	{
		linkPath: '/dashboard/StudentAdmissionDashboard',
		imagePath: images.imgadmis
	},
	{
		linkPath: '/dashboard/ManagersDashboard',
		imagePath: images.imgManagerDashboard
	}
];
const Finance_Manager_Link = [
	{
		linkPath: '/dashboard/AddFinanceManager',
		imagePath: images.imgAddManager
	},
	{
		linkPath: '/dashboard/ViewFinanceManager',
		imagePath: images.imgViewManager
	}
];
const Mangers_Link = [
	{
		linkPath: '/dashboard/AddManager',
		imagePath: images.imgAddManager
	},
	{
		linkPath: '/dashboard/ViewManager',
		imagePath: images.imgViewManager
	}
];
const Accounts_Dashboard_Links = [
	{
		linkPath: '/dashboard/FeesDashboard',
		imagePath: images.imgFeesMain
	},
	{
		linkPath: '/dashboard/BillingDashboard',
		imagePath: images.imgBillingMain
	}
];
const Fees_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddFeeTypeNew',
		imagePath: images.imgAddFeeType
	},
	{
		linkPath: '/dashboard/ViewFeeTypeNew',
		imagePath: images.imgViewFeeType
	},
	{
		linkPath: '/dashboard/FeesCollectionNew',
		imagePath: images.imgFeeCollection
	},
	{
		linkPath: '/dashboard/MonthlyFeeListNew',
		imagePath: images.imgFeeView
	}
];
const Billing_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddBilling',
		imagePath: images.imgaddBilling
	},
	{
		linkPath: '/dashboard/ViewBilling',
		imagePath: images.imgViewBilling
	}
];
const PayRoll_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddPayRoll',
		imagePath: images.imgAddPayRoll
	},
	{
		linkPath: '/dashboard/ViewPayRoll',
		imagePath: images.imgViewPayRoll
	},
	{
		linkPath: '/dashboard/ViewPaySlip',
		imagePath: images.imgPaySlips
	}
];
const Library_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddBook',
		imagePath: images.imgAddBook
	},
	{
		linkPath: '/dashboard/ViewBook',
		imagePath: images.imgViewBook
	},
	{
		linkPath: '/dashboard/IssueBook',
		imagePath: images.imgIssuedBook
	},
	{
		linkPath: '/dashboard/ViewIssuedBook',
		imagePath: images.imgViewIssued
	}
];
const Event_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddEvent',
		imagePath: images.imgAddEvent
	},
	{
		linkPath: '/dashboard/UpdateEvent',
		imagePath: images.imgUpdateEvent
	},
	{
		linkPath: '/dashboard/ViewEvent',
		imagePath: images.imgViewEvent
	}
];

const StudentReport_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddStudentProgressReport',
		imagePath: images.AddStudentProgressReport
	},
	{
		linkPath: '/dashboard/ViewStudentProgressReport',
		imagePath: images.ViewStudentProgressReport
	},
];

const MaintenanceManager_Dashboard_Links = [
	{
		linkPath: '/dashboard/CampusMaintenanceDashboard',
		imagePath: images.DashboardCampusMaintenance
	}
]

const CampusMaintenance_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddFloor',
		imagePath: images.AddFloorCampusMaintenance
	},
	{
		linkPath: '/dashboard/ViewFloor',
		imagePath: images.ViewFloorCampusMaintenance
	},
	{
		linkPath: '/dashboard/AddRoom',
		imagePath: images.AddRoomCampusMaintenance
	},
	{
		linkPath: '/dashboard/ViewRoom',
		imagePath: images.ViewRoomCampusMaintenance
	},
	{
		linkPath: '/dashboard/AddCleanlinessCategory',
		imagePath: images.AddCleanlinessCategoryCampusMaintenance
	},
	{
		linkPath: '/dashboard/ViewCleanlinessCategory',
		imagePath: images.ViewCleanlinessCategoryCampusMaintenance
	},
	{
		linkPath: '/dashboard/AddCyclicCleanliness',
		imagePath: images.AddCyclicCleanlinessCampusMaintenance
	},
	{
		linkPath: '/dashboard/ViewCyclicCleanliness',
		imagePath: images.ViewCyclicCleanlinessCampusMaintenance
	},
	{
		linkPath: '/dashboard/AddEmergencyMaintenance',
		imagePath: images.AddEmergencyMaintenanceCampusMaintenance
	},
	{
		linkPath: '/dashboard/ViewEmergencyMaintenance',
		imagePath: images.ViewEmergencyMaintenanceCampusMaintenance
	},
	{
		linkPath: '/dashboard/NotificationEmergencyMaintenance',
		imagePath: images.NotificationEmergencyMaintenanceCampusMaintenance
	},
];


const Class_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddClass',
		imagePath: images.imgAddClass
	},
	{
		linkPath: '/dashboard/ViewClass',
		imagePath: images.imgViewClass
	},
]

const Section_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddSection',
		imagePath: images.imgAddSection
	},
	{
		linkPath: '/dashboard/ViewSection',
		imagePath: images.imgViewSection
	},
]

const Session_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddSession',
		imagePath: images.imgAddSession
	},
	{
		linkPath: '/dashboard/ViewSession',
		imagePath: images.imgViewSession
	},
]

const Subject_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddSubject',
		imagePath: images.imgAddSubject
	},
	{
		linkPath: '/dashboard/ViewSubject',
		imagePath: images.imgViewSubject
	},
]

const ClassTeacher_Dashboard_Links = [
	{
		linkPath: '/dashboard/AssignClassTeacher',
		imagePath: images.imgAddClassTeacher
	},
	{
		linkPath: '/dashboard/ViewClassTeacher',
		imagePath: images.imgViewClassTeacher
	},
]

const Indent_Dashboard_Links = [
	{
		linkPath: '/dashboard/ViewIndentForm',
		imagePath: images.imgViewIndent
	},
]

const IndentStaff_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddIndent',
		imagePath: images.imgAddIndent
	},
	{
		linkPath: '/dashboard/ViewIndentByStaff',
		imagePath: images.imgViewIndent
	},
]

const TeacherEvaluation_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddTeacherEvaluation',
		imagePath: images.imgAddTeacherEvaluation
	},
	{
		linkPath: '/dashboard/ViewTeacherEvaluation',
		imagePath: images.imgViewTeacherEvaluation
	},
]

const PastoralsLinks = [
	{
		linkPath: '/dashboard/MedicalDashboard',
		imagePath: images.imgPastoralMedicalCenter
	},
	{
		linkPath: '/dashboard/ActivityDashboard',
		imagePath: images.imgPastoralActivity
	},
]

const Medical_Dashboard_Links = [
	{
		linkPath: '/dashboard/PatientDashboard',
		imagePath: images.imgPatient
	},
	{
		linkPath: '/dashboard/DoctorDashboard',
		imagePath: images.imgDoctor
	},
	{
		linkPath: '/dashboard/MedicalHistory',
		imagePath: images.imgViewMedHistory
	}
]

const Doctor_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddDoctor',
		imagePath: images.imgAddDoctor
	},
	{
		linkPath: '/dashboard/ViewDoctor',
		imagePath: images.imgViewDoctor
	},
]

const Patient_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddPatient',
		imagePath: images.imgAddPatient
	},
	{
		linkPath: '/dashboard/PatientDetail',
		imagePath: images.imgViewPatient
	},
]

const CampusSecurity_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddVisitorEntry',
		imagePath: images.imgCSAddVisitor
	},
	{
		linkPath: '/dashboard/VisitorEntryRecord',
		imagePath: images.imgCSViewVisitor
	},
	{
		linkPath: '/dashboard/GenerateVisitorCardView',
		imagePath: images.imgCSGenerateCard
	},
]

const Leave_Dashboard_Links = [
	{
		linkPath: '/dashboard/ViewLeave',
		imagePath: images.imgViewLeaveStaff
	},
]

const StudentLeave_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddLeave',
		imagePath: images.imgAddLeave
	},
	{
		linkPath: '/dashboard/ViewLeaveByStudent',
		imagePath: images.imgViewLeaveStudent
	},
]

const SecurityManager_Links = [
	{
		linkPath: '/dashboard/CampusSecurityDashboard',
		imagePath: images.imgCSecurity
	},
]

const ProcurementManager_Links = [
	{
		linkPath: '/dashboard/IndentDashboard',
		imagePath: images.imgAdminProcurement
	},
]

const Tour_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddTour',
		imagePath: images.imgAddTour
	},
	{
		linkPath: '/dashboard/ViewTour',
		imagePath: images.imgViewTour
	},
	{
		linkPath: '/dashboard/ViewFilterTour',
		imagePath: images.imgViewTourFilters
	},
]

const Activity_Dashboard_Links = [
	{
		linkPath: '/dashboard/TourDashboard',
		imagePath: images.imgPastoralTour
	},
	{
		linkPath: '/dashboard/CertificateDashboard',
		imagePath: images.imgActivityCertificate
	},
	{
		linkPath: '/dashboard/AwardDashboard',
		imagePath: images.imgAward
	},
	{
		linkPath: '/dashboard/EventDashboard',
		imagePath: images.imgEvent
	},
	{
		linkPath: '/dashboard/GameDashboard',
		imagePath: images.imgGames
	},
]

const Certificate_Dashboard_Links = [
	{
		linkPath: '/dashboard/OtherCertificateDashboard',
		imagePath: images.imgOtherCertificate
	},
	{
		linkPath: '/dashboard/CertificateCategoryDashboard',
		imagePath: images.imgCertificateCategory
	},
]

const OtherCertificate_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddOtherCertificate',
		imagePath: images.imgAddOtherCertificate
	},
	{
		linkPath: '/dashboard/ViewOtherCertificate',
		imagePath: images.imgViewOtherCertificate
	},
]

const CertificateCategory_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddCertificateCategory',
		imagePath: images.imgAddCertificateCategory
	},
	{
		linkPath: '/dashboard/ViewCertificateCategory',
		imagePath: images.imgViewCertificateCategory
	},
]

const Award_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddAward',
		imagePath: images.imgAddAward
	},
	{
		linkPath: '/dashboard/ViewAward',
		imagePath: images.imgViewAward
	},
]

const Game_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddGame',
		imagePath: images.imgAddGames
	},
	{
		linkPath: '/dashboard/ViewGame',
		imagePath: images.imgViewGames
	},
	{
		linkPath: '/dashboard/OffGameDashboard',
		imagePath: images.imgOffGames
	},
]

const OffGame_Dashboard_Links = [
	{
		linkPath: '/dashboard/AddOffGame',
		imagePath: images.imgAddOffGame
	},
	{
		linkPath: '/dashboard/ViewOffGame',
		imagePath: images.imgViewOffGame
	}
]

export default {
	PayRoll_Dashboard_Links,
	StaffModules_Links,
	ownerLinks,
	academicLinks,
	timetableLinks,
	LPWLinks,
	financeLinks,
	budgetLinks,
	HrsmartLinks,
	academicLmsLink,
	timeTable_module_links,
	academicCMSLinks,
	academicSIDLinks,
	Communication_Dashboard_Links,
	ParentPortalManager_Links,
	Admission_Smart_Links,
	Mangers_Link,
	Accounts_Dashboard_Links,
	Fees_Dashboard_Links,
	caModuleLinks,
	Finance_Manager_Link,
	Billing_Dashboard_Links,
	Library_Dashboard_Links,
	Event_Dashboard_Links,
	StudentReport_Dashboard_Links,
	CampusMaintenance_Dashboard_Links,
	MaintenanceManager_Dashboard_Links,
	Class_Dashboard_Links,
	Section_Dashboard_Links,
	Session_Dashboard_Links,
	Subject_Dashboard_Links,
	ClassTeacher_Dashboard_Links,
	Indent_Dashboard_Links,
	IndentStaff_Dashboard_Links,
	TeacherEvaluation_Dashboard_Links,
	PastoralsLinks,
	Medical_Dashboard_Links,
	Doctor_Dashboard_Links,
	Patient_Dashboard_Links,
	CampusSecurity_Dashboard_Links,
	Leave_Dashboard_Links,
	StudentLeave_Dashboard_Links,
	SecurityManager_Links,
	ProcurementManager_Links,
	Tour_Dashboard_Links,
	Activity_Dashboard_Links,
	Certificate_Dashboard_Links,
	OtherCertificate_Dashboard_Links,
	CertificateCategory_Dashboard_Links,
	Award_Dashboard_Links,
	Game_Dashboard_Links,
	OffGame_Dashboard_Links
};
