import React from 'react';
import LoaderWrapper from '../HOC/LoaderWrapper';
import Colors from '../../utils/app_constants/colors_constants';

export default function CustomReportComponent(props) {
	const renderMarks = (marks = []) => {
		return (
			<React.Fragment>
				{marks.map((item, index) => (
					<td
						style={{
							textAlign: 'center',
							border: `1px solid ${Colors.SEA_GREEN_THEME}`
						}}
					>
						{item.marks}
					</td>
				))}
			</React.Fragment>
		);
	};

	const {
		isLoader = false,
		mainData = [],
		headData = [
			{ subjectName: 'Physics' },
			{ subjectName: 'MATH' },
			{ subjectName: 'ICT' },
			{ subjectName: 'BUSINESS' },
			{ subjectName: 'ENGLISH' },
			{ subjectName: 'ARABIC' },
			{ subjectName: 'SOCIAL' },
			{ subjectName: 'PE' },
			{ subjectName: 'ISLAMIC' },
			{ subjectName: 'ART' },
			{ subjectName: 'Music' }
		],
		startTH = [],
		tHeadFieldName = 'subjectName',
		headerImage
	} = props;

	return (
		<div>
			<LoaderWrapper isLoading={isLoader}>
				<table class="table table-bordered">
					<thead>
						<tr className="tablecheck print-table-head">
							{startTH &&
								startTH.map((item, index) => (
									<th
										className="tablecheck print-table-head"
										style={{ textAlign: 'center' }}
									>
										{' '}
										<div id="myDiv">
											<span id="mySpan"> {item}</span>{' '}
										</div>
									</th>
								))}
							{headData && headData.length > 0
								? headData.map(item => (
										<th className="print-table-head">
											<div id="myDiv">
												{' '}
												<span id="mySpan"> {item[tHeadFieldName]} </span>{' '}
											</div>
										</th>
								  ))
								: null}
						</tr>
					</thead>
					<tbody>
						{mainData && mainData.length > 0 ? (
							mainData.map((item, index) => (
								<tr>
									<td
										className="print-td-border"
										style={{
											border: `1px solid ${Colors.SEA_GREEN_THEME}`,
											textAlign: 'center'
										}}
									>
										{index + 1}
									</td>
									<td
										className="print-td-border"
										style={{ border: `1px solid ${Colors.SEA_GREEN_THEME}` }}
									>
										{item.StudentName}
									</td>
									{renderMarks(item.marks)}
								</tr>
							))
						) : (
							<tr>no Data</tr>
						)}
					</tbody>
				</table>
			</LoaderWrapper>
		</div>
	);
}
