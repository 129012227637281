import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import { Redirect } from 'react-router-dom';
import LayoutWrapper from '../../../components/HOC/LayoutWrapper';
import Stepper from 'bs-stepper';
import {
	Step1,
	Step2,
	Step3,
	Step4,
	Step5,
	Step1_Version1
} from '../../Time-Table';
import SnackBar from '../../../components/SnackBar/SnackBar.jsx';

import DateandTime from '../../Transport/DateandTime';
import * as sharedActions from '../../../Actions/sharedActions';
import * as timeActions from '../../../Actions/TimeTable';
import * as actions from '../../../Actions/Admission';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alphaNumericReturn, utilsTTActions } from '../../../utils';
import timeImag from '../../../Assets/images/timetable smart-03.svg';
import $ from 'jquery';
import { Fade } from 'react-reveal';
// import TimePicker from 'react-time-picker';

const format = 'h:mm a';

class TimeTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// flag
			metaExist: false,
			//
			// snackmsg
			msg: '',
			snackColor: '',
			//
			// contain data for existing time table
			metaDataForTT: null,
			// end data for existing time table
			// data for getting staff process
			allStaffData: null,
			// end of data for getting staff
			//modalLoader
			mLoader: false,
			// modalLoader
			// staffForParticular subject
			staffForPar: [],
			//
			// take official timing
			getOffDiff: '',
			// end of official timinig

			// start of multiple break
			multipleBreak: [],
			// end of multiple bbreak;
			discard: false,
			Stepes: ['Step 1', 'Step 2', 'Step 3'],
			offSDate: '',
			offEDate: '',
			PerPSDate: '',
			PerPEDate: '',
			noOfPeriods: '',
			getRemainingTime: '',
			getPreDiff: '',
			breakNo: '',

			noOfBreaks: 0,
			BsTime: '',
			BeTime: '',
			periodArr: [],
			breakRemain: 0,
			getTotalPeriodTime: '',
			workingDays: [
				{ day: 'Monday', off: true },
				{ day: 'Tuesday', off: true },
				{ day: 'Wednesday', off: true },
				{ day: 'Thursday', off: true },
				{ day: 'Friday', off: true },
				{ day: 'Saturday', off: true },
				{ day: 'Sunday', off: true }
			],
			workDayOptions: '',
			classSectionData: [],
			teachAndStaff: [],
			finalArray: [],
			newPeriodStruct: null,
			classId: '',
			sectionId: '',
			section: '',
			grade: '',
			gradeName: '',
			className: '',
			gradeId: '',
			breakRemainingTime: null,
			Sdate: new Date().toLocaleTimeString(),
			eDate: null,
			selectedDisPeriod: [],
			teachAndStaff: [],
			appearDialog: false,
			renderRemainTimeflag: false,
			renderEachDays: false,
			readyToDone: null,
			isLoading: true,
			animate: false,
			validDuration: new Date().toDateString(),
			errors: {}
		};
		this.timeTable = {
			Sdate: new Date().toLocaleTimeString(),
			Edate: new Date().toLocaleTimeString()
		};
	}

	onDiscard = () => {
		this.setState({
			discard: true
		});
	};
	Validation = step => {
		const {
			offSDate,
			offEDate,
			validDuration,
			breakNo,
			BsTime,
			BeTime,
			errors,
			noOfPeriods,
			workingDays,
			classId,
			gradeId,
			sectionId
		} = this.state;

		if (step === '1') {
			if (
				offSDate !== '' &&
				offEDate !== '' &&
				new Date(validDuration).getTime() > new Date().getTime()
			) {
				if (new Date(offSDate).getTime() >= new Date(offEDate).getTime()) {
					let newErrors = { ...errors };
					newErrors.step1 = true;
					this.setState({
						errors: newErrors
					});
					return true;
				} else {
					let newErrors = { ...errors };
					newErrors.step1 = false;
					this.setState({
						errors: newErrors
					});
					return false;
				}
			} else {
				let newErrors = { ...errors };
				newErrors.step1 = true;
				this.setState({
					errors: newErrors
				});
				return true;
			}
		} else if (step === '3') {
			if (
				BsTime === '' ||
				BeTime === '' ||
				new Date(BsTime).getTime() >= new Date(BeTime).getTime() ||
				breakNo === ''
			) {
				let newErrors = { ...errors };
				newErrors.step3 = true;
				this.setState({
					errors: newErrors
				});
				return true;
			} else {
				let newErrors = { ...errors };
				newErrors.step3 = false;
				this.setState({
					errors: newErrors
				});

				return false;
			}
		} else if (step === '4') {
			if (!workingDays.some(e => e.off === false)) {
				let newErrors = { ...errors };
				newErrors.step4 = true;
				this.setState({
					errors: newErrors
				});
				return true;
			} else {
				let newErrors = { ...errors };
				newErrors.step4 = false;
				this.setState({
					errors: newErrors
				});
				return false;
			}
		} else if (step === '5') {
			if (gradeId === '' || sectionId === '' || classId === '') {
				let newErrors = { ...errors };
				newErrors.step5 = true;
				this.setState({
					errors: newErrors
				});
				return true;
			} else {
				let newErrors = { ...errors };
				newErrors.step5 = false;
				this.setState({
					errors: newErrors
				});
				return false;
			}
		}
	};
	onChangeDate = (date, state) => {
		this.setState({ [state]: date });
	};

	changeAnimated = state => {
		this.setState({
			animate: state
		});
	};

	componentDidMount() {
		this.stepper = new Stepper(document.querySelector('#stepper1'), {
			linear: false,
			animation: true
		});
		this.props.sharedAction
			.getAllData(
				'/api/ClassesSections',
				this.props.actions.setAllClassSectonGrade,
				this.props.actions.updateSectionGradeLoader
			)
			.then(_success => {
				this.setState({
					isLoading: false
				});
			})
			.catch(error => {
				console.log(error);
			});
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.classSectionData !== nextProps.getClassSectionGrade.Data) {
			return {
				classSectionData: { ...nextProps.getClassSectionGrade.Data }
			};
		}
		return null;
	}
	onNextClick = () => {
		this.stepper.next();
	};
	// onChange = oFSD => this.setState({ oFSD })

	onCheckChange = e => {
		const { workingDays } = this.state;
		let cloneDays = [...workingDays];
		console.log(e.target.checked);
		if (e.target.value === 'Sun' && e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Sun'
			);
			cloneDays[getIndex].off = false;
		} else if (e.target.value === 'Sun' && !e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Sun'
			);
			cloneDays[getIndex].off = true;
		} else if (e.target.value === 'Sat' && e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Sat'
			);
			cloneDays[getIndex].off = false;
		} else if (e.target.value === 'Sat' && !e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Sat'
			);
			cloneDays[getIndex].off = true;
		} else if (e.target.value === 'Mon' && e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Mon'
			);
			cloneDays[getIndex].off = false;
		} else if (e.target.value === 'Mon' && !e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Mon'
			);
			cloneDays[getIndex].off = true;
		} else if (e.target.value === 'Tue' && e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Tue'
			);
			cloneDays[getIndex].off = false;
		} else if (e.target.value === 'Tue' && !e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Tue'
			);
			cloneDays[getIndex].off = true;
		} else if (e.target.value === 'Wed' && e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Wed'
			);
			cloneDays[getIndex].off = false;
		} else if (e.target.value === 'Wed' && !e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Wed'
			);
			cloneDays[getIndex].off = true;
		} else if (e.target.value === 'Thu' && e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Thu'
			);
			cloneDays[getIndex].off = false;
		} else if (e.target.value === 'Thu' && !e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Thu'
			);
			cloneDays[getIndex].off = true;
		} else if (e.target.value === 'Fri' && e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Fri'
			);
			cloneDays[getIndex].off = false;
		} else if (e.target.value === 'Fri' && !e.target.checked) {
			let getIndex = cloneDays.findIndex(
				item => item.day.substring(0, 3) === 'Fri'
			);
			cloneDays[getIndex].off = true;
		}
		console.log(cloneDays);
		this.setState({
			workingDays: cloneDays
		});
	};
	onChange = (value, name) => {
		this.setState({
			[name]: new Date(value && value._d).setSeconds(0)
		});
	};
	onRMChange = e => {
		const {
			getRemainingTime,
			noOfPeriods,
			periodArr,
			breakRemain
		} = this.state;
		switch (e.target.value) {
			case 'Yes': {
				this.setState({
					readyToDone: true
				});
				break;
			}
			case 'No':
				this.setState({
					readyToDone: false
				});
				break;
			default:
				this.setState({
					readyToDone: null
				});
				break;
		}
	};
	makeChangesInDays = (days, changeArr, ...args) => {
		switch (days) {
			case 'Mon to Sat':
				let getCloneArray = [...changeArr];
				let getNonChangeIndex = getCloneArray.findIndex(
					item => item.day.substring(0, 3) === args[0]
				);
				getCloneArray.map((_item, index) => {
					if (index !== getNonChangeIndex) getCloneArray[index].off = false;

					return '';
				});
				console.log(getCloneArray);
				this.setState({
					workingDays: getCloneArray
				});
				break;
			case 'Mon to Fri':
				const getCloneSatSunArray = [...changeArr];
				let getNonChangeSatIndex = getCloneSatSunArray.findIndex(
					item => item.day.substring(0, 3) === args[0]
				);
				let getNonChangeSunIndex = getCloneSatSunArray.findIndex(
					item => item.day.substring(0, 3) === args[1]
				);

				getCloneSatSunArray.map((_item, index) => {
					if (index !== getNonChangeSatIndex && index !== getNonChangeSunIndex)
						getCloneSatSunArray[index].off = false;
					return '';
				});
				console.log(getCloneSatSunArray);

				this.setState({
					workingDays: getCloneSatSunArray
				});

				break;

			default:
				break;
		}
	};
	onChangePeriodDistribution = e => {
		switch (e.target.value) {
			case 'Yes':
				break;
			case 'No':
				break;

			default:
				break;
		}
	};
	onChangeClassName = e => {
		console.log(e.target.value);
		const { gradeSection } = this.state.classSectionData;
		let getGradeData = gradeSection.filter(
			(item, index) => item.className === e.target.value
		);
		console.log(getGradeData);
		this.setState({
			grade: getGradeData,
			gradeName: getGradeData[0] && getGradeData[0].gradeName,
			className: e.target.value,
			gradeId: getGradeData[0] && getGradeData[0].gradeId,
			classId: getGradeData[0] && getGradeData[0].id,
			sectionId: ''
		});
	};
	onRadioChange = e => {
		const { workingDays } = this.state;
		let workingDay = [
			{ day: 'Monday', off: true },
			{ day: 'Tuesday', off: true },
			{ day: 'Wednesday', off: true },
			{ day: 'Thursday', off: true },
			{ day: 'Friday', off: true },
			{ day: 'Saturday', off: true },
			{ day: 'Sunday', off: true }
		];
		if (e.target.value === 'Others') {
			this.setState({
				workingDays: workingDay,
				renderEachDays: true
			});
		} else {
			this.setState({
				renderEachDays: false
			});
		}
		if (e.target.value === 'Mon to Sat') {
			this.makeChangesInDays('Mon to Sat', workingDays, 'Sun');
		} else if (e.target.value === 'Mon to Fri') {
			this.makeChangesInDays('Mon to Fri', workingDays, 'Sun', 'Sat');
		}
	};
	onTextChange = e => {
		const { gradeSection } = this.state.classSectionData;
		if (e.target.name === 'sectionId') {
			try {
				let getSectionName = gradeSection.filter(
					item => item.sectionId === parseInt(e.target.value)
				)[0].section;
				this.setState({
					[e.target.name]: e.target.value,
					section: getSectionName
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			this.setState({
				[e.target.name]: e.target.value
			});
		}
	};
	millisToMinutesAndSeconds = millis => {
		var minutes = Math.floor(millis / 60000);
		var seconds = ((millis % 60000) / 1000).toFixed(0);
		return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
	};
	returnPureNum = getNum => {
		if (isNaN(getNum)) {
			return 0;
		} else if (getNum < 0) {
			return 0;
		} else {
			return getNum;
		}
	};
	onChangePP = (value, name) => {
		const { offEDate, offSDate, breakRemain } = this.state;
		let getOffDiff =
			new Date(offEDate).getTime() - new Date(offSDate).getTime();
		this.setState(
			{
				[name]: value && value._d
			},
			() => {
				if (this.state.PerPEDate !== '' && this.state.PerPSDate !== '') {
					let getPreDiff =
						new Date(this.state.PerPEDate).getTime() -
						new Date(this.state.PerPSDate).getTime();

					let getNoOfPeriodNoParse = getOffDiff / getPreDiff;
					let getNoOfPeriod = parseInt(getOffDiff / getPreDiff);
					let getDiffOfPeriodNoparse = getNoOfPeriodNoParse - getNoOfPeriod;

					let getTotalPeriodTime = getNoOfPeriod * getPreDiff;
					let getRemainingtime =
						getOffDiff - getTotalPeriodTime + getDiffOfPeriodNoparse;

					let setRemainTime = this.millisToMinutesAndSeconds(getRemainingtime);
					console.log('getDivident: ', getOffDiff / getPreDiff);
					console.log(getNoOfPeriod);
					console.log(this.msToTime(getOffDiff));

					console.log(breakRemain);

					this.setState({
						noOfPeriods: this.returnPureNum(getNoOfPeriod),
						getRemainingTime: getRemainingtime,
						getPreDiff,
						getTotalPeriodTime,
						getOffDiff
					});
				}
			}
		);
	};
	msToTime = duration => {
		var milliseconds = parseInt((duration % 1000) / 100),
			seconds = Math.floor((duration / 1000) % 60),
			minutes = Math.floor((duration / (1000 * 60)) % 60),
			hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

		hours = hours < 10 ? '0' + hours : hours;
		minutes = minutes < 10 ? '0' + minutes : minutes;
		seconds = seconds < 10 ? '0' + seconds : seconds;

		return hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
	};
	splitingData = item => {
		let getSplittingObj = item.split(' ');
		return getSplittingObj[1];
	};
	onSubmit = () => {
		const {
			classId,
			sectionId,
			gradeId,
			validDuration,
			newPeriodStruct,
			offSDate,
			offEDate,
			errors,
			metaExist
		} = this.state;
		console.log(newPeriodStruct);

		const Data = {
			timetables: {
				gradeId,
				sectionId,
				classId,
				startTime: new Date(offSDate).toLocaleTimeString(),
				endTime: new Date(offEDate).toLocaleTimeString(),
				validDuration,
				date: new Date().toLocaleDateString(),
				time: new Date().toLocaleTimeString(),
				accountId: this.props.name,
				accountType: this.props.role
			},
			periods: [
				...newPeriodStruct['Monday'],
				...newPeriodStruct['Tuesday'],
				...newPeriodStruct['Wednesday'],
				...newPeriodStruct['Thursday'],
				...newPeriodStruct['Friday'],
				...newPeriodStruct['Saturday'],
				...newPeriodStruct['Sunday']
			]
		};
		console.log(Data);
		// e.preventDefault();
		this.props.sharedAction
			.simpleAddRequest('/api/periods', Data)
			.then(success => {
				console.log(success);
				// $('#timeTableModal').modal('hide');
				if (metaExist) {
					$('#timeTableModal').modal('hide');
				} else {
					$('#newtimeTableModal').modal('hide');
				}

				this.props.history.push('/dashboard/viewTimetable');
			})
			.catch(error => {
				if (error.response.status === 400) {
					if (
						error.response &&
						error.response.data &&
						error.response.data &&
						error.response.data.Message
					) {
						let newErrors = { ...errors };
						newErrors.responseError = error.response.data.Message;
						this.setState({
							errors: newErrors
						});
					}
				}
				console.log(error);
			});
	};

	stepTwo = () => {
		const { noOfPeriods, getPreDiff, errors } = this.state;
		const myPeroidArr = [];
		if (!(getPreDiff <= 0)) {
			if (noOfPeriods !== '' && getPreDiff !== '') {
				for (let i = 1; i <= parseInt(noOfPeriods); i++) {
					myPeroidArr.push({
						periodDuration: getPreDiff,
						type: 'Period ',
						name: alphaNumericReturn(i),
						date: new Date().toLocaleDateString(),
						time: new Date().toLocaleTimeString(),
						accountId: this.props.name,
						accountType: this.props.role
					});
				}
				console.log(myPeroidArr);
				let newError = { ...errors };
				newError.step2 = false;
				this.setState({
					errors: newError
				});
				this.setState({
					periodArr: myPeroidArr,
					errors: newError
				});

				this.stepper.next();
			}
		} else {
			let newError = { ...errors };
			newError.step2 = true;
			this.setState({
				errors: newError
			});
		}
	};

	onDone = () => {
		const {
			breakRemainingTime,
			getRemainingTime,
			periodArr,
			workingDays,
			readyToDone,
			selectedDisPeriod
		} = this.state;

		if (
			getRemainingTime !== '' &&
			getRemainingTime !== 0 &&
			readyToDone === null
		) {
			this.setState({
				renderRemainTimeflag: true
			});
		} else if (
			getRemainingTime !== '' &&
			getRemainingTime !== 0 &&
			readyToDone
		) {
			let getupdatedArray = utilsTTActions.getModifiedPeriodArray(
				periodArr.length - 1,
				periodArr,
				getRemainingTime,
				true
			);
			let getAllPeriods = utilsTTActions.getAllPeriodsDataWithDays(
				workingDays,
				getupdatedArray
			);
			console.log(getAllPeriods);
			console.log(getupdatedArray);
			let getNewPeriodStructure = workingDays.map((item, index) => {
				return {
					...utilsTTActions.changeDataStructure(getAllPeriods, item.day)
				};
			});

			console.log(getNewPeriodStructure[6]);
			let getNewStructure = { ...getNewPeriodStructure[6] };
			console.log(getNewStructure);

			this.setState({
				periodArr: getupdatedArray,
				finalArray: getAllPeriods,
				newPeriodStruct: getNewStructure
			});
			$('#exampleModalLong').modal('hide');
			Promise.all([
				this.props.sharedAction.gAllData(
					`/api/getSubjectByClass?classId=${this.state.classId}`,
					this.props.ttActions.getTeachAndStudent
				),
				this.props.sharedAction.gAllData(
					`/api/ConsolidatedDropdown?classId=${this.state.classId}&sectionId=${this.state.sectionId}&gradeId=${this.state.gradeId}`,
					this.props.ttActions.getTeachAndStudent
				)
			])

				.then(success => {
					console.log(success);
					this.setState({
						teachAndStaff: success[0],
						allStaffData: success[1]
					});
					$('#newtimeTableModal').modal();
				})
				.catch(error => {
					console.log(error);
				});

			// console.log(utilsTTActions.getAllPeriodsDataWithDays(workingDays, periodArr))
			// alert('ready to done is true');
		} else if (
			getRemainingTime !== '' &&
			getRemainingTime !== 0 &&
			!readyToDone
		) {
			// const { selectedDisPeriod , periodArr } = this.state;
			let getElementArray = periodArr.filter((item, index) =>
				selectedDisPeriod.some(item2 => item2.name === item.name)
			);
			console.log('previous', getElementArray);

			let getupdatedArray = utilsTTActions.getModifiedPeriodArray(
				selectedDisPeriod.length,
				periodArr,
				getRemainingTime,
				false,
				getElementArray
			);
			console.log('updated:', getupdatedArray);
			let getCompeleteResult = utilsTTActions.getModifiedPeriodArray(
				selectedDisPeriod.length,
				periodArr,
				getRemainingTime,
				null,
				getupdatedArray,
				true,
				selectedDisPeriod
			);
			console.log(getCompeleteResult);
			let getAllPeriods = utilsTTActions.getAllPeriodsDataWithDays(
				workingDays,
				getCompeleteResult
			);
			console.log(getAllPeriods);
			let getNewPeriodStructure = workingDays.map((item, index) => {
				return {
					...utilsTTActions.changeDataStructure(getAllPeriods, item.day)
				};
			});

			console.log(getNewPeriodStructure[6]);
			let getNewStructure = { ...getNewPeriodStructure[6] };
			console.log(getNewStructure);

			this.setState({
				periodArr: getCompeleteResult,
				finalArray: getAllPeriods,
				newPeriodStruct: getNewStructure
			});
			$('#exampleModalLong').modal('hide');
			this.props.sharedAction
				.gAllData(
					`/api/AssignSubjectsToTeacher?classId=${this.state.classId}&sectionId=${this.state.sectionId}&gradeId=${this.state.gradeId}`,
					this.props.ttActions.getTeachAndStudent
				)
				.then(success => {
					this.setState({
						teachAndStaff: success
					});
					$('#newtimeTableModal').modal();
				})
				.catch(error => {
					console.log(error);
				}); // alert('ready to done is false');
		} else {
			let getAllPeriods = utilsTTActions.getAllPeriodsDataWithDays(
				workingDays,
				periodArr
			);
			let getNewPeriodStructure = workingDays.map((item, index) => {
				return {
					...utilsTTActions.changeDataStructure(getAllPeriods, item.day)
				};
			});

			console.log(getNewPeriodStructure[6]);
			let getNewStructure = { ...getNewPeriodStructure[6] };
			console.log(getNewStructure);

			this.setState({
				finalArray: getAllPeriods,
				newPeriodStruct: getNewStructure
			});
			console.log('done appear time-table view');
			$('#exampleModalLong').modal('hide');
			this.props.sharedAction
				.gAllData(
					`/api/AssignSubjectsToTeacher?classId=${this.state.classId}&sectionId=${this.state.sectionId}&gradeId=${this.state.gradeId}`,
					this.props.ttActions.getTeachAndStudent
				)
				.then(success => {
					this.setState({
						teachAndStaff: success
					});

					$('#newtimeTableModal').modal();
				})
				.catch(error => {
					console.log(error);
				});
		}
	};

	onChangeNewPeriodData = (e, index, day) => {
		const { allStaffData } = this.state;

		const NewStructurePeriod = this.state.newPeriodStruct;
		NewStructurePeriod[day][index][e.target.name] = e.target.value;

		console.log(NewStructurePeriod);

		if (e.target.name === 'subjectId') {
			let getStaffData = allStaffData.dropdown.filter(
				item => item.subjectId == parseInt(e.target.value)
			);
			console.log(getStaffData);
			try {
				let assignStaff = getStaffData[0].assignStaff;

				if (assignStaff.length > 0) {
					NewStructurePeriod[day][index]['staffDropDown'] = [...assignStaff];
					NewStructurePeriod[day][index]['staffId'] = '-';
				}
			} catch (error) {
				console.log(error);
			}
		}

		console.log(NewStructurePeriod);

		this.setState({
			newPeriodStruct: NewStructurePeriod
		});
		if (e.target.name === 'subjectId' && this.state.metaExist) {
			let $select = $(`#${'staffId'}_${day}_${index}_pre`);

			$select.val('Choose Staff');
		} else if (e.target.name === 'subjectId' && !this.state.metaExist) {
			let $select = $(`#${'staffId'}_${day}_${index}_post`);

			$select.val('Choose Staff');
		}
	};
	onLastStepNext = () => {
		const {
			breakRemain,
			getRemainingTime,
			periodArr,
			workingDays,
			readyToDone,
			selectedDisPeriod
		} = this.state;
		if (!this.Validation('5')) {
			if (
				getRemainingTime !== '' &&
				getRemainingTime !== 0 &&
				readyToDone === null &&
				breakRemain !== 0
			) {
				this.setState({
					renderRemainTimeflag: true
				});
			} else if (
				getRemainingTime !== '' &&
				getRemainingTime !== 0 &&
				readyToDone === null &&
				breakRemain === 0
			) {
				this.setState({
					renderRemainTimeflag: true
				});
			} else if (breakRemain !== 0 && getRemainingTime === 0) {
				this.setState({
					renderRemainTimeflag: true
				});
			} else if (
				getRemainingTime !== '' &&
				getRemainingTime !== 0 &&
				readyToDone
			) {
				$('#exampleModalLong').modal();

				// console.log(utilsTTActions.getAllPeriodsDataWithDays(workingDays, periodArr))
				// alert('ready to done is true');
			} else if (!readyToDone && selectedDisPeriod.length < 1) {
				this.setState({
					renderRemainTimeflag: true
				});
			} else if (
				getRemainingTime !== '' &&
				getRemainingTime !== 0 &&
				!readyToDone
			) {
				// const { selectedDisPeriod , periodArr } = this.state;
				// let getElementArray = periodArr.filter((item,index)=> selectedDisPeriod.some((item2=> item2.name === item.name)) );
				// console.log('previous',getElementArray);

				// let getupdatedArray = utilsTTActions.getModifiedPeriodArray(selectedDisPeriod.length,periodArr,getRemainingTime,false,getElementArray)
				// console.log('updated:',getupdatedArray) ;
				// let getCompeleteResult = utilsTTActions.getModifiedPeriodArray(selectedDisPeriod.length,periodArr,getRemainingTime,null,getupdatedArray,true,selectedDisPeriod)
				//
				// console.log(getCompeleteResult);

				$('#exampleModalLong').modal();
				// alert('ready to done is false');
			} else {
				console.log('done appear time-table view');
				$('#exampleModalLong').modal();
			}
		}
	};
	getBreakData = data => {
		this.setState({
			multipleBreak: data
		});
	};
	onNextStep3 = () => {
		const {
			BsTime,
			BeTime,
			breakNo,
			getOffDiff,
			periodArr,
			noOfPeriods,
			getTotalPeriodTime,
			getPreDiff,
			multipleBreak,
			breakRemain,
			getRemainingTime
		} = this.state;
		let getPeriodArr = [...periodArr];

		if (!utilsTTActions.IsValidMinMaxPeriods(multipleBreak)) {
			console.log(multipleBreak);

			let multipleCloneBreak = [...multipleBreak];
			let getBreaksDiff = [];
			multipleCloneBreak.map(item =>
				getBreaksDiff.push(
					Math.abs(
						new Date(item.endTime).getTime() -
							new Date(item.startTime).getTime()
					)
				)
			);
			console.log(getBreaksDiff);
			// let getBreakDiff =
			// 	new Date(BeTime).getTime() - new Date(BsTime).getTime();
			if (!utilsTTActions.hasZero(getBreaksDiff)) {
				console.log(getOffDiff);
				let getIndex = [];

				multipleCloneBreak.map(itemUpper => {
					getIndex.push(
						getPeriodArr.findIndex(
							item => item.name.charAt(0) === itemUpper.periodNum.charAt(0)
						)
					);
				});

				let realPeriodArray = utilsTTActions.throwUpdatedArr(
					getPeriodArr,
					getIndex,
					getBreaksDiff
				);
				console.log(realPeriodArray);
				let getRemTime = utilsTTActions.getUpdatedRemainingTime(
					getOffDiff,
					utilsTTActions.getPPTimingFromUpdateArr(realPeriodArray, noOfPeriods)
						.perPUpdateTime,
					noOfPeriods,
					utilsTTActions.getPPTimingFromUpdateArr(realPeriodArray, noOfPeriods)
						.totalPeriodDurationAfterBreak
				);

				if (getRemTime >= 0) {
					this.setState({
						getRemainingTime: getRemTime,
						periodArr: realPeriodArray
					});

					this.stepper.next();
				}
			}
		}
	};
	onLastPerivous = () => {
		this.stepper.previous();
		this.setState({
			renderRemainTimeflag: false
		});
	};
	onChangePeriodSelect = e => {
		const { periodArr, selectedDisPeriod } = this.state;

		let getIndex = periodArr.findIndex(
			(item, index) => item.name === e.target.value
		);
		if (e.target.checked) {
			this.setState({
				selectedDisPeriod: selectedDisPeriod.concat({
					ind: getIndex,
					name: e.target.value
				})
			});
		} else if (
			!e.target.checked &&
			utilsTTActions.modificationOfIncludes(
				selectedDisPeriod,
				'name',
				e.target.value
			)
		) {
			let getFilterList = selectedDisPeriod.filter(
				(item, index) => item.name !== e.target.value
			);

			this.setState({
				selectedDisPeriod: getFilterList
			});

			console.log(getFilterList);
		}
	};
	onClickStep1Next = () => {
		if (!this.Validation('1')) {
			this.stepper.next();
		}
	};

	onClickStep4 = () => {
		// const { breakRemain , getRemainingTime } = this.state;
		if (!this.Validation('4')) {
			// if(breakRemain !== 0 && getRemainingTime === 0 ){
			//
			//   this.setState({
			//     getRemainingTime: getRemainingTime + breakRemain,
			//   })
			// }else if(breakRemain !== 0 &&  getRemainingTime !==0 ) {
			//
			//   this.setState({
			//     getRemainingTime: getRemainingTime + breakRemain,
			//   })
			// }

			this.stepper.next();
		}
	};
	onClickStep2Prev = () => {
		this.stepper.previous();
		this.changeAnimated(false);
	};
	componentWillUnmount() {
		this.setState({
			discard: false
		});
	}

	preCheckApiNextFirst = () => {
		const { classId, sectionId, gradeId } = this.state;

		if (!this.Validation('5')) {
			try {
				this.props.sharedAction
					.getDataWithoutDispatch(
						`/api/checkDataTimetable?classId=${classId}&sectionId=${sectionId}&gradeId=${gradeId}`
					)
					.then(success => {
						this.setState({
							metaExist: true
						});
						let offSDate = '1970-01-01'.concat(
							` ${success.startTime.split(' ')[0]}`
						);
						let offEDate = '1970-01-01'.concat(
							` ${success.endTime.split(' ')[0]}`
						);

						this.setState({
							offSDate,
							offEDate
						});

						console.log();
						const workingDays = utilsTTActions.modifyDays(
							[
								{ day: 'Monday', off: true },
								{ day: 'Tuesday', off: true },
								{ day: 'Wednesday', off: true },
								{ day: 'Thursday', off: true },
								{ day: 'Friday', off: true },
								{ day: 'Saturday', off: true },
								{ day: 'Sunday', off: true }
							],
							success.activeDays
						);

						const getAllPeriods = utilsTTActions.getAllPeriodsDataWithDays(
							workingDays,
							success.horizontalData
						);
						const updatedPeriod = getAllPeriods.map(item => {
							return {
								...item,
								date: new Date().toLocaleDateString(),
								time: new Date().toLocaleTimeString(),
								accountId: this.props.name,
								accountType: this.props.role
							};
						});

						let getNewPeriodStructure = workingDays.map((item, index) => {
							return {
								...utilsTTActions.changeDataStructure(updatedPeriod, item.day)
							};
						});

						console.log(getNewPeriodStructure[6]);
						let getNewStructure = { ...getNewPeriodStructure[6] };
						console.log(getNewStructure);
						let horizonData = success.horizontalData.map(item => {
							return { ...item, periodDuration: parseInt(item.periodDuration) };
						});

						this.setState({
							workingDays,
							periodArr: horizonData,
							finalArray: updatedPeriod,
							newPeriodStruct: getNewStructure
						});
						// $('#exampleModalLong').modal('hide');
						Promise.all([
							this.props.sharedAction.gAllData(
								`/api/getSubjectByClass?classId=${this.state.classId}`,
								this.props.ttActions.getTeachAndStudent
							),
							this.props.sharedAction.gAllData(
								`/api/ConsolidatedDropdown?classId=${this.state.classId}&sectionId=${this.state.sectionId}&gradeId=${this.state.gradeId}`,
								this.props.ttActions.getTeachAndStudent
							)
						])

							.then(success => {
								this.setState({
									teachAndStaff: success[0],
									allStaffData: success[1]
								});

								$('#timeTableModal').modal();
							})
							.catch(error => {
								console.log(error);
							});
					})
					.catch(e => {
						console.log(e);

						if (
							typeof e.response !== 'undefined' &&
							e.response.data &&
							e.response.data.Message === 'TimeTable Already Exists'
						) {
							this.setState({
								msg: e.response.data.Message,
								snackColor: '#000'
							});
							this.props.snackbar();
						} else {
							this.stepper.next();
						}
					});
			} catch (e) {
				console.log(e);
			}
		}
	};
	render() {
		const { Stepes, discard, metaExist } = this.state;
		console.log('main render:', this.state);

		if (discard && metaExist) {
			$('#timeTableModal').modal('hide');
			this.props.history.push('/dashboard/viewTimeTable');
		} else if (discard && !metaExist) {
			$('#newtimeTableModal').modal('hide');
			this.props.history.push('/dashboard/viewTimeTable');
		}
		return (
			<div className={'table-responsive'}>
				{/* <Prompt when={this.state.valid !== true}
          message='Leaving this form will lose your data'
      /> */}
				<div style={{ textAlign: 'center' }}>
					<SnackBar backColor={this.state.snackColor} msg={this.state.msg} />
					<p style={{ fontSize: 50 }}>
						<span
							style={{
								background: '#01AC8A',
								color: '#fff',
								letterSpacing: 20,
								marginBottom: 50
							}}
							class="badge badge-pill"
						>
							<Fade delay={500} right big cascade>
								{/* TIME TABLE */}
								<img
									style={{ width: 'fit-content', height: 'auto' }}
									src={timeImag}
									alt="#timetable"
								/>
							</Fade>
						</span>
					</p>
				</div>
				<div>
					<div id="stepper1" class="bs-stepper vertical">
						<div class="bs-stepper-header">
							<div class="step" data-target="#test-l-1">
								<button class="step-trigger">
									<span
										style={{ background: '#01AC8A' }}
										class="bs-stepper-circle"
									>
										1
									</span>
									<span class="bs-stepper-label">Class & Section</span>
								</button>
							</div>
							<div
								style={{ textAlign: 'center', fontSize: 100, color: '#6C757D' }}
							>
								|
							</div>
							<div class="step" data-target="#test-l-2">
								<button class="step-trigger">
									<span
										style={{ background: '#01AC8A' }}
										class="bs-stepper-circle"
									>
										2
									</span>
									<span class="bs-stepper-label">Official Timing</span>
								</button>
							</div>
							<div
								style={{ textAlign: 'center', fontSize: 100, color: '#6C757D' }}
							>
								|
							</div>

							<div class="step" data-target="#test-l-3">
								<button class="step-trigger">
									<span
										style={{ background: '#01AC8A' }}
										class="bs-stepper-circle"
									>
										3
									</span>
									<span class="bs-stepper-label">Period Timing</span>
								</button>
							</div>
							<div
								style={{ textAlign: 'center', fontSize: 100, color: '#6C757D' }}
							>
								|
							</div>
							<div class="step" data-target="#test-l-4">
								<button class="step-trigger">
									<span
										style={{ background: '#01AC8A' }}
										class="bs-stepper-circle"
									>
										4
									</span>
									<span class="bs-stepper-label">Break</span>
								</button>
							</div>
							<div
								style={{ textAlign: 'center', fontSize: 100, color: '#6C757D' }}
							>
								|
							</div>
							<div class="step" data-target="#test-l-5">
								<button class="step-trigger">
									<span
										style={{ background: '#01AC8A' }}
										class="bs-stepper-circle"
									>
										5
									</span>
									<span class="bs-stepper-label">Effective Days</span>
								</button>
							</div>
						</div>
						<div class="bs-stepper-content">
							<form action="javascript:void(0)">
								<div
									style={{ display: 'flex', flexDirection: 'column' }}
									id="test-l-1"
									class="content"
									style={{ height: 260 }}
								>
									<Step1_Version1
										stateData={this.state}
										errors={this.state.errors}
										onChangeClassName={this.onChangeClassName}
										onTextChange={this.onTextChange}
										onRadioChange={this.onRadioChange}
										millisToMinutesAndSeconds={this.millisToMinutesAndSeconds}
										changeRenderTimeFlag={this.changeRenderTimeFlag}
										onRMChange={this.onRMChange}
										onChangePeriodSelect={this.onChangePeriodSelect}
										msToTime={this.msToTime}
										onDone={this.onDone}
										timeTable={this.timeTable}
										onChangeNewPeriodData={this.onChangeNewPeriodData}
										onSubmit={this.onSubmit}
										onDiscard={this.onDiscard}
									/>

									<button
										className="ttBtnStyling"
										onClick={this.preCheckApiNextFirst}
									>
										Next
									</button>
								</div>
								<div
									style={{ display: 'flex', flexDirection: 'column' }}
									id="test-l-2"
									style={{ height: 250 }}
									class="content"
								>
									<div class="form-group">
										<Step1
											stateData={this.state}
											onChange={this.onChange}
											stepperFun={this.onNextClick}
											onChangeDate={this.onChangeDate}
										/>
									</div>
									<button
										className="ttBtnStyling"
										onClick={this.onClickStep1Next}
									>
										Next
									</button>
								</div>
								<div id="test-l-3" class="content" style={{ height: 122 }}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center'
											// position: 'relative',
											// top: 60
										}}
									>
										{/* <label for="name" class="">
											<i
												className="fas fa-house animated fadeIn"
												style={{ marginRight: 4 }}
											/>

											<p style={{ fontSize: 30 }}>
												<span class="badge badge-pill badge-dark">
													<p style={{ margin: 0, color: '#fff' }}>Per Period Timing</p>
												</span>
											</p>
										</label> */}
									</div>
									<div class="form-group">
										<Step2
											errors={this.state.errors}
											stateData={this.state}
											onChange={this.onChangePP}
											onTextChange={this.onTextChange}
											stepperFun={this.onNextClick}
											changeAnimated={this.changeAnimated}
										/>
									</div>
									<button className="ttBtnStyling" onClick={this.stepTwo}>
										Next
									</button>
									{/* <div class="form-group">
										<Step3
											onTextChange={this.onTextChange}
											stateData={this.state}
											onChange={this.onChange}
											getBreakData={this.getBreakData}
										/>
									</div>
									<div
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											marginLeft: 5
										}}
									>
										<button
											style={{
												background: '#01AC8A',
												color: '#fff',
												borderColor: '#01AC8A',
												width: '15%',
												marginRight: 8
											}}
											class="btn btn-primary"
											onClick={this.onNextStep3}
										>
											Next
										</button>
										{/* <button
                      style={{
                        background: '#01AC8A',
                        color: '#fff',
                        borderColor: '#01AC8A'
                      }}
                      class="btn btn-primary"
                      onClick={() => this.stepper.previous()}
                    >
                      Previous
                    </button> 
									</div> */}
								</div>
								<div id="test-l-4" style={{ height: 400 }} class="content">
									<label
										style={{
											position: 'relative',
											top: 40,
											margin: 0,
											display: 'flex',
											justifyContent: 'center'
										}}
										for="name"
										class=""
									>
										<i
											className="fas fa-house animated fadeIn"
											style={{ marginRight: 4 }}
										/>

										<p style={{ fontSize: 30 }}>
											<span class="badge badge-pill badge-dark">
												<p style={{ margin: 0, color: '#fff' }}>Breaks</p>
											</span>
										</p>
									</label>
									<div class="form-group">
										<Step3
											onTextChange={this.onTextChange}
											stateData={this.state}
											onChange={this.onChange}
											getBreakData={this.getBreakData}
										/>
									</div>
									<div
										style={{
											display: 'flex',
											justifyContent: 'flex-start',
											marginLeft: 5
										}}
									>
										<button class="ttBtnStyling" onClick={this.onNextStep3}>
											Next
										</button>
										{/* <div style={{ height: '50vh' }} class="form-group">
										<Step4
											onRadioChange={this.onRadioChange}
											stateData={this.state}
											onChange={this.onChange}
											onCheckChange={this.onCheckChange}
											staticDays={utilsTTActions.staticDaysArrays}
										/>
									</div>
									<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
										<button
											style={{
												background: '#01AC8A',
												color: '#fff',
												width: '15%',
												borderColor: '#01AC8A',
												marginRight: 8,
												position: 'relative',
												bottom: 50
											}}
											class="btn btn-primary"
											onClick={() => this.onClickStep4()}
										>
											Next
										</button> */}
										{/* <button
                      style={{
                        background: '#01AC8A',
                        color: '#fff',
                        borderColor: '#01AC8A'
                      }}
                      class="btn btn-primary"
                      onClick={() => this.stepper.previous()}
                    >
                      Previous
                    </button> */}
									</div>
								</div>
								<div id="test-l-5" class="content text-center">
									{/* <div style={{height:185}} class="form-group">
										<Step4
											onRadioChange={this.onRadioChange}
											stateData={this.state}
											onChange={this.onChange}
											onCheckChange={this.onCheckChange}
											staticDays={utilsTTActions.staticDaysArrays}
										/>
									</div> */}
									{/* <div style={{ display: 'flex', justifyContent: 'flex-end' , marginTop:60 }}>
										<button
											
											class="ttBtnStyling"
											onClick={() => this.onClickStep4()}
										>
											Next
										</button>{' '}

										<button
										
											class="ttBtnStyling"
											onClick={() => this.stepper.previous()}
										>
											Previous
										</button>
										</div> */}
									<Step5
										onRadioChange={this.onRadioChange}
										stateData={this.state}
										onChange={this.onChange}
										onCheckChange={this.onCheckChange}
										staticDays={utilsTTActions.staticDaysArrays}
										stateData={this.state}
										errors={this.state.errors}
										onChangeClassName={this.onChangeClassName}
										onTextChange={this.onTextChange}
										onRadioChange={this.onRadioChange}
										millisToMinutesAndSeconds={this.millisToMinutesAndSeconds}
										changeRenderTimeFlag={this.changeRenderTimeFlag}
										onRMChange={this.onRMChange}
										onChangePeriodSelect={this.onChangePeriodSelect}
										msToTime={this.msToTime}
										onDone={this.onDone}
										timeTable={this.timeTable}
										onChangeNewPeriodData={this.onChangeNewPeriodData}
										onSubmit={this.onSubmit}
										onDiscard={this.onDiscard}
									/>
									<div
										style={{
											position: 'relative',
											bottom: 80,
											display: 'flex',
											justifyContent: 'space-between'
										}}
									>
										<button
											style={{
												background: '#01AC8A',
												color: '#fff',
												borderColor: '#01AC8A',
												marginLeft: 8,
												width: '15%'
											}}
											data-backdrop="false"
											class="btn btn-primary"
											onClick={this.onLastStepNext}
										>
											Done
										</button>
										<button
											style={{
												background: '#01AC8A',
												color: '#fff',
												borderColor: '#01AC8A',
												marginRight: 10,
												width: '15%'
											}}
											class="btn btn-primary"
											onClick={this.onLastPerivous}
										>
											Previous
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		sharedAction: bindActionCreators(sharedActions, dispatch),
		ttActions: bindActionCreators(timeActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		getTeachAndStaff: state.setTimeTableReducer,
		getClassSectionGrade: state.setClassSectionGradeReducer,
		admissionState: state.setAdmissionReducer,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TimeTable);
