import React, { Component } from 'react';

const RenderDynamicDrops = props => {
	const {
		itemObj,
		onHandleTextChange,
		stateData,
		rowIndex,
		updateSpecialArrObjOnChange,
		arrName
	} = props;

	let keys = Object.keys(itemObj);
	// console.log(stateData.siblingsDetails[0]['age'])
	if (keys.includes('StudentSiblingDetailId') && keys.includes('studentId')) {
		let newKeys = keys.filter(
			item => item !== 'studentId' && item !== 'StudentSiblingDetailId'
		);
		keys = [...newKeys];
	}
	if (keys.includes('PreviousSchoolDetailId') && keys.includes('studentId')) {
		let newKeys = keys.filter(
			item => item !== 'studentId' && item !== 'PreviousSchoolDetailId'
		);
		keys = [...newKeys];
	}

	return (
		<React.Fragment>
			{keys.map((item, index) =>
				item == 'classId' ? (
					<td key={index}>
						<select
							class="custom-select"
							name={`${item}`}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
						>
							<option selected={true}>Select Class..</option>
							{stateData.class &&
								stateData.class.map((item, index) => (
									<option key={index} value={`${item.id}`}>
										{item.className}
									</option>
								))}
						</select>
					</td>
				) : item == 'sectionId' ? (
					<td key={index}>
						<select
							class="custom-select"
							name={`${item}`}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
						>
							<option selected={true}>Select Section..</option>
							{stateData.section &&
								stateData.section.map((item, index) => (
									<option key={index} value={`${item.sectionId}`}>
										{item.section}
									</option>
								))}
						</select>{' '}
					</td>
				) : (
					''
				)
			)}
		</React.Fragment>
	);
};
export default RenderDynamicDrops;
