import React, { Component } from 'react';
import $ from 'jquery';
// import './viewAdmin.css';
import Axios from 'axios';
import config from '../../config/config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StaffActions from '../../Actions/StaffActions';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput';
import validator from '../../validator/registrationValidator';
import * as userAction from '../../Actions/setUserActions/userAction';
import { Link } from 'react-router-dom';
const options = ['Mixed', 'Performance', 'Fixed'];

class ViewAppraisal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			type: '',
			staffId: '',
			nextDateOfInc: '',
			basicSalary: '',
			percentageBasic: '',
			percentagePerformance: '',
			accountId: '',
			accountType: '',
			name: '',
			count: 1,
			timeDuration: '********',
			enabled: false,
			id: null,
			isLoading: true,
			disablepage: false,
			disabledPB: true,
			disabledP: true,
			currentPage: 1,
			todosPerPage: 10,
			msg: '',
			successflag: false,
			errors: {}
		};
	}

	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	isValid = () => {
		const { errors, isValid } = validator(this.state, 'checkEditAppraisalData');
		if (!isValid) {
			this.setState({
				errors
			});
		}
		return isValid;
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.adminData !== nextProps.getAllAppraisalData) {
			return {
				adminData: nextProps.getAllAppraisalData
			};
		}
		return null;
	}
	componentWillUnmount() {
		this.props.actionUser.setNotificationLink('');
		$('[data-toggle="tooltip"]').tooltip('hide');
	}
	onMouseMoveOut = () => {
		$('[data-toggle="tooltip"]').tooltip('hide');
	};

	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		console.log(this.props);

		this.props.actionUser.setNotificationLink(this.props.match.path);
		this.props.sharedActions
			.gAllData('/api/Appraisals', this.props.staffActions.getAppraisalData)
			.then(success => {
				this.setState({
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});

				console.log(err);
			});
	}
	// }
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecord(
				'/api/Appraisals/',
				itemId,
				this.props.staffActions.deleteStaffRecord
			)
			.then(success => {
				this.setState({
					msg: 'Delete Record Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {});
	};
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.t1.id === itemId);

		this.setState({
			modalData: getData,
			type: getData[0].t1.type,
			staffId: getData[0].t1.staffId,
			timeDuration: getData[0].t1.timeDuration,
			basicSalary: getData[0].t1.basicSalary,
			percentageBasic: getData[0].t1.percentageBasic,
			percentagePerformance: getData[0].t1.percentagePerformance,
			accountId: getData[0].t1.accountId,
			accountType: getData[0].t1.accountType,
			nextDateOfInc: getData[0].t1.nextDateOfInc,
			id: getData[0].t1.id
		});
	};

	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleAppraisalTypeChange = e => {
		switch (e.target.value) {
			case 'Mixed':
				this.setState({
					disabledP: false,
					disabledPB: false,
					[e.target.name]: e.target.value,
					successflag: false
				});
				break;
			case 'Performance':
				{
					let { errors } = this.state;
					let getNewError = { ...errors };
					getNewError.percentageBasic = false;
					this.setState({
						disabledP: false,
						disabledPB: true,
						[e.target.name]: e.target.value,
						errors: getNewError,
						percentageBasic: '',
						percentagePerformance: '',
						successflag: false
					});
				}
				break;
			case 'Fixed':
				{
					let { errors } = this.state;
					let getNewError = { ...errors };
					getNewError.percentagePerformance = false;
					this.setState({
						disabledPB: false,
						disabledP: true,
						[e.target.name]: e.target.value,
						errors: getNewError,
						percentagePerformance: '',
						successflag: false
					});
				}
				break;
			default:
				const { errors } = this.state;
				let getNewError = { ...errors };
				getNewError.percentageBasic = false;
				getNewError.percentagePerformance = false;
				this.setState({
					disabledP: true,
					disabledPB: true,
					[e.target.name]: e.target.value,
					percentagePerformance: '',
					percentageBasic: '',
					errors: getNewError,
					successflag: false
				});
				break;
		}
	};
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const {
			type,
			timeDuration,
			staffId,
			id,
			basicSalary,
			percentageBasic,
			percentagePerformance,
			nextDateOfInc,
			errors
		} = this.state;

		if (this.isValid()) {
			if (percentagePerformance === '') {
				let bodyParameters = {
					id,
					type,
					timeDuration,
					staffId,
					basicSalary,
					percentageBasic,
					percentagePerformance: '0',
					nextDateOfInc,
					accountId: this.props.name,
					accountType: this.props.role,
					Date: new Date().toLocaleDateString(),
					Time: new Date().toLocaleTimeString()
				};

				this.props.sharedActions
					.editRecord(
						'/api/Appraisals/',
						id,
						bodyParameters,
						this.props.staffActions.editStaffRecord
					)
					.then(success => {
						this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
						this.props.snackbar();
					})
					.catch(err => {
						console.log(err);
					});
			} else if (percentageBasic === '') {
				let bodyParameters = {
					id,
					type,
					timeDuration,
					staffId,
					basicSalary,
					percentageBasic: '0',
					percentagePerformance,
					nextDateOfInc,
					accountId: this.props.name,
					accountType: this.props.role,
					Date: new Date().toLocaleDateString(),
					Time: new Date().toLocaleTimeString()
				};

				this.props.sharedActions
					.editRecord(
						'/api/Appraisals/',
						id,
						bodyParameters,
						this.props.staffActions.editStaffRecord
					)
					.then(success => {
						this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
						this.props.snackbar();
					})
					.catch(err => {
						console.log(err);
					});
			} else {
				let bodyParameters = {
					id,
					type,
					timeDuration,
					staffId,
					basicSalary,
					percentageBasic,
					percentagePerformance,
					nextDateOfInc,
					accountId: this.props.name,
					accountType: this.props.role,
					Date: new Date().toLocaleDateString(),
					Time: new Date().toLocaleTimeString()
				};

				this.props.sharedActions
					.editRecord(
						'/api/Appraisals/',
						id,
						bodyParameters,
						this.props.staffActions.editStaffRecord
					)
					.then(success => {
						this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
						this.props.snackbar();
					})
					.catch(err => {
						console.log(err);
					});
			}
		}
	};
	setPreStateOverEditData = type => {
		switch (type) {
			case 'Mixed':
				this.setState({
					disabledP: false,
					disabledPB: false,
					successflag: false
				});
				break;
			case 'Performance':
				this.setState({
					disabledP: false,
					disabledPB: true,
					percentageBasic: '',
					successflag: false
				});
				break;
			case 'Fixed':
				this.setState({
					disabledPB: false,
					disabledP: true,
					percentagePerformance: '',
					successflag: false
				});
				break;

			default:
				this.setState({
					disabledP: true,
					disabledPB: true,
					percentagePerformance: '',
					percentageBasic: '',
					successflag: false
				});
				break;
		}
	};
	onEditModalOpen = () => {
		const { adminData } = this.state;

		this.setState({ enabled: true });
		// this.setPreStateOverEditData(adminData['type']);
	};
	onEditClick = itemId => {
		const { adminData } = this.state;
		this.setPreStateOverEditData(adminData[0].t1['type']);

		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	render() {
		const { adminData, modalData, errors } = this.state;

		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return index <= 3 ? (
				<li
					class={`page-item ${
						this.state.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={this.handleClickNext}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			) : (
				''
			);
		});
		// parseFloat
		const { currentPage, todosPerPage } = this.state;
		const indexOfLastTodo = currentPage * todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);

		const renderTodos = currentTodos.map((item, index) => {
			return (
				<tr className="bordersSpace" key={index}>
					<th className="bordersSpace" scope="row">
						{index + 1}
					</th>
					<td className="bordersSpace">{item.t1.type}</td>
					<td className="bordersSpace">{item.t1.timeDuration}</td>
					<td className="bordersSpace">{item.Name}</td>

					<td className="bordersSpace">
						{parseFloat(item.t1.basicSalary).toFixed(2)}
					</td>
					<td className="bordersSpace">{item.t1.percentageBasic + '%'}</td>
					<td className="bordersSpace">
						{item.t1.percentagePerformance + '%'}
					</td>
					<td className="bordersSpace">{item.t1.nextDateOfInc}</td>
					<td className="bordersSpace">{item.t1.accountId}</td>
					<td className="bordersSpace">{item.t1.accountType}</td>

					<td className="bordersSpace">
						<a href="#" data-toggle="tooltip" title="Edit!">
							<button
								id="firstbutton"
								onClick={() => this.onEditClick(item.t1.id)}
								onMouseOver={this.btnTechHover()}
								data-toggle="modal"
								data-target="#myModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i class="fas fa-pen"></i>
							</button>
						</a>
						<a href="#" data-toggle="tooltip" title="Delete!">
							<button
								onClick={() => this.onEditClick(item.t1.id)}
								data-toggle="modal"
								data-target="#myModal1"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
							</button>
						</a>
					</td>
				</tr>
			);
		});
		const MainContent = (
			<div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div>
									<div style={{ textAlign: 'center' }}>
										<h4>Are you sure ?</h4>
									</div>
									<div
										style={{ display: 'flex', justifyContent: 'space-evenly' }}
									>
										<div>
											<button
												disabled={this.state.enabled}
												data-dismiss="modal"
												onClick={() => this.onTakeItemDelete(this.state.id)}
												type="button"
												class="btn btn-primary buttonAppear"
											>
												Yes
											</button>
										</div>
										<div>
											<button
												data-dismiss="modal"
												disabled={this.state.enabled}
												type="button"
												class="btn btn-primary buttonAppear"
											>
												No
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal fade" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<form onSubmit={this.onHandleSubmit}>
									{/* {modalData && modalData.map((item, index) => ( */}
									<div>
										<fieldset disabled={!this.state.enabled}>
											<SelectionInput
												marginTop={20}
												feildName={'type'}
												selectName={'Appraisal Type'}
												onHandleChange={this.onHandleAppraisalTypeChange}
												errors={errors}
												optionsArrys={options}
												selectedText={'Type'}
												stateData={this.state}
												iconClassName={'fas fa-money-bill-wave animated fadeIn'}
												optionType="static"
												successflag={'successflag'}
												editUse={'staffEdit'}
											/>
											<div class="form-group">
												<label for="pwd">timeDuration:</label>
												<input
													readOnly={true}
													required
													style={{
														borderTop: 0,
														borderLeft: 0,
														borderRight: 0,
														boxShadow: 'none'
													}}
													value={this.state.timeDuration}
													onChange={this.onHandleText}
													type="timeDuration"
													name="timeDuration"
													class="form-control"
													id="timeDuration"
												/>
											</div>
											<div class="form-group">
												<label for="pwd">staffId:</label>
												<input
													readOnly={true}
													style={{
														borderTop: 0,
														borderLeft: 0,
														borderRight: 0,
														boxShadow: 'none'
													}}
													value={this.state.staffId}
													onChange={this.onHandleText}
													type="text"
													name="staffId"
													class="form-control"
													id="staffId"
												/>
											</div>
											<div class="form-group">
												<label for="pwd">Basic Salary:</label>
												<input
													readOnly={true}
													style={{
														borderTop: 0,
														borderLeft: 0,
														borderRight: 0,
														boxShadow: 'none'
													}}
													value={this.state.basicSalary}
													onChange={this.onHandleText}
													type="text"
													name="basicSalary"
													class="form-control"
													id="basicSalary"
												/>
											</div>
											<TextInput
												marginTop={20}
												feildName={'percentageBasic'}
												inputLabelName={'Percentage Basic'}
												onHandleChange={this.onHandleTextChange}
												errors={errors}
												stateData={this.state}
												iconClassName={'fas fa-percentage'}
												placeholder={'Add Value in %'}
												disabledField={'disabledPB'}
											/>
											<TextInput
												marginTop={20}
												feildName={'percentagePerformance'}
												inputLabelName={'Percentage Performance'}
												onHandleChange={this.onHandleTextChange}
												errors={errors}
												stateData={this.state}
												iconClassName={'fas fa-percentage'}
												placeholder={'Add Value in %'}
												disabledField={'disabledP'}
											/>

											<div class="form-group">
												<label for="pwd">Account Id:</label>
												<input
													readOnly={true}
													required
													style={{
														borderTop: 0,
														borderLeft: 0,
														borderRight: 0,
														boxShadow: 'none'
													}}
													value={this.state.accountId}
													onChange={this.onHandleText}
													type="text"
													name="accountId"
													class="form-control"
													id="accountId"
												/>
											</div>
											<div class="form-group">
												<label for="pwd">Account Type:</label>
												<input
													readOnly={true}
													required
													style={{
														borderTop: 0,
														borderLeft: 0,
														borderRight: 0,
														boxShadow: 'none'
													}}
													value={this.state.accountType}
													onChange={this.onHandleText}
													type="text"
													name="accountType"
													class="form-control"
													id="accountType"
												/>
											</div>
										</fieldset>
										<button
											disabled={this.state.enabled}
											onClick={this.onEditModalOpen}
											type="button"
											class="btn btn-primary buttonAppear"
										>
											Edit
										</button>
										<button
											style={{ marginLeft: 5 }}
											disabled={!this.state.enabled}
											type="submit"
											class="btn btn-primary buttonAppear"
										>
											Save
										</button>
									</div>
									{/* ))} */}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="table-responsive">
					<table class="table table-hover" style={{ textAlign: 'center' }}>
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Type</th>
								<th scope="col"> Duration</th>
								<th scope="col">Staff </th>
								<th scope="col">Basic Salary </th>

								<th scope="col">Basic %</th>
								<th scope="col">Performance %</th>
								<th scope="col">Next Increment</th>

								<th scope="col">AccountId</th>
								<th scope="col">AccountType</th>

								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>{renderTodos}</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<div>
								<h2>Appraisal&nbsp;Details</h2>
							</div>
							<div>
								<Link to="/dashboard/AppraisalNotification">
									<a
										href="#"
										data-toggle="tooltip"
										title="Appraisal Notification!"
									>
										<button
											id="btnAppNot"
											type="button"
											class="btn btn-info"
											style={{
												marginTop: 5,
												marginLeft: 10,
												backgroundColor: '#0A4F5E',
												fontSize: 13
											}}
											onMouseLeave={this.onMouseMoveOut}
										>
											<i
												style={{ marginTop: 5, fontSize: 10 }}
												className="fas fa-bell"
											/>
										</button>
									</a>
								</Link>
							</div>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								<li
									class={`page-item ${
										this.state.disableBPage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextBack}
										class="page-link"
										href="#"
									>
										{this.state.disableBPage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-backward"></i>
										)}
									</a>
								</li>
								{renderPageNumbers}
								<li
									class={`page-item ${
										this.state.disablepage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextFrwd}
										class="page-link"
										href="#"
									>
										{this.state.disablepage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-forward"></i>
										)}
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				{this.state.isLoading ? Loader : MainContent}
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		staffActions: bindActionCreators(StaffActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch),
		actionUser: bindActionCreators(userAction, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		getAllAppraisalData: state.setStaffReducer.getAppraisalData,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewAppraisal);
