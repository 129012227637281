import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import * as sharedActions from '../../Actions/sharedActions';
import { connect } from 'react-redux';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';

class CoordinatorsDashboard extends Component {
	state = {
		hodPostIdForView: '1',
		viewAPIData: [],
		hodPostId: '',
		hodPostName: '',
		deanPostId: '',
		deanPostName: '',
		modalData: [],
		id: '',
		coordinatorPostId: '',
		DeanData: [],
		redirect: '',
		redirect2: '',
		errorMsg: false,
		msg: '',
		backColor: '',
		staffId: '',
		StudentList: [],
		checking: [],
		tableColor: 'black',
		searchCheck: false,
		loaderCheck: false,
		staffApiCheck: false,
		labelCheck: false,
		userName: '',
		password: ''
	};
	componentDidMount() {
		$(document).click(function(e) {
			if (!$(e.target).is('.panel-body')) {
				$('.collapse').collapse('hide');
			}
		});
		let Colors = ['#00a559', '#dc4a38', '#003d59', '#feb117', '#8cc938'];
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/DeanEnglishListHOD/?coordinatorPostId=${this.props.match.params.name}`
			)
			.then(success => {
				let getData = [...success];
				let newData = getData.map((item, index) => {
					return {
						id: item.id,
						coordinatorPostId: item.coordinatorPostId,
						postCoordinator: item.postCoordinator,
						postHODId: item.postHODId,
						postHOD: item.postHOD,
						deanPostId: item.deanPostId,
						postDean: item.postDean,
						color: Colors[index]
					};
				});
				debugger;
				this.setState({
					DeanData: newData
				});
			})
			.catch(err => {
				this.setState({
					msg: 'No Record Found',
					isLoading: false
				});
				this.props.snackbar();

				console.log(err);
			});
	}

	onHandleSubmit = e => {
		e.preventDefault();
		const {
			deanPostName,
			id,
			staffId,
			deanPostId,
			hodPostId,
			hodPostName,
			coordinatorPostId,
			userName,
			password,
			labelCheck
		} = this.state;
		if (
			Object.is(id, '') ||
			Object.is(staffId, '') ||
			Object.is(hodPostId, '') ||
			Object.is(userName, '') ||
			Object.is(password, '') ||
			Object.is(deanPostId, '') ||
			labelCheck == true
		) {
			this.setState({
				errorMsg: true
			});
		} else {
			const data = {
				hodPostId,
				hodPostName,
				deanPostId,
				deanPostName,
				coordinatorPostId,
				staffId,
				userName,
				password,
				accountType: this.props.role,
				accountId: this.props.name
			};
			debugger;
			this.props.sharedActions
				.simpleAddRequest('/api/HOD ', data)
				.then(success => {
					this.setState({
						errorMsg: false,
						ledger: '',
						amount: '',
						account: '',
						sessionId: '',
						payTo: '',
						msg: 'HOD Added Successfully',
						backColor: '#01ac8a'
					});
					$('#myModal1').modal('hide');
					this.props.snackbar();
				})
				.catch(error => {
					this.setState({
						msg: error.response.data.Message,
						backColor: 'red'
					});
					this.props.snackbar();
				});
		}
		console.log(this.state);
	};

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { DeanData } = this.state;
		let getData = DeanData.filter(item => item.id === itemId);

		this.setState({
			modalData: getData,
			hodPostId: getData[0].postHODId,
			hodPostName: getData[0].postHOD,
			coordinatorPostId: getData[0].coordinatorPostId,
			deanPostId: getData[0].deanPostId,
			deanPostName: getData[0].postDean,
			id: getData[0].id
		});
		debugger;
	};
	hitViewAPI = HodID => {
		this.setState({ viewAPIData: [] });
		this.props.sharedActions
			.getDataWithoutDispatch(`/api/HOD/?hodPostId=${HodID}`)
			.then(success => {
				let data = [];
				data.push({ ...success });
				debugger;
				this.setState({
					viewAPIData: data,
					searchCheck: false
				});
			})
			.catch(err => {
				this.setState({});

				console.log(err);
			});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecordWithoutDispatchWithoutSlash('/api/HOD/?id=', itemId)
			.then(success => {
				debugger;
				let deleteData = this.state.viewAPIData.filter(
					item => item.id != itemId
				);
				this.setState({
					viewAPIData: deleteData,
					msg: 'Record Deleted Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {
				debugger;
			});
	};
	changeHODId = id => {
		debugger;
		this.setState({
			hodPostIdForView: id
		});
	};
	paginationRequest = number => {
		this.setState({
			searchCheck: true
		});
		console.log(number);

		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/EnrollStaffInHodPostId?PageNumber=${number}&pageSize=10&hodpostId=${this.state.hodPostIdForView}`
			)
			.then(success => {
				this.setState({
					StudentList: success.staffList,
					checking: success.paginationMetadata,
					searchCheck: true
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});

				console.log(err);
			});
	};
	ViewStaffAPI = (id, color) => {
		this.setState({
			loaderCheck: true
		});
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/EnrollStaffInHodPostId/?pageNumber=1&pageSize=10&hodpostId=${id}`
			)
			.then(success => {
				debugger;
				this.setState({
					hodPostIdForView: id,
					tableColor: color,
					StudentList: success.staffList,
					checking: success.paginationMetadata,
					searchCheck: true,
					loaderCheck: false
				});
			})
			.catch(err => {
				this.setState({
					checking: [],
					StudentList: [],
					searchCheck: true,
					tableColor: color,
					loaderCheck: false
				});

				console.log(err);
			});
	};
	hitStaffIdAPI = e => {
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/AssignSubjectCoordinator/?staffId=${e.target.value}`
			)
			.then(success => {
				debugger;
				this.setState({
					staffId: success.staffId,
					staffName: success.staffName,
					previousPost: success.postName,
					staffApiCheck: true
				});
			})
			.catch(err => {
				this.setState({
					staffApiCheck: false
				});

				console.log(err);
			});
	};
	hitUserNameAPI = e => {
		console.log(e.target.value);
		e.preventDefault();
		debugger;
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/CheckHODUsername/?userName=${e.target.value}`
			)
			.then(success => {
				this.setState({
					labelCheck: false
				});
			})
			.catch(err => {
				debugger;
				this.setState({
					labelCheck: true
				});

				console.log(err);
			});
		this.setState({
			userName: e.target.value
		});
		console.log(e.target.value);
	};
	render() {
		const { tableColor, searchCheck, loaderCheck } = this.state;
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <=
			Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return (
				<li
					class={`page-item ${
						this.state.checking.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={() => this.paginationRequest(number)}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			);
		});
		return (
			<div>
				<div class="modal fade" id="myModalView">
					<div class="modal-dialog modal-lg">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									View Data
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{/* <div class="text-center">
									<label class="font-weight-bold text-danger">
										Already Event Added on this Date {this.state.msg}
									</label>
								</div> */}
								<table class="table table-sm">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Post Name</th>
											<th scope="col">Dean Name</th>
											<th scope="col">User Name</th>
											<th scope="col">Password</th>
											<th scope="col">Date</th>
											<th scope="col">Time</th>
											<th scope="col">AccountId</th>
											<th scope="col">AccountType</th>
											<th scope="col">Actions</th>
										</tr>
									</thead>
									<tbody>
										{this.state.viewAPIData.map((item, index) => (
											<tr>
												<th scope="row">{index + 1}</th>
												<td>{item.hodPostName}</td>
												<td>{item.name}</td>
												<td>{item.userName}</td>
												<td>{item.password}</td>
												<td>{item.date}</td>
												<td>{item.time}</td>
												<td>{item.accountId}</td>
												<td>{item.accountType}</td>
												<td>
													<a href="#" data-toggle="tooltip" title="Delete!">
														<button
															onClick={() => this.onTakeItemDelete(item.id)}
															style={{
																cursor: 'pointer',
																fontSize: 20,
																border: 'none',
																background: 'none',
																marginLeft: 5,
																marginBottom: 5
															}}
														>
															<i
																style={{ color: '#D11A2A' }}
																class="fas fa-trash-alt"
															></i>
														</button>
													</a>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog modal-lg">
						<div class="modal-content modal_content_custom">
							<div class="modal-header modal_custom_header">
								<h4 style={{ color: 'white' }} class="modal-title">
									Add HOD
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<section class="mb-4">
									<div
										class="row"
										style={{
											justifyContent: 'center',
											textAlign: 'center'
										}}
									>
										<div
											class="col-md-9 mb-md-0 mb-5"
											style={{
												padding: 0,
												borderRadius: 15,
												width: 'auto'
											}}
										>
											<form id="contact-form" name="contact-form">
												<div class="row">
													<div class="col-md-6" style={{ marginTop: 20 }}>
														<div class="md-form mb-0">
															<label for="name" class="font-weight-bold">
																Admin Name
															</label>
															<input
																value={this.props.name}
																readOnly={true}
																type="text"
																name="AdminName"
																class="form-control"
																onChange={this.onHandleTextChange}
															/>
														</div>
													</div>

													<div class="col-md-6" style={{ marginTop: 20 }}>
														<div class="md-form mb-0">
															<label for="name" class="font-weight-bold">
																Admin Type
															</label>
															<input
																value={this.props.role}
																readOnly={true}
																type="text"
																name="AdminType"
																class="form-control"
																onChange={this.onHandleTextChange}
															/>
														</div>
													</div>
													<div class="col-md-6" style={{ marginTop: 20 }}>
														<div class="md-form mb-0">
															<label for="email" class="font-weight-bold">
																Staff Id
															</label>
															<input
																placeholder="Enter Staff Id"
																type="number"
																name="staffId"
																className="form-control check"
																onChange={this.hitStaffIdAPI}
															/>
														</div>
													</div>
													{this.state.staffApiCheck ? (
														<React.Fragment>
															<div class="col-md-6" style={{ marginTop: 20 }}>
																<div class="md-form mb-0">
																	<label for="name" class="font-weight-bold">
																		Post Name
																	</label>
																	<input
																		disabled={true}
																		placeholder="Post Name"
																		type="text"
																		name="payTo"
																		class="form-control"
																		value={this.state.previousPost}
																		onChange={this.onHandleTextChange}
																	/>
																</div>
															</div>
															<div class="col-md-6" style={{ marginTop: 20 }}>
																<div class="md-form mb-0">
																	<label for="email" class="font-weight-bold">
																		Staff Name
																	</label>
																	<input
																		placeholder="Enter Staff Id"
																		type="text"
																		name="staffId"
																		value={this.state.staffName}
																		className="form-control check"
																		readOnly={true}
																	/>
																</div>
															</div>
															<div class="col-md-6" style={{ marginTop: 20 }}>
																<div class="md-form mb-0">
																	{this.state.labelCheck ? (
																		<React.Fragment>
																			<label class="text-center text-danger font-weight-bold">
																				<span class="fas fa-times"></span>{' '}
																				Username Already Exist
																			</label>
																		</React.Fragment>
																	) : (
																		<label for="email" class="font-weight-bold">
																			User Name
																		</label>
																	)}
																	<input
																		placeholder="Add User Name"
																		type="text"
																		name="userName"
																		value={this.state.userName}
																		className="form-control"
																		onChange={e => this.hitUserNameAPI(e)}
																	/>
																</div>
															</div>
															<div class="col-md-6" style={{ marginTop: 20 }}>
																<div class="md-form mb-0">
																	<label for="email" class="font-weight-bold">
																		Password
																	</label>
																	<input
																		placeholder="Enter Password"
																		type="text"
																		name="password"
																		value={this.state.password}
																		className="form-control check"
																		onChange={this.onHandleTextChange}
																	/>
																</div>
															</div>
														</React.Fragment>
													) : (
														''
													)}
												</div>
												<br />
												<br />
												{this.state.errorMsg ? (
													<p style={{ color: 'red', textAlign: 'center' }}>
														Please fill the form properly
													</p>
												) : (
													''
												)}
												<div class="text-right text-md-right">
													<button
														onClick={this.onHandleSubmit}
														class="buttonHover2"
													>
														Add
													</button>
												</div>
											</form>
											<div class="status" />
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</div>
				<section class="mb-4">
					<SnackBar msg={this.state.msg} backColor={this.state.backColor} />
					<h3 class="h3-responsive font-weight-bold text-center my-4">
						English HOD Dashboard
					</h3>
					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center',
							marginBottom: -45
						}}
					>
						<div class="col-md-8">
							<div class="md-form mb-0">
								<hr class="style-head" />
							</div>
						</div>
					</div>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div class="row justify-content-center">
						{this.state.DeanData.map((item, index) => {
							return (
								<React.Fragment key={index}>
									{/* <div class="col-md-2"></div> */}
									<div class="col-md-3 animated slideInUp">
										<div
											class="card shadowForBox"
											style={{ backgroundColor: item.color }}
										>
											<div class="card-body text-center">
												{/* <Link
													to={`/dashboard/EnrolledStaffInHOD/${item.postHODId}`}
												> */}
												<h5
													class="card-title font-weight-bold"
													style={{
														color: 'white',
														cursor: 'pointer',
														height: 60
													}}
													onClick={() =>
														this.ViewStaffAPI(item.postHODId, item.color)
													}
												>
													{item.postHOD}
												</h5>
												{/* </Link> */}
												<label
													style={{
														color: 'white',
														fontSize: 15
													}}
												>
													Click above to see all the Enrolled staff in this{' '}
													{item.postHOD}
												</label>
												{/* <button
													class="buttonHover2"
													data-toggle="modal"
													data-target="#myModal1"
													onClick={() => this.onTakeItem(item.id)}
												>
													Assign
												</button>{' '}
												--{' '}
												<button
													class="buttonHover2"
													style={{ width: 70 }}
													data-toggle="modal"
													data-target="#myModalView"
													onClick={() => this.hitViewAPI(item.postHODId)}
												>
													View
												</button> */}
											</div>
										</div>
										{index == 3 ? <br /> : ''}
									</div>
								</React.Fragment>
							);
						})}
						{loaderCheck ? (
							<div class="col-md-12 justify-content-center">
								<br />
								<br />
								{Loader}{' '}
							</div>
						) : (
							''
						)}
						{searchCheck ? (
							<React.Fragment>
								<div class="col-md-12 justify-content-center">
									<nav aria-label="Page navigation example text-center">
										<ul style={{ color: '#01AC8A' }} class="pagination">
											{renderPageNumbers}
										</ul>
									</nav>
								</div>
								<div class="col-md-12">
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
									>
										<span className="customBadge" style={{ fontSize: 14 }}>
											Page: {this.state.checking.currentPage}
										</span>

										<span className="customBadge" style={{ fontSize: 14 }}>
											Total Page: {this.state.checking.totalPages}
										</span>
									</div>
									<table
										class="table table-hover table-bordered"
										style={{ textAlign: 'center' }}
									>
										<thead style={{ background: tableColor, color: 'white' }}>
											<tr>
												<th scope="col">#</th>
												<th scope="col">Name</th>
												<th scope="col">Subject</th>
												<th scope="col">Class</th>
												<th scope="col">Section</th>
												<th scope="col">Post</th>
												<th scope="col">Actions</th>
											</tr>
										</thead>
										<tbody>
											{this.state.StudentList.map((item, index) => {
												return (
													<tr key={index}>
														<th scope="row">{index + 1}</th>
														<td>{item.name}</td>
														<td>{item.subjectName}</td>
														<td>{item.className}</td>
														<td>{item.section}</td>
														<td>{item.postHOD}</td>
														<td>
															<Link
																to={`/dashboard/AcademicFormViews/${item.id}`}
															>
																<a href="#" data-toggle="tooltip" title="View!">
																	<button
																		style={{
																			cursor: 'pointer',
																			fontSize: 20,
																			border: 'none',
																			background: 'none',
																			marginLeft: 5,
																			marginBottom: 5
																		}}
																	>
																		<i
																			style={{ color: '#D11A2A' }}
																			class="fas fa-list"
																		></i>
																	</button>
																</a>
															</Link>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</React.Fragment>
						) : (
							''
						)}
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CoordinatorsDashboard);
