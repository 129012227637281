import { General } from '../../../utils';

const ON_TEXT_CHANGE = 'onChange';
const ON_RADIO_CHANGE = 'onChangeRadio';
const ON_FILE_CHANGE = 'onFileChange';
const ON_DATE_CHANGE = 'ON_DATE_CHANGE';
const ON_SELECTION_CHANGE = 'ON_SELECTION_CHANGE';
const ON_DYANMIC_ADD = 'ON_DYANMIC_ADD';
const ON_DYANMIC_DELETE = 'ON_DYANMIC_DELETE';
const ON_DYNAMIC_UPDATE = 'ON_DYNAMIC_UPDATE';
const ON_IMAGE_CHANGE = 'ON_IMAGE_CHANGE';
const ON_CLASS_CHANGE = 'ON_CLASS_CHANGE';
const ON_CERTIFICATE_DISPATCH = 'ON_CERTIFICATE_DISPATCH';
const ON_SET_COMPELETE_STATE = 'ON_SET_COMPELETE_STATE';
const ON_SET_STAFF_UPDATE_ADDRESS = 'ON_SET_STAFF_UPDATE_ADDRESS';
const ON_SET_STAFF_DETAILS = 'ON_SET_STAFF_DETAILS';
const ON_SET_STAFF_PASSPORT = 'ON_SET_STAFF_PASSPORT';
const ON_SET_CERTIFICATE_EDIT = 'ON_SET_CERTIFICATE_EDIT';
const ON_SET_EXPERIENCE_EDIT = 'ON_SET_EXPERIENCE_EDIT';
const ON_SET_QUALIFICATON_EDIT = 'ON_SET_QUALIFICATON_EDIT';
const ON_SET_SKILLS_EDIT = 'ON_SET_SKILLS_EDIT';
const ON_SET_SIBLINGS_EDIT = 'ON_SET_SIBLINGS_EDIT';

export const Types = {
	ON_TEXT_CHANGE,
	ON_RADIO_CHANGE,
	ON_FILE_CHANGE,
	ON_DATE_CHANGE,
	ON_SELECTION_CHANGE,
	ON_DYANMIC_ADD,
	ON_DYANMIC_DELETE,
	ON_DYNAMIC_UPDATE,
	ON_IMAGE_CHANGE,
	ON_CLASS_CHANGE,
	ON_CERTIFICATE_DISPATCH,
	ON_SET_COMPELETE_STATE,
	ON_SET_STAFF_UPDATE_ADDRESS,
	ON_SET_STAFF_DETAILS,
	ON_SET_STAFF_PASSPORT,
	ON_SET_CERTIFICATE_EDIT,
	ON_SET_EXPERIENCE_EDIT,
	ON_SET_SIBLINGS_EDIT,
	ON_SET_SKILLS_EDIT,
	ON_SET_QUALIFICATON_EDIT
};

const staffInitailState = {
	// for edit statefull data
	StaffInformation: {
		fullNameArabic: '',

		staffId: '',
		StaffFirstName: '',
		lastName: '',
		gender: 'Choose Gender...',
		fatherName: '',
		designation: '',
		maritailStatus: 'Marital Status...',
		nationality: 'Choose Nationality...',
		city: '',
		country: 'Choose Country...',
		state: '',
		mobileNumber: '',
		email: '',
		address: '',
		religion: 'Choose Religion...',
		username: '',
		StreetNo: '',
		password: '',
		bloodGroup: 'Choose Group...',
		status: 'Choose Status...',
		appraisalStatus: 'Choose Status...',
		staffIdcardNo: '',
		staffPassportNo: '',
		basicSalary: '',
		houseNo: '',
		wayNo: '',
		phoneNo: '',
		classId: 'Choose Class',
		className: '',
		// start of grade done
		gradeId: '',
		gradeName: '',
		// post start
		postName: '',
		postId: 'Choose Post',

		// campus start
		campusName: '',
		// section done
		sectionId: 'Choose Section',
		section: '',
		campusId: 'Choose Campus...',
		// subject start done
		subjectName: '',
		subjectId: 'Choose Subject',
		// end done
		// depart done
		departmentName: 'Choose Department',
		departmentId: 'Choose Depart',
		// depart end
		dob: null,
		age: '',
		dateOfJoining: new Date().toLocaleDateString(),
		// subjectData
		subjectData: [],
		// end of subject data
		// for containing grade data
		grade: [],
		// end of data
		// for containing post data
		post: []
	},
	// end for edit statefull data

	// upload image
	StaffImageUpload: {
		imagePreviewUrl:
			'http://ctt.trains.com/sitefiles/images/no-preview-available.png',
		imageFile: '',
		file: null
	},
	ExtraFields: {},
	// end of upload image
	// step 1 Staff Information
	// class id start done
	// class id end
	// end of grade
	// post end done
	// campus end done
	// subjectData
	// end of post data
	// section end

	// end of staff docs
	// start of staff docs
	StaffDoc: {},
	// end of staff docs
	// start of staff Qualification
	Siblings: [
		{
			siblingName: '',
			siblingRelation: '',
			siblingPassportNo: '',
			siblingIdcardno: '',
			siblingpassDOI: '',
			siblingpassDOE: '',
			siblingIdcardDOI: '',
			siblingIdcardDOE: ''
		}
	],

	Qualifications: [
		{
			qualificationName: '',
			board: '',
			qualificationYear: '',
			institute: ''
		}
	],
	Skills: [
		{
			skillName: ''
		}
	],
	Experiences: [
		{
			experienceName: '',
			companyName: '',
			experinceYear: ''
		}
	],
	Certificate: []

	// end of
};

const setStaffAddressData = (state, action) => {
	return { ...state, [action.payload.name]: action.payload.data };
};
const setWholeState = (state, action) => {
	debugger;
	return {
		...state,
		Experiences: action.payload.getApiData.Experiences,
		Certificate: action.payload.getApiData.Certificate,
		Qualifications: action.payload.getApiData.Qualifications,
		Siblings: action.payload.getApiData.Siblings,
		Skills: action.payload.getApiData.Skills,
		StaffDoc: {
			StaffDocId: action.payload.getApiData.StaffDocument.id,
			staffId: action.payload.getApiData.Staffs.staffId,
			experienceLetter:
				action.payload.getApiData.StaffDocument.experienceLetter,
			iletsCertificate:
				action.payload.getApiData.StaffDocument.iletsCertificate,
			ministryApproval:
				action.payload.getApiData.StaffDocument.ministryApproval,
			lastQualification:
				action.payload.getApiData.StaffDocument.lastQualification,
			passportCopy: action.payload.getApiData.StaffDocument.passportCopy,
			idCardCopys: action.payload.getApiData.StaffDocument.idCardCopys
		},

		StaffInformation: {
			fullNameArabic: action.payload.getApiData.Staffs.fullNameArabic,
			staffId: action.payload.getApiData.Staffs.staffId,
			StaffFirstName: action.payload.getApiData.Staffs.StaffFirstName,
			lastName: action.payload.getApiData.Staffs.lastName,
			gender: action.payload.getApiData.Staffs.gender,
			fatherName: action.payload.getApiData.Staffs.fatherName,
			designation: action.payload.getApiData.Staffs.designation,
			maritailStatus: action.payload.getApiData.Staffs.maritailStatus,
			nationality: action.payload.getApiData.Staffs.nationality,
			state: action.payload.getApiData.Staffs.state,
			mobileNumber: action.payload.getApiData.Staffs.mobileNumber,
			email: action.payload.getApiData.Staffs.email,
			religion: action.payload.getApiData.Staffs.religion,
			username: action.payload.getApiData.Staffs.userName,
			password: action.payload.getApiData.Staffs.password,
			bloodGroup: action.payload.getApiData.Staffs.bloodGroup,
			status: action.payload.getApiData.Staffs.status,
			appraisalStatus: action.payload.getApiData.Staffs.appraisalStatus,
			basicSalary: action.payload.getApiData.Staffs.basicSalary,
			dob: action.payload.getApiData.Staffs.dob,
			dateOfJoining: action.payload.getApiData.Staffs.dateOfJoining,
			age: action.payload.getApiData.Staffs.age,
			classId: action.payload.getApiData.Staffs.classId,
			className: action.payload.getApiData.Staffs.className,
			gradeId: action.payload.getApiData.Staffs.gradeId,
			gradeName: action.payload.getApiData.ClassName.gradeName,
			section: action.payload.getApiData.Staffs.section,
			sectionId: action.payload.getApiData.Staffs.sectionId,
			subjectId: action.payload.getApiData.Staffs.subjectId,
			subjectName: action.payload.getApiData.SubjectName.subjectName,
			campusName: action.payload.getApiData.CampusStaff.campusName,
			campusId: action.payload.getApiData.Staffs.campusId,
			departmentName: action.payload.getApiData.DepartmentName.departmentName,
			departmentId: action.payload.getApiData.Staffs.departmentId,
			postName: action.payload.getApiData.PostName.postName,
			postId: action.payload.getApiData.Staffs.postId
		},
		StaffAddress: {
			city: action.payload.getApiData.Staffs.city,
			houseNo: action.payload.getApiData.Staffs.houseNo,
			wayNo: action.payload.getApiData.Staffs.wayNo,
			mobileNumber: action.payload.getApiData.Staffs.mobileNumber,
			StreetNo: action.payload.getApiData.Staffs.streetNo,
			country: action.payload.getApiData.Staffs.country,
			address: action.payload.getApiData.Staffs.address
		},
		StaffPassport: {
			staffPassportNo: action.payload.getApiData.Staffs.staffPassportNo,
			staffIdcardNo: action.payload.getApiData.Staffs.staffIdcardNo,
			staffPassportDOI: action.payload.getApiData.Staffs.staffPassportDOI,
			staffPassportDOE: action.payload.getApiData.Staffs.staffPassportDOE,
			staffIdcardDOI: action.payload.getApiData.Staffs.staffIdcardDOI,
			staffIdcardDOE: action.payload.getApiData.Staffs.staffIdcardDOE
		},
		StaffImageUpload: {
			imagePreviewUrl: General.conversionOfImageUrl(
				action.payload.getApiData.StaffImageUpload.filePath
			),
			file: action.payload.getApiData.StaffImageUpload,
			imageFile: action.payload.getApiData.StaffImageUpload.fileName,
			id: action.payload.getApiData.StaffImageUpload.id
		}
		// ...action.payload.getApiData.StaffDoc,
		// ...action.payload.getApiData.Staffs,
		// ...action.payload.getApiData.campusStaff,
		// ...action.payload.getApiData.className,
		// ...action.payload.getApiData.departmentName,
		// ...action.payload.getApiData.postName,
		// ...action.payload.getApiData.sectionName,
		// ...action.payload.getApiData.subjectName
	};
};

const certificateDispatch = (state, action) => {
	debugger;
	return { ...state, Certificate: action.payload.Certificate };
};
// const qualificationDispatch =(state,action)=>{
//
// 	return {...state,}
// }
const imageChange = (state, action) => {
	return {
		...state,
		StaffImageUpload: {
			file: action.payload.file,
			imagePreviewUrl: action.payload.imagePreviewUrl,
			imageFile: action.payload.imageFile
		}
	};
};
const onClassChange = (state, action) => {
	console.log(action.payload.value);
	const { grade } = action.payload.allData[0];
	const { sections } = action.payload.allData[0];
	const { subject } = action.payload.allData[0];

	let getSectionData = sections.filter(
		item => item.classId === parseInt(action.payload.value)
	);
	let getGradeData = grade.filter(
		(item, index) => item.classId === parseInt(action.payload.value)
	);
	let getSubjectsData = subject.filter(
		item => item.classId === parseInt(action.payload.value)
	);
	console.log(getGradeData);

	return {
		...state,
		className: getGradeData[0] && getGradeData[0].className,
		gradeName: getGradeData[0] && getGradeData[0].gradeName,
		classId: action.payload.value,
		gradeId: getGradeData[0] && getGradeData[0].gradeId,
		grade: getSectionData,
		subjectData: getSubjectsData
	};
	// this.setState({
	//     grade: getGradeData,
	//     gradeName: getGradeData[0] && getGradeData[0].gradeName,
	//     className: getGradeData[0] && getGradeData[0].className,
	//     classId: parseInt(e.target.value),
	//     gradeId: getGradeData[0] && getGradeData[0].gradeId,
	// })
};
const onClickDynamicDelete = (state, action) => {
	let getStateArr = state[action.payload.arrName];

	let getDeleteLength = getStateArr.length - 1;
	if (getDeleteLength > 0) {
		let getNewDetails = getStateArr.filter(
			(item, index) => index !== getDeleteLength
		);
		console.log(getNewDetails);

		return {
			...state,
			[action.payload.arrName]: [...getNewDetails]
		};
	} else {
		return { ...state };
	}
};
const onClickDynamicAdd = (state, action) => {
	let getStateArr = state[action.payload.arrName];
	getStateArr = [...getStateArr, action.payload.obj];
	console.log(action.payload.arrName);
	return {
		...state,
		[action.payload.arrName]: getStateArr
	};
};
const updateSpecialArrChange = (state, action) => {
	const changeArrOfobj = state[action.payload.arrName];
	changeArrOfobj[action.payload.index][action.payload.name] =
		action.payload.value;

	return {
		...state,
		[action.payload.arrName]: changeArrOfobj
	};
};
const onDateChange = (state, action) => {
	if (action.payload.type === 'age') {
		let getAge =
			new Date().getFullYear() - new Date(action.payload.date).getFullYear();

		return {
			...state,
			[action.payload.name]: action.payload.date,
			age: getAge
		};
	} else if (action.payload.type === 'customSiblings') {
		let realArray = action.payload.realArray;
		realArray[action.payload.index][action.payload.name] = action.payload.date;

		return { ...state, realArray };
	} else {
		return {
			...state,
			[action.payload.name]: action.payload.date
		};
	}
};
const fileOnChange = (state, action) => {
	return { ...state, [action.payload.name]: action.payload.value };
};
const onCustomRadioDocs = (state, action) => {
	switch (action.payload.value) {
		case 'Yes':
			return {
				...state,
				[action.payload.name]: action.payload.value
			};
		case 'No':
			return {
				...state,
				[action.payload.name]: action.payload.value,
				[action.payload.fieldName]: action.payload.set
			};
		default:
			break;
	}
};

const onChange = (state, action) => {
	if (action.payload.name === 'sectionId') {
		let getSection = '';

		if (action.payload.value !== 'Choose Section') {
			getSection = state.grade.filter(
				item => item.sectionId === parseInt(action.payload.value)
			)[0].section;
		}

		return { ...state, sectionId: action.payload.value, section: getSection };
	} else if (action.payload.name === 'departmentId') {
		let { department } = action.payload.extraData[1];
		let getDepartName = '';
		let getPostData = [];

		let { post } = action.payload.extraData[1];
		if (action.payload.value !== 'Choose Depart') {
			getDepartName = department.filter(
				item => item.departmentId === parseInt(action.payload.value)
			)[0].departmentName;
			getPostData = post.filter(
				item => item.departmentId === parseInt(action.payload.value)
			);
		} else {
			getPostData = [];
		}

		return {
			...state,
			departmentName: getDepartName,
			departmentId: action.payload.value,
			post: getPostData
		};
	} else if (action.payload.name === 'postId') {
		let { post } = action.payload.extraData[1];
		let getPostName = '';
		if (action.payload.value === 'Choose Post')
			getPostName = post.filter(
				item => item.postId === parseInt(action.payload.value)
			)[0].postName;
		return { ...state, postName: getPostName, postId: action.payload.value };
	} else if (action.payload.name === 'campusId') {
		let getCampus = action.payload.extraData[2];
		let getCampusName = '';

		if (action.payload.value !== 'Choose Campus...') {
			let getCampusName = getCampus.filter(
				item => item.id === parseInt(action.payload.value)
			)[0].campusName;
		}

		return {
			...state,
			campusName: getCampusName,
			campusId: action.payload.value
		};
	} else if (action.payload.name == 'subjectId') {
		let { subject } = action.payload.extraData[0];
		let getSubName = '';
		if (action.payload.value !== 'Choose Subject') {
			getSubName = subject.filter(
				item => item.subjectId === parseInt(action.payload.value)
			)[0].subjectName;
		}

		return {
			...state,
			subjectId: action.payload.value,
			subjectName: getSubName
		};
	} else {
		return { ...state, [action.payload.name]: action.payload.value };
	}
};
const staffReducer = (state, action) => {
	switch (action.type) {
		case ON_TEXT_CHANGE:
			return onChange(state, action);
		case ON_SET_STAFF_UPDATE_ADDRESS:
			return setStaffAddressData(state, action);
		case ON_RADIO_CHANGE:
			return onCustomRadioDocs(state, action);
		case ON_FILE_CHANGE:
			return fileOnChange(state, action);
		case ON_DATE_CHANGE:
			return onDateChange(state, action);
		case ON_DYANMIC_ADD:
			return onClickDynamicAdd(state, action);
		case ON_CERTIFICATE_DISPATCH:
			return certificateDispatch(state, action);
		case ON_DYANMIC_DELETE:
			return onClickDynamicDelete(state, action);
		case ON_DYNAMIC_UPDATE:
			return updateSpecialArrChange(state, action);
		case ON_IMAGE_CHANGE:
			return imageChange(state, action);
		case ON_CLASS_CHANGE:
			return onClassChange(state, action);
		case ON_SET_COMPELETE_STATE:
			return setWholeState(state, action);
		case 'decrement2':
			return { ...state, count2: state.count2 - 1 };
		case 'set2':
			return { ...state, count2: action.count };
		default:
			throw new Error('Unexpected action');
	}
};

export default {
	staffInitailState,
	staffReducer,
	Types
};
