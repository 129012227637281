import React, { Component, useEffect } from 'react';
import useHttp from '../../hooks/http';

export default function Modal(props) {
	const {
		titleColor,
		mdId,
		animation,
		modalTitle,
		modalBody,
		modalSize,
		mainId = '',
		backdrop,
		datakey,
		fullModal,
		handleSubmit,
		height = 900,
		type,
		header,
		topCloseButton = true,
		bottomCloseButtonName = 'Close',
		bottomSaveButtonName = 'Save Changes',
		closeHandler = true,
		handleCloseButton
	} = props;

	return (
		<div
			data-backdrop={backdrop}
			data-keyboard={datakey}
			class={`modal ${animation}`}
			id={mdId}
			tabindex="-1"
			role="dialog"
			aria-labelledby={`exampleModalScrollableTitle_${mdId}`}
			aria-hidden="true"
		>
			<div
				class={`modal-dialog modal-dialog-scrollable ${modalSize} ${fullModal}`}
				role="document"
			>
				<div class="modal-content">
					{header ? (
						<div
							style={{
								borderBottomLeftRadius: 30,
								borderBottomRightRadius: 30,
								borderTopLeftRadius: 2,
								borderTopRightRadius: 2
							}}
							class="modal-header modal_custom_header"
						>
							<h5
								style={{ color: titleColor }}
								class="modal-title"
								id="exampleModalScrollableTitle"
							>
								{modalTitle}
							</h5>
							{topCloseButton ? (
								<button
									type="button"
									class="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							) : (
								''
							)}
						</div>
					) : (
						''
					)}
					<div id={`${mainId}`} style={{ height: height }} class="modal-body">
						{props.children}
					</div>
					{type !== 'reuse' ? (
						<div class="modal-footer">
							<button
								type="button"
								class="btns"
								style={{ width: '10%', padding: 7, fontSize: 'initial' }}
								onClick={!closeHandler ? () => handleCloseButton() : ''}
								data-dismiss={closeHandler ? 'modal' : ''}
							>
								{bottomCloseButtonName}
							</button>
							<button
								type="button"
								onClick={() => props.handleSubmit()}
								class="btns"
								style={{ width: '15%', padding: 7, fontSize: 'initial' }}
							>
								{bottomSaveButtonName}
							</button>
						</div>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
}
