import * as actionTypes from '../actionTypes';

export function setAllSkillsInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_SKILLS,
		payload: allData
	};
}
export function setAllSkillsViewInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_SKILLS_FOR_VIEW,
		payload: allData
	};
}
export function setAllSkillsListInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_STUDENT_SKILLS_LIST,
		payload: allData
	};
}
export function addSkill(Data) {
	return {
		type: actionTypes.ADD_SKILL,
		payload: Data
	};
}
export function getSessionsForSkills(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_SKILL_SELECT,
		payload: allData
	};
}
export function getClassSectionSubjectForStudentSkill(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_STUDENT_SKILL_SELECT,
		payload: allData
	};
}
export function updateSkillFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_SKILLS,
		payload: setFlag
	};
}
export function updateSkillListFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_SKILLS_LIST,
		payload: setFlag
	};
}
export function updateSkillFlagView(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_SKILLS_VIEW,
		payload: setFlag
	};
}
export function updateSkill(data) {
	return {
		type: actionTypes.EDIT_SKILL_RECORD,
		payload: data
	};
}
// export function deleteSyllabus(id) {
// 	return {
// 		type: actionTypes.DELETE_SYLLABUS,
// 		payload: id
// 	};
// }
