import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const DiscriptiveDashboard = () => {
	return (
		<div>
			<div>
				<section class="mb-4">
					<h3 class="h3-responsive font-weight-bold text-center my-4">
						Descriptive Report Dashboard
					</h3>
					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center',
							marginBottom: -45
						}}
					>
						<div class="col-md-8">
							<div class="md-form mb-0">
								<hr class="style-head" />
							</div>
						</div>
					</div>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{ textAlign: 'center', justifyContent: 'center' }}
					>
						<div class="col-md-9 mb-md-0 mb-5">
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/DescriptiveReport">
												<img
													src={require('../Assets/DescriptiveDashboard/add descriptive report.svg')}
													width="200"
													height="200"
												/>
												{/* <h4>Add Descriptive Report</h4> */}
											</Link>
										</div>
									</div>
									<div class="col-md-6">
										<div class="md-form mb-0">
											<Link to="/dashboard/EditDescriptiveReport">
												<img
													src={require('../Assets/DescriptiveDashboard/view descriptive report.svg')}
													width="200"
													height="200"
												/>
												{/* <h4>View Descriptive Report</h4> */}
											</Link>
										</div>
									</div>
								</div>
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default DiscriptiveDashboard;
