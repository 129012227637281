import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CanteenItemsAction from '../../Actions/CanteenItems';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';

class AddCanteenItems extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			price: '',
			stock: '',
			select: '',
			errorMsg: false,
			msg: false,
			categoryName: '',
			categoryId: ''
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.select !== nextProps.selectData) {
			return {
				select: nextProps.selectData
			};
		}
		return null;
	}

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		console.log(result);
		this.setState({
			categoryId: result.t1.id
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const { name, price, stock } = this.state;
		if (Object.is(name, '') || Object.is(price, '') || Object.is(stock, '')) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			var bodyParameters = {
				name: this.state.name,
				price: this.state.price,
				stock: this.state.stock,
				accountType: this.props.userData.role,
				accountId: this.props.userData.unique_name,
				categoryId: this.state.categoryId
			};
			this.props.sharedActions
				.addRecord(
					'/api/Items',
					bodyParameters,
					this.props.CanteenItemsAction.addCanteenItems
				)
				.then(success => {
					this.props.CanteenItemsAction.updateCanteenItemsFlag(true);
					this.setState({
						name: '',
						price: '',
						select: '',
						msg: true,
						errorMsg: false
					});
				})
				.catch(error => {});
		}
	};
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/ItemsCategories',
				this.props.CanteenItemsAction.getCategoryForItems
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};
	render() {
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Canteen Items
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.Message(
								'Items Added Successfully!',
								'success',
								20,
								this.state.msg
							)}
							{this.Message(
								'Please fill the form properly!',
								'danger',
								20,
								this.state.errorMsg
							)}
							<form
								id="contact-form"
								name="contact-form"
								onSubmit={this.onHandleSubmit}
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												readOnly={true}
												value={this.props.userData.unique_name}
												type="text"
												name="AdminName"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												// placeholder='Enter Stock'

												readOnly={true}
												value={this.props.userData.role}
												type="text"
												name="AdminType"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Category Name
											</label>
											<select
												onChange={this.onSelectChange}
												class="custom-select"
												name="categoryId"
												style={{ borderColor: '#01AC8A' }}
											>
												<option selected={this.state.msg ? true : false}>
													Choose Category...
												</option>
												{this.state.select.map((item, index) => (
													<option key={index} value={`${JSON.stringify(item)}`}>
														{item.t1.name}
													</option>
												))}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Item Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Enter Name"
												type="text"
												name="name"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Item Price
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Enter Price"
												type="text"
												name="price"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Stock
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Enter Stock"
												type="text"
												name="stock"
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
								</div>
								<br />
								<br />
								<div class="text-center text-md-center">
									<button type="submit" class="btns">
										Add
									</button>
								</div>
							</form>

							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		CanteenItemsAction: bindActionCreators(CanteenItemsAction, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectData: state.setCanteenItemsReducer.select,
		handlerData: state.setCanteenReducer.selectHandler
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddCanteenItems);
