import React from 'react';
import Colors from '../../utils/app_constants/colors_constants';

export default function Pagination(props) {
	const {
		handleClickNextFrwd,
		disableBPage,
		handleClickNextBack,
		handleClickNext,
		disablepage,
		pageNumber,
		currentPage,
		paginationListRange = 3
	} = props;
	const renderPageNumbers = pageNumber.map((number, index) => {
		return index <= paginationListRange ? (
			<li class={`page-item ${currentPage === number ? 'active' : ''} `}>
				<a
					key={number}
					id={number}
					onClick={handleClickNext}
					class="page-link "
					href="#"
					style={{
						color: `${currentPage === number ? '#fff' : Colors.SEA_GREEN_THEME}`
					}}
				>
					{number}
				</a>
			</li>
		) : (
			''
		);
	});
	return (
		<nav aria-label="Page navigation example">
			<ul style={{ color: '#01AC8A' }} class="pagination">
				<li class={`page-item ${disableBPage ? 'disabled' : ''}`}>
					<a onClick={handleClickNextBack} class="page-link" href="#">
						{disableBPage ? (
							<i style={{ color: 'grey' }} class="far fa-stop-circle"></i>
						) : (
							<i
								style={{ color: Colors.SEA_GREEN_THEME }}
								class="fas fa-backward"
							></i>
						)}
					</a>
				</li>

				{renderPageNumbers}
				<li class={`page-item ${disablepage ? 'disabled' : ''}`}>
					<a onClick={handleClickNextFrwd} class="page-link" href="#">
						{disablepage ? (
							<i style={{ color: 'grey' }} class="far fa-stop-circle"></i>
						) : (
							<i
								style={{ color: Colors.SEA_GREEN_THEME }}
								class="fas fa-forward"
							></i>
						)}
					</a>
				</li>
			</ul>
		</nav>
	);
}
