import React, { Component } from 'react';

const TextBoxesForFees = props => {
	const {
		itemObj,
		onHandleTextChange,
		stateData,
		rowIndex,
		updateSpecialArrObjOnChange,
		arrName
	} = props;

	let keys = Object.keys(itemObj);
	// console.log(stateData.siblingsDetails[0]['age'])
	if (keys.includes('accountId') && keys.includes('accountType')) {
		let newKeys = keys.filter(
			item =>
				item !== 'accountId' && item !== 'accountType' && item !== 'classId'
		);
		keys = [...newKeys];
	}

	return (
		<React.Fragment>
			{keys.map((item, index) =>
				item === 'description' ? (
					<td key={index}>
						<textarea
							required={true}
							type="text"
							placeholder={`${item}`}
							class="form-control"
							name={`${item}`}
							value={stateData[arrName][rowIndex][`${item}`]}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
						/>
					</td>
				) : item == 'amount' ? (
					<td key={index}>
						<input
							required={true}
							type="number"
							placeholder={`${item}`}
							class="form-control"
							name={`${item}`}
							value={stateData[arrName][rowIndex][`${item}`]}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
						/>
					</td>
				) : (
					<td key={index}>
						<input
							required={true}
							type="text"
							placeholder={`${item}`}
							class="form-control"
							name={`${item}`}
							value={stateData[arrName][rowIndex][`${item}`]}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
						/>
					</td>
				)
			)}
		</React.Fragment>
	);
};
export default TextBoxesForFees;
