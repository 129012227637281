import * as actionTypes from '../actionTypes';

export function setAllClassWiseInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_CLASSWISE_SEARCH,
		payload: allData
	};
}
// export function setPartialStockUsageInRedux(allData) {
//
//     return {
//         type: actionTypes.GET_PARTIAL_STOCK_USAGE,
//         payload: allData
//     };
// }
// export function getItemsForStockUsage(allData) {
//
//     return {
//         type: actionTypes.SET_ALL_ITEMS_NAME_INTO_ADD_STOCK_USAGE_SELECT,
//         payload: allData
//     };
// }
export function getStudentForClassWise(allData) {
	return {
		type: actionTypes.SET_ALL_STUDENT_NAME_INTO_CLASSWISE_SELECT,
		payload: allData
	};
}
export function getSessionsForCensus(allData) {
	return {
		type: actionTypes.SET_ALL_SESSIONS_INTO_CLASSWISE_SELECT,
		payload: allData
	};
}
// export function getStaffForStockUsage(allData) {
//
//     return {
//         type: actionTypes.SET_ALL_STAFF_NAME_INTO_ADD_STOCK_USAGE_SELECT,
//         payload: allData
//     };
// }
// export function addStockUsage(Data) {
//
//     return {
//         type: actionTypes.ADD_STOCK_USAGE,
//         payload: Data
//     };
// }
export function updateClassWiseFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_CLASSWISE_ADD,
		payload: setFlag
	};
}
// export function updateStockUsage(data) {
//
//     return {
//         type: actionTypes.EDIT_STOCK_USAGE_RECORD,
//         payload: data
//     };
// }
// export function deleteStockUsage(id) {
//
//     return {
//         type: actionTypes.DELETE_STOCK_USAGE,
//         payload: id
//     };
// }
