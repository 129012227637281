import * as actionTypes from '../actionTypes';

export function setAllSkillsInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_SKILLS,
		payload: allData
	};
}
export function setAllDescriptiveViewInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_DESCRIPTIVE_FOR_VIEW,
		payload: allData
	};
}
export function setAllSkillsListInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_STUDENT_SKILLS_LIST,
		payload: allData
	};
}
export function addDescriptive(Data) {
	return {
		type: actionTypes.ADD_DESCRIPTIVE_REPORT,
		payload: Data
	};
}
export function getSessionsForDescriptive(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_DESCRIPTIVE_SELECT,
		payload: allData
	};
}
export function getClassSectionSubjectForDescriptiveReport(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_DESCRIPTIVE_SELECT,
		payload: allData
	};
}
export function updateSkillFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_SKILLS,
		payload: setFlag
	};
}
export function updateSkillListFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_SKILLS_LIST,
		payload: setFlag
	};
}
export function updateDescriptiveFlagView(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_Descriptive_VIEW,
		payload: setFlag
	};
}
export function updateDescriptive(data) {
	return {
		type: actionTypes.EDIT_DESCRIPTIVE_RECORD,
		payload: data
	};
}
