import React from 'react';
import Spinner from '../Spinner/Spinner.jsx';

export default function LoaderWrapper(props) {
	const { isLoading = false, children, marginTop = 20 } = props;
	return (
		<React.Fragment>
			{isLoading ? (
				<div style={{ marginTop: 10 }}>
					<Spinner />
				</div>
			) : (
				<div style={{ marginTop: marginTop }}>{children}</div>
			)}
		</React.Fragment>
	);
}
