import * as actionTypes from '../Actions/actionTypes';
import isEmpty from 'lodash/isEmpty';

const defaultState = {
	apiFlag: true,
	apiFlagAll: true,
	apiFlagList: true,
	Data: [],
	DataP: [],
	DataL: [],
	selectStudent: [],
	selectSession: [],
	selectExam: []
};

export default function setDescriptiveReducer(
	state = defaultState,
	action = {}
) {
	switch (action.type) {
		case actionTypes.GET_ALL_SKILLS:
			return { ...state, Data: [...action.payload] };
		case actionTypes.GET_ALL_DESCRIPTIVE_FOR_VIEW:
			return { ...state, DataP: [...action.payload] };
		case actionTypes.GET_ALL_STUDENT_SKILLS_LIST:
			return { ...state, DataL: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_SKILLS:
			return { ...state, apiFlag: action.payload };
		case actionTypes.SET_LOADER_FLAG_Descriptive_VIEW:
			return { ...state, apiFlagAll: action.payload };
		case actionTypes.SET_LOADER_FLAG_SKILLS_LIST:
			return { ...state, apiFlagList: action.payload };
		// case actionTypes.DELETE_SYLLABUS:
		// 	let filterCandidateReqList = state.Data.filter(
		// 		filterOBJ => filterOBJ.Id !== action.payload
		// 	);
		// 	return {
		// 		...state,
		// 		Data: filterCandidateReqList
		// 	};
		case actionTypes.SET_ALL_SESSION_NAME_INTO_DESCRIPTIVE_SELECT:
			return {
				...state,
				selectSession: action.payload
			};
		case actionTypes.SET_ALL_CLASS_NAME_INTO_DESCRIPTIVE_SELECT:
			return {
				...state,
				selectStudent: action.payload
			};
		case actionTypes.EDIT_DESCRIPTIVE_RECORD:
			var adminData = [...state.DataP];
			var index = adminData.findIndex(
				obj => obj.descriptiveReport === action.payload.id
			);
			adminData[index].description = action.payload.description;
			// adminData[index].participation = action.payload.participation;
			// adminData[index].academicProgress = action.payload.academicProgress;
			// adminData[index].examDate = action.payload.examDate;

			return { ...state, DataP: [...adminData] };
		default:
			return state;
	}
}
