let days = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday'
];
function getFilterStaff(subId, subjectData = []) {
	let getPeriod = subjectData.filter(
		period => parseInt(period.subjectId) === parseInt(subId)
	);
	return getPeriod;
}

function changeEditDataStruct(days = days, subjectData, wholeStructure) {
	let getUpdatedData = days.map(day => {
		return {
			[day]: wholeStructure[day].map(dayItem => {
				return {
					...dayItem,
					staffDropDown: getFilterStaff(dayItem.subjectId, subjectData)
				};
			})
		};
	});

	let getProperStructure = {
		...getUpdatedData[0],
		...getUpdatedData[1],
		...getUpdatedData[2],
		...getUpdatedData[3],
		...getUpdatedData[4],
		...getUpdatedData[5],
		...getUpdatedData[6]
	};

	return getProperStructure;
}

function getIdFun(arrObj = []) {
	let subjectContainer = arrObj.map(item => item.subjectId);
	let excludedHiffen = subjectContainer.filter(item => item != '-');

	return subjectContainer;
}

function getStaffId(getArray) {
	let activeDaysData = days.filter(day => getArray[day].length > 0); // O(n) 6
	let activeData = activeDaysData.map(activeDay => getArray[activeDay]); // O(n) 6
	let getSubjectIds = activeData.map(Data => getIdFun(Data));
}

function getAllPeriodsDataWithDays(WD, AP) {
	let newPeriodData = [];
	WD.map((itemWD, indexWD) => {
		if (!itemWD.off) {
			AP.map((itemP, indexP) => {
				if (itemP.type === 'break') {
					newPeriodData.push({
						...itemP,
						dayName: itemWD.day,
						staffId: '-',
						subjectId: '-'
					});
				} else {
					newPeriodData.push({
						...itemP,
						dayName: itemWD.day,
						staffId: '',
						subjectId: '',
						staffDropDown: []
					});
				}
			});
		}
	});
	return newPeriodData;
}
let workingDays = [
	{ day: 'Monday', off: true },
	{ day: 'Tuesday', off: true },
	{ day: 'Wednesday', off: true },
	{ day: 'Thursday', off: true },
	{ day: 'Friday', off: true },
	{ day: 'Saturday', off: true },
	{ day: 'Sunday', off: true }
];
function modifyDays(daysClone, activeDays) {
	let arr = [];
	let found = false;
	for (let i = 0; i < daysClone.length; i++) {
		for (let j = 0; j < activeDays.length; j++) {
			if (daysClone[i].day === activeDays[j].dayName) {
				arr.push({ ...daysClone[i], off: false });
				found = true;
				break;
			} else {
				found = false;
			}
		}
		if (!found) {
			arr.push({ ...daysClone[i] });
		}
	}
	return arr;
}
let [day1, day2, day3, day4, day5, day6, day7] = days;
const allperiod = {};
function changeDataStructure(AllPeriods, day) {
	if (day === day1) {
		allperiod.Monday =
			AllPeriods && AllPeriods.filter(item => item.dayName === day);
	} else if (day === day2) {
		allperiod.Tuesday =
			AllPeriods && AllPeriods.filter(item => item.dayName === day);
	} else if (day === day3) {
		allperiod.Wednesday =
			AllPeriods && AllPeriods.filter(item => item.dayName === day);
	} else if (day === day4) {
		allperiod.Thursday =
			AllPeriods && AllPeriods.filter(item => item.dayName === day);
	} else if (day === day5) {
		allperiod.Friday =
			AllPeriods && AllPeriods.filter(item => item.dayName === day);
	} else if (day === day6) {
		allperiod.Saturday =
			AllPeriods && AllPeriods.filter(item => item.dayName === day);
	} else if (day === day7) {
		allperiod.Sunday =
			AllPeriods && AllPeriods.filter(item => item.dayName === day);
	}
	return allperiod;
}
let staticDaysArrays = [
	{ day: 'Sat' },
	{ day: 'Sun' },
	{ day: 'Mon' },
	{ day: 'Tue' },
	{ day: 'Wed' },
	{ day: 'Thu' },
	{ day: 'Fri' }
];
function getModifiedPeriodArray(
	noOfPeriod,
	periodArray = [],
	getRemainingTime,
	excludingBreak,
	selectedArray,
	secondCond,
	selectedArrayiND
) {
	let countPeriodType = periodArray.filter(item => item.type === 'Period ')
		.length;
	let getAverageTime = getRemainingTime / countPeriodType;
	console.log(getAverageTime);

	if (excludingBreak) {
		return periodArray.map(item => {
			if (item.type !== 'break') {
				return {
					...item,
					periodDuration: item.periodDuration + getAverageTime
				};
			} else {
				return { ...item, periodDuration: item.periodDuration };
			}
		});
	} else if (
		selectedArray.length < periodArray.length &&
		secondCond &&
		excludingBreak === null
	) {
		let getUpdatedArray = [...periodArray];
		let getupdated = selectedArrayiND.map((item, index) => {
			return (getUpdatedArray[item.ind] = selectedArray.filter(
				item2 => item2.name === item.name
			)[0]);
		});
		return getUpdatedArray;
	} else if (!excludingBreak) {
		return selectedArray.map(item => {
			return { ...item, periodDuration: item.periodDuration + getAverageTime };
		});
	}
}

function modificationOfIncludes(arr, CompareName, val) {
	let found = false;
	for (let i = 0; i < arr.length; i++) {
		if (arr[i][CompareName] === val) found = true;
		break;
	}
	return found;
}

function hasZero(arr = []) {
	if (arr.includes(0)) {
		return true;
	}
	return false;
}
function getRemainingTime(totalTime, timeAfterBreak) {
	if (isValidTime(timeAfterBreak, totalTime)) {
		let getRemainTime = parseInt(totalTime) - parseInt(timeAfterBreak);
		console.log('remaining time:', getRemainTime);
		return getRemainTime;
	}
	return null;
}
const isValidTime = (timeAfterAssignBreak, overAllTimeStamp) =>
	parseInt(overAllTimeStamp) >= parseInt(timeAfterAssignBreak) ? true : false;

function getTotalTime(arr = [], totalTimeStamp) {
	let finalTimeAfterBreakAss = 0;
	arr.map(item => (finalTimeAfterBreakAss += parseInt(item.periodDuration)));

	return finalTimeAfterBreakAss;
}
function throwUpdatedArr(realArr = [], updatedIndex = [], breakDuration = []) {
	let originalArr = [...realArr];
	updatedIndex.map((item, index) => {
		originalArr[item]['periodDuration'] = breakDuration[index];
		originalArr[item]['type'] = 'break';
	});
	return originalArr;
}
function getPerPeriodTiming(officialT, perPDiff, isParse = false) {
	if (isParse) {
		return parseInt(officialT / perPDiff);
	}
	return officialT / perPDiff;
}

function getUpdatedRemainingTime(
	officialT,
	perPDiff,
	noOfPeriod,
	getTotalTimeAfterBreak
) {
	let getDiffOfPeriodNoparse =
		getPerPeriodTiming(officialT, perPDiff) -
		getPerPeriodTiming(officialT, perPDiff, true);
	let getTotalPeriodTime = noOfPeriod * perPDiff;
	if (isValidTime(getTotalTimeAfterBreak.periodDuration, officialT)) {
		return officialT - getTotalPeriodTime + getDiffOfPeriodNoparse;
	} else {
		return null;
	}
}
function getPPTimingFromUpdateArr(arr = [], noOfPeriod) {
	let totalPeriodDuration = arr.reduce((a, b) => ({
		periodDuration: a.periodDuration + b.periodDuration
	}));
	return {
		totalPeriodDurationAfterBreak: totalPeriodDuration,
		perPUpdateTime: totalPeriodDuration.periodDuration / noOfPeriod
	};
}

function IsConditionValid1(getBreakTime, min = 900000, max = 1800000) {
	if (getBreakTime >= min && getBreakTime <= max) return true;

	return false;
}

// i need to work over here for validation of multiple breaks max and min time
function IsValidMinMaxPeriods(
	breakCollection = [],
	IsConditionValid = IsConditionValid1
) {
	let status = true;

	// status = breakCollection.map(item => {
	// 	if (!IsConditionValid(item.endTime - item.startTime)) {
	// 		return false;
	// 	}
	// });

	return false;
}

export default {
	getAllPeriodsDataWithDays,
	staticDaysArrays,
	days,
	getModifiedPeriodArray,
	modificationOfIncludes,
	changeDataStructure,
	hasZero,
	getRemainingTime,
	throwUpdatedArr,
	getTotalTime,
	getPPTimingFromUpdateArr,
	getUpdatedRemainingTime,
	IsValidMinMaxPeriods,
	getStaffId,
	modifyDays,
	changeEditDataStruct
};
