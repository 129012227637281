import React from 'react';
import CustomBadge from '../CustomBadge/CustomBadge';

export default function CustomFilterSectionLayout(props) {
	const { displayDirection = 'row' } = props;
	return (
		<div className="filter-section">
			<CustomBadge badgeText={'Filters'} badgeIconClass={'fas fa-filter'} />
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: displayDirection
				}}
			>
				{props.children}
			</div>
		</div>
	);
}
