import React, { Component, lazy, Suspense } from 'react';
import { Alert, Button, Form, Col, Container, Row } from 'react-bootstrap';
import background from '../images/sing2.png';
import axios from 'axios';
import config from '../config/config';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as LoginAcions from '../Actions/LoginActions/index';
import '../Container/signup.css';
import '../Container/signup';
const Home = ['Home 1', 'Home 2', 'Home 3'];
// const background = lazy(()=> import('../images/sing2.png'));
const styles = {
	header: {
		backgroundImage: `url(${background})`,
		height: '90vh',
		opacity: 0.7,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover'
	},
	content: {
		height: '100%',
		width: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)'
	}
};
const FormStyle = {
	//   boxShadow: "5px 5px 5px gray",
	padding: '50px'
};
const pStyle = {
	fontSize: '15px',
	textAlign: 'center'
};
const FormLabel = {
	color: '#fff',
	fontSize: 20
};
const FormInput = {
	width: '100%'
};
const BackgroundColor = {
	backgroundColor: 'rgba(0, 0, 0, 0.5)'
};

class SignUpForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			name: '',
			password: '',
			isLoading: false,
			errors: {}
		};
		if (this.props.auth) {
			this.props.history.push('/dashboard');
		}
	}
	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const { email, name, password } = this.state;
		this.setState({
			isLoading: true
		});
		this.props.actionLogin
			.Login({ email, name, password }, '/signup/api')
			.then(success => {
				console.log(success);

				this.setState({
					isLoading: false
				});
				this.props.history.push('/dashboard');
			})
			.catch(Err => {
				console.log(Err);

				this.setState({
					isLoading: false,
					errors: { errormsg: Err.response.data.errors.form, errorFlag: true }
				});
			});
	};
	displayMsg = (color, msg, flag) => {
		if (flag) {
			return <Alert variant={color}>{msg}</Alert>;
		}
	};
	componentWillUnmount() {
		this.setState({
			isLoading: false,
			errors: {}
		});
	}
	render() {
		const { errors } = this.state;
		return (
			<div class="wrapper">
				<nav id="sidebar">
					<div class="sidebar-header">
						<h3>Bootstrap Sidebar</h3>
					</div>
					<ul class="list-unstyled components">
						<p>Dummy Heading</p>
						<li class="active">
							<a
								href="#homeSubmenu"
								data-toggle="collapse"
								aria-expanded="false"
								class="dropdown-toggle"
							>
								Home
							</a>
							<ul class="collapse list-unstyled" id="homeSubmenu">
								<li>
									<a href="#">Home 1</a>
								</li>
								<li>
									<a href="#">Home 2</a>
								</li>
								<li>
									<a href="#">Home 3</a>
								</li>
							</ul>
						</li>
						<li>
							<a href="#">About</a>
						</li>
						<li>
							<a
								href="#pageSubmenu"
								data-toggle="collapse"
								aria-expanded="false"
								class="dropdown-toggle"
							>
								Pages
							</a>
							<ul class="collapse list-unstyled" id="pageSubmenu">
								<li>
									<a href="#">Page 1</a>
								</li>
								<li>
									<a href="#">Page 2</a>
								</li>
								<li>
									<a href="#">Page 3</a>
								</li>
							</ul>
						</li>
						<li>
							<a href="#">Portfolio</a>
						</li>
						<li>
							<a href="#">Contact</a>
						</li>
					</ul>

					<ul class="list-unstyled CTAs">
						<li>
							<a
								href="https://bootstrapious.com/tutorial/files/sidebar.zip"
								class="download"
							>
								Download source
							</a>
						</li>
						<li>
							<a
								href="https://bootstrapious.com/p/bootstrap-sidebar"
								class="article"
							>
								Back to article
							</a>
						</li>
					</ul>
				</nav>

				{/* <!-- Page Content  --> */}
				<div id="content">
					<nav class="navbar navbar-expand-lg navbar-light bg-light">
						<div class="container-fluid">
							<button type="button" id="sidebarCollapse" class="btn btn-info">
								<i class="fas fa-align-left"></i>
								<span>Toggle Sidebar</span>
							</button>
							<button
								class="btn btn-dark d-inline-block d-lg-none ml-auto"
								type="button"
								data-toggle="collapse"
								data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<i class="fas fa-align-justify"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarSupportedContent">
								<ul class="nav navbar-nav ml-auto">
									<li class="nav-item active">
										<a class="nav-link" href="#">
											Page
										</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" href="#">
											Page
										</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" href="#">
											Page
										</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" href="#">
											Page
										</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>

					<h2>Collapsible Sidebar Using Bootstrap 4</h2>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
						pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
						culpa qui officia deserunt mollit anim id est laborum.
					</p>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
						pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
						culpa qui officia deserunt mollit anim id est laborum.
					</p>

					<div class="line"></div>

					<h2>Lorem Ipsum Dolor</h2>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
						pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
						culpa qui officia deserunt mollit anim id est laborum.
					</p>

					<div class="line"></div>

					<h2>Lorem Ipsum Dolor</h2>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
						pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
						culpa qui officia deserunt mollit anim id est laborum.
					</p>

					<div class="line"></div>

					<h3>Lorem Ipsum Dolor</h3>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
						pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
						culpa qui officia deserunt mollit anim id est laborum.
					</p>
				</div>
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		actionLogin: bindActionCreators(LoginAcions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignUpForm);
