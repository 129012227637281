import React, { Component } from 'react';

class StaffSiblingsPrint extends Component {
	state = {};
	render() {
		const { Document } = this.props;
		console.log(Document, 'Siblings Data');
		return (
			<div>
				<div id="div1">
					<section class="mb-4">
						<div class="container">
							<div class="row rowStaffCheck" style={{ marginTop: 10 }}>
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>Sibling Name: </label>{' '}
									&nbsp;&nbsp;
									{Document && Document.siblingName}
								</div>
								<div class="col colStaffCheck">
									{' '}
									<label style={{ fontWeight: 'bold' }}>Sibling DOB:</label>
									&nbsp;&nbsp; {Document && Document.siblingDob}
								</div>
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>
										Sibling Relation:
									</label>
									&nbsp;&nbsp; {Document && Document.siblingRelation}
								</div>
							</div>
							<div class="row rowStaffCheck">
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>Passport No:</label>
									&nbsp;&nbsp; {Document && Document.siblingPassportNo}{' '}
								</div>
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>
										Passport Issue Date:
									</label>
									&nbsp;&nbsp; {Document && Document.siblingpassDOI}{' '}
								</div>
							</div>
							<div class="row rowStaffCheck">
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>
										Passport Expiry Date:
									</label>
									&nbsp;&nbsp; {Document && Document.siblingpassDOE}{' '}
								</div>
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>ID Card Number:</label>
									&nbsp;&nbsp; {Document && Document.siblingIdcardno}{' '}
								</div>
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>Card Issue Date:</label>
									&nbsp;&nbsp; {Document && Document.siblingIdcardDOI}{' '}
								</div>
							</div>
							<div class="row rowStaffCheck">
								<div class="col colStaffCheck">
									<label style={{ fontWeight: 'bold' }}>
										Card Expiry Date:
									</label>
									&nbsp;&nbsp; {Document && Document.siblingIdcardDOE}{' '}
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}

export default StaffSiblingsPrint;
