import React from 'react';

export default function CustomBadge(props) {
	const {
		badgeText,
		badgeIconClass,
		bottom = 20,
		left = 5,
		top,
		fontSize = 18
	} = props;
	return (
		<span
			style={{ position: 'relative', bottom, left, top, fontSize }}
			className="custom-badge-filter newPrintCss"
		>
			<i class={badgeIconClass}></i>
			{badgeText}
		</span>
	);
}
