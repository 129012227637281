class Colors {
	static LIGHT_GREEN = '#4C9A2A';
	static BLACK = '#000';
	static DARK_GREY = '#A9A9A9';
	static DISABLE_GREEN = '#a6f1a6';
	static DISABLE_GREY = '	#D3D3D3';
	static RED_DANGER = '#FF0000';
	static SEA_GREEN_THEME = '#01AC8A';
	static WHITE = '#fff';
	static DARK_SEA_GREEN_THEME = '#01425E';
}
export default Colors;
