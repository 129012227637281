import React from 'react';
import LayoutWrapper from '../../../components/HOC/LayoutWrapper';

const StepperContent = props => {
	const { onSubmit, error } = props;
	return (
		<div style={{ width: '-webkit-fill-available' }}>
			<div>
				<div style={{ alignSelf: 'center' }} class="bs-stepper-content">
					<form action={'javascript:void(0)'}>
						{error ? (
							<p style={{ color: 'red' }}>Please fill the Form Properly!</p>
						) : (
							''
						)}
						{props.children}
					</form>
				</div>
			</div>
		</div>
	);
};

export default StepperContent;
