import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import LoaderWrapper from '../../../components/HOC/LoaderWrapper';
import SnackBar from '../../../components/SnackBar/SnackBar';
import ViewComponent from '../../../components/ViewComponent/ViewComponent';
import useCustomHttpWithDataMiddleware from '../../../hooks/Services/httpWithCustomDataMiddleware';
import useHttp from '../../../hooks/http';

import {
	addDataMiddleware,
	viewClassReturn,
	disableColorOverCondition
} from '../../../utils/general/index';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';

function ParentStudentDiscipline(props) {
	const [snackbarMessage, setMsg] = useState('');
	const [studentData, setStudentData] = useState('');
	debugger;
	const [classId, setClassId] = useState('');
	const [sectionId, setSectionId] = useState('');
	const [studentId, setStudentId] = useState('');

	const [subjectId, setSubjectId] = useState('');
	const [sessionId, setSessionId] = useState('');
	const [term, setTerm] = useState('');
	const [customLoader, setCustomLoader] = useState(false);
	var [isLoading, fetchDropData] = useHttp(
		`/api/GetChildByParent?userName=${props.name}`,
		[]
	);

	var [isLoader, fetchData, setFetchData] = useCustomHttpWithDataMiddleware(
		`/api/GetChildByParent?userName=${props.name}`,
		[],
		{
			fields: [
				{
					type: 'select',
					selectName: 'Choose Term',
					feildName: 'term',
					optionsArray: ['1st Term', '2nd Term', '3rd Term'],
					optionType: 'static',
					errors: {}
				}
			],
			Action: [
				{
					term: '',
					isEnable: false,
					type: 'actionLink',
					title: 'View',
					initialPath: '/dashboard/StudentRoleDiscipline',
					iconName: 'fas fa-list',
					iconColor: '#01AC8A'
				}
			]
		}
	);
	debugger;

	const onSetTerm = (e, rowIndex, feildName, mainData) => {
		debugger;
		const getCloneData = JSON.parse(JSON.stringify(studentData));
		debugger;
		if (e.target.value == 'Choose Term') {
			setTerm('');
			debugger;
			getCloneData[rowIndex]['Action'][0]['isEnable'] = false;

			getCloneData[rowIndex]['Action'][0]['term'] = e.target.value;
			debugger;
			setStudentData(getCloneData);
		} else {
			setTerm(e.target.value);
			debugger;

			getCloneData[rowIndex]['Action'][0]['isEnable'] = true;
			getCloneData[rowIndex]['Action'][0]['term'] = e.target.value;

			debugger;
			setStudentData(getCloneData);
			debugger;
		}
	};

	const onSearch = () => {
		setCustomLoader(true);
		props.sharedAction
			.getDataWithoutDispatch(
				`/api/StudentListDisciplinary?classId=${classId}&sectionId=${sectionId}&term=${term}&subjectId=${subjectId}&sessionId=${sessionId}`
			)
			.then(success => {
				debugger;
				let requiredData = addDataMiddleware(
					{
						marks: '',
						classId,
						sessionId,
						subjectId,
						sectionId,
						term,
						accountId: props.name,
						accountType: props.role
					},
					success
				);
				setStudentData(requiredData);
				setCustomLoader(false);
			})
			.catch(error => {
				debugger;
				console.log(error);
				if (error && error.response && error.response.status == 404) {
					// 404-Error no understanding of 404 Error
					setStudentData([]);

					setMsg('404 Not Found');
					props.snackbar();
					setCustomLoader(false);
				}
				if (error && error.response && error.response.status == 400) {
					setStudentData([]);
					console.log(error);
					debugger;
					setMsg(error.response.data.Message);
					props.snackbar();

					setCustomLoader(false);
				}
			});
	};

	const onSubmit = () => {
		props.sharedAction
			.simpleAddRequest(`/api/DisciplinaryRecord`, {
				DisciplinaryRecord: [...studentData]
			})
			.then(success => {
				setTerm('Choose Term');
				setSectionId('');
				setClassId('');
				setSubjectId('');
				setSessionId('');
				setStudentData([]);
				setMsg('Successfully Submitted');
				props.snackbar();
			})
			.catch(error => {
				if (error && error.response && error.response.status == 404) {
					//404-Error no understanding of 404 Error
					setMsg('404 Not Found');

					props.snackbar();
				}
				debugger;
			});
	};
	const onStudentChange = e => {
		if (e.target.value === 'Select Child') {
			setStudentId('');
		} else {
			setCustomLoader(true);
			setStudentId(e.target.value);
			props.sharedAction
				.getDataWithoutDispatch(`/api/StudentDetail?userName=${e.target.value}`)
				.then(success => {
					let requiredData = addDataMiddleware(
						{
							fields: [
								{
									type: 'select',
									selectName: 'Choose Term',
									feildName: 'term',
									optionsArray: ['1st Term', '2nd Term', '3rd Term'],
									optionType: 'static',
									errors: {}
								}
							],
							Action: [
								{
									term: '',
									isEnable: false,
									exceptionCase: true,
									type: 'actionLink',
									title: 'View',
									initialPath: '/dashboard/ParentStudentDiscipline',
									iconName: 'fas fa-list',
									iconColor: '#01AC8A'
								}
							]
						},
						success
					);

					setStudentData(requiredData);

					setCustomLoader(false);
				})
				.catch(error => {
					console.log(error);
					if (error && error.response && error.response.status == 404) {
						// 404-Error no understanding of 404 Error
						setStudentData([]);

						setMsg('404 Not Found');
						props.snackbar();
						setCustomLoader(false);
					}
					if (error && error.response && error.response.status == 400) {
						setStudentData([]);
						console.log(error);

						setMsg(error.response.data.Message);
						props.snackbar();

						setCustomLoader(false);
					}
				});
		}
	};
	return (
		<div>
			<h2 className="text-center generalHead">Student Discipline</h2>
			<SnackBar backColor={'#000'} msg={snackbarMessage} />

			<LoaderWrapper marginTop={20} isLoading={isLoader ? true : false}>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<SelectionInput
						// newFlag={'customWidth'}
						feildName="studentId"
						selectName={'Choose your child'}
						selectedText="Select Child"
						isValidte={false}
						optionsArrys={
							fetchDropData && fetchDropData.length < 1 ? [] : fetchDropData
						}
						errors={{}}
						stateData={{
							studentId: studentId
						}}
						optionType="dynamic"
						onHandleChange={onStudentChange}
						property={'studentName'}
						propertyId={'studentUserName'}
						useFlag={false}
					/>
				</div>
				<br />
				<LoaderWrapper marginTop={20} isLoading={customLoader}>
					<ViewComponent
						exceptionalHandler={viewClassReturn}
						conditionalIconsColor={disableColorOverCondition}
						mainData={studentData}
						headData={[
							'S #',
							'Student Name',
							'Class Name',
							'Section',
							'Session',
							'Status',
							'Term',
							'Actions'
						]}
						extraRedirectProperty={'term'}
						excludedData={[
							'studentId',
							'userName',
							'classId',
							'sectionId',
							'sessionId'
						]}
						extraRedirectProperty="term"
						onSetTerm={onSetTerm}
						redirectIds={[
							'studentId',
							'classId',
							'sectionId',
							'sessionId',
							'term'
						]}
					/>
				</LoaderWrapper>
			</LoaderWrapper>
		</div>
	);
}
function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ParentStudentDiscipline);
