import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';

const useCustomHttp = (url, dispatch, Type, payloadName, dependencies) => {
	const [isLoading, setIsLoading] = useState(true);
	const [fetchData, setFetchData] = useState(null);

	useEffect(() => {
		let token = '';
		if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
		axios
			.get(`${config.localhttp}${url}`, {
				headers: { Authorization: `Bearer  ${token}` }
			})
			.then(res => {
				console.log('hook data get: ', res);
				console.log(res.data);
				setIsLoading(false);
				setFetchData(res.data);

				dispatch({
					type: Type,
					payload: { [payloadName]: res.data }
				});
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
			});
	}, dependencies);

	return [isLoading, fetchData, setFetchData, setIsLoading];
};
export default useCustomHttp;
