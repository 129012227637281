import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	select: [],
	sessionSelect: []
};

export default function setQuizInfoReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.SET_ALL_CLASS_NAME_INTO_QUIZ_INFO_SELECT:
			return {
				...state,
				select: action.payload
			};
		case actionTypes.SET_ALL_SESSION_NAME_INTO_QUIZ_INFO_SELECT:
			return {
				...state,
				sessionSelect: action.payload
			};

		case actionTypes.GET_ALL_QUIZ_INFO:
			return { ...state, Data: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_QUIZ_INFO:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_QUIZ_INFO:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.id !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_QUIZ_INFO_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.id === action.payload.id);
			adminData[index].quizName = action.payload.quizName;
			adminData[index].quizTime = action.payload.quizTime;
			adminData[index].quizDate = action.payload.quizDate;
			adminData[index].totalMarks = action.payload.totalMarks;
			adminData[index].className = action.payload.className;
			adminData[index].sectionName = action.payload.sectionName;
			adminData[index].className = action.payload.className;
			adminData[index].StaffName = action.payload.staffName;
			adminData[index].sessionName = action.payload.sessionName;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
