import React, { Component } from 'react';
import DateandTime from './../Transport/DateandTime';

import GeneralRadioOptionSelection from '../../components/Admission/GeneralRadioOptionSelection';
export default class MedicalCondition extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gridNum: 4
		};
	}
	render() {
		const { onHandleTextChange, stateData } = this.props;
		return (
			<div>
				<section class="mb-6">
					<DateandTime />
					<div
						class="row"
						style={{
							justifyContent: 'left',
							textAlign: 'left'
						}}
					>
						<div class="col-md-12 mb-md-0 mb-5">
							<form
								id="contact-form"
								name="contact-form"
								// onSubmit={this.onHandleSubmit}
								noValidate
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">{/*<DateandTime/>*/}</div>
									</div>
								</div>
								<br />

								<div class="col">
									<GeneralRadioOptionSelection
										onHandleTextChange={onHandleTextChange}
										stateData={stateData}
										Name="Threat Conditon:"
										idForYes="customRadiothreCY"
										idForNo="customRadiodobthreCN"
										feildName="threatConditionReason"
									/>
									{stateData.threatConditionReason === 'Yes' ? (
										<div class="md-form mb-0">
											<textarea
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '60%'
												}}
												onChange={e => onHandleTextChange(e)}
												type="text"
												value={stateData.threatCondition}
												name={'threatCondition'}
												class="form-control"
												id="threadCondition"
												placeholder="Threat Conditon"
											></textarea>
										</div>
									) : stateData.threatConditionReason === 'No' ? (
										<p></p>
									) : (
										''
									)}
									<GeneralRadioOptionSelection
										onHandleTextChange={onHandleTextChange}
										stateData={stateData}
										Name="Need Medication:"
										idForYes="customRadioNMY"
										idForNo="customRadiodobthreNMN"
										feildName="needMedicationReason"
									/>
									{stateData.needMedicationReason === 'Yes' ? (
										<React.Fragment>
											<div class="md-form mb-0">
												<textarea
													style={{
														borderTop: 0,
														borderLeft: 0,
														borderRight: 0,
														boxShadow: 'none',
														width: '60%'
													}}
													onChange={e => onHandleTextChange(e)}
													value={stateData.needMedication}
													name={'needMedication'}
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													placeholder="Need Medication"
												></textarea>
											</div>
										</React.Fragment>
									) : stateData.needMedicationReason === 'No' ? (
										<p></p>
									) : (
										''
									)}

									<GeneralRadioOptionSelection
										onHandleTextChange={onHandleTextChange}
										stateData={stateData}
										Name="Other Medication:"
										idForYes="customRadioOMY"
										idForNo="customRadiodobOMN"
										feildName="otherMedicationReason"
									/>
									{stateData.otherMedicationReason === 'Yes' ? (
										<div class="md-form mb-0">
											<textarea
												style={{
													borderTop: 0,
													borderLeft: 0,
													borderRight: 0,
													boxShadow: 'none',
													width: '60%'
												}}
												onChange={e => onHandleTextChange(e)}
												name={'otherMedication'}
												value={stateData.otherMedication}
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												placeholder="Other Medication"
											></textarea>
										</div>
									) : stateData.otherMedicationReason === 'No' ? (
										<p></p>
									) : (
										''
									)}
								</div>
								<br />
								{/*<div class="text-center text-md-center">*/}
								{/*<button class="btns">Add</button>*/}
								{/*</div>*/}
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}
