import React from 'react';
import TimePicker from '../../../components/TimePicker';
import moment from 'moment';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
const format = 'h:mm a';

const now = moment()
	.hour(0)
	.minute(0);

{
	/* <React.Fragment>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexWrap: 'wrap'
						}}
					>
						<div>
					<span style={{fontSize:16}} class="badge badge-warning">Break {index + 1 }</span>
						
						</div>
						<div style={{width:'40%'}} >
							<TimePicker
								className="specialTPicker"
								showSecond={false}
								placeholder={'Select Start Time'}
								name="BsTime"
								defaultValue={null}
								onChange={e => props.onChange(e, 'BsTime')}
								format={format}
								use12Hours
								// inputReadOnly
							/>
						</div>
						<div style={{width:'40%'}} >
							<TimePicker
								className="specialTPicker"
								showSecond={false}
								placeholder={'Select End Time'}
								name="BeTime"
								defaultValue={null}
								onChange={e => props.onChange(e, 'BeTime')}
								format={format}
								use12Hours
								// inputReadOnly
							/>
						</div>
						<div style={{ display: 'flex', marginBottom: 20 , marginTop:10 }}>
							<SelectionInput
								selectFont={17}
								isIcon={false}
								// selectName=""
								useFlag={true}
								flag={true}
								isValidte={false}
								feildName={item.periodNum}
								selectedText="Choose Break"
								optionsArrys={getBreakNumber}
								errors={{}}
								stateData={props.stateData}
								optionType="static"
								onHandleChange={e => onChangeText(e,index)}
							
							/>
						</div>
					</div>
					<hr size="30"/>
				</React.Fragment> */
}
function BreakObjRendering({
	obj,
	onChangeText,
	getBreakNumber,
	rowIndex,
	onChange
}) {
	let keys = Object.keys(obj);
	// console.log(stateData.siblingsDetails[0]['age'])
	if (keys.includes('type')) {
		let newKeys = keys.filter(item => item !== 'type');
		keys = [...newKeys];
	}
	console.log(keys);
	return (
		<React.Fragment>
			{keys.map((item, index) =>
				item === 'startTime' || item === 'endTime' ? (
					<td style={{ padding: 10 }}>
						<TimePicker
							className="specialTPicker"
							showSecond={false}
							placeholder={item}
							name={item}
							defaultValue={null}
							onChange={e => onChange(e, item, rowIndex)}
							format={format}
							use12Hours
							// inputReadOnly
						/>
					</td>
				) : item === 'periodNum' ? (
					<td style={{ padding: 10 }}>
						<SelectionInput
							selectFont={17}
							isIcon={false}
							// selectName=""
							useFlag={true}
							flag={true}
							isValidte={false}
							feildName={item}
							selectedText="Choose Break"
							optionsArrys={getBreakNumber}
							errors={{}}
							stateData={{}}
							optionType="static"
							onHandleChange={e => onChangeText(e, rowIndex)}
						/>
					</td>
				) : (
					''
				)
			)}
		</React.Fragment>
	);
}

export default BreakObjRendering;
