import React from 'react';

export default function StudentListAdmin(props) {
	const { stateData, openReport, viewFunction } = props;
	return (
		<div>
			<div class="animated fadeInDown">
				<div class="row">
					<div class="col-md-6">
						<h5 class="h5-responsive font-weight-bold text-left">
							Select Student:
						</h5>
					</div>
					<div class="col-md-6 text-right">
						<button class="buttonHover" onClick={e => openReport(e)}>
							<i class="fas fa-clipboard" style={{ marginRight: 5 }}></i> View
							Disciplinary Record
						</button>
					</div>
				</div>
				<br />

				<div style={{ width: '100%' }}>
					<div class="table-responsive">
						<table class="table table-hover text-left">
							<thead style={{ background: '#01AC8A', color: 'white' }}>
								<tr>
									<th class="text-center">#</th>
									<th>Student Name</th>
									<th class="text-center">Actions</th>
								</tr>
							</thead>
							<tbody>
								{stateData &&
									stateData.ClassDiscipline.map((item, index) => (
										<tr key={index}>
											<th class="text-center">{index + 1}</th>
											<th>{item.studentName}</th>
											<th class="text-center">
												<a
													href="#"
													data-toggle="tooltip"
													title="View Discipline"
												>
													<button
														onClick={e =>
															viewFunction(e, item.studentId, item.studentName)
														}
														class="buttonHover"
													>
														<i class="fas fa-edit"> </i>
													</button>
												</a>
											</th>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
				<br />
				<br />
				<br />
			</div>
		</div>
	);
}
