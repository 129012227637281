import React, { Component } from 'react';
import './Form.css';
import StudentAddress from './StudentAddress';
import StudentParentInfo from './StudentParentInfo';
import StudentDocuments from './StudentDocuments';
import StudentParentDocument from './StudentParentDocument';
import StudentMedicalCondition from './StudentMedicalCondition';
import StudentSiblings from './StudentSiblings';
import StudentPreviousSchoolDetail from './StudentPreviousSchoolDetail';
import StudentWithdrawDetails from './StudentWithdrawDetails';
import StudentOtherInformation from './StudentOtherInformation';
import StudentTransport from './StudentTransport';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as addActions from '../../Actions/Admission';
import * as sharedActions from '../../Actions/sharedActions';
import * as Generalfunction from '../../utils/general/index';
class PrintAdmissionFormFinal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imagePreviewUrl:
				'http://ctt.trains.com/sitefiles/images/no-preview-available.png',
			getSingleAddData: [],
			gotData: false,
			images: '',
			imageData: ''
		};
	}
	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};
	conversionOfImageUrl = url => {
		let getSplitResult = url.split('\\');
		let result = '';
		for (let i = 4; i < getSplitResult.length; i++) {
			if (getSplitResult.length - 1 == i) result += getSplitResult[i];
			else result += getSplitResult[i].concat('\\\\');
		}
		let finalResult = 'http://'.concat(`${result}`);
		return finalResult;
	};
	componentDidMount() {
		const { match } = this.props;
		this.props.shareAction
			.gAllData(
				`/api/StudentAdmissionForm/${match.params.id}`,
				this.props.addAction.getIndividualStudentData
			)
			.then(success => {
				console.log(this.state.getSingleAddData.StudentAdmissionForms);
				this.setState({
					isLoader: false,
					imagePreviewUrl: Generalfunction.newconversionOfImageUrl(
						this.state.getSingleAddData &&
							this.state.getSingleAddData.StudentImageUpload.filePath
					)
				});
				console.log(success);
			})
			.catch(error => {
				this.setState({
					isLoader: false
				});
			});
		this.setState({
			// images: this.conversionOfImageUrl(this.state.getSingleAddData && this.state.getSingleAddData.StudentImageUpload.filePath)
		});
		// let imageData = {
		//   imagePreviewUrl: this.conversionOfImageUrl(this.state.getSingleAddData && this.state.getSingleAddData.StudentImageUpload.filePath),
		// };
		// console.log(imageData.imagePreviewUrl, "URL")
		// this.setState({
		//   images: imageData.imagePreviewUrl
		// })
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.getSingleAddData !== nextProps.getData) {
			return {
				getSingleAddData: nextProps.getData
			};
		}

		return null;
	}
	render() {
		const studentImage =
			this.state.getSingleAddData &&
			this.state.getSingleAddData.StudentImageUpload.filePath;
		console.log(studentImage, 'File Path Agayaa');
		return (
			<div>
				<div id="div1">
					<section class="mb-4">
						<div class="divclass">
							<img src={require('../../Assets/images/3 headers-03.jpg')} />
						</div>
						<br />
						<div class="row print">
							<div class="col-md-12">
								<div class="md-form mb-0">
									<h2
										style={{
											backgroundColor: '#0A4F5E',
											textAlign: 'center',
											color: 'white'
										}}
									>
										Student Admission Form
									</h2>
								</div>
							</div>
						</div>

						<div class="container wow">
							<div class="row" style={{ float: 'right' }}>
								<div class="col-md-4">
									<div class="md-form mb-0">
										<div>
											<img
												src={this.state.imagePreviewUrl}
												alt="..."
												height={220}
												width={200}
												class="rounded float-left"
												style={{ border: 'solid' }}
											></img>
										</div>
									</div>
								</div>
							</div>
							<div class="row rowCheck" style={{ marginTop: 10 }}>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>
										Registration No:{' '}
									</label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms
											.registrationNo}
								</div>
								<div class="col colCheck">
									{' '}
									<label style={{ fontWeight: 'bold' }}>Admission:</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms
											.admissionNo}
								</div>
								<div class="col colCheck">
									{' '}
									<label style={{ fontWeight: 'bold' }}>Roll No: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.rollNo}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>First Name:</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.firstName}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Last Name:</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms
											.lastName}{' '}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Father Name: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms
											.fatherName}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Admission Type: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms
											.admissionType}{' '}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Gender: </label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.gender}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Current Session:</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms
											.currentSession}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Date Of Birth </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.dob}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Class Name</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.className}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Nationality: </label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms
											.nationality}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Language:</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.language}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Age:</label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.age}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>
										Agent Name / Reference:{' '}
									</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.AgentName.agentName}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Place Of Birth :</label>
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms
											.placeOfBirth}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Religion: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.religion}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Grade Name: </label>
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.CampusGrade.gradeName}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Campus Name: </label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.CampusGrade.campusName}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Section: </label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.section}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Username: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.userName}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Password: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.password}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Account Name:</label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.accountId}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Account Type: </label>
									&nbsp;&nbsp;{' '}
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms
											.accountType}
								</div>
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Result Status:</label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms
											.resultStatus}
								</div>
							</div>
							<div class="row rowCheck">
								<div class="col colCheck">
									<label style={{ fontWeight: 'bold' }}>Status: </label>{' '}
									&nbsp;&nbsp;
									{this.state.getSingleAddData &&
										this.state.getSingleAddData.StudentAdmissionForms.status}
								</div>
							</div>
						</div>
						<br />
						<StudentAddress
							data={
								this.state.getSingleAddData &&
								this.state.getSingleAddData.StudentAddresss
							}
						/>

						<StudentParentInfo
							parentData={
								this.state.getSingleAddData &&
								this.state.getSingleAddData.ParentInfos
							}
						/>
						<div class="hojaa">
							<StudentDocuments
								studentDocs={
									this.state.getSingleAddData &&
									this.state.getSingleAddData.StudentDocuments
								}
							/>
						</div>
						<StudentParentDocument
							parentDocs={
								this.state.getSingleAddData &&
								this.state.getSingleAddData.ParentDocuments
							}
						/>
						<StudentMedicalCondition
							medicalCondition={
								this.state.getSingleAddData &&
								this.state.getSingleAddData.MedicalConditions
							}
						/>

						<div class="row print">
							<div class="col-md-12">
								<div class="md-form mb-0">
									<h2
										style={{
											backgroundColor: '#0A4F5E',
											textAlign: 'center',
											color: 'white'
										}}
									>
										Student Siblings:
									</h2>
								</div>
							</div>
						</div>

						{this.state.getSingleAddData &&
							this.state.getSingleAddData.StudentSiblingDetails.map(
								(item, index) => {
									return <StudentSiblings siblingData={item} />;
								}
							)}
						<div class="row print">
							<div class="col-md-12">
								<div class="md-form mb-0">
									<h2
										style={{
											backgroundColor: '#0A4F5E',
											textAlign: 'center',
											color: 'white'
										}}
									>
										Previous School Details:
									</h2>
								</div>
							</div>
						</div>
						{this.state.getSingleAddData &&
							this.state.getSingleAddData.PreviousSchoolDetails.map(
								(items, index) => {
									return <StudentPreviousSchoolDetail prevSchool={items} />;
								}
							)}
						<StudentWithdrawDetails
							widthDraw={
								this.state.getSingleAddData &&
								this.state.getSingleAddData.WidthdrawDetails
							}
						/>
						<StudentOtherInformation
							otherInformation={
								this.state.getSingleAddData &&
								this.state.getSingleAddData.OtherInformations
							}
						/>
						<div class="hojaa">
							<StudentTransport
								studentTransport={
									this.state.getSingleAddData &&
									this.state.getSingleAddData.StudentTransports
								}
								transportDriver={
									this.state.getSingleAddData &&
									this.state.getSingleAddData.DriverName
								}
								routeTransport={
									this.state.getSingleAddData &&
									this.state.getSingleAddData.RoutesName
								}
							/>
						</div>
					</section>
					<div id="footer">
						<div class="h1-responsive font-weight-bold text-center my-4">
							<img
								src={require('../../Assets/images/Powerd-01.svg')}
								style={{ width: 200 }}
							/>
						</div>
					</div>
				</div>
				<div class="text-center text-md-right">
					<label for="name" class="" onClick={() => this.PrintContent('div1')}>
						<i
							className="fas fa-print fa-2x animated fadeIn"
							style={{ marginRight: 4 }}
						/>
						Print
					</label>
				</div>
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		addAction: bindActionCreators(addActions, dispatch),
		shareAction: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		getData: state.setAdmissionReducer.getSingleFormData
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintAdmissionFormFinal);
