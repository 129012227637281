import React, { Component } from 'react';
import './Form.css';

class StudentOtherInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { otherInformation } = this.props;
		console.log(otherInformation, 'Other Information Ka Data Haii');
		return (
			<div>
				<section class="mb-4">
					<div class="row print">
						<div class="col-md-12">
							<div class="md-form mb-0">
								<h2
									style={{
										backgroundColor: '#0A4F5E',
										textAlign: 'center',
										color: 'white'
									}}
								>
									Other Information:
								</h2>
							</div>
						</div>
					</div>
					<div class="container">
						<div class="row rowCheck" style={{ marginTop: 10 }}>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Sports: </label>
								&nbsp;&nbsp;{otherInformation && otherInformation.sports}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Other Activity:</label>{' '}
								&nbsp;&nbsp;{otherInformation && otherInformation.otherActivity}
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default StudentOtherInformation;
