import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import Axios from 'axios/index';
import config from '../../config/config';
import { connect } from 'react-redux';
import DateandTime from '../Transport/DateandTime';

class AddSuppliers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			contactNo: '',
			address: '',
			description: '',
			valid: false,
			msg: false,
			errorMsg: false
		};
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onNumber = e => {
		const re = /^[0-9\b]+$/;

		// if value is not blank, then test the regex
		if (e.target.value === '' || re.test(e.target.value)) {
			this.setState({ [e.target.name]: e.target.value });
			console.log(this.state.ContactNo);
		}
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const { name, address, description, contactNo } = this.state;
		if (
			Object.is(name, '') ||
			Object.is(address, '') ||
			Object.is(description, '') ||
			Object.is(contactNo, '')
		) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			let token = '';
			if (localStorage.jwtToken) {
				token = localStorage.getItem('jwtToken');
			}
			var Headconfig = {
				headers: { Authorization: 'Bearer ' + token }
			};
			var bodyParameters = {
				name: this.state.name,
				contactNo: this.state.contactNo,
				description: this.state.description,
				address: this.state.address,
				accountId: this.props.data.unique_name,
				accountType: this.props.data.role
			};
			Axios.post(
				`${config.localhttp}/api/Suppliers`,
				bodyParameters,
				Headconfig
			)
				.then(response => {
					console.log(response);

					if (response.status == 200) {
						this.setState({
							name: '',
							contactNo: '',
							description: '',
							address: '',
							msg: true
						});
					}
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code

						// that falls out of the range of 2xx
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received

						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
				});
		}
	};
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};

	render() {
		return (
			<div>
				<Prompt
					when={this.state.valid !== true}
					message="Leaving this form will lose your data"
				/>
				<div className="container">
					<section class="mb-4">
						<h2 class="h1-responsive font-weight-bold text-center my-4">
							Add Suppliers
						</h2>

						<p class="text-center w-responsive mx-auto mb-5" />

						<div
							class="row"
							style={{
								justifyContent: 'center',
								textAlign: 'center'
							}}
						>
							<div
								class="col-md-9 mb-md-0 mb-5"
								style={{
									border: 'solid',
									borderColor: '#01ac8a',
									padding: 40,
									borderRadius: 15,
									width: 'auto'
								}}
							>
								{this.Message(
									'Supplier Add Successfully!',
									'success',
									20,
									this.state.msg
								)}
								{this.Message(
									'Please fill the form properly!',
									'danger',
									20,
									this.state.errorMsg
								)}
								<form
									onSubmit={this.onHandleSubmit}
									id="contact-form"
									name="contact-form"
								>
									<div class="row">
										<div class="col-md-12">
											<div class="md-form mb-0">
												<DateandTime />
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-6">
											<div class="md-form mb-0">
												<label for="name" class="">
													<i
														className="fas fa-user-shield animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Admin Name
												</label>
												<input
													readOnly={true}
													value={this.props.data.unique_name}
													type="text"
													name="AdminName"
													class="form-control"
												/>
											</div>
										</div>

										<div class="col-md-6">
											<div class="md-form mb-0">
												<label for="name" class="">
													<i
														className="fas fa-user-alt animated fadeIn"
														style={{ marginRight: 4 }}
													/>
													Admin Type
												</label>
												<input
													value={this.props.data.role}
													type="text"
													readOnly={true}
													name="AdminType"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													<i
														className="fas fa-luggage-cart"
														style={{ marginRight: 4 }}
													/>
													Suppliers Name
												</label>
												<input
													type="text"
													value={this.state.name}
													placeholder="Name"
													name="name"
													onChange={this.onHandleTextChange}
													class="form-control"
												/>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													<i
														className="fas fa-phone"
														style={{ marginRight: 4 }}
													/>
													Contact No
												</label>
												<input
													type="text"
													value={this.state.contactNo}
													placeholder="Contact No"
													name="contactNo"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													<i
														className="fas fa-address-card"
														style={{ marginRight: 4 }}
													/>
													Address
												</label>
												<input
													type="text"
													value={this.state.address}
													placeholder="Address"
													name="address"
													onChange={this.onHandleTextChange}
													class="form-control"
												/>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="">
													<i
														className="fas fa-comment"
														style={{ marginRight: 4 }}
													/>
													Description
												</label>
												<textarea
													type="text"
													value={this.state.description}
													placeholder="Description"
													onChange={this.onHandleTextChange}
													name="description"
													class="form-control"
												></textarea>
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="text-center text-md-center">
										<button type="submit" class="btns">
											Add
										</button>
									</div>
								</form>

								<div class="status" />
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		data: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	null
)(AddSuppliers);
