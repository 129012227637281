import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StudentEnquiryActions from '../../Actions/StudentEnquiry';
import * as sharedActions from '../../Actions/sharedActions';
import ClassComponent from './ClassComponent';

class StudentEnquiry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			accountId: '',
			accountType: '',
			applicantName: '',
			fatherName: '',
			motherName: '',
			address: '',
			city: '',
			state: '',
			country: '',
			emailId: '',
			postalCode: '',
			dob: '',
			phoneNo: '',
			mobileNo: '',
			status: '',
			classId: '',
			sectionId: '',
			previousAcademics: '',
			referenceType: '',
			referenceName: '',
			details: '',
			select: '',
			errorMsg: false,
			msg: false,
			sections: []
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.select !== nextProps.selectData) {
			return {
				select: nextProps.selectData
			};
		}
		return null;
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const {
			applicantName,
			fatherName,
			motherName,
			mobileNo,
			address,
			city,
			state,
			country,
			emailId,
			postalCode,
			dob,
			phoneNo,
			status,
			classId,
			sectionId,
			previousAcademics,
			referenceType,
			referenceName,
			details
		} = this.state;
		if (
			Object.is(applicantName, '') ||
			Object.is(fatherName, '') ||
			Object.is(motherName, '') ||
			Object.is(address, '') ||
			Object.is(city, '') ||
			Object.is(state, '') ||
			Object.is(phoneNo, '') ||
			Object.is(mobileNo, '') ||
			Object.is(details, '') ||
			Object.is(country, '') ||
			Object.is(postalCode, '') ||
			Object.is(emailId, '') ||
			Object.is(status, '') ||
			Object.is(referenceName, '') ||
			Object.is(referenceType, '') ||
			Object.is(previousAcademics, '') ||
			Object.is(classId, '') ||
			Object.is(sectionId, '') ||
			Object.is(dob, '')
		) {
			this.setState({
				errorMsg: true
			});
		} else {
			const data = {
				applicantName,
				fatherName,
				motherName,
				mobileNo,
				address,
				city,
				state,
				country,
				emailId,
				postalCode,
				dob,
				phoneNo,
				status,
				classId,
				sectionId,
				previousAcademics,
				referenceType,
				referenceName,
				details,
				accountType: this.props.userData.role,
				accountId: this.props.userData.unique_name
			};
			this.props.sharedActions
				.addRecord(
					'/api/EnquiryForm',
					data,
					this.props.StudentEnquiryActions.addStudentEnquiry
				)
				.then(success => {
					this.setState({
						applicantName: '',
						fatherName: '',
						motherName: '',
						mobileNo: '',
						address: '',
						city: '',
						state: '',
						country: '',
						emailId: '',
						postalCode: '',
						dob: '',
						phoneNo: '',
						status: '',
						classId: '',
						sectionId: '',
						previousAcademics: '',
						referenceType: '',
						referenceName: '',
						details: '',
						errorMsg: false,
						msg: true
					});
				})
				.catch(error => {});
		}
		console.log(this.state);
	};
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/ClassSection',
				this.props.StudentEnquiryActions.getClassesForStudentEnquiry
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}
	onSelectChange = e => {
		console.log(e.target.value);

		const sections = this.state.select.section;
		let check = sections.filter(item => item.classId == e.target.value);
		console.log(check);

		this.setState({
			sections: check,
			classId: e.target.value
		});
	};
	onSectionChange = e => {
		console.log(e.target.value);
		this.setState({
			sectionId: e.target.value
		});
	};
	inputData = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const { classList } = this.state.select;
		console.log('classes k naam', classList);
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Student Enquiry
					</h2>

					<p class="text-center w-responsive mx-auto mb-2" align="center" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.state.errorMsg ? (
								<p style={{ color: 'red' }}>Please fill the form properly!</p>
							) : (
								''
							)}
							{this.state.msg ? (
								<p style={{ color: 'green' }}>
									Student Enquiry Added Successfully!
								</p>
							) : (
								''
							)}
							<form
								id="contact-form"
								name="contact-form"
								onSubmit={this.onHandleSubmit}
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />

								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-user-shield animated fadeIn"
                        /> */}
												Admin Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminName"
												class="form-control"
												onChange={this.onHandleTextChange}
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-user-alt animated fadeIn"
                        /> */}
												Admin Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												type="text"
												name="AdminType"
												class="form-control"
												onChange={this.onHandleTextChange}
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
									</div>
								</div>
								<ClassComponent
									classes={classList}
									sectionsData={this.state.sections}
									onHandleChange={this.onSelectChange}
									onHandleSection={this.onSectionChange}
								/>
								<div class="row">
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-child animated fadeIn"
                        /> */}
												Applicant Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Applicant Name"
												type="text"
												id="name"
												name="applicantName"
												value={this.state.applicantName}
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-male animated fadeIn"
                        /> */}
												Father Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Father Name"
												type="text"
												id="email"
												name="fatherName"
												value={this.state.fatherName}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-female animated fadeIn"
                        /> */}
												Mother Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Mother Name"
												type="text"
												id="email"
												name="motherName"
												value={this.state.state.motherName}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-address-card animated fadeIn"
                        /> */}
												Address
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Address"
												type="text"
												id="email"
												name="address"
												value={this.state.address}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-building animated fadeIn"
                        /> */}
												City
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="City"
												type="text"
												id="email"
												name="city"
												value={this.state.city}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-globe-asia animated fadeIn"
                        /> */}
												Country
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Country"
												type="text"
												id="email"
												name="country"
												value={this.state.country}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-flag animated fadeIn"
                        /> */}
												State
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="State"
												type="text"
												id="email"
												name="state"
												value={this.state.state}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-envelope-open-text animated fadeIn"
                        /> */}
												Email Id
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Email Id"
												type="text"
												id="email"
												name="emailId"
												value={this.state.emailId}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-mail-bulk animated fadeIn"
                        /> */}
												Postal Code
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Postal Code"
												type="text"
												id="email"
												name="postalCode"
												value={this.state.postalCode}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-birthday-cake animated fadeIn"
                        /> */}
												Date Of Birth
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Date Of Birth"
												type="text"
												id="email"
												name="dob"
												value={this.state.dob}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-phone-volume animated fadeIn"
                        /> */}
												Phone Number
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Phone Number"
												type="text"
												id="email"
												name="phoneNo"
												value={this.state.phoneNo}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-phone animated fadeIn"
                        /> */}
												Mobile Number
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Mobile Number"
												type="text"
												id="email"
												name="mobileNo"
												value={this.state.mobileNo}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												{/* <i className="fas fa-user-shield" style={{ marginRight: 4 }} /> */}
												Status
											</label>
											<select
												onChange={this.inputData}
												style={{ borderColor: '#01AC8A' }}
												class="custom-select"
												name="status"
											>
												<option>Choose Status..</option>
												<option value="admitted">Admitted</option>
												<option value="In Process">In Process</option>
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-university animated fadeIn"
                        /> */}
												Previous Academics
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Previous Academics"
												type="text"
												id="email"
												name="previousAcademics"
												value={this.state.previousAcademics}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-link animated fadeIn"
                        /> */}
												Reference Type
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Reference Type"
												type="text"
												id="email"
												name="referenceType"
												value={this.state.referenceType}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-link animated fadeIn"
                        /> */}
												Reference Name
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Reference Name"
												type="text"
												id="email"
												name="referenceName"
												value={this.state.referenceName}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												{/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-info animated fadeIn"
                        /> */}
												Details
											</label>
											<input
												style={{ borderColor: '#01AC8A' }}
												placeholder="Details"
												type="text"
												id="email"
												name="details"
												value={this.state.details}
												className="form-control check"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
								</div>

								<br />
								<br />
								<br />
								<div class="text-center text-md-center">
									<button type="submit" class="btns">
										Add
									</button>
								</div>
							</form>

							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		StudentEnquiryActions: bindActionCreators(StudentEnquiryActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectData: state.setStudentEnquiryReducer.select
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StudentEnquiry);
