import React, { useState } from 'react';
import { Types } from '../../hooks/HookReducers/StaffReducer';
import RenderDynamicSiblingsRow from '../../Container/Admission/RenderDynamicSiblingsRow';
import Button from '../Button';

const Experience = props => {
	const { ON_DYANMIC_ADD, ON_DYANMIC_DELETE } = Types;
	const {
		dispatch,
		errors,
		onPreviousClick,
		getValidatedArray,
		edit = false,
		editData = {},
		onUpdate,
		putLoader
	} = props;
	const [Experiences, setExperiences] = useState(
		edit
			? editData
			: [
					{
						experienceName: '',
						companyName: '',
						experinceYear: ''
					}
			  ]
	);
	const onNextStep = e => {
		debugger;
		if (!getValidatedArray(e, Experiences, 'experiences')) {
			dispatch({
				type: Types.ON_EXPERIENCES_DISPATCH,
				payload: { Experiences }
			});
		}
	};
	const updateSpecialArrObjOnChange = (e, index, arrName) => {
		const changeArrOfObj = [...Experiences];
		changeArrOfObj[index][e.target.name] = e.target.value;

		// arrName = changeArrOfObj;
		// console.log(arrName);
		setExperiences(changeArrOfObj);
	};
	const onClickAddItem = (arr, obj) => {
		let getStateArr = [...arr];
		getStateArr = [...getStateArr, obj];
		console.log(getStateArr);

		setExperiences(getStateArr);
	};
	const onClickDeleteItem = arr => {
		let getStateArr = [...arr];
		let getDeleteLength = getStateArr.length - 1;

		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			setExperiences(getNewDetails);
		}
	};
	const onEditHandleSubmit = e => {
		debugger;
		const arrayItem = Experiences.map(
			({ staffId, interviewId, id, ...keepAttrs }) => keepAttrs
		);
		if (!getValidatedArray(e, arrayItem, 'experiences')) {
			onUpdate(dispatch, 'ON_SET_EXPERIENCE_EDIT', {
				name: 'StaffExperience',
				Experiences
			});
		}
	};

	return (
		<div>
			{errors.experiences ? (
				<p style={{ color: '#ff0000' }}>Please fill the form properly!</p>
			) : (
				''
			)}

			<div style={{ width: '100%' }}>
				<table class="table table-hover">
					<thead style={{ background: '#01AC8A', color: 'white' }}>
						<tr>
							<th scope="col">#</th>
							<th scope="col">Experience Name</th>
							<th scope="col">Company Name</th>
							<th scope="col">Experience Year</th>
						</tr>
					</thead>
					<tbody>
						{Experiences.map((item, index) => (
							<tr key={index}>
								<th scope={'row'}>{index + 1}</th>
								<RenderDynamicSiblingsRow
									arrName={'Experiences'}
									rowIndex={index}
									stateData={{ Experiences: [...Experiences] }}
									dispatch={dispatch}
									hookReuse={false}
									updateSpecialArrObjOnChange={updateSpecialArrObjOnChange}
									itemObj={item}
								/>
							</tr>
						))}
					</tbody>
					<div className={'row'} style={{ justifyContent: 'flex-end' }}>
						<div>
							<div>
								<button
									onClick={() =>
										onClickAddItem(Experiences, {
											experienceName: '',
											companyName: '',
											experinceYear: ''
										})
									}
									style={{
										background: '#0A4F5E',
										color: '#FFFFFF',
										borderRadius: 100
									}}
									type="button"
									class="btn"
								>
									<i class="fas fa-plus"></i>
								</button>
							</div>
							<div>
								<button
									onClick={() => onClickDeleteItem(Experiences)}
									style={{ color: '#FFFFFF', borderRadius: 100 }}
									type="button"
									class="btn btn-danger"
								>
									<i class="far fa-trash-alt"></i>
								</button>
							</div>
						</div>
					</div>
				</table>
			</div>
			<br />
			<br />
			<br />
			{/*<div class="text-center text-md-center">*/}
			{/*<button class="btns">Add</button>*/}
			{/*</div>*/}
			{!edit ? (
				<div
					style={{
						marginTop: 10,
						display: 'flex',
						justifyContent: 'flex-start'
					}}
				>
					<div>
						<button
							class="btns"
							style={{ marginRight: 25 }}
							onClick={e => onNextStep(e)}
						>
							Next
						</button>
					</div>
					<div>
						<button class="btns" onClick={() => onPreviousClick()}>
							Previous
						</button>
					</div>
				</div>
			) : (
				''
			)}
			{edit ? (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						stateData={{ isLoading: putLoader }}
						btnName="Update"
						buttonClass="btn-smart"
						loaderBmargin={5}
						marginTop={20}
						onHandleSubmit={onEditHandleSubmit}
					/>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default Experience;
