import * as actionTypes from '../Actions/actionTypes';
import isEmpty from 'lodash/isEmpty';

const defaultState = {
	apiFlag: true,
	Data: [],
	selectStudent: [],
	selectSession: [],
	selectExam: []
};

export default function setExamReducer(state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.GET_ALL_EXAM:
			return { ...state, Data: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_EXAM:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_EXAM:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.Id !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.GET_SESSION_FOR_EXAM:
			return {
				...state,
				selectSession: action.payload
			};
		case actionTypes.GET_EXAM_FOR_EXAMDETAILS:
			return {
				...state,
				selectExam: action.payload
			};
		case actionTypes.GET_CLASS_GRADE_SUBJECT_FOR_EXAM:
			return {
				...state,
				selectStudent: action.payload
			};
		case actionTypes.EDIT_EXAM_RECORD:
			var adminData = [...state.Data];
			var index = adminData.findIndex(obj => obj.Id === action.payload.id);
			adminData[index].status = action.payload.status;
			// adminData[index].TotalMarks = action.payload.TotalMarks;
			// adminData[index].examTime = action.payload.examTime;
			// adminData[index].examDate = action.payload.examDate;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
