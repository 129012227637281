import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../Actions/sharedActions';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput/TextInput';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import general from '../../utils/general';

class AssignSubject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            accountId: props.name,
            accountType: props.role,
            errors: {},
            errorMsg: false,
            classDataOld: [],
            classDataNew: [],
            classIdOld: '',
            classIdNew: '',
            sectionDataOld: [],
            sectionDataNew: [],
            sectionIdOld: '',
            sectionIdNew: '',
            subjectDataOld: [],
            subjectDataNew: [],
            subjectIdOld: '',
            subjectIdNew: '',
            gradeId: '',
            gradeData: []
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/classSectionGradeTeacher`
            )
            .then(success => {
                this.setState({
                    mainData: success,
                    classDataOld: [...success.classes],
                    classDataNew: [...success.classes]
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.log(error);
            });

        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/Posts/All`
            )
            .then(success => {

                let postData = general.dataFilteration(
                    success,
                    ['PostId', 'Post'],
                    {
                        departmentId: 1
                    });

                this.setState({
                    postData: postData,
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.log(error);
            });

    }

    onHandleClassOldChange = e => {
        const { mainData } = this.state;

        let sectionData = general.dataFilteration(
            mainData.sections,
            ['section', 'sectionId'],
            {
                classId: parseInt(e.target.value)
            })

        let subjectData = general.dataFilteration(
            mainData.subject,
            ['subjectName', 'subjectId'],
            {
                classId: parseInt(e.target.value),
            })

        this.setState({
            [e.target.name]: e.target.value,
            sectionDataOld: sectionData,
            subjectDataOld: subjectData,
            staffData: []
        })
    }

    onHandleClassNewChange = e => {
        const { mainData } = this.state;

        let sectionData = general.dataFilteration(
            mainData.sections,
            ['section', 'sectionId'],
            {
                classId: parseInt(e.target.value)
            })

        let subjectData = general.dataFilteration(
            mainData.subject,
            ['subjectName', 'subjectId'],
            {
                classId: parseInt(e.target.value),
            })

        let gradeData = general.dataFilteration(
            mainData.grade,
            ['gradeName', 'gradeId'],
            {
                classId: parseInt(e.target.value),
            })


        this.setState({
            [e.target.name]: e.target.value,
            sectionDataNew: sectionData,
            subjectDataNew: subjectData,
            gradeData: gradeData,
            gradeName: gradeData[0].gradeName,
            gradeId: gradeData[0].gradeId
        })
    }


    onHandleSectionChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    onHandleSelectChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onHandleSubjectChange = e => {
        const { mainData, classIdOld, sectionIdOld } = this.state;

        let staffData = general.dataFilteration(
            mainData.staff,
            ['staffId', 'staffName'],
            {
                classId: parseInt(classIdOld),
                sectionId: parseInt(sectionIdOld),
                subjectId: e.target.value
            }
        )

        this.setState({
            [e.target.name]: e.target.value,
            staffData: staffData
        })
    }

    onHandleStaffChange = e => {
        const { staffData } = this.state;

        let staffName = general.dataFilteration(
            staffData,
            ['staffName'],
            {
                staffId: e.target.value
            }
        )

        this.setState({
            [e.target.name]: e.target.value,
            staffName: staffName[0].staffName
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const {
            classIdNew,
            staffId,
            sectionIdNew,
            subjectIdNew,
            postId,
            gradeId,
            accountId,
            accountType
        } = this.state;

        if (
            Object.is(classIdNew, '') ||
            Object.is(staffId, '') ||
            Object.is(sectionIdNew, '') ||
            Object.is(subjectIdNew, '') ||
            Object.is(postId, '') ||
            Object.is(gradeId, '')
        ) {
            this.setState({
                errorMsg: true
            });
        } else {
            const data = {
                classId:  classIdNew,
                staffId,
                sectionId:  sectionIdNew,
                subjectId : subjectIdNew,
                hodPostId: postId,
                gradeId,
                accountId,
                accountType
            }
            console.log(data);
            

            this.setState({
                isLoading: true
            });
            this.props.sharedActions
                .simpleAddRequest(
                    '/api/AssignSubjectsToTeacher/',
                    data,
                )
                .then(success => {
                    this.setState({
                        classIdOld: '',
                        classIdNew: '',
                        sectionDataOld: [],
                        sectionDataNew: [],
                        sectionIdOld: '',
                        sectionIdNew: '',
                        subjectDataOld: [],
                        subjectDataNew: [],
                        subjectIdOld: '',
                        subjectIdNew: '',
                        gradeId: '',
                        gradeData: [],
                        gradeName:'',
                        postId:'',
                        staffId: '',
                        staffName: '',            
                        errorMsg: false,
                        isLoading: false,
                        msg: 'Subject Assigned Succefully!',
                    });
                    this.props.snackbar();
                })
                .catch(error => { });


        }
    };

    render() {
        const {
            errors,
            isLoading,
            classDataNew,
            classDataOld,
            sectionDataOld,
            sectionDataNew,
            subjectDataOld,
            subjectDataNew,
            staffData,
            postData,
        } = this.state;

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div class="spinner-border" style={{ color: '#01AC8A' }}></div>
            </div>
        );

        const Form = (
            <FormLayoutWrapper
                formName='Assign Subject To Teacher'
                borderColor='#01ac8a'
                borderRadius={15}
            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly
								</p>
                ) : (
                        ''
                    )}


                <div className="row">


                    <SelectionInput
                        marginTop={20}
                        feildName={'classIdOld'}
                        selectName={'Class Name'}
                        onHandleChange={e => this.onHandleClassOldChange(e)}
                        errors={errors}
                        optionsArrys={classDataOld}
                        selectedText={'Class Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'className'}
                        propertyId={'classId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'sectionIdOld'}
                        selectName={'Section'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={sectionDataOld}
                        selectedText={'Section Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'section'}
                        propertyId={'sectionId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />


                    <SelectionInput
                        marginTop={20}
                        feildName={'subjectIdOld'}
                        selectName={'Subject Name'}
                        onHandleChange={e => this.onHandleSubjectChange(e)}
                        errors={errors}
                        optionsArrys={subjectDataOld}
                        selectedText={'Subject'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'subjectName'}
                        propertyId={'subjectId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'staffId'}
                        selectName={'Staff Name'}
                        onHandleChange={e => this.onHandleStaffChange(e)}
                        errors={errors}
                        optionsArrys={staffData}
                        selectedText={'Staff Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'staffName'}
                        propertyId={'staffId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}

                    />
                </div>

                <div class='row' style={{ marginTop: 50 }}>
                    <div class='col-md-12'>
                        <h2 class="h1-responsive font-weight-bold text-center my-4">Assign New Subject</h2>
                    </div>
                </div>

                <div class='row'>
                    <SelectionInput
                        feildName={'classIdNew'}
                        selectName={'Class Name'}
                        onHandleChange={e => this.onHandleClassNewChange(e)}
                        errors={errors}
                        optionsArrys={classDataNew}
                        selectedText={'Class Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'className'}
                        propertyId={'classId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        feildName={'sectionIdNew'}
                        selectName={'Section'}
                        onHandleChange={e => this.onHandleSectionChange(e)}
                        errors={errors}
                        optionsArrys={sectionDataNew}
                        selectedText={'Section Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'section'}
                        propertyId={'sectionId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />


                    <SelectionInput
                        marginTop={20}
                        feildName={'subjectIdNew'}
                        selectName={'Subject Name'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={subjectDataNew}
                        selectedText={'Subject'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'subjectName'}
                        propertyId={'subjectId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'staffName'}
                        inputLabelName={'Staff Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Staff Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        marginTop={20}
                        feildName={'gradeName'}
                        inputLabelName={'Grade Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Grade Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />


                    <SelectionInput
                        marginTop={20}
                        feildName={'postId'}
                        selectName={'Post Name'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={postData}
                        selectedText={'Post Name'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'Post'}
                        propertyId={'PostId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />
                </div>

                <br />
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Submit"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={20}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                />

            </FormLayoutWrapper >
        )
        return (
            <React.Fragment>
                <SnackBar msg={this.state.msg} />
                {isLoading ? Loader : Form}
            </React.Fragment>

        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignSubject);