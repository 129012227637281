import React, { Component } from 'react';
import './Form.css';

class StudentAddress extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { data } = this.props;
		console.log(data, 'sadsd');
		return (
			<div>
				<section class="mb-4">
					<div class="row print">
						<div class="col-md-12">
							<div class="md-form mb-0">
								<h2
									style={{
										backgroundColor: '#0A4F5E',
										textAlign: 'center',
										color: 'white'
									}}
								>
									Student Address:
								</h2>
							</div>
						</div>
					</div>
					<div class="container">
						<div class="row rowCheck" style={{ marginTop: 10 }}>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Address: </label>{' '}
								&nbsp;&nbsp;{data && data.address}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>House No: </label>
								&nbsp;&nbsp;{data && data.houseNo}{' '}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Id Card: </label>
								&nbsp;&nbsp;{data && data.studentIdCardNo}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Email Id: </label>
								&nbsp;&nbsp;{data && data.studentEmailId}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Way No:</label>
								&nbsp;&nbsp;{data && data.wayNo}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Street No:</label>
								&nbsp;&nbsp;{data && data.streetNo}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Area:</label>&nbsp;&nbsp;
								{data && data.area}
							</div>
						</div>
						<div class="row rowCheck">
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>City:</label>&nbsp;&nbsp;
								{data && data.city}{' '}
							</div>
							<div class="col colCheck">
								<label style={{ fontWeight: 'bold' }}>Country:</label>
								&nbsp;&nbsp;{data && data.country}{' '}
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default StudentAddress;
