import * as actionTypes from '../actionTypes';

export function setAllAssignmentInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_ASSIGNMENT,
		payload: allData
	};
}
export function getParticularAssignment(allData) {
	return {
		type: actionTypes.GET_PARTICULAR_ASSIGNMENT,
		payload: allData
	};
}

export function getClassForAssignment(allData) {
	return {
		type: actionTypes.SET_ALL_CLASS_NAME_INTO_ASSIGNMENT_SELECT,
		payload: allData
	};
}
export function getSessionForAssignment(allData) {
	return {
		type: actionTypes.SET_ALL_SESSION_NAME_INTO_ASSIGNMENT_SELECT,
		payload: allData
	};
}
export function addAssignment(Data) {
	return {
		type: actionTypes.ADD_ASSIGNMENT,
		payload: Data
	};
}
export function updateAssignmentFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_ASSIGNMETN,
		payload: setFlag
	};
}
export function updateAssignment(data) {
	return {
		type: actionTypes.EDIT_ASSIGNMETN_RECORD,
		payload: data
	};
}
export function deleteAssignment(id) {
	return {
		type: actionTypes.DELETE_ASSIGNMENT,
		payload: id
	};
}
