import React, { useState, useReducer, useRef } from 'react';
import { connect } from 'react-redux';

import {
	StaffDetails,
	Sibling,
	Qualification,
	StaffAddress,
	Certificates,
	StaffPassport,
	Experience
} from '../../../components/Staff';
import Skills from '../../../components/Staff/Skills';
import StaffEditReducer, {
	Types
} from '../../../hooks/HookReducers/StaffEditReducer';
import Axios from 'axios';
import config from '../../../config/config';
import { General } from '../../../utils';
import LayoutWrapper from '../../../components/HOC/LayoutWrapper';
import validateinput from '../../../validator/registrationValidator';
function EditStaffFrom(props) {
	const [newState, dispatch] = useReducer(
		StaffEditReducer.staffReducer,
		StaffEditReducer.staffInitailState
	);
	const main = useRef();

	const [putLoader, setPutLoader] = useState(false);
	const { location, match } = props;
	const [errors, setErrors] = useState({});

	const { state } = location;
	const [imgEditFlag, setEditImgFlag] = useState(false);
	const [imgId, setImageId] = useState(state.StaffImageUpload.id);
	const [imgDetails, setImgDetail] = useState(state.StaffImageUpload);
	const { params } = match;

	const isValid = (type, data, data2 = {}) => {
		debugger;
		const { errors, isValid } = validateinput(data, type, data2);
		debugger;
		if (!isValid) {
			setErrors(errors);
		}
		return isValid;
	};
	const dynamicFieldValidate = array => {
		debugger;
		let isValid = array.map((item, index) => {
			debugger;
			console.log(General.IsObjectEmpty(item));
			if (!General.IsObjectEmpty(item)) {
				debugger;
				return false;
			} else {
				debugger;
				return true;
			}
		});
		console.log(isValid);

		let getValue = false;
		if (isValid.includes(false)) {
			getValue = false;
		} else {
			getValue = true;
		}
		debugger;
		return getValue;
	};
	const getValidatedArray = (e, arr, createErrorField) => {
		debugger;
		let getCloneErrors = { ...errors };
		if (!dynamicFieldValidate(arr)) {
			getCloneErrors[createErrorField] = true;
			setErrors(getCloneErrors);
			handleScroll(e);
			debugger;
			return true;
		} else {
			getCloneErrors[createErrorField] = false;
			setErrors(getCloneErrors);
			debugger;
			return false;
			// handleScroll(e);
		}
	};
	const onNextClick = (e, type, data, data2 = {}) => {
		debugger;
		if (isValid(type, data, data2)) {
			setErrors({});
			return true;
			debugger;
		} else if (type === 'checkStaffDetails') {
			debugger;
			handleScroll(e);
			return false;
		} else {
			debugger;
			return false;
			handleScroll(e);
		}
	};
	const handleScroll = e => {
		debugger;
		e.preventDefault();
		const newmain = main.current;
		window.scrollTo({
			top: newmain.offsetTop,
			left: 0,
			behavior: 'instant'
		});
	};
	const imageChange = e => {
		e.preventDefault();
		try {
			let reader = new FileReader();
			let file = e.target.files[0];

			setEditImgFlag(true);
			if (
				!General.checkForImageOrFile(
					true,
					false,
					errors,
					file.size,
					2.1,
					file.name,
					'imgType',
					setErrors
				)
			) {
				reader.onloadend = () => {
					setImgDetail({
						file,
						imagePreviewUrl: reader.result,
						imageFile: file.name
					});
				};
				reader.readAsDataURL(file);
			}
		} catch (e) {
			console.log(e);
		}
	};
	const onUpdate = (dispatch, type, payload) => {
		let Staff = {
			...state.StaffInformation,
			...state.StaffAddress,
			...state.StaffPassport
		};
		let StaffDoc = state.StaffDoc;
		let Certificate = state.Certificate;
		let Experiences = state.Experiences;
		let Qualifications = state.Qualifications;
		let Skills = state.Skills;
		let Siblings = state.Siblings;

		if (
			type === Types.ON_SET_STAFF_UPDATE_ADDRESS ||
			type === Types.ON_SET_STAFF_DETAILS ||
			type === Types.ON_SET_STAFF_PASSPORT
		) {
			Staff = {
				...state.StaffInformation,
				...state.StaffAddress,
				...state.StaffPassport,
				...payload.data
			};
		}
		if (type === Types.ON_SET_CERTIFICATE_EDIT) {
			debugger;
			Certificate = [...payload.data];
		}
		if (type === Types.ON_SET_EXPERIENCE_EDIT) {
			Experiences = [...payload.Experiences];
		}
		if (type === Types.ON_SET_SKILLS_EDIT) {
			Skills = [...payload.Skills];
		}
		if (type === Types.ON_SET_QUALIFICATON_EDIT) {
			Qualifications = [...payload.Qualifications];
		}
		if (type === Types.ON_SET_SIBLINGS_EDIT) {
			Siblings = [...payload.Siblings];
		}
		let imageData = null;
		if (imgEditFlag) {
			imageData = new FormData();
			imageData.append('file', imgDetails.file, imgDetails.file.name);
			imageData.append('accountId', props.name);
			imageData.append('staffId', Staff.staffId);
			imageData.append('accountType', props.role);
		}
		let getData = {
			Staffs: { ...Staff, accountId: props.name, accountType: props.role },
			Siblings,
			Experiences,
			Qualifications,
			Skills,
			Certificate,
			StaffDoc
		};
		debugger;
		let token = '';
		if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
		setPutLoader(true);
		let Header = {
			headers: { Authorization: `Bearer  ${token}` }
		};
		Promise.all([
			Axios.put(
				`${config.localhttp}/api/Staff/${Staff.staffId}`,
				getData,
				Header
			)
		])
			.then(result => {
				debugger;
				setPutLoader(false);
				setEditImgFlag(true);
				props.history.push('/dashboard/viewStaff');
			})
			.catch(error => {});
	};

	const renderForm = type =>
		type === 'StaffInformation' ? (
			<LayoutWrapper formType="Staff Details" step="1">
				<StaffDetails
					edit={true}
					onUpdate={onUpdate}
					putLoader={putLoader}
					imageDetailsEdit={imgDetails}
					imageChange={imageChange}
					onNextClick={onNextClick}
					errors={errors}
					imageChangeEdit={imgDetails.imagePreviewUrl}
					stateData={state.StaffInformation}
					dispatch={dispatch}
				/>
			</LayoutWrapper>
		) : type === 'Siblings' ? (
			<LayoutWrapper formType="Siblings" step="2">
				<Sibling
					getValidatedArray={getValidatedArray}
					putLoader={putLoader}
					errors={errors}
					edit={true}
					onUpdate={onUpdate}
					editData={state.Siblings}
				/>
			</LayoutWrapper>
		) : type === 'Qualifications' ? (
			<LayoutWrapper formType="Qualification" step="3">
				<Qualification
					errors={errors}
					getValidatedArray={getValidatedArray}
					putLoader={putLoader}
					edit={true}
					onUpdate={onUpdate}
					editData={state.Qualifications}
				/>
			</LayoutWrapper>
		) : type === 'Skills' ? (
			<LayoutWrapper formType="Skills" step="4">
				<Skills
					staffId={state.StaffInformation.staffId}
					errors={errors}
					getValidatedArray={getValidatedArray}
					edit={true}
					putLoader={putLoader}
					onUpdate={onUpdate}
					editData={state.Skills}
				/>
			</LayoutWrapper>
		) : type === 'Experiences' ? (
			<LayoutWrapper formType="Experience" step="5">
				<Experience
					errors={errors}
					getValidatedArray={getValidatedArray}
					edit={true}
					putLoader={putLoader}
					onUpdate={onUpdate}
					editData={state.Experiences}
				/>
			</LayoutWrapper>
		) : type === 'Certificate' ? (
			<LayoutWrapper formType="Certificates" step="6">
				<Certificates
					errors={errors}
					getValidatedArray={getValidatedArray}
					edit={true}
					putLoader={putLoader}
					onUpdate={onUpdate}
					editData={state.Certificate}

					// getValidatedArray={getValidatedArray}
					// dispatch={dispatch}
					// onPreviousClick={onPreviousClick}
				/>
			</LayoutWrapper>
		) : type === 'StaffAddress' ? (
			<LayoutWrapper formType="Staff Address" step="7">
				<StaffAddress
					errors={errors}
					onNextClick={onNextClick}
					edit={true}
					putLoader={putLoader}
					dispatch={dispatch}
					// nonStateData={nonReRenderState}
					stateData={state.StaffAddress}
					onUpdate={onUpdate}
				/>
			</LayoutWrapper>
		) : type === 'StaffPassport' ? (
			<LayoutWrapper formType="Staff Passport" step="8">
				<StaffPassport
					errors={errors}
					onNextClick={onNextClick}
					edit={true}
					putLoader={putLoader}
					dispatch={dispatch}
					// nonStateData={nonReRenderState}
					stateData={state.StaffPassport}
					onUpdate={onUpdate}
				/>
			</LayoutWrapper>
		) : (
			''
		);

	return <div ref={main}>{renderForm(params.sectionType)}</div>;
}
function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
export default connect(
	mapStateToProps,
	null
)(EditStaffFrom);
