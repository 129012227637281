import * as actionTypes from '../actionTypes';

export function getCampusForCanteen(allData) {
	return {
		type: actionTypes.SET_ALL_CAMPUS_NAME_INTO_CANTEEN_SELECT,
		payload: allData
	};
}

export function getCategoryForItems(allData) {
	return {
		type: actionTypes.SET_ALL_CATEGORY_NAME_INTO_ITEMS_SELECT,
		payload: allData
	};
}

export function getCanteenForCategory(allData) {
	return {
		type: actionTypes.SET_ALL_CANTEEN_NAME_INTO_CATEGORY_SELECT,
		payload: allData
	};
}

export function getHandlerForCanteen(allData) {
	return {
		type: actionTypes.SET_ALL_HANDLER_NAME_INTO_CANTEEN_SELECT,
		payload: allData
	};
}

export function setAllCanteenInRedux(allData) {
	return {
		type: actionTypes.GET_ALL_CANTEEN,
		payload: allData
	};
}

export function addCanteen(Data) {
	return {
		type: actionTypes.ADD_CANTEEN,
		payload: Data
	};
}

export function updateCanteenFlag(setFlag) {
	return {
		type: actionTypes.SET_LOADER_FLAG_CANTEEN,
		payload: setFlag
	};
}

export function updateCanteen(data) {
	return {
		type: actionTypes.EDIT_CANTEEN_RECORD,
		payload: data
	};
}

export function deleteCanteen(id) {
	return {
		type: actionTypes.DELETE_CANTEEN,
		payload: id
	};
}
