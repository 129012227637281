import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import Year from '../../components/Year';
import Moment from 'moment';
import months from '../../utils/general';
import $ from 'jquery';

class MonthlyAttendanceView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			SkillReports: [],
			studentList: [],
			studentId: '',
			studentName: '',
			year: '',
			month: '',
			adminData: [],
			enabled: false,
			id: null,
			select: [],
			date: '',
			studentName: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			errorMsg: '',
			increament: 1,
			selectClassData: '',
			classId: '',
			sectionId: '',
			sections: [],
			subjects: [],
			session: [],
			sessionId: '',
			subjectId: '',
			staffId: '',
			term: '',
			btnLoad: false,
			exist: false,
			checking: [],
			searchCheck: true,
			multiple: [],
			errorText: '',
			badRequest: false,
			present: '',
			absent: '',
			excusedAbsent: '',
			excusedNonAbsent: '',
			totalLate: ''
		};
	}

	onSelectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		const sections = this.state.selectClassData.staffClassSection;
		let check = sections.filter(item => item.classId == result.classId);
		console.log(check);

		this.setState({
			sections: check,
			staffId: check[0].staffId,
			staffName: check[0].staffName,
			classId: result.classId,
			className: result.className
		});
	};

	onSectionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		const sections = this.state.selectClassData.staffClassSection;
		let check2 = sections.filter(
			item =>
				item.classId == this.state.classId && item.sectionId == result.sectionId
		);
		this.setState(
			{
				subjects: check2,
				sectionId: result.sectionId,
				sectionName: result.section
			},
			this.hitStudentApi(result.sectionId)
		);
		console.log(this.state.staffId);
	};

	onSessionChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		this.setState({
			sessionId: result.sessionId,
			sessionName: result.currentSession
		});
		console.log(this.state);
	};

	onSubjectChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		this.setState({
			subjectId: result.subjectId,
			subjectName: result.subjectName
		});
	};
	onStudentChange = e => {
		console.log(e.target.value);
		let result = JSON.parse(e.target.value);
		this.setState({
			studentId: result.studentId
		});
	};

	hitStudentApi = sectionId => {
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/AttendanceClassSectionStudent/?classId=${this.state.classId}&sectionId=${sectionId}`
			)
			.then(success => {
				debugger;
				this.setState({
					studentList: success
				});
				console.log(success);
			})
			.catch(error => {
				this.setState({
					studentList: []
				});
				console.log(error);
			});
	};
	componentDidUpdate() {
		$('.tooltip').tooltip('hide');

		if (
			this.state.msg ||
			this.state.errorMsg ||
			this.state.exist ||
			this.state.badRequest
		) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					exist: false,
					badRequest: false
				});
			}, 3000);
		}
	}

	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/DescriptiveReport?userName=${this.props.users.unique_name}`
			)
			.then(success => {
				this.setState({
					selectClassData: success
				});
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		this.props.sharedActions
			.getDataWithoutDispatch('/api/Session')
			.then(success => {
				this.setState({
					session: success
				});
			})
			.catch(error => {});
	}

	onSearchStudent = e => {
		this.setState({
			btnLoad: true
		});
		let Date1 = Moment(this.state.date).format('MM/DD/YYYY');
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/MonthlyAttendance/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&studentId=${this.state.studentId}&year=${this.state.year}&subjectId=${this.state.subjectId}&month=${this.state.month}`
			)
			.then(success => {
				debugger;
				this.setState({
					adminData: success.attendanceRecord,
					present: success.totalPresent,
					absent: success.totalAbsents,
					excusedAbsent: success.excuseAbsents,
					excusedNonAbsent: success.nonExcusedAbsents,
					totalLate: success.totalLates,
					studentName: success.studentName,
					isLoading: false,
					btnLoad: false,
					searchCheck: false
				});
				debugger;
			})
			.catch(err => {
				this.setState({
					errorText: err.response.data.Message,
					isLoading: false,
					btnLoad: false,
					searchCheck: true,
					badRequest: true
				});

				console.log(err);
			});
	};

	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.skillReportId === itemId);

		this.setState({
			modalData: getData,
			efforts: getData[0].efforts,
			average: getData[0].average,
			academicProgress: getData[0].academicProgress,
			id: getData[0].skillReportId,
			studentName: getData[0].stduentName,
			participation: getData[0].participation
		});
	};

	onHandleTextNumber = e => {
		console.log(e.target.value);
		if (e.target.value <= 5) {
			this.setState({
				[e.target.name]: e.target.value
			});
		}
		console.log(this.state.effort);
	};
	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onChangeText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	changeDate = (date, state) => {
		this.setState({
			[state]: date
		});
		console.log(date);
	};

	PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};

	render() {
		const {
			classId,
			sectionId,
			sessionId,
			subjectId,
			term,
			adminData,
			date,
			sections,
			msg,
			subjects,
			studentList,
			studentId,
			year,
			month
		} = this.state;

		const { session } = this.state.session;
		const { classes } = this.state.selectClassData;

		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<label style={{ color: 'red' }}>No Data</label>
			</div>
		);

		const renderTodos = adminData.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" className="bordersSpace" style={{ padding: 2 }}>
						{index + 1}
					</th>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.leaveType}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.attendanceStatus}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.lateTime}
					</td>
					<td className="bordersSpace" style={{ padding: 2 }}>
						{item.date}
					</td>
				</tr>
			);
		});
		const MainContent = (
			<div>
				<div id="div1">
					<div class="divclass">
						<img src={require('../../Assets/images/3 headers-03.jpg')} />
					</div>
					<br />
					<div class="colCheckb">
						<div class="row justify-content-between">
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Student: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.studentName}
									</label>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="md-form mb-0">
									<label for="name" class="">
										Class: &nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.className}
									</label>
								</div>
							</div>
						</div>
						<div class="row justify-content-between">
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Staff: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.staffName}
									</label>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="md-form mb-0">
									<label for="name" class="">
										Section: &nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.sectionName}
									</label>
								</div>
							</div>
						</div>

						<div class="row justify-content-between">
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Month: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.month}
									</label>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="md-form mb-0">
									<label for="name" class="">
										Year: &nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.year}
									</label>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div className="table-responsive">
						<table class="table table-hover text-center">
							<thead
								style={{ background: '#01AC8A', color: 'white' }}
								class="bordersSpace"
							>
								<tr class="vendorListHeading">
									<th class="bordersSpace" style={{ padding: 2 }}>
										#
									</th>
									<th class="bordersSpace" style={{ padding: 2 }}>
										Leave Type
									</th>
									<th class="bordersSpace" style={{ padding: 2 }}>
										Attendance Status
									</th>
									<th class="bordersSpace" style={{ padding: 2 }}>
										Late
									</th>
									<th class="bordersSpace" style={{ padding: 2 }}>
										Date
									</th>
								</tr>
							</thead>
							<tbody>{renderTodos}</tbody>
						</table>
					</div>
					<div class="colCheckb">
						<div class="row justify-content-between">
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Total Present: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.present}
									</label>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="md-form mb-0">
									<label for="name" class="">
										Total Absent: &nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.absent}
									</label>
								</div>
							</div>
						</div>
						<div class="row justify-content-between">
							<div class="col-md-4">
								<div class="md-form mb-0">
									<label for="name" class="">
										Absent Excused: &nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.excusedAbsent}
									</label>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="md-form mb-0">
									<label for="name" class="">
										Absent Non Excused: &nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.excusedNonAbsent}
									</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="md-form mb-0">
									<label for="name" class="">
										Total Late: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</label>
									<label
										for="name"
										class=""
										style={{
											fontWeight: 'bold',
											fontSize: 20,
											color: '#01425e'
										}}
									>
										{this.state.totalLate}
									</label>
								</div>
							</div>
						</div>
					</div>

					<br />
					{this.state.errorMsg ? (
						<p style={{ color: 'red', textAlign: 'center' }}>
							Grading Marks Already Exist For This Term
						</p>
					) : (
						''
					)}
					{this.state.msg ? (
						<p style={{ color: 'green', textAlign: 'center' }}>
							Added Successfully!
						</p>
					) : (
						''
					)}
					{this.state.exist ? (
						<p style={{ color: 'red', textAlign: 'center' }}>
							Please Fill the Fields
						</p>
					) : (
						''
					)}
				</div>
				<div class="text-center text-md-right">
					<label for="name" class="" onClick={() => this.PrintContent('div1')}>
						<i
							className="fas fa-print fa-2x animated fadeIn"
							style={{ marginRight: 4 }}
						/>
						Print It Out
					</label>
				</div>
			</div>
		);
		return (
			<div>
				{this.state.badRequest ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						{this.state.errorText}
					</p>
				) : (
					''
				)}
				<div className="page-header">
					<SnackBar msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center'
						}}
					>
						<div>
							<h5 class="generalHead">View Monthly Attendance</h5>
						</div>
					</div>
				</div>
				<div class="container">
					<div class="row" style={{ justifyContent: 'center' }}>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Class Name
								</label> */}
								<select
									onChange={this.onSelectChange}
									class="custom-select"
									name="className"
								>
									<option selected={msg ? true : false}>Select Class...</option>
									{classes &&
										classes.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.className}
											</option>
										))}
								</select>
							</div>
						</div>

						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Section
								</label> */}
								<select
									onChange={this.onSectionChange}
									class="custom-select"
									name="className"
								>
									<option selected={msg ? true : false}>
										Select Section...
									</option>
									{sections &&
										sections.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.section}
											</option>
										))}
								</select>
							</div>
						</div>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Subject
								</label> */}
								<select
									onChange={this.onSubjectChange}
									class="custom-select"
									name="className"
								>
									<option selected={msg ? true : false}>
										Select Subject...
									</option>
									{subjects &&
										subjects.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.subjectName}
											</option>
										))}
								</select>
							</div>
						</div>
						<div class="col-md-2" style={{ marginTop: 20 }}>
							<div class="md-form mb-0">
								{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Session
								</label> */}
								<select
									onChange={this.onStudentChange}
									class="custom-select"
									name="sessionId"
								>
									<option selected={msg ? true : false}>
										Select Student...
									</option>
									{studentList &&
										studentList.map((item, index) => (
											<option key={index} value={`${JSON.stringify(item)}`}>
												{item.studentName}
											</option>
										))}
								</select>
							</div>
						</div>
					</div>
				</div>
				<br />
				<div class="row justify-content-center">
					<div class="col-md-2" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							{/* <label for="email" class="">
                                <i
                                    style={{ marginRight: 4 }}
                                    className="fas fa-map animated fadeIn"
                                />
                                Section
                      </label> */}
							<select
								onChange={this.onHandleText}
								class="custom-select"
								name="month"
							>
								<option selected={this.state.msg ? true : false}>
									Select Month...
								</option>
								{months.Months.map((item, index) => (
									<option key={index} value={`${item}`}>
										{item}
									</option>
								))}
							</select>
						</div>
					</div>
					<div class="col-md-2" style={{ marginTop: 20 }}>
						<div class="md-form mb-0">
							{/* <label for="email" class="">
									<i
										style={{ marginRight: 4 }}
										className="fas fa-map animated fadeIn"
									/>
									Session
								</label> */}
							<Year onMonthChange={this.onHandleText} />
						</div>
					</div>
				</div>
				<br />
				<div class="col-md-12 text-center">
					<button
						type="submit"
						disabled={
							classId !== '' &&
							studentId !== '' &&
							sectionId !== '' &&
							month !== '' &&
							year !== '' &&
							subjectId !== ''
								? false
								: true
						}
						style={{
							backgroundColor:
								classId !== '' &&
								studentId !== '' &&
								sectionId !== '' &&
								month !== '' &&
								subjectId !== '' &&
								year !== ''
									? '#01ac8a'
									: '#ABD0BC',
							border: 'none',
							fontSize: 20,
							borderRadius: 4,
							color: 'white'
						}}
						onClick={this.onSearchStudent}
					>
						Search
						<i
							style={{ color: 'white', marginLeft: 4 }}
							class="fas fa-search"
						></i>
						<span
							style={{ marginBottom: 5 }}
							class={`${
								this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''
							}`}
						></span>
					</button>
				</div>
				<br />
				{this.state.searchCheck ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MonthlyAttendanceView);
