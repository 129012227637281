import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StudentEnquiryActions from '../../Actions/StudentEnquiry';
import * as sharedActions from '../../Actions/sharedActions';
import $ from 'jquery';

class ShowAllSyllabusData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			vehicleNo: '',
			capacity: '',
			transportType: '',
			enabled: false,
			id: null,
			select: [],
			status: '',
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10
		};
	}
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const { id, status } = this.state;
		var bodyParameters = { status };
		this.props.sharedActions
			.editPatchRecord(
				'/api/EnquiryForm/',
				id,
				bodyParameters,
				this.props.StudentEnquiryActions.updateAdmittedStudent
			)
			.then(success => {
				this.setState({ enabled: false, msg: 'Edit Record Successfully!' });
				this.props.snackbar();
			})
			.catch(err => {});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = this.props.data.filter(item => item.id === itemId);

		this.setState({
			modalData: getData,
			id: getData[0].id,
			status: getData[0].status
		});
	};
	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	render() {
		const { modalData } = this.state;
		const MainContent = (
			<div class="modal" id="myModalUpdate">
				<div class="modal-dialog">
					<div class="modal-content modal_content_custom">
						<div
							style={{
								borderBottomLeftRadius: 30,
								borderBottomRightRadius: 30,
								borderTopLeftRadius: 18,
								borderTopRightRadius: 18
							}}
							class="modal-header modal_custom_header"
						>
							<h4 style={{ color: 'white' }} class="modal-title">
								Details
							</h4>
							<button
								style={{ color: 'white' }}
								type="button"
								class="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div class="modal-body">
							<form onSubmit={this.onHandleSubmit}>
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<fieldset disabled={!this.state.enabled}>
												<div class="form-group">
													<label for="transportType">Status:</label>
													<input
														readOnly={true}
														style={{
															borderTop: 0,
															borderLeft: 0,
															borderRight: 0,
															boxShadow: 'none'
														}}
														type="text"
														value={this.state.status}
														onChange={this.onHandleText}
														name="transportType"
														class="form-control"
														id="transportType"
													/>
												</div>
												<div class="col-md-12" style={{ marginTop: 20 }}>
													<div class="md-form mb-0">
														<label for="name" class="">
															<i
																className="fas fa-user-shield"
																style={{ marginRight: 4 }}
															/>
															Status
														</label>
														<select
															onChange={this.onHandleText}
															class="custom-select"
															name="status"
														>
															<option>Choose Status..</option>
															<option value="admitted">Admitted</option>
															<option value="In Process">In Process</option>
														</select>
													</div>
												</div>
											</fieldset>
											<br />
											<br />
											<button
												disabled={this.state.enabled}
												onClick={() => this.setState({ enabled: true })}
												type="button"
												class="btn btn-primary buttonAppear"
											>
												Edit
											</button>
											<button
												style={{ marginLeft: 5 }}
												disabled={!this.state.enabled}
												type="submit"
												class="btn btn-primary buttonAppear"
											>
												Save
											</button>
										</div>
									))}
							</form>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								style={{ background: '#00435D' }}
								class="btn btn-danger"
								data-dismiss="modal"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
		return (
			<React.Fragment>
				{this.props.data.map((item, index) => (
					<tr key={index}>
						<th style={{ padding: 3 }} className="bordersSpace" scope="row">
							{index + 1}
						</th>
						<td style={{ padding: 3 }} className="bordersSpace">
							{item.title}
						</td>
						<td style={{ padding: 3 }} className="bordersSpace">
							{item.className}
						</td>
						<td style={{ padding: 3 }} className="bordersSpace">
							{item.section}
						</td>
						<td style={{ padding: 3 }} className="bordersSpace">
							{item.session}
						</td>
						<td style={{ padding: 3 }} className="bordersSpace">
							{item.term}
						</td>
						<td style={{ padding: 3 }} className="bordersSpace">
							{item.date}
						</td>
						<td style={{ padding: 3 }} className="bordersSpace">
							{item.time}
						</td>
						<td style={{ padding: 3 }} className="bordersSpace">
							{item.accountId}
						</td>
						<td style={{ padding: 3 }} className="bordersSpace">
							{item.accountType}
						</td>
						<td style={{ padding: 3 }} className="bordersSpace">
							<Link to={`/dashboard/ViewSyllabusTemplate/${item.syllabusId}`}>
								<a href="#" data-toggle="tooltip" title="View Template!">
									<button
										id="firstbutton"
										onMouseOver={this.btnTechHover()}
										style={{
											cursor: 'pointer',
											fontSize: 20,
											border: 'none',
											marginLeft: 5,
											background: 'none',
											marginBottom: 5
										}}
									>
										<i style={{ color: '#34495e' }} class="fas fa-list"></i>
									</button>
								</a>
							</Link>
						</td>
					</tr>
				))}
				{MainContent}
			</React.Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		StudentEnquiryActions: bindActionCreators(StudentEnquiryActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		AllTransportData: state.setStudentEnquiryReducer.Data,
		allTransportState: state.setStudentEnquiryReducer,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ShowAllSyllabusData);
