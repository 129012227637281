import React, { useEffect, useRef, useState } from 'react';
import { Bar, Pie, Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import img1 from '../../../Assets/images/toplog.jpg';
import CustomBadge from '../../../components/CustomBadge/CustomBadge.jsx';
import GraphGeneralLayout from '../../../components/Graphs/GraphGeneralLayout/GraphGeneralLayout.jsx';
import LoaderWrapper from '../../../components/HOC/LoaderWrapper.jsx';
import CustomReportComponent from '../../../components/Reports/CustomReportComponent.jsx';
import CustomReportHeader from '../../../components/Reports/CustomReportHeader/CustomReportHeader.jsx';
import useHttp from '../../../hooks/http.js';
import CoverLayout from '../../../components/HOC/CoverLayout';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';

function StudentDisciplineReports(props) {
	const chartRef = useRef();
	const [graphType, setGraphType] = useState('barChart');
	const [graphData, setGrapData] = useState({});
	const [isGraphDataLoading, setIsGraphDataLoading] = useState(false);
	const [pieData, setPieData] = useState({
		datasets: [
			{
				data: [10, 90],
				backgroundColor: ['#cacaca', '#1ab43e'],
				borderColor: ['#cacaca', '#1ab43e']
			}
		],

		// These labels appear in the legend and in the tooltips when hovering different arcs
		labels: ['low', 'high']
	});

	const returnGraphs = type => {
		switch (type) {
			case 'barChart':
				return <Bar height={100} data={data} />;

			case 'pieChart':
				return <Pie height={100} data={data} />;

			case 'lineChart':
				return <Line height={100} data={data} />;

			default:
				break;
		}
	};
	const [data, setData] = useState({
		datasets: [
			{
				barPercentage: 0.4,
				minBarLength: 2,
				label: 'Course Average',
				data: [3, 2, 2, 3, 3, 4, 3, 2],
				backgroundColor: [
					'#ea766c',
					'#abe2b9',
					'#adbceb',
					'#be9e9d',
					'#9d5b4d',
					'#e4d86b',
					'#9ecf54',
					'#e4d86b'
				],
				borderColor: [
					'#ea766c',
					'#abe2b9',
					'#adbceb',
					'#be9e9d',
					'#9d5b4d',
					'#e4d86b',
					'#9ecf54',
					'#e4d86b'
				],
				borderWidth: 2
			}
		],

		// These labels appear in the legend and in the tooltips when hovering different arcs
		labels: ['BIO', 'ICT', 'MATH', 'SOCIAL', 'PHYS', 'CHEM', 'ENG', 'ISL'],
		options: {}
	});
	useEffect(() => {}, []);
	const { classId, section, session, term } = props.match.params;
	const [isLoader, fetchData] = useHttp(
		`/api/ConsolidatedDisciplinaryView?classId=${classId}&sectionId=${section}&sessionId=${session}&term=${term}`,
		[]
	);

	useEffect(() => {
		setIsGraphDataLoading(true);
		props.sharedAction
			.getDataWithoutDispatch(
				`/api/AverageValue?classId=${classId}&sectionId=${section}&sessionId=${session}&term=${term}`
			)
			.then(success => {
				debugger;
				setGrapData(success);
				let getData = { ...data };
				let getAvg = success && success.map(item => item.average);
				let getSubName =
					success &&
					success.map(item => item.subjectName.slice(0, 3).toUpperCase());
				getData['datasets'][0]['data'] = [...getAvg];
				getData['labels'] = [...getSubName];
				setData(getData);
				debugger;
			})
			.catch(error => {
				debugger;
			});
	}, []);

	const PrintContent = el => {
		// Previous code mentioned below

		setTimeout(() => {
			window.print();
			window.location.reload();
		}, 700);
	};

	console.log(fetchData);

	console.log('Student Discipline Reports', props);

	return (
		<div>
			<div id="printDev">
				<img src={img1} alt="#img" style={{ width: '100%', height: '30%' }} />
				<div>
					<CustomBadge
						fontSize={25}
						bottom={-10}
						badgeText={'DISCIPLINARY RECORDS'}
						badgeIconClass={'fas fa-graduation-cap'}
					/>
				</div>

				<CustomReportHeader
					headRenderData={[
						{ displayData: 'Class Name', fieldName: 'className' },
						{ displayData: 'Section', fieldName: 'section' },
						{ displayData: 'Term', fieldName: 'term' },
						{ displayData: 'Session', fieldName: 'sessionName' }
					]}
					targetedObj="teacherinfo"
					headData={fetchData}
				/>

				<LoaderWrapper isLoading={isLoader && isGraphDataLoading}>
					<br />
					<br />
					<GraphGeneralLayout
						setType={setGraphType}
						setProperty={graphType}
						titleName={'Analysis'}
						titleIcon={'fas fa-chart-bar'}
						children={() => returnGraphs(graphType)}
					/>
					<br />
					<CoverLayout
						titleName={'Report'}
						titleIcon={'fas fa-clipboard'}
						children={() => (
							<CustomReportComponent
								headerImage={img1}
								isLoader={isLoader}
								startTH={['S#', 'Student Name']}
								tHeadFieldName="subjectName"
								headData={fetchData && fetchData.subjectInfo}
								mainData={fetchData && fetchData.Student}
							/>
						)}
					/>

					<br />
				</LoaderWrapper>
				<div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginTop: 90
							}}
						>
							{/* <Doughnut data={data} /> */}

							<div>_______________________</div>
							<div>Signature Class Teacher.</div>
						</div>
						<div
							id="gradeBox"
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '22%',
								height: 'fit-content'
							}}
						>
							<div>
								<table className="table table-bordered">
									<tr>
										<td style={{ fontWeight: 'bolder', padding: 2 }}>
											4 - Excellent
										</td>
									</tr>
									<tr>
										<td
											style={{
												fontWeight: 'bolder',
												padding: 2,
												textAlign: 'right'
											}}
										>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end' }}
											>
												<div>ممتاز </div>
												<div> - 4</div>
											</div>
										</td>
									</tr>
									<tr>
										<td style={{ fontWeight: 'bolder', padding: 2 }}>
											3 - Very Good
										</td>
									</tr>
									<tr>
										<td
											style={{
												fontWeight: 'bolder',
												padding: 2,
												textAlign: 'right'
											}}
										>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end' }}
											>
												<div>حسن جدا </div>
												<div> - 3</div>
											</div>
										</td>
									</tr>
									<tr>
										<td style={{ fontWeight: 'bolder', padding: 2 }}>
											2 - Good
										</td>
									</tr>
									<tr>
										<td
											style={{
												fontWeight: 'bolder',
												padding: 2,
												textAlign: 'right'
											}}
										>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end' }}
											>
												<div>جيد</div>
												<div> - 2</div>
											</div>
										</td>
									</tr>
									<tr>
										<td style={{ fontWeight: 'bolder', padding: 2 }}>
											1 - Poor
										</td>
									</tr>
									<tr>
										<td
											style={{
												fontWeight: 'bolder',
												padding: 2,
												textAlign: 'right'
											}}
										>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end' }}
											>
												<div>فقير</div>
												<div> - 1</div>
											</div>
										</td>
									</tr>
									<tr>
										<td style={{ fontWeight: 'bolder', padding: 2 }}>
											0 - Unstatisfactory
										</td>
									</tr>
									<tr>
										<td
											style={{
												fontWeight: 'bolder',
												padding: 2,
												textAlign: 'right'
											}}
										>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end' }}
											>
												<div>غير مرض</div>
												<div> - 0</div>
											</div>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{ marginTop: 20 }}
				class="text-center text-md-right displayElemet"
			>
				<label for="name" class="" onClick={() => PrintContent('printDev')}>
					<i
						className="fas fa-print fa-2x animated fadeIn"
						style={{ marginRight: 4 }}
					/>
					Print It Out
				</label>
			</div>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StudentDisciplineReports);
