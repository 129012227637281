import React, { Component } from 'react';
import * as PlansAction from '../../Actions/Plans';
import * as sharedActions from '../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DateandTime from '../Transport/DateandTime';
import $ from 'jquery';

class AddLessonPlan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			AdminName: '',
			AdminType: '',
			file: '',
			chooseFile: '',
			planType: '',
			classId: '',
			sectionId: '',
			select: '',
			sessions: '',
			sessionId: '',
			sections: [],
			errorMsg: false,
			msg: false
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.select !== nextProps.selectData ||
			prevState.sessions !== nextProps.selectSessionData
		) {
			return {
				select: nextProps.selectData,
				sessions: nextProps.selectSessionData
			};
		}
		return null;
	}

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	handleSubmit = e => {
		const { chooseFile, planType, classId, sectionId, sessionId } = this.state;
		e.preventDefault();
		if (
			Object.is(chooseFile, '') ||
			Object.is(planType, '') ||
			Object.is(classId, '') ||
			Object.is(sectionId, '')
		) {
			this.setState({
				errorMsg: true
			});
		} else {
			console.log(this.state);
			let file = this.state.chooseFile;
			let accountType = this.state.accountType;
			let accountId = this.state.accountId;
			accountId = this.props.name;
			accountType = this.props.role;
			let data = new FormData();
			data.append('file', file);
			data.append('sessionId', sessionId);
			data.append('planType', planType);
			data.append('classId', classId);
			data.append('sectionId', sectionId);
			data.append('accountType', accountType);
			data.append('accountId', accountId);
			console.log('DATA Part', data);

			this.props.sharedActions
				.addRecord('/api/LessonPlan', data, this.props.PlansAction.addPlans)
				.then(success => {
					this.setState({
						description: '',
						msg: true,
						errorMsg: false
					});
					// this.props.campusActions.updateCampusFlag(true);
				})
				.catch(err => {
					console.log(err);
				});
		}
		console.log(this.state);
	};
	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false
				});
			}, 3000);
		}
	}
	componentDidMount() {
		$('#chooseFile').bind('change', function() {
			var filename = $('#chooseFile').val();
			if (/^\s*$/.test(filename)) {
				$('.file-upload').removeClass('active');
				$('#noFile').text('No file chosen...');
			} else {
				$('.file-upload').addClass('active');
				$('#noFile').text(filename.replace('C:\\fakepath\\', ''));
			}
		});
		this.props.sharedActions
			.gAllData('/api/classSection', this.props.PlansAction.getClassesForPlans)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		this.props.sharedActions
			.gAllData('/api/Session', this.props.PlansAction.getSessionsForPlans)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	}
	onSelectChange = e => {
		console.log(e.target.value);

		const sections = this.state.select.section;
		let check = sections.filter(item => item.classId == e.target.value);
		console.log(check);

		this.setState({
			sections: check,
			classId: e.target.value
		});
	};
	onSectionChange = e => {
		console.log(e.target.value);
		this.setState({
			sectionId: e.target.value
		});
	};
	onChange = e => {
		this.setState({
			chooseFile: e.target.files[0]
		});
		console.log(this.state.chooseFile);
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);

		this.setState({
			sessionId: e.target.value
		});
		console.log(this.state);
	};
	render() {
		console.log('Classes ka Data', this.state.select);
		console.log('Session ka Data', this.state.sessions);
		const { classList } = this.state.select;
		const { session } = this.state.sessions;
		return (
			<div>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Lesson Plan
					</h2>

					<p class="text-center w-responsive mx-auto mb-5">School Smart ®</p>

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.state.errorMsg ? (
								<p style={{ color: 'red' }}>Please fill the form properly!</p>
							) : (
								''
							)}
							{this.state.msg ? (
								<p style={{ color: 'green' }}>Lessons Added Successfully!</p>
							) : (
								''
							)}
							<form id="contact-form" name="contact-form">
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-user-shield animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Admin Name
											</label>
											<input
												value={this.props.name}
												readOnly={true}
												type="text"
												name="AdminName"
												class="form-control"
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												<i
													className="fas fa-user-alt animated fadeIn"
													style={{ marginRight: 4 }}
												/>
												Admin Type
											</label>
											<input
												value={this.props.role}
												readOnly={true}
												type="text"
												name="AdminType"
												class="form-control"
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Session's
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onSelectSessionChange}
												class="custom-select"
												name="sectionName"
											>
												<option selected={this.state.msg ? true : false}>
													Select Current Session..
												</option>
												{session &&
													session.map((item, index) => (
														<option value={`${item.sessionId}`}>
															{item.currentSession}
														</option>
													))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Class Name
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onSelectChange}
												class="custom-select"
												name="className"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Class...
												</option>
												{classList &&
													classList.map((item, index) => (
														<option key={index} value={`${item.id}`}>
															{item.className}
														</option>
													))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Section
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onSectionChange}
												class="custom-select"
												name="className"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Section...
												</option>
												{this.state.sections.map((item, index) => (
													<option key={index} value={`${item.sectionId}`}>
														{item.section}
													</option>
												))}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Plan Type
											</label>
											<select
												style={{ borderColor: '#01AC8A' }}
												onChange={this.onHandleTextChange}
												class="custom-select"
												name="planType"
											>
												<option selected={this.state.msg ? true : false}>
													Select Plan
												</option>
												<option value="Weekly">Weekly</option>
												<option value="Monthly">Monthly</option>
												<option value="Termly">Termly</option>
												<option value="Yearly">Yearly</option>
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 50 }}>
										<div class="md-form mb-0">
											<div class="file-upload">
												<div class="file-select">
													<div class="file-select-button" id="fileName">
														Choose File
													</div>
													<div class="file-select-name" id="noFile">
														No File Choosen
													</div>
													<input
														type="file"
														name="chooseFile"
														id="chooseFile"
														onChange={this.onChange}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>

								<br />
								<br />
								<div class="text-center text-md-center">
									<button onClick={this.handleSubmit} class="btns">
										Add
									</button>
								</div>
							</form>

							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		PlansAction: bindActionCreators(PlansAction, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId,
		selectData: state.setPlansReducer.select,
		selectSessionData: state.setPlansReducer.selectSession
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddLessonPlan);
