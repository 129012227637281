const ON_TEXT_CHANGE = 'onChange';
const ON_RADIO_CHANGE = 'onChangeRadio';
const ON_FILE_CHANGE = 'onFileChange';
const ON_DATE_CHANGE = 'ON_DATE_CHANGE';
const ON_SELECTION_CHANGE = 'ON_SELECTION_CHANGE';
const ON_DYANMIC_ADD = 'ON_DYANMIC_ADD';
const ON_DYANMIC_DELETE = 'ON_DYANMIC_DELETE';
const ON_DYNAMIC_UPDATE = 'ON_DYNAMIC_UPDATE';
const ON_IMAGE_CHANGE = 'ON_IMAGE_CHANGE';
const ON_CLASS_CHANGE = 'ON_CLASS_CHANGE';
const ON_CERTIFICATE_DISPATCH = 'ON_CERTIFICATE_DISPATCH';
const ON_SKILLS_DISPATCH = 'ON_SKILLS_DISPATCH';
const ON_EXPERIENCES_DISPATCH = 'ON_EXPERIENCES_DISPATCH';
const ON_QUALIFICATION_DISPATCH = 'ON_QUALIFICATION_DISPATCH';
const ON_SIBLING_DISPATCH = 'ON_SIBLING_DISPATCH';
const ON_DOCUMENTS_DISPATCH = 'ON_DOCUMENTS_DISPATCH';
export const Types = {
	ON_TEXT_CHANGE,
	ON_RADIO_CHANGE,
	ON_FILE_CHANGE,
	ON_DATE_CHANGE,
	ON_SELECTION_CHANGE,
	ON_DYANMIC_ADD,
	ON_DYANMIC_DELETE,
	ON_DYNAMIC_UPDATE,
	ON_IMAGE_CHANGE,
	ON_CLASS_CHANGE,
	ON_CERTIFICATE_DISPATCH,
	ON_SKILLS_DISPATCH,
	ON_EXPERIENCES_DISPATCH,
	ON_QUALIFICATION_DISPATCH,
	ON_SIBLING_DISPATCH,
	ON_DOCUMENTS_DISPATCH
};
const staffNonStateFull = {
	StaffFirstName: '',
	fullNameArabic: '',
	lastName: '',
	gender: 'Choose Gender...',
	fatherName: '',
	designation: '',
	maritailStatus: 'Marital Status...',
	nationality: 'Choose Nationality...',
	city: '',
	country: 'Choose Country...',
	state: '',
	mobileNumber: '',
	email: '',
	address: '',
	religion: 'Choose Religion...',
	username: '',
	StreetNo: '',
	password: '',
	bloodGroup: 'Choose Group...',
	status: 'Choose Status...',
	appraisalStatus: 'Choose Status...',
	staffIdcardNo: '',
	staffPassportNo: '',
	basicSalary: '',
	houseNo: '',
	wayNo: '',
	phoneNo: ''
};
const staffDocState = {
	// staff docs done
	experienceLetter: '',
	iletsCertificate: '',
	ministryApproval: '',
	lastQualification: '',
	staffpassportCopy: '',
	passportCopy: '',
	idCardCopy: '',
	experienceLetterfilePath: '',
	iletsCertificatefilePath: '',
	ministryApprovalfilePath: '',
	lastQualificationfilePath: '',
	staffpassportCopyfilePath: '',
	passportCopyfilePath: '',
	idCardCopysfilePath: ''
	// end of staff docs
};
const staffImageState = {
	imagePreviewUrl:
		'http://ctt.trains.com/sitefiles/images/no-preview-available.png',
	imageFile: '',
	file: null
};
const staffInitailState = {
	// upload image

	imagePreviewUrl:
		'http://ctt.trains.com/sitefiles/images/no-preview-available.png',
	imageFile: '',
	file: null,
	// end of upload image
	// step 1 Staff Information
	// class id start done
	classId: 'Choose Class',
	className: '',
	// class id end
	// start of grade done
	gradeId: '',
	gradeName: '',
	// end of grade
	// post start
	postName: '',
	postId: 'Choose Post',
	// post end done
	// campus start
	campusName: '',
	campusId: 'Choose Campus...',

	// class start
	classes: [],

	// end class
	// accountSection
	isAccountAccess: false,
	// end accountSection
	// dept start
	depart: [],
	// dept end
	// campus end done
	// subjectData
	subjectData: [],
	// end of subject data
	// for containing grade data
	grade: [],
	// end of data
	// for containing post data
	post: [],
	// end of post data
	// section done
	sectionId: 'Choose Section',
	section: '',
	// section end
	// subject start done
	subjectName: '',
	subjectId: 'Choose Subject',
	// end done
	// depart done
	departmentName: 'Choose Department',
	departmentId: 'Choose Depart',
	// depart end
	dob: null,
	age: '',
	staffIdcardCopy: '',
	staffIdfilePath1: '',
	staffPassportDOI: '',
	staffPassportDOE: '',
	staffIdcardDOI: '',
	staffIdcardDOE: '',
	dateOfJoining: new Date().toLocaleDateString(),
	// End of Step 1 Staff information
	// staff docs done
	experienceLetter: '',
	iletsCertificate: '',
	ministryApproval: '',
	lastQualification: '',
	staffpassportCopy: '',
	passportCopy: '',
	idCardCopys: '',
	experienceLetterfilePath: '',
	iletsCertificatefilePath: '',
	ministryApprovalfilePath: '',
	lastQualificationfilePath: '',
	staffpassportCopyfilePath: '',
	passportCopyfilePath: '',
	idCardCopysfilePath: '',
	// end of staff docs
	// start of staff Qualification
	Siblings: [
		{
			siblingName: '',
			siblingRelation: '',
			siblingPassportNo: '',
			siblingIdcardno: '',
			siblingpassDOI: '',
			siblingpassDOE: '',
			siblingIdcardDOI: '',
			siblingIdcardDOE: ''
		}
	],

	Qualifications: [
		{
			qualificationName: '',
			board: '',
			qualificationYear: '',
			institute: ''
		}
	],
	Skills: [
		{
			skillName: ''
		}
	],
	Experiences: [
		{
			experienceName: '',
			companyName: '',
			experinceYear: ''
		}
	],
	Certificate: [
		{
			certificateName: '',
			certificateOrg: '',
			certificateDuration: '',
			certificatePlace: '',
			certificateYear: ''
		}
	],

	// end of qualification
	count2: 0,
	errors: {}
};

const ImageUploadDispatch = (state, action) => {
	debugger;
};

const StaffDocumentUploadDispatch = (state, action) => {
	return { ...state, Certificate: action.payload.Certificate };
};

const siblingsDispatch = (state, action) => {
	return {
		...state,
		Siblings: action.payload.Siblings
	};
};
const qualificationDispatch = (state, action) => {
	debugger;
	return { ...state, Qualifications: action.payload.Qualifications };
};
const experienceDispatch = (state, action) => {
	debugger;
	return { ...state, Experiences: action.payload.Experiences };
};
const certificateDispatch = (state, action) => {
	debugger;

	return { ...state, Certificate: action.payload.Certificate };
};
const staffDocsDispatch = (state, action) => {
	debugger;
	return { ...state, ...action.payload.documents };
};
// const qualificationDispatch =(state,action)=>{
//
// 	return {...state,}
// }
const imageChange = (state, action) => {
	return {
		...state,
		file: action.payload.file,
		imagePreviewUrl: action.payload.imagePreviewUrl,
		imageFile: action.payload.imageFile
	};
};
const onClassChange = (state, action) => {
	console.log(action.payload.value);
	const { grade } = action.payload.allData[0];
	const { sections } = action.payload.allData[0];
	const { subject } = action.payload.allData[0];

	let getSectionData = sections.filter(
		item => item.classId === parseInt(action.payload.value)
	);
	let getGradeData = grade.filter(
		(item, index) => item.classId === parseInt(action.payload.value)
	);
	let getSubjectsData = subject.filter(
		item => item.classId === parseInt(action.payload.value)
	);
	console.log(getGradeData);

	return {
		...state,
		className: getGradeData[0] && getGradeData[0].className,
		gradeName: getGradeData[0] && getGradeData[0].gradeName,
		classId: action.payload.value,
		gradeId: getGradeData[0] && getGradeData[0].gradeId,
		grade: getSectionData,
		subjectData: getSubjectsData
	};
	// this.setState({
	//     grade: getGradeData,
	//     gradeName: getGradeData[0] && getGradeData[0].gradeName,
	//     className: getGradeData[0] && getGradeData[0].className,
	//     classId: parseInt(e.target.value),
	//     gradeId: getGradeData[0] && getGradeData[0].gradeId,
	// })
};
const onClickDynamicDelete = (state, action) => {
	let getStateArr = state[action.payload.arrName];

	let getDeleteLength = getStateArr.length - 1;
	if (getDeleteLength > 0) {
		let getNewDetails = getStateArr.filter(
			(item, index) => index !== getDeleteLength
		);
		console.log(getNewDetails);

		return {
			...state,
			[action.payload.arrName]: [...getNewDetails]
		};
	} else {
		return { ...state };
	}
};
const onClickDynamicAdd = (state, action) => {
	let getStateArr = state[action.payload.arrName];
	getStateArr = [...getStateArr, action.payload.obj];
	console.log(action.payload.arrName);
	return {
		...state,
		[action.payload.arrName]: getStateArr
	};
};
const updateSpecialArrChange = (state, action) => {
	const changeArrOfobj = state[action.payload.arrName];
	changeArrOfobj[action.payload.index][action.payload.name] =
		action.payload.value;

	return {
		...state,
		[action.payload.arrName]: changeArrOfobj
	};
};
const onDateChange = (state, action) => {
	if (action.payload.type === 'age') {
		let getAge =
			new Date().getFullYear() - new Date(action.payload.date).getFullYear();

		return {
			...state,
			[action.payload.name]: action.payload.date,
			age: getAge
		};
	} else if (action.payload.type === 'customSiblings') {
		let realArray = action.payload.realArray;
		realArray[action.payload.index][action.payload.name] = action.payload.date;

		return { ...state, realArray };
	} else {
		return {
			...state,
			[action.payload.name]: action.payload.date
		};
	}
};
const fileOnChange = (state, action) => {
	debugger;
	return { ...state, [action.payload.name]: action.payload.value };
};
const onCustomRadioDocs = (state, action) => {
	switch (action.payload.value) {
		case 'Yes':
			return {
				...state,
				[action.payload.name]: action.payload.value
			};
		case 'No':
			return {
				...state,
				[action.payload.name]: action.payload.value,
				[action.payload.fieldName]: action.payload.set
			};
		default:
			break;
	}
};

const onChange = (state, action) => {
	if (action.payload.name === 'sectionId') {
		let getSection = '';
		if (action.payload.value !== 'Choose Section') {
			getSection = state.grade.filter(
				item => item.sectionId === parseInt(action.payload.value)
			)[0].section;
		}
		return { ...state, sectionId: action.payload.value, section: getSection };
	} else if (action.payload.name === 'departmentId') {
		let { department } = action.payload.extraData[1];
		let getDepartName = '';
		let getPostData = [];
		let classes = [];
		let isAccountNotAccess = true;

		let { post } = action.payload.extraData[1];
		if (action.payload.value !== 'Choose Depart') {
			debugger;
			getDepartName = department.filter(
				item => item.departmentId === parseInt(action.payload.value)
			)[0].departmentName;
			getPostData = post.filter(
				item => item.departmentId === parseInt(action.payload.value)
			);
			if (parseInt(action.payload.value) === 1) {
				classes = [...action.payload.extraData[0].classes];
				isAccountNotAccess = true;
			} else {
				classes = [];
				isAccountNotAccess = false;
			}
		} else {
			getPostData = [];
		}

		return {
			...state,
			departmentName: getDepartName,
			departmentId: action.payload.value,
			post: getPostData,
			classes: classes,
			isAccountAccess: isAccountNotAccess
		};
	} else if (action.payload.name === 'postId') {
		debugger;
		let { post } = action.payload.extraData[1];
		let getPostName = '';
		if (action.payload.value === 'Choose Post')
			getPostName = post.filter(
				item => item.postId === parseInt(action.payload.value)
			)[0].postName;
		return { ...state, postName: getPostName, postId: action.payload.value };
	} else if (action.payload.name === 'campusId') {
		debugger;
		let getCampus = action.payload.extraData[3]['campus'];
		let getDepartment = action.payload.extraData[1].department;
		let getCampusName = '';
		debugger;
		let getFilterdDepartment = [];
		if (action.payload.value !== 'Choose Campus...') {
			getCampusName = getCampus.filter(
				item => item.campusId === parseInt(action.payload.value)
			)[0].campusName;
			getFilterdDepartment = [...getDepartment];
		}

		return {
			...state,
			campusName: getCampusName,
			campusId: action.payload.value,
			depart: getFilterdDepartment
		};
	} else if (action.payload.name == 'subjectId') {
		let { subject } = action.payload.extraData[0];
		let getSubName = '';
		if (action.payload.value !== 'Choose Subject') {
			getSubName = subject.filter(
				item => item.subjectId === parseInt(action.payload.value)
			)[0].subjectName;
		}

		return {
			...state,
			subjectId: action.payload.value,
			subjectName: getSubName
		};
	} else {
		return { ...state, [action.payload.name]: action.payload.value };
	}
};
const skillsDispatch = (state, action) => {
	return { ...state, Skills: action.payload.Skills };
};
const staffReducer = (state, action) => {
	switch (action.type) {
		case ON_TEXT_CHANGE:
			return onChange(state, action);
		case ON_RADIO_CHANGE:
			return onCustomRadioDocs(state, action);
		case ON_FILE_CHANGE:
			return fileOnChange(state, action);
		case ON_DATE_CHANGE:
			return onDateChange(state, action);
		case ON_DYANMIC_ADD:
			return onClickDynamicAdd(state, action);
		case ON_SIBLING_DISPATCH:
			return siblingsDispatch(state, action);
		case ON_QUALIFICATION_DISPATCH:
			return qualificationDispatch(state, action);
		case ON_CERTIFICATE_DISPATCH:
			return certificateDispatch(state, action);
		case ON_EXPERIENCES_DISPATCH:
			return experienceDispatch(state, action);
		case ON_SKILLS_DISPATCH:
			return skillsDispatch(state, action);
		case ON_DYANMIC_DELETE:
			return onClickDynamicDelete(state, action);
		case ON_DYNAMIC_UPDATE:
			return updateSpecialArrChange(state, action);
		case ON_IMAGE_CHANGE:
			return imageChange(state, action);
		case ON_CLASS_CHANGE:
			return onClassChange(state, action);
		case 'decrement2':
			return { ...state, count2: state.count2 - 1 };
		case 'set2':
			return { ...state, count2: action.count };
		default:
			throw new Error('Unexpected action');
	}
};
const staffDocsReducer = (state, action) => {
	debugger;
	switch (action.type) {
		case ON_DOCUMENTS_DISPATCH:
			debugger;
			return staffDocsDispatch(state, action);
		case ON_RADIO_CHANGE:
			return onCustomRadioDocs(state, action);
		case ON_FILE_CHANGE:
			return fileOnChange(state, action);
		default:
			throw new Error('Unexpected action');
	}
};
export default {
	staffDocsReducer,
	staffDocState,
	staffInitailState,
	staffReducer,
	Types,
	staffNonStateFull
};
