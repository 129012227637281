import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import { Link } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import months from '../../utils/general';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import $ from 'jquery';
import Moment from 'moment';
import MultipleEvents from './MultipleEvents';

class UpdateEvent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listCheck: false,
			ClassesList: [],
			EventGrades: [],
			errorText: '',
			eventDate: '',
			AllData: [],
			class: [],
			section: [],
			classId: '',
			sessionId: '',
			AllEventData: [],
			monthlyStudentList: [],
			newMonthlyStudentList: [],
			adminData: [],
			filterCheck: false,
			status: '',
			msgSnack: '',
			backColor: '',
			year: '',
			month: '',
			modalData: null,
			enabled: false,
			id: null,
			select: [],
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10,
			pageCounter: 10,
			msg: '',
			errorMsg: '',
			increament: 1,
			sectionId: '',
			sections: [],
			session: [],
			sessionId: '',
			btnLoad: false,
			exist: false,
			checking: [],
			searchCheck: true,
			badRequest: false,
			errorText: '',
			check: false,
			ClassesDataForAdd: []
		};
	}
	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
			this.setState({
				disablepage: false
			});
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};

	componentDidUpdate() {
		$('.tooltip').tooltip('hide');

		if (
			this.state.msg ||
			this.state.errorMsg ||
			this.state.exist ||
			this.state.badRequest
		) {
			setTimeout(() => {
				this.setState({
					msg: false,
					errorMsg: false,
					exist: false,
					badRequest: false
				});
			}, 3000);
		}
	}

	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});

		this.props.sharedActions
			.getDataWithoutDispatch('/api/EventList?pageNumber=1&pageSize=10')
			.then(success => {
				debugger;
				this.setState({
					AllEventData: success.eventList,
					checking: success.paginationMetadata,
					searchCheck: false
				});
			})
			.catch(err => {
				this.setState({});

				console.log(err);
			});

		this.props.sharedActions
			.getDataWithoutDispatch('/api/AddClass')
			.then(success => {
				debugger;
				this.setState({
					AllData: success,
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({});
				console.log(err);
			});
	}

	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		this.props.sharedActions
			.deleteRecordWithoutDispatchWithoutSlash('/api/Event/', itemId)
			.then(success => {
				debugger;
				let deleteData = this.state.AllEventData.filter(
					item => item.id != itemId
				);
				this.setState({
					AllEventData: deleteData,
					msg: 'Record Deleted Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {
				debugger;
			});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { AllEventData } = this.state;

		let getData = AllEventData.filter(item => item.id === itemId);

		let ClassesDataForAdd = this.state.AllData.map((item, index) => {
			return {
				classId: item.id,
				eventId: getData[0].id,
				className: item.className,
				isChecked: false
			};
		});

		this.setState({
			ClassesDataForAdd,
			modalData: getData,
			month: getData[0].month,
			eventDate: getData[0].eventDate,
			eventTime: getData[0].eventTime,
			sessionId: getData[0].sessionId,
			subject: getData[0].subject,
			description: getData[0].description,
			id: getData[0].id
		});
	};

	onHandleText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
		debugger;
	};

	onStatusChange = e => {
		console.log(e.target.value);
		this.setState(
			{
				status: e.target.value
			},
			this.hitFilterApi
		);
	};
	Reload = () => {
		setTimeout(function() {
			window.location.reload();
		}, 3000);
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const {
			description,
			eventTime,
			eventDate,
			subject,
			month,
			sessionId,
			id
		} = this.state;
		var bodyParameters = {
			description,
			eventTime,
			eventDate,
			subject,
			month,
			sessionId,
			accountId: this.props.users.unique_name,
			accountType: this.props.users.role
		};
		this.props.sharedActions
			.editRecordWithoutDispatch(`/api/Event/`, id, bodyParameters)
			.then(success => {
				debugger;
				this.setState(
					{ enabled: false, msg: 'Edit Record Successfully!' },
					() => {
						$('#myModal').modal('hide');
					}
				);
				this.props.snackbar();
				this.Reload();
			})
			.catch(err => {
				this.setState({
					errorMsg: true,
					errorText: err.response.data.Message
				});
				debugger;
			});
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};

	paginationRequest = number => {
		this.setState({
			searchCheck: true
		});
		console.log(number);

		this.props.sharedActions
			.getDataWithoutDispatch(`/api/EventList?pageNumber=${number}&pageSize=10`)
			.then(success => {
				debugger;
				this.setState({
					AllEventData: success.eventList,
					checking: success.paginationMetadata,
					searchCheck: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});

				console.log(err);
			});
	};

	onClickDeleteItem = arrName => {
		let getStateArr = this.state[`${arrName}`];
		let getDeleteLength = getStateArr.length - 1;
		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			this.setState({
				[arrName]: [...getNewDetails]
			});
		}
	};

	onClickAddItem = (arrName, obj) => {
		let getStateArr = this.state[`${arrName}`];
		getStateArr = [...getStateArr, obj];
		console.log(arrName);
		this.setState({
			[arrName]: getStateArr
		});
		console.log(this.state.EventGrades);
	};
	updateSpecialArrObjOnChange = (e, index, arrName) => {
		debugger;
		const changeArrOfObj = this.state[`${arrName}`];
		changeArrOfObj[index][e.target.name] = e.target.value;

		if (e.target.name == 'classId') {
			const Sections = this.state.AllData.section.filter(
				item => item.classId == e.target.value
			);
			this.setState({
				section: Sections
			});
		}
		this.setState({
			[arrName]: changeArrOfObj
		});
		console.log(this.state);
	};
	onChangeText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	submitClasses = e => {
		const { EventGrades } = this.state;
		const data = {
			EventGrades
		};
		this.props.sharedActions
			.simpleAddRequest('/api/AddClassInEvent', data)
			.then(success => {
				this.setState(
					{
						backColor: '#01ac8a',
						EventGrades: [],
						msg: 'Classes Added in Event'
					},
					() => {
						$('#AddClassModal').modal('hide');
					}
				);
				this.props.snackbar();
			})
			.catch(err => {
				debugger;
				this.setState(
					{
						EventGrades: [],
						msg: err.response.data.Message,
						backColor: 'red'
					},
					() => {
						$('#AddClassModal').modal('hide');
					}
				);
				this.props.snackbar();
			});
	};
	viewClassList = eventId => {
		this.props.sharedActions
			.getDataWithoutDispatch(
				`/api/GetClassByParticularEvent?eventId=${eventId}`
			)
			.then(success => {
				debugger;
				this.setState({
					listCheck: true,
					ClassesList: success,
					searchCheck: false
				});
			})
			.catch(err => {
				this.setState({
					listCheck: false
				});

				console.log(err);
			});
	};
	deleteClass = eventGrade => {
		this.props.sharedActions
			.deleteRecordWithoutDispatchWithoutSlash(
				'/api/RemoveClassFromEvent/?eventGradeId=',
				eventGrade
			)
			.then(success => {
				debugger;
				let deleteData = this.state.ClassesList.filter(
					item => item.eventGradeId != eventGrade
				);
				this.setState({
					backColor: '#01ac8a',
					ClassesList: deleteData,
					msg: 'Record Deleted Successfully!'
				});
				this.props.snackbar();
			})
			.catch(error => {
				debugger;
			});
	};
	classSelect = (e, classId, className) => {
		let obj = {
			eventId: this.state.id,
			classId: classId,
			className: className
		};
		this.state.ClassesDataForAdd.forEach(item => {
			if (classId == item.classId) {
				debugger;
				item.isChecked = e.target.checked;
			}

			if (e.target.checked == true) {
				debugger;
				this.setState({
					EventGrades: [...this.state.EventGrades, obj]
				});
			}
			if (e.target.checked == false) {
				debugger;
				let clearObj = this.state.EventGrades.filter(
					item => item.classId !== classId
				);
				this.setState({
					check: false,
					EventGrades: clearObj
				});
			}

			console.log(this.state.EventGrades);
		});
		this.setState({
			ClassesDataForAdd: this.state.ClassesDataForAdd
		});
	};
	AllClassSelect = e => {
		const allClasses = this.state.ClassesDataForAdd.map((item, index) => {
			return {
				classId: item.classId,
				eventId: this.state.id,
				className: item.className
			};
		});

		this.state.ClassesDataForAdd.forEach(item => {
			item.isChecked = e.target.checked;
		});

		this.setState({
			check: true,
			EventGrades: allClasses,
			ClassesDataForAdd: this.state.ClassesDataForAdd
		});
	};
	render() {
		const { modalData } = this.state;
		const { session } = this.state.session;

		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);

		let pageNumbers = [];
		for (
			let i = 1;
			i <=
			Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
			i++
		) {
			pageNumbers.push(i);
		}

		const renderPageNumbers = pageNumbers.map((number, index) => {
			return (
				<li
					class={`page-item ${
						this.state.checking.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={() => this.paginationRequest(number)}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			);
		});

		const renderBillData = this.state.AllEventData.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row">{index + 1}</th>
					<td>{item.eventDate}</td>
					<td>{item.month}</td>
					<td>{item.eventTime}</td>
					<td>{item.session}</td>
					<td>{item.subject}</td>
					<td>{item.description}</td>
					<td>
						<a href="#" data-toggle="tooltip" title="Add Class">
							<button
								onClick={() => this.onEditClick(item.id)}
								data-toggle="modal"
								data-target="#AddClassModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
								class="buttonHover"
							>
								<i style={{ color: '#9db3e3' }} class="fas fa-plus-circle"></i>
							</button>
						</a>
						<a href="#" data-toggle="tooltip" title="View Classes">
							<button
								onClick={() => this.viewClassList(item.id)}
								data-toggle="modal"
								data-target="#ViewClassModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
								class="buttonHover"
							>
								<i style={{ color: 'black' }} class="fas fa-list"></i>
							</button>
						</a>
						<a href="#" data-toggle="tooltip" title="Edit!">
							<button
								id="firstbutton"
								onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								data-toggle="modal"
								data-target="#myModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i class="fas fa-pen"></i>
							</button>
						</a>
						<a href="#" data-toggle="tooltip" title="Delete!">
							<button
								onClick={() => this.onEditClick(item.id)}
								data-toggle="modal"
								data-target="#myModal1"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
							</button>
						</a>
					</td>
				</tr>
			);
		});

		const MainContent = (
			<div>
				<div class="modal fade" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>

				<div class="modal fade" id="ViewClassModal">
					<div class="modal-dialog modal-lg modal-dialog-centered">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									View Classes
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<table class="table table-sm">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Subject</th>
											<th scope="col">Grades</th>
											<th scope="col">Action</th>
										</tr>
									</thead>
									<tbody>
										{this.state.listCheck ? (
											this.state.ClassesList.map((item, index) => (
												<tr>
													<th>{index + 1}</th>
													<td>{item.subject}</td>
													<td>{item.className}</td>
													<td>
														<a href="#" data-toggle="tooltip" title="Delete!">
															<button
																onClick={() =>
																	this.deleteClass(item.eventGradeId)
																}
																style={{
																	cursor: 'pointer',
																	fontSize: 20,
																	border: 'none',
																	background: 'none',
																	marginLeft: 5,
																	marginBottom: 5
																}}
															>
																<i
																	style={{ color: '#D11A2A' }}
																	class="fas fa-trash-alt"
																></i>
															</button>
														</a>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td></td>
												<td></td>
												<td>
													<label class="font-weight-bold text-danger">
														No Class Added in this Event
													</label>
												</td>
												<td></td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div class="modal fade" id="AddClassModal">
					<div class="modal-dialog modal-lg  modal-dialog-centered">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Add Classes in Event
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div class="table-responsive">
									<table class="table table-hover table-sm">
										<thead style={{ background: '#01AC8A', color: 'white' }}>
											<tr>
												<th scope="col">#</th>
												<th scope="col">Grades</th>
												<th scope="col">
													Add
													<div
														class="col-md-4 text-right"
														style={{ fontSize: 15, float: 'right' }}
													>
														<div class="custom-control custom-checkbox text-right">
															<input
																class="custom-control-input"
																type="checkbox"
																onClick={this.AllClassSelect}
																value="checkedall"
																id="defaultChecked"
															/>
															<label
																class="font-weight-bold custom-control-label"
																for="defaultChecked"
															>
																Add All
															</label>
														</div>
													</div>
												</th>
											</tr>
										</thead>
										<tbody>
											{this.state.ClassesDataForAdd.map((item, index) => (
												<React.Fragment>
													<tr class="">
														<td>{index + 1}</td>
														<td>
															{' '}
															<label class="font-weight-bold">
																{item.className}
															</label>
														</td>
														<td>
															<input
																id="CBOX1"
																style={{ marginRight: 20 }}
																type="checkbox"
																checked={item.isChecked}
																onClick={e =>
																	this.classSelect(
																		e,
																		item.classId,
																		item.className
																	)
																}
																name={item.name}
															/>
														</td>
													</tr>
												</React.Fragment>
											))}
										</tbody>
									</table>

									{/* <ul class="list-inline">
											{this.state.ClassesDataForAdd.map((item, index) => (
												<React.Fragment>
													<li class="">
														<input
															id="CBOX1"
															style={{ marginRight: 20 }}
															type="checkbox"
															checked={item.isChecked}
															onClick={e =>
																this.classSelect(
																	e,
																	item.classId,
																	item.className
																)
															}
															name={item.name}
														/>
														&nbsp;
														<label class="font-weight-bold">
															{item.className}
														</label>
													</li>
												</React.Fragment>
											))}
										</ul> */}

									<div class="text-right text-md-right">
										<button onClick={this.submitClasses} class="buttonHover2">
											Add
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="modal fade" tabindex="-1" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{this.state.errorMsg ? (
									<p style={{ color: 'red', textAlign: 'center' }}>
										{this.state.errorText}
									</p>
								) : (
									''
								)}
								<form onSubmit={this.onHandleSubmit}>
									{modalData &&
										modalData.map((item, index) => (
											<div key={index}>
												<fieldset disabled={!this.state.enabled}>
													<div class="form-group">
														<label for="campusName">Subject/Event:</label>
														<input
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.subject}
															onChange={this.onHandleText}
															name="subject"
															class="form-control"
															id="campusName"
														/>
													</div>
													<div class="form-group">
														<label for="campusName">Description:</label>
														<textarea
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.description}
															onChange={this.onHandleText}
															name="description"
															class="form-control"
															id="campusName"
														/>
													</div>
												</fieldset>
												<button
													disabled={this.state.enabled}
													onClick={() => this.setState({ enabled: true })}
													type="button"
													class="btn btn-primary buttonAppear"
												>
													Edit
												</button>
												<button
													style={{ marginLeft: 5 }}
													disabled={!this.state.enabled}
													type="submit"
													class="btn btn-primary buttonAppear"
												>
													Save
												</button>
											</div>
										))}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span className="customBadge" style={{ fontSize: 14 }}>
						Page: {this.state.checking.currentPage}
					</span>

					<span className="customBadge" style={{ fontSize: 14 }}>
						Total Page: {this.state.checking.totalPages}
					</span>
				</div>
				<div class="table-responsive">
					<table
						class="table table-hover table-bordered"
						style={{ textAlign: 'center' }}
					>
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Date</th>
								<th scope="col">Month</th>
								<th scope="col">Time</th>
								<th scope="col">Session</th>

								<th scope="col">Subject</th>
								<th scope="col">Description</th>

								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>{renderBillData}</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				<SnackBar msg={this.state.msg} backColor={this.state.backColor} />

				{this.state.exist ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Please Fill the Fields
					</p>
				) : (
					''
				)}
				{this.state.badRequest ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						{this.state.errorText}
					</p>
				) : (
					''
				)}
				<div className="page-header">
					<div class="row">
						<div class="col-md-3"></div>
						<div class="col-md-6">
							<h4 class="generalHead">Manage Events</h4>
						</div>
						<div class="col-md-3 text-right">
							<nav aria-label="Page navigation example">
								<ul style={{ color: '#01AC8A' }} class="pagination">
									{renderPageNumbers}
								</ul>
							</nav>
						</div>
					</div>
				</div>

				<br />
				<br />
				{this.state.searchCheck ? Loader : MainContent}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		allTransportState: state.setStudentSkillReducer,
		users: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UpdateEvent);
