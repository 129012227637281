import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
	apiFlag: true,
	Data: [],
	ParticularData: [],
	select: [],
	selectSession: []
};

export default function setAssignmentReducer(
	state = defaultState,
	action = {}
) {
	switch (action.type) {
		case actionTypes.SET_ALL_CLASS_NAME_INTO_ASSIGNMENT_SELECT:
			return {
				...state,
				select: action.payload
			};
		case actionTypes.SET_ALL_SESSION_NAME_INTO_ASSIGNMENT_SELECT:
			return {
				...state,
				selectSession: action.payload
			};

		case actionTypes.GET_ALL_ASSIGNMENT:
			return { ...state, Data: [...action.payload] };
		case actionTypes.GET_PARTICULAR_ASSIGNMENT:
			return { ...state, ParticularData: [...action.payload] };
		case actionTypes.SET_LOADER_FLAG_ASSIGNMETN:
			return { ...state, apiFlag: action.payload };
		case actionTypes.DELETE_ASSIGNMENT:
			let filterCandidateReqList = state.Data.filter(
				filterOBJ => filterOBJ.assignmentId !== action.payload
			);
			return {
				...state,
				Data: filterCandidateReqList
			};
		case actionTypes.EDIT_ASSIGNMETN_RECORD:
			var adminData = [...state.Data];
			// var index = adminData.findIndex(obj => obj.assignmentId === action.payload.id);
			// adminData[index].link = action.payload.link;
			// // adminData[index].campusName = action.payload.campusName;
			// // adminData[index].campusId = action.payload.campusId;

			return { ...state, Data: [...adminData] };
		default:
			return state;
	}
}
