import React, { Component } from 'react';
import './AddGm.css';
import { Prompt } from 'react-router-dom';
import * as LoginActions from '../../../Actions/LoginActions';
import * as AdminActions from '../../../Actions/getAllAdmin';
import * as sharedActions from '../../../Actions/sharedActions';
import Snackbar from '../../../components/SnackBar/SnackBar';
import DateandTime from '../../Transport/DateandTime';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class AddAdmin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			name: '',
			password: '',
			valid: false,
			post: '',
			msg: '',
			errorMsg: false,
			backColor: ''
		};
	}
	onHandleTextChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	componentDidUpdate() {
		if (this.state.msg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					msg: '',
					errorMsg: false
				});
			}, 3000);
		}
	}
	onHandleSubmit = e => {
		e.preventDefault();
		const { username, password, name } = this.state;
		if (
			Object.is(username, '') ||
			Object.is(password, '') ||
			Object.is(name, '')
		) {
			this.setState({
				valid: false,
				errorMsg: true
			});
		} else {
			this.setState({
				valid: true
			});
			var bodyParameters = {
				userName: this.state.username,
				name: this.state.name,
				password: this.state.password,
				post: this.state.post,
				accountType: this.props.role,
				accountId: this.props.name
			};
			this.props.sharedActions
				.addRecord(
					'/api/Admin',
					bodyParameters,
					this.props.adminActions.addAdmin
				)
				.then(success => {
					this.props.adminActions.updateGlobalFlag(true);
					this.setState({
						username: '',
						password: '',
						post: '',
						name: '',
						msg: 'Admin Added Successfully',
						backColor: '#01ac8a',
						errorMsg: false
					});
					this.props.snackbar();
				})
				.catch(error => {
					debugger;
					this.setState({
						msg: error.response.data.Message,
						backColor: 'red'
					});
					this.props.snackbar();
				});
		}
	};
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};

	render() {
		return (
			<div className="container">
				<Snackbar msg={this.state.msg} backColor={this.state.backColor} />
				<Prompt
					when={this.state.valid !== true}
					message="Leaving this form will lose your data"
				/>
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4">
						Add Admin
					</h2>

					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: '#01ac8a',
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							{this.Message(
								'Please fill the form properly!',
								'danger',
								20,
								this.state.errorMsg
							)}
							<form
								id="contact-form"
								name="contact-form"
								onSubmit={this.onHandleSubmit}
								noValidate
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.name}
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label style={{ color: 'black' }} for="name" class="">
												Admin Type
											</label>
											<input
												type="text"
												name="AdminType"
												readOnly={true}
												value={this.props.role}
												class="form-control"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Name
											</label>
											<input
												class="form-control"
												type="text"
												value={this.state.name}
												placeholder="Name"
												name="name"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Username
											</label>
											<input
												class="form-control"
												type="text"
												value={this.state.username}
												placeholder="Username"
												name="username"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Password
											</label>
											<input
												type="password"
												value={this.state.password}
												placeholder="Password"
												name="password"
												onChange={this.onHandleTextChange}
												class="form-control"
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Select Manager
											</label>
											<select
												onChange={this.onHandleTextChange}
												class="custom-select"
												name="post"
											>
												<option>Choose Manager..</option>
												<option value="Finanace Smart">Finance Smart</option>
												<option value="Business Smart">Business Smart</option>
												<option value="Administration Smart">
													Administration Smart
												</option>
												<option value="Academic Smart">Academic Smart</option>
												<option value="Admission Smart">Admission Smart</option>
												<option value="Communication Smart">
													Communication Smart
												</option>
												<option value="HR Smart">HR Smart</option>
												<option value="Pastoral Smart">Pastoral Smart</option>
												<option value="System Smart">System Smart</option>
											</select>
										</div>
									</div>
								</div>

								<br />
								<br />
								<div class="text-center text-md-center">
									<button class="btns">Add</button>
								</div>
							</form>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		addAdmin: bindActionCreators(LoginActions, dispatch),
		adminActions: bindActionCreators(AdminActions, dispatch),
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddAdmin);
