import React, {
	useEffect,
	useReducer,
	useRef,
	useState,
	useCallback
} from 'react';
import Stepper from 'bs-stepper';
import validator from '../../validator/registrationValidator';
import config from '../../config/config';
import DocumentSectionStep from '../../Container/Staff/DocumentSectionStep/DocumentSectionStep';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';

import { StepperList, StepperContent } from '../../components/Stepper';

import {
	Certificates,
	Experience,
	StaffSkills,
	Qualification,
	Sibling,
	StaffDetails,
	StaffDoc,
	StaffAddress,
	StaffPassport
} from '../../components/Staff';
import http from '../../hooks/http';

import staff from '../../hooks/HookReducers/StaffReducer/index';
import LayoutWrapper from '../../components/HOC/LayoutWrapper';
import { General } from '../../utils';
import Axios from 'axios';
// import { General } from '../../utils';
const StaffForm = props => {
	const [staffId, setStaffId] = useState(null);
	const [customLoader, setCustomLoader] = useState(false);
	const [docMode, setDocMode] = useState(false);
	const stepper = useRef();
	const main = useRef();
	const [errors, setErrors] = useState({
		exist: false
	});
	const nonReRenderState = useRef(staff.staffNonStateFull);
	const [newState, dispatch] = useReducer(
		staff.staffReducer,
		staff.staffInitailState
	);
	debugger;
	const [newStatePart2, setNewState] = useState(null);

	useEffect(() => {
		setNewState(newState);
		debugger;
	}, [newState]);
	debugger;

	const onUserFieldBlur = () => {
		const { username } = nonReRenderState.current;
		const error = { ...errors };
		props.sharedAction
			.simpleAddRequest('/api/UsernameInStudentForm', {
				checkUserName: username
			})
			.then(success => {
				debugger;
				error['exist'] = false;
				setErrors(error);
			})
			.catch(error => {
				error['exist'] = true;
				setErrors(error);
			});
	};
	const fileUpload = (staffId, file, ...moreFiles) => {
		const {
			experienceLetter,
			iletsCertificate,
			ministryApproval,
			lastQualification,
			passportCopy,
			idCardCopys,
			staffpassportCopy,
			staffIdcardCopy,
			experienceLetterfilePath,
			iletsCertificatefilePath,
			ministryApprovalfilePath,
			lastQualificationfilePath,
			passportCopyfilePath,
			idCardCopysfilePath,
			staffpassportCopyfilePath,
			staffIdfilePath1
		} = newState;
		let imageData = new FormData();
		imageData.append('file', file, file.name);
		imageData.append('accountId', props.name);
		imageData.append('staffId', staffId);
		imageData.append('accountType', props.role);

		let data = new FormData();
		data.append('staffId', staffId);

		//parent docs
		if (experienceLetter === 'Yes') {
			data.append('filePath_experienceLetter', moreFiles[0], moreFiles[0].name);
			data.append(
				'experienceLetter',
				experienceLetter + '_' + moreFiles[0].name
			);
		} else {
			data.append('experienceLetter', experienceLetter);
		}
		if (iletsCertificate === 'Yes') {
			data.append('filePath_iletsCertificate', moreFiles[1], moreFiles[1].name);
			data.append(
				'iletsCertificate',
				iletsCertificate + '_' + moreFiles[1].name
			);
		} else {
			data.append('iletsCertificate', iletsCertificate);
		}
		if (ministryApproval === 'Yes') {
			data.append('filePath_ministryApproval', moreFiles[2], moreFiles[2].name);
			data.append(
				'ministryApproval',
				ministryApproval + '_' + moreFiles[2].name
			);
		} else {
			data.append('ministryApproval', ministryApproval);
		}
		if (lastQualification === 'Yes') {
			data.append(
				'filePath_lastQualification',
				moreFiles[3],
				moreFiles[3].name
			);
			data.append(
				'lastQualification',
				lastQualification + '_' + moreFiles[3].name
			);
		} else {
			data.append('lastQualification', lastQualification);
		}
		if (passportCopy === 'Yes') {
			data.append('filePath_passportCopy', moreFiles[4], moreFiles[4].name);
			data.append('passportCopy', passportCopy + '_' + moreFiles[4].name);
		} else {
			data.append('passportCopy', passportCopy);
		}
		if (idCardCopys === 'Yes') {
			data.append('filePath_idCardCopys', moreFiles[5], moreFiles[5].name);
			data.append('idCardCopys', idCardCopys + '_' + moreFiles[5].name);
		} else {
			data.append('idCardCopys', idCardCopys);
		}
		if (staffpassportCopy === 'Yes') {
			data.append(
				'filePath_staffpassportCopy',
				moreFiles[6],
				moreFiles[6].name
			);
			data.append(
				'staffpassportCopy',
				staffpassportCopy + '_' + moreFiles[6].name
			);
		} else {
			data.append('staffpassportCopy', staffpassportCopy);
		}
		if (staffIdcardCopy === 'Yes') {
			data.append('filePath_staffIdcardCopy', moreFiles[7], moreFiles[7].name);
			data.append('staffIdcardCopy', staffIdcardCopy + '_' + moreFiles[7].name);
		} else {
			data.append('staffIdcardCopy', staffIdcardCopy);
		}
		data.append('accountId', props.name);
		data.append('accountType', props.role);

		let token = '';
		if (localStorage.jwtToken) {
			token += localStorage.getItem('jwtToken') + token;
		}
		var Headconfig = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: 'Bearer ' + token
			}
		};
		// var bodyParameters = { data }

		return Promise.all([
			Axios.post(
				`http://www.https://embassyapp.linkit.org.uk/api/StaffDocuments`,
				data,
				Headconfig
			),
			Axios.post(
				'http://www.https://embassyapp.linkit.org.uk/api/StaffImageUpload',
				imageData,
				Headconfig
			)
		])
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
	};
	const onSubmit = async (e, cdata) => {
		console.log(newState);
		console.log(cdata);
		if (true) {
			// alert('every thing is fine');
			console.log('statefull data', state);
			debugger;
			const {
				Experiences,
				Skills,
				Qualifications,
				Siblings,
				Certificate,

				// docs start
				experienceLetter,
				iletsCertificate,
				ministryApproval,
				lastQualification,
				passportCopy,
				idCardCopys,
				staffpassportCopy,
				staffIdcardCopy,
				staffIdfilePath1,
				experienceLetterfilePath,
				iletsCertificatefilePath,
				ministryApprovalfilePath,
				lastQualificationfilePath,
				passportCopyfilePath,
				staffpassportCopyfilePath,
				idCardCopysfilePath,
				// docs ends
				//  campus
				campusName,
				campusId,
				// end of campus

				// post
				postId,
				postName,
				// end post

				// department
				departmentId,
				departmentName,
				//  end of depart

				// section
				section,
				sectionId,

				// end of section
				// subjectId
				// subject start
				subjectId,
				subjectName,
				// subject end done
				// start of class name done
				className,
				classId,
				//  end of class name
				// start of gradeName
				gradeId,
				gradeName,
				//  end of gradename

				dob,
				age,
				staffPassportDOI,
				staffPassportDOE,
				staffIdcardDOI,
				staffIdcardDOE,
				dateOfJoining,
				file
			} = newState;
			debugger;
			const {
				fullNameArabic,
				StaffFirstName,
				lastName,
				gender,
				fatherName,
				designation,
				maritailStatus,
				nationality,
				city,
				country,
				state,
				StreetNo,
				mobileNumber,
				email,
				bloodGroup,
				address,
				religion,
				username,
				password,
				status,
				appraisalStatus,
				staffPassportNo,
				staffIdcardNo,
				basicSalary,
				houseNo,
				wayNo,
				phoneNo
			} = nonReRenderState.current;
			debugger;
			console.log('nonstatfull data: ', nonReRenderState);

			let data = {
				Staffs: {
					StaffFirstName,
					fullNameArabic,
					lastName,
					gender,
					fatherName,
					departmentId,
					postId,
					subjectId,
					className,
					gradeId,
					classId,
					sectionId,
					section,
					designation,
					maritailStatus,
					nationality,
					city,
					country,
					state,
					address,
					religion,
					mobileNumber,
					email,
					dob,
					age,
					bloodGroup,
					experienceLetter,
					iletsCertificate,
					ministryApproval,
					staffPassportNo,
					// departmentName,
					staffPassportDOI,
					staffPassportDOE,
					staffpassportCopy,
					staffIdcardNo,
					staffIdcardDOI,
					staffIdcardDOE,
					staffIdcardCopy,
					houseNo,
					wayNo,
					StreetNo,
					phoneNo,
					campusId,
					campusName,
					accountId: props.name,
					accountType: props.role,
					userName: username,
					password,
					basicSalary,
					appraisalStatus,
					dateOfJoining,
					status
				},
				PostName: {
					postId,
					postName
				},
				DepartmentName: {
					departmentName,
					departmentId
				},
				SubjectName: {
					subjectName,
					subjectId
				},
				CampusStaff: {
					campusName,
					campusId
				},
				ClassName: {
					classId,
					className,
					gradeId,
					gradeName
				},
				SectionName: {
					sectionId,
					sectionName: section
				},
				Certificate: cdata,
				Qualifications,
				Skills,
				Experiences,
				Siblings
			};
			debugger;
			let token = '';

			if (localStorage.jwtToken) token = localStorage.getItem('jwtToken');
			setCustomLoader(true);
			Axios.post(`${config.localhttp}/api/AddStaff`, data, {
				headers: { Authorization: `Bearer  ${token}` }
			})
				.then(success => {
					debugger;
					if (
						success &&
						success['data'] &&
						success['data']['Staffs'] &&
						success['data']['Staffs'] &&
						success['data']['Staffs']['staffId']
					) {
						debugger;
						setStaffId(success['data']['Staffs']['staffId']);
					}
					setCustomLoader(false);
					setDocMode(true);
				})
				.catch(error => {});
		}
	};
	const converter = input => {
		return input.replace(/([A-Z])/g, ' $1');
	};

	useEffect(() => {
		stepper.current = new Stepper(document.querySelector('#stepper1'), {
			linear: false,
			animation: true
		});
	}, []);
	const isValid = type => {
		const { errors, isValid } = validator(
			nonReRenderState.current,
			type,
			newState
		);
		if (!isValid) {
			setErrors(errors);
		}
		return isValid;
	};

	const handleScroll = e => {
		debugger;
		e.preventDefault();
		const newmain = main.current;
		window.scrollTo({
			top: newmain.offsetTop,
			left: 0,
			behavior: 'instant'
		});
	};

	const onNextClick = (e, type) => {
		debugger;
		if (isValid(type) && !errors['exist']) {
			setErrors({});
			debugger;
			stepper.current.next();
		} else if (type === 'checkStaffDetails' && errors['exist']) {
			debugger;
			handleScroll(e);
		} else {
			debugger;
			handleScroll(e);
		}
	};
	const on2ndStep = e => {
		if (isValid('checkStaffDetails')) {
			alert('every thing is fine');
			stepper.current.next();
		} else {
			handleScroll(e);
		}
	};
	const dynamicFieldValidate = array => {
		let isValid = array.map((item, index) => {
			console.log(General.IsObjectEmpty(item));
			debugger;
			if (!General.IsObjectEmpty(item)) {
				debugger;
				return false;
			} else {
				debugger;
				return true;
			}
		});
		console.log(isValid);

		let getValue = false;
		if (isValid.includes(false)) {
			getValue = false;
		} else {
			getValue = true;
		}
		return getValue;
	};
	const getValidatedArray = (e, arr, createErrorField) => {
		let getCloneErrors = { ...errors };
		if (!dynamicFieldValidate(arr)) {
			getCloneErrors[createErrorField] = true;
			setErrors(getCloneErrors);
			debugger;
			handleScroll(e);
			return true;
		} else {
			getCloneErrors[createErrorField] = false;
			setErrors(getCloneErrors);
			debugger;
			stepper.current.next();
			return false;

			// handleScroll(e);
		}
	};
	const docsChange = e => {
		e.preventDefault();
		try {
			let reader = new FileReader();
			let file = e.target.files[0];

			if (
				!General.checkForImageOrFile(
					false,
					true,
					errors,
					file.size,
					2.1,
					file.name,
					'fileType',
					setErrors
				)
			) {
				dispatch({
					type: 'onFileChange',
					payload: {
						name: e.target.name,
						value: e.target.files[0]
					}
				});
			} else {
			}
		} catch (e) {
			console.log(e);
		}
	};
	const imageChange = e => {
		e.preventDefault();
		try {
			let reader = new FileReader();
			let file = e.target.files[0];

			if (
				!General.checkForImageOrFile(
					true,
					false,
					errors,
					file.size,
					2.1,
					file.name,
					'imgType',
					setErrors
				)
			) {
				reader.onloadend = () => {
					dispatch({
						type: 'ON_IMAGE_CHANGE',
						payload: {
							file,
							imagePreviewUrl: reader.result,
							imageFile: file.name
						}
					});
				};
				reader.readAsDataURL(file);
			} else {
			}
		} catch (e) {
			console.log(e);
		}
	};
	// onCertificateDispatch(())
	const onPreviousClick = () => {
		stepper.current.previous();
	};
	const docsSection = (
		<React.Fragment>
			<div id="stepper1" style={{ display: 'block' }}>
				{/* ss */}
			</div>
			<div className="animated fadeIn">
				<DocumentSectionStep {...props} id={staffId} />
			</div>
		</React.Fragment>
	);
	return !docMode ? (
		<div className={'table-responsive'}>
			{/* <Hello name={this.state.name} /> */}
			<div ref={main} id="stepper1" class="bs-stepper">
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<StepperList
						stepperId="stepper1"
						Stepes={[
							'Step 1',
							'Step 2',
							'Step 3',
							'Step 4',
							'Step 5',
							'Step 6',
							'Step 7',
							'Step 8'
						]}
					/>
					<StepperContent>
						<div id="test-l-1" class="content">
							<LayoutWrapper formType="Staff Information" step="1">
								{/* {errorRendering(errors)} */}
								<StaffDetails
									edit={false}
									errors={errors}
									onUserFieldBlur={onUserFieldBlur}
									onNextClick={onNextClick}
									getValidatedArray={getValidatedArray}
									onPreviousClick={onPreviousClick}
									imageChange={imageChange}
									stateData={newState}
									dispatch={dispatch}
									nonStateData={nonReRenderState}
								/>
							</LayoutWrapper>
						</div>
						<div id="test-l-2" class="content">
							<LayoutWrapper formType="Staff Address" step="2">
								<StaffAddress
									errors={errors}
									edit={false}
									onNextClick={onNextClick}
									onPreviousClick={onPreviousClick}
									dispatch={dispatch}
									nonStateData={nonReRenderState}
									stateData={newState}
								/>
							</LayoutWrapper>
						</div>
						<div id="test-l-3" class="content">
							<LayoutWrapper formType="Staff Passport" step="3">
								<StaffPassport
									dispatch={dispatch}
									docsChange={docsChange}
									errors={errors}
									edit={false}
									onNextClick={onNextClick}
									onPreviousClick={onPreviousClick}
									nonStateData={nonReRenderState}
									dispatch={dispatch}
									stateData={newState}
								/>
							</LayoutWrapper>
						</div>
						<div id="test-l-4" class="content">
							<LayoutWrapper formType="Siblings" step="4">
								<Sibling
									errors={errors}
									dispatch={dispatch}
									getValidatedArray={getValidatedArray}
									onPreviousClick={onPreviousClick}
								/>
							</LayoutWrapper>
						</div>
						<div id="test-l-5" class="content">
							<LayoutWrapper formType="Staff Qualification" step="5">
								<Qualification
									errors={errors}
									getValidatedArray={getValidatedArray}
									dispatch={dispatch}
									onPreviousClick={onPreviousClick}
								/>
							</LayoutWrapper>
						</div>
						<div id="test-l-6" class="content">
							<LayoutWrapper formType="Staff Experience" step="6">
								<Experience
									errors={errors}
									getValidatedArray={getValidatedArray}
									dispatch={dispatch}
									onPreviousClick={onPreviousClick}
								/>
							</LayoutWrapper>
						</div>
						<div id="test-l-7" class="content">
							<LayoutWrapper formType="Staff Skills" step="7">
								<StaffSkills
									errors={errors}
									getValidatedArray={getValidatedArray}
									dispatch={dispatch}
									onPreviousClick={onPreviousClick}
								/>
							</LayoutWrapper>
						</div>
						<div id="test-l-8" class="content">
							<LayoutWrapper formType="Staff Certificate" step="8">
								<Certificates
									onSubmit={onSubmit}
									newState={newState}
									customLoader={customLoader}
									errors={errors}
									getValidatedArray={getValidatedArray}
									dispatch={dispatch}
									onPreviousClick={onPreviousClick}
								/>
							</LayoutWrapper>
						</div>
					</StepperContent>
				</div>
			</div>
		</div>
	) : (
		docsSection
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedAction: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StaffForm);
